import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import "./DoctorCreateBlog.css";
import Apiservice from "../../Services/Apiservice";

const DoctorCreateBlog = () => {

  // GET AUTHOR DETAILS 
  const UserIdtoDetDetails = localStorage.getItem("id");
  const [authordetails, setauthordetails] = useState([]);
  const [userdetails, setuserdetails] = useState([]);

  useEffect(() => {
    Apiservice
      .get(
        `/current/user/details/${UserIdtoDetDetails}/`,
        
      )

      .then((response) => {
        setauthordetails(response.data);
        // // //console.log(response.data)
      })
      .catch((err) => {});
  }, [UserIdtoDetDetails]);

  // POST BLOG
  const [title, settitle] = useState("");
  const [summary, setsummary] = useState("");
  const [overview, setoverview] = useState("");
  const [category, setcategory] = useState("");
  const currentdateduringpost = new Date();

  const createblog = async (e) => {
    e.preventDefault();
    await Apiservice
      .post("/blogapi/", {
        firstName: authordetails.first_name,
        lastName: authordetails.last_name,
        title: title,
        summary: summary,
        overview: overview,
        // thumbnail: "",
        category: category,
        created_data: currentdateduringpost.toLocaleDateString("fr-CA"),
      }, {
        headers: {
          Authorization: `Token ${localStorage.getItem("SavedToken")}`,
        },
      })
      .then((res) => {
        // // //console.log(res);
      })
      .catch((err) => {});
  };
  return (
    <div className="create-blog-flex-div">
      <div className="create-blog-width-div">
        <div className="create-blog-main-div">
          <div className="create-blog-sub-div-one">
            <h3>Create a New Blog</h3>
          </div>
          <div className="create-blog-sub-div-two">
            <h3>Title : </h3>
            <textarea
              placeholder="Title..."
              className="header-texarea-in-create-blog"
              value={title}
              onChange={(e) => settitle(e.target.value)}
            />
          </div>
          <div className="create-blog-sub-div-two">
            <h3>Please Select the Category of Your Blog : </h3>
            <div className="blog-radio-in-create-blog">
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <input
                  type="radio"
                  name="category"
                  value="Disease"
                  onChange={(e) => setcategory(e.target.value)}
                />
                <p>Disease</p>
              </div>
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <input
                  type="radio"
                  name="category"
                  value="Lifestyle"
                  onChange={(e) => setcategory(e.target.value)}
                />
                <p>Lifestyle</p>
              </div>
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <input
                  type="radio"
                  name="category"
                  value="Medical_Test"
                  onChange={(e) => setcategory(e.target.value)}
                />
                <p>Medical Test</p>
              </div>
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <input
                  type="radio"
                  name="category"
                  value="Medicine"
                  onChange={(e) => setcategory(e.target.value)}
                />
                <p>Medicine</p>
              </div>
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <input
                  type="radio"
                  name="category"
                  value="Other"
                  onChange={(e) => setcategory(e.target.value)}
                />
                <p>Other</p>
              </div>
            </div>
          </div>
          <div className="create-blog-sub-div-two">
            <h3>Summary : </h3>
            <textarea
              placeholder="Summary..."
              className="summary-texarea-in-create-blog"
              maxLength="200"
              value={summary}
              onChange={(e) => setsummary(e.target.value)}
            />
            <p
              style={{
                color: "gray",
                fontSize: "10px",
                marginTop: "-5px",
                paddingLeft: "12px",
              }}
            >
              *Maxlength is 200 Characters
            </p>
          </div>
          <div className="create-blog-sub-div-two">
            <h3>Image : </h3>
            <input type="file" className="image-upload-in-create-blog" />
          </div>
          <div className="create-blog-sub-div-two">
            <h3>Blog : </h3>
            <textarea
              placeholder="Write your blog here..."
              className="blog-texarea-in-create-blog"
              value={overview}
              onChange={(e) => setoverview(e.target.value)}
            />
          </div>
          <div className="add-pat-btn-div">
            <button className="read-more-btn" onClick={createblog}>
              POST YOUR BLOG
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorCreateBlog;
