import React from 'react'

const AccessibilityPolicy = () => {
  return (
    <div className="terms-and-condn-flex-div">
    <div className="terms-and-condn-width-div">
      <div className="terms-and-condn-main-div">
        <div className="terms-and-condn-main-div-one">
          <h3>Accessibility Policy </h3>
        </div>
        <div className="terms-and-condn-main-div-two">
            <p>
            We haves an ongoing commitment to achieving the highest levels of satisfaction 
            for users of this Platform. As part of these efforts, we are dedicated to offering 
            full and equal enjoyment of our Platform to users with disabilities.
            </p><br/>
            <p>
            To assist in achieving the aforementioned accessibility 
            goal with respect to the Platform, we have committed to the Platform being designed, 
            developed, and operated in accordance with generally recognized and accepted guidelines and 
            standards for website accessibility (the "Standards"). While these Standards may change or evolve over time, 
            they are currently at least the World Wide Web Consortium's Web Content Accessibility Guidelines 2.1 at Levels 
            A and AA ("WCAG 2.1").
            </p><br/>
          <p>
          [Below are some of the steps we are taking under this website accessibility policy:
          ______]
          </p><br/>
          <p>
          While we strive to make our Platform as accessible as possible, our pages may not always 
          pass online validation tools due to aspects of the technology used to manage this Platform, 
          but we regularly monitor these sections, 
          and look to develop accessible solutions as technology improve
          </p><br/>
          <p>
          We're always looking to improve the accessibility of this Platform. If you find any problems not listed on this 
          page or think we're not meeting accessibility requirements please let us know by sending the online form or an email 
          to our address set forth in the "Contact Us" section of the Platform.
          </p>
        </div>
      </div>
    </div>
  </div>
  )
}

export default AccessibilityPolicy