import {
  QUIZZ_LIST_REQUEST,
  QUIZZ_LIST_SUCCESS,
  QUIZZ_LIST_FAIL,
  QUIZZ_POST_REQUEST,
  QUIZZ_POST_SUCCESS,
  QUIZZ_POST_FAIL,
} from "../Constants/Task-constants";

import { BACKEND_URL } from "../../environment";
import axios from "axios";

export const quizListAction = (params) => async (dispatch) => {
  try {
    dispatch({
      type: QUIZZ_LIST_REQUEST,
    });

    const { data } = await axios.get(`${BACKEND_URL}get-quiz/`);
    //filter data as per category where category is not equal to 3
    const filteredData = data.filter((item) => item.category !== 3);

    // // // //console.log("Actin-----",data)
    dispatch({
      type: QUIZZ_LIST_SUCCESS,
      payload: filteredData,
    });

    return true;
  } catch (error) {
    dispatch({
      type: QUIZZ_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const quizPostAction = (params) => async (dispatch) => {
  try {
    dispatch({
      type: QUIZZ_POST_REQUEST,
    });

    const { data } = await axios.post(`${BACKEND_URL}save-quiz/`, params);
    dispatch({
      type: QUIZZ_POST_SUCCESS,
      payload: data,
    });

    return true;
  } catch (error) {
    dispatch({
      type: QUIZZ_POST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
