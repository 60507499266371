import React from "react";
import { Link, useNavigate } from "react-router-dom";

const ThankYou = () => {
  const navigatetwo = useNavigate()
  return (
    <>
      <div className=" w-full bg-blue-100 relative h-screen	">
        <main>
          <div className="">
            <div className="">
              <div class="flex items-center justify-center h-screen">
                <div class="bg-indigo-800 text-white font-bold rounded-lg border shadow-lg p-10">
                  <div className="heading-top text-center text-3xl mb-5 pt-10">
                    <h2>Thank You</h2>
                  </div>
                  <div className=" mb-12">
                    <div className="heading-top text-center text-md mb-12">
                      <p>Your quizz have successfully submitted.</p>
                    </div>
                    <div class="text-center mt-4 thankyoudiv">
                      <Link
                        className="bg-blue-500 hover:bg-blue-700 text-white  py-2 px-4 rounded mb-20"
                        // to="/"
                        onClick={() => navigatetwo(-1)}
                      >
                        Back to Quizz
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default ThankYou;
