import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Loader from "../Loader/Loader";
import swal from "sweetalert";

const Que = (props) => {
  return (
    <div>
      <div className="questo-form-quesyion-ans-main-div-for-main">
      <div className="questo-form-questions-wrapper" style={{ width: "55%", cursor:"pointer" }}>
      <div className="questo-form-questions-div" >
          <p className="question-text">{props.number}. {props.item.question}</p>
        </div>
        <div className="tooltip">More information</div>
        </div>
        <div className="response-div">
        <div className="questo-form-options-div" style={{ width: "15%" }}>
        <p classname="label-for-response" style={{color: '#88869d', marginBottom: '30px'}}>Response: </p>
          <p style={{ textAlign: "center" }}>{props.item.patient_response1}</p>
        </div>
        <div
          className="questo-form-details-div-in-pat"
          style={{ width: "30%" }}
        >
          <p classname="questo-new-label-for-pat-que" style={{color: '#88869d'}}>Details: </p>
          <div className="questo-form-input-tag-for-patient">
            <p>{props.item.response_details1}</p>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

const QueUpdate = (props) => {
  return (
    <div>
      <div className="questo-form-quesyion-ans-main-div-for-main">
      <div className="questo-form-questions-wrapper" style={{ width: "55%", cursor:"pointer" }}>
      <div className="questo-form-questions-div" >
      <p className="question-text">{props.number}. {props.item.question}</p>
      {props.item.mandatory === "Yes" ? (
              <span style={{ color: "red" }}> *</span>
            ) : null}
        </div>
        <div className="tooltip">More information</div>
        </div>
        <div className="response-div">
        <div className="questo-form-options-div" style={{ width: "15%" }}>
        <p classname="label-for-response" style={{color: '#88869d', marginBottom: '30px'}}>Response: </p>
          <div style={{ display: "flex", gap: "9px" }}>
            <input
              className="add-patient-input-field-of-radio"
              name={props.item.response_id1}
              type="radio"
              value="Yes"
              onChange={(event) =>
                props.radioButtonHandler({
                  que_id: props.item.response_id1,
                  val: event.target.value,
                })
              }
            />
            <p style={{ fontSize: "13px" }}>Yes</p>
            <input
              className="add-patient-input-field-of-radio"
              name={props.item.response_id1}
              type="radio"
              value="No"
              onChange={(event) =>
                props.radioButtonHandler({
                  que_id: props.item.response_id1,
                  val: event.target.value,
                })
              }
            />
            <p style={{ fontSize: "13px" }}>No</p>
          </div>
        </div>
        <div
          className="questo-form-details-div-in-pat"
          style={{ width: "30%" }}
        >
          <p classname="questo-new-label-for-pat-que" style={{color: '#88869d'}}>Details: </p>
          <textarea
            className="questo-form-input-tag-for-patient"
            type={"text"}
            placeholder="Details..."
            onChange={(event) =>
              props.detailsHandler({
                que_id: props.item.response_id1,
                val: event.target.value,
              })
            }
          />
        </div>
        </div>
      </div>
    </div>
  );
};

const QuestionnaireOther = () => {
  const [questionetailsget, setquestionetailsget] = useState([]);
  const [loading, setLoading] = useState(true);
  // const UsernameofPatient = localStorage.getItem("Username");
  const is_patient = localStorage.getItem("is_patient")

  const is_doctor = localStorage.getItem("is_doctor");
  const UsernameofPatient =
    is_patient == "true"
      ? localStorage.getItem("Username")
      : is_doctor == "true"
      ? localStorage.getItem("patientUserName")
      : localStorage.getItem("Username");

//   const UsernameofPatient = 
//   is_patient == "true" ?
//   localStorage.getItem("Username")
// : localStorage.getItem("patientUserName")
  const childRef = useRef();
  const [isUpdateActive, setisUpdateActive] = useState(false);

  const OpenUpdateHandler = () => {
    setisUpdateActive(true);
  };

  const CloseUpdateHandler = () => {
    setisUpdateActive(false);
  };

  useEffect(() => {
    axios
      .get(
        `https://djangoobesitychat.azurewebsites.net/patient/response/get/Other/${UsernameofPatient}/`,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("SavedToken")}`,
          },
        }
      )

      .then((response) => {
        const data = response.data.map((entry) => {
          return Object.assign({}, entry, {
            // patient_name: localStorage.getItem("Username"),
            patient_name: UsernameofPatient,
            response_id1: entry.response_id1,
          });
        });
        setquestionetailsget(data);
        setLoading(false);
      })
      .catch((err) => {});
  }, []);

  const radioButtonHandler = (param) => {
    const newResponse = questionetailsget.map((item) => {
      if (item.response_id1 == param.que_id) {
        item.patient_response1 = param.val;
      }
      return item;
    });
    setquestionetailsget(newResponse);
  };

  const detailsHandler = (param) => {
    const newResponse = questionetailsget.map((item) => {
      if (item.response_id1 == param.que_id) {
        item.response_details1 = param.val;
      }
      return item;
    });
    setquestionetailsget(newResponse);
  };

  const [submitLoading, setsubmitLoading] = useState(false);

  const submitHandler = (props) => {
    // const UsernameofPatient = localStorage.getItem("Username");
    const test = questionetailsget;
    setsubmitLoading(true);

    axios
      .put(
        `https://djangoobesitychat.azurewebsites.net/patient-response/bulk-update/`,
        questionetailsget
      )
      .then((res) => {
        // //console.log("res", res);
    setsubmitLoading(false);

        swal({
          title: "Your Response has been Updated Successfully",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });
        CloseUpdateHandler();

        // //console.log("res", res);
      })
      .catch((err) => {
        // //console.log("err", err);
    setsubmitLoading(false);

      });
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>
        {submitLoading && (
          <div className="purchase-order-details-pop-up-main-div">
            <div className="purchase-order-details-pop-up-sub-div">
              <div
                className="purchase-order-details-pop-up-sub-div-container"
                style={{ background: "none" }}
              >
                <Loader />
              </div>
            </div>
          </div>
        )}
          <div style={{ display: "flex", justifyContent: "center" }}>
            {isUpdateActive == true ? (
              <button
                className="submit-btn-in-que"
                style={{ width: "175px" }}
                onClick={CloseUpdateHandler}
              >
                Discard Changes
              </button>
            ) : (
              <button
                className="submit-btn-in-que"
                style={{ width: "175px" }}
                onClick={OpenUpdateHandler}
              >
                Update Your Response
              </button>
            )}
          </div>
          < hr />
          {/* <div className="questo-form-quesyion-ans-main-div-for-main">
            <div
              className="questo-form-questions-div"
              style={{ width: "55%", justifyContent: "center" }}
            >
              <h3>Questions</h3>
            </div>
            <div
              className="questo-form-options-div"
              style={{ width: "15%", justifyContent: "center" }}
            >
              <h3 style={{ textAlign: "center" }}>Response</h3>
            </div>
            <div
              className="questo-form-details-div-in-pat"
              style={{ width: "30%", justifyContent: "center" }}
            >
              <h3>Details</h3>
            </div>
          </div> */}
          {questionetailsget.map((item, index) => {
            return (
              <div>
                {isUpdateActive == true ? (
                  <QueUpdate
                    key={item.question_id}
                    item={item}
                    number={index + 1}
                    ref={childRef}
                    detailsHandler={detailsHandler}
                    radioButtonHandler={radioButtonHandler}
                  />
                ) : (
                  <Que
                    key={item.question_id}
                    item={item}
                    number={index + 1}
                    // ref={childRef}
                    // detailsHandler={detailsHandler}
                    // radioButtonHandler={radioButtonHandler}
                  />
                )}
              </div>
            );
          })}
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "0px",
        }}
      >
        {isUpdateActive == true ? (
          <button style={{marginTop: '4px'}} onClick={submitHandler} className="submit-btn-in-que">
            Submit
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default QuestionnaireOther;
