import React, { useEffect, useState} from "react";
import "./ClinicDoctorList.css";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { FaFilter } from "react-icons/fa";
import { AiFillAppstore } from "react-icons/ai";
import Add from "@mui/icons-material/Add";
import PatientLogo from "../../Images/ClinicSVG/newdoctor.svg";
import Loader from "../Loader/Loader";
import { Link, useNavigate } from "react-router-dom";
import Apiservice from "../../Services/Apiservice";

const ClinicDoctorList = () => {
  const [doctordetails, setdoctordetails] = useState([]);
  const savedtoken = localStorage.getItem("SavedToken");

  const [loading, setLoading] = useState(true);
  const clinciID = localStorage.getItem("ClinicIDForHomepage");
  const SubClinicIDForHomepage = localStorage.getItem("SubClinicIDForHomepage");

  const navigate = useNavigate();

  useEffect(() => {
    Apiservice
      .get(
        clinic == "true" ?
        `/user/doctor/clinic/${clinciID}/`
        :
        `/user/doctor/subclinic/${SubClinicIDForHomepage}/`
        
        , {
        headers: {
          Authorization: `Token ${savedtoken}`,
        },
      })

      .then((response) => {
        setdoctordetails(response.data);
        // // //console.log("ASD", response.data);
        setLoading(false);
      })
      .catch((err) => {});
  }, []);
  if (!doctordetails) return null;

  function generateRandom() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  const columns = [
    {
      field: "doctor_id",
      headerName: "ID",
      width: 90,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        const handleClick = () => {
          navigate(`/clinicsidebar/adddoctordetails`);
          localStorage.setItem("DoctorUserNameDuringRegistration", params.row.doctor_name);
        };
        
  
        return (
          <a href={`/clinicsidebar/adddoctordetails`} onClick={handleClick}>
            <div style={{ cursor: "pointer" }}>
              {params.row.doctor_id}
            </div>
          </a>
        );
      },
    },

    {
      field: "doctor_firstName",
      headerName: "First name",
      flex: 1,
      minWidth: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "doctor_lastName",
      headerName: "Last name",
      flex: 1,
      minWidth: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "disease_specialist",
      headerName: "Specialist",
      flex: 1,
      minWidth: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "doctor_age",
      headerName: "Age",
      //   type: "number",
      width: 110,
      headerClassName: "super-app-theme--header",
      //   editable: true,
      //   align:"center"
    },
    {
      field: "doctor_gender",
      headerName: "Gender",
      flex: 1,
      minWidth: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "doctor_mobile",
      headerName: "Mobile",
      flex: 1,
      minWidth: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "doctor_email",
      headerName: "Email",
      width: 220,
      headerClassName: "super-app-theme--header",
    },
  ];

  const clinic = localStorage.getItem("is_clinic");

  return (
    <div>
      <div className="paient-list-top-main-flex-div">
        <div className="paient-list-top-main-width-div">
          <div
            className="paient-list-top-main-div"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="page-header-main-div">
              <p>Doctor List</p>
            </div>

            <div
              className="table-page-middle-nav-main-div"
              style={{ paddingBottom: "0px" }}
            >
              <div>
                {clinic == "true" ? (
                  <Link to="/clinicsidebar/doctorregistration">
                    <button className="add-btn-btn-tag">
                      <Add style={{ height: "20px", width: "20px" }} />
                      Add New Doctor
                    </button>
                  </Link>
                ) : (
                  <Link to="/subclinicsidebar/doctorregistration">
                    <button className="add-btn-btn-tag">
                      <Add style={{ height: "20px", width: "20px" }} />
                      Add New Doctor
                    </button>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div style={{ padding: "15px" }}>
          <Box
            sx={{
              height: 600,
              width: "100%",
              "& .super-app-theme--header": {
                backgroundColor: "rgb(49, 163, 214)",
              },
            }}
          >
            <DataGrid
              rows={doctordetails}
              columns={columns}
              // pageSize={5}
              // rowsPerPageOptions={[5]}
              experimentalFeatures={{ newEditingApi: true }}
              getRowId={(row) => generateRandom()}
            />
          </Box>
        </div>
      )}
    </div>
  );
};

export default ClinicDoctorList;
