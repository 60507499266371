// src/BodyMap.js
import React, { useState } from 'react';
import './BodyMap.css';

const BodyMap = ({ data }) => {
    const diseaseString = data?.[0]?.background_disease;
    const diseaseArray = JSON.parse(diseaseString.replace(/'/g, '"'));
    const drugInteractions = data?.[0]?.drug_interaction_match_list;
    const drugInteractionsArray = JSON.parse(drugInteractions.replace(/'/g, '"'));
    const contraindications = data?.[0]?.contraindications_match_list;
    const contraindicationsArray = JSON.parse(contraindications.replace(/'/g, '"'));
    const warnings = data?.[0]?.warnings_and_precautions_match_list;
    const warningsArray = JSON.parse(warnings.replace(/'/g, '"'));


    return (
        <>
            <div className="mycard">
                <div className='innercard'>
                    <div className="frontside">
                    <p className="title">Disease List</p>
                    <ul className="content">
                    {diseaseArray.length > 0 ? (
                            <ul>
                                {diseaseArray.map((disease, index) => (
                                    <li style={{listStyle: 'none', margin: 0, padding: 0}} key={index}>{disease}</li>
                                ))}
                            </ul>
                        ) : (
                            <p>No reported Diseases</p>
                        )}
                    </ul>
                    </div>
                    <div className="backside">
                    <ul className="content">
                        <div style={{marginLeft:'4px'}}>
                        <h3>Drug Interactions</h3>
                        <hr />
                        {drugInteractionsArray.length > 0 ? (
                            <ul>
                                {drugInteractionsArray.map((disease, index) => (
                                    <li style={{listStyle: 'none', margin: 0, padding: 0}} key={index}>{disease}</li>
                                ))}
                            </ul>
                        ) : (
                            <p>No drug interactions found</p>
                        )}
                        </div>
                        <div style={{marginLeft:'4px'}}> 
                        <h3>Contraindications</h3>
                        <hr style={{marginTop: '28px'}} />
                        {contraindicationsArray.length > 0 ? (
                            <ul>
                                {contraindicationsArray.map((disease, index) => (
                                    <li style={{listStyle: 'none', margin: 0, padding: 0}} key={index}>{disease}</li>
                                ))}
                            </ul>
                        ) : (
                            <p>No Contraindications found</p>
                        )}
                        </div>
                        <div style={{marginLeft:'4px'}}> 
                        <h3>Warnings & Precautions</h3>
                        <hr  />
                        {warningsArray.length > 0 ? (
                            <ul>
                                {warningsArray.map((disease, index) => (
                                    <li style={{listStyle: 'none', margin: 0, padding: 0}} key={index}>{disease}</li>
                                ))}
                            </ul>
                        ) : (
                            <p>No Warnings and Precautions found</p>
                        )}
                        </div>
                    </ul>
                    </div>
                    
                   
                </div>
            </div>
        </>
    );
};


export default BodyMap;
