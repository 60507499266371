import React, {useEffect, useState} from 'react';
import GraphComponent from './GraphComponentByClinic';
import axios from 'axios';
import MainDashboard from './MainDashboard';
import MailNotification from './MailNotification';

const CustomerSuccessByClinic = () => {
  const [mailNotification, setMailNotification] = useState(true);
  const [mainDashboard, setMainDashboard] = useState(false);
  const ClinicIDForHomepage = localStorage.getItem('ClinicIDForHomepage');
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);


    // const data =  [
    //         {
    //           "patient_name": "demo_dietitian",
    //           "answered_percentage": 0
    //         },
    //         {
    //           "patient_name": "conda",
    //           "answered_percentage": 0
    //         },
    //         {
    //           "patient_name": "demo_patient",
    //           "answered_percentage": 13.740458015267176
    //         }
    //       ]
        
    const mainDashboardHandler = () => {
        setMainDashboard(true);
        setMailNotification(false);
    }

    const mailNotificationHandler = () => {
        setMailNotification(true);
        setMainDashboard(false);
    }

    

    useEffect(() => {
        const fetchData = async () => {
          const result = await axios.get(`https://djangoobesitychat.azurewebsites.net/patientresponse_categorize_by_clinic/${ClinicIDForHomepage}/`,
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("SavedToken")}`,
            },
          },
          );
          setData(result.data);
        };
        setLoading(false);
        fetchData();
      }, [ClinicIDForHomepage]);

    const patientsdata = data ? Object?.keys(data).reduce((acc, key) => {
        const patients = data[key].patients.map(patient => ({
            patient_name: patient.patient_name,
            answered_percentage: patient.answered_percentage
        }));
        return [...acc, ...patients];
    }, []) : [];


      //console.log(patientsdata)
    // if (loading) return 'Loading...';   
    if (!data) return null;


  return (
    
    <div>
      <div className="health-overview-sub-one-flex">
                <p
                  className={mainDashboard ? "bg-salmon" : ""}
                  onClick={mainDashboardHandler}
                >
                  Dashboard
                </p>

                <p
                  className={mailNotification ? "bg-salmon" : ""}
                  onClick={mailNotificationHandler}
                >
                  Mail Notification settings
                </p>
               
              </div>
              {mainDashboard && <MainDashboard />}
              {mailNotification && <MailNotification />}
              {/*{PatientHistoryPage && <PatientHistory />}*/}
            
      
     
    
    </div>
    
  );
};

export default CustomerSuccessByClinic;