import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from "react";
import axios from "axios";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "./ClinicPrescriptionCard.css";
import PrescriptionView from "../PrescriptionNew/PrescriptionView";
import Apiservice from "../../Services/Apiservice";

const ClinicPrescriptionCard = () => {
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const [selectedFile, setSelectedFile] = React.useState(null);
  const clinicID = localStorage.getItem("ClinicIDForHomepage");
  const UserIdtoDetDetails = localStorage.getItem("UserIdtoDetDetails");
  const clinic = localStorage.getItem("is_clinic");
  const subclinic_id = localStorage.getItem("SubClinicIDForHomepage");

  const [patientdetailsinaddpatient, setpatientdetailsinaddpatient] = useState(
    []
  );
  const [opentwo, setOPentwo] = useState(false);
  const toggletwo = () => {
    setOPentwo(!opentwo);
  };

  const patientusernameforprescription = localStorage.getItem(
    "patientUserName"
  );

  // SEARCH PATIENT

  const PatientURL =
    clinic == "true"
      ? `/user/patient/clinic/${clinicID}/`
      : `/user/patient/subclinic/${subclinic_id}/`;

  useEffect(() => {
    const loadPosts = async () => {
      setLoading(true);

      const response = await Apiservice.get(PatientURL);
      setPosts(response.data);
      setLoading(false);
      if (UserIdtoDetDetails) {
      Apiservice
        .get(
          `/user/patientapi/${patientusernameforprescription}/`
        )
        .then((response) => {
          setpatientdetailsinaddpatient(response.data);
        });

    }
    };
    loadPosts();
  }, []);

  // GET SEARCHED PATIENT DETAILS
  useEffect(() => {
    if (patientusernameforprescription) {
    Apiservice
      .get(`user/patientapi/${patientusernameforprescription}/`)
      .then((response) => {
        setpatientdetailsinaddpatient(response.data);
      })
      .catch((err) => {});
  }
  },
   
  [patientusernameforprescription]);

  const patientIDforPrescription = localStorage.getItem("patientUserName");

  

  return (
    <div className="clinic-docs-flex-div">
      <div className="clinic-docs-width-div">
        <div className="clinic-docs-main-div">
          <div className="appointment-by-clini-sub-div-one">
            <h4>Prescription</h4>
          </div>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              gap: "10px",
              width: "100%",
              alignItems: "center",
              paddingTop: "25px",
              paddingLeft: "150px",
              paddingRight: "150px",
              justifyContent: "center",
            }}
          >
            <div
              className="patient-details-in-appointment-sub-div-two-part-one"
              style={{ width: "20%" }}
            >
              <h4>Search Patient :</h4>
            </div>
            <div
              className="patient-details-in-appointment-sub-div-two-part-one-two"
              style={{ width: "100%" }}
            >
              <input
                type="text"
                placeholder="Search Patient..."
                className="search-patient-in-appt-inp-tag"
                onClick={toggletwo}
                onChange={(e) => setSearchTitle(e.target.value)}
              />
              {opentwo && (
                <div className="list-of-all-user" onClick={toggletwo}>
                  {loading ? (
                    <div style={{ display: "none" }}></div>
                  ) : (
                    posts
                      .filter((value) => {
                        if (searchTitle === "") {
                          // return value;
                        } else if (
                          value.patient_name
                            ?.toLowerCase()
                            .includes(searchTitle?.toLowerCase())
                        ) {
                          return value;
                        }
                      })
                      .map((item) => (
                        <div className="list-of-user-main-flex-div">
                          <div className="list-of-user-main-div">
                            <p
                              onClick={() => (
                                localStorage.setItem(
                                  "UserIdtoDetDetails",
                                  item.id
                                ),
                                localStorage.setItem(
                                  "patientUserName",
                                  item.patient_name
                                )
                              )}
                            >
                              {item.patient_name}
                            </p>
                          </div>
                        </div>
                      ))
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            width: "100%",
            alignItems: "center",
            paddingTop: "25px",
            paddingLeft: "150px",
            paddingRight: "150px",
            justifyContent: "center",
          }}
        >
          <div
            className="events-patient-details-main-div"
            style={{ width: "100%" }}
          >
            <div style={{ width: "22%" }}>
              <h4>Patient Name :</h4>
            </div>
            <div
              className="user-details-get-main-div"
              style={{ width: "100%" }}
            >
              <p>
                {patientdetailsinaddpatient?.firstName}{" "}
                {patientdetailsinaddpatient?.lastName}
              </p>
            </div>
          </div>
        </div>

        <div>
        {patientusernameforprescription ?
          <PrescriptionView patientusernameforprescription = {patientusernameforprescription}/>
          : ""
        }
        </div>
      </div>
    </div>
  );
};

export default ClinicPrescriptionCard;
