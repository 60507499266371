import {
  QUIZZ_POST_REQUEST,
  QUIZZ_POST_SUCCESS,
  QUIZZ_POST_FAIL,
  QUIZZ_LIST_REQUEST,
  QUIZZ_LIST_SUCCESS,
  QUIZZ_LIST_FAIL,
} from "../Constants/Task-constants";

export const QuizzListReducer = (state = {}, action) => {
  switch (action.type) {
    case QUIZZ_LIST_REQUEST:
      return { loading: true };
    case QUIZZ_LIST_SUCCESS:
      return { loading: false, success: true, questionlist: action.payload };
    case QUIZZ_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const QuizzPostReducer = (state = {}, action) => {
  switch (action.type) {
    case QUIZZ_POST_REQUEST:
      return { loading: true };
    case QUIZZ_POST_SUCCESS:
      return { loading: false, success: true, questionpost: action.payload };
    case QUIZZ_POST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
