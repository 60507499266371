import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Loader from "../Loader/Loader";
import Apiservice from "../../Services/Apiservice";
import swal from "sweetalert";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PreviewIcon from '@mui/icons-material/Preview';

const DoctorPatientDocuments = () => {
  const [patientuserdetails, setpatientuserdetails] = useState([]);
  const patientUserName = localStorage.getItem("patientUserName");
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [testname, settestname] = React.useState("");
  const [submitLoading, setsubmitLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setsubmitLoading(true);
    const formData = new FormData();
    formData.append("med_docs", selectedFile && selectedFile);
    formData.append("customeuser_username", patientUserName);
    formData.append("test_name", testname);
    const medDocsField = formData.get("med_docs");
    // // //console.log(medDocsField);
    try {
      const response = await axios({
        method: "post",
        url: `https://djangoobesitychat.azurewebsites.net/medicaltestapi/${patientUserName}/`,
        data: formData,
      });
      swal({
        title: "Your Medical Test Documents Submitted Successfully",
        className: "successAlert",
        buttons: true,
        timer: 3000,
      });
      setsubmitLoading(false);

      Apiservice
        .get(`/medicaltestapi/${patientUserName}/`)
        .then((response) => {
          setpatientuserdetails(response.data);
        });
    } catch (error) {
      // // //console.log(error);
      setsubmitLoading(false);
    }
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleinputSelect = (event) => {
    settestname(event.target.value);
  };

  useEffect(() => {
    Apiservice
      .get(`/medicaltestapi/${patientUserName}/`)

      .then((response) => {
        setLoading(false);
        setpatientuserdetails(response.data);
      })
      .catch((err) => {});
  }, []);

  const handleClick = (key) => {
    // // // //console.log(key);
    localStorage.setItem("MedDocID", key);
    handleClickOpen();
  };
  const [popup, setPop] = useState(false);
  const handleClickOpen = () => {
    setPop(!popup);
  };
  const MedDocID = localStorage.getItem("MedDocID");

  const [showDocuments, setshowDocuments] = useState(false);
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState("")
  const handleButtonClick = (url) => {
    setSelectedDocumentUrl(url.slice(27));
    setshowDocuments(true);
  };

  const handleButtonClickClose =()=>{
    setshowDocuments(false)
  }

  const DownloadURL = `https://djangoobesitychat.azurewebsites.net/filedownload/${MedDocID}/`;
  return (
    <div>

    <div
      style={{
        height: "140vh",
        overflowY: "scroll",
        boxShadow: "rgb(0 0 0 / 35%) 0px 5px 15px",
        padding: "10px",
        borderRadius: "10px",
        marginTop: "20px",
      }}
    >
      <div>
        {loading ? (
          <Loader />
        ) : (
          
          <div>
            <div>
        <form onSubmit={handleSubmit}>
          <div
            style={{
              paddingLeft: "100px",
              paddingRight: "100px",
              paddingTop: "25px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <h4 style={{ width: "50%" }}>Test Name</h4>
              <input
                placeholder="Test Name..."
                style={{ width: "50%" }}
                className="test-name-info-div-one-input"
                onChange={handleinputSelect}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "50px",
                alignItems: "center",
                width: "100%",
                paddingTop: "15px",
                paddingBottom: "20px",
              }}
            >
              <h4 style={{ width: "50%" }}>Documents</h4>
              <div style={{ width: "50%", display: "flex" }}>
                <input type="file" onChange={handleFileSelect} />
                <input type="submit" value="Upload File" />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
            {patientuserdetails.map((index) => (
              <div className="test-doc-details-table">
                <div className="test-table-doc-sub-div">{index.test_name}</div>
                <div className="test-table-doc-sub-div">
                  <button
                    className="update-btn-btn-tag"
                    onClick={() => handleClick(index.id)}
                  >
                    <FileDownloadIcon />
                  </button>
                </div>
                <div className="test-table-doc-sub-div">
                <button
                  className="update-btn-btn-tag"
                  onClick={() => handleButtonClick(index.med_docs)}
                >
                  <PreviewIcon />
                </button>
              </div>

              {showDocuments && (
                <div className="purchase-order-details-pop-up-main-div">
                  <div className="purchase-order-details-pop-up-sub-div">
                    <div
                      className="purchase-order-details-pop-up-sub-div-container"
                      style={{ background: "none" }}
                    >
                      <div style={{ display: "block" }}>
                        <div>
                          <button   className="update-btn-btn-tag" onClick={handleButtonClickClose}>Close</button>
                        </div>
                        <div>
                          <iframe
                            src={`https://djangoobesitychat.azurewebsites.net${selectedDocumentUrl}`}
                            title="Patient Documents"
                            width="600px"
                            height="500px"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              </div>
            ))}
            </div>
          </div>
        )}
    </div>
      
      
    </div>
    {popup ? (
      <div className="msg-download-popup">
        <p>Do You Want to Download Selected File ?</p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <a href={DownloadURL}>
            <button
              className="download-confirm-btn-tag"
              style={{ color: "white", backgroundColor: "rgb(49,163,214)" }}
              onClick={handleClickOpen}
            >
              Yes
            </button>
          </a>
          <button
            onClick={handleClick}
            className="download-confirm-btn-tag"
            style={{ color: "white", backgroundColor: "rgb(49,163,214)" }}
          >
            No
          </button>
        </div>
      </div>
    ) : (
      ""
    )}
    </div>

  );
};

export default DoctorPatientDocuments;
