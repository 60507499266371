import React, { useEffect, useRef, useState } from "react";

import { Line, getDatasetAtEvent, getElementAtEvent } from "react-chartjs-2";
import { Chart as ChartJs, Title, Tooltip, Legend } from "chart.js";
import axios from "axios";
import moment from "moment";

ChartJs.register(Title, Tooltip, Legend);

const WeightForcasting = (props) => {
  const chartRef = useRef();

  const options = {
    scales: {
      x: {
        type: 'linear',
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  };


  const [futureweights, setfutureweights] = useState("");
  
  const [currentweights, setcurrentweights] = useState("");
  // IF DOCTOR LOGIN
  const patientUserName = localStorage.getItem("patientUserName");
  // IF PATIENT LOGIN
  const Username = localStorage.getItem("Username");
  const is_patient = localStorage.getItem("is_patient");

  useEffect(() => {
    axios
      .get(
        is_patient == "true"
          ? `https://djangoobesitychat.azurewebsites.net/user/futureweights/${Username}/90/`
          : `https://djangoobesitychat.azurewebsites.net/user/futureweights/${patientUserName}/90/`,
        {
          headers: {  
            Authorization: `Token ${localStorage.getItem("SavedToken")}`,
          },
        }
      )

      .then((response) => {
        setfutureweights(response.data);
        const appointments = response.data["my_patients"];
      })
      .catch((err) => {});
  }, []);


          // const today = new Date();
          // const isoString = today.toISOString();   
          // const splitDate = isoString;         
          


          useEffect(() => {
            axios
              .get(
                is_patient == "true"
                  ? `https://djangoobesitychat.azurewebsites.net/user/allwighthistory/${Username}/`
                  : `https://djangoobesitychat.azurewebsites.net/user/allwighthistory/${patientUserName}/`, {
                    headers: {
                      Authorization: `Token ${localStorage.getItem("SavedToken")}`,
                    },
                  }
              )
        
              .then((response) => {
                setcurrentweights(response.data);
                // const appointments = response.data["my_patients"];
              })
              .catch((err) => {});
          }, []);

          const CurrentWeightData = currentweights&&currentweights.map(index =>
              index.current_weight
          )

          const CurrentWeightDateData = currentweights && currentweights.map(index => index.date)
          const localizedDates1 = CurrentWeightDateData&&CurrentWeightDateData.map(date => new Date(date).toLocaleDateString('en-CA'));
          const ForecastDates = futureweights && futureweights.forecast_dates
          const localizedDates = ForecastDates&&ForecastDates.map(date => new Date(date).toLocaleDateString('en-CA'));
          //console.log("CurrentWeightDateData",localizedDates1)
          //console.log("ForecastDates",localizedDates)

          const today = new Date();
          const currentDateString = today.toISOString().split('T')[0];
          const currentDate = moment(currentDateString);

          const combinedDates = [...(localizedDates1 ?? []), ...(localizedDates ?? [])]
          // const filteredDates = combinedDates.filter(date => moment(date).isAfter(currentDate));
          const sortedDates = combinedDates.sort();
          const firstDate = moment(sortedDates[0]);

          const weekNumbers = combinedDates.map(date => {
            const currentDate = moment(date);
            return currentDate.diff(firstDate, 'weeks') + 1;
          })
          // //console.log("currentweights",CurrentWeightData)
          // //console.log("CurrentWeightDateData",CurrentWeightDateData)
          const ForcastWeight = futureweights&&futureweights.forecast_weights
          // const ForecastDates = futureweights&&futureweights.forecast_dates
          // const localizedDates = ForecastDates&&ForecastDates.map(date => new Date(date).toLocaleDateString('en-CA'));
          // const combinedDates = [...CurrentWeightDateData, ...localizedDates]
          // const sortedDates = combinedDates.sort();
          // const firstDate = moment(sortedDates[0]);
          // const weekNumbers = combinedDates.map(date => {
          //   const currentDate = moment(date);
          //   return currentDate.diff(firstDate, 'weeks') + 1;
          // });
          let weightDataByWeek = {};

          combinedDates.forEach((date, i) => {
          const weekNum = weekNumbers[i];
          if (!weightDataByWeek[weekNum]) {
            weightDataByWeek[weekNum] = [];
            }
            weightDataByWeek[weekNum].push(CurrentWeightData[i]);
            });

          let forecastDataByWeek = {};

          combinedDates.forEach((date, i) => {
            const weekNum = weekNumbers[i];
            if (!forecastDataByWeek[weekNum]) {
              forecastDataByWeek[weekNum] = [];
            }
            forecastDataByWeek[weekNum].push(ForcastWeight[i]);
          });

          const averageCurrentWeightByWeek = Object.entries(weightDataByWeek).map(([week, weights]) => {
            const avgWeight = weights.reduce((a, b) => a + b, 0) / weights.length;
            return {x: parseInt(week), y: avgWeight};
          });
          //console.log("averageCurrentWeightByWeek", averageCurrentWeightByWeek)
          // //console.log("forecastDataByWeek", forecastDataByWeek)
          
          const averageForecastWeightByWeek = Object.entries(forecastDataByWeek).map(([week, weights]) => {
            const avgWeight = weights.reduce((a, b) => a + b, 0) / weights.length;
            return {x: parseInt(week), y: avgWeight};
          });
          //console.log("averageForecastWeightByWeek", averageForecastWeightByWeek)
          const combinedWeekList = weekNumbers.map((week, i) => {
            return {x: week, y: CurrentWeightData[i]};
          });
          
          const combinedWeekList1 = weekNumbers.map((week, i) => {
            return {x: week, y: ForcastWeight[i]};
          });

          const combinedList = localizedDates&&localizedDates.map((x, i) => {
            return {x: x, y: ForcastWeight[i]};
          });
          const combinedList1 = CurrentWeightDateData&&CurrentWeightDateData.map((x, i) => {
            return {x: x, y: CurrentWeightData[i]};
          });
          // //console.log("ForcastWeight",combinedList)
  const lenC = averageCurrentWeightByWeek&&averageCurrentWeightByWeek.length
  const combinedList2 = [...combinedWeekList1, ...combinedWeekList]
  // //console.log(combinedList2)
  

  const data = {
    // labels: localizedDates,
    datasets: [
      {
        label: "Actual Weight",
        data: averageCurrentWeightByWeek,
        fill: true,
        borderColor: "rgb(185,213,255)",
      },
      {
        label: "Forecast Weight",
        data: averageForecastWeightByWeek,
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgb(255,196,66)",
      },
      
    ],
  };
    // const data = {
    // labels: localizedDates,
    // datasets: [
    //     {
    //       fill: false,
    //       label: 'Page View',
    //       borderColor: "hsl(226.15793242887034,78.48665583019744%,62.177112879909686%)",
    //       data: combinedList
    //       },
    //       {
    //         fill: false,
    //         label: 'Page View',
    //         borderColor: "hsl(226.15793242887034,78.48665583019744%,62.177112879909686%)",
    //         data: combinedList1
    //       },
    //     ]
    //   }



  const clickhandler = (event) => {
    //// // //console.log("Click Event Called",getElementAtEvent(chartRef.current, event)[0])
    let checkUndef = getElementAtEvent(chartRef.current, event)[0];
    if (checkUndef !== undefined) {
      let index = getElementAtEvent(chartRef.current, event)[0].index;
      //console.log("index", index);
      let dataset = getElementAtEvent(chartRef.current, event)[0].datasetIndex;
      //console.log("dataset", dataset);
      let weightValue = data.datasets[dataset].data[index].y;
      //console.log("weightValue", weightValue);
      props.passChildData({
        dataset: dataset,
        dataindex: index,
        value: weightValue,
      });
      props.passClick(true);
    }
  };



  return (
    data ? (
    <div>
      <Line
        ref={chartRef}
        data={data}
        options={options}
        onClick={clickhandler}
      />
    </div>
  ): (
    <p>Please answer a few questions and add your weight to see this graph</p>
  )
  );
};

export default WeightForcasting;


