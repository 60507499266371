import axios from "axios";
import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useMemo,
} from "react";
import Loader from "../Loader/Loader";
import swal from "sweetalert";
import EastSharpIcon from "@mui/icons-material/EastSharp";
import { Link } from "react-router-dom";
import Apiservice from "../../Services/Apiservice";

const Que = forwardRef((props, ref) => {
  const [detail, setdetail] = useState("");
  const UsernameofPatient = localStorage.getItem("Username");
  const [response, setresponse] = useState("");
  const [message, setMessage] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [options, setOptions] = useState([]);
  const [name, setName] = useState("");
  const [year, setYear] = useState("");
  const [rows1, setRows1] = useState([{ name: "", year: "" }]);
  const [count, setCount] = useState(0);


  const handleSubmit = (event) => {
    event.preventDefault();
    // Convert the data from the table into the format the API expects
    const tableData = {};
    rows1.forEach((row) => {
      tableData[row.name] = {
        Name: row.name,
        Year: row.year,
      };
    });
    const data = Object.fromEntries(Object.entries(tableData));

    // send data to API
    fetch(
      `https://djangoobesitychat.azurewebsites.net/patient/response/post/Other/${UsernameofPatient}/`,
      {
        method: "POST",
        body: JSON.stringify([
          {
            question1: props.item.question_id,
            patient_name: UsernameofPatient,
            tablejson: data,
          },
        ]),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  const handleAddRow = () => {
    setRows1([...rows1, { name: "", year: "" }]);
  };

  const handleRemoveRow = () => {
    const newRows = [...rows1];
    newRows.splice(-1, 1);
    setRows1(newRows);
  };

  const handleChange = (event, index) => {
    const newRows = [...rows1];
    newRows[index][event.target.name] = event.target.value;
    setRows1(newRows);
    // // //console.log(rows1);
  };

  const handleClick = () => {
    const DiseaseCategory = props.item.disease_category;
    const headers = {
      "Authorization": `Token ${localStorage.getItem("SavedToken")}`,
    };
    // // //console.log(DiseaseCategory);
    fetch(
      `https://djangoobesitychat.azurewebsites.net/diseaseapi/category/${DiseaseCategory}/`, {headers: headers}
    )
      .then((response) => response.json())
      .then((data) => setOptions(data));
  };
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const [tableCount, setTableCount] = useState(0);
  useEffect(() => {
    setTableCount(tableCount + 1);
  }, []);
  // // // //console.log(tableCount+1)
  const tableKey = `table-${props.item.question_id}`;
  // // //console.log(tableKey);
  return (
    <div>
      <div className="questo-form-quesyion-ans-main-div-for-main">
      <div className="questo-form-questions-wrapper" style={{ width: "55%", cursor:"pointer" }}>
      <div className="questo-form-questions-div" >
      <p className="question-text">{props.number}. {props.item.question}</p>
      {props.item.mandatory === "Yes" ? (
              <span style={{ color: "red" }}> *</span>
            ) : null}
        </div>
        <div className="tooltip">More information</div>
        </div>
        {/*
        <div className="questo-form-options-div" style={{ width: "15%" }}>
          <div style={{ display: "flex", gap: "9px" }}>
            <input
              className="add-patient-input-field-of-radio"
              name={props.item.response_id1}
              type="radio"
              value="Yes"
              onChange={(event) =>
                props.radioButtonHandler({
                  que_id: props.item.response_id1,
                  val: event.target.value,
                })
              }
            />
            <p style={{ fontSize: "13px" }}>Yes</p>
            <input
              className="add-patient-input-field-of-radio"
              name={props.item.response_id1}
              type="radio"
              value="No"
              onChange={(event) =>
                props.radioButtonHandler({
                  que_id: props.item.response_id1,
                  val: event.target.value,
                })
              }
            />
            <p style={{ fontSize: "13px" }}>No</p>
          </div>
        </div>
       <div
          className="questo-form-details-div-in-pat"
          style={{ width: "30%" }}
        >
          <textarea
            className="questo-form-input-tag-for-patient"
            type={"text"}
            placeholder="Details..."
            onChange={(event) =>
              props.detailsHandler({
                que_id: props.item.response_id1,
                val: event.target.value,
              })
            }
          />
          </div>*/}
          {props.item.question_category === "yes_no" ? (
            
            
              <div className="response-div">
              <div className="questo-form-options-div" style={{ width: "15%" }}>
              <p classname="label-for-response" style={{color: '#88869d', marginBottom: '20px'}}>Response: </p>
                <div style={{ display: "flex", gap: "9px" }}>
                  <input
                    className="add-patient-input-field-of-radio"
                    name={props.item.question_id}
                    type="radio"
                    value="Yes"
                    checked={props.item.patient_response1 === "Yes"}
                    onChange={(event) =>
                      props.radioButtonHandler({
                        que_id: props.item.question_id,
                        val: event.target.value,
                      })
                    }
                  />
                  <p style={{ fontSize: "13px" }}>Yes</p>
                  <input
                    className="add-patient-input-field-of-radio"
                    name={props.item.question_id}
                    type="radio"
                    value="No"
                    checked={props.item.patient_response1 === "No"}
                    onChange={(event) =>
                      props.radioButtonHandler({
                        que_id: props.item.question_id,
                        val: event.target.value,
                      })
                    }
                  />
                  <p style={{ fontSize: "13px" }}>No</p>
                </div>
              
              </div>

              <div
                className="questo-form-details-div-in-pat"
                style={{ width: "30%" }}
              >
                <p classname="questo-new-label-for-pat-que" style={{color: '#88869d'}}>Disease: </p>
                {props.item.patient_response1 == "Yes"  &&
                  <div>
                    
                    <select
                      className="questo-form-input-tag-for-patient"
                      onClick={handleClick}
                      // multiple
                      onChange={(event) =>
                        props.detailsHandler(
                          {
                            que_id: props.item.question_id,
                            val: event.target.value,
                          },
                          setSelectedOption(event.target.value)
                        )
                      }
                    >
                      <option>Select Disease</option>
                      {options?.map((option) => (
                        <option key={option?.id} value={option?.disease_name}>
                          {option?.disease_name}
                        </option>
                      ))}

                      <option value="Other">Other</option>
                    </select>
                    
                  </div>
               }
              </div>
              {selectedOption === "Other" ? (
                      <div
                      className="questo-form-details-div-in-pat"
                      style={{ width: "30%" }}
                    >
                      <p classname="questo-new-label-for-pat-que" style={{color: '#88869d'}}>Details: </p>
                      <textarea
                        className="questo-form-input-tag-for-patient"
                        type={"text"}
                        placeholder="Details..."
                        onChange={(event) =>
                          props.detailsHandler({
                            que_id: props.item.question_id,
                            val: event.target.value,
                          })
                        }
                      />
                    </div>
                    ) : null}
              </div>
            
          ) : props.item.question_category === "multiple_choice" ? (
            <div>Multiple Choice</div>
          ) : props.item.question_category === "free_text" ? (
            <div
              className="questo-form-quesyion-ans-main-div-for-main"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
                <div className="questo-form-questions-wrapper" style={{ width: "55%", cursor:"pointer" }}>
            <div className="questo-form-questions-div" style={{ width: "100%" }}>
                <p className="question-text">
                {props.item.mandatory === "Yes" ? (
                  <span style={{ color: "red" }}> *</span>
                ) : null}
                  {props.number}. {props.item.question}
                 
                </p>
              </div>
              <div className="tooltip">More information</div>
              </div>
              <div className="response-div">
              <textarea
                className="questo-form-input-tag-for-patient"
                style={{ width: "55%" }}
                type={"text"}
                placeholder="Details..."
                onChange={(event) =>
                  props.detailsHandler({
                    que_id: props.item.question_id,
                    val: event.target.value,
                  })
                }
              />
              </div>
            </div>
          ) : props.item.question_category === "table_format" ? (
            <div
              className="questo-form-quesyion-ans-main-div-for-main"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <form
                style={{ width: "50%" }}
                className="questo-form-input-tag-for-patient"
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <table key={tableKey}>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Year</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rows1.map((row, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              type="text"
                              name="name"
                              className="questo-form-input-tag-for-patient"
                              value={row.name}
                              onChange={(event) => handleChange(event, index)}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="year"
                              className="questo-form-input-tag-for-patient"
                              value={row.year}
                              onChange={(event) => handleChange(event, index)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot style={{ textAlign: "center" }}>
                      <tr>
                        <td colSpan={3}>
                          <button
                          type="button"
                            onClick={handleAddRow}
                            className="male-female-btn-div"
                            style={{
                              backgroundColor: "rgb(49, 163, 214)",
                              padding: "2px",
                            }}
                          >
                            Add Row
                          </button>
                          <button
                          type="button"
                            onClick={handleRemoveRow}
                            className="male-female-btn-div"
                            style={{
                              backgroundColor: "rgb(49, 163, 214)",
                              padding: "2px",
                              marginLeft: "5px",
                            }}
                          >
                            Remove Row
                          </button>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    type="submit"
                    className="submit-btn-in-que"
                    style={{ padding: "4px" }}
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          ) : null}
       
        
      </div>
    </div>
  );
});

const PatientQuestionnaireDisease = () => {
  const [questionetailsget, setquestionetailsget] = useState([]);
  const [loading, setLoading] = useState(true);

  // //console.log("questionetailsget",questionetailsget)
  const childRef = useRef();

  useEffect(() => {
    Apiservice
      .get(
        // `https://djangoobesitychat.azurewebsites.net/question/category/Disease/`,
        `/question/category/Disease/`,
        {}
      )

      .then((response) => {
        const data = response.data.map((entry) => {
          return Object.assign({}, entry, {
            patient_name: localStorage.getItem("Username"),
            question1: entry.question,
          });
        });
        setquestionetailsget(data);
        // // //console.log(data);
        setLoading(false);
      })
      .catch((err) => {});
  }, []);

  const radioButtonHandler = (param) => {
    const newResponse = questionetailsget.map((item) => {
      if (item.question_id == param.que_id) {
        item.patient_response1 = param.val;
      }
      return item;
    });
    setquestionetailsget(newResponse);
  };

  const detailsHandler = (param) => {
    const newResponse = questionetailsget.map((item) => {
      if (item.question_id == param.que_id) {
        // item.response_details1 = param.val;
        item.disease_name = param.val;

      }
      return item;
    });
    setquestionetailsget(newResponse);
  };


  const othersHandler = (param) => {
    const newResponse = questionetailsget.map((item) => {
      if (item.question_id == param.que_id) {
        item.response_details1 = param.val;
        // item.detail = param.val;

      }
      return item;
    });
    setquestionetailsget(newResponse);
  };




  const tableHandler = (param) => {
    const newResponse = questionetailsget.map((item) => {
      if (item.question_id == param.que_id) {
        item.tablejson = param.val;
      }
      return item;
    });
    setquestionetailsget(newResponse);
  };
  // // //console.log("questionetailsget", questionetailsget);
  const [submitLoading,setsubmitLoading]=useState(false)

  const submitHandler = (props) => {
    const UsernameofPatient = localStorage.getItem("Username");
    const test = questionetailsget;
    setsubmitLoading(true)
    axios
      .post(
        `https://djangoobesitychat.azurewebsites.net/patient/response/post/Disease/${UsernameofPatient}/`,
        questionetailsget,
        {}
      )
      .then((res) => {
        // // //console.log("res", res);
        localStorage.setItem("Disease", true);
    setsubmitLoading(false)

        swal({
          title:
            "Your Response has been Submitted Successfully!! Please Move to the Lifestyle Questionnaire...",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });
      })
      .catch((err) => {
    setsubmitLoading(false)

        swal({
          title: "Please Fill all the Responces...",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });
      });
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
        {submitLoading &&
          <div className="purchase-order-details-pop-up-main-div">
                <div className="purchase-order-details-pop-up-sub-div">
                  <div className="purchase-order-details-pop-up-sub-div-container" style={{background:"none"}}>
          <Loader/>
          </div>
          </div>
          </div>
        }
          <div>
          <div className="skip-for-now-div">
          <Link>
            <h3 onClick={submitHandler}>
              Skip For Now <EastSharpIcon style={{ marginTop: "4px" }} />
            </h3>
          </Link>
        </div>
            {/* <div
              className="questo-form-quesyion-ans-main-div-for-main"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="questo-form-questions-div"
                style={{ width: "55%", justifyContent: "center" }}
              >
                <h3>Questions</h3>
              </div>
              <div
                className="questo-form-options-div"
                style={{ width: "15%", justifyContent: "center" }}
              >
                <h3>Response</h3>
              </div>
              <div
                className="questo-form-details-div-in-pat"
                style={{ width: "30%", justifyContent: "center" }}
              >
                <h3>Details</h3>
              </div>
            </div> */}
            {questionetailsget.map((item, index) => {
              return (
                <Que
                  key={item.question_id}
                  number={index + 1}
                  item={item}
                  ref={childRef}
                  detailsHandler={detailsHandler}
                  radioButtonHandler={radioButtonHandler}
                  tableHandler={tableHandler}
                  othersHandler={othersHandler}
                />
              );
            })}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "10px",
            }}
          >
            <button style={{marginTop: '2px'}} onClick={submitHandler} className="submit-btn-in-que">
              Submit
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default PatientQuestionnaireDisease;
