import axios from "axios";
import React from "react";
import { useState } from "react";
import "./CallNotesPost.css";
import swal from "sweetalert";
import { Link } from "react-router-dom";

const CallNotesPost = () => {
  const [notes, setnotes] = useState();
  const username = localStorage.getItem("Username");
  const PatientUsernameforSummary = localStorage.getItem(
    "patientUserName"
  );

  const SubmitNotes = () => {
    axios
      .post(`https://djangoobesitychat.azurewebsites.net/callnotes/post/`, {
        notes: notes,
        doctor_name: username,
        patient_name: PatientUsernameforSummary,
      })
      .then((res) => {
        // //console.log(res);
          swal({
            title: "Note Added Successfully...!",
            className: "successAlert",
            buttons: true,
            timer: 3000,
          });
      })
      .catch((res) => {
        // // //console.log("err", res.response.data);
      });
  };

  return (
    <div className="call-notes-post-flex-div">
      <div className="call-notes-post-width-div">
        <div>
          <textarea
            placeholder="Write Note here..."
            className="call-notes-post-text-area"
            value={notes}
            onChange={(e) => setnotes(e.target.value)}
          />
        </div>
        <div className="call-notes-post-btn-div">
          <button className="update-btn-btn-tag" onClick={SubmitNotes}>
            Submit Note
          </button>
        </div>
        <div className="call-notes-post-btn-div" style={{paddingTop:"20px"}}>
        <Link to="/dashboard/patientprescription" target="_blank">
        <button className="update-btn-btn-tag">
        Prescribe The Medicines
        </button>
        </Link>
      </div>
      </div>
    </div>
  );
};

export default CallNotesPost;
