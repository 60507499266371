import React, { useState, Fragment, useEffect } from "react";
import "./DietCalender.css";
import Calendar from "react-calendar";
import Modal from "@mui/material/Modal";
import { Button, Box, Grid } from "@mui/material";
import axios from "axios";
import swal from "sweetalert";
import SearchLoader from "../Loader/SearchLoader";
import Add from "@mui/icons-material/Add";
import Loader from "../Loader/Loader";
import Apiservice from "../../Services/Apiservice";

const style = {
  position: "absolute",
  top: "80%",
  left: "60%",
  right: "100%",
  bottom: "2vw",
  transform: "translate(-60%, -100%)",
  height: "70vh",
  width: "32vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
};

const DietCalender = () => {
  const [value, onChange] = useState(new Date());
  const [open, setOpen] = useState(false);
  const [datesWithAppointments, setDatesWithAppointments] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = (value) => {
    setOpen(false);
  };

  //   SEARCH DIETPLAN
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  useEffect(() => {
    const loadPosts = async () => {
      setLoading(true);
      const response = await axios.get(
        //  "https://djangoobesitychat.azurewebsites.net/alldietplans/get/"
        "https://djangoobesitychat.azurewebsites.net/food/post/"
      );
      setPosts(response.data);
      //   // // //console.log(response.data);
      setLoading(false);
    };
    loadPosts();
  }, []);

  const [days_of_operation, setdays_of_operation] = useState([]);
  const [patient_name, setpatient_name] = useState("");
  const [exercise_time, setexercise_time] = useState("");
  const [exerciseplans_id, setexerciseplans_id] = useState("");
  const FoodName = localStorage.getItem("FoodName");
  const foodid = localStorage.getItem("foodid");
  const patientUserName = localStorage.getItem("patientUserName");
  const trainer_name = localStorage.getItem("Username");
  const [mainDataLoading, setmainDataLoading] = useState(false);

  const [opentwo, setOPentwo] = useState(false);
  const toggletwo = () => {
    setOPentwo(!opentwo);
  };

  function handleChange(event) {
    setdays_of_operation([...days_of_operation, event.target.value]);
  }

  const AddPatientExercise = async (e) => {
    e.preventDefault();
    setmainDataLoading(true);

    await Apiservice
      .post(
        // `https://djangoobesitychat.azurewebsites.net/user/doctorpostapi/`,
        `/dietprescription/post/`,

        {
          days_of_operation: days_of_operation,
          dietitian_name: trainer_name,
          patient_name: patientUserName,
          meal_time: exercise_time,
          foods: [foodid],
          diet_date: value.toLocaleDateString("fr-CA"),
        }
      )
      .then((res) => {
        setmainDataLoading(false);
        handleClose();
        swal({
          title: "Diet Plan Added Successfully...",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });

        //  fetch(
        //    `https://djangoobesitychat.azurewebsites.net/dietprescription/patient/${patientUserName}/`
        //  )
        // fetch(`https://djangoobesitychat.azurewebsites.net/exerciseprescription/patient/demo_patient/`)
        //    .then((data) => data.json())
        //    .then((data) => setTableData(data));
      });
    //  HideAddPrescriptionHandler().catch((err) => {});
  };

  const date = value.toLocaleDateString("fr-CA");

  const [drilldown,setdrilldown]=useState()

  useEffect(()=>{
    Apiservice.get(`/phenotypedrilldown/${patientUserName}/`)
    .then((response)=>{
      setdrilldown(response.data["Diet_and_Exercise_recommendation"])
    })
    .catch(error =>{
    })
  },[patientUserName])

  // //console.log("drilldown",drilldown)


  return (
    <div className="calender-main-div">
      <Calendar
        onChange={onChange}
        value={value}
        onClickDay={handleOpen}
        tileClassName={({ date }) => {
          let day = date.getDate();
          let month = date.getMonth() + 1;
          if (month < 10) {
            month = "0" + month;
          }
          if (day < 10) {
            day = "0" + day;
          }
          const realDate = date.getFullYear() + "-" + month + "-" + day;
          return datesWithAppointments.includes(realDate)
            ? "appointment-date"
            : "";
        }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
          sx={{
            height: "40vh",
            width: "100%",
            margin: "auto",
          }}
          style={{ overflowY: "scroll" }}
        >
          <Grid item xs={14} sx={style} justify="flex-end">
            <Button sx={{ marginTop: -4 }} onClick={handleClose}>
              Close
            </Button>

            {mainDataLoading && (
              <div className="purchase-order-details-pop-up-main-div">
                <div className="purchase-order-details-pop-up-sub-div">
                  <div
                    className="purchase-order-details-pop-up-sub-div-container"
                    style={{ background: "none" }}
                  >
                    <Loader />
                  </div>
                </div>
              </div>
            )}

            <div className="doctor-prescription-medicine-flex-div">
              <div
                className="patient-details-in-appointment-main-div"
                style={{ width: "650px" }}
              >
                <div className="patient-details-in-appointment-sub-div-two-part">
                  <div className="patient-details-in-appointment-sub-div-two-part-one">
                    <h4>Search Meal :</h4>
                  </div>
                  <div className="patient-details-in-appointment-sub-div-two-part-one-two">
                    <input
                      type="text"
                      placeholder="Search Meal..."
                      className="search-patient-in-appt-inp-tag"
                      onClick={toggletwo}
                      onChange={(e) => setSearchTitle(e.target.value)}
                    />
                    {opentwo && (
                      <div
                        className="list-of-all-user"
                        onClick={toggletwo}
                        style={{ width: "200px" }}
                      >
                        {loading ? (
                          <div>
                            <SearchLoader />
                          </div>
                        ) : (
                          posts
                            .filter((value) => {
                              if (searchTitle === "") {
                                return posts;
                              } else if (
                                value.FoodName?.toLowerCase().includes(
                                  searchTitle?.toLowerCase()
                                )
                              ) {
                                return value;
                              }
                            })
                            .map((item) => (
                              <div className="list-of-user-main-flex-div">
                                <div className="list-of-user-main-div">
                                  <p
                                    onClick={() => {
                                      localStorage.setItem(
                                        "FoodName",
                                        item.FoodName
                                      );
                                      localStorage.setItem(
                                        "foodid",
                                        item.food_id
                                      );
                                    }}
                                  >
                                    {item.FoodName}
                                  </p>
                                </div>
                              </div>
                            ))
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <div className="patient-details-in-appointment-sub-div-two">
                  <div className="patient-details-in-appointment-sub-div-two-part">
                    <div className="patient-details-in-appointment-sub-div-two-part-one">
                      <p>Meal Name</p>
                    </div>
                    <div className="patient-details-in-appointment-sub-div-two-part-one-two">
                      <p>{FoodName}</p>
                    </div>
                  </div>

                  <div className="patient-details-in-appointment-sub-div-two-part">
                    <div className="patient-details-in-appointment-sub-div-two-part-one">
                      <p>Time</p>
                    </div>

                    <select
                      className="email-input-field-tag"
                      onChange={(e) => setexercise_time(e.target.value)}
                      //   style={{ width: "200px" }}
                    >
                      <option>Select Time</option>
                      <option value="Morning">Morning</option>
                      <option value="Mid Morning">Mid Morning</option>
                      <option value="Lunch">Lunch</option>
                      <option value="Mid Lunch">Mid Lunch</option>
                      <option value="Dinner">Dinner</option>
                    </select>
                  </div>
                </div>

                <div className="add-pat-btn-div">
                  <button
                    className="add-btn-btn-tag"
                    onClick={AddPatientExercise}
                  >
                    <Add style={{ height: "20px", width: "20px" }} />
                    SUBMIT MEAL
                  </button>
                </div>

              

              </div>
              
            </div>
            <div>
            {drilldown ?
              <div>
              <div className="recommended-medicine-main-div">
              <p style={{margin:"0px", fontSize:"14px"}}>Phenotype : </p>
              <p style={{margin:"0px", fontSize:"14px"}}>{drilldown.phenotype}</p>
              </div>
              
            <div>
              <div className="recommended-medicine-main-div">
              <p className="recommended-medicine-main-div-p-tag" style={{textAlign:"center"}}>{drilldown.Diet}</p>
              </div>
              </div>
              </div>
              : ""}
            </div>

          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};

export default DietCalender;
