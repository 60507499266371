import axios from "axios";
import React, { useEffect, useState } from "react";
import "./MarketingMailbyClinicSubClinic.css";
// import MyStatefulEditor from "./RichTextEditor";
import swal from "sweetalert";
import Apiservice from "../../../Services/Apiservice";
import { Textarea } from "@mui/joy";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import the styles

const MarketingMailbyClinicSubClinic = () => {
  const [val, setVal] = useState("");
  const [subject, setsubject] = useState("");
  const [editorHtml, setEditorHtml] = useState('');

  const onChange = (value) => {
    setVal(value);
    setEditorHtml(value);
  };

  const id = localStorage.getItem("id");

  const [userdetails, setuserdetails] = useState([]);
  useEffect(() => {
    Apiservice
      .get(`/current/user/details/${id}/`)

      .then((response) => {
        setuserdetails(response.data);
      })
      .catch((err) => {});
  }, [id]);

  //   // //console.log("userdetails",userdetails)

  const clinicID = localStorage.getItem("ClinicIDForHomepage");
  const subclinic_id = localStorage.getItem("SubClinicIDForHomepage");
  const is_clinic = localStorage.getItem("is_clinic");

  const SendMail = async (e) => {
    e.preventDefault();
    await Apiservice
      .post(
        is_clinic == "true"
          ? `/send-email/allclinicpatient/${clinicID}/`
          : `/send-email/allsubclinicpatient/${subclinic_id}/`,

        {
          subject: subject,
          body: val,
          sender: userdetails.email,
        }
      )
      .then((res) => {
        swal({
          title: "Mail Sent Successfully...",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });
      })
      .catch((res) => {});
  };

  return (
    <div className="marketing-mail-width-div">
      <div className="marketing-mail-flex-div">
        <div className="marketing-mail-main-div">
          <div className="marketing-mail-main-div-one">
            <div className="marketing-mail-main-div-one-sender">
              <h3>Subject</h3>
            </div>
            <div className="marketing-mail-main-div-one-sender-text">
              <input
                type={"text"}
                className="marketing-mail-main-div-one-sender-input"
                value={subject}
                onChange={(e) => setsubject(e.target.value)}
              />
            </div>
          </div>

          <div className="marketing-mail-main-div-one">
            <div className="marketing-mail-main-div-one-sender">
              <h3>Body</h3>
            </div>
          </div>

          <div className="marketing-mail-main-div-one">
            {/* <Textarea markup="" onChange={onChange} /> */}
            <ReactQuill value={editorHtml} onChange={onChange} />
          </div>

          <div className="marketing-mail-main-div-one" style={{marginTop:"25px",display:"flex", justifyContent:"center"}}>
            <button onClick={SendMail}
            className="disease-handler-input-search-tag-btn"
            >Submit</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketingMailbyClinicSubClinic;
