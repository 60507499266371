import React from "react";
import "./AIDiet.css";
import { useState } from "react";
import axios from "axios";
import Loader from "../Loader/Loader";
import { useEffect } from "react";
import swal from "sweetalert";
import Apiservice from "../../Services/Apiservice";

const AIDietNew = () => {
  const [WeightforDiet, setWeightforDiet] = useState(null);
  const [MeatTypeforDiet, setMeatTypeforDiet] = useState("");
  const [CuisineforDiet, setCuisineforDiet] = useState("");
  const [IntoleranceforDiet, setIntoleranceforDiet] = useState("");
  const Username = localStorage.getItem("Username");
  const [LoginUserDetails, setLoginUserDetails] = useState("");
  const [phenotype, setphenotype] = useState({});

  //   GET AGE AND GENDER FROM USER MODEL
  useEffect(() => {
    Apiservice
      .get(`/current/user/details/${Username}/`)

      .then((response) => {
        setLoginUserDetails(response.data);
        setphenotype(response.data.phenotype[0]);
        //console.log("data", response.data.phenotype[0])
      })
      .catch((err) => {});
  }, [Username]);

  //   GET CURRENT WEIGHT
  useEffect(() => {
    Apiservice
      .get(`/user/allwighthistory/${Username}/`)
      .then((response) => {
        setWeightforDiet(
          response.data[response.data.length - 1]["current_weight"]
        );
      })
      .catch((err) => {});
  }, [Username]);

  const GenderforDiet = LoginUserDetails.gender;
  const AgeforDiet = LoginUserDetails.age;
  const CaleoriesforDiet = LoginUserDetails.gender == "Male" ? "2200" : "1900";
  const [loading, setLoading] = useState(false);
  const [AIDIetResponse, setAIDIetResponse] = useState();
  const [BreakfastDiet, setBreakfastforDiet] = useState();
  const [LunchDiet, setLunchforDiet] = useState("");
  const [DinnerDiet, setDinnerforDiet] = useState("");
  const [SnacksDiet, setSnacksforDiet] = useState("");
  // const [MidMorningSnackDiet, setMidMorningSnackforDiet] = useState("");
  // const [MidAfternoonSnackDiet, setMidAfternoonSnackforDiet] = useState("");
  // const [DessertDiet, setDessertforDiet] = useState("");
  // const [BedTimeSnackDiet, setBedTimeSnackforDiet] = useState("");
  // const [TipsDiet, setTipsDiet] = useState("");
//console.log("phenotype",phenotype)
  const SendData = async (e) => {
    setLoading(true);
    e.preventDefault();
    await Apiservice
      .post(
        `/defaultdietapi/${GenderforDiet}/${WeightforDiet}Kgs/${AgeforDiet}/${CaleoriesforDiet}/${MeatTypeforDiet}/${CuisineforDiet}/${IntoleranceforDiet}/${Username}/`
      )
      .then((res) => {
        setAIDIetResponse(res.data);
        setBreakfastforDiet(res.data["Breakfast"]);
        setLunchforDiet(res.data["Lunch"]);
        setDinnerforDiet(res.data["Dinner"]);
        setSnacksforDiet(res.data["Snacks"]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  //console.log("AIDIetResponse", AIDIetResponse);

  const SavetoDB = async (e) => {
    e.preventDefault();
    setLoading(true);

    await Apiservice
      .post(
        "/suggesteddiet/post/",

        {
          suggesteddiet: AIDIetResponse,
          patient_name: Username,
        }
      )
      .then((res) => {
        swal({
          title: "Diet Plan Added as Your Default Diet Successfully...",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });
        setLoading(false);
      })
      .catch((res) => {
        setLoading(false);
      });
  };

  return (
    <div className="ai-diet-flex-div">
      {loading && (
        <div className="purchase-order-details-pop-up-main-div">
          <div className="purchase-order-details-pop-up-sub-div">
            <div
              className="purchase-order-details-pop-up-sub-div-container"
              style={{ background: "none" }}
            >
              <Loader />
            </div>
          </div>
        </div>
      )}
        
      <div className="ai-diet-width-div">
        <>
        {phenotype ? (
        <div className="ai-diet-main-div">
          <div className="ai-diet-main-div-one">
            <h3>Please Fill the Below Details to Get Your Diet Plan....!</h3>
          </div>
          <div className="ai-diet-main-div-one">
            <div className="acc-setting-form-main-div">
              <div className="acc-setting-form-block-div">
                {/*<div className="acc-setting-details">
                  <div className="acc-info-div-one">
                    <p>Age</p>
                  </div>
                  <div className="acc-info-div-one">
                    <input
                      type="number"
                      value={AgeforDiet}
                      onChange={(e) => setAgeforDiet(e.target.value)}
                    />
                    </div>
                    </div>*/}

                    {WeightforDiet === "" ? (
                      <div className="acc-setting-details">
                        <div className="acc-info-div-one">
                          <p>Weight</p>
                        </div>
                        <div className="acc-info-div-one">
                          <input
                            placeholder="Please add Your Weight"
                            type="number"
                            value={WeightforDiet}
                            onChange={(e) => setWeightforDiet(e.target.value)}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {/*
                    <div className="acc-setting-details">
                      <div className="acc-info-div-one">
                        <p>Calories Consumed</p>
                      </div>
                      <div className="acc-info-div-one">
                        <input
                          type="number"
                          value={CaleoriesforDiet}
                          onChange={(e) => setCaleoriesforDiet(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="acc-setting-details">
                      <div className="acc-info-div-one">
                        <p>Gender</p>
                      </div>
                      <div className="acc-info-div-one">
                        <select onChange={(e) => setGenderforDiet(e.target.value)}>
                          <option>Select Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                      </div>
                    </div>
                    */}

                    <div className="acc-setting-details">
                      <div className="acc-info-div-one">
                        <p>Meat Type</p>
                      </div>
                      <div className="acc-info-div-one">
                        <select
                          onChange={(e) => setMeatTypeforDiet(e.target.value)}
                        >
                          <option>Select Meat Type</option>
                          <option value="Halal">Halal</option>
                          <option value="Non Halal">Non Halal</option>
                        </select>
                      </div>
                    </div>

                    <div className="acc-setting-details">
                      <div className="acc-info-div-one">
                        <p>Cuisine</p>
                      </div>
                      <div className="acc-info-div-one">
                        <select onChange={(e) => setCuisineforDiet(e.target.value)}>
                          <option>Select Cuisine</option>
                          <option value="French">French</option>
                          <option value="Chinese">Chinese</option>
                          <option value="Japanese">Japanese</option>
                          <option value="Italian">Italian</option>
                          <option value="Greek">Greek</option>
                          <option value="Spanish">Spanish</option>
                          <option value="Mediterranean">Mediterranean</option>
                          <option value="Lebanese">Lebanese</option>
                          <option value="Moroccan">Moroccan</option>
                          <option value="Turkish">Turkish</option>
                          <option value="Thai">Thai</option>
                          <option value="Indian">Indian</option>
                          <option value="Cajun">Cajun</option>
                          <option value="Mexican">Mexican</option>
                          <option value="Caribbean">Caribbean</option>
                          <option value="German">German</option>
                          <option value="Russian">Russian</option>
                          <option value="Hungarian">Hungarian</option>
                          <option value="American">American</option>
                        </select>
                      </div>
                    </div>

                    <div className="acc-setting-details">
                      <div className="acc-info-div-one">
                        <p>Intolerance</p>
                      </div>
                      <div className="acc-info-div-one">
                        <input
                          type="text"
                          placeholder="Lactose Intolerant"
                          value={IntoleranceforDiet}
                          onChange={(e) => setIntoleranceforDiet(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="email-verification-main-div-two">
                      <button
                        className="login-page-login-btn"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "60px",
                        }}
                        onClick={SendData}
                      >
                        Submit
                      </button>
                    </div>

                    <div></div>
                    </div>
                    </div>
                    </div>
                    </div>
                    ) : (
                    <p>Please fill all the questionnaires to help us define your Phenotype</p>
                    )}
        </>

        <div className="ai-diet-responce-main-div">
          {BreakfastDiet ? (
            <div>
              <div className="ai-diet-main-div-one">
                <h3>Breakfast</h3>
              </div>
              <div style={{ paddingLeft: "100px", paddingTop: "15px" }}>
                {BreakfastDiet &&
                  BreakfastDiet?.map((index) => <li>{index}</li>)}
              </div>
            </div>
          ) : (
            ""
          )}

          {LunchDiet ? (
            <div>
              <div className="ai-diet-main-div-one">
                <h3>Lunch</h3>
              </div>
              <div style={{ paddingLeft: "100px", paddingTop: "15px" }}>
                {LunchDiet && LunchDiet?.map((index) => <li>{index}</li>)}
              </div>
            </div>
          ) : (
            ""
          )}

          {DinnerDiet ? (
            <div>
              <div className="ai-diet-main-div-one">
                <h3>Dinner</h3>
              </div>
              <div style={{ paddingLeft: "100px", paddingTop: "15px" }}>
                {DinnerDiet && DinnerDiet?.map((index) => <li>{index}</li>)}
              </div>
            </div>
          ) : (
            ""
          )}

          {SnacksDiet ? (
            <div>
              <div className="ai-diet-main-div-one">
                <h3>Snacks</h3>
              </div>
              <div style={{ paddingLeft: "100px", paddingTop: "15px" }}>
                {SnacksDiet && SnacksDiet?.map((index) => <li>{index}</li>)}
              </div>
            </div>
          ) : (
            ""
          )}

          {AIDIetResponse ? (
            <div className="email-verification-main-div-two">
              <button
                className="login-page-login-btn"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px",
                }}
                onClick={SavetoDB}
              >
                Save the above Diet Plan as my Default Diet
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default AIDietNew;
