import axios from "axios";
import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import Loader from "../Loader/Loader";
import swal from "sweetalert";
import EastSharpIcon from "@mui/icons-material/EastSharp";
import { Link } from "react-router-dom";
import Apiservice from "../../Services/Apiservice";
const Que = forwardRef((props, ref) => {
  const [detail, setdetail] = useState("");
  const UsernameofPatient = localStorage.getItem("Username");
  const [response, setresponse] = useState("");
  const [message, setMessage] = useState("");
  const [rows, setRows] = useState([{ name: "", year: "" }]);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Convert the data from the table into the format the API expects
    const tableData = {};
    rows.forEach((row) => {
      tableData[row.name] = {
        Name: row.name,
        Year: row.year,
      };
    });
    const data = Object.fromEntries(Object.entries(tableData));

    // send data to API
    fetch(
      `https://djangoobesitychat.azurewebsites.net/patient/response/post/Other/${UsernameofPatient}/`,
      {
        method: "POST",
        body: JSON.stringify([
          {
            question_id: props.item.question_id,
            patient_name: UsernameofPatient,
            tablejson: data,
          },
        ]),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  const handleAddRow = () => {
    setRows([...rows, { medication: "", dosage: "", number: "" }]);
  };

  const handleChange = (event, index) => {
    const newRows = [...rows];
    newRows[index][event.target.name] = event.target.value;
    setRows(newRows);
  };

  return (
    <div>
      {props.item.question_category === "yes_no" ? (
        <div
          className="questo-form-quesyion-ans-main-div-for-main"
        >
        <div className="questo-form-questions-wrapper" style={{ width: "55%", cursor:"pointer" }}>
        <div className="questo-form-questions-div">
            <p className="question-text">
            
              {props.number}. {props.item.question}
              {props.item.mandatory === "Yes" ? (
              <span style={{ color: "red" }}> *</span>
            ) : null}
             
            </p>
          </div>
          <div className="tooltip">More information</div>
          </div>
          <div className="response-div">
          <div className="questo-form-options-div" style={{ width: "15%" }}>
          <p classname="label-for-response" style={{color: '#88869d', marginBottom: '30px'}}>Response: </p>
            <div style={{ display: "flex", gap: "9px" }}>
              <input
                className="add-patient-input-field-of-radio"
                // name="response"
                name={props.item.question_id}
                type="radio"
                value="Yes"
                onChange={(event) =>
                  props.radioButtonHandler({
                    que_id: props.item.question_id,
                    val: event.target.value,
                  })
                }
              />
              <p style={{ fontSize: "13px" }}>Yes</p>
              <input
                className="add-patient-input-field-of-radio"
                // name="response"
                name={props.item.question_id}
                type="radio"
                value="No"
                onChange={(event) =>
                  props.radioButtonHandler({
                    que_id: props.item.question_id,
                    val: event.target.value,
                  })
                }
              />
              <p style={{ fontSize: "13px" }}>No</p>
            </div>
          </div>
          
          <div
            className="questo-form-details-div-in-pat"
            style={{ width: "30%" }}
          >
            <p classname="questo-new-label-for-pat-que" style={{color: '#88869d'}}>Details: </p>
            <textarea
              className="questo-form-input-tag-for-patient"
              type={"text"}
              placeholder="Details..."
              onChange={(event) =>
                props.detailsHandler({
                  que_id: props.item.question_id,
                  val: event.target.value,
                })
              }
            />
          </div>
          </div>
        </div>
      ) : props.item.question_category === "multiple_choice" ? (
        <div>Multiple Choice</div>
      ) : props.item.question_category === "table_format" ? (
        <>
        <div className="questo-form-questions-wrapper" style={{ width: "55%", cursor:"pointer" }}>
        <div className="questo-form-questions-div" style={{ width: "100%" }}>
            <p>
            {props.item.mandatory === "Yes" ? (
              <span style={{ color: "red" }}> *</span>
            ) : null}
              {props.item.question}
             
            </p>
          </div>
          <div className="tooltip">More information</div>
          </div>
          <form
            onSubmit={handleSubmit}
            style={{
              width: "55%",
              marginLeft: "15em",
              marginTop: "2em",
              marginBottom: "2em",
            }}
            className="questo-form-input-tag-for-patient"
          >
            <table>
              <div style={{display: "flex", justifyContent: "center"}}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Year</th>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="text"
                          name="medication"
                          value={row.name}
                          onChange={(event) => handleChange(event, index)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="dosage"
                          value={row.year}
                          onChange={(event) => handleChange(event, index)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={3}>
                      <button onClick={handleAddRow}>Add Row</button>
                    </td>
                  </tr>
                </tfoot>
              </div>
            </table>
            <button type="submit">Save</button>
          </form>
        </>
      ) : props.item.question_category === "free_text" ? (
        <div
          className="questo-form-quesyion-ans-main-div-for-main"
        >
            <div className="questo-form-questions-wrapper" style={{ width: "55%", cursor:"pointer" }}>
        <div className="questo-form-questions-div" style={{ width: "100%" }}>
            <p className="question-text">
            {props.item.mandatory === "Yes" ? (
              <span style={{ color: "red" }}> *</span>
            ) : null}
              {props.number}. {props.item.question}
             
            </p>
          </div>
          <div className="tooltip">More information</div>
          </div>
          <div className="response-div">
          <p classname="questo-new-label-for-pat-que" style={{color: '#88869d'}}>Details: </p>
          <textarea
            className="questo-form-input-tag-for-patient"
            style={{ width: "55%" }}
            type={"text"}
            placeholder="Details..."
            onChange={(event) =>
              props.detailsHandler({
                que_id: props.item.question_id,
                val: event.target.value,
              })
            }
          />
          </div>
        </div>
      ) : null}
    </div>
  );
});

const PatientQuestionnaireLifestyle = () => {
  const [questionetailsget, setquestionetailsget] = useState([]);
  const [loading, setLoading] = useState(true);

  const childRef = useRef();

  useEffect(() => {
    Apiservice
      .get(
        `/question/category/Lifestyle/`,
        {}
      )

      .then((response) => {
        const data = response.data.map((entry) => {
          return Object.assign({}, entry, {
            patient_name: localStorage.getItem("Username"),
            question1: entry.question,
          });
        });
        setquestionetailsget(data);
        // // //console.log(data);
        setLoading(false);
      })
      .catch((err) => {});
  }, []);

  const radioButtonHandler = (param) => {
    const newResponse = questionetailsget.map((item) => {
      if (item.question_id == param.que_id) {
        item.patient_response1 = param.val;
      }
      return item;
    });
    setquestionetailsget(newResponse);
  };

  const detailsHandler = (param) => {
    const newResponse = questionetailsget.map((item) => {
      if (item.question_id == param.que_id) {
        item.response_details1 = param.val;
      }
      return item;
    });
    setquestionetailsget(newResponse);
  };

  const [submitLoading,setsubmitLoading]=useState(false)

  const submitHandler = (props) => {
    const UsernameofPatient = localStorage.getItem("Username");
    const test = questionetailsget;
    setsubmitLoading(true)

    axios
      .post(
        `https://djangoobesitychat.azurewebsites.net/patient/response/post/Lifestyle/${UsernameofPatient}/`,
        questionetailsget,
        {}
      )
      .then((res) => {
        setsubmitLoading(false)
        localStorage.setItem("Lifestyle", true);

        swal({
          title:
            "Your Response has been Submitted Successfully !! Please Move to the Medical Test Questionnaire...",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });
      })
      .catch((err) => {
    setsubmitLoading(false)

        swal({
          title: "Please Fill all the Responces...",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });
      });
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
        {submitLoading &&
          <div className="purchase-order-details-pop-up-main-div">
                <div className="purchase-order-details-pop-up-sub-div">
                  <div className="purchase-order-details-pop-up-sub-div-container" style={{background:"none"}}>
          <Loader/>
          </div>
          </div>
          </div>
        }
          <div>
          <div className="skip-for-now-div">
          <Link>
            <h3 onClick={submitHandler}>
              Skip For Now <EastSharpIcon style={{ marginTop: "4px" }} />
            </h3>
          </Link>
        </div>
            {/* <div
              className="questo-form-quesyion-ans-main-div-for-main"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="questo-form-questions-div"
                style={{ width: "55%", justifyContent: "center" }}
              >
                <h3>Questions</h3>
              </div>
              <div
                className="questo-form-options-div"
                style={{ width: "15%", justifyContent: "center" }}
              >
                <h3>Response</h3>
              </div>
              <div
                className="questo-form-details-div-in-pat"
                style={{ width: "30%", justifyContent: "center" }}
              >
                <h3>Details</h3>
              </div>
            </div> */}
            {questionetailsget.map((item, index) => {
              return (
                <Que
                  key={item.question_id}
                  number={index + 1}
                  item={item}
                  ref={childRef}
                  detailsHandler={detailsHandler}
                  radioButtonHandler={radioButtonHandler}
                />
              );
            })}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "10px",
            }}
          >
            <button onClick={submitHandler} className="submit-btn-in-que">
              Submit
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default PatientQuestionnaireLifestyle;
