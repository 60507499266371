
import axios from "axios";
import React, { useEffect, useState } from "react";
import Loader from "../Loader/Loader";

const Que = (props) => {
  return (
    <div>
      <div className="questo-form-quesyion-ans-main-div-for-main">
        <div className="questo-form-questions-div" style={{ width: "55%" }}>
          <p>{props.item.question}</p>
        </div>
        <div className="questo-form-options-div" style={{ width: "15%" }}>
          <p style={{ textAlign: "center" }}>{props.item.patient_response1}</p>
        </div>
        <div
          className="questo-form-details-div-in-pat"
          style={{ width: "30%" }}
        >
          <div className="questo-form-input-tag-for-patient">
            <p>{props.item.response_details1}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const QuestionnaireMedTest = () => {
  const [questionetailsget, setquestionetailsget] = useState([]);
  const [loading, setLoading] = useState(true);
  const UsernameofPatient = localStorage.getItem("patientUserName");

  useEffect(() => {
    axios
      .get(
        `https://djangoobesitychat.azurewebsites.net/patient/response/get/Medical_Test/${UsernameofPatient}/`,
        {}
      )

      .then((response) => {
        setquestionetailsget(response.data);
        setLoading(false);
      })
      .catch((err) => {});
  }, []);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="questo-form-quesyion-ans-main-div-for-main">
            <div
              className="questo-form-questions-div"
              style={{ width: "55%", justifyContent: "center" }}
            >
              <h3>Questions</h3>
            </div>
            <div
              className="questo-form-options-div"
              style={{ width: "15%", justifyContent: "center" }}
            >
              <h3 style={{ textAlign: "center" }}>Response</h3>
            </div>
            <div
              className="questo-form-details-div-in-pat"
              style={{ width: "30%", justifyContent: "center" }}
            >
              <h3>Details</h3>
            </div>
          </div>
          {questionetailsget.map((item) => {
            return <Que item={item} />;
          })}
        </div>
      )}
    </div>
  );
};

export default QuestionnaireMedTest;
