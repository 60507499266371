import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Loader from "../Loader/Loader";
import "./DoctorBlog.css";
import Apiservice from "../../Services/Apiservice";

const DoctorBlog = () => {
  const [open, setOPen] = useState(false);
  const toggle = () => {
    setOPen(!open);
    // // //console.log("toggle", open);
  };

  const [blogdetails, setblogdetails] = useState("");
  const [loading, setLoading] = useState(true);
  const BlogID = localStorage.getItem("BlogID");
  useEffect(() => {
    Apiservice
      .get(`/blogbyidapi/${BlogID}/`, {})

      .then((response) => {
        setblogdetails(response.data[0]);
        // // //console.log("setblogdetails", response.data);
        setLoading(false);
      })
      .catch((err) => {});
  }, []);
  return (
    <div className="blog-page-flex-div">
    {loading ? (
      <Loader />
    ) : (
      <div className="blog-page-width-div">
        <div className="blog-page-main-div">
          <div className="blog-page-sub-div-one">
            <div>
              <img
                src="https://media.istockphoto.com/id/1297146235/photo/blue-chromosome-dna-and-gradually-glowing-flicker-light-matter-chemical-when-camera-moving.jpg?b=1&s=170667a&w=0&k=20&c=exQXSk71T61mZ01CIqLgPSvYqmDfduPakUNw491s6zI="
                alt=""
                className="blog-main-img-tag"
              />
            </div>
            <div className="blod-data-main-div">
              <div className="blog-header-div">
                <h2>{blogdetails && blogdetails.title}</h2>
                <p>{blogdetails && blogdetails.created_data}</p>
              </div>
              <div className="blog-main-para-div">
                <p>{blogdetails && blogdetails.overview}</p>
                {open && (
                  <div>
                    <p>{blogdetails && blogdetails.summary}</p>
                  </div>
                )}
              </div>
              <div>
                <div className="add-pat-btn-div">
                  {open == false ? (
                    <button className="read-more-btn" onClick={toggle}>
                      Read More
                    </button>
                  ) : (
                    <button className="read-more-btn" onClick={toggle}>
                      Close
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="blog-page-sub-div-two">
            <div className="blog-page-sub-div-two-sec-one">
              <h3>RECENT POSTS</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisci elit, sed
                eiusmod tempor incidunt ut labore et dolore magna aliqua.
              </p>
            </div>
            <div className="blog-page-sub-div-two-sec-two">
              <h3>RECENT COMMENTS</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisci elit, sed
                eiusmod tempor incidunt ut labore et dolore magna aliqua.
              </p>
            </div>
            <div className="blog-page-sub-div-two-sec-two">
              <h3>ARCHIVES</h3>
              <p>October 2022</p>
              <p>September 2022</p>
              <p>August 2022</p>
              <p>July 2022</p>
            </div>
          </div>
        </div>
      </div>
    )}
    </div>
  );
};

export default DoctorBlog;
