import axios from 'axios';
import React, { useEffect, useState } from 'react'

const QuestionnaireEmotional = () => {
  const [emotionalqueres, setemotionalqueres] = useState([]);

    useEffect(() => {
        axios
          .get(`https://djangoobesitychat.azurewebsites.net/emotional/list-view-answer/`)
    
          .then((response) => {
            // if response.data.categoy_id != 3
            //console.log("response.data", response.data);
            const data = response.data.filter((item) => item.category !== 3);
            setemotionalqueres(data);
            // // //console.log("setemotionalqueres", response);
          })
          .catch((err) => {
            // // // //console.log(err);
          });
      }, []);
  return (
    <div>
      <div className="test-doc-details-table">
      <div className="test-table-doc-sub-div"> 
        <h4>Question</h4>
      </div>
      <div  className="test-table-doc-sub-div">
        <h4>Response</h4>
      </div>
    </div>
        {emotionalqueres.map((index) => (
 
            <div className="test-doc-details-table">
              <div className="test-table-doc-sub-div">{index.question}</div>
              <div className="test-table-doc-sub-div">
                <p>{index.answer}</p>
              </div>
            </div>
        ))}
      </div>
  )
}

export default QuestionnaireEmotional