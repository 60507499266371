import React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";
import Apiservice from "../../Services/Apiservice";

const TrainerAppointmentList = () => {
  const [appointmentlist, setappointmentlist] = useState([]);
  const [loading, setLoading] = useState(true);
  const clinicID = localStorage.getItem("ClinicIDForHomepage");
  const subclinicID = localStorage.getItem("SubClinicIDForHomepage");
  const is_subclinic = localStorage.getItem("is_subclinic");
  const is_receptionist = localStorage.getItem("is_receptionist");
  const is_trainer = localStorage.getItem("is_trainer");
  const trainerUsername = localStorage.getItem("Username");

  useEffect(() => {
    Apiservice
      .get(
        // is_subclinic == "true" ?
        // `https://djangoobesitychat.azurewebsites.net/trainer/appointment/subclinic/${subclinicID}/`
        //  :
        // `https://djangoobesitychat.azurewebsites.net/trainer/appointment/clinic/${clinicID}/`,
        is_trainer === "true"
          ? `/appointmentapi/trainer/${trainerUsername}/`
          :is_receptionist === "true"
          ? clinicID === "null"
            ? `/trainer/appointment/subclinic/${subclinicID}/`
            : `/trainer/appointment/clinic/${clinicID}/`
          : is_subclinic === "true"
          ? `/trainer/appointment/subclinic/${subclinicID}/`
          : `/trainer/appointment/clinic/${clinicID}/`,

        {}
      )

      .then((response) => {
        setappointmentlist(response.data);
        // // //console.log("ASD", response.data);
        setLoading(false);
      })
      .catch((err) => {});
  }, []);
  if (!appointmentlist) return null;

  function generateRandom() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  console.log("appointmentlist", appointmentlist)

  const columns = [
    {
      field: "patient_firstName",
      headerName: "Patient First name",
      flex: 1,
      minWidth: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "patient_lastName",
      headerName: "Patient Last name",
      flex: 1,
      minWidth: 250,
      maxWidth: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "trainer_firstName",
      headerName: "Trainer First Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      minWidth: 250,
      maxWidth: 250,
    },
    {
      field: "trainer_lastName",
      headerName: "Trainer Last name",
      flex: 1,
      minWidth: 250,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "starttime",
      headerName: "Date",
      flex: 1,
      minWidth: 250,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "duration",
      headerName: "Duration",
      minWidth: 250,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "is_approved",
      headerName: "Status",
      flex: 1,
      minWidth: 250,
      headerClassName: "super-app-theme--header",
    },
  ];

  const rows = [];
  return (
    <div className="appointment-list-flex-div">
      <div className="appointment-list-width-div">
        <div className="appointment-list-main-div">
          <div className="appt-list-create-new-appt-main-div-btn">
            {is_subclinic == "true" ? (
              <Link to="/subclinicsidebar/appointmentbyclinic">
                <button>Create New Appointment</button>
              </Link>
            )
            :
            is_receptionist == "true" ? 
            <Link to="/receptionistsidebar">
            <button>Create New Appointment</button>
            </Link>
            : (
              <Link to="/clinicsidebar/appointmentbyclinic">
                <button>Create New Appointment</button>
              </Link>
            )}
          </div>
          <div className="appointment-list-sub-div-one">
            <h3>Appointments</h3>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="appointment-list-sub-div-two">
              <Box
                sx={{
                  height: 800,
                  width: "100%",
                  "& .super-app-theme--header": {
                    backgroundColor: "rgb(49, 163, 214)",
                  },
                }}
              >
                <DataGrid
                  rows={appointmentlist}
                  columns={columns}
                  pageSize={50}
                  rowsPerPageOptions={[50]}
                  getRowId={(row) => generateRandom()}
                  experimentalFeatures={{ newEditingApi: true }}
                />
              </Box>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TrainerAppointmentList;
