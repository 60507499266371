import React, { useState, useEffect } from "react";
import axios from "axios";
import Add from "@mui/icons-material/Add";
import { AiFillCaretDown } from "react-icons/ai";
import swal from "sweetalert";
import "./AppointmentbyClinic.css";
import { Navigate, useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import SearchLoader from "../Loader/SearchLoader";
import Apiservice from "../../Services/Apiservice";
import moment from "moment";

const AppointmentbyClinic = () => {
  //Search Name of Patient
  const [date, setDate] = useState("");
  const [time, settime] = useState("");
  const [ServiceType, setServiceType] = useState("");
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const [duration, setduration] = useState("");
  const [message, setMessage] = useState("");
  const clinicID = localStorage.getItem("ClinicIDForHomepage");
  //console.log("clinicID", clinicID);
  // const clinciID = localStorage.getItem("ClinicIDForHomepage")
  const subclinic_id = localStorage.getItem("SubClinicIDForHomepage");
  const [response, setresponse] = useState();

  useEffect(() => {
    const loadPosts = async () => {
      setLoading(true);
      const response = await Apiservice.get(
        // "https://djangoobesitychat.azurewebsites.net/all/patient/"
        `/user/patient/clinic/${clinicID}/`
      );
      setPosts(response.data);
      // // // //console.log(response.data);
      setLoading(false);
    };
    loadPosts();
  }, [clinicID]);

  // GET Details of Patient by Username
  const UserIdtoDetDetails = localStorage.getItem("UserIdtoDetDetails");
  const [patientdetailsinaddpatient, setpatientdetailsinaddpatient] = useState(
    []
  );
  const [userdetails, setuserdetails] = useState([]);
  const is_clinic = localStorage.getItem("is_clinic");
  const is_subclinic = localStorage.getItem("is_subclinic");
  const  is_receptionist = localStorage.getItem("is_receptionist");
  useEffect(() => {
    if (UserIdtoDetDetails) {
    Apiservice
      .get(
        `/current/user/details/${UserIdtoDetDetails}/`
        // `https://djangoobesitychat.azurewebsites.net/all/patient/${UserIdtoDetDetails}/`
      )

      .then((response) => {
        setpatientdetailsinaddpatient(response.data);
        // // // //console.log(response.data);
      })
      .catch((err) => {});
    }
  }, [UserIdtoDetDetails]);

  const [opentwo, setOPentwo] = useState(false);
  const toggletwo = () => {
    setOPentwo(!opentwo);
  };
  const [openthree, setOPenthree] = useState(false);
  const togglethree = () => {
    setOPenthree(!openthree);
  };
  const [openfour, setOPenfour] = useState(false);
  const togglefour = () => {
    setOPenfour(!openfour);
  };

  //Search Name of Doctor
  const [poststwo, setPoststwo] = useState([]);
  const [searchTitletwo, setSearchTitletwo] = useState("");

  useEffect(() => {
    const loadPoststwo = async () => {
      setLoading(true);
      const response = await Apiservice.get(
        // "https://djangoobesitychat.azurewebsites.net/all/doctor/"
        `/user/doctor/clinic/${clinicID}/`
      );
      setPoststwo(response.data);
      // // // //console.log(response.data);
      setLoading(false);
    };
    loadPoststwo();
  }, [clinicID]);

  const minTime24 = poststwo&&poststwo[0]?.in_time;
  // convert time to 12 hour format from 24 hour format
  
  
  const maxTime24 = poststwo&&poststwo[0]?.out_time;
  // convert time to 12 hour format from 24 hour format
  

  function convertTo12HourFormat(time24) {
    if (time24) {
      const [hours, minutes, seconds] = time24.split(':');
      let period = 'AM';
      let convertedHours = hours;
      if (hours >= 12) {
        period = 'PM';
      }
      if (hours > 12) {
        convertedHours -= 12;
      }
      return `${convertedHours}:${minutes} ${period}`;
    }
    return null;
  }
  const minTime = convertTo12HourFormat(poststwo&&poststwo[0]?.in_time);
  const maxTime = convertTo12HourFormat(poststwo&&poststwo[0]?.out_time);
  

  //console.log("minTime",minTime24)
  //console.log("maxTime",maxTime)


  // GET Details of Doctor by Username
  const DoctorIdToGetDetails = localStorage.getItem("DoctorIdToGetDetails");
  const [doctorDetailsinAppointment, setdoctorDetailsinAppointment] = useState(
    []
  );
  const [doctordetails, setdoctordetails] = useState([]);
  const navigate = useNavigate();

  const [submitLoading,setsubmitLoading]=useState(false)

  useEffect(() => {
    if (DoctorIdToGetDetails) {
    Apiservice
      .get(
        // `https://djangoobesitychat.azurewebsites.net/current/user/details/${DoctorIdToGetDetails}/`
        `/current/user/details/${DoctorIdToGetDetails}/`
      )

      .then((response) => {
        setdoctorDetailsinAppointment(response.data);
        // // // //console.log(response.data);
      })
      .catch((err) => {});
    }
  }, [DoctorIdToGetDetails]);

  // POST Appointment Request

  // const clinicid=localStorage.getItem(id)
  const CreateAppointment = async (e) => {
    e.preventDefault();
    setsubmitLoading(true)
    await Apiservice
      .post(
        `/appointmentpostapi/`,

        {
         
          doctor_name: doctorDetailsinAppointment.username,
          patient_id2: patientdetailsinaddpatient.id,
          service: ServiceType,
          starttime: DateFieldNew,
          duration: duration,
          clinic_id: clinicID,
          // subclinic_id: subclinic_id,
          endtime: "2023-03-07T12:19:00Z",
        }
      )
      .then((res) => {
        setsubmitLoading(false)
        localStorage.removeItem("DoctorIdToGetDetails");
        localStorage.removeItem("UserIdtoDetDetails");
        swal({
          title: "Appointment Created Successfully...",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });
        // navigate("/clinicsidebar/appointmentlist");
        if (is_receptionist === "true") {
          navigate("/receptionistsidebar/appointmentlist");
        } else {
          navigate("/clinicsidebar/appointmentlist");
        }
      })
      .catch((res) => {
        setresponse(res.response.data);
        swal({
          title: "Please Fill all the Details...",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });
      });
  };

  const DateFieldNew = date + "T" + time + "Z"
  // // //console.log("DateFieldNew",DateFieldNew)

  

  return (
    <div className="appointment-by-clini-flex-div">
    {submitLoading &&
      <div className="purchase-order-details-pop-up-main-div">
            <div className="purchase-order-details-pop-up-sub-div">
              <div className="purchase-order-details-pop-up-sub-div-container" style={{background:"none"}}>
      <Loader/>
      </div>
      </div>
      </div>
    }
      <div className="appointment-by-clini-width-div">
        <div className="appointment-by-clini-main-div">
          <div className="appointment-by-clini-sub-div-one">
            <h4>Create New Appointment For Doctor</h4>
          </div>
          <div className="appointment-by-clini-sub-div-two">
            <div className="patient-details-in-appointment-main-div">
              <div className="patient-details-in-appointment-sub-div-two-part">
                <div className="patient-details-in-appointment-sub-div-two-part-one">
                  <h4>Search Patient :</h4>
                </div>
                <div className="patient-details-in-appointment-sub-div-two-part-one-two">
                  <input
                    type="text"
                    placeholder="Search Patient..."
                    className="search-patient-in-appt-inp-tag"
                    onClick={toggletwo}
                    onChange={(e) => setSearchTitle(e.target.value)}
                  />
                  {opentwo && (
                    <div className="list-of-all-user" onClick={toggletwo}>
                      {loading ? (
                        <div>
                          <SearchLoader />
                        </div>
                      ) : (
                        posts
                          .filter((value) => {
                            if (searchTitle === "") {
                              return posts;
                            } else if (
                              (value.patient_name,
                              value.patient_firstName
                                ?.toLowerCase()
                                .includes(searchTitle?.toLowerCase()))
                            ) {
                              return value;
                            }
                          })
                          .map((item) => (
                            <div className="list-of-user-main-flex-div">
                              <div className="list-of-user-main-div">
                                <p
                                  onClick={() =>
                                    localStorage.setItem(
                                      "UserIdtoDetDetails",
                                      item.patient_name
                                    )
                                  }
                                >
                                  {item.patient_name}
                                </p>
                              </div>
                            </div>
                          ))
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className="patient-details-in-appointment-sub-div-two">
                <div className="patient-details-in-appointment-sub-div-two-part">
                  <div className="patient-details-in-appointment-sub-div-two-part-one">
                    <p>First Name</p>
                  </div>
                  <div className="patient-details-in-appointment-sub-div-two-part-one-two">
                    <p>{patientdetailsinaddpatient.firstName}</p>
                  </div>
                </div>
                <div className="patient-details-in-appointment-sub-div-two-part">
                  <div className="patient-details-in-appointment-sub-div-two-part-one">
                    <p>Last Name</p>
                  </div>
                  <div className="patient-details-in-appointment-sub-div-two-part-one-two">
                    <p>{patientdetailsinaddpatient.lastName}</p>
                  </div>
                </div>
                <div className="patient-details-in-appointment-sub-div-two-part">
                  <div className="patient-details-in-appointment-sub-div-two-part-one">
                    <p>Gender</p>
                  </div>
                  <div className="patient-details-in-appointment-sub-div-two-part-one-two">
                    <p>{patientdetailsinaddpatient.gender}</p>
                  </div>
                </div>
                <div className="patient-details-in-appointment-sub-div-two-part">
                  <div className="patient-details-in-appointment-sub-div-two-part-one">
                    <p>Age</p>
                  </div>
                  <div className="patient-details-in-appointment-sub-div-two-part-one-two">
                    <p>{patientdetailsinaddpatient.age}</p>
                  </div>
                </div>
                <div className="patient-details-in-appointment-sub-div-two-part">
                  <div className="patient-details-in-appointment-sub-div-two-part-one">
                    <p>Address</p>
                  </div>
                  <div className="patient-details-in-appointment-sub-div-two-part-one-two">
                    <p>{patientdetailsinaddpatient.address}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="patient-details-in-appointment-main-div">
              <div className="patient-details-in-appointment-sub-div-two-part">
                <div className="patient-details-in-appointment-sub-div-two-part-one">
                  <h4>Search Doctor :</h4>
                </div>
                <div className="patient-details-in-appointment-sub-div-two-part-one-two">
                  <input
                    type="text"
                    placeholder="Search Doctor..."
                    className="search-patient-in-appt-inp-tag"
                    onClick={togglethree}
                    onChange={(e) => setSearchTitletwo(e.target.value)}
                  />
                  {openthree && (
                    <div className="list-of-all-user" onClick={togglethree}>
                      {loading ? (
                        <div>
                          <SearchLoader />
                        </div>
                      ) : (
                        poststwo
                          .filter((value) => {
                            if (searchTitletwo === "") {
                              return poststwo;
                            } else if (
                              // value.firstName,
                              value.doctor_name
                                ?.toLowerCase()
                                .includes(searchTitletwo?.toLowerCase())
                            ) {
                              return value;
                            }
                          })
                          .map((item) => (
                            <div className="list-of-user-main-flex-div">
                              <div className="list-of-user-main-div">
                                <p
                                  onClick={() =>
                                    localStorage.setItem(
                                      "DoctorIdToGetDetails",
                                      item.doctor_name
                                    )
                                  }
                                >
                                  {item.doctor_name}
                                </p>
                              </div>
                            </div>
                          ))
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className="patient-details-in-appointment-sub-div-two">
                <div className="patient-details-in-appointment-sub-div-two-part">
                  <div className="patient-details-in-appointment-sub-div-two-part-one">
                    <p>First Name</p>
                  </div>
                  <div className="patient-details-in-appointment-sub-div-two-part-one-two">
                    <p>{doctorDetailsinAppointment.firstName}</p>
                  </div>
                </div>
                <div className="patient-details-in-appointment-sub-div-two-part">
                  <div className="patient-details-in-appointment-sub-div-two-part-one">
                    <p>Last Name</p>
                  </div>
                  <div className="patient-details-in-appointment-sub-div-two-part-one-two">
                    <p>{doctorDetailsinAppointment.lastName}</p>
                  </div>
                </div>
                <div className="patient-details-in-appointment-sub-div-two-part">
                  <div className="patient-details-in-appointment-sub-div-two-part-one">
                    <p>Gender</p>
                  </div>
                  <div className="patient-details-in-appointment-sub-div-two-part-one-two">
                    <p>{doctorDetailsinAppointment.gender}</p>
                  </div>
                </div>
                <div className="patient-details-in-appointment-sub-div-two-part">
                  <div className="patient-details-in-appointment-sub-div-two-part-one">
                    <p>Age</p>
                  </div>
                  <div className="patient-details-in-appointment-sub-div-two-part-one-two">
                    <p>{doctorDetailsinAppointment.age}</p>
                  </div>
                </div>
                <div className="patient-details-in-appointment-sub-div-two-part">
                  <div className="patient-details-in-appointment-sub-div-two-part-one">
                    <p>Address</p>
                  </div>
                  <div className="patient-details-in-appointment-sub-div-two-part-one-two">
                    <p>{doctorDetailsinAppointment.address}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div></div>
          <div className="final-appointment-main-div-flex">
            <div className="final-appointment-main-div">
              <div className="final-appointment-block-div">
                <div className="final-appointment-sub-div-one">
                  <div className="final-appointment-main-div-part-one">
                    <h4>Service</h4>
                  </div>
                  <div className="final-appointment-main-div-part-two">
                    <div className="appointment-details-fill-div">
                      <div
                        className="toggle-drop-down-in-service-main-new"
                        style={{ display: "flex" }}
                      >
                        <div className="toggle-drop-down-in-service-sub">
                          <div>
                            <input
                              type="radio"
                              name="ServiceType"
                              value="Doctor care"
                              onChange={(e) => setServiceType(e.target.value)}
                            />
                          </div>
                          <div>
                            <p>Doctor care</p>
                          </div>
                        </div>
                        <div className="toggle-drop-down-in-service-sub">
                          <div>
                            <input
                              type="radio"
                              name="ServiceType"
                              value="Clinic Care"
                              onChange={(e) => setServiceType(e.target.value)}
                            />
                          </div>
                          <div>
                            <p>Clinic care</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="error-message-preview">
                      <p>{response && response.service}</p>
                    </div>
                  </div>
                </div>

                <div className="final-appointment-sub-div-one">
                  <div className="final-appointment-main-div-part-one">
                    <h4>Date</h4>
                  </div>
                  <div className="final-appointment-main-div-part-two">
                    <div>
                      <input
                        type="date"
                        className="appointment-details-fill-div"
                        // placeholder="dd-mm-yyyy"
                        // value="date"
                        onChange={(e) => setDate(e.target.value)}
                      />
                    </div>
                    <div className="error-message-preview">
                      <p>{response && response.day}</p>
                    </div>
                  </div>
                 
                </div>
                <div className="final-appointment-sub-div-one">
                <div className="final-appointment-main-div-part-one">
                  <h4>Time</h4>
                </div>
                <div className="final-appointment-main-div-part-two">
                <div>
                <label htmlFor="timeInput">Select a time between {minTime} and {maxTime}:</label>
                <input
                  type="time"
                  className="appointment-details-fill-div"
                  step="2"
                  value={time}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= minTime24 && value <= maxTime24) {
                      settime(value);
                    }
                    else{
                      // throw new Error("Invalid time");
                      alert(`Select a time between ${minTime} and ${maxTime}`);
                    }
                  }}
                />
              </div>
              </div>
              </div>
              <div className="error-message-preview">
              <p>{response && response.starttime}</p>
            </div>

                <div className="final-appointment-sub-div-one">
                  <div className="final-appointment-main-div-part-one">
                    <h4>Duration</h4>
                  </div>
                  <div className="final-appointment-main-div-part-two">
                  <div>
                  <input
                    type="number"
                    className="appointment-details-fill-div"
                    placeholder="in Minutes"
                    value={duration}
                    onChange={(e) => setduration(e.target.value)}
                  />
                </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "10px",
              paddingBottom: "5px",
            }}
          >
            {message ? <p>{message}</p> : <p style={{ fontSize: "10px" }}></p>}
          </div>

          <div className="add-pat-btn-div">
            <button className="add-btn-btn-tag" onClick={CreateAppointment}>
              <Add style={{ height: "20px", width: "20px" }} />
              CREATE APPOINTMENT
            </button>
          </div>
        </div>
      </div>
          
    </div>
  );
};

export default AppointmentbyClinic;
