import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTable } from 'react-table';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import "./PatientResponseAnalysis.css"
import Apiservice from "../../Services/Apiservice";
import BodyMap from './BodyMap';

const PatientResponseAnalysis = ({ patientName }) => {
  const [data, setData] = useState(null);
  const [patientData, setPatientData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [opentwo, setOPentwo] = useState(false);
  const [posts, setPosts] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const toggletwo = () => {
    setOPentwo(!opentwo);
  };
  // SEARCH PATIENT
  useEffect(() => {
    const loadPosts = async () => {
      setLoading(true);
      const response = await Apiservice.get(
        "/all/patient/"
      );
      setPosts(response.data);
      setLoading(false);
    };
    loadPosts();
  }, []);

  const UsernameOfPatient = localStorage.getItem("UsernameOfPatient");

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(`https://djangoobesitychat.azurewebsites.net/patient_response_analysis/${UsernameOfPatient}/`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("SavedToken")}`,
        },
      }
      );
      setData(result.data);
      const patientResult = await axios.get(`https://djangoobesitychat.azurewebsites.net/user/patientapi/${UsernameOfPatient}/`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("SavedToken")}`,
        },
      },
      );
      setPatientData(patientResult.data);
      const userResult = await axios.get(`https://djangoobesitychat.azurewebsites.net/current/user/details/${UsernameOfPatient}/`, { headers: { Authorization: `Token ${localStorage.getItem("SavedToken")}` } });
      setUserData(userResult.data);
    };

    fetchData();
  }, [UsernameOfPatient]);

  console.log(userData);


  // useEffect(() => {
  //   const fetchData = async () => {
  //     const result = await axios.post(`https://djangoobesitychat.azurewebsites.net/patient_response_analysis/${UsernameOfPatient}/`,
  //     {
  //       headers: {
  //         Authorization: `Token ${localStorage.getItem("SavedToken")}`,
  //       },
  //     }
  //     );
  //     setData(result.data);
  //   };

  //   fetchData();
  // }, [patientName]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Category Name',
        accessor: 'category_name',
        width: 200,
      },
      {
        Header: 'Total Responses',
        accessor: 'total_responses',
        width: 150,
      },
      {
        Header: 'Total Answered',
        accessor: 'total_answered',
        width: 150,
      },
      {
        Header: 'Total Questions Answered Percentage',
        accessor: 'total_questions_answered_percentage',
        width: 250,
      },
      {
        Header: 'Total Mandatory Question',
        accessor: 'total_mandatory_question',
        width: 200,
      },
      {
        Header: 'Not Answered Mandatory Question',
        accessor: 'not_answered_mandatory_question',
        Cell: ({ value }) => <div style={{ flexWrap: 'wrap' }}>{value}</div>,
        width: 250,
      },
      {
        Header: 'Total Not Answered',
        accessor: 'total_not_answered',
        width: 150,
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data: data?.category_results || [] });

  //console.log(patientData);

  return (
    <div>
      {/* <div>
          <div
            style={{
              display: "flex",
              gap: "10px",
              width: "100%",
              alignItems: "center",
              paddingTop: "25px",
              paddingLeft: "150px",
              paddingRight: "150px",
              justifyContent: "center",
            }}
          >
            <div
              className="patient-details-in-appointment-sub-div-two-part-one"
              style={{ width: "20%" }}
            >
              <h4>Search Patient :</h4>
            </div>
            <div
              className="patient-details-in-appointment-sub-div-two-part-one-two"
              style={{ width: "100%" }}
            >
              <input
                type="text"
                placeholder="Search Patient..."
                className="search-patient-in-appt-inp-tag"
                onClick={toggletwo}
                onChange={(e) => setSearchTitle(e.target.value)}
              />
              
              {opentwo && (
                <div className="list-of-all-user" onClick={toggletwo}>
                  {loading ? (
                    <div style={{ display: "none" }}></div>
                  ) : (
                    posts
                      .filter((value) => {
                        if (searchTitle === "") {
                          // return value;
                        } else if (
                          value.firstName &&
                          value.username
                            ?.toLowerCase()
                            .includes(searchTitle?.toLowerCase())
                        ) {
                          return value;
                        }
                      })
                      .map((item) => (
                        <div className="list-of-user-main-flex-div">
                          <div className="list-of-user-main-div">
                            <p
                              onClick={() =>
                                localStorage.setItem(
                                  "UserIdtoDetDetails",
                                  item.id
                                ) ||
                                localStorage.setItem(
                                  "UsernametogetDocs",
                                  item.username
                                )
                              }
                            >
                              {item.username}
                            </p>
                          </div>
                        </div>
                      ))
                  )}
                </div>
              )}
            </div>
          </div>
        </div> */}
      {patientData && (
        <>
        <h2 style={{ display: "flex", justifyContent: "center", alignItems: "center" }}> {UsernameOfPatient}'s Dashboard </h2>
        <div class="personal-info">
        <div class="card-overall">
        <div class="card-title">{UsernameOfPatient}'s Personal Information</div>

          <div class = "card">
          
            <div class="bg">
              <div class="card-info">
                <p>First Name: {patientData[0]?.patient_firstName}</p>
                <p>Last Name: {patientData[0]?.patient_lastName}</p>
                <p>Age: {userData?.age}</p>
                <p>Gender: {patientData[0]?.gender}</p>
                <p>Height: {patientData[0]?.height}</p>
                <p>Race: {patientData[0]?.race}</p>
                <p>Date of Birth: {patientData[0]?.patient_date_of_birth}</p>
                <p>Email: {patientData[0]?.patient_email}</p>
              
              </div>
            </div>
            <div class="blob"></div>
          
          </div>
        </div>
        <div class="card-overall">
        <div class="card-title">{UsernameOfPatient}'s Key Measurements</div>

          <div class = "card">
          
            <div class="bg">
              <div class="card-info">
                <p>Systolic Blood Pressure: {patientData[0]?.Systolic_blood_pressure}</p>
                <p>Diastolic Blood Pressure: {patientData[0]?.Diastolic_blood_pressure}</p>
                <p>Beginning Weight: {patientData[0]?.beginning_weight}</p>
                <p>Target Weight: {patientData[0]?.target_weight}</p>
                <p>Heart Rate: {patientData[0]?.heart_rate}</p>
                <p>Bust Circumference: {patientData[0]?.bust_circumference_cm}</p>
                <p>Hip Circumference: {patientData[0]?.hip_circumference_cm}</p>
                <p>Waist Circumference: {patientData[0]?.waist_circumference_cm}</p>
                <p>Last Exercise: {patientData[0]?.last_exercise}</p>
                <p>Total Calories: {patientData[0]?.total_calories}</p>
                <p>Last Meal: {patientData[0]?.last_meal}</p>
                <p>Kcal Consumed Range: {patientData[0]?.kcal_consumed_rng}</p>
                
              </div>
            </div>
            <div class="blob"></div>
          
          </div>
        </div>
        </div>
        <div class="personal-info">
        <div class="card-overall">
        <div class="card-title">{UsernameOfPatient}'s Key Health Information</div>

          <div class = "card">
          
            <div class="bg">
              <BodyMap data={patientData} />
            </div>
            <div class="blob"></div>
          
          </div>
        </div>
        <div class="card-overall">
        <div class="card-title">{UsernameOfPatient}'s Phenotype Drilldown</div>

          <div class = "card">
          
            <div class="bg">
            <div class="card">
  <div class="header">Percentage Phenotype</div>
  <div class="body">
    <div class="skill">
      <div class="skill-name">Slow Burn</div>
      <div class="skill-level">
        <div class="skill-percent" style={{width: `${parseFloat(patientData[0]?.phenotype[0]["slow burn"]).toFixed(0)}%`}}></div>
      </div>
      <div class="skill-percent-number">{patientData[0]?.phenotype[0]["slow burn"]}</div>
    </div>
    <div class="skill">
      <div class="skill-name">Hungry Gut</div>
      <div class="skill-level">
        <div class="skill-percent" style={{width: `${parseFloat(patientData[0]?.phenotype[0]["hungry gut"]).toFixed(0)}%`}}></div>
      </div>
      <div class="skill-percent-number">{patientData[0]?.phenotype[0]["hungry gut"]}</div>
    </div>
    <div class="skill">
      <div class="skill-name">Hungry Brain</div>
      <div class="skill-level">
        <div class="skill-percent" style={{width: `${parseFloat(patientData[0]?.phenotype[0]["hungry brain"]).toFixed(0)}%`}}></div>
      </div>
      <div class="skill-percent-number">{patientData[0]?.phenotype[0]["hungry brain"]}</div>
    </div>
    <div class="skill">
      <div class="skill-name">Emotional Hunger</div>
      <div class="skill-level">
        <div class="skill-percent" style={{width: `${parseFloat(patientData[0]?.phenotype[0]["emotional hunger"]).toFixed(0)}%`}}></div>
      </div>
      <div class="skill-percent-number">{patientData[0]?.phenotype[0]["emotional hunger"]}</div>
    </div>
  </div>
</div>
            </div>
            <div class="blob"></div>
          
          </div>
        </div>
        </div>
        </>
        )}
      {data && (
        <>
        
           <div className="patient-response-analysis-container">
            <h1 className="patient-response-analysis-title">{UsernameOfPatient}'s Questionnaire Response Analysis</h1>
            <div className="patient-response-analysis-content">
        <div className="patient-response-analysis-chart">
            <div className="patient-response-analysis-graph">
                <h2 className="patient-response-analysis-graph-title">Total Responses</h2>
                <div className="patient-response-analysis-graph-box">
                    <PieChart width={400} height={400}>
                        <Pie
                            dataKey="value"
                            isAnimationActive={true}
                            data={[
                                { name: 'Total Responses', value: data.total_responses },
                                { name: 'Total Answered', value: data.total_answered },
                                { name: 'Total Not Answered', value: data.total_not_answered },
                            ]}
                            cx={200}
                            cy={200}
                            innerRadius={120}
                            outerRadius={150}
                            fill="#8884d8"
                            label
                        >
                            <Cell fill="rgb(244,180,0)" />
                            <Cell fill="rgb(211,68,91)" />
                            <Cell fill="rgb(107,198,201)" />
                        </Pie>
                        <Tooltip />
                    </PieChart>
                </div>
            </div>
            <div className="patient-response-analysis-graph">
                <h2 className="patient-response-analysis-graph-title">Total Questions Analysis</h2>
                <div className="patient-response-analysis-graph-box">
                    <PieChart width={400} height={400}>
                        <Pie
                            dataKey="value"
                            isAnimationActive={true}
                            data={[
                                { name: 'Total Responses', value: data.total_responses },
                                { name: 'Total Answered', value: data.total_answered },
                                { name: 'Total Not Answered', value: data.total_not_answered },
                            ]}
                            cx={200}
                            cy={200}
                            innerRadius={120}
                            outerRadius={150}
                            fill="#8884d8"
                            label
                        >
                            <Cell fill="rgb(244,180,0)" />
                            <Cell fill="rgb(211,68,91)" />
                            <Cell fill="rgb(107,198,201)" />
                        </Pie>
                        <Tooltip />
                    </PieChart>
                </div>
            </div>
            <div className="patient-response-analysis-graph">
                <h2 className="patient-response-analysis-graph-title">Total Disease Questions Analysis</h2>
                <div className="patient-response-analysis-graph-box">
                    <PieChart width={400} height={400}>
                        <Pie
                            dataKey="value"
                            isAnimationActive={true}
                            data={[
                                { name: 'Total Responses', value: data.total_responses },
                                { name: 'Total Answered', value: data.total_answered },
                                { name: 'Total Not Answered', value: data.total_not_answered },
                            ]}
                            cx={200}
                            cy={200}
                            innerRadius={120}
                            outerRadius={150}
                            fill="#8884d8"
                            label
                        >
                            <Cell fill="rgb(244,180,0)" />
                            <Cell fill="rgb(211,68,91)" />
                            <Cell fill="rgb(107,198,201)" />
                        </Pie>
                        <Tooltip />
                    </PieChart>
                </div>
            </div>
        </div>
        <div className="patient-response-analysis-chart" style={{marginLeft:'80px'}}>
        {data?.category_results
        .filter((category) => !["Emotional", "Social", "Medical_Test"].includes(category.category_name))
        .map((category, index) => (
    <div key={index} className="patient-response-analysis-graph">
        <h2 className="patient-response-analysis-graph-title">{category.category_name} Analysis</h2>
        <div className="patient-response-analysis-graph-box">
            <PieChart width={310} height={400} >
                <Pie
                    dataKey="value"
                    isAnimationActive={true}
                    data={[
                        { name: 'Total Responses', value: category.total_responses },
                        { name: 'Total Answered', value: category.total_answered },
                        { name: 'Total Not Answered', value: category.total_not_answered },
                    ]}
                    cx={100}
                    cy={140}                 
                    innerRadius={80}
                    outerRadius={100}
                    fill="#8884d8"
                    label
                >
                    <Cell fill="rgb(244,180,0)" />
                    <Cell fill="rgb(211,68,91)" />
                    <Cell fill="rgb(107,198,201)" />
                </Pie>
                <Tooltip />
            </PieChart>
        </div>
    </div>
))}
</div>
        

          <div className="patient-response-analysis-table">
          <table {...getTableProps()} style={{ margin: 'auto' }}>
            <thead >
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()} >
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()} style={{minWidth: column.minWidth, width: column.width, backgroundColor: "rgb(49, 163, 214)"}}>{column.render('Header')} </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
          
          </div>
          </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PatientResponseAnalysis;