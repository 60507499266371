import { Routes, Route, BrowserRouter } from "react-router-dom";
import Login from "./components/Login/Login";
import PatientSignUp from "./components/PatientSignUp/PatientSignUp";
import Sidebar from "./components/sidebar/SidebarNew";
import PatientSidebar from "./components/PatientSidebar/PatientSidebar";
import ClinicSidebar from "./components/ClinicSidebar/ClinicSidebar";
import ClinicSignup from "./components/ClinicSignup/ClinicSignup";
import ReceptionistSidebar from "./components/ReceptionistSidebar/ReceptionistSidebar";
import SubClinicSidebar from "./components/SubClinicSidebar/SubClinicSidebar";
import PatientQuestionnaireNew from "./components/PatientQuestionnaire/PatientQuestionnaireNew";
import PatientExtraDetails from "./components/PatientSignUp/PatientExtraDetails";
import ClinicExtraDetails from "./components/ClinicExtraDetails/ClinicExtraDetails";
import TermsandConditions from "./components/TermsandConditions/TermsandConditions";
import PrivacyPolicy from "./components/TermsandConditions/PrivacyPolicy";
import AccessibilityPolicy from "./components/TermsandConditions/AccessibilityPolicy";
import DietitianSidebar from "./components/DietitianSidebar/DietitianSidebar";
import TrainerSidebar from "./components/TrainerSidebar/TrainerSidebar";
import CompanyDetailsVideo from "./components/CompanyDetailsVideo/CompanyDetailsVideo";
import EmailVerification from "./components/PatientSignUp/EmailVerification";
import ForgotPassword from "./components/Login/ForgotPassword";
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools'
import CustomerSuccessDashboard from "./components/CustomerSuccessDashboard/PatientMetrics";
// import ChatSignup from "./components/Chat/ChatSignup";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path= "/customersuccessdashboard/*" element={<CustomerSuccessDashboard/>} />

      {/* ROUTES FOR USER TYPE PATIENT */}
      <Route path="/patientDashboard/*" element={<PatientSidebar />} />

      {/* ROUTES FOR USER TYPE DOCTOR */}
      <Route path="/dashboard/*" element={<Sidebar />} />

      {/* ROUTES FOR USER TYPE CLINC */}
      <Route path="/clinicsidebar/*" element={<ClinicSidebar />} />

      {/* ROUTES FOR USER TYPE SUBCLINIC */}
      <Route path="/subclinicsidebar/*" element={<SubClinicSidebar />} />
      
      {/* ROUTES FOR USER TYPE RECEPTIONIST */}
      <Route path="/receptionistsidebar/*" element={<ReceptionistSidebar />} />

      {/* ROUTES FOR USER TYPE DIETICIAN */}
      <Route path="/dietitiansidebar/*" element={<DietitianSidebar/>} />

      {/* ROUTES FOR USER TYPE TRAINER */}
      <Route path="/trainersidebar/*" element={<TrainerSidebar/>} />

      {/* PATIENT SELF SIGNUP PATH */}
      <Route path="/patientSignup" element={<PatientSignUp />} />
      <Route path="/patientSignup/:id" element={<PatientSignUp />} />
      <Route path="/emailverification" element={<EmailVerification/>} />

      {/* NEWLY REGISTERED PATIENT FIRST TIME QUESTIONNAIRE POST REQUEST  */}
      <Route
      path="/patientquestionnaire"
      element={<PatientQuestionnaireNew />}
    />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/termsandconditions" element={<TermsandConditions/>} />
      <Route path="/privacypolicy" element={<PrivacyPolicy/>} />
      <Route path="/accessibilitypolicy" element={<AccessibilityPolicy/>} />
      <Route path="/companydetailsvideo" element={<CompanyDetailsVideo/>} />

      {/* CLINIC SELF SIGNUP PATH */}
      <Route path="/clinicSignup" element={<ClinicSignup />} />
      <Route path="/clinicextradetails" element={<ClinicExtraDetails/>} />
     
    </Routes>
    {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}



export default App;
