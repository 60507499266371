import axios from "axios";
import React, { useState } from "react";
import "./AddTrainingCategory.css"
import Add from "@mui/icons-material/Add";
import { useEffect } from "react";
import swal from "sweetalert";
import Loader from "../Loader/Loader";
import Apiservice from "../../Services/Apiservice";

const AddTrainingCategory = () => {
  const [exercise_name, setexercise_name] = useState("");
  const [image, setimage] = useState("");
  const [url, seturl] = useState("");
  const [exercisecategory, setexercisecategory] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const Username = localStorage.getItem("Username");
  const [getFoodCategory, setgetFoodCategory] = useState([]);
  useEffect(() => {
    const loadPosts = async () => {
      const response = await Apiservice.get(
        `/exercisecategoryapi/post/`
      );
      setgetFoodCategory(response.data);
    };
    loadPosts();
  }, []);

  const [submitLoading, setsubmitLoading] = useState(false);
  const [response, setresponse] = useState();

  //console.log("response", response);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setsubmitLoading(true);

    const formData = new FormData();
    formData.append("exercise_name", exercise_name);
    formData.append("url", url);
    formData.append("exercisecategory", exercisecategory);
    formData.append("trainer_name", Username);
    formData.append("image", selectedFile && selectedFile);
    const medDocsField = formData.get("image");

    axios({
      method: "post",
      url: `https://djangoobesitychat.azurewebsites.net/exerciseapi/post/`,
      data: formData,
    })
      .then((res) => {
        setsubmitLoading(false);

        swal({
          title: "Exercise Plan Added Successfully...",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });
      })

      .catch((res) => {
        setsubmitLoading(false);
        setresponse(res.response.data);
      });
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  return (
    <div className="diet-prescr-flex-div">
      {submitLoading && (
        <div className="purchase-order-details-pop-up-main-div">
          <div className="purchase-order-details-pop-up-sub-div">
            <div
              className="purchase-order-details-pop-up-sub-div-container"
              style={{ background: "none" }}
            >
              <Loader />
            </div>
          </div>
        </div>
      )}
      <div className="diet-prescr-width-div">
        <h3 className="add-new-pat-h-tag">Add Training Category</h3>
        <div className="diet-prescr-main-div">
          <div className="new-pat-part-one-flex">
            <div className="new-pat-part-one-sub-one">
              <p>Training Name</p>
            </div>
            <div className="new-pat-part-one-sub-two">
              <input
                className="add-patient-input-field"
                placeholder="Training Name"
                value={exercise_name}
                onChange={(e) => setexercise_name(e.target.value)}
              />
            </div>
          </div>
          <div className="new-pat-part-one-flex">
            <div className="new-pat-part-one-sub-one">
              <p>Image</p>
            </div>
            <div className="new-pat-part-one-sub-two">
              <input
                type={"file"}
                className="add-patient-input-field"
                placeholder="PortionSize"
                onChange={handleFileSelect}
              />
            </div>
          </div>
        </div>
        <div className="input-fiend-response-two">
          <p>{response && response.image}</p>
        </div>

        <div className="diet-prescr-main-div">
          <div className="new-pat-part-one-flex">
            <div className="new-pat-part-one-sub-one">
              <p>URL</p>
            </div>
            <div className="new-pat-part-one-sub-two">
              <input
                className="add-patient-input-field"
                placeholder="URL"
                value={url}
                onChange={(e) => seturl(e.target.value)}
              />
            </div>
          </div>

          <div className="new-pat-part-one-flex">
            <div className="new-pat-part-one-sub-one">
              <p>Exercise Category</p>
            </div>
            <div className="new-pat-part-one-sub-two">
              <select
                className="add-patient-input-field"
                value={exercisecategory}
                onChange={(e) => setexercisecategory(e.target.value)}
              >
                <option>Select Exercise Category</option>
                {getFoodCategory &&
                  getFoodCategory?.map((item) => (
                    <option key={item.id} value={item.exercisecategory_name}>
                      {item.exercisecategory_name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>

        <div className="add-pat-btn-div">
          <button className="add-btn-btn-tag" onClick={handleSubmit}>
            <Add style={{ height: "20px", width: "20px" }} />
            Upload Training Category
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddTrainingCategory;
