import React, { useState } from "react";
import axios from "axios";
import "./ForgotPassword.css";
import Loader from "../Loader/Loader";



const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [successMessage, setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState(null);


  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setLoading(true);
    axios
      .get(`https://djangoobesitychat.azurewebsites.net/current/user/details/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("SavedToken")}`,
        },
      })
      .then((response) => {
        //console.log(response.data);
        // iterate through the list to find the user based on email
        response.data.forEach((user) => {
          if (user.email === event.target.value && user.is_patient) {
            //console.log(user.email);
            setUserType("patient");
          }
          else if (user.email === event.target.value && !user.is_patient) {
            //console.log(user.email);
            setUserType("doctor");
          }
        }
        );
        setLoading(false);
      })
      .catch((error) => {
        //console.log(error);
        setLoading(false);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    axios
      .post("https://djangoobesitychat.azurewebsites.net/password_reset/", { email, username })
      .then((response) => {
        //console.log(response.data);
        if (response.status === 200) {
          setSuccessMessage("Password reset link sent successfully");
        }

      })
      .catch((error) => {
        setLoading(false);
        //console.log(error.response.data);
      });
  };

  return (
    <div className="container">
      <div className="card-forgot-pass">
        <p className="lock-icon">
          <i className="fas fa-lock"></i>
        </p>
        <h2>Forgot Password?</h2>
        <p>You can reset your Password here</p>
        <form id="forgotPasswordForm" onSubmit={handleSubmit}>
          <input
            type="text"
            className="passInput"
            placeholder="Email address"
            required
            value={email}
            onChange={handleEmailChange}
          />
          {userType !== "patient" && (
          
            
            <input
              type="text"
              value={username}
              className="passInput" placeholder="Username"
              onChange={(event) => setUsername(event.target.value)}
            />
          
        )}
          {/* <input type="text" className="passInput" placeholder="Username" required value={username} onChange={(e) => setUsername(e.target.value)} /> */}
          <button className="forgot-pass-btn" type="submit">Send the reset link</button>
        </form>
        {successMessage && <h4 style={{ margin: '4px' }} className="success-message">{successMessage}</h4>}
      </div>
    </div>
  );
};

export default ForgotPassword;