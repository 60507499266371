import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import axios from "axios";
import Add from "@mui/icons-material/Add";
import { AiOutlineClose } from "react-icons/ai";
import "./PatientEventsForDoctor.css";
import swal from "sweetalert";
import Apiservice from "../../Services/Apiservice";

const PatientEventsForDoctor = () => {
  const patientUserName = localStorage.getItem("patientUserName");
  const LoginDoctorID = localStorage.getItem("id");

  function ImageCellRenderer(params) {
    const imageUrl = params.value;
    return <img style={{ height: "3em" }} src={imageUrl} alt="Cell" />;
  }
  const gridRef = useRef();
  const [columnDefs, setColumnDefs] = useState([
    { field: "date", filter: true, headerName: "Date" },
    { field: "events", filter: true, headerName: "Event" },
    { field: "Event_details", filter: true, headerName: "Event Details" },
    { field: "next_steps", headerName: "Next Steps" },
    { field: "doctor_firstName", headerName: "Created by First Name" },
    { field: "doctor_lastName", headerName: "Created by Last Name" },
  ]);
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    resizable: true,
    suppressToolPanel: true,
  }));
  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(
      document.getElementById("filter-text-box").value
    );
  }, []);
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    fetch(`https://djangoobesitychat.azurewebsites.net/event/user/${patientUserName}/`, {
      headers: {
        Authorization: `Token ${localStorage.getItem("SavedToken")}`,
      },
    })
      .then((data) => data.json())
      .then((data) => setTableData(data));
    // setLoading(false);
  }, []);

  const [PopUp, setPopUp] = useState(false);
  const togglePopUp = () => {
    setPopUp(!PopUp);
  };
  const closePopup = () => {
    setPopUp(false);
  };

  // SEARCH EVENTS

  const [poststhree, setPoststhree] = useState([]);
  const [searchTitlethree, setSearchTitlethree] = useState("");
  const [openfour, setOPenfour] = useState(false);
  const [loading, setLoading] = useState(false);
  const togglefour = () => {
    setOPenfour(!openfour);
  };

  useEffect(() => {
    const loadPoststhree = async () => {
      setLoading(true);
      const response = await Apiservice.get("/commoneventapi/");
      setPoststhree(response.data);
      // // //console.log("event", response.data);
      setLoading(false);
    };
    loadPoststhree();
  }, []);

  const [Event_details, setEvent_details] = useState([]);
  const [next_steps, setnext_steps] = useState("");
  const [date, setdate] = useState([]);
  const [response, setresponse] = useState();

  // POST EVENT

  const CreateEvent = async (e) => {
    e.preventDefault();
    await Apiservice
      .post(
        `/allevents/`,
        {
          patient_name:patientUserName,
          doctor_id:LoginDoctorID,
          events:localStorage.getItem("EventName"),
          Event_details:Event_details,
          next_steps:next_steps,
          date:date
        },
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("SavedToken")}`,
          },

        }
        )
        .then((res) => {
          // // // //console.log("Response",res);
          swal({
            title: "Event Created Successfully...",
            className: "successAlert",
            buttons: true,
            timer: 3000,
          });
          fetch(`https://djangoobesitychat.azurewebsites.net/event/user/${patientUserName}/`, {
            headers: {
              Authorization: `Token ${localStorage.getItem("SavedToken")}`,
            },
          })
          .then((data) => data.json())
          .then((data) => setTableData(data));
          togglePopUp()
        })
        .catch((res) => {
          // // //console.log("err", res.response.data);
          setresponse(res.response.data);
        });
    };


  return (
    <div>
      <div className="add-pat-btn-div" style={{ justifyContent: "end" }}>
        <button className="add-btn-btn-tag" onClick={togglePopUp}>
          <Add style={{ height: "20px", width: "20px" }} />
          Create Event
        </button>
      </div>

      {PopUp ? (
        <div className="doctor-patient-prescription-main-div-for-popup">
          <div
            className="doctor-patient-prescription-sub-div-for-popup"
            style={{ zIndex: "1" }}
          >
            <div
              className="doctor-patient-prescription-sub-div-for-popup-close-div"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <AiOutlineClose
                onClick={closePopup}
                className="close-btn-in-pop-up-of-presc"
              />
            </div>
            <div className="create-events-by-doctor-main-div">
              <div className="event-by-clinic-sub-div-two">
                <div className="events-patient-details-main-div">
                  <div className="user-details-get-main-div">
                    <div
                      className="patient-details-in-appointment-sub-div-two-part-one-two-"
                      style={{ width: "100%" }}
                    >
                      <input
                        type="text"
                        placeholder="Search Events..."
                        className="search-patient-in-appt-inp-tag"
                        onClick={togglefour}
                        onChange={(e) => setSearchTitlethree(e.target.value)}
                      />
                      {openfour && (
                        <div className="list-of-all-user" onClick={togglefour}>
                          {loading ? (
                            <div style={{ display: "none" }}></div>
                          ) : (
                            poststhree
                              .filter((value) => {
                                if (searchTitlethree === "") {
                                  return poststhree;
                                } else if (
                                  value.event
                                    ?.toLowerCase()
                                    .includes(searchTitlethree?.toLowerCase())
                                ) {
                                  return value;
                                }
                              })
                              .map((item) => (
                                <div className="list-of-user-main-flex-div">
                                  <div className="list-of-user-main-div">
                                    <p
                                      onClick={() =>
                                        localStorage.setItem(
                                          "EventName",
                                          item.event
                                        )
                                      }
                                    >
                                      {item.event}
                                    </p>
                                  </div>
                                </div>
                              ))
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                </div>

                <div className="events-patient-details-main-div">
                  <div>
                    <h3>Event</h3>
                  </div>
                  <div className="user-details-get-main-div">
                    <p>{localStorage.getItem("EventName")}</p>
                  </div>
                </div>
              </div>
              <div className="event-by-clinic-sub-div-three">
              <div style={{ width: "17%" }}>
                <h3>Event Details</h3>
              </div>
              <div className="event-details-text-area-main-div">
                <textarea placeholder="Event Details..." 
                value={Event_details}
                onChange={(e) => setEvent_details(e.target.value)}
                />
                <div className="error-message-preview">
                <p>{response && response.Event_details}</p>
              </div>
              </div>
            </div>
  
  
            <div className="event-by-clinic-sub-div-three">
              <div style={{ width: "17%" }}>
                <h3>Event Date</h3>
              </div>
              <div className="event-details-text-area-main-div">
                <input type={"date"}  
                value={date}
                onChange={(e) => setdate(e.target.value)}
                />
                <div className="error-message-preview">
                <p>{response && response.date}</p>
              </div>
              </div>
            </div>
  
  
  
            <div className="event-by-clinic-sub-div-three">
              <div style={{ width: "17%" }}>
                <h3>Next Steps</h3>
              </div>
              <div className="event-details-text-area-main-div">
                <textarea placeholder="Next Steps..." 
                value={next_steps}
                onChange={(e) => setnext_steps(e.target.value)}
                />
                <div className="error-message-preview">
                <p>{response && response.next_steps}</p>
              </div>
              </div>
            </div>
            </div>

            <div className="add-pat-btn-div" style={{ justifyContent: "center" }}>
        <button className="add-btn-btn-tag" onClick={CreateEvent}>
          <Add style={{ height: "20px", width: "20px" }} />
          Create Event
        </button>
      </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div
        className="ag-theme-material"
        style={{
          width: "100%",
          height: "150vh",
          boxShadow: "rgb(0 0 0 / 35%) 0px 5px 15px",
          marginTop: "20px",
          overflowY: "scroll",
        }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={tableData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          rowSelection="multiple"
        />
      </div>
    </div>
  );
};

export default PatientEventsForDoctor;
