import React, { useEffect, useState } from "react";
import "./PhenotypeDrilldownDoughnut.css";
import { PieChart, Pie, Legend, Label, ResponsiveContainer, Tooltip } from "recharts";
import axios from "axios";
import { json } from "react-router-dom";
import Apiservice from "../../Services/Apiservice";



const PhenotypeDrilldownDoughnut = () => {

  const [drilldown,setdrilldown]=useState()
  const patientUserName = localStorage.getItem("patientUserName")

  useEffect(()=>{
    Apiservice.get(`/phenotypedrilldown/${patientUserName}/`)
    .then((response)=>{
      setdrilldown(response.data["categorized"])
    })
    .catch(error =>{
    })
  },[patientUserName])

  //console.log("drilldown",drilldown)

  



  const data = [
    { name: "Hungry Brain", value: parseFloat(drilldown&&drilldown[`hungry brain`]), fill: "#d00000" },
    { name: "Emotional Hunger", value: parseFloat(drilldown&&drilldown[`emotional hunger`]), fill: "#ffba08" },
    { name: "Hungry Gut", value: parseFloat(drilldown&&drilldown[`hungry gut`]), fill: "#3f88c5" },
    { name: "Slow Burn", value: parseFloat(drilldown&&drilldown[`slow burn`]), fill: "#032b43" },
  ];

  return (
    
    <ResponsiveContainer
      width={303}
      height={319}
      className="chart-resp-cont-in-pheno"
    >
      <PieChart>
      <Tooltip
      formatter={(value, name) => [`${value}%`, name]}
      />
        <Pie
          data={data}
          cx={100}
          cy={180}
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
          align="center"
          stroke="none"
          className="pie-in-phno"
        ></Pie>
        <Legend verticalAlign="top" align="left" height={1} />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PhenotypeDrilldownDoughnut;
