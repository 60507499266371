import { useRef, useEffect,useState } from "react";
const AutoComplete = (props) => {
const [address, setaddress] = useState("");
 const autoCompleteRef = useRef();
 const inputRef = useRef();
 const options = {};
 useEffect(() => {
  autoCompleteRef.current = new window.google.maps.places.Autocomplete(
   inputRef.current,
   options
  );
  autoCompleteRef.current.addListener("place_changed", async function () {
   const place = await autoCompleteRef.current.getPlace();
   setaddress(place.formatted_address)
   props.parentCallback(address)
 
  });
 }, [address]);

 return (
        <input
            type="text"
            className="email-input-field-tag"
            placeholder="Address"
            value={props.address}
            ref={inputRef}
            onChange={props.parentCallback(address)}
/> 
 );
};
export default AutoComplete;