import React, { useEffect, useState ,
    useMemo,
    useRef,
    useCallback,} from "react";
  // import "./PatientPrescription.css";
  import { AgGridReact } from "ag-grid-react"; 
  import "ag-grid-community/styles/ag-grid.css"; 
  import "ag-grid-community/styles/ag-theme-material.css"; 
  import axios from "axios";
  
  
  const PatientHistoryForDoctorClinic = () => {
    const UserNameforHistory = localStorage.getItem("patientUserName");
  
    function ImageCellRenderer(params) {
      const imageUrl = params.value;
      return <img style={{ height: "3em" }} src={imageUrl} alt="Cell" />;
    }
    const gridRef = useRef();
    const [columnDefs, setColumnDefs] = useState([
      // { field: "date", filter: true, headerName: "Date" , width:"30"},
      { field: "activity_type", filter: true, headerName: "Activity" },
    { field: "updatedate", filter: true, headerName: "Updated at" ,width:"30"},
      // { field: "Event_details", filter: true, headerName: "Event Details" },
      // { field: "next_steps", headerName: "Next Steps" },
      // { field: "doctor_name", headerName: "Doctor Name" },
    ]);
    const defaultColDef = useMemo(() => ({
      flex: 1,
      sortable: true,
      filter: "agTextColumnFilter",
      resizable: true,
      suppressToolPanel: true,
    }));
    const onFilterTextBoxChanged = useCallback(() => {
      gridRef.current.api.setQuickFilter(
        document.getElementById("filter-text-box").value
      );
    }, []);
    const [tableData, setTableData] = useState([]);
    useEffect(() => {
      fetch(`https://djangoobesitychat.azurewebsites.net/notifications/${UserNameforHistory}/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("SavedToken")}`,
        },
      })
        .then((response) => response.json())
        .then((data) => setTableData(data));
        
    }, []);
  
    //console.log("Hisotry Table",tableData);
  
    return (
      <div>
     
      <div
      className="ag-theme-material"
      style={{ width: "100%", height: "140vh", boxShadow:"rgb(0 0 0 35%) 0px 5px 15px", marginTop:"20px" }}
      >
      <AgGridReact
      ref={gridRef} 
      rowData={tableData} 
      columnDefs={columnDefs} 
      defaultColDef={defaultColDef} 
      animateRows={true} 
      rowSelection="multiple" 
    />
      </div>
      </div>
    );
  };
  
  export default PatientHistoryForDoctorClinic;
  