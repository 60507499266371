import React, { useState, Fragment, useEffect } from "react";
import "./Calender.css";
// import 'react-calendar/dist/Calendar.css'
import Calendar from "react-calendar";
import Modal from "@mui/material/Modal";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Box, Grid } from "@mui/material";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PatientLogo from "../../Images/ClinicSVG/newdoctor.svg";
import { Link } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import NotesIcon from "@mui/icons-material/Notes";
import moment from "moment/moment";
import Apiservice from "../../Services/Apiservice";
const style = {
  position: "absolute",
  top: "80%",
  left: "60%",
  right: "100%",
  bottom: "2vw",
  transform: "translate(-60%, -100%)",
  height: "70vh",
  width: "32vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
};

const Calender = (props) => {
  const [value, onChange] = useState(new Date());
  const [open, setOpen] = useState(false);
  const [datesWithAppointments, setDatesWithAppointments] = useState([]);
  const [showTime, setShowTime] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = (value) => {
    setOpen(false);
  };
  const [mypatients, setmypatients] = useState([]);
  const DoctorName = localStorage.getItem("Username");
  const isdietitian = localStorage.getItem("is_dietitian");
  const is_trainer = localStorage.getItem("is_trainer");

  const [appointmentget, setappointmentget] = useState("");
  useEffect(() => {
    Apiservice
      .get(
        isdietitian == "true"
          ? `/appointmentapi/dietitian/${DoctorName}/`
          : is_trainer == "true"
          ? `/appointmentapi/trainer/${DoctorName}/`
          : `/appointmentapi/doctor/${DoctorName}/`,
        {}
      )

      .then((response) => {
        setappointmentget(response.data);
        // setLoading(false);
      })
      .catch((err) => {});
  }, [props.dependency]);

  //console.log("appointmentget",appointmentget)

  const dates =
    appointmentget &&
    appointmentget
      .filter((appointment) => appointment.is_approved === "Approved")
      .map((appointment) => appointment.starttime?.slice(0, 10));
  //console.log("dates", dates);
  const tileContent = ({ date, view }) => {
    const appointmentDate = date.toDateString();
    if (dates.includes(appointmentDate)) {
      return <div className="appointment-date"></div>;
    }
    return null;
  };
  const date = value.toLocaleDateString("fr-CA");

  const handleClick = (key) => {
    localStorage.setItem("patientUserName", key);
  };

  const PatientUsernameforSummary = localStorage.getItem("patientUserName");

  return (
    <div className="calender-main-div">
      <Calendar
        onChange={onChange}
        value={value}
        onClickDay={handleOpen}
        tileClassName={({ date }) => {
          let day = date.getDate();
          let month = date.getMonth() + 1;
          if (month < 10) {
            month = "0" + month;
          }
          if (day < 10) {
            day = "0" + day;
          }
          const realDate = date.getFullYear() + "-" + month + "-" + day;
          return dates.includes(realDate) ? "appointment-date" : "";
        }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
          sx={{
            height: "40vh",
            width: "100%",
            margin: "auto",
          }}
        >
          <Grid
            item
            xs={14}
            sx={style}
            justify="flex-end"
            style={{ width: "500px" }}
          >
            <Button sx={{ marginTop: -4 }} onClick={handleClose}>
              Close
            </Button>
            <div style={{ display: "flex" }}>
              <p
                style={{
                  fontSize: 12,
                  marginLeft: -4,
                  marginTop: "2vh",
                  marginBottom: "2vh",
                }}
              >
                {value.toLocaleDateString("en-CA")}
              </p>
              <p
                style={{
                  marginTop: "2vh",
                  marginBottom: "2vh",
                  marginLeft: "5vw",
                }}
              >
                Appointments
              </p>
              {/* <Divider /> */}
            </div>

            <div className="dahs-board-main-div-two-sub-one-new">
              <TableContainer component={Paper} style={{ height: "50vh" }}>
                <Table aria-label="simple table">
                  <TableBody>
                    {appointmentget &&
                      appointmentget.map((row) => (
                        <TableRow
                          key={row && row.name}
                          id={row && row.patient_id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          onClick={() =>
                            handleClick(row && row.patient_username)
                          }
                        >
                          {row &&
                          row.starttime.slice(0, 10) == date &&
                          row &&
                          row.is_approved == "Approved" ? (
                            <div>
                              <TableCell
                                align="left"
                                style={{ padding: "10px" }}
                              >
                                <p>{row && row.appoinment_id}</p>
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "10px" }}
                              >
                                <div style={{ display: "flex", gap: "2px" }}>
                                  <h3
                                    style={{
                                      fontSize: "14px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {row && row.patient_firstName}{" "}
                                    {row && row.patient_lastName}
                                  </h3>
                                </div>
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "10px" }}
                              >
                                <p>{row && row.service}</p>
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "10px" }}
                              >
                                <p> {row && row.revisit_data}</p>
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "10px" }}
                              >
                                <p>{row && row.starttime.slice(11, 16)}</p>
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "10px", cursor: "pointer" }}
                              >
                                <Link

                                  to={
                                    isdietitian == "true"
                                      ? `/dietitiansidebar/webcam/${PatientUsernameforSummary}`
                                      : is_trainer == "true"
                                      ? `/trainersidebar/webcam/${PatientUsernameforSummary}`
                                      : `/dashboard/webcam/${PatientUsernameforSummary}`
                                  }
                                >
                                  <CameraAltIcon
                                    style={{
                                      fill: "rgb(216 74 74)",
                                      marginBottom: "-8px",
                                    }}
                                  />
                                </Link>
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "10px", cursor: "pointer" }}
                              >
                                <Link 
                                
                                to={
                                  isdietitian == "true"
                                    ? "/dietitiansidebar/callnotes"
                                    : is_trainer == "true"
                                    ? "/trainersidebar/callnotes"
                                    : "/dashboard/callnotes"
                                }
                                
                                >
                                  <NotesIcon
                                    style={{
                                      fill: "rgb(216 74 74)",
                                      marginBottom: "-8px",
                                    }}
                                  />
                                </Link>
                              </TableCell>
                            </div>
                          ) : (
                            ""
                          )}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};

export default Calender;
