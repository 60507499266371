import axios from "axios";
import React, { useEffect, useState, useMemo, useRef } from "react";
import "./CallNotes.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { AiOutlineClose } from "react-icons/ai";
import Apiservice from "../../Services/Apiservice";

const CallNotes = () => {
  const PatientUsername = localStorage.getItem("patientUserName");

  const gridRef = useRef();
  const [columnDefs, setColumnDefs] = useState([
    {
      field: "callnotes_id",
      filter: true,
      headerName: "ID",
      headerClassName: "super-app-theme--header",
    },
    { field: "meeting_date", filter: true, headerName: "Date" },
    {
      field: "patient_firstName",
      filter: true,
      headerName: "Patient FirstName",
    },
    { field: "patient_lastName", headerName: "Patient LastName" },
  ]);
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    resizable: true,
    suppressToolPanel: true,
  }));

  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    fetch(`https://djangoobesitychat.azurewebsites.net/callnotes/patient/${PatientUsername}/`, {
      method: "GET",
      headers: {
        "Authorization": `Token ${localStorage.getItem("SavedToken")}`,
      },
    }
      
    )
      .then((data) => data.json())
      .then((data) => setTableData(data));
  }, []);

  const callnotes_id = localStorage.getItem("callnotes_id")

  const[callNote,setcallNote]=useState("")
  useEffect (()=>{
    Apiservice.get(`/callnotes/callnotes_id/${callnotes_id}/`)
    .then((response) => {
      setcallNote(response.data[0]);
    })
    .catch((err) => {});
}, [callnotes_id])


  const [popup, setPop] = useState(false);
  const handleClickOpen = () => {
    setPop(!popup);
  };
  const handleClick = (params) => {
    localStorage.setItem("callnotes_id",params.data.callnotes_id);
    handleClickOpen();
  };





  return (
    <div className="call-notes-flex-div">
      <div className="call-notes-width-div">
        <div className="call-notes-main-div">
          <div
            className="ag-theme-material"
            style={{
              width: "100%",
              height: "150vh",
              boxShadow: "rgb(0 0 0 / 35%) 0px 5px 15px",
              marginTop: "20px",
              overflowY: "scroll",
            }}
          >
            <AgGridReact
              ref={gridRef}
              rowData={tableData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              animateRows={true}
              onCellClicked={handleClick}
              rowSelection="multiple"
            />
          </div>

          {popup &&(
            <div className="call-notes-pop-up-flex-div">
            <div className="doctor-patient-prescription-sub-div-for-popup-close-div">
                <AiOutlineClose
                  onClick={handleClickOpen}
                  className="close-btn-in-pop-up-of-presc"
                />
              </div>
            <p>{callNote&&callNote.notes}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CallNotes;
