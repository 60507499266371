import React, { useState } from "react";
import EntityList from "./Entity";
import "./DiseaseHandler.css";

function ICDSearch() {
  const [query, setQuery] = useState("");
  const [entityList, setEntityList] = useState([]);

  const handleSearch = async (event) => {
    event.preventDefault();

    // Fetch the list of entities from the ICD API using the search query
    const response = await fetch(`https://djangoobesitychat.azurewebsites.net/diseaseadd/${query}/`);
    const data = await response.json();
    const entities = data.destinationEntities.map((result) => result.title);
    // const entities ={}
    // //console.log(entities);

    // Update the state with the new entity list
    setEntityList(entities);
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  const handleAddButtonClick = (entity) => {
    // make API call to Django API
    fetch("https://djangoobesitychat.azurewebsites.net/diseaseapi/", {
      method: "POST",
      body: JSON.stringify({disease_name: entity}),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      // .then((data) => //console.log(data))
      .catch((error) => console.error(error));
  };

  return (
    <>
      <div className="disease-handler-input-search-tag-main-flex-div">
        <form onSubmit={handleSearch}>
          <div className="disease-handler-input-search-tag-div">
            <div>
              <input
                className="disease-handler-input-search-tag"
                placeholder="Search Disease"
                type="text"
                value={query}
                onChange={handleQueryChange}
              />
            </div>
            <div>
              <button
                className="disease-handler-input-search-tag-btn"
                type="submit"
              >
                Search
              </button>
            </div>
          </div>
        </form>

        {entityList.length > 0 && (
          <EntityList
            entities={entityList}
            onAddButtonClick={handleAddButtonClick}
          />
        )}
      </div>
      {/* <div>
       <h2>Entity List</h2>
      <ul>
        {entityList.map((entity) => (
          <li key={entity.id}>{entity}</li>
        ))}
      </ul>
    </div> */}
    </>
  );
}

export default ICDSearch;
