import axios from "axios";
import React, { useEffect, useMemo, useRef, useCallback } from "react";
import { useState } from "react";
import "./PrescriptionView.css";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-material.css"; // Optional theme CSS
import Loader from "../Loader/Loader";
import { AiOutlineClose } from "react-icons/ai";
import Apiservice from "../../Services/Apiservice";
// import logo from "./Anirban_DigitalSignature.jpg";

const PrescriptionView = (patientusernameforprescription) => {
  const Username = localStorage.getItem("Username");
  const [proddetails, setproddetails] = useState(false);
  const is_patient = localStorage.getItem("is_patient");
  const patientUserName = localStorage.getItem("patientUserName");
  const [getPrescriptionId, setgetPrescriptionId] = useState([{ prescription_id: "" }]);
  const [getPrescriptionMedicine, setgetPrescriptionMedicine] = useState([{medicine_name: ""}]);
  const PrescriptionID = localStorage.getItem("PrescriptionID");
  const [medicineLoading, setmedicineLoading] = useState(true);
  const [submitLoading, setsubmitLoading] = useState(false);
  
  const onClickShowPopup = async (params) => {
    setsubmitLoading(true)
    localStorage.setItem("PrescriptionID", params.data.prescription_id);
    const url = `/prescription2api/prescription_id/${params.data.prescription_id}/`
    try {
      const response = await Apiservice.get(url);
      // Do something with the response data, e.g., update the state
      setgetPrescription(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setgetPrescriptionId(params.data.prescription_id);
    setproddetails((prevProddetails) => !prevProddetails);
    setsubmitLoading(false)
  };

  // //console.log("proddetails", proddetails);

  const closeproddetails = () => {
    setproddetails(false);
  };

  const headerHeight = 50;
  const gridOptions = {
    onColumnResized: (params) => {
      // //console.log(params);
    },
  };
  function sizeToFit(skipHeader) {
    const allColumnIds = [];
    if (gridOptions && gridOptions.columnApi) {
      gridOptions.columnApi.getColumns().forEach((column) => {
        allColumnIds.push(column.getId());
      });
    } else {
      console.error('gridOptions or gridOptions.columnApi is not defined');
    }

    gridOptions.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }
  const gridRef = useRef();
  const [columnDefs, setColumnDefs] = useState([
    {
      field: "disease_name",
      filter: true,
      headerName: "Diagnosis",
      onCellClicked: { onClickShowPopup },
      width: 330,
      headerClass: "header-in-aggrement-table",
    },
    {
      field: "prescription_date",
      filter: true,
      headerName: "Date",
      width: 330,
      headerClass: "header-in-aggrement-table",
    },
    {
      field: "doctors_clinic_name",
      filter: true,
      headerName: "Clinic",
      width: 200,
      headerClass: "header-in-aggrement-table",
    },
    {
      field: "doctor_firstName",
      headerName: "Doctor First Name",
      editable: true,
      width: 300,
      headerClass: "header-in-aggrement-table",
    },
    {
      field: "doctor_lastName",
      headerName: "Doctor Last Name",
      editable: true,
      width: 200,
      headerClass: "header-in-aggrement-table",
    },
  ]);
  const defaultColDef = useMemo(() => ({
    sortable: true,
    filter: "agTextColumnFilter",
    resizable: true,
    flex: 1,
    minWidth: 180,
  }));
  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(
      document.getElementById("filter-text-box").value
    );
  }, []);
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    fetch(
      is_patient == "true"
        ? `https://djangoobesitychat.azurewebsites.net/prescription2api/patient_name/${patientusernameforprescription?.patientusernameforprescription}/`
        : `https://djangoobesitychat.azurewebsites.net/prescription2api/patient_name/${patientusernameforprescription?.patientusernameforprescription}/`,
      {}
    )
      .then((data) => data.json())
      .then((data) => setTableData(data));
  }, [patientusernameforprescription?.patientusernameforprescription]);

  const [getPrescription, setgetPrescription] = useState([{ 
    comment: "",
    disease_name : "",
    doctor_email : "",
    doctor_firstName : "",
    doctor_id : "",
    doctor_lastName : "",
    doctor_mobile : "",
    doctor_name : "",
    doctors_clinic_address : "",
    doctors_clinic_email : "",
    doctors_clinic_id : "",
    doctors_clinic_mobile : "",
    doctors_clinic_name : "",
    doctors_disease_specialist : "",
    doctor_signature : "",
    medicine_name : [],
    patient_firstName : "",
    patient_lastName : "",
    patient_name : "",
    prescription_date : "",
    prescription_id : "",
    updatedate : "" }]);
  
  
  // //console.log(getPrescriptionId)
    
  

  
  // const fetchPrescription = async () => {
  //   const response = await axios.get(`https://djangoobesitychat.azurewebsites.net/prescription2api/prescription_id/${getPrescriptionId}/`);
  //   setgetPrescription(response.data);
  // };

  // useEffect(() => {
  //   fetchPrescription();
  // }, [getPrescriptionId]);




  // useEffect(() => {
  //   axios
  //     .get(
  //       `https://djangoobesitychat.azurewebsites.net/prescription2api/prescription_id/${PrescriptionID}/`,
  //       {}
  //     )

  //     .then((response) => {
  //       setgetPrescription(response.data);
  //       // setgetPrescriptionMedicine(response.data["medicine_name"]);
  //       // setLoading(false);
  //     })
  //     .catch((err) => {});
  // }, [PrescriptionID]);

  // //console.log("getPrescriptionMedicine", getPrescriptionMedicine);

  const Print = () => {
    //// // //console.log('print');
    let printContents = document.getElementById("printablediv").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };

  // //console.log("getPrescription", getPrescription);

  return (
    <div className="pre-scr-pti-on-view-for-pat-ient-doctor-cli-nic-flex-div">
    {submitLoading && (
      <div className="purchase-order-details-pop-up-main-div">
        <div className="purchase-order-details-pop-up-sub-div">
          <div
            className="purchase-order-details-pop-up-sub-div-container"
            style={{ background: "none" }}
          >
            <Loader />
          </div>
        </div>
      </div>
    )}
      <div className="pre-scr-pti-on-view-for-pat-ient-doctor-cli-nic-width-div">
        <div
          className="appointment-list-sub-div-one"
          style={{ marginBottom: "20px" }}
        >
          <h3>All Prescriptions</h3>
        </div>

        <div
          className="ag-theme-material"
          style={{
            width: "100%",
            height: "90vh",
            boxShadow: "rgb(0 0 0 / 35%) 0px 5px 15px",
            marginTop: "20px",
          }}
        >
          {!tableData ? (
            <Loader />
          ) : (
            <AgGridReact
              ref={gridRef} // Ref for accessing Grid's API
              rowData={tableData} // Row Data for Rows
              columnDefs={columnDefs} // Column Defs for Columns
              defaultColDef={defaultColDef} // Default Column Properties
              animateRows={true} // Optional - set to 'true' to have rows animate when sorted
              rowSelection="multiple" // Options - allows click selection of rows
              // onCellValueChanged={onCellValueChanged} // Optional - registering for Grid Event
              // onCellClicked={cellClickedListener} // Optional - registering for Grid Event
              onCellClicked={onClickShowPopup}
              headerHeight={headerHeight}
              onGridReady={sizeToFit}
            />
          )}

        </div>

        {proddetails ? (
          <div className="purchase-order-details-pop-up-main-div">
            <div className="purchase-order-details-pop-up-sub-div">
              <div className="purchase-order-details-pop-up-sub-div-container">
                <div
                  className="purchase-order-details-pop-up-sub-div-container-one"
                  style={{height:"35em"}}
                >
                  <div
                    className="doctor-patient-prescription-sub-div-for-popup-close-div"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <AiOutlineClose
                      className="close-btn-in-pop-up-of-presc"
                      onClick={closeproddetails}
                    />
                    <button
                      type="button"
                      onClick={Print}
                      className="add-btn-btn-tag"
                    >
                      Print Prescription
                    </button>
                  </div>

                   
                    
                    <div className="prescription-main-card-for-shadow"
                  id="printablediv"
                    
                    >
                    <div>
                      {getPrescription &&
                        getPrescription?.map((index) => (
                          <div>
                            <div>
                              <div>
                                <div className="prescription-pop-up-main-div-for-doctor-clinic">
                                  <div
                                    className="prescription-pop-up-main-div"
                                    style={{ textAlign: "initial" }}
                                  >
                                    <div className="prescription-pop-up-sub-div-one">
                                      <h3>
                                        Dr.{index.doctor_firstName}{" "}
                                        {index.doctor_lastName}
                                      </h3>
                                      <p>{index.doctor_email}</p>
                                      <p>{index.doctor_mobile}</p>
                                    </div>
                                    <div className="prescription-pop-up-sub-div-one">
                                      <h3>{index.doctors_clinic_name}</h3>
                                      <p>{index.doctors_clinic_email}</p>
                                      <p>{index.doctors_clinic_mobile}</p>
                                      <p></p>
                                    </div>
                                    <div className="prescription-pop-up-sub-div-one">
                                      <h3>{index.prescription_date}</h3>
                                      <p>{index.doctors_clinic_address}</p>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="prescription-pop-up-main-div-for-patient-detail"
                                  style={{ textAlign: "initial", borderBottom:"2px solid red", paddingBottom:"10px" }}

                                >
                                  <div className="patient-details-in-prescription">
                                    <h3>Patient Name : </h3>{" "}
                                    <p>
                                      {index.patient_firstName}{" "}
                                      {index.patient_lastName}
                                    </p>
                                  </div>
                                  <div className="patient-details-in-prescription">
                                    <h3>Diagnosis :</h3>{" "}
                                    <p>{index.disease_name}</p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className="prescription-pop-up-flex-div"
                              style={{ paddingTop: "10px" }}
                            >
                              <div className="prescription-pop-up-width-div">
                                <div className="prescription-pop-up-main-div">
                                  <div className="prescription-pop-up-sub-div-one">
                                    <h3>Medicine Name</h3>
                                  </div>
                                  <div className="prescription-pop-up-sub-div-one">
                                    <h3>Dosage</h3>
                                  </div>
                                  <div className="prescription-pop-up-sub-div-one">
                                    <h3>Duration</h3>
                                  </div>
                                </div>

                                {index.medicine_name &&
                                  index.medicine_name?.map((data) => (
                                    <div style={{paddingTop:"10px"}}>
                                      <div className="prescription-pop-up-main-div">
                                        <div className="prescription-pop-up-sub-div-one">
                                          <p
                                            style={{ color: "rgb(49,163,214)" }}
                                          >
                                            {data.medicine_name}
                                          </p>
                                        </div>
                                        <div className="prescription-pop-up-sub-div-one">
                                          <p>{data.dosage} mg</p>
                                        </div>
                                        <div className="prescription-pop-up-sub-div-one">
                                          <p>{data.duration}</p>
                                        </div>
                                      </div>
                                      <div className="medicine-frequency-flex-div">
                                        <div className="medicine-frequency-main-flex-div">
                                          <h5>Morning : </h5>
                                          <p>{data.morning == true ? 1 : 0}</p>
                                        </div>

                                        <div className="medicine-frequency-main-flex-div">
                                          <h5>Afternoon : </h5>
                                          <p>
                                            {data.afternoon == true ? 1 : 0}
                                          </p>
                                        </div>

                                        <div className="medicine-frequency-main-flex-div">
                                          <h5>Evening : </h5>
                                          <p>{data.evening == true ? 1 : 0}</p>
                                        </div>

                                        <div className="medicine-frequency-main-flex-div">
                                          <h5>Night : </h5>
                                          <p>{data.nignt == true ? 1 : 0}</p>
                                        </div>
                                      </div>
                                      <label style={{marginLeft: '450px', marginTop: "20px"}}>Doctor's Signature: </label>
                                    <img src={"https://djangoobesitychat.azurewebsites.net/" + index.doctor_signature.replace("build/", "")} style={{height: '30px', width : '100px'}} alt="doc_sign" />
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className="visit-again-div-main">
                    <div className="visit-again-div">
                    <p>Thank You! Visit Again!!!</p>
                    
                    </div>
                    </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        ) : null
        
        }
      </div>
    </div>
  );
};

export default PrescriptionView;
