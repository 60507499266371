import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Loader from "../Loader/Loader";
import swal from "sweetalert";

// GET CSRF COOKIE
function getCsrfToken() {
  if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          // Does this cookie string begin with the name we want?
          if (cookie.substring(0, "csrftoken".length + 1) === ("csrftoken" + '=')) {
              return decodeURIComponent(cookie.substring("csrftoken".length + 1));
          }
      }
  }
  return null;
}

const csrfToken = getCsrfToken();



console.log("csrfToken", csrfToken)

const Que = (props) => {
  const tabledata = props.item.tablejson;
  console.log("tabledata", tabledata)
  return (
    <div>
      <div className="questo-form-quesyion-ans-main-div-for-main">
      <div className="questo-form-questions-wrapper" style={{ width: "55%", cursor:"pointer" }}>
      <div className="questo-form-questions-div" >
      <p className="question-text">{props.number}. {props.item.question}</p>
        </div>
        <div className="tooltip">More information</div>
        </div>
        {props.item.question_category === "yes_no" ? (
        <div className="response-div">
        <div className="questo-form-options-div" style={{ width: "15%" }}>
        <p classname="label-for-response" style={{color: '#88869d', marginBottom: '30px'}}>Response: </p>
          <p style={{ textAlign: "center" }}>{props.item.patient_response1}</p>
        </div>
        <div
          className="questo-form-details-div-in-pat"
          style={{ width: "30%" }}
        >
          <p classname="questo-new-label-for-pat-que" style={{color: '#88869d'}}>Details: </p>
          <div className="questo-form-input-tag-for-patient">
            <p>
              {props.item.disease_name == "Other"
                ? props.item.response_details1
                : props.item.disease_name}
            </p>
          </div>
          </div>
        </div>
        ) : props.item.question_category === "multiple_choice" ? (
          <div>Multiple Choice</div>
        ) : props.item.question_category === "free_text" ? (
          <div className="response-div">
          <div className="questo-form-options-div" style={{ width: "15%" }}>
          <p classname="label-for-response" style={{color: '#88869d', marginBottom: '30px'}}>Response: </p>
            <p style={{ textAlign: "center" }}>{props.item.patient_response1}</p>
          </div>
          <div
            className="questo-form-details-div-in-pat"
            style={{ width: "30%" }}
          >
            <p classname="questo-new-label-for-pat-que" style={{color: '#88869d'}}>Details: </p>
            <div className="questo-form-input-tag-for-patient">
              <p>{props.item.response_details1}</p>
            </div>
            </div>
          </div>
        ) : props.item.question_category === "table_format" ? (
          <div className="response-div">
          <div
              className="questo-form-quesyion-ans-main-div-for-main"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Year</th>
                  </tr>
                </thead>
                <tbody>
                {props.item && props.item.tablejson && Object.values(props.item.tablejson).map((row, index) => (
                  <tr key={index}>
                    <td>{row[0]}</td>
                    <td>{row[1]}</td>
                  </tr>
                ))}
                </tbody>
              </table>
          </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const QueUpdate = (props) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [options, setOptions] = useState([]);
  const [rows1, setRows1] = useState([{ name: "", year: "" }]);
  const is_patient = localStorage.getItem("is_patient")
  const is_doctor = localStorage.getItem("is_doctor");
  const SavedToken = localStorage.getItem("SavedToken");
  const UsernameofPatient =
    is_patient == "true"
      ? localStorage.getItem("Username")
      : is_doctor == "true"
      ? localStorage.getItem("patientUserName")
      : localStorage.getItem("Username");
  const handleSubmit = (event) => {
    event.preventDefault();
    // Convert the data from the table into the format the API expects
    const tableData = {};
    rows1.forEach((row) => {
      tableData[row.name] = row.year;
    });
    const data = Object.fromEntries(Object.entries(tableData));
    console.log(data);
    props.tableHandler({ que_id: props.item.response_id1, val: data });

  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const [tableCount, setTableCount] = useState(0);
  useEffect(() => {
    setTableCount(tableCount + 1);
  }, []);

  const handleAddRow = (event) => {
    event.preventDefault();
    setRows1([...rows1, { name: "", year: "" }]);
  };

  const handleRemoveRow = () => {
    const newRows = [...rows1];
    newRows.splice(-1, 1);
    setRows1(newRows);
  };


  const handleChange = (event, index) => {
    const newRows = [...rows1];
    newRows[index][event.target.name] = event.target.value;
    setRows1(newRows);
    // // //console.log(rows1);
  };
  const tableKey = `table-${props.item.question_id}`;

  const handleClick = () => {
    const DiseaseCategory = props.item.disease_category;
    // // //console.log(DiseaseCategory);
    fetch(`https://djangoobesitychat.azurewebsites.net/diseaseapi/category/${DiseaseCategory}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${SavedToken}`,
      },
      })
      .then((response) => response.json())
      .then((data) => setOptions(data));
  };

  console.log("options", options)



  return (
    <div>
      <div className="questo-form-quesyion-ans-main-div-for-main">
      <div className="questo-form-questions-wrapper" style={{ width: "55%", cursor:"pointer" }}>
      <div className="questo-form-questions-div" >
      <p className="question-text">{props.number}. {props.item.question}</p>
        </div>
        <div className="tooltip">More information</div>
        </div>
        {/*
        <div className="questo-form-options-div" style={{ width: "15%" }}>
          <div style={{ display: "flex", gap: "9px" }}>
            <input
              className="add-patient-input-field-of-radio"
              name={props.item.response_id1}
              type="radio"
              value="Yes"
              onChange={(event) =>
                props.radioButtonHandler({
                  que_id: props.item.response_id1,
                  val: event.target.value,
                })
              }
            />
            <p style={{ fontSize: "13px" }}>Yes</p>
            <input
              className="add-patient-input-field-of-radio"
              name={props.item.response_id1}
              type="radio"
              value="No"
              onChange={(event) =>
                props.radioButtonHandler({
                  que_id: props.item.response_id1,
                  val: event.target.value,
                })
              }
            />
            <p style={{ fontSize: "13px" }}>No</p>
          </div>
        </div>
       <div
          className="questo-form-details-div-in-pat"
          style={{ width: "30%" }}
        >
          <textarea
            className="questo-form-input-tag-for-patient"
            type={"text"}
            placeholder="Details..."
            onChange={(event) =>
              props.detailsHandler({
                que_id: props.item.response_id1,
                val: event.target.value,
              })
            }
          />
          </div>*/}
          {props.item.question_category === "yes_no" ? (
            
            
              <div className="response-div">
              <div className="questo-form-options-div" style={{ width: "15%" }}>
              <p classname="label-for-response" style={{color: '#88869d', marginBottom: '20px'}}>Response: </p>
                <div style={{ display: "flex", gap: "9px" }}>
                  <input
                    className="add-patient-input-field-of-radio"
                    name={props.item.response_id1}
                    type="radio"
                    value="Yes"
                    checked={props.item.patient_response1 === "Yes"}
                    onChange={(event) =>
                      props.radioButtonHandler({
                        que_id: props.item.response_id1,
                        val: event.target.value,
                      })
                    }
                  />
                  <p style={{ fontSize: "13px" }}>Yes</p>
                  <input
                    className="add-patient-input-field-of-radio"
                    name={props.item.response_id1}
                    type="radio"
                    value="No"
                    checked={props.item.patient_response1 === "No"}
                    onChange={(event) =>
                      props.radioButtonHandler({
                        que_id: props.item.response_id1,
                        val: event.target.value,
                      })
                    }
                  />
                  <p style={{ fontSize: "13px" }}>No</p>
                </div>
              
              </div>

              <div
                className="questo-form-details-div-in-pat"
                style={{ width: "30%" }}
              >
                <p classname="questo-new-label-for-pat-que" style={{color: '#88869d'}}>Disease: </p>
                {props.item.patient_response1 == "Yes"  &&
                  <div>
                    
                    <select
                      className="questo-form-input-tag-for-patient"
                      onClick={handleClick}
                      // multiple
                      onChange={(event) =>
                        props.detailsHandler(
                          {
                            que_id: props.item.response_id1,
                            val: event.target.value,
                          },
                          setSelectedOption(event.target.value)
                        )
                      }
                    >
                      <option>Select Disease</option>
                      {options?.map((option) => (
                        <option key={option?.id} value={option?.disease_name}>
                          {option?.disease_name}
                        </option>
                      ))}

                      <option value="Other">Other</option>
                    </select>
                    
                  </div>
               }
              </div>
              {selectedOption === "Other" ? (
                      <div
                      className="questo-form-details-div-in-pat"
                      style={{ width: "30%" }}
                    >
                      <p classname="questo-new-label-for-pat-que" style={{color: '#88869d'}}>Details: </p>
                      <textarea
                        className="questo-form-input-tag-for-patient"
                        type={"text"}
                        placeholder="Details..."
                        onChange={(event) =>
                          props.detailsHandler({
                            que_id: props.item.response_id1,
                            val: event.target.value,
                          })
                        }
                      />
                    </div>
                    ) : null}
              </div>
            
          ) : props.item.question_category === "multiple_choice" ? (
            <div>Multiple Choice</div>
          ) : props.item.question_category === "free_text" ? (
            <div
              className="questo-form-quesyion-ans-main-div-for-main"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
                <div className="questo-form-questions-wrapper" style={{ width: "55%", cursor:"pointer" }}>
            <div className="questo-form-questions-div" style={{ width: "100%" }}>
                <p className="question-text">
                {props.item.mandatory === "Yes" ? (
                  <span style={{ color: "red" }}> *</span>
                ) : null}
                  {props.number}. {props.item.question}
                 
                </p>
              </div>
              <div className="tooltip">More information</div>
              </div>
              <div className="response-div">
              <textarea
                className="questo-form-input-tag-for-patient"
                style={{ width: "55%" }}
                type={"text"}
                placeholder="Details..."
                onChange={(event) =>
                  props.detailsHandler({
                    que_id: props.item.question_id,
                    val: event.target.value,
                  })
                }
              />
              </div>
            </div>
          ) : props.item.question_category === "table_format" ? (
            <div
              className="questo-form-quesyion-ans-main-div-for-main"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <form
                style={{ width: "50%" }}
                className="questo-form-input-tag-for-patient"
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <table key={tableKey}>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Year</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rows1.map((row, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              type="text"
                              name="name"
                              className="questo-form-input-tag-for-patient"
                              value={row.name}
                              onChange={(event) => handleChange(event, index)}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="year"
                              className="questo-form-input-tag-for-patient"
                              value={row.year}
                              onChange={(event) => handleChange(event, index)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot style={{ textAlign: "center" }}>
                      <tr>
                        <td colSpan={3}>
                          <button
                          type="button"
                            onClick={handleAddRow}
                            className="male-female-btn-div"
                            style={{
                              backgroundColor: "rgb(49, 163, 214)",
                              padding: "2px",
                            }}
                          >
                            Add Row
                          </button>
                          <button
                          type="button"
                            onClick={handleRemoveRow}
                            className="male-female-btn-div"
                            style={{
                              backgroundColor: "rgb(49, 163, 214)",
                              padding: "2px",
                              marginLeft: "5px",
                            }}
                          >
                            Remove Row
                          </button>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    type="submit"
                    className="submit-btn-in-que"
                    style={{ padding: "4px" }}
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          ) : null}
       
        
      </div>
    </div>
  );
};

const QuestionnaireDisease = () => {
  const [questionetailsget, setquestionetailsget] = useState([]);
  const [loading, setLoading] = useState(true);
  // const UsernameofPatient = localStorage.getItem("Username");
  const childRef = useRef();
  const [isUpdateActive, setisUpdateActive] = useState(false);
  const is_patient = localStorage.getItem("is_patient");

  // const UsernameofPatient =
  //   is_patient == "true"
  //     ? localStorage.getItem("Username")
  //     : localStorage.getItem("patientUserName");

  const is_doctor = localStorage.getItem("is_doctor");
  const UsernameofPatient =
    is_patient == "true"
      ? localStorage.getItem("Username")
      : is_doctor == "true"
      ? localStorage.getItem("patientUserName")
      : localStorage.getItem("Username");

  console.log("questionetailsget",questionetailsget)

  const OpenUpdateHandler = () => {
    setisUpdateActive(true);
  };

  const CloseUpdateHandler = () => {
    setisUpdateActive(false);
  };

  useEffect(() => {
    axios
      .get(
        `https://djangoobesitychat.azurewebsites.net/patient/response/get/Disease/${UsernameofPatient}/`,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("SavedToken")}`,
          },
        }
      )

      .then((response) => {
        const data = response.data.map((entry) => {
          return Object.assign({}, entry, {
            // patient_name: localStorage.getItem("Username"),
            patient_name: UsernameofPatient,
            response_id1: entry.response_id1,
          });
        });
        setquestionetailsget(data);
        setLoading(false);
      })
      .catch((err) => {});
  }, []);

  const radioButtonHandler = (param) => {
    const newResponse = questionetailsget.map((item) => {
      if (item.response_id1 == param.que_id) {
        item.patient_response1 = param.val;
      }
      return item;
    });
    setquestionetailsget(newResponse);
  };

  const detailsHandler = (param) => {
    const newResponse = questionetailsget.map((item) => {
      if (item.response_id1 == param.que_id) {
        item.disease_name = param.val;
      }
      return item;
    });
    setquestionetailsget(newResponse);
  };

  const othersHandler = (param) => {
    const newResponse = questionetailsget.map((item) => {
      if (item.response_id1 == param.que_id) {
        item.response_details1 = param.val;
        // item.detail = param.val;
      }
      return item;
    });
    setquestionetailsget(newResponse);
  };

  const tableHandler = (param) => {
    const newResponse = questionetailsget.map((item) => {
      if (item.response_id1 == param.que_id) {
        item.tablejson = param.val;
      }
      return item;
    }
    );
    setquestionetailsget(newResponse);
  };
  

  //console.log("questionetailsget", questionetailsget);

  const [submitLoading, setsubmitLoading] = useState(false);

  const submitHandler = (props) => {
    const test = questionetailsget;
    setsubmitLoading(true);

    axios
      .put(
        `https://djangoobesitychat.azurewebsites.net/patient-response/bulk-update/`,
        questionetailsget
      )
      .then((res) => {
        setsubmitLoading(false);

        swal({
          title: "Your Response has been Updated Successfully",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });
        CloseUpdateHandler();
      })
      .catch((err) => {
        setsubmitLoading(false);
      });
  };

  const selectAllYes = () => {
    const newResponse = questionetailsget.map((item) => {
      if (item.question_category === "yes_no") {
        item.patient_response1 = "Yes";
      }
      return item;
    });
    setquestionetailsget(newResponse);
  };

  const selectAllNo = () => {
    const newResponse = questionetailsget.map((item) => {
      if (item.question_category === "yes_no") {
        item.patient_response1 = "No";
      }
      return item;
    });
    setquestionetailsget(newResponse);
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {submitLoading && (
            <div className="purchase-order-details-pop-up-main-div">
              <div className="purchase-order-details-pop-up-sub-div">
                <div
                  className="purchase-order-details-pop-up-sub-div-container"
                  style={{ background: "none" }}
                >
                  <Loader />
                </div>
              </div>
            </div>
          )}
          
          <div className="update-response-div">
            {isUpdateActive == true ? (
              <>
              <button
                className="submit-btn-in-que"
                onClick={CloseUpdateHandler}
              >
                Discard Changes
              </button>
              <p style={{ fontSize: "16px" }}>Select All : </p>
        <button onClick={selectAllYes} className="submit-btn-in-que">
          Yes
        </button>
        <button onClick={selectAllNo} className="submit-btn-in-que">
          No
        </button>
              </>
            ) : (
              <button
                className="submit-btn-in-que"
                onClick={OpenUpdateHandler}
              >
                Update Your Response
              </button>
            )}
          </div>

          <hr />

          {/* <div className="questo-form-quesyion-ans-main-div-for-main">
            
            <div
              className="questo-form-questions-div"
              style={{ width: "55%", justifyContent: "center" }}
            >
              <h3>Questions</h3>
            </div>
            <div
              className="questo-form-options-div"
              style={{ width: "15%", justifyContent: "center" }}
            >
              <h3 style={{ textAlign: "center" }}>Response</h3>
            </div>
            <div
              className="questo-form-details-div-in-pat"
              style={{ width: "30%", justifyContent: "center" }}
            >
              <h3>Details</h3>
            </div>
          </div> */}
          {questionetailsget.map((item, index) => {
            return (
              <div>
                {isUpdateActive == true ? (
                  <QueUpdate
                    key={item.question_id}
                    number={index + 1}
                    item={item}
                    ref={childRef}
                    detailsHandler={detailsHandler}
                    radioButtonHandler={radioButtonHandler}
                    othersHandler={othersHandler}
                    tableHandler={tableHandler}
                  />
                ) : (
                  <Que
                    key={item.question_id}
                    number={index + 1}
                    item={item}
                    // ref={childRef}
                    // detailsHandler={detailsHandler}
                    // radioButtonHandler={radioButtonHandler}
                  />
                )}
              </div>
            );
          })}
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "0px",
        }}
      >
        {isUpdateActive == true ? (
          <button style={{marginTop: '4px'}} onClick={submitHandler} className="submit-btn-in-que">
            Submit
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default QuestionnaireDisease;
