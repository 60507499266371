import React, { useState, useEffect } from "react";
// import PatientQuestionnaireProp from "./PatientQuestionnaireProp";
import { BiRightArrow } from "react-icons/bi";
import axios from "axios";
import Add from "@mui/icons-material/Add";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Loader from "../Loader/Loader";
import Apiservice from "../../Services/Apiservice";

const PatientQuestionnaire = () => {
  const [question, setquestion] = useState("");
  const [response, setresponse] = useState("");
  const [details, setdetails] = useState("");

  const [questionetailsget, setquestionetailsget] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Apiservice
      .get(`/add/question/`, {})

      .then((response) => {
        setquestionetailsget(response.data);
        // // //console.log(response.data);
        setLoading(false);
      })
      .catch((err) => {});
  }, []);

  const rows = [];


  const PatientIDtoPostQuestion = localStorage.getItem("id")

  const addquesdetails = async (e) => {
    e.preventDefault();
    await Apiservice
      .post(`/patient/response/${PatientIDtoPostQuestion}/`, {
        question1: questionetailsget.question,
        patient_response1: response,
        response_details1: details,
      })
      .then((res) => {
        // // //console.log("res", res);
      })
      .catch((err) => {
        // // //console.log("err", err);
      });
  };

  return (
    <div className="questo-flex-div">
      <div className="questo-width-div">
        <div className="questo-main-div">
          <div className="questo-form-main-div">
            <div className="questo-form-main-div-for-title">
              <h3>
                Patient’s Questionnaire for a candidate for Obesity
                Pharmacological Treatment
              </h3>
              <div className="add-pat-btn-div-in-que">
                <button onClick={addquesdetails} className="add-btn-btn-tag-in-que">
                 SAVE
                </button>
              </div>
            </div>
            
            <div>
            {/* 
            <div>
              <div className="questo-form-sub-div">
                <h4>Health Details</h4>
                <p>Blood Type :</p>
              </div>
              <div className="questo-form-quesyion-ans-main-div-for-flex">
                <div className="questo-form-quesyion-ans-main-div-for-width">
                  <PatientQuestionnaireProp
                    question="Are you generally healthy with no medical history?"
                    details="Detail"
                    responce="No"
                  />

                  <div className="questo-form-quesyion-ans-main-div-for-main">
                    <div
                      className="questo-form-questions-div"
                      style={{ width: "55%" }}
                    >
                      <BiRightArrow style={{ height: "13px" }} />
                      <p
                        className="questo-form-para-tag"
                        // onChange={(e) => setquestion(e.target.value)}
                      >
                        {questionetailsget.question}
                      </p>
                    </div>
                    <div
                      className="questo-form-options-div"
                      style={{ width: "15%" }}
                    >
                      <div style={{ display: "flex", gap: "9px" }}>
                        <input
                          className="add-patient-input-field-of-radio"
                          name="gender"
                          type="radio"
                          value="yes"
                          onChange={(e) => setresponse(e.target.value)}
                        />
                        <p style={{ fontSize: "13px" }}>Yes</p>
                        <input
                          className="add-patient-input-field-of-radio"
                          name="gender"
                          type="radio"
                          value="no"
                          onChange={(e) => setresponse(e.target.value)}
                        />
                        <p style={{ fontSize: "13px" }}>No</p>
                      </div>
                    </div>
                    <div
                      className="questo-form-details-div"
                      style={{ width: "30%" }}
                    >
                      <p className="questo-form-para-tag">
                        <input
                          placeholder="Details"
                          onChange={(e) => setdetails(e.target.value)}
                        />
                      </p>
                    </div>
                  </div>

                  <PatientQuestionnaireProp
                    question="Do you have any allergy for drugs and\or any other substance (including Yellow Tetrazine)?"
                    details="Detail"
                    responce="Yes"
                  />

                  <PatientQuestionnaireProp
                    question="Are you taking or ever took any Opiates?"
                    details="Detail"
                    responce="No"
                  />
                  <PatientQuestionnaireProp
                    question="Are you suffering from Migraines?"
                    details=""
                    responce="Yes"
                  />
                  <PatientQuestionnaireProp
                    question="Are you taking any Migraines Prophylactic drugs?"
                    details="Detail drug’s name, dose and how long"
                    responce="No"
                  />
                  <PatientQuestionnaireProp
                    question="Have you ever been treated with any Obesity drug?"
                    details="Detail"
                    responce="Yes"
                  />
                  <PatientQuestionnaireProp
                    question="Are you suffering of Hypothyroidism\Hyperthyroidism?"
                    details="Drugs’ details"
                    responce="No"
                  />
                  <PatientQuestionnaireProp
                    question="Past\Present Pancreatitis"
                    details="Detail"
                    responce="Yes"
                  />
                  <PatientQuestionnaireProp
                    question="Pre-Diabetes\Diabetes"
                    details="Detail drug’s name, dose and how long"
                    responce="No"
                  />
                  <PatientQuestionnaireProp
                    question="Are you taking an anti-diabetes drug containing PIOGLITAZONE?"
                    details="Detail drug’s name, dose and how long"
                    responce="Yes"
                  />
                  <PatientQuestionnaireProp
                    question="Hyper blood pressure"
                    details="Drugs’ details"
                    responce="No"
                  />
                  <PatientQuestionnaireProp
                    question="Hyperlipidemia"
                    details="Drugs’ details"
                    responce="Yes"
                  />
                  <PatientQuestionnaireProp
                    question="Any Cardiovascular Disease records?"
                    details="Detail"
                    responce="No"
                  />
                  <PatientQuestionnaireProp
                    question="For Adolescents: Growth Conditions"
                    details="Detail"
                    responce="Yes"
                  />
                  <PatientQuestionnaireProp
                    question="Fever related disease (past\present)"
                    details="Detail"
                    responce="No"
                  />
                  <PatientQuestionnaireProp
                    question="Potassium deficiency requiring treatment"
                    details="Drugs’ details"
                    responce="Yes"
                  />
                  <PatientQuestionnaireProp
                    question="Inflammatory Bowel Disease"
                    details="Detail"
                    responce="No"
                  />
                  <PatientQuestionnaireProp
                    question="Kidney Disease"
                    details="Detail"
                    responce="Yes"
                  />
                  <PatientQuestionnaireProp
                    question="Liver Disease\Jaundice"
                    details="Detail"
                    responce="No"
                  />
                  <PatientQuestionnaireProp
                    question="Asthma"
                    details="Drug’s details"
                    responce="Yes"
                  />
                  <PatientQuestionnaireProp
                    question="Rheumatoid Arthritis"
                    details="Detail"
                    responce="No"
                  />
                  <PatientQuestionnaireProp
                    question="Epilepsy\Seizure Disease"
                    details="Drugs’ details"
                    responce="Yes"
                  />
                  <PatientQuestionnaireProp
                    question="Tuberculosis"
                    details="Detail"
                    responce="No"
                  />
                  <PatientQuestionnaireProp
                    question="Autoimmune Disease"
                    details="Detail"
                    responce="Yes"
                  />
                  <PatientQuestionnaireProp
                    question="Mental condition (past\present) requiring treatment and\or hospitalization"
                    details="Detail"
                    responce="No"
                  />
                  <PatientQuestionnaireProp
                    question="Are you taking anti-depressant drug containing AMITRIPTYLINE?"
                    details="Drugs’ details"
                    responce="Yes"
                  />
                  <PatientQuestionnaireProp
                    question="HIV\AIDS"
                    details="Drugs’ details"
                    responce="No"
                  />
                  <PatientQuestionnaireProp
                    question="Anemia or any other Hematological condition"
                    details="Detail"
                    responce="Yes"
                  />
                  <PatientQuestionnaireProp
                    question="Anorexia\Bulimia"
                    details="Detail"
                    responce="No"
                  />
                  <PatientQuestionnaireProp
                    question="Glaucoma"
                    details="Detail"
                    responce="Yes"
                  />
                  <PatientQuestionnaireProp
                    question="Are you taking any diuretic drugs (e.g., Furosemide, Indapamide, Hydrochlorothiazide)"
                    details="Drugs’ details"
                    responce="No"
                  />

                  <div className="questo-form-sub-div">
                    <p>For Women :</p>
                  </div>

                  <PatientQuestionnaireProp
                    question="Are you pregnant or breast feeding?"
                    details=""
                    responce="Yes"
                  />
                  <PatientQuestionnaireProp
                    question="Contraceptive"
                    details="Details"
                    responce="No"
                  />
                  <div className="questo-form-sub-div">
                    <p>
                      Are there any other medical conditions that were not
                      included in this questionnaire?
                    </p>
                  </div>
                </div>
              </div>

              <div className="questo-presc-drug-main-div">
                <div className="questo-form-sub-div">
                  <h4 style={{ width: "150px" }}>Prescription Drugs</h4>
                  <p>
                    Please detail all drugs administrated routinely including
                    drug’s name, dosage, how long
                  </p>
                </div>
              </div>
              <div className="questo-form-sub-div">
                <h4 style={{ width: "70px" }}>Lifestyle</h4>
              </div>

              <PatientQuestionnaireProp
                question="Alcohol consumption"
                details="Frequency Details"
                responce="Yes"
              />
              <PatientQuestionnaireProp
                question="Smoking"
                details="Frequency Details"
                responce="No"
              />
              <PatientQuestionnaireProp
                question="Onset of obesity (since when? How many years?)"
                details=""
                responce=""
              />
              <PatientQuestionnaireProp
                question="Physical Activity"
                details="Details"
                responce="Yes"
              />
              <PatientQuestionnaireProp
                question="Alcohol consumption"
                details="Frequency Details"
                responce="Yes"
              />
              <PatientQuestionnaireProp
                question="Defecating"
                details="Frequency Details"
                responce="Yes"
              />
              <PatientQuestionnaireProp
                question="Alcohol consumption"
                details="Frequency Details"
                responce="Regularly"
              />
              <PatientQuestionnaireProp
                question="Does your eating habits affected by your emotions?"
                details="Frequency Details"
                responce="Regularly"
              />

              <div className="add-pat-btn-div">
                <button onClick={addquesdetails} className="add-btn-btn-tag">
                  <Add style={{ height: "20px", width: "20px" }} />
                  SUBMIT
                </button>
              </div>
            </div>
        */}
        </div>

            {loading ? (
              <Loader />
            ) : (
              <div style={{ paddingTop: "20px" }}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <h3>Question</h3>
                        </TableCell>
                        <TableCell align="center">
                          <h3>Response</h3>
                        </TableCell>
                        <TableCell align="center">
                          <h3>Answer</h3>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {questionetailsget.map((row) => (
                        <TableRow
                          key={row.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center">
                            <BiRightArrow style={{ height: "13px" }} />
                          </TableCell>
                          <TableCell align="left">
                            <h4>{row.question}</h4>
                          </TableCell>
                          <TableCell align="center">
                            <div
                              className="questo-form-options-div"
                              // style={{ width: "15%" }}
                            >
                              <div style={{ display: "flex", gap: "9px" }}>
                                <input
                                  className="add-patient-input-field-of-radio"
                                  name="gender"
                                  type="radio"
                                  value="yes"
                                  onChange={(e) => setresponse(e.target.value)}
                                />
                                <p style={{ fontSize: "13px" }}>Yes</p>
                                <input
                                  className="add-patient-input-field-of-radio"
                                  name="gender"
                                  type="radio"
                                  value="no"
                                  onChange={(e) => setresponse(e.target.value)}
                                />
                                <p style={{ fontSize: "13px" }}>No</p>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell align="left">
                            <p className="questo-form-para-tag">
                              <textarea
                              value={details}
                                placeholder="Details"
                                onChange={(e) => setdetails(e.target.value)}
                              />
                            </p>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientQuestionnaire;
