import axios from "axios";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./PatientExtraDetails.css";
import Apiservice from "../../Services/Apiservice";

const PatientExtraDetails = () => {
  const patientname = localStorage.getItem("Username");

  const [address, setAddress] = useState("");
  const [bmi, setBmi] = useState("");
  const [wc, setWc] = useState("");
  const [heartRate, setheartRate] = useState("");
  const [bloodSugar, setbloodSugar] = useState("");
  const [bloodPressure, setbloodPressure] = useState("");
  const navigate = useNavigate();

  const addPatient = async (e) => {
    e.preventDefault();

    await Apiservice
      .post(
        "/user/patientpostapi/",

        {
          patient_name: "vagabond",
          // gender: patientdetailsinaddpatient.gender,
          // date_of_birth: patientdetailsinaddpatient.dob,
        //   address: address,
        //   bmi: bmi,
          current_weight: wc,
          heart_rate: heartRate,
          blood_pressure: bloodPressure,
          blood_sugar: bloodSugar,
        }
        // {
        //   headers: {
        //     Authorization: `Token ${tokenForAddPatient}`,
        //   },
        // }
      )
      .then((res) => {
        // // //console.log(res);
        navigate("/")

      })
      .catch((err) => {});
  };

  return (
    <div className="patient-extra-details-flex-div">
      <div className="patient-extra-details-width-div">
        <div className="login-page-sub-one-sec-one">
          <h4>obesity tool</h4>
          <p>by sk pharma</p>
        </div>
        <div className="patient-extra-details-main-div">
          <h3>Welcome to the Obesity Tool User</h3>
        </div>
        <div className="patient-extra-details-main-div">
          <p>Please Fill the below Details to Proceed Further !!!</p>
        </div>
        <div className="patient-extra-details-main-div">
          <p>Please insert 0 if you don't have the below data . . .</p>
        </div>
        <div className="patient-extra-details-main-div-one">
          <div className="pat-extra-sub-one">Username</div>
          <div className="pat-extra-sub-two">{patientname} Username</div>
        </div>
        <div className="patient-extra-details-main-div-one">
          <div className="pat-extra-sub-one">Address</div>
          <div className="pat-extra-sub-two"><input type={"text"} placeholder="Address..."
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          /></div>
        </div>
        <div className="patient-extra-details-main-div-one">
          <div className="pat-extra-sub-one">BMI</div>
          <div className="pat-extra-sub-two"><input type={"number"} placeholder="BMI"
          value={bmi}
          onChange={(e) => setBmi(e.target.value)}
          /></div>
        </div>
        <div className="patient-extra-details-main-div-one">
          <div className="pat-extra-sub-one">Current Weight</div>
          <div className="pat-extra-sub-two"><input type={"number"} placeholder="Current Weight"
          value={wc}
          onChange={(e) => setWc(e.target.value)}
          /></div>
        </div>
        <div className="patient-extra-details-main-div-one">
          <div className="pat-extra-sub-one">Heart Rate</div>
          <div className="pat-extra-sub-two"><input type={"number"} placeholder="Heart Rate"
          value={heartRate}
          onChange={(e) => setheartRate(e.target.value)}
          /></div>
        </div>
        <div className="patient-extra-details-main-div-one">
          <div className="pat-extra-sub-one">Blood Pressure</div>
          <div className="pat-extra-sub-two"><input type={"number"} placeholder="Blood Pressure"
          value={bloodPressure}
          onChange={(e) => setbloodPressure(e.target.value)}
          /></div>
        </div>
        <div className="patient-extra-details-main-div-one">
          <div className="pat-extra-sub-one">Blood Sugar</div>
          <div className="pat-extra-sub-two"><input type={"number"} placeholder="Blood Sugar"
          value={bloodSugar}
          onChange={(e) => setbloodSugar(e.target.value)}
          /></div>
        </div>
        <div className="add-pat-btn-div">
        <button onClick={addPatient} className="add-btn-btn-tag">
          SUBMIT
        </button>
      </div>
      </div>
    </div>
  );
};

export default PatientExtraDetails;
