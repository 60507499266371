import React, { useState } from "react";
// import HandImg from "../../Images/HandImageLoginPg.png";
import LoginPageImage from "../../Images/FrontMainImage2.jpg";
import ArrowForwardSharpIcon from "@mui/icons-material/ArrowForwardSharp";
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../Loader/Loader";
import AutoComplete from "../Map/AutoComplete";

const ClinicSignup = () => {
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [password2, setpassword2] = useState("");
  const [email, setemail] = useState("");
  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [mobile, setmobile] = useState("");
  const [address, setaddress] = useState("");
  const [age, setage] = useState("");
  const [gender, setgender] = useState("");
  const [date_of_birth, setdate_of_birth] = useState("");
  const [image, setimage] = useState("");
  const navigate = useNavigate();
  const [response, setresponse] = useState();
  const [selectedFile, setSelectedFile] = useState("");
  const [responseData, setResponseData] = useState(null);
  const [submitLoading, setsubmitLoading] = useState(false);

  const [value, onChange] = useState(new Date());

  const date = value.toLocaleDateString("fr-CA");


  const onHandleChange = (childData) => {
    setaddress(childData)
  };


  // CLINIC REGISTRATION POST REQUEST USING FORM DATA METHOD

  const handleSubmit = async (event) => {
    event.preventDefault();
    setsubmitLoading(true);
    const formData = new FormData();
    formData.append("image", selectedFile && selectedFile);
    formData.append("username", username);
    formData.append("password", password);
    formData.append("password2", password2);
    formData.append("email", email);
    formData.append("firstName", first_name);
    formData.append("lastName", last_name);
    formData.append("mobile", mobile);
    formData.append("address", address);
    formData.append("gender", "Not Specified");
    formData.append("date_of_birth", date);
    const medDocsField = formData.get("image");

    axios({
      method: "post",
      url: `https://djangoobesitychat.azurewebsites.net/user/clinic/register/`,
      data: formData,
    })
      .then((res) => {
        setsubmitLoading(false);
        const { username } = res.data;
        localStorage.setItem("Username", username);
        navigate("/clinicextradetails");
      })

      .catch((res) => {
        setresponse(res.response.data);
        setsubmitLoading(false);
      });
  };


  // FUNCTION TO SELECT THE FILE OR IMAGE OR TO HANDLE INPUT TYPE FILE
  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  return (
    <div className="login-page-flex-div">
    {/* LOADER */}
      {submitLoading && (
        <div className="purchase-order-details-pop-up-main-div">
          <div className="purchase-order-details-pop-up-sub-div">
            <div
              className="purchase-order-details-pop-up-sub-div-container"
              style={{ background: "none" }}
            >
              <Loader />
            </div>
          </div>
        </div>
      )}


      <div className="login-page-width-div">
        <div className="login-page-main-div">
          <div
            className="login-page-sub-div-one"
            style={{ paddingBottom: "20px" }}
          >
            <div
              className="login-page-sub-one-sec-one"
              style={{ paddingBottom: "0px" }}
            >
              <h4>obesity tool</h4>
              <p>by sk pharma</p>
              <h5>Clinic Registration</h5>
            </div>
            <div className="login-page-sub-one-sec-two">
              <div className="input-fiend-one">
                <input
                  type="text"
                  className="email-input-field-tag"
                  placeholder="Clinic Name"
                  value={first_name}
                  onChange={(e) => setfirst_name(e.target.value)}
                />
              </div>
              <div className="error-message-preview">
                <p>{response && response.firstName}</p>
              </div>
              <div className="input-fiend-one">
                <input
                  type="text"
                  className="email-input-field-tag"
                  placeholder="Branch Name"
                  value={last_name}
                  onChange={(e) => setlast_name(e.target.value)}
                />
              </div>
              <div className="input-fiend-one">
                <input
                  type="email"
                  className="email-input-field-tag"
                  placeholder="Clinic Email ID"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                />
              </div>
              <div className="error-message-preview">
                <p>{response && response.email}</p>
              </div>
              <div className="input-fiend-one">
                <input
                  type="number"
                  className="email-input-field-tag"
                  placeholder="Clinic Mobile Number"
                  value={mobile}
                  onChange={(e) => setmobile(e.target.value)}
                />
              </div>
              <div className="error-message-preview">
                <p>{response && response.mobile}</p>
              </div>
              <div className="input-fiend-one">
                <input
                  type="text"
                  className="email-input-field-tag"
                  placeholder="Clinic Username"
                  value={username}
                  onChange={(e) => setusername(e.target.value)}
                />
              </div>
              <div className="error-message-preview">
                <p>{response && response.username}</p>
              </div>
              <div className="input-fiend-one">
                <input
                  type="password"
                  className="email-input-field-tag"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setpassword(e.target.value)}
                />
              </div>
              <div className="error-message-preview">
                <p>{response && response.password}</p>
              </div>
              <div className="input-fiend-one">
                <input
                  type="password"
                  className="email-input-field-tag"
                  placeholder="Check Password"
                  value={password2}
                  onChange={(e) => setpassword2(e.target.value)}
                />
              </div>
              <div className="error-message-preview">
                <p>{response && response.password2}</p>
              </div>
              <div className="input-fiend-one">
              <AutoComplete parentCallback={onHandleChange} />

              {/*<input
                  type="text"
                  className="email-input-field-tag"
                  placeholder="Clinic Address"
                  value={address}
                  onChange={(e) => setaddress(e.target.value)}
              />*/}
              </div>
              <div className="input-fiend-one">
                <input
                  type="file"
                  className="email-input-field-tag"
                  onChange={handleFileSelect}
                />
              </div>
              {/*  
              <div className="input-fiend-one">
                <input
                  type="text"
                  className="email-input-field-tag"
                  placeholder="Age"
                  value={age}
                  onChange={(e) => setage(e.target.value)}
                />
                </div>
            <div className="input-fiend-one">
                <input
                  type="file"
                  className="email-input-field-tag"
                  placeholder="Image"
                  value={image}
                  onChange={(e) => setimage(e.target.value)}
                />
  </div>
  <div
  className="input-fiend-one"
  style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <p>Gender :</p>
                <div
                  style={{
                    display: "flex",
                    gap: "2px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  >
                  <input
                    type="radio"
                    name="gender"
                    value="Male"
                    style={{}}
                    onChange={(e) => setgender(e.target.value)}
                  />
                  <p>Male</p>
                </div>
                <div style={{ display: "flex", gap: "2px" }}>
                  <input
                    type="radio"
                    name="gender"
                    value="Female"
                    style={{}}
                    onChange={(e) => setgender(e.target.value)}
                    />
                  <p>Female</p>
                </div>
              </div>

              
              <div className="input-fiend-one">
                <input
                  type="date"
                  className="email-input-field-tag"
                  placeholder="Date of Birth"
                  value={date_of_birth}
                  onChange={(e) => setdate_of_birth(e.target.value)}
                />
              </div>
 */}
              {/*<div className="input-fiend-one">
                <input
                  type="image"
                  className="email-input-field-tag"
                  placeholder="Image"
                    value={image}
                    onChange={(e) => setimage(e.target.value)}
                />
  </div>*/}
            </div>

            <div
              className="login-page-sub-one-sec-four"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <button
                onClick={handleSubmit}
                className="login-page-login-btn"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Next{" "}
                <ArrowForwardSharpIcon
                  style={{ color: "white", height: "16px" }}
                />
              </button>
            </div>
            <div className="login-page-sub-one-sec-four">
              <Link to="/">
                <button className="login-page-login-btn">Login</button>
              </Link>
            </div>
            <div className="login-page-sub-one-sec-five">
              <p>Forget Password ?</p>
            </div>
          </div>

          <div className="login-page-sub-div-two">
            <img src={LoginPageImage} alt="" className="hand-img-login" />
          </div>
        </div>
      </div>

    </div>
  );
};

export default ClinicSignup;
