import React from "react";
import "./AppointmentList.css";
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";
import Apiservice from "../../Services/Apiservice";


const SubClinicAppointmentList = () => {


    const [appointmentlist, setappointmentlist] = useState([]);
    const [loading, setLoading] = useState(true);
    const subclinicID = localStorage.getItem("SubClinicIDForHomepage")
  
    useEffect(() => {
      Apiservice
        .get(`/appointmentapi/subclinic/${subclinicID}/`, {
          
        })
  
        .then((response) => {
        setappointmentlist(response.data);
          // // //console.log("ASD", response.data);
          setLoading(false);
        })
        .catch((err) => {});
    }, []);
    if (!appointmentlist) return null;

    function generateRandom() {
      var length = 8,
          charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
          retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
          retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      return retVal;
  }

    const columns = [
        // { field: 'appoinment_id', headerName: 'ID', width: 90 },
        {
          field: 'patient_firstName',
          headerName: 'Patient First name',
          headerClassName: 'super-app-theme--header',
          minWidth:250,
          // editable: true,
        },
        {
          field: 'patient_lastName',
          headerName: 'Patient Last name',
          headerClassName: 'super-app-theme--header',
          minWidth:250,
          // editable: true,
        },
        {
          field: 'doctor_firstName',
          headerName: 'Doctor First name',
          headerClassName: 'super-app-theme--header',
          minWidth:250,
          // editable: true,
        },
        {
          field: 'doctor_lastName',
          headerName: 'Doctor Last name',
          headerClassName: 'super-app-theme--header',
          minWidth:250,
          // editable: true,
        },
    
        {
          field: 'starttime',
          headerName: 'Date and Time',
          headerClassName: 'super-app-theme--header',
          minWidth:250,
          // editable: true,
        },
      
        {
          field: 'is_approved',
          headerName: 'Status',
          headerClassName: 'super-app-theme--header',
          minWidth:250,
          editable: true,
        },
       
        
      ];
      
      const rows = [

      ];
  return (
    <div className="appointment-list-flex-div">
      <div className="appointment-list-width-div">
        <div className="appointment-list-main-div">
        <div className="appt-list-create-new-appt-main-div-btn">
        <Link to="/subclinicsidebar/appointmentbyclinic">
        <button>
              Create New Appointment
            </button>
            </Link>
        </div>
          <div className="appointment-list-sub-div-one">
            <h3>Appointments</h3>
          </div>
          {loading ? (
            <Loader />
          ) : (
          <div className="appointment-list-sub-div-two">
          <Box sx={{ height: 800, width: '100%',
          '& .super-app-theme--header': {
          backgroundColor: 'rgb(49, 163, 214)',
        }, }}>
      <DataGrid
        rows={appointmentlist}
        columns={columns}
        pageSize={50}
        rowsPerPageOptions={[50]}
        // getRowId={(row) =>  row.appoinment_id}
        getRowId={(row) =>  generateRandom()}
        // checkboxSelection
        // disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
          </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubClinicAppointmentList;
