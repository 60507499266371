import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PersonalPatientFileMiddleScreen from '../../containers/PersonalPatientFileMiddleScreen/PersonalPatientFileMiddleScreen';
import LeftArrow from "../../SVG/leftArrow.svg"
import Refresh from "../../SVG/refresh.svg"
import RightArrow from '../../SVG/rightarrow.svg'
import Hexagone from "../../SVG/hexagonAqua.svg"
import Circle from "../../SVG/circle.svg"
import FilledCircle from "../../SVG/filledCircle.svg"
import DropdownDownArrow from "../../SVG/download-one.svg"
import PlaneHexagon from "../../SVG/hexagonopen.svg"
import '../../containers/PersonalPatientFileMiddleScreen/PersonalPatientFileMiddleScreen.css'

const drawerWidth = 320;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor:'white',
  justifyContent:'flex-end',
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
    backgroundColor:'white',
    justifyContent:'flex-end',
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  
  
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  
}));

export default function PersistentDrawerRight() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', justifyItems:'space-between' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{paddingTop:'8vh', justifyContent:'space-evenly'}}>
        <Toolbar sx={{color:'white'}}>
        <div className='personal-patient-second-nav-flex-div' >
            <div className='personal-patient-second-nav-width-div'>
                <div className='personal-patient-second-nav-main-div'>
                    <div className='personal-patient-second-nav-main-div-one'>
                        <div className='personal-patient-second-nav-sub-div-one'>
                            <div className='pp-arrow-div'> <img src={LeftArrow} className="second-pp-nav-svg-logo" alt='' /> <h2>Go Back</h2></div>
                        </div>
                        <div className='personal-patient-second-nav-sub-div-two'>
                            <input className='personal-patient-second-nav-input-tag' placeholder='DRAFT' />
                        </div>
                        <div>
                            <img src={RightArrow} className="right-arrow-tag" alt='' />
                        </div>
                    </div>

                    <div className='personal-patient-second-nav-main-div-two'>

                        <div className='pp-arr-w-main-div-two'><p>Last Update 08/10/2022 21:24</p></div>

                        <div className='personal-patient-second-nav-sub-div-three'>
                            <button className='pp-second-nav-btns-div'>
                                Edit
                            </button>
                            <button className='pp-second-nav-btns-div'>
                                Duplicate
                            </button>
                            <div>
                                <img src={Refresh} className="second-pp-nav-svg-logo-two" alt='' />
                            </div>
                            <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{ ...(open && { display: 'none' }), justifyContent:'flex-end' }}
          >
            <MenuIcon sx={{color:'black'}} />
          </IconButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
          
        </Toolbar>
      </AppBar>
      <Main open={open}>
        <DrawerHeader />
        <PersonalPatientFileMiddleScreen />
      </Main>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton sx={{paddingTop:'10vh'}} onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <div className='ppms-main-div-two'>
                        <div className='ppms-main-div-sub-div-two'>
                            <button>Stages of Treatment</button>
                        </div>
                        <div>
                            <div className='treatment-details-main-div-one'>
                                <div className='hexa-div-in-treat-main'>
                                    <img src={Hexagone} alt='' className='hexagone-svg-main' />
                                </div>
                                <div className='header-name-in-treat'>
                                    <h3>Screening</h3>
                                </div>
                                <div>
                                    <img src={DropdownDownArrow} alt='' className='down-arrow-svg-in-treat-one' />
                                </div>
                            </div>

                            <div className='treat-ment-sub-parts'>
                                <div className='treatment-details-main-div-one-sub-one'>
                                    <div>
                                        <img src={Circle} alt='' className='down-arrow-svg-in-treat' />
                                    </div>
                                    <div className='header-name-in-treat-sub-sec'>
                                        <p>Existing Medical Tests</p>
                                    </div>
                                </div>
                                <div className='treatment-details-main-div-one-sub-one'>
                                    <div>
                                        <img src={Circle} alt='' className='down-arrow-svg-in-treat' />
                                    </div>
                                    <div className='header-name-in-treat-sub-sec'>
                                        <p>Additional Medical Tests</p>
                                    </div>
                                </div>
                                <div className='treatment-details-main-div-one-sub-one'>
                                    <div>
                                        <img src={Circle} alt='' className='down-arrow-svg-in-treat' />
                                    </div>
                                    <div className='header-name-in-treat-sub-sec'>
                                        <p> Comorbity Screening</p>
                                    </div>
                                </div>
                                <div className='treatment-details-main-div-one-sub-one'>
                                    <div>
                                        <img src={Circle} alt='' className='down-arrow-svg-in-treat' />
                                    </div>
                                    <div className='header-name-in-treat-sub-sec'>
                                        <p>Phenotype Screeing</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div>
                            <div className='treatment-details-main-div-one'>
                                <div className='hexa-div-in-treat'>
                                    <img src={PlaneHexagon} alt='' className='hexagone-svg' />
                                </div>
                                <div className='header-name-in-treat'>
                                    <h3>Assessment</h3>
                                </div>
                                <div>
                                    <img src={DropdownDownArrow} alt='' className='down-arrow-svg-in-treat-one' />
                                </div>
                            </div>

                            <div className='treat-ment-sub-parts'>
                                <div className='treat-sub-header-main-div'>
                                    <div>
                                        <img src={FilledCircle} alt='' className='filled-circle-svg-in-treat' />
                                    </div>
                                    <div className='treat-sub-header-font-tag'>
                                        <p>Existing Medical Tests</p>
                                    </div>
                                    <div>
                                        <img src={DropdownDownArrow} alt='' className='down-arrow-svg-in-treat-one' />
                                    </div>
                                </div>
                                <div className='treatment-details-main-div-one-sub-one'>
                                    <div>
                                        <img src={Circle} alt='' className='down-arrow-svg-in-treat' />
                                    </div>
                                    <div className='header-name-in-treat-sub-sec'>
                                        <p>Medical History</p>
                                    </div>
                                </div>
                                <div className='treatment-details-main-div-one-sub-one'>
                                    <div>
                                        <img src={Circle} alt='' className='down-arrow-svg-in-treat' />
                                    </div>
                                    <div className='header-name-in-treat-sub-sec'>
                                        <p>Child Growth</p>
                                    </div>
                                </div>
                                <div className='treatment-details-main-div-one-sub-one'>
                                    <div>
                                        <img src={Circle} alt='' className='down-arrow-svg-in-treat' />
                                    </div>
                                    <div className='header-name-in-treat-sub-sec'>
                                        <p> Parental Obesity</p>
                                    </div>
                                </div>
                            </div>


                            <div className='treat-ment-sub-parts'>
                                <div className='treat-sub-header-main-div'>
                                    <div>
                                        <img src={FilledCircle} alt='' className='filled-circle-svg-in-treat' />
                                    </div>
                                    <div className='treat-sub-header-font-tag'>
                                        <p>Behaviour Risk</p>
                                    </div>
                                    <div>
                                        <img src={DropdownDownArrow} alt='' className='down-arrow-svg-in-treat-one' />
                                    </div>
                                </div>
                                <div className='treatment-details-main-div-one-sub-one'>
                                    <div>
                                        <img src={Circle} alt='' className='down-arrow-svg-in-treat' />
                                    </div>
                                    <div className='header-name-in-treat-sub-sec'>
                                        <p>Sedentary Time</p>
                                    </div>
                                </div>
                                <div className='treatment-details-main-div-one-sub-one'>
                                    <div>
                                        <img src={Circle} alt='' className='down-arrow-svg-in-treat' />
                                    </div>
                                    <div className='header-name-in-treat-sub-sec'>
                                        <p>Eating</p>
                                    </div>
                                </div>
                                <div className='treatment-details-main-div-one-sub-one'>
                                    <div>
                                        <img src={Circle} alt='' className='down-arrow-svg-in-treat' />
                                    </div>
                                    <div className='header-name-in-treat-sub-sec'>
                                        <p> Physical Activity</p>
                                    </div>
                                </div>
                                <div className='treat-sub-header-main-div'>
                                    <div>
                                        <img src={FilledCircle} alt='' className='filled-circle-svg-in-treat' />
                                    </div>
                                    <div className='treat-sub-header-font-tag'>
                                        <p>Activity</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className='treatment-details-main-div-one'>
                                <div className='hexa-div-in-treat'>
                                    <img src={PlaneHexagon} alt='' className='hexagone-svg' />
                                </div>
                                <div className='header-name-in-treat'>
                                    <h3>Intervention</h3>
                                </div>
                                <div>
                                    <img src={DropdownDownArrow} alt='' className='down-arrow-svg-in-treat-one' />
                                </div>
                            </div>

                            <div className='treat-ment-sub-parts'>
                                <div className='treatment-details-main-div-one-sub-one'>
                                    <div>
                                        <img src={Circle} alt='' className='down-arrow-svg-in-treat' />
                                    </div>
                                    <div className='header-name-in-treat-sub-sec'>
                                        <p>Surgical</p>
                                    </div>
                                </div>
                                <div className='treatment-details-main-div-one-sub-one'>
                                    <div>
                                        <img src={Circle} alt='' className='down-arrow-svg-in-treat' />
                                    </div>
                                    <div className='header-name-in-treat-sub-sec'>
                                        <p>Medical</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
      </Drawer>
    </Box>
  );
}
