import React from "react";
import { useState } from "react";
import "./AppoinmentListHomePage.css";
import AppointmentbyClinic from "../AppointmentbyClinic/AppointmentbyClinic";
import DititionistAppointment from "../AppointmentbyClinic/DititionistAppointment";
import TrainerAppointment from "../AppointmentbyClinic/TrainerAppointment";
import AppointmentList from "./AppointmentList";
import DieticianAppointmentList from "./DieticianAppointmentList";
import TrainerAppointmentList from "./TrainerAppointmentList";


const AppointmentListHomePage = () => {
  const [doctor, setDoctor] = useState(true);
  const [Dititionist, setDititionist] = useState(false);
  const [Trainer, setTrainer] = useState(false);

  const [isdoctoractive, setIsdoctoractive] = useState(true);
  const [isDititionistactive, setIsDititionistactive] = useState(false);
  const [isTraineractive, setIsTraineractive] = useState(false);

  const is_receptionist = localStorage.getItem("is_receptionist");
  const ClinicIDForHomepage = localStorage.getItem("ClinicIDForHomepage");
  const SubClinicIDForHomepage = localStorage.getItem("SubClinicIDForHomepage");

  const DoctorHandler = () => {
    setDoctor(true);
    setIsdoctoractive(true);
    setDititionist(false);
    setIsDititionistactive(false);
    setTrainer(false);
    setIsTraineractive(false);
    localStorage.setItem("isTraineractive", false);
    localStorage.setItem("isDititionistactive", false);
    localStorage.setItem("isdoctoractive", true);
  };

  const DititionistHandler = () => {
    setDoctor(false);
    setIsdoctoractive(false);
    setDititionist(true);
    setIsDititionistactive(true);
    setTrainer(false);
    setIsTraineractive(false);
    localStorage.setItem("isTraineractive", false);
    localStorage.setItem("isDititionistactive", true);
    localStorage.setItem("isdoctoractive", false);
  };

  const TrainerHandler = () => {
    setDoctor(false);
    setIsdoctoractive(false);
    setDititionist(false);
    setIsDititionistactive(false);
    setTrainer(true);
    setIsTraineractive(true);
    localStorage.setItem("isTraineractive", true);
    localStorage.setItem("isDititionistactive", false);
    localStorage.setItem("isdoctoractive", false);
  };

  return (
    <div className="appt-by-clinic-homepage-flex-div">
      <div className="appt-by-clinic-homepage-width-div">
        <div className="appt-by-clinic-homepage-main-div">
          <div
            style={{ width: "100%" }}
            className={
              isdoctoractive
                ? "new-que-home-div-sub-one-active"
                : "new-que-home-div-sub-one"
            }
            onClick={DoctorHandler}
          >
            Doctor Appointments
          </div>
          <div
            style={{ width: "100%" }}
            className={
              isDititionistactive
                ? "new-que-home-div-sub-one-active"
                : "new-que-home-div-sub-one"
            }
            onClick={DititionistHandler}
          >
            Dietician Appointments
          </div>

          <div
            style={{ width: "100%" }}
            className={
              isTraineractive
                ? "new-que-home-div-sub-one-active"
                : "new-que-home-div-sub-one"
            }
            onClick={TrainerHandler}
          >
            Trainer Appointments
          </div>
        </div>
        <div>
          {doctor && <AppointmentList />}
          {Dititionist && <DieticianAppointmentList />}
          {Trainer && <TrainerAppointmentList />}
        </div>
      </div>
    </div>
  );
};

export default AppointmentListHomePage;
