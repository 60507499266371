import React, { useState } from "react";
import LoginPageImage from "../../Images/FrontMainImage2.jpg";
import "./PatientSignUp.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import ArrowForwardSharpIcon from "@mui/icons-material/ArrowForwardSharp";
import AutoComplete from "../Map/AutoComplete";
import Loader from "../Loader/Loader";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const PatientSignUp = () => {
  const [username, setusername] = useState("");
  const [duplicateUsername, setDuplicateUsername] = useState("");
  const [password, setpassword] = useState("");
  const [password2, setpassword2] = useState("");
  const [email, setemail] = useState("");
  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [mobile, setmobile] = useState("");
  const [image, setimage] = useState("");
  const [address, setaddress] = useState("");
  const [age, setage] = useState("");
  const [gender, setgender] = useState("");
  const [date_of_birth, setdate_of_birth] = useState("");
  const [religion, setreligion] = useState("");
  const [education, seteducation] = useState("");
  const [privacy_policy, setprivacy_policy] = useState("");
  const [Terms_and_Conditions, setTerms_and_Conditions] = useState("");
  const [accessibility_policy, setaccessibility_policy] = useState("");
  const [health_care_insurance_name, sethealth_care_insurance_name] =
    useState("");
  const [health_care_insurance_number, sethealth_care_insurance_number] =
    useState("");
  const [blood_type, setblood_type] = useState("");
  const [marital_status, setmarital_status] = useState("");
  const [govidnum, setgovidnum] = useState("");
  const [responseData, setResponseData] = useState(null);
  const [response, setresponse] = useState();
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState("");
  const [submitLoading, setsubmitLoading] = useState(false);
  const [policyError, setPolicyError] = useState(''); // error message for policies
  const [genderError, setGenderError] = useState('')
  const [date_of_birthError, setdate_of_birthError] = useState('')
  const [passwordError, setPasswordError] = useState(''); // error message for password



  const [reasponce, setreasponce] = useState("");

  const url = window.location.href;
  localStorage.setItem("email", email);
  //console.log(url);
  const urlSplit = url.split("/");
  //console.log(urlSplit);
  const clinicId = urlSplit[4];
  //console.log(clinicId);

  const handleSubmit = async (event) => {
   
    event.preventDefault();
    if (
      gender === "" ||
      gender === "Select Gender"
    )
    {
      setGenderError('Please select atleast one options from the Gender field');
      return; // stops the function execution here
    }
    else{
      setGenderError(''); // resets the error message when all policies are accepted
    }
    if (
      privacy_policy !== "Yes" ||
      Terms_and_Conditions !== "Yes" ||
      accessibility_policy !== "Yes"
    ) {
      setPolicyError('Please Accept All Policies');
      return; // stops the function execution here
    } else {
      setPolicyError(''); // resets the error message when all policies are accepted
    }

    if (password !== password2) {
      setPasswordError('Password does not match');
      return; // stops the function execution here
    } else {
      setPasswordError(''); // resets the error message when all policies are accepted
    }
    const today = new Date();
    const birthDate = new Date(date_of_birth);
    const ageInMilliseconds = today - birthDate;
    const ageInYears = ageInMilliseconds / (365 * 24 * 60 * 60 * 1000);

    setage(Math.round(ageInYears));
    if (date_of_birth === "" || new Date(date_of_birth) > new Date() || ageInYears < 15) {
      setdate_of_birthError('Please select your date of birth correctly');
      //console.log(ageInYears)
      if (ageInYears < 15 && new Date(date_of_birth) < new Date()) {
        setdate_of_birthError('You are not eligible to sign up into this app');
      }
      return; // stops the function execution here
    } else {
      setdate_of_birthError(''); // resets the error message when all policies are accepted
    }
    // submitLoading(true);
    const formData = new FormData();
    formData.append("image", selectedFile && selectedFile);
    formData.append("username", username);
    formData.append("password", password);
    formData.append("password2", password2);
    formData.append("email", email);
    formData.append("firstName", first_name);
    formData.append("lastName", last_name);
    formData.append("mobile", mobile);
    formData.append("address", address);
    formData.append("age", age);
    formData.append("gender", gender);
    formData.append("date_of_birth", date_of_birth);
    formData.append("religion", religion);
    formData.append("education", education);
    formData.append("health_care_insurance_name", health_care_insurance_name);
    formData.append(
      "health_care_insurance_number",
      health_care_insurance_number
    );
    formData.append("blood_type", blood_type);
    formData.append("marital_status", marital_status);
    formData.append("govidnum", govidnum);
    formData.append("privacy_policy", privacy_policy);
    formData.append("Terms_and_Conditions", Terms_and_Conditions);
    formData.append("accessibility_policy", accessibility_policy);
    const medDocsField = formData.get("image");



    // PATIENT REGISTRATION POST METHOD

    // IN PATIENT REGISTRATION POST METHOD RESPONSE 
    // WE ARE CALLING PATIENT EXTRA DETAILS POST METHOD WITH BLANK DATA
    // AND NAVIGATING THE PAGE TO EMAIL VERIFATION PAGE
    
    axios({
      method: "post",
      url: `https://djangoobesitychat.azurewebsites.net/user/patient/register/`,
      data: formData,
    })
      .then((res) => {
        const { username } = res.data;
        localStorage.setItem("Username", username);

        axios
          .post("https://djangoobesitychat.azurewebsites.net/user/patientpostapi/", {
            clinic_id: 1,
            subclinic_id: null,
            trainer_id: null,
            dietitian_id: null,
            patient_name: username,
            height:10.0,
            age:Math.round(age),
            gender: gender,
            race: null,
            cell: null,
            beginning_weight:0,
            target_weight:0,
            alert:false,
            phenotype: null,
            percent_match_to_phenotype: null,
            dispensed_drug: null,
            dosage: null,
            bust_circumference_cm:1,
            hip_circumference_cm:1,
            waist_circumference_cm:10.0,
            progress_percent_according_to_plan: null,
            combo_phenotype: null,
            exercise_type: null,
            hunger_feeling: null,
            rfm:0,
            total_calories: null,
            background_disease: null,
            last_meal: null,
            drug_interaction_count: null,
            drug_interaction_match_list: null,
            contraindications_count: null,
            last_exercise: null,
            total_calories_burn: null,
            success_rate_in_next_phenotype: null,
            heart_rate: 76,
            blood_sugar: null,
            Systolic_blood_pressure:120,
            Diastolic_blood_pressure:80,
            gender_user: gender,
            age_user: Math.round(age),
          })
          .then((res) => {
            setsubmitLoading(false);
            navigate("/emailverification");
          })
      })

      .catch((res) => {
        setresponse(res.response.data);
        
        if (res.response.status === 500) {
          setDuplicateUsername("Username already exists");
        }
        setsubmitLoading(false);
      });
    
  };

  //console.log("privacy_policy", policyError);
  //console.log("gender", genderError);

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // ADDRESS FROM GOOGLE API
  const onHandleChange = (childData) => {
    setaddress(childData);
  };

  // convert age into integer type
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  console.log("ageInYears", age);

  return (
    <div className="login-page-flex-div">

    {/* LOADER */}
      {submitLoading && (
        <div className="purchase-order-details-pop-up-main-div">
          <div className="purchase-order-details-pop-up-sub-div">
            <div
              className="purchase-order-details-pop-up-sub-div-container"
              style={{ background: "none" }}
            >
              <Loader />
            </div>
          </div>
        </div>
      )}
      
      <div className="login-page-width-div">
        <div className="login-page-main-div" style={{ padding: "20px" }}>
          <div
            className="login-page-sub-div-two"
            style={{ paddingTop: "0vh", width: "100%" }}
          >
            <img src={LoginPageImage} alt="" className="hand-img-login" />
          </div>

          <div
            className="login-page-sub-div-one"
            style={{ paddingBottom: "20px" }}
          >
            <div
              className="login-page-sub-one-sec-one"
              style={{ paddingBottom: "0px" }}
            >
              <h4>obesity tool</h4>
              <p>by sk pharma</p>
              <h5>Patient Registration</h5>
            </div>
            <div>
              <div style={{ display: "flex", gap: "15px" }}>
                <div className="login-page-sub-one-sec-two">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "60px",
                    }}
                  >
                    <div className="input-fiend-one">
                      <input
                        type="text"
                        className="email-input-field-tag"
                        placeholder="First Name"
                        value={first_name}
                        onChange={(e) => setfirst_name(e.target.value)}
                      />
                    </div>

                    <div className="input-fiend-one">
                      <input
                        type="text"
                        className="email-input-field-tag"
                        placeholder="Last Name"
                        value={last_name}
                        onChange={(e) => setlast_name(e.target.value)}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",

                      width: "100%",
                    }}
                  >
                    <div
                      className="input-fiend-response"
                      style={{ color: "red", width: "40%" }}
                    >
                      <p>{response && response.firstName}</p>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",

                      width: "100%",
                    }}
                  ></div>
                  <div className="login-page-sub-one-sec-two">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "60px",
                      }}
                    >
                      <div className="input-fiend-one">
                        <input
                          type="email"
                          className="email-input-field-tag"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setemail(e.target.value)}
                        />
                      </div>
                      <div className="input-fiend-one">
                        <input
                          type="number"
                          className="email-input-field-tag"
                          placeholder="Mobile"
                          value={mobile}
                          onChange={(e) => setmobile(e.target.value)}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "60px",
                        width: "100%",
                      }}
                    >
                      <div
                        className="input-fiend-response"
                        style={{ color: "red", width: "40%" }}
                      >
                        <p>{response && response.email}</p>
                      </div>
                      <div
                        className="input-fiend-response"
                        style={{ color: "red", width: "40%" }}
                      >
                        <p>{response && response.mobile}</p>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "60px",
                        width: "100%",
                      }}
                    ></div>
                  </div>{" "}
                  <div className="login-page-sub-one-sec-two">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "60px",
                      }}
                    >
                      <div className="input-fiend-one">
                        <AutoComplete parentCallback={onHandleChange} />
                      </div>

                      <div className="input-fiend-one">
                        <input
                          type="text"
                          className="email-input-field-tag"
                          placeholder="Username"
                          value={username}
                          onChange={(e) => setusername(e.target.value)}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",

                        width: "100%",
                      }}
                    >
                      <div
                        className="input-fiend-response"
                        style={{ color: "red", width: "50%" }}
                      >
                        <p></p>
                      </div>

                      <div
                        className="input-fiend-response"
                        style={{ color: "red", width: "50%" }}
                      >
                        <p>{response && response.username}</p>
                        <p>{duplicateUsername}</p>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                        width: "100%",
                      }}
                    ></div>
                    <div className="login-page-sub-one-sec-two">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "60px",
                        }}
                      >
                        <div className="input-fiend-one">
                          <input
                            type={passwordShown ? "text" : "password"}
                            className="email-input-field-tag"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setpassword(e.target.value)}
                          />
                          {passwordShown ? (
                            <VisibilityOffIcon
                              onClick={togglePassword}
                              style={{ cursor: "pointer", position: "absolute", transform: 'translateX(-90%)'}}
                            />
                          ) : (
                            <VisibilityIcon
                              onClick={togglePassword}
                              style={{ cursor: "pointer", position: "absolute", transform: 'translateX(-90%)'}}
                            />
                          )
                            
                          }
                        </div>

                        <div className="input-fiend-one">
                          <input
                            type={passwordShown ? "text" : "password"}
                            className="email-input-field-tag"
                            placeholder="Check Password"
                            value={password2}
                            onChange={(e) => setpassword2(e.target.value)}
                          />
                          {passwordShown ? (
                            <VisibilityOffIcon
                              onClick={togglePassword}
                              style={{ cursor: "pointer", position: "absolute",transform: 'translateX(-90%)' }}
                            />
                          ) : (
                            <VisibilityIcon
                              onClick={togglePassword}
                              style={{ cursor: "pointer", position: "absolute", transform: 'translateX(-90%)' }}
                            />
                          )
                            
                          }
                        </div>
                        
                      </div>
                      {passwordError && <p style={{color: 'red', marginLeft:"250px"}}>{passwordError}</p>}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "60px",
                          width: "100%",
                        }}
                      >
                        <div
                          className="input-fiend-response"
                          style={{ color: "red", width: "50%" }}
                        >
                          <p>{response && response.password}</p>
                        </div>
                        <div
                          className="input-fiend-response"
                          style={{ color: "red", width: "50%" }}
                        >
                          <p>{response && response.password2}</p>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "60px",
                          width: "100%",
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="login-page-sub-one-sec-two">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "60px",
                      }}
                    >
                      <div className="input-fiend-one">
                        <select
                          className="email-input-field-tag"
                          onChange={(e) => setgender(e.target.value)}
                          style={{ width: "200px" }}
                        >
                          <option>Select Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Not Specified">Not Specified</option>
                        </select>
                        {genderError && <p style={{color: 'red'}}>{genderError}</p>}
                      </div>
                      
                      
                      <div className="input-fiend-one">
                        <select
                          className="email-input-field-tag"
                          onChange={(e) => setreligion(e.target.value)}
                          style={{ width: "200px" }}
                        >
                          <option>Select Religion</option>
                          <option value="Christianity">Christianity</option>
                          <option value="Islam">Islam</option>
                          <option value="Hindu">Hindu</option>
                          <option value="Buddhism">Buddhism</option>
                          <option value="Judaism">Judaism</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",

                        width: "100%",
                      }}
                    ></div>
                  </div>
                  <div className="login-page-sub-one-sec-two">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "60px",
                      }}
                    >
                      <div className="input-fiend-one">
                        <p
                          className="email-input-field-tag"
                          style={{ fontSize: "14px" }}
                        >
                          Date of Birth
                        </p>
                      </div>
                      <div className="input-fiend-one">
                        <input
                          type="date"
                          className="email-input-field-tag"
                          placeholder="Date of Birth"
                          value={date_of_birth}
                          onChange={(e) => setdate_of_birth(e.target.value)}
                        />
                      </div>
                      
                    </div>
                    {date_of_birthError && <p style={{ color: 'red', marginLeft: '250px'}}>{date_of_birthError}</p>}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",

                        width: "100%",
                      }}
                    ></div>
                  </div>
                  <div className="login-page-sub-one-sec-two">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "60px",
                      }}
                    >
                      <div className="input-fiend-one">
                        <select
                          className="email-input-field-tag"
                          onChange={(e) => seteducation(e.target.value)}
                          style={{ width: "200px" }}
                        >
                          <option>Select Education</option>
                          <option value="High School">High School</option>
                          <option value="College">College</option>
                          <option value="Bachelor's degree">
                            Bachelor's degree
                          </option>
                          <option value="Master's degree">
                            Master's degree
                          </option>
                          <option value="PhD">PhD</option>
                          <option value="Post Doctorate">Post Doctorate</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                      <div className="input-fiend-one">
                        <select
                          className="email-input-field-tag"
                          onChange={(e) => setmarital_status(e.target.value)}
                          style={{ width: "200px" }}
                        >
                          <option>Marital Status</option>
                          <option value="Married">Married</option>
                          <option value="Unmarried">Unmarried</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",

                        width: "100%",
                      }}
                    ></div>
                  </div>
                  <div className="login-page-sub-one-sec-two">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "60px",
                      }}
                    >
                      <div className="input-fiend-one">
                        <select
                          className="email-input-field-tag"
                          onChange={(e) => setblood_type(e.target.value)}
                          style={{ width: "200px" }}
                        >
                          <option>Select Blood Group</option>
                          <option value="A+">A+</option>
                          <option value="A-">A-</option>
                          <option value="B+">B+</option>
                          <option value="O+">O+</option>
                          <option value="O-">O-</option>
                          <option value="AB+">AB+</option>
                          <option value="AB-">AB-</option>
                          <option value="Not available">Not available/aware</option>
                        </select>
                      </div>
                      <div className="input-fiend-one">
                        <input
                          type="text"
                          className="email-input-field-tag"
                          placeholder="Goverment ID Number"
                          value={govidnum}
                          onChange={(e) => setgovidnum(e.target.value)}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",

                        width: "100%",
                      }}
                    ></div>
                  </div>
                  <div className="login-page-sub-one-sec-two">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "60px",
                      }}
                    >
                      <div className="input-fiend-one">
                        <input
                          type="text"
                          className="email-input-field-tag"
                          placeholder="Health Care Insurance Name"
                          value={health_care_insurance_name}
                          onChange={(e) =>
                            sethealth_care_insurance_name(e.target.value)
                          }
                        />
                      </div>

                      <div className="input-fiend-one">
                        <input
                          type="text"
                          className="email-input-field-tag"
                          placeholder="Health Care Insurance Number"
                          value={health_care_insurance_number}
                          onChange={(e) =>
                            sethealth_care_insurance_number(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",

                        width: "100%",
                      }}
                    ></div>
                  </div>
                  <div className="login-page-sub-one-sec-two">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "60px",
                      }}
                    >
                      <div className="input-fiend-one">
                        <p
                          className="email-input-field-tag"
                          style={{ fontSize: "14px" }}
                        >
                          Profile Picture
                        </p>
                      </div>
                      <div className="input-fiend-one">
                        <input
                          type="file"
                          className="email-input-field-tag"
                          onChange={handleFileSelect}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",

                        width: "100%",
                      }}
                    >
                      <div
                        className="input-fiend-response"
                        style={{ color: "red", width: "50%" }}
                      >
                        <p></p>
                      </div>

                      <div
                        className="input-fiend-response"
                        style={{ color: "red", width: "50%" }}
                      >
                        <p>{response && response.image}</p>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="login-page-sub-one-sec-four"
              style={{ display: "flex", width: "400px", alignItems: "center" }}
            >
              <div
                style={{
                  display: "flex",
                  width: "5000px",
                  fontSize: "13px",
                  gap: "3px",
                }}
              >
                <p>I have read and agree to the</p>{" "}
                <Link to="/privacypolicy" target="_blank" rel="noopener noreferrer">
                  <p style={{ gap:'10px', color: "blue", cursor: "pointer" }}>
                    Privacy Policy
                  </p>
                </Link>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  fontSize: "13px",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px", }}
                >
                  <input
                    type="radio"
                    value="Yes"
                    name="privacy"
                    onChange={(e) => setprivacy_policy(e.target.value)}
                  />
                  <p>Yes</p>
                </div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <input
                    type="radio"
                    value="No"
                    name="privacy"
                    onChange={(e) => setprivacy_policy(e.target.value)}
                  />
                  <p>No</p>
                </div>
              </div>
              <div className="error-message-preview">
                <p>{response && response.privacy_policy}</p>
              </div>
            </div>

            <div
              className="login-page-sub-one-sec-four"
              style={{
                display: "flex",
                width: "400px",
                alignItems: "center",
                paddingTop: "4px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "5000px",
                  fontSize: "13px",
                  gap: "3px",
                }}
              >
                <p>I have read and agree to the</p>{" "}
                <Link to="/termsandconditions" target="_blank" rel="noopener noreferrer">
                  <p style={{ color: "blue", cursor: "pointer" }}>
                    Terms and Conditions
                  </p>
                </Link>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  fontSize: "13px",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <input
                    type="radio"
                    value="Yes"
                    name="term"
                    onChange={(e) => setTerms_and_Conditions(e.target.value)}
                  />
                  <p>Yes</p>
                </div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <input
                    type="radio"
                    value="No"
                    name="term"
                    onChange={(e) => setTerms_and_Conditions(e.target.value)}
                  />
                  <p>No</p>
                </div>
              </div>
              <div className="error-message-preview">
                <p>{response && response.Terms_and_Conditions}</p>
              </div>
            </div>

            {/* Accessibility Policy */}
            <div
              className="login-page-sub-one-sec-four"
              style={{
                display: "flex",
                width: "400px",
                alignItems: "center",
                paddingTop: "4px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "5000px",
                  fontSize: "13px",
                  gap: "3px",
                }}
              >
                <p>I have read and agree to the</p>{" "}
                <Link to="/accessibilitypolicy" target="_blank" rel="noopener noreferrer">
                  <p style={{ color: "blue", cursor: "pointer" }}>
                    Accessibility Policy
                  </p>
                </Link>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  fontSize: "13px",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <input
                    type="radio"
                    value="Yes"
                    name="policy"
                    onChange={(e) => setaccessibility_policy(e.target.value)}
                  />
                  <p>Yes</p>
                </div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <input
                    type="radio"
                    value="No"
                    name="policy"
                    onChange={(e) => setaccessibility_policy(e.target.value)}
                  />
                  <p>No</p>
                </div>
              </div>
              <div className="error-message-preview">
                <p>{response && response.accessibility_policy}</p>
              </div>
            </div>
            {policyError && <p style={{color: 'red'}}>{policyError}</p>}

            <div
              className="login-page-sub-one-sec-four"
              style={{ display: "flex", justifyContent: "center" }}
            >
              
              <button
                // onClick={register}
                // Disable button if any policy isn't 'Yes'
                
                onClick={handleSubmit}
                className="login-page-login-btn"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Next{" "}
                <ArrowForwardSharpIcon
                  style={{ color: "white", height: "16px" }}
                />
              </button>
            </div>
            <div className="login-page-sub-one-sec-four">
              <Link to="/">
                <button className="login-page-login-btn">Login</button>
              </Link>
            </div>
            <div className="login-page-sub-one-sec-five">
              <p>Forget Password ?</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientSignUp;
