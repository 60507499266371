import React from "react";
import axios from "axios";
import "./Dashboard.css";
import { GoAlert } from "react-icons/go";
import { AiFillExclamationCircle } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import Calender from "../Calender/Calender";
import TreatmentSuccessRateDoughnut from "../TreatmentSuccessRateDoughnut/TreatmentSuccessRateDoughnut";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PatientLogo from "../../Images/ClinicSVG/newdoctor.svg";
import { useState } from "react";
import { useEffect } from "react";
import Loader from "../Loader/Loader";
import { AiOutlinePlus } from "react-icons/ai";
import { Link } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { TableHead } from "@mui/material";
import swal from "sweetalert";
import Apiservice from "../../Services/Apiservice";



const Dashboard = () => {
  const [open, setOPen] = useState(false);
  const [approveresponse, setapproveresponse] = useState("");
  const [currentId, setCurrentId] = useState(null);
  const [appointmentapprove, setappointmentapprove] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [mypatients, setmypatients] = useState([]);
  const [appointmentget, setappointmentget] = useState("");
  const [totalappointments, settotalappointments] = useState([]);

  const [shootCalender, setshootCalender] = useState(true);
  const [refreshCalender, setrefreshCalender] = useState(true);

  //console.log("appointmentget", appointmentget);

  const refreshCalenderHandler = () => {
    setshootCalender(!shootCalender);
  };

  const refreshCalenderAgainHandler = () => {
    setrefreshCalender(true);
  };

  const toggle = () => {
    setOPen(!open);
  };
  const DoctorName = localStorage.getItem("Username");
  const PatientIDForAppointment = localStorage.getItem(
    "PatientIDForAppointment"
  );

  const userSpecificAuth = localStorage.getItem("SavedToken");

  const createData = (id, name, visitType, button) => {
    return { id, name, visitType, button };
  };

  const isdietitian = localStorage.getItem("is_dietitian");
  const is_trainer = localStorage.getItem("is_trainer");
  const is_doctor = localStorage.getItem("is_doctor");

  // APPOINTMENT COUNT
  useEffect(() => {
    Apiservice
      .get(
        
        isdietitian == "true"
        ? `/dietitian/appointmentcount/${DoctorName}/`
        : is_trainer == "true"
        ? `/trainer/appointmentcount/${DoctorName}/`
        : `/appointmentcount/${DoctorName}/`,
        
        
        // `https://djangoobesitychat.azurewebsites.net/appointmentcount/${DoctorName}/`, 
      
      {})

      .then((response) => {
        settotalappointments(response.data);
        setLoading(false);
      })
      .catch((err) => {});
  }, []);
  // if (!mypatients) return null;

  const [mainDataLoading, setmainDataLoading] = useState(true);
  const [mainDataLoadingtwo, setmainDataLoadingtwo] = useState(false);
  const [DrugInteractionCount, setDrugInteractionCount] = useState("");
  const [ContraIndicationCount, setContraIndicationCount] = useState("");
  const [WarningCount, setWarningCount] = useState("");

  // CARD DATA IN DASHOBARD
  useEffect(() => {
    Apiservice
      .get(
        isdietitian == "true"
          ? `/dietitian/patient/${DoctorName}/`
          : is_trainer == "true"
          ? `/trainer/patient/${DoctorName}/`
          : `/patientresponse/${DoctorName}/`,
        {}
      )

      .then((response) => {
        setmainDataLoading(false);
        setmypatients(response.data);
        setDrugInteractionCount(
          response.data["total_drug_interaction_list"].length
        );
        setContraIndicationCount(
          response.data["contraindications_list"].length
        );
        setWarningCount(
          response.data["total_warnings_and_precautions_list"].length
        );
        // setLoading(false);
      })
      .catch((err) => {
        setmainDataLoading(false);
      });
  }, []);

  // const DrugInteraction = mypatients&&mypatients.total_drug_interaction_list
  // const DrugInteractionCount = DrugInteraction.length()
  ////console.log("DrugInteractionCount", DrugInteractionCount);
  ////console.log("ContraIndicationCount", ContraIndicationCount);
  ////console.log("WarningCount", WarningCount);
  // DOCTOR APPOINTMENTS GET

  useEffect(() => {
    Apiservice
      .get(
        isdietitian == "true"
          ? `/appointmentapi/dietitian/${DoctorName}/`
          : is_trainer == "true"
          ? `/appointmentapi/trainer/${DoctorName}/`
          : `/appointmentapi/doctor/${DoctorName}/`,
        {}
      )

      .then((response) => {
        setappointmentget(response.data);
        setLoading(false);
      })
      .catch((err) => {});
  }, []);

  // // //console.log("appointmentget", appointmentget);

  const rows = [];

  // Appointment Approval
  {
    /*
  const addPatient = async (event) => {
    event.preventDefault();
    setmainDataLoading(true);
    const formData = new FormData();
    formData.append("is_approved", approveresponse);
    formData.append("doctor_name", DoctorName);
    formData.append("patient_id2", PatientUserIdForAppointment);
    formData.append("starttime", PatientStartTimeForAppointment);

    axios({
      method: "put",
      url: `https://djangoobesitychat.azurewebsites.net/appointment/confirmation/${PatientIDForAppointment}/`,
      data: formData,
    })
      .then((res) => {
        setmainDataLoading(false);

        toggle();
        refreshCalenderHandler();
      })

      .catch((res) => {
        setmainDataLoading(false);

      });
  }
*/
  }

  const addPatient = async (e) => {
    e.preventDefault();
    setmainDataLoadingtwo(true);
    is_doctor == "true"
      ? await axios
          .put(
            `https://djangoobesitychat.azurewebsites.net/appointment/confirmation/${PatientIDForAppointment}/`,
            {
              is_approved: approveresponse,
              doctor_name: DoctorName,
              patient_id2: PatientUserIdForAppointment,
              starttime: PatientStartTimeForAppointment,
            },
            {
              headers: {
                Authorization: `Token ${userSpecificAuth}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            setmainDataLoadingtwo(false);
            toggle();
            refreshCalenderHandler();
            Apiservice
              .get(
                `/appointmentapi/doctor/${DoctorName}/`,
                {},
                {
                  headers: {
                    Authorization: `Token ${userSpecificAuth}`,
                    "Content-Type": "application/json",
                  },
                }

              )
              .then((response) => {
                setappointmentget(response.data);
                setLoading(false);
                // refreshCalenderAgainHandler();
              });

            if (approveresponse == "Approved") {
              swal({
                title: "Appointment is Approved",
                className: "successAlert",
                buttons: true,
                timer: 3000,
              });
            } else {
              swal({
                title: "Appointment is Rejected",
                className: "successAlert",
                buttons: true,
                timer: 3000,
              });
            }
          })
          .catch((err) => {
            setmainDataLoadingtwo(false);
          })
      : isdietitian == "true"
      ? await axios
          .put(
            `https://djangoobesitychat.azurewebsites.net/dietitian/confirm/${PatientIDForAppointment}/`,
            {
              is_approved: approveresponse,
              dietitian_name: DoctorName,
              // day: AppointmentDay,
              patient_id2: PatientUserIdForAppointment,
              starttime: PatientStartTimeForAppointment,
            },
            {
              headers: {
                Authorization: `Token ${userSpecificAuth}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            setmainDataLoadingtwo(false);
            toggle();
            refreshCalenderHandler();
            Apiservice
              .get(
                `/appointmentapi/dietitian/${DoctorName}/`
              )
              .then((response) => {
                setappointmentget(response.data);
                setLoading(false);
                // refreshCalenderAgainHandler();
              });

            if (approveresponse == "Approved") {
              swal({
                title: "Appointment is Approved",
                className: "successAlert",
                buttons: true,
                timer: 3000,
              });
            } else {
              swal({
                title: "Appointment is Rejected",
                className: "successAlert",
                buttons: true,
                timer: 3000,
              });
            }
          })
          .catch((err) => {
            // // // //console.log(err);
            setmainDataLoadingtwo(false);
          })
      : await axios
          .put(
            `https://djangoobesitychat.azurewebsites.net/trainer/confirm/${PatientIDForAppointment}/`,
            {
              is_approved: approveresponse,
              trainer_name: DoctorName,
              // day: AppointmentDay,
              patient_id2: PatientUserIdForAppointment,
              starttime: PatientStartTimeForAppointment,
            },
            {
              headers: {
                Authorization: `Token ${userSpecificAuth}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            setmainDataLoadingtwo(false);
            toggle();
            refreshCalenderHandler();
            Apiservice
              .get(
                isdietitian == "true"
                  ? `/appointmentapi/dietitian/${DoctorName}/`
                  : is_trainer == "true"
                  ? `/appointmentapi/trainer/${DoctorName}/`
                  : `/appointmentapi/doctor/${DoctorName}/`,
                {}
              )
              .then((response) => {
                setappointmentget(response.data);
                setLoading(false);
                // refreshCalenderAgainHandler();
              });

            if (approveresponse == "Approved") {
              swal({
                title: "Appointment is Approved",
                className: "successAlert",
                buttons: true,
                timer: 3000,
              });
            } else {
              swal({
                title: "Appointment is Rejected",
                className: "successAlert",
                buttons: true,
                timer: 3000,
              });
            }
          })
          .catch((err) => {
            // // // //console.log(err);
            setmainDataLoadingtwo(false);
          });
  };
  const createDataTwo = (name, visitTime, button) => {
    return { name, visitTime, button };
  };
  const rowsTwo = [];

  const rowsOne = [mypatients.revisit_data_list];
  const rowsThree = [mypatients.my_patients];
  // Create a new list that combines the revisit_data_list and my_patients data
  const combinedData =
    mypatients.revisit_data_list &&
    mypatients.revisit_data_list.map((revisitItem) => {
      const myPatientsItem =
        mypatients.my_patients &&
        mypatients.my_patients.find((item) =>
          is_doctor == "true"
            ? item && item.patient_id2_id === revisitItem.patient_id
            : item && item.patient_id_id === revisitItem.patient_id
        );
      return myPatientsItem && { ...revisitItem, ...myPatientsItem };
    });

  const handleClick = (key) => {
    localStorage.setItem("patientUserName", key);
  };
  const handleClicktwo = (keytwo) => {
    localStorage.setItem("PatientIDForAppointment", keytwo);
  };
  const handleClickthree = (keythree) => {
    localStorage.setItem("PatientUserIdForAppointment", keythree);
  };
  const handleClickfour = (keyfour) => {
    localStorage.setItem("PatientStartTimeForAppointment", keyfour);
  };
  // const handleClickfive = (keyfour) => {
  //   localStorage.setItem("PatientSubClinicIdForAppointment", keyfour);
  // };
  // const handleClicksix = (keyfour) => {
  //   localStorage.setItem("PatientReceptionistIdForAppointment", keyfour);
  // };

  const AppointmentDay = localStorage.getItem("PatientAppointmentDay");
  const PatientUserIdForAppointment = localStorage.getItem(
    "PatientUserIdForAppointment"
  );
  const PatientStartTimeForAppointment = localStorage.getItem(
    "PatientStartTimeForAppointment"
  );
  // const PatientClinicIdForAppointment =  localStorage.getItem("PatientClinicIdForAppointment");
  // const PatientSubClinicIdForAppointment =  localStorage.getItem("PatientSubClinicIdForAppointment");
  // const PatientReceptionistIdForAppointment =  localStorage.getItem("PatientReceptionistIdForAppointment");
  // // //console.log("AppointmentTime", AppointmentTime);
  // // //console.log("AppointmentDay", AppointmentDay);
  const uniqueIds = [];
  const uniquePatients =
    combinedData &&
    combinedData.filter((element) => {
      const isDuplicate = uniqueIds.includes(element && element.patient_id);

      if (!isDuplicate) {
        uniqueIds.push(element && element.patient_id);

        return true;
      }

      return false;
    });
  // // //console.log("combinedData", combinedData);
  console.log("uniqueNames", uniquePatients);
  const currentYear = new Date().getFullYear();
  
  const [value, onChange] = useState(new Date());

  const DateAppt = value.toLocaleDateString("fr-CA");
  const NewDateofAppointment = DateAppt + "T00:00:00Z";
  //console.log("DateAppt", NewDateofAppointment);

  function calculateAge(row) {
    if (row && row.patient_data && row.patient_data[0] && row.patient_data[0]["date_of_birth"]) {
      const dob = new Date(row.patient_data[0]["date_of_birth"]);
      const currentYear = new Date().getFullYear();
      return currentYear - dob.getFullYear();
    }
    return null;
  }

  return (
    <div className="dahs-board-flex-div">
      {mainDataLoading && (
        <div className="purchase-order-details-pop-up-main-div">
          <div className="purchase-order-details-pop-up-sub-div">
            <div
              className="purchase-order-details-pop-up-sub-div-container"
              style={{ background: "none" }}
            >
              <Loader />
            </div>
          </div>
        </div>
      )}

      {mainDataLoadingtwo == true ? (
        <div className="purchase-order-details-pop-up-main-div">
          <div className="purchase-order-details-pop-up-sub-div">
            <div
              className="purchase-order-details-pop-up-sub-div-container"
              style={{ background: "none" }}
            >
              <Loader />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="dahs-board-width-div">
        <div className="dash-board-main-div-for-flex">
          <div className="dahs-board-main-div">
            <div className="dahs-board-sub-div-one">
              <h4>Welcome back, Dr. {DoctorName}</h4>
              <p>
                Thank you for using Obesity Tool,the digital workplace. It's a
                pleasure to have you here!!
              </p>
              <p>
                This is the Obesity Tool dashboard. Here you can fin data and
                summarise about your Patient and treatment activity.{" "}
              </p>
            </div>
            <div className="dahs-board-sub-div-two">
              <div className="dahs-board-sub-div-two-width">
                <div className="dahs-board-sub-div-two-part-one">
                  <Link
                    to={
                      isdietitian == "true"
                        ? "/dietitiansidebar/patientlist"
                        : is_trainer == "true"
                        ? "/trainersidebar/patientlist"
                        : "/dashboard/patientlist"
                    }
                  >
                    <div
                      className="sub-two-card-one"
                      style={{ cursor: "pointer" }}
                    >
                      <p className="card-para-tag" style={{ color: "black" }}>
                        Active Patient
                      </p>
                      <h4 style={{ color: "black" }}>
                        {mypatients.activePatient}
                      </h4>
                    </div>
                  </Link>
                  <Link
                    to={
                      isdietitian == "true"
                        ? "/dietitiansidebar/contraindicationslist"
                        : is_trainer == "true"
                        ? "/trainersidebar/contraindicationslist"
                        : "/dashboard/contraindicationslist"
                    }
                  >
                    <div
                      className="sub-two-card-two-alerts"
                      style={{ cursor: "pointer" }}
                    >
                      <p className="card-para-tag" style={{ color: "black" }}>
                        Critical Alerts
                      </p>
                      <div className="critical-alerts-div">
                        <GoAlert style={{ color: "red" }} />
                        <h4 style={{ color: "black" }}>
                          {ContraIndicationCount}
                        </h4>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="dahs-board-sub-div-two-part-one">
                  <div className="sub-two-card-one">
                    <p className="card-para-tag">
                      Patient Treated in the next 48 hours
                    </p>
                    <h4 style={{ marginTop: "-2vh" }}>
                      {mypatients.Next_48hr_appointment_count}
                    </h4>
                  </div>

                  <Link
                    to={
                      isdietitian == "true"
                        ? "/dietitiansidebar/warninglist"
                        : is_trainer == "true"
                        ? "/trainersidebar/warninglist"
                        : "/dashboard/warninglist"
                    }
                  >
                    <div
                      className="sub-two-card-two-warning"
                      style={{ cursor: "pointer" }}
                    >
                      <p className="card-para-tag" style={{ color: "black" }}>
                        Warning Alerts
                      </p>
                      <div className="critical-alerts-div">
                        <AiFillExclamationCircle
                          style={{
                            color: "rgb(255,192,72)",
                            height: "4vh",
                            width: "4vw",
                            // marginTop: "1.5vw",
                          }}
                        />
                        <h4 style={{ color: "black" }}>{WarningCount}</h4>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="dahs-board-sub-div-two-part-one">
                  <Link
                    to={
                      isdietitian == "true"
                        ? "/dietitiansidebar/druginteractionlist"
                        : is_trainer == "true"
                        ? "/trainersidebar/druginteractionlist"
                        : "/dashboard/druginteractionlist"
                    }
                  >
                    <div
                      className="sub-two-card-one"
                      style={{ cursor: "pointer" }}
                    >
                      <p className="card-para-tag" style={{ color: "black" }}>
                        Drugs drugs interaction
                      </p>
                      <h4 style={{ color: "black" }}>{DrugInteractionCount}</h4>
                    </div>
                  </Link>
                  <div className="sub-two-card-two">
                    <p className="card-para-tag">
                      Today's Total Number of Appointment
                    </p>
                    <h4 style={{ paddingTop: "10px" }}>
                      {totalappointments.appointment_count}
                    </h4>
                  </div>
                </div>
                <div className="dahs-board-sub-div-two-part-one">
                  <Link
                    to={
                      isdietitian == "true"
                        ? "/dietitiansidebar/patientlist"
                        : is_trainer == "true"
                        ? "/trainersidebar/patientlist"
                        : "/dashboard/patientlist"
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <div className="sub-two-card-one-full">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          gap: "20px",
                          alignItems: "center",
                        }}
                      >
                        <FaUserAlt style={{ color: "rgb(45,164,215)" }} />
                        <p className="card-para-tag" style={{ color: "black" }}>
                          New Patient
                        </p>
                      </div>
                      <h4 style={{ paddingTop: "3vh", color: "black" }}>
                        {mypatients.new_count}
                      </h4>
                      <hr style={{ marginTop: "5vh" }} />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          gap: "20px",
                          alignItems: "center",
                          paddingTop: "30px",
                          // borderTop:"1px solid"
                        }}
                      >
                        <FaUserAlt style={{ color: "rgb(45,164,215)" }} />
                        <p className="card-para-tag" style={{ color: "black" }}>
                          Existing Patient
                        </p>
                      </div>
                      <h4 style={{ paddingTop: "3vh", color: "black" }}>
                        {mypatients.revisit_count}
                      </h4>
                    </div>
                  </Link>
                </div>
                <div className="dahs-board-sub-div-two-part-two">
                  <Calender dependency={shootCalender} />
                </div>
              </div>
            </div>
          </div>

          <div className="dahs-board-main-div-two" style={{ width: "100%" }}>
            <div 
              className="dahs-board-main-div-two-sub-one"
              style={{ width: "50%" }}
            >
              <h3 style={{ paddingBottom: "10px" }}>My Patients</h3>
              <TableContainer component={Paper} style={{ height: "335px" }}>
                {loading ? (
                  <Loader />
                ) : (
                  <Table aria-label="simple table">
                    <TableHead
                      style={{
                        position: "sticky",
                        top: "0",
                        color: "white",
                        backgroundColor: "rgb(49, 163, 214)",
                      }}
                    >
                      <TableRow>
                        <TableCell align="left">Logo</TableCell>
                        <TableCell align="left">Name</TableCell>
                        <TableCell align="left">Contact</TableCell>
                        <TableCell align="left">Gender</TableCell>
                        <TableCell align="left">Age</TableCell>
                        <TableCell align="left">Patient Type</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {uniquePatients &&
                        uniquePatients.map((row) => (
                          <TableRow
                            key={row && row.doctor_name_id}
                            id={row && row.patient_id2_id}
                            onClick={() =>
                              handleClick(
                                row && row.patient_data[0]["username"]
                              )
                            }
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="center">
                              <img
                                src={PatientLogo}
                                alt=""
                                style={{ height: "30px", width: "30px" }}
                              />
                            </TableCell>

                            <TableCell align="left" style={{ padding: "10px" }}>
                              <Link
                                to={
                                  is_doctor == "true" &&
                                  "/dashboard/patientsummary"
                                }
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                  fontWeight: "600",
                                }}
                              >
                                {row && row.patient_data[0]["firstName"]}{" "}
                                {row && row.patient_data[0]["lastName"]}
                              </Link>
                            </TableCell>
                            <TableCell align="left" style={{ padding: "10px" }}>
                              {row && row.patient_data[0]["mobile"]}
                            </TableCell>
                            <TableCell align="left">
                              {row && row.patient_data[0]["gender"]}
                            </TableCell>
                            <TableCell align="left">
                              {calculateAge(row)}
                            </TableCell>
                            <TableCell align="left" style={{ padding: "10px" }}>
                              <button className="consul-tation-btn-tag-new">
                                {row && row.revisit_data}
                              </button>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            </div>

            <div
              className="dahs-board-main-div-two-sub-one"
              style={{ width: "50%" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingBottom: "10px",
                }}
              >
                <h3 style={{}}>Appointment Requests</h3>
                <Link to={
                      isdietitian == "true"
                        ? "/dietitiansidebar/dietitianappointmentlist"
                        : is_trainer == "true"
                        ? "/trainersidebar/trainerappointmentlist"
                        : "/dashboard/doctorappointmentlist"
                    }>
                  <button className="add-btn-btn-tag">All Appointments</button>
                </Link>
              </div>

              <TableContainer component={Paper} style={{ height: "335px" }}>
                {loading ? (
                  <Loader />
                ) : (
                  <Table aria-label="simple table">
                    <TableHead
                      style={{
                        position: "sticky",
                        top: "0",
                        color: "white",
                        backgroundColor: "rgb(49, 163, 214)",
                      }}
                    >
                      <TableRow>
                        <div>
                          <TableCell align="left">Logo</TableCell>
                          <TableCell align="left">+</TableCell>
                          <TableCell align="left">Name</TableCell>
                          <TableCell align="left">Date</TableCell>
                          <TableCell align="left">Time</TableCell>
                          <TableCell align="left">Approve</TableCell>
                        </div>
                      </TableRow>
                    </TableHead>
                    {mypatients?.Next_48hr_appointment_count != 0 ? (
                    <TableBody>
                      {appointmentget &&
                        appointmentget.map((row, i) => (
                          <TableRow
                            key={row && row.appoinment_id}
                            id={row && row.patient_id2}
                            onClick={() =>
                              handleClicktwo(row && row.appoinment_id) ||
                              handleClickfour(row && row.starttime) ||
                              is_doctor == "true"
                                ? handleClickthree(row && row.patient_id2)
                                : handleClickthree(row && row.patient_id)
                            }
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            {row &&
                            row.is_approved === "Pending" &&
                            row &&
                            row.starttime >= NewDateofAppointment ? (
                              <div>
                                <TableCell align="left" style={{ padding: "10px" }}>
                                    <img
                                      src={PatientLogo}
                                      alt=""
                                      style={{ height: "30px", width: "30px" }}
                                    />
                                  </TableCell>
                                  <TableCell align="left" style={{ padding: "10px" }}>
                                    <h3
                                      style={{
                                        fontSize: "14px",
                                        cursor: "pointer",
                                      }}
                                      onClick={toggle}
                                    >
                                      <AiOutlinePlus />
                                      {/*{row.appoinment_id}*/}
                                    </h3>
                                  </TableCell>
                                  <TableCell align="left" style={{ padding: "10px" }}>
                                    <p style={{ fontWeight: "600" }}>
                                      {row && row.patient_firstName} {row && row.patient_lastName}
                                    </p>
                                  </TableCell>
                                  <TableCell align="left" style={{ padding: "10px" }}>
                                    {row && row.day}
                                  </TableCell>
                                  <TableCell align="left" style={{ padding: "10px" }}>
                                    {row && row.starttime.slice(0, 10)}
                                  </TableCell>
                                  <TableCell align="left" style={{ padding: "10px" }}>
                                    {row && row.starttime.slice(11, 16)}
                                  </TableCell>
                                  <TableCell align="left" style={{ padding: "10px" }}>
                                    <div>
                                      <div style={{ display: "flex" }}>
                                        <input
                                          type="radio"
                                          name="appointmentapprove"
                                          value="Approved"
                                          onClick={(event) =>
                                            setapproveresponse(event.target.value)
                                          }
                                        />
                                        <p>Approved</p>
                                      </div>
                                      <div style={{ display: "flex" }}>
                                        <input
                                          type="radio"
                                          name="appointmentapprove"
                                          value="Rejected"
                                          onClick={(event) =>
                                            setapproveresponse(event.target.value)
                                          }
                                        />
                                        <p>Rejected</p>
                                      </div>
                                    </div>
                                  </TableCell>
                                  <TableCell align="left" style={{ padding: "10px" }}>
                                    <button
                                      onClick={addPatient}
                                      className="approved-button-msg"
                                    >
                                      Submit
                                    </button>
                                  </TableCell>
                              </div>
                            ) : (
                              ""
                            )}
                          </TableRow>
                        ))}
                    </TableBody>
                    ):(
                      <div style={{display:"flex",justifyContent:"center",alignItems:"center",height:"335px"}}>
                        <h4>No Appointment Requests</h4>
                      </div>
                    )}
                  </Table>
                )}
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
