import React, { useState, useEffect } from "react";
import "./Login.css";
// import HandImg from "../../Images/HandImageLoginPg.png";
// import LoginPageImage from "../../Images/FrontMainImage2.jpg";
import LoginPageImage from "../../Images/FrontMainImage2.jpg";
import { Navigate, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Loader from "../Loader/Loader";
import Apiservice from "../../Services/Apiservice";
import CryptoJS from 'crypto-js';
import { usePost } from "../../Services/ApiServiceReactQuery";

const Login = () => {
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [UserType, setUserType] = useState("");
  const [validation, setvalidation] = useState("");
  const [showvalidation, setshowvalidation] = useState(false);
  const navigate = useNavigate();
  const { mutate } = usePost("/testapi/");

  const [loading, setLoading] = useState(false);
  const [response, setresponse] = useState();

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const onlogin = async (e) => {
    e.preventDefault();

    setLoading(true);
    // navigate("/dashboard")
  // Generating a random salt
  const salt = CryptoJS.lib.WordArray.random(16);

  // Generating a random IV
  const iv = CryptoJS.lib.WordArray.random(16);

  // Deriving a key from the password and salt
  const key = CryptoJS.PBKDF2(username, salt, {
    keySize: 128 / 32,
    iterations: 1000,
    hasher: CryptoJS.algo.SHA256
  });

  // console.log('Key:', key); // Log the key for debugging

  // Encrypting the password with the derived key
  const encryptedPassword = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(password), key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });

  // console.log('Encrypted Password:', encryptedPassword); // Log the encrypted password for debugging

  const postData = {
    username: username,
    password: encryptedPassword.toString(CryptoJS.format.Base64),
    salt: CryptoJS.enc.Base64.stringify(salt),
    iv: CryptoJS.enc.Base64.stringify(iv)
  };

  console.log('Post Data:', postData); // Log the post data for debugging

  mutate(postData, {
    onSuccess: (res) => {
      console.log("res", res);
      if (res.error) {
        setresponse(res?.error);
        setLoading(false);
      }
      const { token, is_clinic, is_subclinic, is_patient, is_doctor, is_receptionist, is_dietitian, is_trainer, user_id } = res;

    localStorage.setItem("is_subclinic", is_subclinic);
    localStorage.setItem("is_clinic", is_clinic);
    localStorage.setItem("is_patient", is_patient);
    localStorage.setItem("is_doctor", is_doctor);
    localStorage.setItem("is_receptionist", is_receptionist);
    localStorage.setItem("is_dietitian", is_dietitian);
    localStorage.setItem("is_trainer", is_trainer);
    localStorage.setItem("id", user_id);
    localStorage.setItem("SavedToken", token);
    localStorage.setItem("SuperUser", res["SuperUser"]);

    if (is_doctor) {
      navigate("/dashboard");
    } else if (is_patient) {
      navigate("/patientDashboard");
    } else if (is_clinic) {
      navigate("/clinicsidebar");
    } else if (is_subclinic) {
      navigate("/subclinicsidebar");
    } else if (is_receptionist) {
      navigate("/receptionistsidebar");
    } else if (is_dietitian) {
      navigate("/dietitiansidebar");
    } else if (is_trainer) {
      navigate("/trainersidebar");
    } else if (res["SuperUser"]) {
      navigate("/customersuccessdashboard");
    }


      localStorage.setItem("Username", username);
      return mutate("/chat/api/online_users/", {
        online_status: true,
        username: username,
      });
    },
    onError: (error) => {
      setLoading(false);
      console.log("error", error?.error);
      setresponse(error?.error);
      
    },
    onSettled: () => {
      setLoading(false);
    }
  });
  console.log("response", response);

//     Apiservice.post("/testapi/", {
//   username: username,
//   password: encryptedPassword.toString(CryptoJS.format.Base64),
//   salt: CryptoJS.enc.Base64.stringify(salt),
//   iv: CryptoJS.enc.Base64.stringify(iv)
// })
//   .then((res) => {
//     if (res.error) {
//       setresponse(res?.error?.response?.data?.non_field_errors[0]);
//     }
//     const { token } = res.data;
//     const { is_clinic } = res.data;
//     const { is_subclinic } = res.data;
//     const { is_patient } = res.data;
//     const { is_doctor } = res.data;
//     const { is_receptionist } = res.data;
//     const { is_dietitian } = res.data;
//     const { is_trainer } = res.data;
//     const { user_id } = res.data;

//     localStorage.setItem("is_subclinic", is_subclinic);
//     localStorage.setItem("is_clinic", is_clinic);
//     localStorage.setItem("is_patient", is_patient);
//     localStorage.setItem("is_doctor", is_doctor);
//     localStorage.setItem("is_receptionist", is_receptionist);
//     localStorage.setItem("is_dietitian", is_dietitian);
//     localStorage.setItem("is_trainer", is_trainer);
//     localStorage.setItem("id", user_id);
//     localStorage.setItem("SavedToken", token, true);
//     localStorage.setItem("Gender", res.data["gender"]);

//     if (is_doctor == true) {
//       navigate("/dashboard");
//     } else if (is_patient == true) {
//       navigate("/patientDashboard");
//     } else if (is_clinic == true) {
//       navigate("/clinicsidebar");
//     } else if (is_subclinic == true) {
//       navigate("/subclinicsidebar");
//     } else if (is_receptionist == true) {
//       navigate("/receptionistsidebar");
//     } else if (is_dietitian == true) {
//       navigate("/dietitiansidebar");
//     } else if (is_trainer == true) {
//       navigate("/trainersidebar");
//     }

//     localStorage.setItem("Username", username);
//     return Apiservice.post("/chat/api/online_users/", {
//       online_status: true,
//       username: username,
//     });
//   })
//   .then((res) => {
//     setLoading(false);
//   })
//   .catch((error) => {
//     console.log("error", error);
    
//     setLoading(false);
//   });

    // try {
    //   const res = await Apiservice.post("/testapi/", {
    //     username: username,
    //     password: password,
    //   });
    //   console.log("res", res);
    //   //console.log("res.data", res.data["gender"]);
    //   // USERTYPE GET FROM REPONSE AND STORE TO LOCAL STORAGE TO NAVIGATE DASHBOARD ACCORDING TO THE USER
    //  const { token } = res.data;
      
    //   // const { SuperUser = true } = res.data;
    //   const { is_clinic } = res.data;
    //   const { is_subclinic } = res.data;
    //   const { is_patient } = res.data;
    //   const { is_doctor } = res.data;
    //   const { is_receptionist } = res.data;
    //   const { is_dietitian } = res.data;
    //   const { is_trainer } = res.data;
    //   const { user_id } = res.data;
    //   // const { refresh } = res.data;
    //   // const { email } = res.data;

    //   localStorage.setItem("is_subclinic", is_subclinic);
    //   localStorage.setItem("is_clinic", is_clinic);
    //   localStorage.setItem("is_patient", is_patient);
    //   localStorage.setItem("is_doctor", is_doctor);
    //   localStorage.setItem("is_receptionist", is_receptionist);
    //   localStorage.setItem("is_dietitian", is_dietitian);
    //   localStorage.setItem("is_trainer", is_trainer);
    //   localStorage.setItem("id", user_id);
    //   localStorage.setItem("SavedToken", token, true);
    //   localStorage.setItem("Gender", res.data["gender"]);

    //   if (is_doctor == true) {
    //     navigate("/dashboard");
    //   } else if (is_patient == true) {
    //     navigate("/patientDashboard");
    //   } else if (is_clinic == true) {
    //     navigate("/clinicsidebar");
    //   } else if (is_subclinic == true) {
    //     navigate("/subclinicsidebar");
    //   } else if (is_receptionist == true) {
    //     navigate("/receptionistsidebar");
    //   } else if (is_dietitian == true) {
    //     navigate("/dietitiansidebar");
    //   } else if (is_trainer == true) {
    //     navigate("/trainersidebar");
    //   }

    //   localStorage.setItem("Username", username);
    //   // // //console.log("err444", res);
    //   await Apiservice.post("/chat/api/online_users/", {
    //     online_status: true,
    //     username: username,
    //   });
    //   }
    //   catch (AxiosError) {
    //     console.log("error", AxiosError);
    //     setresponse(AxiosError);
    //   }
    //   finally {
    //     setLoading(false);
    //   }


  };

  // const red = window.location.href = 'https://djangoobesitychat.azurewebsites.net/password_reset/';

  return (
    <>
   
        <div>
        {loading &&
          <div className="purchase-order-details-pop-up-main-div">
                <div className="purchase-order-details-pop-up-sub-div">
                  <div className="purchase-order-details-pop-up-sub-div-container" style={{background:"none"}}>
          <Loader/>
          </div>
          </div>
          </div>
        }
            <div className="login-page-width-div">
              <div className="login-page-main-div">
                <div className="login-page-sub-div-one">
                  <div className="login-page-sub-one-sec-one">
                    <h4>obesity tool</h4>
                    <p>by sk pharma</p>
                  </div>
                  <form class="form-container">
                    <div className="login-page-sub-one-sec-two">
                      <div className="input-fiend-one">
                        <input
                          type="text"
                          className="email-input-field-tag"
                          placeholder="Username"
                          value={username}
                          onChange={(e) => setusername(e.target.value)}
                        />
                      </div>
                      <div className="error-message-preview">
                        <p>{response && response.username}</p>
                      </div>

                      <div className="input-fiend-one">
                        <input
                          // type="password"
                          type={passwordShown ? "text" : "password"}
                          className="email-input-field-tag"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setpassword(e.target.value)}
                        />
                      </div>
                      <div className="input-fiend-response"
                      style={{ color: "red", width: "95%" }}>
                        <p>{response}</p>
                      </div>
                      
                      <div className="login-page-sub-one-sec-three">
                        <input
                          type="checkbox"
                          onClick={togglePassword}
                          style={{ cursor: "pointer" }}
                        />
                        <p>Show Password</p>
                      </div>
                      <div className="login-page-sub-one-sec-four">
                        <button
                          onClick={onlogin}
                          className="login-page-login-btn"
                        >
                          Login
                        </button>
                      </div>
                      <div className="login-page-sub-one-sec-four">
                        <Link to="/patientSignup">
                          <button
                            // onClick={navigate("/patientSignup")}
                            className="login-page-login-btn"
                          >
                            Patient Signup
                          </button>
                        </Link>
                      </div>
                      <Link to="/forgot-password">
                      <div className="login-page-sub-one-sec-five">
                          <p>Forgot Password ?</p>
                      </div>
                      </Link>
                    </div>
                  </form>
                </div>
                <div className="login-page-sub-div-two">
                  {/*<img src={HandImg} alt="" className="hand-img-login" />*/}
                  <img src={LoginPageImage} alt="" className="hand-img-login" />
                </div>
              </div>
            </div>
          </div>
        
      
    </>
  );
};

export default Login;
