import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import "./UserData.css";

const UserData = () => {
  const [UserDataGet, setUserDataGet] = useState();
  const [PatientGet, setPatientGet] = useState();
  const [WeightHistoryGet, setWeightHistoryGet] = useState();
  const [MedicalTestsGet, setMedicalTestsGet] = useState();
  const [QuestionnaireAnswersGet, setQuestionnaireAnswersGet] = useState();
  const [PrescriptionsHistoryGet, setPrescriptionsHistoryGet] = useState();
  const [AppointmentwithDoctorGet, setAppointmentwithDoctorGet] = useState();
  const [AppointmentwithDietitianGet, setAppointmentwithDietitianGet] =
    useState();
  const [AppointmentwithTrainerGet, setAppointmentwithTrainerGet] = useState();
  const [DietPrescriptionsHistoryGet, setDietPrescriptionsHistoryGet] =
    useState();
  const [ExercisePrescriptionsHistoryGet, setExercisePrescriptionsHistoryGet] =
    useState();
  const [AllEventHistoryGet, setAllEventHistoryGet] = useState();
  const [ActivityHistoryGet, setActivityHistoryGet] = useState();

  const patientUserName = localStorage.getItem("patientUserName");

  useEffect(() => {
    axios
      .get(`https://djangoobesitychat.azurewebsites.net/patient/alldata/${patientUserName}/`)

      .then((response) => {
        setUserDataGet(response.data["Account Details"]);
        setPatientGet(response.data["Patient"]);
        setWeightHistoryGet(response.data["Weight History"]);
        setMedicalTestsGet(response.data["Medical Tests"]);
        setQuestionnaireAnswersGet(response.data["Questionnaire Answers"]);
        setPrescriptionsHistoryGet(response.data["Prescriptions History"]);
        setAppointmentwithDoctorGet(response.data["Appointment with Doctor"]);
        setAppointmentwithDietitianGet(
          response.data["Appointment with Dietitian"]
        );
        setAppointmentwithTrainerGet(response.data["Appointment with Trainer"]);
        setDietPrescriptionsHistoryGet(
          response.data["Diet Prescriptions History"]
        );
        setExercisePrescriptionsHistoryGet(
          response.data["Exercise Prescriptions History"]
        );
        setAllEventHistoryGet(response.data["All Event History"]);
        setActivityHistoryGet(response.data["Activity History"]);
      })
      .catch((err) => {
        // //console.log(err);
      });
  }, []);

  const AccountDataKeys =
    UserDataGet && UserDataGet
      ? Object.keys(UserDataGet && UserDataGet[0])
      : [];
  const AccountDataValues =
    UserDataGet && UserDataGet
      ? Object.values(UserDataGet && UserDataGet[0])
      : [];
  const PatientDataKeys =
    UserDataGet && UserDataGet ? Object.keys(PatientGet && PatientGet[0]) : [];
  const PatientDataValues =
    UserDataGet && UserDataGet
      ? Object.values(PatientGet && PatientGet[0])
      : [];

  const WeightHistoryKeys =
    UserDataGet && UserDataGet
      ? Object.keys(WeightHistoryGet && WeightHistoryGet[0])
      : [];
  const MedicalTestsKeys =
    UserDataGet && UserDataGet
      ? Object.keys(MedicalTestsGet && MedicalTestsGet[0])
      : [];
  const QuestionnaireAnswersKeys =
    UserDataGet && UserDataGet
      ? Object.keys(QuestionnaireAnswersGet && QuestionnaireAnswersGet[0])
      : [];
  const PrescriptionsHistoryKeys =
    UserDataGet && UserDataGet
      ? Object.keys(PrescriptionsHistoryGet && PrescriptionsHistoryGet[0])
      : [];
  const AppointmentwithDoctorKeys =
    UserDataGet && UserDataGet
      ? Object.keys(AppointmentwithDoctorGet && AppointmentwithDoctorGet[0])
      : [];
  const AppointmentwithDietitianKeys =
    UserDataGet && UserDataGet
      ? Object.keys(
          AppointmentwithDietitianGet && AppointmentwithDietitianGet[0]
        )
      : [];
  const AppointmentwithTrainerKeys =
    UserDataGet && UserDataGet
      ? Object.keys(AppointmentwithTrainerGet && AppointmentwithTrainerGet[0])
      : [];
  const DietPrescriptionsHistoryKeys =
    UserDataGet && UserDataGet
      ? Object.keys(
          DietPrescriptionsHistoryGet && DietPrescriptionsHistoryGet[0]
        )
      : [];
  const ExercisePrescriptionsHistoryKeys =
    UserDataGet && UserDataGet
      ? Object.keys(
          ExercisePrescriptionsHistoryGet && ExercisePrescriptionsHistoryGet[0]
        )
      : [];
  const AllEventHistoryKeys =
    UserDataGet && UserDataGet
      ? Object.keys(AllEventHistoryGet && AllEventHistoryGet[0])
      : [];
  const ActivityHistoryKeys =
    UserDataGet && UserDataGet
      ? Object.keys(ActivityHistoryGet && ActivityHistoryGet[0])
      : [];

  return (
    <div className="user-details-flex-div">
      <div className="user-details-width-div">
        <div className="user-details-main-div">
          <h1>Account Details</h1>
          <div className="table-main-div-in-user-details">
            <table className="table-main-div-in-user-details-table">
              <tr>
                {AccountDataKeys &&
                  AccountDataKeys?.map((index) => (
                    <th className="table-main-div-in-user-details-table-th-td">
                      {index}
                    </th>
                  ))}
              </tr>
              <tr>
                {AccountDataValues &&
                  AccountDataValues?.map((index) => (
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index}
                    </td>
                  ))}
              </tr>
            </table>
          </div>
        </div>

        <div className="user-details-main-div">
          <h1>Patient Details</h1>
          <div className="table-main-div-in-user-details">
            <table className="table-main-div-in-user-details-table">
              <tr>
                {PatientDataKeys &&
                  PatientDataKeys?.map((index) => (
                    <th className="table-main-div-in-user-details-table-th-td">
                      {index}
                    </th>
                  ))}
              </tr>
              <tr>
                {PatientDataValues &&
                  PatientDataValues?.map((index) => (
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index}
                    </td>
                  ))}
              </tr>
            </table>
          </div>
        </div>

        <div className="user-details-main-div">
          <h1>Weight History Details</h1>
          <div className="table-main-div-in-user-details">
            <table className="table-main-div-in-user-details-table">
              <tr>
                {WeightHistoryKeys &&
                  WeightHistoryKeys?.map((index) => (
                    <th className="table-main-div-in-user-details-table-th-td">
                      {index}
                    </th>
                  ))}
              </tr>
              {WeightHistoryGet &&
                WeightHistoryGet?.map((index) => (
                  <tr>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.patient_name_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.current_weight}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.height}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.gender}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.age}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.updated_at}
                    </td>
                  </tr>
                ))}
            </table>
          </div>
        </div>

        <div className="user-details-main-div">
          <h1>Medical Test Details</h1>
          <div className="table-main-div-in-user-details">
            <table className="table-main-div-in-user-details-table">
              <tr>
                {MedicalTestsKeys &&
                  MedicalTestsKeys?.map((index) => (
                    <th className="table-main-div-in-user-details-table-th-td">
                      {index}
                    </th>
                  ))}
              </tr>
              {MedicalTestsGet &&
                MedicalTestsGet?.map((index) => (
                  <tr>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.customeuser_username_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.test_name}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.result}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.units}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.start_range}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.end_range}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.med_docs}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.updated_at}
                    </td>
                  </tr>
                ))}
            </table>
          </div>
        </div>

        <div className="user-details-main-div">
          <h1>Questionnaire Details</h1>
          <div className="table-main-div-in-user-details">
            <table className="table-main-div-in-user-details-table">
              <tr>
                {QuestionnaireAnswersKeys &&
                  QuestionnaireAnswersKeys.map((index) => (
                    <th className="table-main-div-in-user-details-table-th-td">
                      {index}
                    </th>
                  ))}
              </tr>
              {QuestionnaireAnswersGet &&
                QuestionnaireAnswersGet.map((index) => (
                  <tr>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.response_id1}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.medicine_name_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.disease_category_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.question_id_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.patient_name_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.patient_current_medicine_image}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.patient_response1}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.response_details1}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.tablejson}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.common_question_type}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.question_category}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.updated_at}
                    </td>
                  </tr>
                ))}
            </table>
          </div>
        </div>

        <div className="user-details-main-div">
          <h1>Prescriptions Details</h1>
          <div className="table-main-div-in-user-details">
            <table className="table-main-div-in-user-details-table">
              <tr>
                {PrescriptionsHistoryKeys &&
                  PrescriptionsHistoryKeys.map((index) => (
                    <th className="table-main-div-in-user-details-table-th-td">
                      {index}
                    </th>
                  ))}
              </tr>
              {PrescriptionsHistoryGet &&
                PrescriptionsHistoryGet.map((index) => (
                  <tr>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.prescription_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.patient_name_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.disease_name_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.doctor_id_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.medicine_name}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.dosage}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.duration}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.prescription_date}
                    </td>

                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.morning == true ? 1 : 0}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.afternoon == true ? 1 : 0}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.evening == true ? 1 : 0}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.night == true ? 1 : 0}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.comment}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.updated_at}
                    </td>
                  </tr>
                ))}
            </table>
          </div>
        </div>

        <div className="user-details-main-div">
          <h1>Appointment with Doctor Details</h1>
          <div className="table-main-div-in-user-details">
            <table className="table-main-div-in-user-details-table">
              <tr>
                {AppointmentwithDoctorKeys &&
                  AppointmentwithDoctorKeys.map((index) => (
                    <th className="table-main-div-in-user-details-table-th-td">
                      {index}
                    </th>
                  ))}
              </tr>
              {AppointmentwithDoctorGet &&
                AppointmentwithDoctorGet.map((index) => (
                  <tr>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.appoinment_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.clinic_id_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.subclinic_id_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.receptionist_id_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.doctor_name_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.patient_id2_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.service}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.starttime}
                    </td>

                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.endtime}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.duration}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.is_approved}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.updated_at}
                    </td>
                  </tr>
                ))}
            </table>
          </div>
        </div>

        <div className="user-details-main-div">
          <h1>Appointment with Dietitian Details</h1>
          <div className="table-main-div-in-user-details">
            <table className="table-main-div-in-user-details-table">
              <tr>
                {AppointmentwithDietitianKeys &&
                  AppointmentwithDietitianKeys.map((index) => (
                    <th className="table-main-div-in-user-details-table-th-td">
                      {index}
                    </th>
                  ))}
              </tr>
              {AppointmentwithDietitianGet &&
                AppointmentwithDietitianGet.map((index) => (
                  <tr>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.appoinment_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.clinic_id_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.subclinic_id_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.receptionist_id_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.dietitian_name_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.patient_id_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.starttime}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.duration}
                    </td>

                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.is_approved}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.updated_at}
                    </td>
                  </tr>
                ))}
            </table>
          </div>
        </div>

        <div className="user-details-main-div">
          <h1>Appointment with Trainer Details</h1>
          <div className="table-main-div-in-user-details">
            <table className="table-main-div-in-user-details-table">
              <tr>
                {AppointmentwithTrainerKeys &&
                  AppointmentwithTrainerKeys.map((index) => (
                    <th className="table-main-div-in-user-details-table-th-td">
                      {index}
                    </th>
                  ))}
              </tr>
              {AppointmentwithTrainerGet &&
                AppointmentwithTrainerGet.map((index) => (
                  <tr>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.appoinment_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.clinic_id_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.subclinic_id_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.receptionist_id_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.trainer_name_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.patient_id_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.starttime}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.duration}
                    </td>

                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.is_approved}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.updated_at}
                    </td>
                  </tr>
                ))}
            </table>
          </div>
        </div>

        <div className="user-details-main-div">
          <h1>Diet Prescription Details</h1>
          <div className="table-main-div-in-user-details">
            <table className="table-main-div-in-user-details-table">
              <tr>
                {DietPrescriptionsHistoryKeys &&
                  DietPrescriptionsHistoryKeys.map((index) => (
                    <th className="table-main-div-in-user-details-table-th-td">
                      {index}
                    </th>
                  ))}
              </tr>
              {DietPrescriptionsHistoryGet &&
                DietPrescriptionsHistoryGet.map((index) => (
                  <tr>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.dietprescription_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.dietitian_name_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.patient_name_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.diet_date}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.meal_time}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.updated_at}
                    </td>
                  </tr>
                ))}
            </table>
          </div>
        </div>

        <div className="user-details-main-div">
          <h1>Exercise Prescription Details</h1>
          <div className="table-main-div-in-user-details">
            <table className="table-main-div-in-user-details-table">
              <tr>
                {ExercisePrescriptionsHistoryKeys &&
                  ExercisePrescriptionsHistoryKeys.map((index) => (
                    <th className="table-main-div-in-user-details-table-th-td">
                      {index}
                    </th>
                  ))}
              </tr>
              {ExercisePrescriptionsHistoryGet &&
                ExercisePrescriptionsHistoryGet.map((index) => (
                  <tr>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.exerciseprescription_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.trainer_name_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.patient_name_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.exercise_date}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.exercise_time}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.updated_at}
                    </td>
                  </tr>
                ))}
            </table>
          </div>
        </div>

        <div className="user-details-main-div">
          <h1>Event Details</h1>
          <div className="table-main-div-in-user-details">
            <table className="table-main-div-in-user-details-table">
              <tr>
                {AllEventHistoryKeys &&
                  AllEventHistoryKeys.map((index) => (
                    <th className="table-main-div-in-user-details-table-th-td">
                      {index}
                    </th>
                  ))}
              </tr>
              {AllEventHistoryGet &&
                AllEventHistoryGet.map((index) => (
                  <tr>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.patientevent_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.date}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.events_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.patient_name_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.doctor_id_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.Event_details}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.next_steps}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.updated_at}
                    </td>
                  </tr>
                ))}
            </table>
          </div>
        </div>

        <div className="user-details-main-div">
          <h1>Activity History Details</h1>
          <div className="table-main-div-in-user-details">
            <table className="table-main-div-in-user-details-table">
              <tr>
                {ActivityHistoryKeys &&
                  ActivityHistoryKeys.map((index) => (
                    <th className="table-main-div-in-user-details-table-th-td">
                      {index}
                    </th>
                  ))}
              </tr>
              {ActivityHistoryGet &&
                ActivityHistoryGet.map((index) => (
                  <tr>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.user_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.activity_type}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.model_id}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td"></td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.read == true ? <p>Yes</p> : <p>No</p>}
                    </td>
                    <td className="table-main-div-in-user-details-table-th-td">
                      {index.updated_at}
                    </td>
                  </tr>
                ))}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserData;
