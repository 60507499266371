import * as React from "react";
import { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Routes, Route } from "react-router-dom";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import DashboardSharpIcon from "@mui/icons-material/DashboardSharp";
// import Navbar from "../navbar/Navbar";
import Navbar from "../Navbar/Navbar";
import { GiDirectionSign } from "react-icons/gi";
import { BsFillBagCheckFill, BsBell } from "react-icons/bs";
import { FaAngleDown } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import PatientQuestionnaire from "../PatientQuestionnaire/PatientQuestionnaire";
import PatientDashboard from "../PatientDashboard/PatientDashboard";
import ProductDetails from "../ProductDetails/ProductDetails";
import PatientAccountSetting from "../PatientAccountSetting/PatientAccountSetting";
import Icon from "../../Images/Icon";
import Chat from "../Chat/ChatTest";
import RandomQuiz from "../Quiz/RandomQuiz";
import PatientPrescription from "../PatientPrescription/PatientPrescription";
// import ReactWhatsapp from "react-whatsapp";
import { BsWhatsapp } from "react-icons/bs";
import DoctorBlog from "../DoctorBlog/DoctorBlog";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import LocalDiningIcon from "@mui/icons-material/LocalDining";
import PatientBlogMainPage from "../PatientBlogMainPage/PatientBlogMainPage";
// import WebCam from "../WebCam/WebCam";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { Provider } from "react-redux";
import store from "../../store";
import ThankYou from "../PatientQuestionnaire/Thankyou";
import AccountSetting from "../AccountSetting/AccountSetting";
import BlogHomePage from "../BlogMainPage/BlogHomePage";
import VideoNew from "../VideoComponent/Prebuilt";
import Notification from "../Notification/Notification";
import PrivacyPolicy from "../TermsandConditions/PrivacyPolicy";
import TermsandConditions from "../TermsandConditions/TermsandConditions";
import AccessibilityPolicy from "../TermsandConditions/AccessibilityPolicy";
import DoctorPatientPrescription from "../DoctorPatientPrescription/DoctorPatientPrescription";
// import ChatComponent from "../Chat/ChatNew";
import PatientDietAndExerciseView from "../PatientDietAndExerciseView/PatientDietAndExerciseView";
import QuestionnairePutRequest from "../QuestionnairePutRequest/QuestionnairePutRequest";
import UserData from "../UserData/UserData";
import PrescriptionView from "../PrescriptionNew/PrescriptionView";
// import DirectChatPage from "../Chat/ChatEngine";
import NotificationComponent from "../Notification/NotificationNew";
import NewNavbar from "../NewNavbar/NewNavbar";
import AIDiet from "../AIDiet/AIDiet";
import AIDietNew from "../AIDiet/AIDietNew";
import AIHomePage from "../AIDiet/AIHomePage";
import { useGet } from "../../Services/ApiServiceReactQuery";
import { useParams } from "react-router-dom";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  overflow: "hidden",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  overflow: "hidden",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(3.5)} + 2px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(5)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  height: 1,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: '#ffffff', // Set the background color to match the image.
  color: '#333333', // Set the text color to match the image.
  borderRadius: "5px",
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  borderRadius: "5px",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
    borderRadius: "5px", // Add border radius
    boxShadow: "0px 3px 6px #00000029", // Add box shadow
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function PatientSidebar() {
  const currentPath = window.location.pathname;
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openPlan, setOpenPlan] = React.useState(true);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [webcamCall, setwebcamCall] = React.useState(false);
  const navigatetwo = useNavigate();

  console.log("currentPath", currentPath);

  const handlewebcamCall = () => {
    // setwebcamCall(true)
    localStorage.setItem("webcamCall", true);
  };

  const [opentwo, setOPentwo] = useState(false);
  const toggletwo = () => {
    setOPentwo(!opentwo);
  };

  const [openthree, setOPenthree] = useState(false);
  const togglethree = () => {
    setOPenthree(!openthree);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickPlan = () => {
    setOpenPlan(!openPlan);
  };
  const handleDrawerOpen = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(!drawerOpen);
  };

  const loginpage = () => {
    navigate("/");
  };

  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();

    navigate("/");
  };
  const [onlineUsers, setOnlineUsers] = useState([]);
  const URL = window.location.href;
  const navigateFunction = () => {
    if (URL !== "http://localhost:3000/patientDashboard" && URL !== "http://localhost:3000/patientDashboard/") {
    navigatetwo(-1);
  }
}
console.log("URL", localStorage.getItem("SavedToken"));

const {data: fetchData } = useGet("/chat/api/online_users/")

console.log("fetchData", fetchData);


  // useEffect(() => {
  //   const fetchOnlineUsers = async () => {
  //     const response = await fetch(
  //       "https://djangoobesitychat.azurewebsites.net/chat/api/online_users/",
  //       {headers: {
  //           Authorization: `Basic ZGVtb19wYXRpZW50OkRlbW9Vc2VyQDEyMw==`,
          
  //       }
  //     }
  //     );
      
  //     const data = await response.json();
  //     setOnlineUsers(data);
  //   };
  //   fetchOnlineUsers();
  // }, []);

  const UserInfo = localStorage.getItem("Username");

  const VideoCallURL = `/dashboard/webcam/${UserInfo}`;




  // CHECK THE USERTPYE IS PATIENT OR NOT

  const [authenticated, setauthenticated] = useState(null);
  useEffect(() => {
    const loggedInUser = localStorage.getItem("is_patient") === "true";

    if (loggedInUser) {
      setauthenticated(loggedInUser);
    }
  }, []);
  if (!authenticated) {
    navigate("/");
  } else
    return (
      <div>
        <Box sx={{ display: "flex", overflow: "hidden" }}>
          <CssBaseline />

          {/* NAVBAR COMPONENT */}
          <NewNavbar open={open} handleDrawerOpen={handleDrawerOpen}/>

          <div></div>

          {/* SIDEBAR CODE */}
          <Drawer
            sx={{ 
              "& .MuiDrawer-paper": { 
                backgroundColor: "#fff",
                borderRadius: drawerOpen ? "10px" : "0", // Apply border radius conditionally
                boxShadow: drawerOpen ? "0px 3px 6px #00000029" : "none", // Apply box shadow conditionally
              } 
            }}
            variant="permanent"
            open={drawerOpen}
          >
            <DrawerHeader>
              <IconButton
                style={{ color: "#84829a", fontSize: 19, borderRadius: "1%" }}
                onClick={handleDrawerClose}
              >
                {theme.direction === "ltr" ? (
                  <GiHamburgerMenu />
                ) : (
                  <GiHamburgerMenu />
                )}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
              <ListItem
                key="Go Back"
                disablePadding
                sx={{ display: "block" }}
                onClick={navigateFunction}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 3,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 1.4,
                      justifyContent: "center",
                    }}
                  >
                    <ArrowBackIcon style={{ color: "#84829a" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Go Back"
                    style={{ color: "#84829a" }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem
                key="Dashboard"
                disablePadding
                sx={{ display: "block" }}
              >
                <Link to="/patientDashboard">
                  {currentPath === "/patientDashboard" || currentPath === "/patientDashboard/" ? (
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 3,
                      backgroundColor: "#0374e5",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 1.4,
                        justifyContent: "center",
                        color: "#fff",
                      }}
                    >
                      <DashboardSharpIcon style={{ color: "#fff" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Dashboard"
                      style={{ color: "#fff", fontWeight: "bolder", fontVariant: "all-small-caps" }}
                    />
                  </ListItemButton>
                ) : (
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 3,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 1.4,
                        justifyContent: "center",
                      }}
                    >
                      <DashboardSharpIcon style={{ color: "#84829a" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Dashboard"
                      style={{ color: "#84829a" }}
                    />
                  </ListItemButton>
                )}
                </Link>
              </ListItem>

              <ListItem
                key="patientList"
                disablePadding
                sx={{ display: "block" }}
              >
                <Link to="/patientDashboard/chat/obesity/">
                  {currentPath === "/patientDashboard/chat/obesity/" ? (
                    <ListItemButton
                    onClick="window.location.href=window.location.href"
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 3,
                      backgroundColor: "#0374e5",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 1.4,
                        justifyContent: "center",
                      }}
                    >
                      <MessageOutlinedIcon style={{ fill: "#fff" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Messenger"
                      style={{ color: "#fff", fontWeight: "bolder", fontVariant: "all-small-caps" }}
                    />
                  </ListItemButton>
                  ) : (
                    <ListItemButton
                    onClick="window.location.href=window.location.href"
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 3,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 1.4,
                        justifyContent: "center",
                      }}
                    >
                      <MessageOutlinedIcon style={{ fill: "#84829a" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Messenger"
                      style={{ color: "#84829a" }}
                    />
                  </ListItemButton>
                  )}
                </Link>
              </ListItem>

              <ListItem
                key="patientList"
                disablePadding
                sx={{ display: "block" }}
              >
                <Link to={VideoCallURL}>
                  {currentPath === VideoCallURL ? (
                    <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 3,
                      backgroundColor: "#0374e5",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 1.4,
                        justifyContent: "center",
                      }}
                    >
                      <CameraAltIcon
                        style={{ fill: "#fff" }}
                        onClick={handlewebcamCall}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Video Call"
                      style={{ color: "#fff" }}
                    />
                  </ListItemButton>
                  ): (
                    <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 3,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 1.4,
                        justifyContent: "center",
                      }}
                    >
                      <CameraAltIcon
                        style={{ fill: "#84829a" }}
                        onClick={handlewebcamCall}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Video Call"
                      style={{ color: "#84829a" }}
                    />
                  </ListItemButton>
                  )
                    
                  }
                  
                </Link>
              </ListItem>

              <ListItem
                key="patientList"
                disablePadding
                sx={{ display: "block" }}
              >
                <Link to="/patientDashboard/blogmainpageforpatient">
                  {currentPath === "/patientDashboard/blogmainpageforpatient" ? (
                    <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 3,
                      backgroundColor: "#0374e5",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 1.4,
                        justifyContent: "center",
                      }}
                    >
                      <TextSnippetIcon style={{ fill: "#fff" }} />
                    </ListItemIcon>
                    <ListItemText primary="Blog" style={{ color: "#fff", fontWeight: "bolder", fontVariant: "all-small-caps" }} />
                  </ListItemButton>
                  ) : (
                    <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 3,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 1.4,
                        justifyContent: "center",
                      }}
                    >
                      <TextSnippetIcon style={{ fill: "#84829a" }} />
                    </ListItemIcon>
                    <ListItemText primary="Blog" style={{ color: "#84829a" }} />
                  </ListItemButton>
                  )}
                  
                </Link>
              </ListItem>

              <ListItem
                key="patientList"
                disablePadding
                sx={{ display: "block" }}
              >
                <Link to="/patientDashboard/aidiet">
                  {currentPath === "/patientDashboard/aidiet" ? (
                    <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 3,
                      backgroundColor: "#0374e5",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 1.4,
                        justifyContent: "center",
                      }}
                    >
                      <LocalDiningIcon style={{ fill: "#fff" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Personal Dietition"
                      style={{ color: "#fff", fontWeight: "bolder", fontVariant: "all-small-caps" }}
                    />
                  </ListItemButton>
                  ) : (
                    <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 3,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 1.4,
                        justifyContent: "center",
                      }}
                    >
                      <LocalDiningIcon style={{ fill: "#84829a" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Personal Dietician"
                      style={{ color: "#84829a" }}
                    />
                  </ListItemButton>
                  )}
                  
                </Link>
              </ListItem>

              {!open ? (
                <div
                  style={{ position: "relative", valign: "bottom", top: "40%" }}
                >
                  <ListItem
                    key="Privacy Policy"
                    disablePadding
                    sx={{ display: "block", valign: "bottom" }}
                  >
                    <Link to="/patientDashboard/privacypolicy">
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 3,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 20,
                            mr: open ? 3 : 1.4,
                            justifyContent: "center",
                          }}
                        ></ListItemIcon>
                        <ListItemText
                          primary="Privacy Policy"
                          style={{ color: "#84829a" }}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>

                  <ListItem
                    key="Terms & Conditions"
                    disablePadding
                    sx={{ display: "block", bottom: "0" }}
                  >
                    <Link to="/patientDashboard/termsandconditions">
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 3,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 20,
                            mr: open ? 3 : 1.4,
                            justifyContent: "center",
                          }}
                        ></ListItemIcon>
                        <ListItemText
                          primary="Terms & Conditions"
                          style={{ color: "#84829a" }}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>

                  <ListItem
                    key="Accessibility Policy"
                    disablePadding
                    sx={{ display: "block", bottom: "0" }}
                  >
                    <Link to="/patientDashboard/accessibilitypolicy">
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 3,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 20,
                            mr: open ? 3 : 1.4,
                            justifyContent: "center",
                          }}
                        ></ListItemIcon>
                        <ListItemText
                          primary="Accessibility Policy"
                          style={{ color: "#84829a" }}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>
                </div>
              ) : (
                <p></p>
              )}
            </List>
          </Drawer>





          {/* PATIENT ROUNTES PATH */}

          <Box component="main" open={open}>
            <DrawerHeader />
            
            <Routes>
              <Route path="/" element={<PatientDashboard />} />
              <Route path="/proddetails" element={<ProductDetails />} />
              <Route path={VideoCallURL} element={<VideoNew />} />

              <Route
                path="patientquestionnaire"
                element={<PatientQuestionnaire />}
              />
              <Route
                path="patientquestionnaireupdate"
                element={<QuestionnairePutRequest />}
              />
              <Route
                path="/chat/obesity/"
                element={<Chat user={UserInfo} onlineUsers={onlineUsers} />}
              />
              <Route path="mq" element={<RandomQuiz />} />
              <Route
                path="patientprescription"
                element={<PrescriptionView />}
              />

              <Route
                path="blogmainpageforpatient"
                element={<PatientBlogMainPage />}
              />
              <Route path="blogbydoctor" element={<DoctorBlog />} />
              <Route path="thankyoumsg" element={<ThankYou />} />
              <Route path="accsetting" element={<AccountSetting />} />
              <Route path="bloghomepage" element={<BlogHomePage />} />
              <Route path="aidiet" element={<AIHomePage />} />
              <Route
                path="patientdietandexerciseview"
                element={<PatientDietAndExerciseView />}
              />
              <Route path="userdata" element={<UserData />} />
              <Route
                path="/termsandconditions"
                element={<TermsandConditions />}
              />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route
                path="/accessibilitypolicy"
                element={<AccessibilityPolicy />}
              />
            </Routes>
          </Box>
        </Box>
      </div>
    );
}
