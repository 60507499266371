import React, { useMemo, useRef, useCallback } from "react";
import "./PatientSummary.css";
import { GiMedicines, GiLoveInjection } from "react-icons/gi";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { AgGridReact } from "ag-grid-react";
import WeightForcasting from "../WeightForcasting/WeightForcasting";
import HungryBrainDoughnut from "../HungryBrainDoughnut/HungryBrainDoughnut";
import EmotionalHungerDoughnut from "../EmotionalHungerDoughnut/EmotionalHungerDoughnut";
import QuestionnaireSummary from "./QuestionnaireSummary";
import NotesIcon from '@mui/icons-material/Notes';
import { Link } from "react-router-dom";
import QuestionnaireNew from "../Questionnaire/QuestionnaireNew";
import Apiservice from "../../Services/Apiservice";
import moment from "moment";
import { AiOutlineClose } from "react-icons/ai";

const PatientSummary = () => {
  const PatientUsername = localStorage.getItem("patientUserName");
  const savedtoken = localStorage.getItem("SavedToken");
  const [patientdetails, setpatientdetails] = useState([]);
  const [patientuserdetails, setpatientuserdetails] = useState([]);
  const [patientBmiHistory, setPatientBmiHistory] = useState();

  // // //console.log("Patient Details", patientdetails);
  // // //console.log("Patient User Details", patientuserdetails);
  useEffect(() => {
    Apiservice
      .get(
        `/user/patientapi/${PatientUsername}/`,
        {
          headers: {
            Authorization: `Token ${savedtoken}`,
          },
        }
      )
      .then((response) => {
        setpatientdetails(response.data[0]);
      })
      .catch((err) => {});
  }, [PatientUsername]);

  useEffect(() => {
    Apiservice
      .get(`/medicaltestapi/${PatientUsername}/`)

      .then((response) => {
        setpatientuserdetails(response.data);
      })
      .catch((err) => {});
  }, []);

  const [contracount, setcontracount] = useState();

  

  useEffect(() => {
    Apiservice
      .get(`/drugwarningcount/${PatientUsername}/`)
      .then((response) => {
        setcontracount(response.data);
        // // //console.log("setcontracount", response.data);
      })
      .catch((err) => {
        // // //console.log(err);
      });
  }, []);

  function ImageCellRenderer(params) {
    const imageUrl = params.value;
    return <img style={{ height: "3em" }} src={imageUrl} alt="Cell" />;
  }
  const gridRef = useRef();
  const [columnDefs, setColumnDefs] = useState([
    { field: "date", filter: true, headerName: "Date" },
    { field: "events", filter: true, headerName: "Event" },
    { field: "Event_details", filter: true, headerName: "Event Details" },
    { field: "next_steps", headerName: "Next Steps" },
    { field: "doctor_name", headerName: "Doctor Name" },
  ]);
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    resizable: true,
    suppressToolPanel: true,
  }));
  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(
      document.getElementById("filter-text-box").value
    );
  }, []);
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    fetch(`https://djangoobesitychat.azurewebsites.net/event/user/${PatientUsername}/`, {
      headers: {
        Authorization: `Token ${localStorage.getItem("SavedToken")}`,
      },
    })
      .then((data) => data.json())
      .then((data) => setTableData(data));
    // setLoading(false);
  }, []);

  // // // //console.log("Prescription Table", tableData);

  const [DrugInteractionPopup, setDrugInteractionPopup] = useState(false);
  const onClickOpenDrugInteractionPopup = () => {
    setDrugInteractionPopup(!DrugInteractionPopup);
  };
  const [contraPopup, setContraPopup] = useState(false);
  const onClickOpen = () => {
    setContraPopup(!contraPopup);
  };

  //BMI API for Patient Personal
  const patientUsername = localStorage.getItem("patientUserName");
  useEffect(() => {
    Apiservice
      .get(`/user/allwighthistory/${patientUsername}/`)
      .then((response) => {
        // // //console.log("BMI Data in Personal PatientFile", response.data);
        setPatientBmiHistory(response.data);
      })
      .catch((err) => {
        // // //console.log(err);
      });
  }, []);



  const dateParsed =
    patientBmiHistory &&
    patientBmiHistory.map((index) =>
      moment(index.updatedate, "MMM DD YYYY HH:mm A").valueOf()
    );

  const bmiParsed =
    patientBmiHistory &&
    patientBmiHistory.map((index) => parseFloat(index.bmi));

  /* Creating a variable called currentWeek and setting it equal to the current week of the year. */
  const currentWeek = moment().week();

  const currentMonth = moment().month();

  /* Filtering the patientBmiHistory array to only include the weeks that are less than or equal to the
  current week. */
  const filtered =
    patientBmiHistory &&
    patientBmiHistory.filter((index) => index.week <= currentWeek);

  const filteredbyMonth =
    patientBmiHistory &&
    patientBmiHistory.filter((index) => index.month <= currentMonth);

  const dataByWeek =
    filtered &&
    filtered.reduce((acc, item) => {
      acc[item.week] = item;
      return acc;
    }, {});

  const dataByMonth =
    filteredbyMonth &&
    filteredbyMonth.reduce((acc, item) => {
      acc[item.month] = item;
      return acc;
    }, {});

    //CARD for patient BMI
  const Card = ({ data }) => {
    const {
      id,
      user,
      activity_type,
      BMI,
      changes,
      date,
      week,
      month,
      updated_at,
    } = data;
    // const BMI = changes && changes.BMI;
    const BMII = BMI;
    const sweek = moment(patientBmiHistory[0].date).month();
    

    return (
      <div className="week-section-main-div">
        <div className="week-section-sub-div-one">
          <p className="week-para" style={{ textAlign: "center" }}>
            Month {month - (sweek - 1)}
          </p>
          <div className="bmi-div-btn">
            <p>{Math.round(BMII)} BMI</p>
          </div>
        </div>
      </div>
    );
  };

  const CardbyWeek = ({ data }) => {
    const {
      id,
      user,
      activity_type,
      BMI,
      changes,
      date,
      week,
      month,
      updated_at,
    } = data;
    // const BMI = changes && changes.BMI;
    const BMII = BMI;
    const sweek = moment(patientBmiHistory[0].date).week();

    // // //console.log("data is::",data)
    // // //console.log("BMI in Card is", BMI)

    return (
      <div className="week-section-main-div">
        <div className="week-section-sub-div-one">
          <p className="week-para" style={{ textAlign: "center" }}>
            Week {week - (sweek - 1)}
          </p>
          <div className="bmi-div-btn">
            <p>{Math.round(BMII)} BMI</p>
          </div>
        </div>
      </div>
    );
  };

  const [ShowBMIbyWeek, setShowBMIbyWeek] = useState(false);
  const toggleShowBMIbyWeek = () => {
    setShowBMIbyWeek(!ShowBMIbyWeek);
  };

  // Contradictions Popup
  // const [contraPopup, setContraPopup] = useState(false);
  // const onClickOpen = () => {
  //   setContraPopup(!contraPopup);
  // };

  const closeContraPopup = () => {
    setContraPopup(false);
  };

  //console.log("Patient BMI History", patientBmiHistory);

  const lastobject = patientBmiHistory&&patientBmiHistory[patientBmiHistory?.length - 1];
  const lastWeight = lastobject && lastobject.current_weight;
  // const lastObject = patientBmiHistory[patientBmiHistory?.length - 1];
  // const lastWeight = lastObject.current_weight;
  
  // //console.log(lastWeight);

  
  return (
    <div>
    <div className="patient-summary-flex-div">
      <div className="patient-summary-width-div">
        <div className="patient-summary-main-div-one">
          <div
            style={{ width: "40%" }}
            className="pateient-main-div-in-patient-new"
          >
            <div>
              <h3 style={{ textAlign: "center" }}>Safety</h3>
            </div>

            <div className="pat-summary-flex-div-two">
              <div className="blood-sugar-card-in-pheno" onClick={onClickOpen} style={{cursor:"pointer"}}>
                <div className="card-first-sec-div">
                  <div className="card-logo-in-contra-diction">
                    <GiLoveInjection
                      style={{
                        height: "25px",
                        width: "25px",
                        color: "white",
                      }}
                    />
                  </div>
                  <div className="card-detail-div-main-for-flex">
                    <div className="card-detail-div-main">
                      <h5>Contraindications</h5>
                    </div>
                  </div>
                </div>
                <div className="contra-dic-text">
                  <h2 style={{ paddingTop: "15px" }}>{contracount && contracount.contraindications_count}</h2>
                </div>
              </div>
              {contraPopup ? (
                <div className="purchase-order-details-pop-up-main-div">
                  <div className="purchase-order-details-pop-up-sub-div">
                    <div className="purchase-order-details-pop-up-sub-div-container">
                      <div className="purchase-order-details-pop-up-sub-div-container-one">
                        <div className="doctor-patient-prescription-sub-div-for-popup-close-div">
                          <AiOutlineClose
                            className="close-btn-in-pop-up-of-presc"
                            onClick={onClickOpen}
                          />
                        </div>
                        <div
                        className="appointment-list-sub-div-one"
                        style={{ marginBottom: "20px" }}
                      >
                        <h3>Contradictions</h3>
                      </div>
                        {contracount &&
                          contracount.contraindications_match_list.map(
                            (index) => <li>{index}</li>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              

              <div
                className="blood-sugar-card-in-pheno-two"
                style={{ marginTop: "0px", cursor:"pointer" }}
                onClick={onClickOpenDrugInteractionPopup}
              >
                <div className="card-first-sec-div">
                  <div className="card-logo-in-medical-history-drugs">
                    <GiMedicines
                      style={{
                        height: "25px",
                        width: "25px",
                        color: "white",
                      }}
                    />
                  </div>
                  <div className="card-detail-div-main-for-flex">
                    <div className="card-detail-div-main">
                      <h5>Drug drug interactions</h5>
                    </div>
                  </div>
                </div>
                <div className="contra-dic-text">
                  <h2 style={{ paddingTop: "15px" }}>{contracount && contracount.drug_interaction_count}</h2>
                </div>
              </div>
              {DrugInteractionPopup ? (
                <div className="contra-pop-up-main-div-for-drug-interaction" style={{marginTop:"295px", marginLeft:"200px"}}>
                  <div className="contra-pop-up">
                    <div className="contradiction-popup">
                    {contracount&&contracount.drug_interaction_match_list.map((index)=>(
                      <p>{index}</p>
                    ))}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div
            style={{ width: "40%" }}
            className="pateient-main-div-in-patient-new"
          >
            <div>
              <h3 style={{ textAlign: "center" }}>Patient Other Details</h3>
            </div>

            <div className="additional-data-div-in-pat-summ">
              <div className="bmi-div-btn-new">
                <h4>Height</h4>
              </div>
              <div className="bmi-div-btn-two">
                <p>{Math.round(patientdetails&&patientdetails.height)} cms</p>
              </div>
            </div>
            <div className="additional-data-div-in-pat-summ">
              <div className="bmi-div-btn-new">
                <h4>Blood Sugar</h4>
              </div>
              <div className="bmi-div-btn-two">
                <p>{Math.round(patientdetails&&patientdetails.blood_sugar)} mg/dl</p>
              </div>
            </div>
            <div className="additional-data-div-in-pat-summ">
              <div className="bmi-div-btn-new">
                <h4>Blood Pressure</h4>
              </div>
              <div className="bmi-div-btn-two">
                <p>{Math.round(patientdetails&&patientdetails.blood_pressure)} mmHg</p>
              </div>
            </div>
            <div className="additional-data-div-in-pat-summ">
              <div className="bmi-div-btn-new">
                <h4>Heart Rate</h4>
              </div>
              <div className="bmi-div-btn-two">
                <p>{Math.round(patientdetails&&patientdetails.heart_rate)} bpm</p>
              </div>
            </div>
          </div>
          <div
            className="pateient-main-div-in-patient"
            style={{ width: "40%", backgroundColor: "rgb(236,249,255)" }}
          >
            <div className="patient-img-name-div-in-pateinet-dashboard">
              <div>
                <h3 style={{ textAlign: "center" }}>Patient Details</h3>
                <div>
                  <div style={{ textAlign: "center" }}>
                    <img
                      src="https://img.freepik.com/premium-vector/user-icon_126283-435.jpg?w=2000"
                      className="patient-img-logo"
                    />
                  </div>
                  <div className="patient-info-main-div-in-pateint-dashboard">
                    <p>Patient ID : {patientdetails&&patientdetails.id}</p>
                    <h4>
                      {patientdetails&&patientdetails.patient_firstName}{" "}
                      {patientdetails&&patientdetails.patient_lastName}
                    </h4>
                  </div>
                  <div
                    className="patient-info-in-user-login-main-div"
                    style={{ paddingLeft: "" }}
                  >
                    <div className="patient-user-login-main-info">
                      <div className="patient-user-login-main-info-one">
                        <h4 className="pat-inho-h-tag">Gender </h4>
                      </div>
                      <div className="patient-user-login-main-info-two">
                        <p className="pat-inho-p-tag">
                          {patientdetails&&patientdetails.gender}
                        </p>
                      </div>
                    </div>

                    <div className="patient-user-login-main-info">
                      <div className="patient-user-login-main-info-one">
                        <h4 className="pat-inho-h-tag">Date of Birth </h4>
                      </div>
                      <div className="patient-user-login-main-info-two">
                        <p className="pat-inho-p-tag">
                          {patientdetails&&patientdetails.patient_date_of_birth}
                        </p>
                      </div>
                    </div>

                    <div className="patient-user-login-main-info">
                      <div className="patient-user-login-main-info-one">
                        <h4 className="pat-inho-h-tag">Age </h4>
                      </div>
                      <div className="patient-user-login-main-info-two">
                        <p className="pat-inho-p-tag">
                          {patientdetails&&patientdetails.age}
                        </p>
                      </div>
                    </div>

                    <div className="patient-user-login-main-info">
                      <div className="patient-user-login-main-info-one">
                        <h4 className="pat-inho-h-tag">Address</h4>
                      </div>
                      <div className="patient-user-login-main-info-two">
                        <p className="pat-inho-p-tag">
                          {patientdetails&&patientdetails.patient_address}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="patient-summary-main-div-two"></div>

        <div className="patient-summary-main-div-three">
          <div className="patient-summary-div-three-sub-one" style={{ width:"40%" }}>
            <div className="medical-history-in-pat-summary">
              <div>
                <h3 style={{ textAlign: "center" }}>Medical History</h3>
              </div>
              <div>
                <div className="test-doc-details-table">
                  <div className="test-table-doc-sub-div">
                    <h4>Test Name</h4>
                  </div>
                  <div className="test-table-doc-sub-div">
                    <h4>Documents</h4>
                  </div>
                </div>
                {patientuserdetails.map((index) => (
                  <div className="test-doc-details-table">
                    <div className="test-table-doc-sub-div">
                      {index.test_name}
                    </div>
                    <div className="test-table-doc-sub-div">
                      <a href={index.med_docs}>
                        <button className="update-btn-btn-tag">Download</button>
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            
            <div className="medical-history-in-pat-summary-two">
              <div>
                <h3 style={{ textAlign: "center" }}>Last Events</h3>
                <Link to="/dashboard/callnotes">
                <div style={{display:"flex", gap:"10px", alignItems:"center", justifyContent:"center", cursor:"pointer"}}>
                <p>Call Notes</p>
                <NotesIcon/>
                </div>
                </Link>
              </div>
              <div
                className="ag-theme-material"
                style={{
                  width: "100%",
                  height: "50vh",
                  boxShadow: "rgb(0 0 0 / 35%) 0px 5px 15px",
                  marginTop: "20px",
                }}
              >
                <AgGridReact
                  ref={gridRef}
                  rowData={tableData}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  animateRows={true}
                  rowSelection="multiple"
                />
              </div>
            </div>
          </div>
          <div
            style={{ width: "40%" }}
            className="pateient-main-div-in-patient-new"
          >
            <div>
              <h3 style={{ textAlign: "center" }}>Patient's Measurement Summary</h3>
            </div>

            <div className="additional-data-div-in-pat-summ">
              <div className="bmi-div-btn-new">
                <h4>Waist Circumference</h4>
              </div>
              <div className="bmi-div-btn-two">
                <p>{Math.round(patientdetails&&patientdetails.waist_circumference_cm)} cms</p>
              </div>
            </div>
            <div className="additional-data-div-in-pat-summ">
              <div className="bmi-div-btn-new">
                <h4>Hip Circumference</h4>
              </div>
              <div className="bmi-div-btn-two">
                <p>{Math.round(patientdetails&&patientdetails.hip_circumference_cm)} cms</p>
              </div>
            </div>
            <div className="additional-data-div-in-pat-summ">
              <div className="bmi-div-btn-new">
                <h4>Bust Circumference</h4>
              </div>
              <div className="bmi-div-btn-two">
                <p>{Math.round(patientdetails&&patientdetails.bust_circumference_cm)} cms</p>
              </div>
            </div>
            <div className="additional-data-div-in-pat-summ">
              <div className="bmi-div-btn-new">
                <h4>Total Calories Consumed</h4>
              </div>
              <div className="bmi-div-btn-two">
                <p>{Math.round(patientdetails&&patientdetails.total_calories)} cals</p>
              </div>
            </div>
            <div className="additional-data-div-in-pat-summ">
              <div className="bmi-div-btn-new">
                <h4>Total Calories Burnt</h4>
              </div>
              <div className="bmi-div-btn-two">
                <p>{Math.round(patientdetails&&patientdetails.total_calories_burn)} cals</p>
              </div>
            </div>
            <div className="additional-data-div-in-pat-summ">
              <div className="bmi-div-btn-new">
                <h4>Height</h4>
              </div>
              <div className="bmi-div-btn-two">
                <p>{Math.round(patientdetails&&patientdetails.height)} cms</p>
              </div>
            </div>
            <div className="additional-data-div-in-pat-summ">
              <div className="bmi-div-btn-new">
                <h4>Last Meal</h4>
              </div>
              <div className="bmi-div-btn-two">
                <p>{(patientdetails&&patientdetails.last_meal)} </p>
              </div>
            </div>
            <div className="additional-data-div-in-pat-summ">
              <div className="bmi-div-btn-new">
                <h4>Last Exercise</h4>
              </div>
              <div className="bmi-div-btn-two">
                <p>{(patientdetails&&patientdetails.last_exercise)}</p>
              </div>
            </div>
            <div className="additional-data-div-in-pat-summ">
              <div className="bmi-div-btn-new">
                <h4>Beginning Weight</h4>
              </div>
              <div className="bmi-div-btn-two">
                <p>{Math.round(patientdetails&&patientdetails.beginning_weight)} kgs</p>
              </div>
            </div>
            <div className="additional-data-div-in-pat-summ">
              <div className="bmi-div-btn-new">
                <h4>Target Weight</h4>
              </div>
              <div className="bmi-div-btn-two">
                <p>{Math.round(patientdetails&&patientdetails.target_weight)} kgs</p>
              </div>
            </div>
          </div>
          <div className="patient-summary-div-three-sub-two">
            
            <div>
              <h3 style={{ textAlign: "center" }}>Additional Data</h3>
            </div>
            <div className="additional-data-div-in-pat-summ" style={{justifyContent:"center"}}>
              <div className="female-sou-ghnut-main-div">
                <HungryBrainDoughnut />
              </div>
              {/*<div className="male-sou-ghnut-main-div">
                <EmotionalHungerDoughnut />
              </div>*/}
            </div>
            <div className="additional-data-div-in-pat-summ">
              <div className="bmi-div-btn">
                <h4>Beginning BMR</h4>
              </div>
              <div className="bmi-div-btn">
                <p>123 kJ</p>
              </div>
            </div>
            <div className="additional-data-div-in-pat-summ">
              <div className="bmi-div-btn">
                <h4>Current BMR</h4>
              </div>
              <div className="bmi-div-btn">
                <p>{Math.round(patientdetails&&patientdetails.BMR)} kJ</p>
              </div>
            </div>
            {/* <div className="week-section-main-div">
              <div className="week-section-sub-div-one">
                <p className="week-para">Week 1</p>
                <div className="bmi-div-btn">
                  <p>88.1 BMI</p>
                </div>
              </div>
              <div className="week-section-sub-div-one">
                <p className="week-para">Week 2</p>
                <div className="bmi-div-btn">
                  <p>88.1 BMI</p>
                </div>
              </div>
              <div className="week-section-sub-div-one">
                <p className="week-para">Week 3</p>
                <div className="bmi-div-btn">
                  <p>88.1 BMI</p>
                </div>
              </div>
              <div className="week-section-sub-div-one">
                <p className="week-para">Week 4</p>
                <div className="bmi-div-btn">
                  <p>{Math.round(patientdetails&&patientdetails.BMI)}</p>
                </div>
              </div>
            </div> */}
            <div
                className="personal-patient-file-bmi-flex"
                style={{ display: "flex", justifyContent: "left", gap: "30px" }}
              >
                {dataByMonth &&
                  Object.values(dataByMonth).map((index) => (
                    <Card key={index.id} data={index} />
                  ))}
              </div>

              <div style={{ display: "flex", justifyContent: "center" }}>
                {ShowBMIbyWeek == true ? (
                  <button
                    onClick={toggleShowBMIbyWeek}
                    className="male-female-btn-div-active"
                  >
                    Hide BMI By Week
                  </button>
                ) : (
                  <button
                    onClick={toggleShowBMIbyWeek}
                    className="male-female-btn-div-active"
                  >
                    Show BMI By Week
                  </button>
                )}
              </div>

              {ShowBMIbyWeek && (
                <div className="bmi-cards-main-div-in-patient-dashboard">
                  {dataByMonth &&
                    Object.values(dataByWeek).map((index) => (
                      <CardbyWeek key={index.id} data={index} />
                    ))}
                </div>
              )}
            <div className="chart-section-main-div">
              <p>Weight Forcasting</p>
              <div className="weight-forcast-graph-div">
                <WeightForcasting />
              </div>
            </div>
            <div className="additional-data-div-in-pat-summ" style={{paddingTop:"10px"}}>
              <div className="bmi-div-btn">
                <h3 style={{fontSize:"14px"}}>Current Weight of Patient</h3>
              </div>
              <div className="bmi-div-btn">
                <p>{lastWeight} kgs</p>
              </div>
            </div>
            
          </div>
        </div>
      </div>
     
    </div>
    <div style={{padding:"10px"}}>
    <QuestionnaireNew/>
    {/*
    <QuestionnaireSummary/>
  */}
            </div>
    </div>
  );
};

export default PatientSummary;
