import React from 'react'
// import "./AvgPatientGraph.css"

import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    // CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from "recharts";
import { useEffect, useState } from 'react';
import Apiservice from '../../Services/Apiservice';
import axios from 'axios';


const data = [
    {
        name: "Jan",
        PatientVisit: 125,
        year: 2022
    },
    {
        name: "Feb",
        PatientVisit: 155,
        year: 2022
    },
    {
        name: "Mar",
        PatientVisit: 190,
        year: 2022
    },
    {
        name: "Apr",
        PatientVisit: 80,
        year: 2022
    },
    {
        name: "May",
        PatientVisit: 125,
        year: 2022
    },
    {
        name: "Jun",
        PatientVisit: 180,
        year: 2022
    },
    {
        name: "Jul",
        PatientVisit: 130,
        year: 2022
    },
    {
        name: "Aug",
        PatientVisit: 140,
        year: 2022
    },
    {
        name: "Sept",
        PatientVisit: 100,
        year: 2022
    },
    {
        name: "Oct",
        PatientVisit: 200,
        fill:"rgb(33,186,255)",
        year: 2022
    },
    // {
    //     name: "Nov",
    //     // pv: 190,
    // },
    // {
    //     name: "Dec",
    //     // pv: 160,
    // }
];

const AvgPatientGraph = (clinicID ) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        Apiservice.get('/patient/drugcount/clinic/' + clinicID.clinicID + '/')
            .then(response => {
                if (!response.error) {
                    //console.log(response.data);
                    setData(response.data['Monthwise_total_number_of_appointments_count']);
                } else {
                    console.error(response.error);
                }
            })
            .catch(error => {
                //console.log(error);
            })
    }, [clinicID.clinicID]);


    //console.log(data);



    return (
        <ResponsiveContainer   height={250} margin-left={-27}>
            <BarChart
                // width={590}
                // height={300}
                data={data}
                margin={{
                    right: 30,
                    left: 20,
                    bottom: 5
                }}
            >
                <XAxis dataKey="name" />
                <YAxis />
                <Bar dataKey="PatientVisit" fill="rgb(133,217,255)" radius={[10, 10, 0, 0]}  />
                <Tooltip />
                {/* <Legend verticalAlign="top" height={50} /> */}
            </BarChart >
        </ResponsiveContainer>
    )
}

export default AvgPatientGraph