import axios from "axios";
import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import Loader from "../Loader/Loader";
import swal from "sweetalert";
import EastSharpIcon from "@mui/icons-material/EastSharp";
import { Link } from "react-router-dom";
import Apiservice from "../../Services/Apiservice";
const Que = forwardRef((props, ref) => {
  const [detail, setdetail] = useState("");
  const UsernameofPatient = localStorage.getItem("Username");
  const [response, setresponse] = useState("");
  const [message, setMessage] = useState("");
  const [rows, setRows] = useState([
    { medication: "", dosage: "", number: "" },
  ]);
  const [selectedOption, setSelectedOption] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Convert the data from the table into the format the API expects
    const tableData = {};
    rows.forEach((row) => {
      tableData[row.medication] = {
        "Medication Name": row.medication,
        Dosage: row.dosage,
        Number: row.number,
      };
    });
    const data = Object.fromEntries(Object.entries(tableData));

    // send data to API
    fetch(
      `https://djangoobesitychat.azurewebsites.net/patient/response/Other/${UsernameofPatient}/`,
      {
        method: "POST",
        body: JSON.stringify([
          {
            question1: props.item.question1,
            patient_name: UsernameofPatient,
            tablejson: data,
          },
        ]),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  const handleAddRow = () => {
    setRows([...rows, { medication: "", dosage: "", number: "" }]);
  };

  const handleRemoveRow = () => {
    const newRows = [...rows];
    newRows.splice(-1, 1);
    setRows(newRows);
  };

  useEffect(() => {
    const headers = {
      "Authorization": `Token ${localStorage.getItem("SavedToken")}`
    };

    // Fetch medicines on component mount
    fetch(`https://djangoobesitychat.azurewebsites.net/medicineapi/`, { headers: headers })
      .then((response) => response.json())
      .then((data) => setOptions(data));

  }, []);

  useEffect(() => {
    if (Array.isArray(options)) {
      const fieldsToSearch = ['medicine_name', 'generic_name', 'indications'];
      const results = options.filter(option =>
        fieldsToSearch.some(field => 
          option[field] && option[field].toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setFilteredOptions(results);
    }
  }, [searchTerm, options]);

  console.log("selectedOption", selectedOption)
  console.log("searchTerm", searchTerm)
  const handleClick = () => {
    const headers = {
      "Authorization": `Token ${localStorage.getItem("SavedToken")}`,
    };
    // // //console.log(DiseaseCategory);
    fetch(
      `https://djangoobesitychat.azurewebsites.net/medicineapi/`
    )
      .then((response) => response.json())
      .then((data) => setOptions(data));
  };
  const handleSuggestionClick = (medicineName) => {
    setSearchTerm(medicineName);
    setSelectedOption(medicineName);
    setShowSuggestions(false); // Hide suggestions after selecting
  };

  const handleChange = (event, index) => {
    const newRows = [...rows];
    newRows[index][event.target.name] = event.target.value;
    setRows(newRows);
  };
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div>
      <div className="questo-form-quesyion-ans-main-div-for-main">
      <div className="questo-form-questions-wrapper" style={{ width: "55%", cursor:"pointer" }}>
      <div className="questo-form-questions-div" >
          <p className="question-text">{props.number}. {props.item.question}</p>
          {props.item.mandatory === "Yes" ? (
              <span style={{ color: "red" }}> *</span>
            ) : null}
        </div>
        <div className="tooltip">More information</div>
        </div>
        {/*<div className="questo-form-options-div" style={{ width: "15%" }}>
          <div style={{ display: "flex", gap: "9px" }}>
            <input
              className="add-patient-input-field-of-radio"
              name={props.item.response_id1}
              type="radio"
              value="Yes"
              onChange={(event) =>
                props.radioButtonHandler({
                  que_id: props.item.response_id1,
                  val: event.target.value,
                })
              }
            />
            <p style={{ fontSize: "13px" }}>Yes</p>
            <input
              className="add-patient-input-field-of-radio"
              name={props.item.response_id1}
              type="radio"
              value="No"
              onChange={(event) =>
                props.radioButtonHandler({
                  que_id: props.item.response_id1,
                  val: event.target.value,
                })
              }
            />
            <p style={{ fontSize: "13px" }}>No</p>
          </div>
        </div>
        <div
          className="questo-form-details-div-in-pat"
          style={{ width: "30%" }}
        >
          <textarea
            className="questo-form-input-tag-for-patient"
            type={"text"}
            placeholder="Details..."
            onChange={(event) =>
              props.detailsHandler({
                que_id: props.item.response_id1,
                val: event.target.value,
              })
            }
          />
          </div>*/}

          {props.item.question_category === "yes_no" ? (
            <div className="response-div">
              <div className="questo-form-options-div" style={{ width: "15%" }}>
              <p classname="label-for-response" style={{color: '#88869d', marginBottom: '20px'}}>Response: </p>
                <div style={{ display: "flex", gap: "9px"}}>
                  <input
                    className="add-patient-input-field-of-radio"
                    // name="response"
                    name={props.item.question_id}
                    type="radio"
                    value="Yes"
                    checked={props.item.patient_response1 === "Yes"}
                    onChange={(event) =>
                      props.radioButtonHandler({
                        que_id: props.item.question_id,
                        val: event.target.value,
                      })
                    }
                  />
                  <p style={{ fontSize: "13px" }}>Yes</p>
                  <input
                    className="add-patient-input-field-of-radio"
                    // name="response"
                    name={props.item.question_id}
                    type="radio"
                    value="No"
                    checked={props.item.patient_response1 === "No"}
                    onChange={(event) =>
                      props.radioButtonHandler({
                        que_id: props.item.question_id,
                        val: event.target.value,
                      })
                    }
                  />
                  <p style={{ fontSize: "13px" }}>No</p>
                </div>
              </div>
              <div
                className="questo-form-details-div-in-pat"
                style={{ width: "30%"}}
              >
                <p classname="questo-new-label-for-pat-que" style={{color: '#88869d'}}>Medicines: </p>
               
                <select
                  className="questo-form-input-tag-for-patient"
                  // value={selectedOption}
                  // onChange={handleSelectChange}
                  onChange={(event) =>
                    props.detailsHandler({
                      que_id: props.item.response_id1,
                      val: event.target.value,
                    },
                setSelectedOption(event.target.value)
                    
                    )
                  }
                >
                  <option>Select Medicine</option>
                  <option value="CONTRAVE">CONTRAVE</option>
                  <option value="LOMAIRA">LOMAIRA</option>
                  <option value="SAXENDA">SAXENDA</option>
                  <option value="QSYMIA">QSYMIA</option>
                  <option value="MYSIMBA">MYSIMBA</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              {selectedOption === "Other" ? (
                  <div
                  className="questo-form-details-div-in-pat"
                  style={{ width: "30%" }}
                >
                  <p classname="questo-new-label-for-pat-que" style={{color: '#88869d'}}>Details: </p>
                  <textarea
                    className="questo-form-input-tag-for-patient"
                    type={"text"}
                    style={{marginTop:"5px"}}
                    placeholder="Details..."
                    onChange={(event) =>
                      props.othersHandler({
                        que_id: props.item.response_id1,
                        val: event.target.value,
                      })
                    }
                  />
                </div>
                ) : null}
              <div>
                {/*{props.detailsHandler({medicine_name}== 'Other')  && <input/>}*/}
              </div>
            </div>
          ) : props.item.question_category === "free_text" ? (
            <>
              <div className="questo-form-questions-div" style={{ width: "55%" }}>
                <p>
                  {props.item.mandatory === "Yes" ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : null}
                </p>
              </div>
              <textarea
                className="questo-form-input-tag-for-patient"
                type={"text"}
                placeholder="Details..."
                onChange={(event) =>
                  props.detailsHandler({
                    que_id: props.item.response_id1,
                    val: event.target.value,
                  })
                }
              />
            </>
          ) : props.item.question_category === "" ? (
            <>
              <div className="questo-form-questions-div" style={{ width: "55%" }}>
                <p>
                  {props.item.mandatory === "Yes" ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : null}
                </p>
              </div>
              <textarea
                className="questo-form-input-tag-for-patient"
                type={"text"}
                placeholder="Details..."
                onChange={(event) =>
                  props.detailsHandler({
                    que_id: props.item.response_id1,
                    val: event.target.value,
                  })
                }
              />
            </>
          ):props.item.question_category === "table_format" ? (
            <div
              className="questo-form-quesyion-ans-main-div-for-main"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="questo-form-questions-div" style={{ width: "55%" }}>
                <p>
                  {props.item.mandatory === "Yes" ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : null}
                </p>
              </div>
              <form
                // onSubmit={handleSubmit}
                style={
                  {
                   
                  }
                }
                className="questo-form-input-tag-for-patient"
              >
              <div style={{display: "flex", justifyContent: "center"}}>
              <table>
    
    
                    <thead>
                      <tr>
                        <th>Medication Name</th>
                        <th>Dosage</th>
                        <th>Number of times taken daily</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rows.map((row, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              type="text"
                              name="medication"
                              className="questo-form-input-tag-for-patient"
                              value={row.medication}
                              onChange={(event) => handleChange(event, index)}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="dosage"
                              className="questo-form-input-tag-for-patient"
                              value={row.dosage}
                              onChange={(event) => handleChange(event, index)}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="number"
                              className="questo-form-input-tag-for-patient"
                              value={row.number}
                              onChange={(event) => handleChange(event, index)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot style={{textAlign:"center"}}>
                      <tr>
                        <td colSpan={3}>
                          <button type="button" onClick={handleAddRow} className="male-female-btn-div" style={{backgroundColor:"rgb(49, 163, 214)",padding:"2px"}}>Add Row</button>
                          <button type="button" onClick={handleRemoveRow} className="male-female-btn-div" style={{backgroundColor:"rgb(49, 163, 214)",padding:"2px", marginLeft: "5px"}}>Remove Row</button>
                        </td>
                      </tr>
                    </tfoot>
                    </table>
                    </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                <button type="submit" onClick={handleSubmit} className="submit-btn-in-que" style={{padding:"4px"}}>Save</button>
                </div>
              </form>
            </div>
          ) : null}

      </div>
    </div>
  );
});

const PatientQuestionnaireLifestyle = () => {
  const [questionetailsget, setquestionetailsget] = useState([]);
  const [loading, setLoading] = useState(true);

  //console.log("questionetailsget",questionetailsget)

  const childRef = useRef();

  useEffect(() => {
    Apiservice
      .get(
        // `https://djangoobesitychat.azurewebsites.net/question/category/Medicine/`,
        `/question/category/Medicine/`,
        {}
      )

      .then((response) => {
        const data = response.data.map((entry) => {
          return Object.assign({}, entry, {
            patient_name: localStorage.getItem("Username"),
            question1: entry.question,
          });
        });
        setquestionetailsget(data);
        // // //console.log(data);
        setLoading(false);
      })
      .catch((err) => {});
  }, []);

  const radioButtonHandler = (param) => {
    const newResponse = questionetailsget.map((item) => {
      if (item.question_id == param.que_id) {
        item.patient_response1 = param.val;
      }
      return item;
    });
    setquestionetailsget(newResponse);
  };

  const detailsHandler = (param) => {
    const newResponse = questionetailsget.map((item) => {
      if (item.question_id == param.que_id) {
        // item.response_details1 = param.val;
        item.medicine_name = param.val;
      }
      return item;
    });
    setquestionetailsget(newResponse);
  };


  const othersHandler = (param) => {
    const newResponse = questionetailsget.map((item) => {
      if (item.question_id == param.que_id) {
        item.response_details1 = param.val;
        // item.detail = param.val;

      }
      return item;
    });
    setquestionetailsget(newResponse);
  };

  const [submitLoading, setsubmitLoading] = useState(false);
  const submitHandler = (props) => {
    const UsernameofPatient = localStorage.getItem("Username");

    const test = questionetailsget;
    setsubmitLoading(true);

    Apiservice
      .post(
        // `https://djangoobesitychat.azurewebsites.net/patient/response/Medicine/${UsernameofPatient}/`,
        `/patient/response/post/Medicine/${UsernameofPatient}/`,
        questionetailsget,
        {}
      )
      .then((res) => {
        // // //console.log("res", res);
        localStorage.setItem("Medicine", true);
        setsubmitLoading(false);

        swal({
          title: "Your Response has been Submitted Successfully",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });
      })
      .catch((err) => {
        setsubmitLoading(false);

        swal({
          title: "Please Fill all the Responces...",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });
      });
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {submitLoading && (
            <div className="purchase-order-details-pop-up-main-div">
              <div className="purchase-order-details-pop-up-sub-div">
                <div
                  className="purchase-order-details-pop-up-sub-div-container"
                  style={{ background: "none" }}
                >
                  <Loader />
                </div>
              </div>
            </div>
          )}
          <div>
            <div className="skip-for-now-div">
              <Link>
                <h3 onClick={submitHandler}>
                  Skip For Now <EastSharpIcon style={{ marginTop: "4px" }} />
                </h3>
              </Link>
            </div>
            {/* <div
              className="questo-form-quesyion-ans-main-div-for-main"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="questo-form-questions-div"
                style={{ width: "55%", justifyContent: "center" }}
              >
                <h3>Questions</h3>
              </div>
              <div
                className="questo-form-options-div"
                style={{ width: "15%", justifyContent: "center" }}
              >
                <h3>Response</h3>
              </div>
              <div
                className="questo-form-details-div-in-pat"
                style={{ width: "30%", justifyContent: "center" }}
              >
                <h3>Details</h3>
              </div>
            </div> */}
            {questionetailsget.map((item, index) => {
              return (
                <Que
                  key={item.question_id}
                  number = {index +1}
                  item={item}
                  ref={childRef}
                  detailsHandler={detailsHandler}
                  radioButtonHandler={radioButtonHandler}
                  othersHandler={othersHandler}

                />
              );
            })}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "10px",
            }}
          >
            <button onClick={submitHandler} className="submit-btn-in-que">
              Submit
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default PatientQuestionnaireLifestyle;
