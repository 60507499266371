import axios from "axios";
import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import Loader from "../Loader/Loader";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { quizListAction, quizPostAction } from "../../Redux/Action/Task-action";
import EastSharpIcon from "@mui/icons-material/EastSharp";
import { Link } from "react-router-dom";
import Apiservice from "../../Services/Apiservice";
const Que = forwardRef((props, ref) => {
  const [detail, setdetail] = useState("");
  const UsernameofPatient = localStorage.getItem("Username");
  const [response, setresponse] = useState("");
  const [message, setMessage] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [options, setOptions] = useState([]);
  const [name, setName] = useState("");
  const [year, setYear] = useState("");
  const [rows, setRows] = useState([{ name: "", year: "" }]);
  const [checkList, setCheckList] = useState([]);
  const [totalAttempts, setTotalAttempts] = useState(0);
  const [totalQuestions, setTotalQuestions] = useState(0);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Convert the data from the table into the format the API expects
    const tableData = {};
    rows.forEach((row) => {
      tableData[row.name] = {
        Name: row.name,
        Year: row.year,
      };
    });
    const data = Object.fromEntries(Object.entries(tableData));

    // send data to API
    fetch(
      `https://djangoobesitychat.azurewebsites.net/patient/response/Other/${UsernameofPatient}/`,
      {
        method: "POST",
        body: JSON.stringify([
          {
            question1: props.item.question1,
            patient_name: UsernameofPatient,
            tablejson: data,
          },
        ]),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  const handleClick = () => {
    const DiseaseCategory = props.item.disease_category;
    // // //console.log(options);
    fetch(`https://djangoobesitychat.azurewebsites.net/diseaseapi/category/${DiseaseCategory}/`)
      .then((response) => response.json())
      .then((data) => setOptions(data));
  };
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleAddRow = () => {
    setRows([...rows, { medication: "", dosage: "", number: "" }]);
  };

  const handleChange = (event, index) => {
    const newRows = [...rows];
    newRows[index][event.target.name] = event.target.value;
    setRows(newRows);
  };
  const updateList = (index, question_id, question, answer, weightage) => {
    let list = [...checkList];
    list[index].answer = answer;
    list[index].weightage = weightage;
    setCheckList(list);
    const checkAnswers = list.filter((item) => item.answer == "");
    const unanswersCount = checkAnswers.length;
    const totalAttempts = totalQuestions - unanswersCount;

    setTotalAttempts(totalAttempts);
  };
  // // //console.log(props.item.question_category)
  return (
    <div>
    <div className="questo-form-quesyion-ans-main-div-for-main">
    <div className="questo-form-questions-wrapper" style={{ width: "55%", cursor:"pointer" }}>
    <div className="questo-form-questions-div" >
    <p className="question-text">{props.number}. {props.item.question}</p>
    
    {props.item.mandatory === "Yes" ? (
            <span style={{ color: "red" }}> *</span>
          ) : null}
      </div>
      <div className="tooltip">More information</div>
      </div>
      <div className="response-div">
      <div className="questo-form-options-div" style={{ width: "15%" }}>
      <p classname="label-for-response" style={{color: '#88869d', marginBottom: '30px'}}>Response: </p>
        <div style={{ display: "flex", gap: "9px" }}>
          <input
            className="add-patient-input-field-of-radio"
            name={props.item.question_id}
            type="radio"
            value="Yes"
            onChange={(event) =>
              props.radioButtonHandler({
                que_id: props.item.question_id,
                val: event.target.value,
              })
            }
          />
          <p style={{ fontSize: "13px" }}>Yes</p>
          <input
            className="add-patient-input-field-of-radio"
            name={props.item.question_id}
            type="radio"
            value="No"
            onChange={(event) =>
              props.radioButtonHandler({
                que_id: props.item.question_id,
                val: event.target.value,
              })
            }
          />
          <p style={{ fontSize: "13px" }}>No</p>
        </div>
      </div>
      <div
        className="questo-form-details-div-in-pat"
        style={{ width: "30%" }}
      >
        <p classname="questo-new-label-for-pat-que" style={{color: '#88869d'}}>Details: </p>
        <textarea
          className="questo-form-input-tag-for-patient"
          type={"text"}
          placeholder="Details..."
          onChange={(event) =>
            props.detailsHandler({
              que_id: props.item.question_id,
              val: event.target.value,
            })
          }
        />
      </div>
      </div>
    </div>
  </div>
  );
});

const PatientQuestionnaireOther = () => {
  const [questionetailsget, setquestionetailsget] = useState([]);
  const [questionetailsgettwo, setquestionetailsgettwo] = useState([]);
  const [loading, setLoading] = useState(true);

  const childRef = useRef();

  const url1 = "https://djangoobesitychat.azurewebsites.net/question/category/Other/";
  const url2 = "https://djangoobesitychat.azurewebsites.net/emotional/get-quiz/";

  useEffect(() => {
    // axios
    //   .get(`https://djangoobesitychat.azurewebsites.net/question/category/Other/`, {})

    Promise.all([axios.get(url2), axios.get(url1)])
      .then(([response, response1]) => {
        // // //console.log("response", response);
        const data = response.data.map((entry) => {
          return Object.assign({}, entry, {
            patient_name: localStorage.getItem("Username"),
            question: entry.question,
          });
        });
        setquestionetailsget(data);

        const data1 = response1.data.map((entry) => {
          return Object.assign({}, entry, {
            patient_name: localStorage.getItem("Username"),
            question1: entry.question,
          });
        });
        setquestionetailsgettwo(data1);
        // // //console.log("data", data);
        // // //console.log("data1", data1);
        setLoading(false);
      })
      .catch((err) => {});
  }, []);

  const radioButtonHandler = (param) => {
    const newResponse = questionetailsget.map((item) => {
      if (item.question_id == param.que_id) {
        item.patient_response1 = param.val;
      }
      return item;
    });
    setquestionetailsget(newResponse);
  };

  const tableHandler = (param) => {
    // copy the current questiondetails state
    let newQuestionDetails = { ...questionetailsget };

    //find the question_id in the questiondetails state and update the tablejson field
    if (newQuestionDetails.question_id === param.que_id) {
      newQuestionDetails.tablejson = param.val;
    }

    //update the questiondetails state
    setquestionetailsget(newQuestionDetails);
  };

  const detailsHandler = (param) => {
    const newResponse = questionetailsget.map((item) => {
      if (item.question_id == param.que_id) {
        item.response_details1 = param.val;
      }
      return item;
    });
    setquestionetailsget(newResponse);
  };


  const radioButtonHandlertwo = (param) => {
    const newResponse = questionetailsgettwo.map((item) => {
      if (item.question_id == param.que_id) {
        item.patient_response1 = param.val;
      }
      return item;
    });
    setquestionetailsgettwo(newResponse);
  };

  const tableHandlertwo = (param) => {
    // copy the current questiondetails state
    let newQuestionDetails = { ...questionetailsgettwo };

    //find the question_id in the questiondetails state and update the tablejson field
    if (newQuestionDetails.question_id === param.que_id) {
      newQuestionDetails.tablejson = param.val;
    }

    //update the questiondetails state
    setquestionetailsgettwo(newQuestionDetails);
  };

  const detailsHandlertwo = (param) => {
    const newResponse = questionetailsgettwo.map((item) => {
      if (item.question_id == param.que_id) {
        item.response_details1 = param.val;
      }
      return item;
    });
     const radioButtonHandler = (param) => {
    const newResponse = questionetailsgettwo.map((item) => {
      if (item.question_id == param.que_id) {
        item.patient_response1 = param.val;
      }
      return item;
    });
    setquestionetailsgettwo(newResponse);
  };


  };

 

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user_id = 1;
  const [checkList, setCheckList] = useState([]);
  const [totalAttempts, setTotalAttempts] = useState(0);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const formData = new FormData();

  const submitQuizz = (e) => {
  if (checkList.length > 0) {
    for (let index = 0; index < checkList.length; index++) {
      const element = checkList[index];
      formData.append("quizz", JSON.stringify(element));
    }
  }
  formData.append("user", user_id);
  formData.append("total_attempts", totalAttempts);
  formData.append("total_questions", totalQuestions);
  dispatch(quizPostAction(formData));
  }

  const [submitLoading, setsubmitLoading] = useState(false);

  const submitHandler = (props) => {
    const UsernameofPatient = localStorage.getItem("Username");
    const test = questionetailsget;
    setsubmitLoading(true);

    submitQuizz()
     Apiservice
      .post(
        `/patient/response/post/Other/${UsernameofPatient}/`,
        questionetailsgettwo,
        {}
      )
      .then((res) => {
        // // //console.log("res", res);
        setsubmitLoading(false);
        navigate("/")
        localStorage.setItem("Other", true);
        localStorage.clear();

        swal({
          title: "Your Response has been Submitted Successfully",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });

        
      })
      .catch((err) => {
        setsubmitLoading(false);

        swal({
          title: "Please Fill all the Responces...",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });
      });
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
        {submitLoading && (
          <div className="purchase-order-details-pop-up-main-div">
            <div className="purchase-order-details-pop-up-sub-div">
              <div
                className="purchase-order-details-pop-up-sub-div-container"
                style={{ background: "none" }}
              >
                <Loader />
              </div>
            </div>
          </div>
        )}
          <div>

          <div className="skip-for-now-div">
          <Link>
            <h3 onClick={submitHandler}>
              Skip For Now <EastSharpIcon style={{ marginTop: "4px" }} />
            </h3>
          </Link>
        </div>
            {/* <div
              className="questo-form-quesyion-ans-main-div-for-main"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="questo-form-questions-div"
                style={{ width: "55%", justifyContent: "center" }}
              >
                <h3>Questions</h3>
              </div>
              <div
                className="questo-form-options-div"
                style={{ width: "15%", justifyContent: "center" }}
              >
                <h3>Response</h3>
              </div>
              <div
                className="questo-form-details-div-in-pat"
                style={{ width: "30%", justifyContent: "center" }}
              >
                <h3>Details</h3>
              </div>
            </div> */}
            {questionetailsget.map((item, index) => {
              return (
                <Que
                  key={item.id}
                  number={index + 1}
                  item={item}
                  ref={childRef}
                  detailsHandler={detailsHandler}
                  radioButtonHandler={radioButtonHandler}
                  tableHandler={tableHandler}
                />
              );
            })}

            {questionetailsgettwo.map((item, index) => {
              return (
                <Que
                  key={item.question_id}
                  number={index + 41}
                  item={item}
                  ref={childRef}
                  detailsHandler={detailsHandlertwo}
                  radioButtonHandler={radioButtonHandlertwo}
                  tableHandler={tableHandlertwo}
                />
              );
            })}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "0px",
            }}
          >
            <button onClick={submitHandler} className="submit-btn-in-que">
              Submit
            </button>
          </div>
         {/*<button
            onClick={() => {
              submitQuizz();
            }}
            className="submit-btn-in-que"
          >
            Submit
          </button>*/}
        </div>
      )}
    </>
  );
};

export default PatientQuestionnaireOther;
