import React from "react";

function EntityList({ entities, onAddButtonClick }) {
  return (
    <div className="disease-handler-input-search-tag-main-flex-div">
      <ul>
        {entities.map((entity) => (
          <li key={entity.id}>
          <div className="entity-main-flex-div">
          <div className="entity-main-flex-div-one">
          {entity?.replace(/<[^>]+>/g, "")}
          </div>
          <div className="entity-main-flex-div-one">
          <button onClick={() => onAddButtonClick(entity)} className="disease-handler-input-search-tag-btn">Add</button>
          </div>
          </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default EntityList;
