import React, { useState, useEffect } from "react";
import axios from "axios";
import "./AddPatient.css";
import Add from "@mui/icons-material/Add";
import { FaAngleDown } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Loader from "../Loader/Loader";
import SearchLoader from "../Loader/SearchLoader";
import Apiservice from "../../Services/Apiservice";

const AddPatient = () => {
  const [patientname, setPatientname] = useState("");
  const [address, setAddress] = useState("");
  const [bmi, setBmi] = useState("");
  const [pheno, setPheno] = useState("");
  const [percPheno, setPercPheno] = useState("");
  const [bgDisease, setBgDisease] = useState("");
  const [patientCluster, setPatientCluster] = useState("");
  const [progress, setProgress] = useState("");
  const [comboPheno, setComboPheno] = useState("");

  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [race, setRace] = useState("");
  const [cell, setCell] = useState("");
  const [wb, setWb] = useState("");
  const [wc, setWc] = useState("");
  const [wt, setWt] = useState("");
  const [dispenseDrug, setDispenseDrug] = useState("");
  const [dosage, setDosage] = useState("");
  const [heartRate, setheartRate] = useState("");
  const [bloodSugar, setbloodSugar] = useState("");
  const [bloodPressure, setbloodPressure] = useState("");
  const [Diastolic_blood_pressure, setDiastolic_blood_pressure] = useState("");
  const [Systolic_blood_pressure, setSystolic_blood_pressure] = useState("");
  const [successRate, setsuccessRate] = useState("");
  const [height, setheight] = useState("");
  const [bust_circumference_cm, setbust_circumference_cm] = useState("");
  const [hip_circumference_cm, sethip_circumference_cm] = useState("");
  const [waist_circumference_cm, setwaist_circumference_cm] = useState("");

  const [response, setresponse] = useState();

  const tokenForAddPatient = localStorage.getItem("SavedToken");
  const ClinicIDForHomepage = localStorage.getItem("ClinicIDForHomepage");
  const SubClinicIDForHomepage = localStorage.getItem("SubClinicIDForHomepage");
  const [submitLoading, setsubmitLoading] = useState(false);
  const is_clinic = localStorage.getItem("is_clinic")

  // Add Patient Post Request
  const addPatient = async (e) => {
    e.preventDefault();
    setsubmitLoading(true);

    await axios
      .put(
        `https://djangoobesitychat.azurewebsites.net/user/patientapi/update2/${UserIdtoDetDetails}/`,

        {
          // patient_name: patientname,
          // date_of_birth: patientdetailsinaddpatient.dob,
          // address: address,
          // bmi: bmi,
          // phenotype: pheno,
          // percent_match_to_phenotype: percPheno,
          // background_diseases: bgDisease,
          // progress_percent_according_to_plan: progress,
          // combo_phenotype: comboPheno,
          // success_rate_in_next_phenotype: successRate,
          patient_name: patientdetailsinaddpatient.patient_name,
          gender: patientdetailsinaddpatient.gender,
          race: race,
          cell: patientdetailsinaddpatient.patient_mobile,
          beginning_weight: wb,
          // current_weight: wc,
          target_weight: wt,
          // dispensed_drug: dispenseDrug,
          // dosage: dosage,
          heart_rate: heartRate,
          // blood_pressure: bloodPressure,
          blood_sugar: bloodSugar,
          height: height,
          age: patientdetailsinaddpatient.age,
          clinic_id: ClinicIDForHomepage,
          subclinic_id: SubClinicIDForHomepage,
          bust_circumference_cm: bust_circumference_cm,
          hip_circumference_cm: hip_circumference_cm,
          waist_circumference_cm: waist_circumference_cm,
          Diastolic_blood_pressure: Diastolic_blood_pressure,
          Systolic_blood_pressure: Systolic_blood_pressure,
        },
        {
          headers: {
            Authorization: `Token ${tokenForAddPatient}`,
          },
        }
      )
      .then((res) => {
        // // //console.log(res);
        setsubmitLoading(false);

        swal({
          title: "Patient Added Successfully...",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });

       

        if (is_clinic === "true") {
          navigate("/clinicsidebar/clinicpatientlist");
        } else {
          navigate("/subclinicsidebar/subclinicpatientlist");
        }

      })
      .catch((res) => {
        // // //console.log("err", res.response.data);
        setresponse(res.response.data);
        setsubmitLoading(false);
      });
  };

  //Get USER  by Username
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const savedtoken = localStorage.getItem("SavedToken");
  const navigate = useNavigate();

  useEffect(() => {
    const loadPosts = async () => {
      setLoading(true);
      const response = await Apiservice.get("/user/patientapi/");
      setPosts(response.data);
      setLoading(false);
    };

    loadPosts();
  }, []);

  // GET USER DETAILS

  const UserIdtoDetDetails = localStorage.getItem("UserIdtoDetDetails");
  const [patientdetailsinaddpatient, setpatientdetailsinaddpatient] = useState(
    []
  );
  const [userdetails, setuserdetails] = useState([]);

  useEffect(() => {
    // setsubmitLoading(true);
    Apiservice
      .get(`/user/patientapi/${UserIdtoDetDetails}/`)

      .then((response) => {
        setpatientdetailsinaddpatient(response.data[0]);
        setheartRate(response.data[0]["heart_rate"]);
        setbloodSugar(response.data[0]["blood_sugar"]);
        setWb(response.data[0]["beginning_weight"]);
        setWt(response.data[0]["target_weight"]);
        setheight(response.data[0]["height"]);
        setwaist_circumference_cm(response.data[0]["waist_circumference_cm"]);
        setbust_circumference_cm(response.data[0]["bust_circumference_cm"]);
        sethip_circumference_cm(response.data[0]["hip_circumference_cm"]);
        setDiastolic_blood_pressure(
          response.data[0]["Diastolic_blood_pressure"]
        );
        setSystolic_blood_pressure(response.data[0]["Systolic_blood_pressure"]);
        // setsubmitLoading(false);
      })
      .catch((err) => {});
  }, [UserIdtoDetDetails]);

  //console.log("patientdetailsinaddpatient", patientdetailsinaddpatient);

  const [open, setOPen] = useState(false);
  const toggle = () => {
    setOPen(!open);
  };

  const [opentwo, setOPentwo] = useState(false);
  const toggletwo = () => {
    setOPentwo(!opentwo);
  };

  return (
    <div>
      <div className="add-patient-main-popup">
        {submitLoading && (
          <div className="purchase-order-details-pop-up-main-div">
            <div className="purchase-order-details-pop-up-sub-div">
              <div
                className="purchase-order-details-pop-up-sub-div-container"
                style={{ background: "none" }}
              >
                <Loader />
              </div>
            </div>
          </div>
        )}
        <div className="add-patient-main-width-popup">
          <h3 className="add-new-pat-h-tag">Add New Patient</h3>
          <div className="search-patient-main-div">
            <div className="flex-div-width-div">
              <div>
                <h3 style={{ textAlign: "center" }}>Search Patient</h3>
              </div>
              <div>
                <div>
                  <input
                    onClick={toggletwo}
                    className="search-user-for-add-inp"
                    type="text"
                    placeholder="Search..."
                    onChange={(e) => setSearchTitle(e.target.value)}
                  />
                </div>
                {opentwo && (
                  <div className="list-of-all-user" onClick={toggletwo}>
                    {loading ? (
                      <div>
                        <SearchLoader />
                      </div>
                    ) : (
                      posts
                        .filter((value) => {
                          if (searchTitle === "") {
                            return posts;
                          } else if (
                            (value.first_name,
                            value.username
                              ?.toLowerCase()
                              .includes(searchTitle?.toLowerCase()))
                          ) {
                            return value;
                          }
                        })
                        .map((item) => (
                          <div className="list-of-user-main-flex-div">
                            <div className="list-of-user-main-div">
                              <p
                                onClick={() =>
                                  localStorage.setItem(
                                    "UserIdtoDetDetails",
                                    item.patient_name
                                  )
                                }
                              >
                                {item.patient_firstName}{"  "}{item.patient_lastName}
                              </p>
                            </div>
                          </div>
                        ))
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="new-patient-form-flex-div">
            <div className="new-patient-form-width-div">
              <div className="new-patient-form-main-div">
                <div className="new-patient-form-main-div-sub">
                  <div className="new-pat-part-one-flex">
                    <div className="new-pat-part-one-sub-one">
                      <p>Username</p>
                    </div>
                    <div className="new-pat-part-one-sub-two">
                      <div className="add-patient-input-field">
                        <h3>{patientdetailsinaddpatient&&patientdetailsinaddpatient?.patient_name}</h3>
                      </div>
                    </div>
                  </div>

                  <div className="new-pat-part-one-flex">
                    <div className="new-pat-part-one-sub-one">
                      <p>First Name</p>
                    </div>
                    <div className="new-pat-part-one-sub-two">
                      <div className="add-patient-input-field">
                        <h3>{patientdetailsinaddpatient&&patientdetailsinaddpatient?.patient_firstName}</h3>
                      </div>
                    </div>
                  </div>

                  <div className="new-pat-part-one-flex">
                    <div className="new-pat-part-one-sub-one">
                      <p>Last Name</p>
                    </div>
                    <div className="new-pat-part-one-sub-two">
                      <div className="add-patient-input-field">
                        <h3>{patientdetailsinaddpatient&&patientdetailsinaddpatient?.patient_lastName}</h3>
                      </div>
                    </div>
                  </div>

                  <div className="new-pat-part-one-flex">
                    <div className="new-pat-part-one-sub-one">
                      <p>Gender</p>
                    </div>
                    <div className="new-pat-part-one-sub-two">
                      <div className="add-patient-input-field">
                        <h3>{patientdetailsinaddpatient&&patientdetailsinaddpatient?.gender}</h3>
                      </div>
                      {/*<select
                        className="add-patient-input-field"
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <option>Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Not Specified">Not Specified</option>
                     </select>*/}
                    </div>
                  </div>

                  <div className="new-pat-part-one-flex">
                    <div className="new-pat-part-one-sub-one">
                      <p>Age</p>
                    </div>
                    <div className="new-pat-part-one-sub-two">
                      <div className="add-patient-input-field">
                        <h3>{patientdetailsinaddpatient&&patientdetailsinaddpatient?.age}</h3>
                      </div>
                    </div>
                  </div>

                  <div className="new-pat-part-one-flex">
                  <div className="new-pat-part-one-sub-one">
                    <p>Mobile Number</p>
                  </div>
                  <div className="new-pat-part-one-sub-two">
                    <div className="add-patient-input-field">
                      <h3>{patientdetailsinaddpatient&&patientdetailsinaddpatient?.patient_mobile}</h3>
                    </div>
                  </div>
                </div>

                  <div className="new-pat-part-one-flex">
                    <div className="new-pat-part-one-sub-one">
                      <p>Race</p>
                    </div>
                    <div className="new-pat-part-one-sub-two">
                      <input
                        className="add-patient-input-field"
                        placeholder="Race"
                        value={race}
                        onChange={(e) => setRace(e.target.value)}
                      />
                    </div>
                  </div>

                 

                  <div className="new-pat-part-one-flex">
                    <div className="new-pat-part-one-sub-one">
                      <p>Heart Rate</p>
                    </div>
                    <div className="new-pat-part-one-sub-two">
                      <input
                        className="add-patient-input-field"
                        placeholder="Heart Rate"
                        type="number"
                        value={heartRate}
                        onChange={(e) => setheartRate(e.target.value)}
                      />
                      <div className="error-message-preview">
                        <p>{response && response.heart_rate}</p>
                      </div>
                    </div>
                  </div>

                  <div className="new-pat-part-one-flex">
                    <div className="new-pat-part-one-sub-one">
                      <p>Blood Sugar</p>
                    </div>
                    <div className="new-pat-part-one-sub-two">
                      <input
                        className="add-patient-input-field"
                        placeholder="Blood Sugar"
                        type="number"
                        value={bloodSugar}
                        onChange={(e) => setbloodSugar(e.target.value)}
                      />
                      <div className="error-message-preview">
                        <p>{response && response.blood_sugar}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="new-patient-form-main-div-sub">
                  <div className="new-pat-part-one-flex">
                    <div className="new-pat-part-one-sub-one">
                      <p>Beginning Weight in Kg</p>
                    </div>
                    <div className="new-pat-part-one-sub-two">
                      <input
                        className="add-patient-input-field"
                        placeholder="Beginning Weight"
                        type="number"
                        value={wb}
                        onChange={(e) => setWb(e.target.value)}
                      />
                      <div className="error-message-preview">
                        <p>{response && response.beginning_weight}</p>
                      </div>
                    </div>
                  </div>

                  {/*<div className="new-pat-part-one-flex">
                    <div className="new-pat-part-one-sub-one">
                      <p>Current Weight in Kg</p>
                    </div>
                    <div className="new-pat-part-one-sub-two">
                      <input
                        className="add-patient-input-field"
                        placeholder="Current Weight "
                        type="number"
                        value={wc}
                        onChange={(e) => setWc(e.target.value)}
                      />
                      <div className="error-message-preview">
                        <p>{response && response.current_weight}</p>
                      </div>
                    </div>
                    </div>*/}

                  <div className="new-pat-part-one-flex">
                    <div className="new-pat-part-one-sub-one">
                      <p>Target Weight in Kg</p>
                    </div>
                    <div className="new-pat-part-one-sub-two">
                      <input
                        className="add-patient-input-field"
                        placeholder="Target Weight "
                        type="number"
                        value={wt}
                        onChange={(e) => setWt(e.target.value)}
                      />
                      <div className="error-message-preview">
                        <p>{response && response.target_weight}</p>
                      </div>
                    </div>
                  </div>

                  <div className="new-pat-part-one-flex">
                    <div className="new-pat-part-one-sub-one">
                      <p>Height in cm</p>
                    </div>
                    <div className="new-pat-part-one-sub-two">
                      <input
                        type={"number"}
                        className="add-patient-input-field"
                        placeholder="Height"
                        value={height}
                        onChange={(e) => setheight(e.target.value)}
                      />
                      <div className="error-message-preview">
                        <p>{response && response.height}</p>
                      </div>
                    </div>
                  </div>

                  {/*
                  <div className="new-pat-part-one-flex">
                    <div className="new-pat-part-one-sub-one">
                      <p>Dispense Drug</p>
                    </div>
                    <div className="new-pat-part-one-sub-two">
                      <input
                        className="add-patient-input-field"
                        placeholder="Dispense Drug"
                        value={dispenseDrug}
                        onChange={(e) => setDispenseDrug(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="new-pat-part-one-flex">
                    <div className="new-pat-part-one-sub-one">
                      <p>Dosage</p>
                    </div>
                    <div className="new-pat-part-one-sub-two">
                      <input
                        className="add-patient-input-field"
                        placeholder="Dosage"
                        value={dosage}
                        onChange={(e) => setDosage(e.target.value)}
                      />
                    </div>
                  </div>
                                 */}

                  <div className="new-pat-part-one-flex">
                    <div className="new-pat-part-one-sub-one">
                      <p>Waist Circumference in cm</p>
                    </div>
                    <div className="new-pat-part-one-sub-two">
                      <input
                        className="add-patient-input-field"
                        placeholder="Waist Circumference"
                        type="number"
                        value={waist_circumference_cm}
                        onChange={(e) =>
                          setwaist_circumference_cm(e.target.value)
                        }
                      />
                      <div className="error-message-preview">
                        <p>{response && response.waist_circumference_cm}</p>
                      </div>
                    </div>
                  </div>
                  <div className="new-pat-part-one-flex">
                    <div className="new-pat-part-one-sub-one">
                      <p>Bust Circumference in cm</p>
                    </div>
                    <div className="new-pat-part-one-sub-two">
                      <input
                        className="add-patient-input-field"
                        placeholder="Bust Circumference"
                        type="number"
                        value={bust_circumference_cm}
                        onChange={(e) =>
                          setbust_circumference_cm(e.target.value)
                        }
                      />
                      <div className="error-message-preview">
                        <p>{response && response.bust_circumference_cm}</p>
                      </div>
                    </div>
                  </div>

                  <div className="new-pat-part-one-flex">
                    <div className="new-pat-part-one-sub-one">
                      <p>Hip Circumference in cm</p>
                    </div>
                    <div className="new-pat-part-one-sub-two">
                      <input
                        className="add-patient-input-field"
                        placeholder="Hip Circumference"
                        type="number"
                        value={hip_circumference_cm}
                        onChange={(e) =>
                          sethip_circumference_cm(e.target.value)
                        }
                      />
                      <div className="error-message-preview">
                        <p>{response && response.hip_circumference_cm}</p>
                      </div>
                    </div>
                  </div>

                  <div className="new-pat-part-one-flex">
                    <div className="new-pat-part-one-sub-one">
                      <p>Diastolic Blood Pressure</p>
                    </div>
                    <div className="new-pat-part-one-sub-two">
                      <input
                        className="add-patient-input-field"
                        placeholder="Blood Pressure"
                        type="number"
                        value={Diastolic_blood_pressure}
                        onChange={(e) =>
                          setDiastolic_blood_pressure(e.target.value)
                        }
                      />
                      <div className="error-message-preview">
                        <p>{response && response.Diastolic_blood_pressure}</p>
                      </div>
                    </div>
                  </div>

                  <div className="new-pat-part-one-flex">
                    <div className="new-pat-part-one-sub-one">
                      <p>Systolic Blood Pressure</p>
                    </div>
                    <div className="new-pat-part-one-sub-two">
                      <input
                        className="add-patient-input-field"
                        placeholder="Blood Pressure"
                        type="number"
                        value={Systolic_blood_pressure}
                        onChange={(e) =>
                          setSystolic_blood_pressure(e.target.value)
                        }
                      />
                      <div className="error-message-preview">
                        <p>{response && response.Systolic_blood_pressure}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="add-pat-btn-div">
            <button onClick={addPatient} className="add-btn-btn-tag">
              <Add style={{ height: "20px", width: "20px" }} />
              SUBMIT
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPatient;
