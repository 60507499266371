import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PatientLogo from "../../Images/ClinicSVG/newdoctor.svg";
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";
import { TableHead } from "@mui/material";
import Apiservice from "../../Services/Apiservice";

const DoctorAppointmentList = () => {
  const [loading, setLoading] = useState(true);
  const [mypatients, setmypatients] = useState([]);
  const DoctorName = localStorage.getItem("Username");

  useEffect(() => {
    Apiservice
      .get(`/patientresponse/${DoctorName}/`, {})

      .then((response) => {
        setmypatients(response.data);
        setLoading(false);
      })
      .catch((err) => {});
  }, []);
  const rowsOne = [mypatients.revisit_data_list];
  const rowsThree = [mypatients.my_patients];
  // Create a new list that combines the revisit_data_list and my_patients data
  const combinedData =
    mypatients.revisit_data_list &&
    mypatients.revisit_data_list.map((revisitItem) => {
      const myPatientsItem =
        mypatients.my_patients &&
        mypatients.my_patients.find(
          (item) => item && item.patient_id2_id === revisitItem.patient_id
        );
      return myPatientsItem && { ...revisitItem, ...myPatientsItem };
    });

  const cd = combinedData&&combinedData.sort((a, b) => new Date(...b.updated_at.split('/').reverse()) - new Date(...a.updated_at.split('/').reverse()));
  // // //console.log(cd);

  const handleClick = (key) => {
    localStorage.setItem("patientUserName", key);
  };
  return (
    <div style={{ padding: "20px" }}>
    <div className="second-para-div--n-docs" style={{paddingTop:"0px"}}>
    <div className="acc-setting-main-div">
      <div className="update-account-title-div">
        <h2>Appointment List</h2>
      </div>
    </div>
  </div>
      <TableContainer component={Paper}>
        {loading ? (
          <Loader />
        ) : (
          <Table aria-label="simple table">
            <TableHead sx={{backgroundColor:'rgb(49, 163, 214)', color: 'white'}}>
              <TableRow>
                <TableCell align="left">Appointment ID</TableCell>
                <TableCell align="left">Logo</TableCell>
                <TableCell align="left">Patient ID</TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Contact</TableCell>
                <TableCell align="left">Gender</TableCell>
                <TableCell align="left">Age</TableCell>
                <TableCell align="left">Service Type</TableCell>
                <TableCell align="left">Patient Type</TableCell>
                <TableCell align="left">Appointment Date</TableCell>
                <TableCell align="left">Appointment Time</TableCell>
                <TableCell align="left">Appointment Status</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {combinedData &&
                combinedData.map((row) => (
                  <TableRow
                    key={row && row.name}
                    id={row && row.patient_id}
                    onClick={() =>
                      handleClick(row && row.patient_data[0]["username"])
                    }
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                  <TableCell align="left">
                      {row && row.appoinment_id}
                    </TableCell>
                    <TableCell align="center">
                      <img
                        src={PatientLogo}
                        alt=""
                        style={{ height: "30px", width: "30px" }}
                      />
                    </TableCell>

                    <TableCell align="left" style={{ padding: "10px" }}>
                      <div style={{ display: "flex", gap: "2px" }}>
                        <Link
                          to="/dashboard/patientsummary"
                          style={{ textDecoration: "none" }}
                        >
                          <h3
                            style={{
                              fontSize: "14px",
                              cursor: "pointer",
                              gap: "3px",
                            }}
                          >
                            {row && row.patient_id2_id}
                          </h3>
                        </Link>
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      {row && row.patient_data[0]["firstName"]}{" "}
                      {row && row.patient_data[0]["lastName"]}
                    </TableCell>
                    <TableCell align="left">
                      {row && row.patient_data[0]["mobile"]}
                    </TableCell>
                    <TableCell align="left">
                      {row && row.patient_data[0]["gender"]}
                    </TableCell>
                    <TableCell align="left">
                      {row && row.patient_data[0]["age"]}
                    </TableCell>
                    <TableCell align="left">{row && row.service}</TableCell>
                    <TableCell align="left" style={{ padding: "10px" }}>
                      {row && row.revisit_data}
                    </TableCell>
                    <TableCell align="left" style={{ padding: "10px" }}>
                      {row && row.starttime.slice(0,10)}
                    </TableCell>
                    <TableCell align="left" style={{ padding: "10px" }}>
                      {row && row.starttime.slice(11,16)}
                    </TableCell>
                    <TableCell align="left" style={{ padding: "10px" }}>
                      {row && row.is_approved}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </div>
  );
};

export default DoctorAppointmentList;
