import axios from 'axios'

class Apiservice{

    // GET REQUEST CALL
  get = async (url) => {
    try {
      const response = await axios.get(process.env.REACT_APP_SERVERURL + url,
        { headers: { Authorization: `Token ${localStorage.getItem("SavedToken")}` } }
        );
      return { error: null, data: response.data };
    } catch (error) {
      //console.log("Error :: " + error);
      return { error, data: null };
    }
  };


   // POST REQUEST CALL
   post = async (url, payload) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_SERVERURL + url,
        payload,
        { headers: { Authorization: `Token ${localStorage.getItem("SavedToken")}` } }
      );
      return { error: null, data: response.data };
    } catch (error) {
      //console.log("Error :: " + error);
      return { error, data: null };
    }
  };

  // PUT REQUEST CALL
  put = async (url, payload) => {
    try {
      const response = await axios.put(
        process.env.REACT_APP_SERVERURL + url,
        payload,
        { headers: { Authorization: `Token ${localStorage.getItem("SavedToken")}` } }
      );
      return { error: null, data: response.data };
    } catch (error) {
      //console.log("Error :: " + error);
      return { error, data: null };
    }
  }

  




}
export default new Apiservice()