import React, { useState } from "react";
// import HandImg from "../../Images/HandImageLoginPg.png";
import LoginPageImage from "../../Images/FrontMainImage2.jpg";
import "./DoctorRegistration.css";
import swal from "sweetalert";
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../Loader/Loader";
import AutoComplete from "../Map/AutoComplete";
import { Height, Padding } from "@mui/icons-material";

const DoctorRegistration = () => {
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [password2, setpassword2] = useState("");
  const [email, setemail] = useState("");
  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [mobile, setmobile] = useState("");
  const [address, setaddress] = useState("");
  // const [age, setage] = useState("");
  const [gender, setgender] = useState("");
  const [date_of_birth, setdate_of_birth] = useState("");
  const [image, setimage] = useState("");
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState("");
  const [response, setresponse] = useState();
  const [responseData, setResponseData] = useState(null);

  const is_clinic = localStorage.getItem("is_clinic")


  const [submitLoading, setsubmitLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setsubmitLoading(true);

    const formData = new FormData();
    formData.append("image", selectedFile && selectedFile);
    formData.append("username", username);
    formData.append("password", password);
    formData.append("password2", password2);
    formData.append("email", email);
    formData.append("firstName", first_name);
    formData.append("lastName", last_name);
    formData.append("mobile", mobile);
    formData.append("address", address);
    // formData.append("age", age);
    formData.append("gender", gender);
    formData.append("date_of_birth", date_of_birth);
    const medDocsField = formData.get("image");
    // // //console.log(medDocsField);

    axios({
      method: "post",
      url: `https://djangoobesitychat.azurewebsites.net/user/doctor/register/`,
      data: formData,
    })
      .then((res) => {
        const { username } = res.data;
        localStorage.setItem("DoctorUserNameDuringRegistration", username);

        if (is_clinic === "true") {
          navigate("/clinicsidebar/adddoctordetails");
        } else {
          navigate("/subclinicsidebar/adddoctordetails");
        }

        // navigate("/subclinicsidebar/adddoctordetails");
        setsubmitLoading(false);

        swal({
          title: "Doctor Added Successfully...",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });
      })

      .catch((res) => {
        // // //console.log("err", res.response.data);
        setsubmitLoading(false);

        setresponse(res.response.data);
      });
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  //data for chatengine
  //  const data = {
  //   "username": username,
  //   "secret": "pass12345",
  //   "email": email,
  //   "first_name": first_name,
  //   "last_name": last_name,
  // };
  // const config = {
  //   method: 'post',
  //   url: 'https://api.chatengine.io/users/',
  //   headers: {
  //     'PRIVATE-KEY': '7e272f37-e1d7-44ca-8fd6-89585bd671d1'
  //   },
  //   data: data
  // };

  // axios(config)
  //   .then(function (response) {
  //     setResponseData(response.data);
  //   })
  //   .catch(function (error) {
  //     //console.log(error);
  //   });

  const onHandleChange = (childData) => {
    // //console.log("Value inside Patient is",childData )
    setaddress(childData);
  };

  return (
    <div className="login-page-flex-div">
      {submitLoading && (
        <div className="purchase-order-details-pop-up-main-div">
          <div className="purchase-order-details-pop-up-sub-div">
            <div
              className="purchase-order-details-pop-up-sub-div-container"
              style={{ background: "none" }}
            >
              <Loader />
            </div>
          </div>
        </div>
      )}
      <div className="login-page-width-div">
        <div className="login-page-main-div">
          <div
            className="login-page-sub-div-one"
            style={{ paddingBottom: "20px" }}
          >
            <div
              className="login-page-sub-one-sec-one"
              style={{ paddingBottom: "0px" }}
            >
              <h4>obesity tool</h4>
              <p>by sk pharma</p>
              <h5>Doctor Registration</h5>
            </div>
            <div className="login-page-sub-one-sec-two">
              <div className="display-input-field-inflex">
                <div className="input-fiend-one">
                  <input
                    type="text"
                    autoComplete="off"
                    className="email-input-field-tag"
                    placeholder="Doctor First Name"
                    style={{ width: "290px" }}
                    value={first_name}
                    onChange={(e) => setfirst_name(e.target.value)}
                  />
                </div>




                <div className="input-fiend-one">
                  <input
                    type="text"
                    autoComplete="off"
                    className="email-input-field-tag"
                    placeholder="Doctor Last Name"
                    style={{ width: "290px" }}
                    value={last_name}
                    onChange={(e) => setlast_name(e.target.value)}
                  />
                </div>
              </div>


              <div className="display-input-field-inflex">

                <div className="error-message-preview" style={{ width: "290px" }}>
                  <p>{response && response.firstName}</p>
                </div>
                <div className="error-message-preview-mobile" style={{ width: "290px" }}>

                </div>
              </div>


              <div className="display-input-field-inflex">
                <div className="input-fiend-one" >
                  <input
                    type="email"
                    autoComplete="off"
                    className="email-input-field-tag"
                    placeholder="Doctor Email ID"
                    style={{ width: "290px" }}
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                  />
                </div>

                <div className="input-fiend-one">
                  <input
                    type="number"
                    autoComplete="off"
                    className="email-input-field-tag"
                    placeholder="Doctor Mobile Number"
                    style={{ width: "290px" }}
                    value={mobile}
                    onChange={(e) => setmobile(e.target.value)}
                  />
                </div>
              </div>

              <div className="display-input-field-inflex">

                <div className="error-message-preview" style={{ width: "290px" }}>
                  <p>{response && response.email}</p>
                </div>
                <div className="error-message-preview-mobile" style={{ width: "290px" }}>

                </div>
              </div>


              <div className="display-input-field-inflex">
                <div className="input-fiend-one">
                  <input
                    type="text"
                    autoComplete="off"
                    className="email-input-field-tag"
                    placeholder="Doctor Username"
                    style={{ width: "290px" }}
                    value={username}
                    onChange={(e) => setusername(e.target.value)}
                  />
                </div>

                <div className="input-fiend-one">
                  <input
                    type="file"
                    autoComplete="off"
                    className="email-input-field-tag"
                    style={{ width: "290px" }}
                    onChange={handleFileSelect}
                  />
                </div>
              </div>

              <div className="display-input-field-inflex">

                <div className="error-message-preview" style={{ width: "290px" }}>
                  <p>{response && response.username}</p>
                </div>
                <div className="error-message-preview-mobile" style={{ width: "290px" }}>
                  <p>{response && response.image}</p>
                </div>
              </div>

              <div className="display-input-field-inflex">
                <div className="input-fiend-one">
                  <input
                    type="password"
                    autoComplete="off"
                    className="email-input-field-tag"
                    placeholder="Password"
                    style={{ width: "290px" }}
                    value={password}
                    onChange={(e) => setpassword(e.target.value)}
                  />
                </div>

                <div className="input-fiend-one">
                  <input
                    type="password"
                    autoComplete="off"
                    className="email-input-field-tag"
                    placeholder="Check Password"
                    style={{ width: "290px" }}
                    value={password2}
                    onChange={(e) => setpassword2(e.target.value)}
                  />
                </div>
              </div>

              <div className="display-input-field-inflex">

                <div className="error-message-preview" style={{ width: "290px" }}>
                
                </div>
                <div className="error-message-preview-mobile" style={{ width: "290px" }}>
                  <p>{response && response.password2}</p>
                </div>
              </div>


              {/*<div className="input-fiend-one">
                  <input
                    type="text"
                    className="email-input-field-tag"
                    placeholder="Doctor Address"
                    style={{ width: "290px" }}
                    value={address}
                    onChange={(e) => setaddress(e.target.value)}
                  />
              </div>*/}

              <div className="input-fiend-one">
                <AutoComplete parentCallback={onHandleChange} />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "60px",
              }}
            >
              <div className="input-fiend-one" style={{ paddingTop: "5px" }}>
                <h4>Gender</h4>
              </div>
              <div
                className="input-fiend-one"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "60px",
                  paddingTop: "3px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "2px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="radio"
                    name="gender"
                    value="Male"
                    style={{}}
                    onChange={(e) => setgender(e.target.value)}
                  />
                  <p>Male</p>
                </div>
                <div style={{ display: "flex", gap: "2px" }}>
                  <input
                    type="radio"
                    name="gender"
                    value="Female"
                    style={{}}
                    onChange={(e) => setgender(e.target.value)}
                  />
                  <p>Female</p>
                </div>
              </div>
            </div>
            <div className="input-fiend-one" style={{ paddingTop: "5px" }}>
              <h4>Date of Birth</h4>
            </div>
            <div className="input-fiend-one" style={{ paddingTop: "3px" }}>
              <input
                type="date"
                autoComplete="off"
                className="email-input-field-tag"
                placeholder="Date of Birth"
                style={{ width: "290px" }}
                value={date_of_birth}
                onChange={(e) => setdate_of_birth(e.target.value)}
              />
            </div>


            <div className="login-page-sub-one-sec-four">
              <button onClick={handleSubmit} className="login-page-login-btn">
                Register a Doctor
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default DoctorRegistration;
