import * as React from "react";
import { useState, useEffect } from "react";
// import "./PatientList.css";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaFilter } from "react-icons/fa";
import { AiFillAppstore } from "react-icons/ai";
import Add from "@mui/icons-material/Add";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import PatientLogo from "../../Images/ClinicSVG/newdoctor.svg";

const SubClinicReceptionistList = () => {
  // GET PATIENT DETAILS
  const [patientdetails, setpatientdetails] = useState([]);
  const savedtoken = localStorage.getItem("SavedToken");
  const clinic = localStorage.getItem("is_clinic");

  const [loading, setLoading] = useState(true);
  const SubClinicID = localStorage.getItem("SubClinicIDForHomepage");

  useEffect(() => {
    axios
      .get(`https://djangoobesitychat.azurewebsites.net/user/receptionist/subclinicid/${SubClinicID}/`, {
        headers: {
          Authorization: `Token ${savedtoken}`,
        },
      })

      .then((response) => {
        setpatientdetails(response.data);
        // // // //console.log("ASD",response.data)
        setLoading(false);
      })
      .catch((err) => {});
  }, []);
  if (!patientdetails) return null;

  const Row = (props) => {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    const SaveID = () => {
      localStorage.setItem("PatientID", row.receptionist_id);
    };

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }} onClick={SaveID}>
          <TableCell align="center" style={{ cursor: "pointer" }}>
            {row.receptionist_id}
          </TableCell>

          <TableCell align="center">
            {row.receptionist_firstName} {row.receptionist_lastName}
          </TableCell>
          <TableCell align="center">{row.receptionist_name}</TableCell>
          <TableCell align="center">{row.receptionist_email}</TableCell>
          <TableCell align="center">{row.receptionist_mobile}</TableCell>
          <TableCell align="center">{row.receptionist_gender}</TableCell>
          <TableCell align="center">
            {row.receptionist_address} {row.city}
          </TableCell>
          <TableCell align="center">{row.opentime}</TableCell>
          <TableCell align="center">{row.closetime}</TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  return (
    <div>
      <div>
        <div className="paient-list-top-main-flex-div">
          <div className="paient-list-top-main-width-div">
            <div className="paient-list-top-main-div" style={{display:"flex",justifyContent:"space-between",alignItems:"center",paddingBottom:"20px"}}>
              <div className="page-header-main-div">
                <p>Receptionist List</p>
              </div>

              <div className="table-page-middle-nav-main-div"  style={{paddingBottom:"0px"}}>
                <div>
                  <Link to="/subclinicsidebar/receptionistregistration">
                    <button className="add-btn-btn-tag">
                      <Add style={{ height: "20px", width: "20px" }} />
                      Add Receptionist
                    </button>
                  </Link>
                </div>

              </div>
            </div>
          </div>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <div>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell component="th" scope="row" align="center">
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <p>ID</p>{" "}
                        <BsThreeDotsVertical
                          style={{
                            cursor: "pointer",
                            color: "rgb(49,163,214)",
                          }}
                        />
                      </div>
                    </TableCell>

                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <p>Name</p>
                        <BsThreeDotsVertical
                          style={{
                            cursor: "pointer",
                            color: "rgb(49,163,214)",
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <p>Username</p>{" "}
                        <BsThreeDotsVertical
                          style={{
                            cursor: "pointer",
                            color: "rgb(49,163,214)",
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <p>Email</p>{" "}
                        <BsThreeDotsVertical
                          style={{
                            cursor: "pointer",
                            color: "rgb(49,163,214)",
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <p>Contact</p>{" "}
                        <BsThreeDotsVertical
                          style={{
                            cursor: "pointer",
                            color: "rgb(49,163,214)",
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <p>Gender</p>{" "}
                        <BsThreeDotsVertical
                          style={{
                            cursor: "pointer",
                            color: "rgb(49,163,214)",
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <p>Address</p>{" "}
                        <BsThreeDotsVertical
                          style={{
                            cursor: "pointer",
                            color: "rgb(49,163,214)",
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <p>In Time</p>{" "}
                        <BsThreeDotsVertical
                          style={{
                            cursor: "pointer",
                            color: "rgb(49,163,214)",
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <p>Out Time</p>{" "}
                        <BsThreeDotsVertical
                          style={{
                            cursor: "pointer",
                            color: "rgb(49,163,214)",
                          }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {patientdetails.map((row) => (
                    <Row key={row.receptionist_id} row={row} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubClinicReceptionistList;
