import React from "react";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import "./PatientViewForDoctor.css";
import Apiservice from "../../Services/Apiservice";

const PatientViewForDoctor = () => {
  const [patientdetails, setpatientdetails] = useState([]);
  const savedtoken = localStorage.getItem("SavedToken");
  const [loading, setLoading] = useState(true);
  const PatientIDforRedirect = localStorage.getItem("patientUserName");


  useEffect(() => {
    Apiservice
      .get(`/user/patientapi/${PatientIDforRedirect}/`, {
        headers: {
          Authorization: `Token ${savedtoken}`,
        },
      })
      .then((response) => {
        setpatientdetails(response.data[0]);
        // // //console.log("patientdetails", response.data);
        setLoading(false);
      })
      .catch((err) => {});
  }, [PatientIDforRedirect]);
  if (!patientdetails) return null;
  return (
    <div className="patient-view-flex-div">
      <div className="patient-view-width-div">
        <div className="patient-view-main-div">
          <div className="patient-view-sub-div-one">
            <p className="patient-detail-para">Patient First Name :</p>
            <p className="patient-info-para">{patientdetails.patient_firstName}</p>
          </div>
          <div className="patient-view-sub-div-one">
            <p className="patient-detail-para">Patient Last Name :</p>
            <p className="patient-info-para">{patientdetails.patient_lastName}</p>
          </div>
          <div className="patient-view-sub-div-one">
            <p className="patient-detail-para">Patient Username :</p>
            <p className="patient-info-para">{patientdetails.patient_name}</p>
          </div>
          <div className="patient-view-sub-div-one">
            <p className="patient-detail-para">Patient Age :</p>
            <p className="patient-info-para">{patientdetails.age}</p>
          </div>
          <div className="patient-view-sub-div-one">
            <p className="patient-detail-para">Patient Gender :</p>
            <p className="patient-info-para">{patientdetails.gender}</p>
          </div>
          <div className="patient-view-sub-div-one">
            <p className="patient-detail-para">Patient Current Weight :</p>
            <p className="patient-info-para">{patientdetails.current_weight}</p>
          </div>
          <div className="patient-view-sub-div-one">
            <p className="patient-detail-para">Patient Target Weight :</p>
            <p className="patient-info-para">{patientdetails.target_weight}</p>
          </div>
          <div className="patient-view-sub-div-one">
            <p className="patient-detail-para">Patient BMI :</p>
            <p className="patient-info-para">{patientdetails.BMI}</p>
          </div>
          <div className="patient-view-sub-div-one">
            <p className="patient-detail-para">Patient BMR :</p>
            <p className="patient-info-para">{patientdetails.BMR}</p>
          </div>
          <div className="patient-view-sub-div-one">
            <p className="patient-detail-para">Patient Blood Pressure :</p>
            <p className="patient-info-para">{patientdetails.blood_pressure}</p>
          </div>
          <div className="patient-view-sub-div-one">
            <p className="patient-detail-para">Patient Blood Sugar :</p>
            <p className="patient-info-para">{patientdetails.blood_sugar}</p>
          </div>
          <div className="patient-view-sub-div-one">
            <p className="patient-detail-para">Patient Heart Rate :</p>
            <p className="patient-info-para">{patientdetails.heart_rate}</p>
          </div>
          <div className="patient-view-sub-div-one">
            <p className="patient-detail-para">Patient Combo Phenotype :</p>
            <p className="patient-info-para">{patientdetails.combo_phenotype}</p>
          </div>
          <div className="patient-view-sub-div-one">
            <p className="patient-detail-para">Patient Dispensed Drug :</p>
            <p className="patient-info-para">{patientdetails.dispensed_drug}</p>
          </div>
          <div className="patient-view-sub-div-one">
            <p className="patient-detail-para">Patient Percentage Match to Phenotype :</p>
            <p className="patient-info-para">{patientdetails.percent_match_to_phenotype}</p>
          </div>
          <div className="patient-view-sub-div-one">
            <p className="patient-detail-para">Patient Phenotype :</p>
            <p className="patient-info-para">{patientdetails.phenotype}</p>
          </div>
          <div className="patient-view-sub-div-one">
            <p className="patient-detail-para">Patient Progress Percent according to plan :</p>
            <p className="patient-info-para">{patientdetails.progress_percent_according_to_plan}</p>
          </div>
          <div className="patient-view-sub-div-one">
            <p className="patient-detail-para">Patient Race :</p>
            <p className="patient-info-para">{patientdetails.race}</p>
          </div>
          <div className="patient-view-sub-div-one">
            <p className="patient-detail-para">Patient Success Rate in Next Phenotype :</p>
            <p className="patient-info-para">{patientdetails.success_rate_in_next_phenotype}</p>
          </div>
          <div className="patient-view-sub-div-one">
            <p className="patient-detail-para">Patient Height :</p>
            <p className="patient-info-para">{patientdetails.height}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientViewForDoctor;
