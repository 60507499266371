import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import Apiservice from "../../Services/Apiservice";
import "./DoctorExtraDetails.css";
// import "./PatientExtraDetails.css";

const DoctorExtraDetails = () => {
  const clinic_name = localStorage.getItem("Username");
  const clinic_ID = localStorage.getItem("ClinicIDForHomepage");
  const subclinic_id = localStorage.getItem("SubClinicIDForHomepage");
  const doctor_name = localStorage.getItem("DoctorUserNameDuringRegistration");
  const diseasename = localStorage.getItem("DiseaseSpecialistName");

  const [response, setresponse] = useState("");
  const [country, setcountry] = useState("");
  const [region, setregion] = useState("");
  const [opentime, setopentime] = useState("");
  const [closetime, setclosetime] = useState("");
  const [disease_specialist, setdisease_specialist] = useState("");
  const [days_of_operation, setdays_of_operation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [YearsOfExperience, setYearsOfExperience] = useState("");

  const navigate = useNavigate();

  const [posts, setPosts] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");

  const [opentwo, setOPentwo] = useState(false);
  const toggletwo = () => {
    setOPentwo(!opentwo);
  };

  const [submitLoading, setsubmitLoading] = useState(false);
  const is_clinic = localStorage.getItem("is_clinic");

  const addclinicextradetais = async (e) => {
    e.preventDefault();
    setsubmitLoading(true);

    await Apiservice
      .post(
        // `https://djangoobesitychat.azurewebsites.net/user/doctorpostapi/`,
        `/user/doctorpostapi/`,

        {
          clinic_id: clinic_ID,
          subclinic_id: subclinic_id,
          doctor_name: doctor_name,
          country: country,
          region: region,
          in_time: opentime,
          out_time: closetime,
          days_of_operation: days_of_operation,
          disease_specialist: diseasename,
          YearsOfExperience: YearsOfExperience,
        }
      )
      .then((res) => {
        // // //console.log(res);
        setsubmitLoading(false);

        // navigate("/clinicsidebar/clinicserviseproviderlist");

        if (is_clinic === "true") {
          navigate("/clinicsidebar/clinicserviseproviderlist");
        } else {
          navigate("/subclinicsidebar/subclinicserviseproviderlist");
        }
      })
      .catch((res) => {
        setsubmitLoading(false);
        setresponse(res.response.data);
      });
  };

  // function handleChange(event) {
  //   setdays_of_operation([...days_of_operation, event.target.value]);
  // }

  function handleChange(event) {
    const { value, checked } = event.target;
    if (value === "8") {
      // If the "All" checkbox is checked, set all other checkboxes to checked
      const allCheckboxes = document.querySelectorAll(
        '.toggle-drop-down-in-service-sub input[type="checkbox"]'
      );
      allCheckboxes.forEach((checkbox) => {
        checkbox.checked = checked;
      });
    } else {
      // If any other checkbox is checked/unchecked, update the state accordingly
      setdays_of_operation((prevDays) => {
        if (checked) {
          return [...prevDays, value];
        } else {
          return prevDays.filter((day) => day !== value);
        }
      });
    }
  }

  useEffect(() => {
    const loadPosts = async () => {
      // setLoading(true);
      const response = await Apiservice.get(
        // `https://djangoobesitychat.azurewebsites.net/diseaseapi/`
        `/diseaseapi/`
      );
      setPosts(response.data);
      // // //console.log("diseasename",response.data);
    };
    loadPosts();
  }, []);

  // //console.log("Disease",posts)

  return (
    <div className="modal">
      {submitLoading && (
        <div className="purchase-order-details-pop-up-main-div">
          <div className="purchase-order-details-pop-up-sub-div">
            <div
              className="purchase-order-details-pop-up-sub-div-container"
              style={{ background: "none" }}
            >
              <Loader />
            </div>
          </div>
        </div>
      )}
      <div className="patient-extra-details-width-div">
        <div className="login-page-sub-one-sec-one">
          <h4>obesity tool</h4>
          <p>by sk pharma</p>
        </div>
        <div className="patient-extra-details-main-div">
          <h3>Welcome to the Obesity Tool {clinic_name}</h3>
        </div>
        <div className="patient-extra-details-main-div">
          <p>Please Fill the below Details to Proceed Further !!!</p>
        </div>
        <div className="input_container">
          <label>Doctor Username
          <div className="input_field" style={{ minHeight: "33px" }}>
            {doctor_name}
          </div>
          </label>
        </div>
        <div className="input_container">
          <label>Clinic Username
          <div className="input_field" style={{ minHeight: "33px" }}>
            {clinic_name}
          </div>
          </label>
        </div>
        <div className="input_container">
          <label>Sub Clinic Username
          <div className="input_field" style={{ minHeight: "33px" }}>
            {subclinic_id}
          </div>
          </label>
        </div>

        <div className="input_container">
          <label>State
          <div className="input_field">
            <input
            style={{width: '100%',height:'100%'}}
              type={"text"}
              placeholder="State"
              value={disease_specialist}
              onChange={(e) => setdisease_specialist(e.target.value)}
            />
          </div>
          </label>
        </div>

        <div className="input_container">
          <label>Country
          <div className="input_field">
            <select
            style={{width: '100%',height:'100%'}}
              className="select-country-input-tag"
              id="country"
              name="country"
              value={country}
              onChange={(e) => setcountry(e.target.value)}
            >
              <option>Select Country</option>
              <option value="AF">Afghanistan</option>
              <option value="AX">Aland Islands</option>
              <option value="AL">Albania</option>
              <option value="DZ">Algeria</option>
              <option value="AS">American Samoa</option>
              <option value="AD">Andorra</option>
              <option value="AO">Angola</option>
              <option value="AI">Anguilla</option>
              <option value="AQ">Antarctica</option>
              <option value="AG">Antigua and Barbuda</option>
              <option value="AR">Argentina</option>
              <option value="AM">Armenia</option>
              <option value="AW">Aruba</option>
              <option value="AU">Australia</option>
              <option value="AT">Austria</option>
              <option value="AZ">Azerbaijan</option>
              <option value="BS">Bahamas</option>
              <option value="BH">Bahrain</option>
              <option value="BD">Bangladesh</option>
              <option value="BB">Barbados</option>
              <option value="BY">Belarus</option>
              <option value="BE">Belgium</option>
              <option value="BZ">Belize</option>
              <option value="BJ">Benin</option>
              <option value="BM">Bermuda</option>
              <option value="BT">Bhutan</option>
              <option value="BO">Bolivia</option>
              <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
              <option value="BA">Bosnia and Herzegovina</option>
              <option value="BW">Botswana</option>
              <option value="BV">Bouvet Island</option>
              <option value="BR">Brazil</option>
              <option value="IO">British Indian Ocean Territory</option>
              <option value="BN">Brunei Darussalam</option>
              <option value="BG">Bulgaria</option>
              <option value="BF">Burkina Faso</option>
              <option value="BI">Burundi</option>
              <option value="KH">Cambodia</option>
              <option value="CM">Cameroon</option>
              <option value="CA">Canada</option>
              <option value="CV">Cape Verde</option>
              <option value="KY">Cayman Islands</option>
              <option value="CF">Central African Republic</option>
              <option value="TD">Chad</option>
              <option value="CL">Chile</option>
              <option value="CN">China</option>
              <option value="CX">Christmas Island</option>
              <option value="CC">Cocos (Keeling) Islands</option>
              <option value="CO">Colombia</option>
              <option value="KM">Comoros</option>
              <option value="CG">Congo</option>
              <option value="CD">
                Congo, Democratic Republic of the Congo
              </option>
              <option value="CK">Cook Islands</option>
              <option value="CR">Costa Rica</option>
              <option value="CI">Cote D'Ivoire</option>
              <option value="HR">Croatia</option>
              <option value="CU">Cuba</option>
              <option value="CW">Curacao</option>
              <option value="CY">Cyprus</option>
              <option value="CZ">Czech Republic</option>
              <option value="DK">Denmark</option>
              <option value="DJ">Djibouti</option>
              <option value="DM">Dominica</option>
              <option value="DO">Dominican Republic</option>
              <option value="EC">Ecuador</option>
              <option value="EG">Egypt</option>
              <option value="SV">El Salvador</option>
              <option value="GQ">Equatorial Guinea</option>
              <option value="ER">Eritrea</option>
              <option value="EE">Estonia</option>
              <option value="ET">Ethiopia</option>
              <option value="FK">Falkland Islands (Malvinas)</option>
              <option value="FO">Faroe Islands</option>
              <option value="FJ">Fiji</option>
              <option value="FI">Finland</option>
              <option value="FR">France</option>
              <option value="GF">French Guiana</option>
              <option value="PF">French Polynesia</option>
              <option value="TF">French Southern Territories</option>
              <option value="GA">Gabon</option>
              <option value="GM">Gambia</option>
              <option value="GE">Georgia</option>
              <option value="DE">Germany</option>
              <option value="GH">Ghana</option>
              <option value="GI">Gibraltar</option>
              <option value="GR">Greece</option>
              <option value="GL">Greenland</option>
              <option value="GD">Grenada</option>
              <option value="GP">Guadeloupe</option>
              <option value="GU">Guam</option>
              <option value="GT">Guatemala</option>
              <option value="GG">Guernsey</option>
              <option value="GN">Guinea</option>
              <option value="GW">Guinea-Bissau</option>
              <option value="GY">Guyana</option>
              <option value="HT">Haiti</option>
              <option value="HM">Heard Island and Mcdonald Islands</option>
              <option value="VA">Holy See (Vatican City State)</option>
              <option value="HN">Honduras</option>
              <option value="HK">Hong Kong</option>
              <option value="HU">Hungary</option>
              <option value="IS">Iceland</option>
              <option value="IN">India</option>
              <option value="ID">Indonesia</option>
              <option value="IR">Iran, Islamic Republic of</option>
              <option value="IQ">Iraq</option>
              <option value="IE">Ireland</option>
              <option value="IM">Isle of Man</option>
              <option value="IL">Israel</option>
              <option value="IT">Italy</option>
              <option value="JM">Jamaica</option>
              <option value="JP">Japan</option>
              <option value="JE">Jersey</option>
              <option value="JO">Jordan</option>
              <option value="KZ">Kazakhstan</option>
              <option value="KE">Kenya</option>
              <option value="KI">Kiribati</option>
              <option value="KP">Korea, Democratic People's Republic of</option>
              <option value="KR">Korea, Republic of</option>
              <option value="XK">Kosovo</option>
              <option value="KW">Kuwait</option>
              <option value="KG">Kyrgyzstan</option>
              <option value="LA">Lao People's Democratic Republic</option>
              <option value="LV">Latvia</option>
              <option value="LB">Lebanon</option>
              <option value="LS">Lesotho</option>
              <option value="LR">Liberia</option>
              <option value="LY">Libyan Arab Jamahiriya</option>
              <option value="LI">Liechtenstein</option>
              <option value="LT">Lithuania</option>
              <option value="LU">Luxembourg</option>
              <option value="MO">Macao</option>
              <option value="MK">
                Macedonia, the Former Yugoslav Republic of
              </option>
              <option value="MG">Madagascar</option>
              <option value="MW">Malawi</option>
              <option value="MY">Malaysia</option>
              <option value="MV">Maldives</option>
              <option value="ML">Mali</option>
              <option value="MT">Malta</option>
              <option value="MH">Marshall Islands</option>
              <option value="MQ">Martinique</option>
              <option value="MR">Mauritania</option>
              <option value="MU">Mauritius</option>
              <option value="YT">Mayotte</option>
              <option value="MX">Mexico</option>
              <option value="FM">Micronesia, Federated States of</option>
              <option value="MD">Moldova, Republic of</option>
              <option value="MC">Monaco</option>
              <option value="MN">Mongolia</option>
              <option value="ME">Montenegro</option>
              <option value="MS">Montserrat</option>
              <option value="MA">Morocco</option>
              <option value="MZ">Mozambique</option>
              <option value="MM">Myanmar</option>
              <option value="NA">Namibia</option>
              <option value="NR">Nauru</option>
              <option value="NP">Nepal</option>
              <option value="NL">Netherlands</option>
              <option value="AN">Netherlands Antilles</option>
              <option value="NC">New Caledonia</option>
              <option value="NZ">New Zealand</option>
              <option value="NI">Nicaragua</option>
              <option value="NE">Niger</option>
              <option value="NG">Nigeria</option>
              <option value="NU">Niue</option>
              <option value="NF">Norfolk Island</option>
              <option value="MP">Northern Mariana Islands</option>
              <option value="NO">Norway</option>
              <option value="OM">Oman</option>
              <option value="PK">Pakistan</option>
              <option value="PW">Palau</option>
              <option value="PS">Palestinian Territory, Occupied</option>
              <option value="PA">Panama</option>
              <option value="PG">Papua New Guinea</option>
              <option value="PY">Paraguay</option>
              <option value="PE">Peru</option>
              <option value="PH">Philippines</option>
              <option value="PN">Pitcairn</option>
              <option value="PL">Poland</option>
              <option value="PT">Portugal</option>
              <option value="PR">Puerto Rico</option>
              <option value="QA">Qatar</option>
              <option value="RE">Reunion</option>
              <option value="RO">Romania</option>
              <option value="RU">Russian Federation</option>
              <option value="RW">Rwanda</option>
              <option value="BL">Saint Barthelemy</option>
              <option value="SH">Saint Helena</option>
              <option value="KN">Saint Kitts and Nevis</option>
              <option value="LC">Saint Lucia</option>
              <option value="MF">Saint Martin</option>
              <option value="PM">Saint Pierre and Miquelon</option>
              <option value="VC">Saint Vincent and the Grenadines</option>
              <option value="WS">Samoa</option>
              <option value="SM">San Marino</option>
              <option value="ST">Sao Tome and Principe</option>
              <option value="SA">Saudi Arabia</option>
              <option value="SN">Senegal</option>
              <option value="RS">Serbia</option>
              <option value="CS">Serbia and Montenegro</option>
              <option value="SC">Seychelles</option>
              <option value="SL">Sierra Leone</option>
              <option value="SG">Singapore</option>
              <option value="SX">Sint Maarten</option>
              <option value="SK">Slovakia</option>
              <option value="SI">Slovenia</option>
              <option value="SB">Solomon Islands</option>
              <option value="SO">Somalia</option>
              <option value="ZA">South Africa</option>
              <option value="GS">
                South Georgia and the South Sandwich Islands
              </option>
              <option value="SS">South Sudan</option>
              <option value="ES">Spain</option>
              <option value="LK">Sri Lanka</option>
              <option value="SD">Sudan</option>
              <option value="SR">Suriname</option>
              <option value="SJ">Svalbard and Jan Mayen</option>
              <option value="SZ">Swaziland</option>
              <option value="SE">Sweden</option>
              <option value="CH">Switzerland</option>
              <option value="SY">Syrian Arab Republic</option>
              <option value="TW">Taiwan, Province of China</option>
              <option value="TJ">Tajikistan</option>
              <option value="TZ">Tanzania, United Republic of</option>
              <option value="TH">Thailand</option>
              <option value="TL">Timor-Leste</option>
              <option value="TG">Togo</option>
              <option value="TK">Tokelau</option>
              <option value="TO">Tonga</option>
              <option value="TT">Trinidad and Tobago</option>
              <option value="TN">Tunisia</option>
              <option value="TR">Turkey</option>
              <option value="TM">Turkmenistan</option>
              <option value="TC">Turks and Caicos Islands</option>
              <option value="TV">Tuvalu</option>
              <option value="UG">Uganda</option>
              <option value="UA">Ukraine</option>
              <option value="AE">United Arab Emirates</option>
              <option value="GB">United Kingdom</option>
              <option value="US">United States</option>
              <option value="UM">United States Minor Outlying Islands</option>
              <option value="UY">Uruguay</option>
              <option value="UZ">Uzbekistan</option>
              <option value="VU">Vanuatu</option>
              <option value="VE">Venezuela</option>
              <option value="VN">Viet Nam</option>
              <option value="VG">Virgin Islands, British</option>
              <option value="VI">Virgin Islands, U.s.</option>
              <option value="WF">Wallis and Futuna</option>
              <option value="EH">Western Sahara</option>
              <option value="YE">Yemen</option>
              <option value="ZM">Zambia</option>
              <option value="ZW">Zimbabwe</option>
            </select>
          </div>
          </label>
        </div>
        <div className="input_container">
          <label>Region
          <div
            className="input_field"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            {" "}
            <div className="toggle-drop-down-in-service-sub">
              <div>
                <input
                  type="radio"
                  name="Region"
                  value="EU"
                  onChange={(e) => setregion(e.target.value)}
                />
              </div>
              <div>
                <p>EU</p>
              </div>
            </div>
            <div className="toggle-drop-down-in-service-sub">
              <div>
                <input
                  type="radio"
                  name="Region"
                  value="US"
                  onChange={(e) => setregion(e.target.value)}
                />
              </div>
              <div>
                <p>US</p>
              </div>
            </div>
            <div className="toggle-drop-down-in-service-sub">
              <div>
                <input
                  type="radio"
                  name="Region"
                  value="LATAM"
                  onChange={(e) => setregion(e.target.value)}
                />
              </div>
              <div>
                <p>LATAM</p>
              </div>
            </div>
            <div className="toggle-drop-down-in-service-sub">
              <div>
                <input
                  type="radio"
                  name="Region"
                  value="APAC"
                  onChange={(e) => setregion(e.target.value)}
                />
              </div>
              <div>
                <p>APAC</p>
              </div>
            </div>
            <div className="toggle-drop-down-in-service-sub">
              <div>
                <input
                  type="radio"
                  name="Region"
                  value="MIDDLE EAST"
                  onChange={(e) => setregion(e.target.value)}
                />
              </div>
              <div>
                <p>MIDDLE EAST</p>
              </div>
            </div>
          </div>
          </label>
        </div>

        <div className="input_container">
          <div style={{ width: "50%" }}></div>
          <div
            style={{
              width: "50%",
              color: "red",
              margin: "0px",
              fontSize: "13px",
            }}
          >
            <p>{response && response.region}</p>
          </div>
        </div>

        <div className="input_container">
          <label>In Time
          <div className="input_field">
            <input
            style={{width: '100%',height:'100%'}}
              type={"time"}
              placeholder="Open Time"
              value={opentime}
              onChange={(e) => setopentime(e.target.value)}
            />
          </div>
          </label>
        </div>

        <div className="input_container">
          <div style={{ width: "50%" }}></div>
          <div
            style={{
              width: "50%",
              color: "red",
              margin: "0px",
              fontSize: "13px",
            }}
          >
            <p>{response && response.in_time}</p>
          </div>
        </div>

        <div className="input_container">
          <label>Out Time
          <div className="input_field">
            <input
            style={{width: '100%',height:'100%'}}
              type={"time"}
              placeholder="Close Time"
              value={closetime}
              onChange={(e) => setclosetime(e.target.value)}
            />
          </div>
          </label>
        </div>

        <div className="input_container">
          <div style={{ width: "50%" }}></div>
          <div
            style={{
              width: "50%",
              color: "red",
              margin: "0px",
              fontSize: "13px",
            }}
          >
            <p>{response && response.out_time}</p>
          </div>
        </div>

        <div className="input_container">
          <label>Search Diseases
          <div className="input_field">
            <input
            style={{width: '100%', height:'100%'}}
              type="text"
              placeholder="Search Disease..."
              value={searchTitle}
              // className="search-patient-in-appt-inp-tag"
              onClick={toggletwo}
              onChange={(e) => setSearchTitle(e.target.value)}
            />
            {opentwo && (
              <div className="list-of-all-user" onClick={toggletwo}>
                {loading ? (
                  <div style={{ display: "none" }}></div>
                ) : (
                  posts
                    .filter((value) => {
                      if (searchTitle === "") {
                        return posts;
                      } else if (
                        value.disease_name
                          ?.toLowerCase()
                          .includes(searchTitle?.toLowerCase())
                      ) {
                        return value;
                      }
                    })
                    .map((item) => (
                      <div className="list-of-user-main-flex-div">
                        <div className="list-of-user-main-div">
                          <p
                            onClick={() =>
                              localStorage.setItem(
                                "DiseaseSpecialistName",
                                item.disease_name
                              )
                            }
                          >
                            {item.disease_name}
                          </p>
                        </div>
                      </div>
                    ))
                )}
              </div>
            )}
          </div>
          </label>
          <label> Selected Disease
          <div className="input_field" style={{ minHeight: "33px" }}>
            {diseasename}
          </div>
          </label>
        </div>

        <div className="input_container">
          <label>Years of Experience
          <div className="input_field">
            <input
            style={{width: '100%',height:'100%'}}
              type={"number"}
              placeholder="Years Of Experience"
              value={YearsOfExperience}
              onChange={(e) => setYearsOfExperience(e.target.value)}
            />
          </div>
          </label>
        </div>

        <div className="input_container">
          <label>Days of Operation
          <div className="pat-extra-sub-two" style={{ minHeight: "33px" }}>
            <div>
              <div className="toggle-drop-down-in-service-sub">
                <div>
                  <input
                    type="checkbox"
                    // name="Region"
                    value={8}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <p>All</p>
                </div>
              </div>
              <div className="toggle-drop-down-in-service-sub">
                <div>
                  <input
                    type="checkbox"
                    // name="Region"
                    value={1}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <p>Monday</p>
                </div>
              </div>
              <div className="toggle-drop-down-in-service-sub">
                <div>
                  <input
                    type="checkbox"
                    // name="Region"
                    value={2}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <p>Tuesday</p>
                </div>
              </div>
              <div className="toggle-drop-down-in-service-sub">
                <div>
                  <input
                    type="checkbox"
                    // name="Region"
                    value={3}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <p>Wednesday</p>
                </div>
              </div>

              <div className="toggle-drop-down-in-service-sub">
                <div>
                  <input
                    type="checkbox"
                    // name="Region"
                    value={4}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <p>Thursday</p>
                </div>
              </div>
              <div className="toggle-drop-down-in-service-sub">
                <div>
                  <input
                    type="checkbox"
                    // name="Region"
                    value={5}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <p>Friday</p>
                </div>
              </div>
              <div className="toggle-drop-down-in-service-sub">
                <div>
                  <input
                    type="checkbox"
                    // name="Region"
                    value={6}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <p>Saturday</p>
                </div>
              </div>
              <div className="toggle-drop-down-in-service-sub">
                <div>
                  <input
                    type="checkbox"
                    // name="Region"
                    value={7}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <p>Sunday</p>
                </div>
              </div>
            </div>
          </div>
          </label>
        </div>

        <div className="patient-extra-details-main-div-one">
          <div style={{ width: "50%" }}></div>
          <div
            style={{
              width: "50%",
              color: "red",
              margin: "0px",
              fontSize: "13px",
            }}
          >
            <p>{response && response.days_of_operation}</p>
          </div>
        </div>

        <div className="add-pat-btn-div">
          <button onClick={addclinicextradetais} className="add-btn-btn-tag">
            SUBMIT
          </button>
        </div>
      </div>
    </div>
  );
};

export default DoctorExtraDetails;
