import React, { useEffect, useState } from "react";
import axios from "axios";
import "./PersonalPatientFile.css";
import { FaAngleDown } from "react-icons/fa";
import WeightForcasting from "../WeightForcasting/WeightForcasting";
import MedicalHistory from "../MedicalHistory/MedicalHistory";
import PatientEvents from "../PatientEvents/PatientEvents";
import PatientDocuments from "../PatientDocuments/PatientDocuments";
import PatientHistory from "../PatientHistory/PatientHistory";
import QuestionnaireNew from "../Questionnaire/QuestionnaireNew";
import oneThirtykg from "../../Images/WeightImages/OneHundredThirtyKg.svg";
import oneTwentykg from "../../Images/WeightImages/OneHundredTwentyKg.svg";
import onehundreadkg from "../../Images/WeightImages/HundredKg.svg";
import nintykg from "../../Images/WeightImages/NinetyKg.svg";
import MnintyClothes from "../../Images/WeightImages/ninetyMclothes.svg";
import MhundreadClothes from "../../Images/WeightImages/hundreadMclothes.svg";
import MhundreadtenClothes from "../../Images/WeightImages/hundreadtenMclothes.svg";
import MhundreadtenplusClothes from "../../Images/WeightImages/hundreadtenplusMclothes.svg";
import FnintyClothes from "../../Images/WeightImages/ninetyFClothes.svg";
import FhundreadClothes from "../../Images/WeightImages/hundreadFClothes.svg";
import FhundreadtenClothes from "../../Images/WeightImages/hundreadtenFClothes.svg";
import FhundreadtenplusClothes from "../../Images/WeightImages/hundreadtenplusFClothes.svg";
import FnintyWithoutClothes from "../../Images/WeightImages/ninetyFWithout.svg";
import FhundreadWithoutClothes from "../../Images/WeightImages/hundreadFWithout.svg";
import FhundreadtenWithoutClothes from "../../Images/WeightImages/hundreadtenFWithout.svg";
import FhundreadtenplusWithoutClothes from "../../Images/WeightImages/hundreadtenplusFWithout.svg";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { Link } from "react-router-dom";
import PatientEventsForDoctor from "../PatientEvents/PatientEventsForDoctor";
import PatientHistoryForDoctorClinic from "../PatientHistory/PatientHistoryForDoctorClinic";
import DoctorPatientDocuments from "../DoctorPatientDocuments/DoctorPatientDocuments";
import moment from "moment";
// import ReactWhatsapp from "react-whatsapp";
import { BsWhatsapp } from "react-icons/bs";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import Loader from "../Loader/Loader";
import QuestionnairePutRequest from "../QuestionnairePutRequest/QuestionnairePutRequest";
import Apiservice from "../../Services/Apiservice";
import swal from "sweetalert";

const PersonalPatientFile = () => {
  const [HealthOverview, setHealthOverview] = useState(true);
  const [HealthDetails, setHealthDetails] = useState(false);
  const [PatientEventsPage, setPatientEventsPage] = useState(false);
  const [PatientDocumentsPage, setPatientDocumentsPage] = useState(false);
  const [PatientHistoryPage, setPatientHistoryPage] = useState(false);
  const [isMedActive, setIsMedActive] = useState(true);
  const [isQuesActive, setIsQuesActive] = useState(false);
  const [isEventsActive, setIsEventsActive] = useState(false);
  const [isDocumentActive, setIsDocumentActive] = useState(false);
  const [isHistoryActive, setIsHistoryActive] = useState(false);
  const [imageweight, setImageweight] = useState(0);
  // const [imageweight, setImageweight] = useState(userdetails.current_weight);

  const [childData, setChildData] = useState({
    dataset: "",
    dataindex: "",
    value: 0,
  });
  const [isClicked, setIsClicked] = useState(false);

  const [male, setMale] = useState(true);
  const [female, setFemale] = useState(false);
  const [malewithclothes, setMalewithclothes] = useState(true);
  const [malewithoutclothes, setMalewithoutclothes] = useState(false);
  const [femalewithclothes, setFemalewithclothes] = useState(true);
  const [femalewithoutclothes, setFemalewithoutclothes] = useState(false);
  const [isMaleActive, setIsMaleActive] = useState(true);
  const [isFemaleActive, setIsFemaleActive] = useState(false);
  const [isClothesActive, setIsClothesActive] = useState(true);
  const [isWithoutClothesActive, setIsWithoutClothesActive] = useState(false);

  const Male = () => {
    setMale(true);
    setFemale(false);
    setIsMaleActive(true);
    setIsFemaleActive(false);
  };
  const Female = () => {
    setFemale(true);
    setMale(false);
    setIsMaleActive(false);
    setIsFemaleActive(true);
  };

  const MalewithclothesHandler = () => {
    setMalewithclothes(true);
    setMalewithoutclothes(false);
    setIsClothesActive(true);
    setIsWithoutClothesActive(false);
  };
  const MalewithoutclothesHandler = () => {
    setMalewithclothes(false);
    setMalewithoutclothes(true);
    setIsClothesActive(false);
    setIsWithoutClothesActive(true);
  };
  const FemalewithclothesHandler = () => {
    setFemalewithclothes(true);
    setFemalewithoutclothes(false);
    setIsClothesActive(true);
    setIsWithoutClothesActive(false);
  };
  const FemalewithoutclothesHandler = () => {
    setFemalewithclothes(false);
    setFemalewithoutclothes(true);
    setIsClothesActive(false);
    setIsWithoutClothesActive(true);
  };

  const HealthOverviewHandler = () => {
    setIsMedActive(true);
    setIsQuesActive(false);
    setIsEventsActive(false);
    setIsDocumentActive(false);
    setIsHistoryActive(false);
    setHealthOverview(true);
    setHealthDetails(false);
    setPatientEventsPage(false);
    setPatientDocumentsPage(false);
    setPatientHistoryPage(false);
  };

  const HealthDetailsHandler = () => {
    setIsMedActive(false);
    setIsQuesActive(true);
    setIsEventsActive(false);
    setIsDocumentActive(false);
    setIsHistoryActive(false);
    setHealthOverview(false);
    setHealthDetails(true);
    setPatientEventsPage(false);
    setPatientDocumentsPage(false);
    setPatientHistoryPage(false);
  };
  const PatientEventsPageHandler = () => {
    setIsEventsActive(true);
    setIsMedActive(false);
    setIsQuesActive(false);
    setIsDocumentActive(false);
    setIsHistoryActive(false);
    setHealthOverview(false);
    setHealthDetails(false);
    setPatientEventsPage(true);
    setPatientDocumentsPage(false);
    setPatientHistoryPage(false);
  };
  const PatientDocumentsPageHandler = () => {
    setIsEventsActive(false);
    setIsMedActive(false);
    setIsQuesActive(false);
    setIsDocumentActive(true);
    setIsHistoryActive(false);
    setHealthOverview(false);
    setHealthDetails(false);
    setPatientEventsPage(false);
    setPatientDocumentsPage(true);
    setPatientHistoryPage(false);
  };
  const PatientHistoryPageHandler = () => {
    setIsEventsActive(false);
    setIsMedActive(false);
    setIsQuesActive(false);
    setIsDocumentActive(false);
    setIsHistoryActive(true);
    setHealthOverview(false);
    setHealthDetails(false);
    setPatientEventsPage(false);
    setPatientDocumentsPage(false);
    setPatientHistoryPage(true);
  };

  // Doughnut Popup
  const [popup, setPop] = useState(false);
  const handleClickOpen = () => {
    setPop(!popup);
  };

  const closePopup = () => {
    setPop(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", () => {
      setPop(false);
    });
  });

  // Contradictions Popup
  const [contraPopup, setContraPopup] = useState(false);
  const onClickOpen = () => {
    setContraPopup(!contraPopup);
  };

  const closeContraPopup = () => {
    setContraPopup(false);
  };

  const [ShowBMIbyWeek, setShowBMIbyWeek] = useState(false);
  const toggleShowBMIbyWeek = () => {
    setShowBMIbyWeek(!ShowBMIbyWeek);
  };

  // Current Date
  const currentdate = new Date();
  const weekDay = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const day = weekDay[currentdate.getDay()];
  const month = months[currentdate.getMonth()];
  const date = currentdate.getDate();
  const year = currentdate.getFullYear();
  const isdietitian = localStorage.getItem("is_dietitian");
  const is_trainer = localStorage.getItem("is_trainer");
  const is_doctor = localStorage.getItem("is_doctor");

  const PatientIDforRedirect = localStorage.getItem("PatientID");
  const patientUserName = localStorage.getItem("patientUserName");
  const [patientBmiHistory, setPatientBmiHistory] = useState();
  const [patientdetails, setpatientdetails] = useState([]);
  const savedtoken = localStorage.getItem("SavedToken");
  const [userdetails, setuserdetails] = useState([]);
  const [weightdetails, setweightdetails] = useState("");

  const [loginUserDetails, setLoginUserDetails] = useState("");
  useEffect(() => {
    Apiservice
      .get(`/user/patientapi/${patientUserName}/`)
      .then((responseone) => {
        setLoginUserDetails(responseone.data[0]);
        // // // //console.log("patientapi", responseone.data);
      })
      .catch((err) => {});
  }, [patientUserName]);

  // const imageweight = {userdetails.current_weight}

  useEffect(() => {
    Apiservice
      .get(
        // `https://djangoobesitychat.azurewebsites.net/patientapi/update/${PatientIDforRedirect}/`,
        `/user/patientapi/update/${PatientIDforRedirect}/`,
        {
          headers: {
            Authorization: `Token ${savedtoken}`,
          },
        }
      )
      .then((response) => {
        setuserdetails(response.data);
        // // // //console.log(response);
      })
      .catch((err) => {
        // // // //console.log(err);
      });
  }, [PatientIDforRedirect]);
  // const [imageweight, setImageweight] = useState(userdetails.current_weight);

  const patientGender = userdetails.gender;
  // // // //console.log("patientGender",patientGender)

  const UserImagePath = userdetails.image;
  // // // //console.log("UserImagePath",UserImagePath)
  const filename = UserImagePath?.split('/').pop();
  const spliturltwo = UserImagePath && UserImagePath?.slice(6);

  const spliturlthree = spliturltwo && spliturltwo?.slice(0, -4) + "_avatar_" + (weightdetails?.current_weight || childData.value) + "kgs.jpeg";
  // const [imageFile, setImageFile] = useState(spliturlthree ? `https://djangoobesitychat.azurewebsites.net${spliturlthree}` : null);

  const [imageFile, setImageFile] = useState(null);

useEffect(() => {
  if (spliturlthree) {
    setImageFile(`https://djangoobesitychat.azurewebsites.net${spliturlthree}`);
  }
}, [spliturlthree]);

  const handleImageChange = (e) => {
    setImageFile(`https://djangoobesitychat.azurewebsites.net${spliturlthree}`);
  }
  console.log("imageFile", imageFile)


  const childDatavalue = localStorage.getItem("childDatavalue");
  let getweight = childDatavalue
    ? childDatavalue
    : weightdetails && weightdetails.current_weight;

  if (isClicked) {
    localStorage.setItem("childDatavalue", childData.value);
    setIsClicked(false);
  }

  //BMI API for Patient Personal
  const patientUsername = localStorage.getItem("patientUserName");
  useEffect(() => {
    Apiservice
      .get(`/user/allwighthistory/${patientUsername}/`)
      .then((response) => {
        // // //console.log("BMI Data in Personal PatientFile", response.data);
        setPatientBmiHistory(response.data);
      })
      .catch((err) => {
        // // //console.log(err);
      });
  }, [patientUserName]);

  const dateParsed =
    patientBmiHistory &&
    patientBmiHistory.map((index) =>
      moment(index.updatedate, "MMM DD YYYY HH:mm A").valueOf()
    );

  // const sortedData = patientBmiHistory && patientBmiHistory.sort((a, b) => moment(b.updatedate, "MMM DD YYYY HH:mm A").valueOf() - moment(a.updatedate, "MMM DD YYYY HH:mm A").valueOf());
  const sortedData =
    patientBmiHistory && patientBmiHistory.sort((a, b) => a.id - b.id);

  /* Creating a variable called currentWeek and setting it equal to the current week of the year. */
  const currentWeek = moment().week();

  const currentMonth = moment().month();


  /* Filtering the patientBmiHistory array to only include the weeks that are less than or equal to the
  current week. */
  const filtered =
    patientBmiHistory &&
    patientBmiHistory.filter((index) => index.week <= currentWeek);

  const filteredbyMonth =
    patientBmiHistory &&
    patientBmiHistory.filter((index) => index.month <= currentMonth);

  const dataByWeek =
    filtered &&
    filtered.reduce((acc, item) => {
      acc[item.week] = item;
      return acc;
    }, {});

  const dataByMonth =
    filteredbyMonth &&
    filteredbyMonth.reduce((acc, item) => {
      acc[item.month] = item;
      return acc;
    }, {});

  //CARD for patient BMI
  const Card = ({ data }) => {
    const {
      id,
      user,
      activity_type,
      BMI,
      changes,
      date,
      week,
      month,
      updated_at,
    } = data;
    // const BMI = changes && changes.BMI;
    const BMII = BMI;
    const sweek = moment(patientBmiHistory[0].date).month();
    

    return (
      <div className="week-section-main-div">
        <div className="week-section-sub-div-one">
          <p className="week-para" style={{ textAlign: "center" }}>
            Month {month - (sweek - 1)}
          </p>
          <div className="bmi-div-btn">
            <p>{Math.round(BMII)} BMI</p>
          </div>
        </div>
      </div>
    );
  };

  const CardbyWeek = ({ data }) => {
    const {
      id,
      user,
      activity_type,
      BMI,
      changes,
      date,
      week,
      month,
      updated_at,
    } = data;
    // const BMI = changes && changes.BMI;
    const BMII = BMI;
    const sweek = moment(patientBmiHistory[0].date).week();

    // // //console.log("data is::",data)
    // // //console.log("BMI in Card is", BMI)

    return (
      <div className="week-section-main-div">
        <div className="week-section-sub-div-one">
          <p className="week-para" style={{ textAlign: "center" }}>
            Week {week - (sweek - 1)}
          </p>
          <div className="bmi-div-btn">
            <p>{Math.round(BMII)} BMI</p>
          </div>
        </div>
      </div>
    );
  };

  const Username = localStorage.getItem("Username");
  const [mainDataLoading, setmainDataLoading] = useState(true);

  useEffect(() => {
    Apiservice
      .get(`/user/patientapi/${patientUserName}/`)
      .then((responseone) => {
        setLoginUserDetails(responseone.data[0]);
        setmainDataLoading(false);
      })
      .catch((err) => {});
  }, [Username]);
  // // //console.log("data is::",data)
  // // //console.log("BMI in Card is", BMI)
  const addweight = async (e) => {
    e.preventDefault();
    setmainDataLoading(true);
    await Apiservice
      .post(
        // `https://djangoobesitychat.azurewebsites.net/user/patientapi/update2/${Username}/`,
        `/user/currentwight/post/`,
        {
          patient_name: patientUserName,
          current_weight: imageweight,
          height: loginUserDetails.height,
          gender: loginUserDetails.gender,
          age: loginUserDetails.age,
          // clinic_id: loginUserDetails.clinic_id,
          // subclinic_id: loginUserDetails.subclinic_id,
        },
        {
          headers: {
            Authorization: `Token ${savedtoken}`,
          },
        }
      )
      .then((res) => {
        swal({
          title: "Your Weight has been Submitted Successfully",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });
        Apiservice
          .get(`/user/allwighthistory/${patientUserName}/`)
          .then((response) => {
            setweightdetails(response.data[response.data.length - 1]);
          });
        setmainDataLoading(false);
      })
      .catch((err) => {
        setmainDataLoading(false);
      });
  };
  useEffect(() => {
    Apiservice
      .get(`/user/allwighthistory/${patientUserName}/`)
      .then((response) => {
        setweightdetails(response.data[response.data.length - 1]);
      })
      .catch((err) => {});
  }, [patientUserName]);
  // // //console.log("weightdetails", weightdetails);
  // const getweight = weightdetails && weightdetails.current_weight;

  const [authenticatedpatient, setauthenticatedpatient] = useState(null);
  useEffect(() => {
    const loggedInUserpatient = localStorage.getItem("PatientID");

    if (loggedInUserpatient) {
      setauthenticatedpatient(loggedInUserpatient);
    }
  }, []);
  if (!authenticatedpatient) {
  } else
    return (
      <div className="health-overview-flex-div">
        <div className="health-overview-width-div">
          <div className="health-overview-main-div">
            <div className="health-overview-sub-div-one">
            {is_doctor == "true" ? (
                  <div className="health-overview-sub-one-flex">
                    <div className={isMedActive ? "tab active" : "tab"} onClick={HealthOverviewHandler}>
                    <p className={isMedActive ? "tab-title active" : "tab-title"}>
                        General Overview
                    </p>
                </div>

                <div className={isQuesActive ? "tab active" : "tab"} onClick={HealthDetailsHandler}>
                    <p className={isQuesActive ? "tab-title active" : "tab-title"}>
                        Questionnaire
                    </p>
                </div>

                <div className={isEventsActive ? "tab active" : "tab"} onClick={PatientEventsPageHandler}>
                    <p className={isEventsActive ? "tab-title active" : "tab-title"}>
                        Events
                    </p>
                </div>

                <div className={isDocumentActive ? "tab active" : "tab"} onClick={PatientDocumentsPageHandler}>
                    <p className={isDocumentActive ? "tab-title active" : "tab-title"}>
                        Documents
                    </p>
                </div>
                <div className={isHistoryActive ? "tab active" : "tab"} onClick={PatientHistoryPageHandler}>
                    <p
                      className={isHistoryActive ? "tab-title active" : "tab-title"}
                      onClick={PatientHistoryPageHandler}
                    >
                      History
                    </p>
                    </div>
                  </div>
                ) : (
                  <div
                    className="health-overview-sub-one-flex"
                    style={{ justifyContent: "center", gap: "120px" }}
                  >
                    <div className={isMedActive ? "tab active" : "tab"} onClick={HealthOverviewHandler}>
                    <p className={isMedActive ? "tab-title active" : "tab-title"}>
                        General Overview
                    </p>
                </div>

                <div className={isEventsActive ? "tab active" : "tab"} onClick={PatientEventsPageHandler}>
                    <p className={isEventsActive ? "tab-title active" : "tab-title"}>
                        Events
                    </p>
                </div>
                  </div>
                )}
              <div className="health-overview-sub-div-one-part-one">
                

               

                {HealthOverview && <MedicalHistory />}

                {/*{HealthDetails && <QuestionnaireNew />}*/}
                {HealthDetails && <QuestionnairePutRequest />}
              

                {PatientEventsPage && <PatientEventsForDoctor />}
                {PatientDocumentsPage && <DoctorPatientDocuments />}
                {PatientHistoryPage && <PatientHistoryForDoctorClinic />}

                <div></div>
              </div>
            </div>

            <div className="health-overview-sub-div-two">
              <div className="pateient-main-div-in-patient">
                <div
                  className="patient-img-name-div-in-pateinet-dashboard"
                  style={{ width: "100%" }}
                >
                  <div style={{ textAlign: "center" }}>
                    <img
                      src="https://img.freepik.com/premium-vector/user-icon_126283-435.jpg?w=2000"
                      className="patient-img-logo"
                    />
                  </div>
                  <div className="patient-info-main-div-in-pateint-dashboard">
                  <h4>
                      {loginUserDetails && loginUserDetails.patient_firstName}{" "}
                      {loginUserDetails && loginUserDetails.patient_lastName}
                    </h4>
                    <p>
                      Patient ID : {loginUserDetails && loginUserDetails.id}
                    </p>
                    
                  </div>
                  
                </div>
                <div className="patient-info-in-user-login-main-div">
                    <div className="patient-user-login-main-info">
                      <div className="patient-user-login-main-info-one">
                        <h4 className="pat-inho-h-tag">Gender: </h4>
                      </div>
                      <div className="patient-user-login-main-info-two">
                        <p className="pat-inho-p-tag">
                          {userdetails && userdetails.gender_user}
                        </p>
                      </div>
                    </div>

                    <div className="patient-user-login-main-info">
                      <div className="patient-user-login-main-info-one">
                        <h4 className="pat-inho-h-tag">DOB: </h4>
                      </div>
                      <div className="patient-user-login-main-info-two">
                        <p className="pat-inho-p-tag">
                          {loginUserDetails &&
                            loginUserDetails.patient_date_of_birth}
                        </p>
                      </div>
                    </div>

                    <div className="patient-user-login-main-info">
                      <div className="patient-user-login-main-info-one">
                        <h4 className="pat-inho-h-tag">Age: </h4>
                      </div>
                      <div className="patient-user-login-main-info-two">
                        <p className="pat-inho-p-tag">
                          {userdetails && userdetails.age_user}
                        </p>
                      </div>
                    </div>

                    
                    
                  </div>
                  <div className="patient-user-login-main-info">
                      <div className="patient-user-login-main-info-one">
                        <h4 className="pat-inho-h-tag">Address</h4>
                      </div>
                      <div className="patient-user-login-main-info-two">
                        <p className="pat-inho-p-tag">
                          {loginUserDetails && loginUserDetails.patient_address}
                        </p>
                      </div>
                      <div className="dropdown-in-patient-details">
                      {/* <div>
                        <input
                          type={"month"}
                          className="patient-last-week-btn"
                        />
                      </div> */}
                      <div className="nav-symb-div">
                       { /*<ReactWhatsapp
                          number={
                            loginUserDetails && loginUserDetails.patient_mobile
                          }
                          message="Hello!!!"
                          style={{
                            backgroundColor: "#ecf9ff",
                            border: "none",
                            cursor: "pointer",
                          }}
                        >
                          <BsWhatsapp
                            style={{
                              height: "20px",
                              width: "20px",
                              color: "rgb(188,190,90)",
                            }}
                          />
                          </ReactWhatsapp>*/}
                      </div>
                      <div>
                        {is_doctor === "true" ? (
                          <Link to="/dashboard/chat/obesity/" state={{ data: patientUserName }} >
                            <MessageOutlinedIcon style={{ fill: "#252b4a" }} />
                          </Link>
                        ) : is_trainer === "true" ? (
                          <Link to="/trainersidebar/chat/obesity/" state={{data: patientUserName }}>
                          <MessageOutlinedIcon style={{ fill: "#252b4a" }} />
                          </Link>
                        ) : isdietitian ==="true" ? (
                          <Link to="/dietitiansidebar/chat/obesity/" state={{data: patientUserName }}>
                          <MessageOutlinedIcon style={{ fill: "#252b4a" }} />
                          </Link>
                        ) : (
                          <Link to="/clinicsidebar/chat/obesity/" state={{ data: patientUserName }} >
                            <MessageOutlinedIcon style={{ fill: "#252b4a" }} />
                          </Link>
                        )

                        }
                        
                      </div>
                    </div>
                  </div>
              </div>

              {/*
                <div className="week-section-main-div">
                <div className="week-section-sub-div-one">
                  <p className="week-para">Week 1</p>
                  <div className="bmi-div-btn">
                    <p>88.1 BMI</p>
                  </div>
                </div>
                <div className="week-section-sub-div-one">
                  <p className="week-para">Week 2</p>
                  <div className="bmi-div-btn">
                    <p>88.1 BMI</p>
                  </div>
                </div>
                <div className="week-section-sub-div-one">
                  <p className="week-para">Week 3</p>
                  <div className="bmi-div-btn">
                    <p>88.1 BMI</p>
                  </div>
                </div>
                <div className="week-section-sub-div-one">
                  <p className="week-para">Week 4</p>
                  <div className="bmi-div-btn">
                    <p>88.1 BMI</p>
                  </div>
                </div>
  </div>*/}

              
              <div className="chart-section-main-div">
                <p>Weight Forcasting</p>
                <div className="weight-forcast-graph-div">
                  <WeightForcasting
                    passChildData={setChildData}
                    passClick={setIsClicked}
                  />
                </div>
              </div>
              <div className = "body-measurement-card">
              <div className="chart-section-main-div-last">
                <h4>Body Measurements</h4>
                {/*<p>Last Checked 2 Days Ago</p>*/}
                <p>Last Checked {userdetails.updated_at}</p>
              
            <div className="body-measurement-main-div">
              <div className="body-measurement-weight-div">
                <div className="enter-weight-p-div">
                  <p>Current Weight of the Patient</p>
                </div>
                <div></div>
                <p
                  className="enter-weight-p-div-for-bmr"
                  style={{ textAlign: "center" }}
                >
                  {weightdetails && weightdetails.current_weight}
                </p>
              </div>
              

              <div className="body-measurement-weight-div">
                <div className="enter-weight-p-div">
                  <p>Beginning BMR</p>
                </div>
                <div></div>
                <p
                  className="enter-weight-p-div-for-bmr"
                  style={{ textAlign: "center" }}
                >
                  1697 kJ
                </p>
              </div>
              

              <div className="body-measurement-weight-div">
                <div className="enter-weight-p-div">
                  <p>Current BMR</p>
                </div>
                <div></div>
                <p
                  className="enter-weight-p-div-for-bmr"
                  style={{ textAlign: "center" }}
                >
                  {Math.round(weightdetails && weightdetails.BMR)} kJ
                </p>
              </div>
              </div>

              {/* 
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  onClick={Male}
                  className={
                    isMaleActive
                      ? "male-female-btn-div-active"
                      : "male-female-btn-div"
                  }
                >
                  <p>Male</p>
                </div>
                <div
                  onClick={Female}
                  className={
                    isFemaleActive
                      ? "male-female-btn-div-active"
                      : "male-female-btn-div"
                  }
                >
                  <p>Female</p>
                </div>
              </div>
                */}

              {
                patientGender == "Male" ? (
                  // {male && (
                  <div>
                    
                    <div>
                      {malewithclothes && (
                        <div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {getweight < 90 ? (
                            <div style={{display: 'flex', flexDirection:"column"}}>
                            <img
                              src={imageFile}
                              alt={Math.round(childData?.value)}
                              className=""
                              style={{ marginLeft: "5px", width:"350px", height: "325px" }}
                            />
                            <p>Your Avatar when your weight is {Math.round(childData?.value)}kgs</p>
                            </div>
                          ) : getweight >= 90 && getweight <= 100 ? (
                            <div style={{display: 'flex', flexDirection:"column"}}>
                            <img
                              src={imageFile}
                              alt={Math.round(childData?.value)}
                              className=""
                              style={{ marginLeft: "5px", width:"350px", height: "325px" }}
                            />
                            <p>Your Avatar when your weight is {Math.round(childData?.value)}kgs</p>
                            </div>
                          ) : getweight >= 100 && getweight <= 110 ? (
                            <div style={{display: 'flex', flexDirection:"column"}}>
                            <img
                              src={imageFile}
                              alt={Math.round(childData?.value)}
                              className=""
                              style={{ marginLeft: "5px", width:"350px", height: "325px" }}
                            />
                            <p>Your Avatar when your weight is {Math.round(childData?.value)}kgs</p>
                            </div>
                          ) : getweight >= 110 ? (
                            <div style={{display: 'flex', flexDirection:"column"}}>
                            <img
                              src={imageFile}
                              alt={Math.round(childData?.value)}
                              className=""
                              style={{ marginLeft: "5px", width:"350px", height: "325px" }}
                            />
                            <p>Your Avatar when your weight is {Math.round(childData?.value)}kgs</p>
                            </div>
                          ) : (
                            <p>Please Enter your Weight</p>
                          )}
                        </div>
                      </div>
                      )}

                      {malewithoutclothes && (
                        <div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {getweight < 90 ? (
                            <div style={{display: 'flex', flexDirection:"column"}}>
                            <img
                              src={imageFile}
                              alt={Math.round(childData?.value)}
                              className=""
                              style={{ marginLeft: "5px", width:"350px", height: "325px" }}
                            />
                            <p>Your Avatar when your weight is {Math.round(childData?.value)}kgs</p>
                            </div>
                          ) : getweight >= 90 && getweight <= 100 ? (
                            <div style={{display: 'flex', flexDirection:"column"}}>
                            <img
                              src={imageFile}
                              alt={Math.round(childData?.value)}
                              className=""
                              style={{ marginLeft: "5px", width:"350px", height: "325px" }}
                            />
                            <p>Your Avatar when your weight is {Math.round(childData?.value)}kgs</p>
                            </div>
                          ) : getweight >= 100 && getweight <= 110 ? (
                            <div style={{display: 'flex', flexDirection:"column"}}>
                            <img
                              src={imageFile}
                              alt={Math.round(childData?.value)}
                              className=""
                              style={{ marginLeft: "5px", width:"350px", height: "325px" }}
                            />
                            <p>Your Avatar when your weight is {Math.round(childData?.value)}kgs</p>
                            </div>
                          ) : getweight >= 110 ? (
                            <div style={{display: 'flex', flexDirection:"column"}}>
                            <img
                              src={imageFile}
                              alt={Math.round(childData?.value)}
                              className=""
                              style={{ marginLeft: "5px", width:"350px", height: "325px" }}
                            />
                            <p>Your Avatar when your weight is {Math.round(childData?.value)}kgs</p>
                            </div>
                          ) : (
                            <p>Please Enter your Weight</p>
                          )}
                        </div>
                      </div>
                      )}
                    </div>
                  </div>
                ) : (
                  // )}
                  // {female && (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingTop: "10px",
                      }}
                    >
                      <p
                        onClick={FemalewithclothesHandler}
                        className={
                          isClothesActive
                            ? "male-female-btn-div-active"
                            : "male-female-btn-div"
                        }
                      >
                        With Clothes
                      </p>
                      <p
                        onClick={FemalewithoutclothesHandler}
                        className={
                          isWithoutClothesActive
                            ? "male-female-btn-div-active"
                            : "male-female-btn-div"
                        }
                      >
                        Without Clothes
                      </p>
                    </div>
                    <div>
                      {femalewithclothes && (
                        <div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {getweight < 90 ? (
                            <div style={{display: 'flex', flexDirection:"column"}}>
                            <img
                              src={imageFile}
                              alt={Math.round(childData?.value)}
                              className=""
                              style={{ marginLeft: "5px", width:"350px", height: "325px" }}
                            />
                            <p>Your Avatar when your weight is {Math.round(childData?.value)}kgs</p>
                            </div>
                          ) : getweight >= 90 && getweight <= 100 ? (
                            <div style={{display: 'flex', flexDirection:"column"}}>
                            <img
                              src={imageFile}
                              alt={Math.round(childData?.value)}
                              className=""
                              style={{ marginLeft: "5px", width:"350px", height: "325px" }}
                            />
                            <p>Your Avatar when your weight is {Math.round(childData?.value)}kgs</p>
                            </div>
                          ) : getweight >= 100 && getweight <= 110 ? (
                            <div style={{display: 'flex', flexDirection:"column"}}>
                            <img
                              src={imageFile}
                              alt={Math.round(childData?.value)}
                              className=""
                              style={{ marginLeft: "5px", width:"350px", height: "325px" }}
                            />
                            <p>Your Avatar when your weight is {Math.round(childData?.value)}kgs</p>
                            </div>
                          ) : getweight >= 110 ? (
                            <div style={{display: 'flex', flexDirection:"column"}}>
                            <img
                              src={imageFile}
                              alt={Math.round(childData?.value)}
                              className=""
                              style={{ marginLeft: "5px", width:"350px", height: "325px" }}
                            />
                            <p>Your Avatar when your weight is {Math.round(childData?.value)}kgs</p>
                            </div>
                          ) : (
                            <p>Please Enter your Weight</p>
                          )}
                        </div>
                      </div>
                      )}
                      {femalewithoutclothes && (
                        <div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {getweight < 90 ? (
                            <div style={{display: 'flex', flexDirection:"column"}}>
                            <img
                              src={imageFile}
                              alt={Math.round(childData?.value)}
                              className=""
                              style={{ marginLeft: "5px", width:"350px", height: "325px" }}
                            />
                            <p>Your Avatar when your weight is {Math.round(childData?.value)}kgs</p>
                            </div>
                          ) : getweight >= 90 && getweight <= 100 ? (
                            <div style={{display: 'flex', flexDirection:"column"}}>
                            <img
                              src={imageFile}
                              alt={Math.round(childData?.value)}
                              className=""
                              style={{ marginLeft: "5px", width:"350px", height: "325px" }}
                            />
                            <p>Your Avatar when your weight is {Math.round(childData?.value)}kgs</p>
                            </div>
                          ) : getweight >= 100 && getweight <= 110 ? (
                            <div style={{display: 'flex', flexDirection:"column"}}>
                            <img
                              src={imageFile}
                              alt={Math.round(childData?.value)}
                              className=""
                              style={{ marginLeft: "5px", width:"350px", height: "325px" }}
                            />
                            <p>Your Avatar when your weight is {Math.round(childData?.value)}kgs</p>
                            </div>
                          ) : getweight >= 110 ? (
                            <div style={{display: 'flex', flexDirection:"column"}}>
                            <img
                              src={imageFile}
                              alt={Math.round(childData?.value)}
                              className=""
                              style={{ marginLeft: "5px", width:"350px", height: "325px" }}
                            />
                            <p>Your Avatar when your weight is {Math.round(childData?.value)}kgs</p>
                            </div>
                          ) : (
                            <p>Please Enter your Weight</p>
                          )}
                        </div>
                      </div>
                      )}
                    </div>
                  </div>
                  
                )
                // )}
              }
              </div>
              </div>
          <div className="enter-weight-card-div">
            <div className="enter-weight-card-div-one">
              <div className="enter-weight-p-div">
                <p style={{ fontWeight: "600" }}>Please Enter Your Weight</p>
              </div>
              <div>
                <h2>:</h2>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "3px",
                }}
              >
                <input
                  onChange={(event) => setImageweight(event.target.value)}
                  className="enter-weight-p-div-input"
                  type="number"
                />
                <p>Kg</p>
              </div>
            </div>
            <div
            >
              <button
                onClick={addweight}
                className="male-female-btn-div"
                style={{ backgroundColor: "rgb(49,163,214)" }}
              >
                Submit
              </button>
            </div>
          </div>
            <div className="waist-hip-measurements-section">
              <div style={{ paddingTop: "10px", paddingBottom: "20px" }}>
                <h4>Measurements (in cms)</h4>
              </div>
              <div className="waist-hip-measurements-section-flex">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="enter-weight-p-div">
                  <p style={{ fontWeight: "600" }}>Waist Circumference</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "3px",
                  }}
                >
                  <p className="enter-weight-p-div-input" type="number">
                    {userdetails.waist_circumference_cm}
                  </p>
                 
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="enter-weight-p-div">
                  <p style={{ fontWeight: "600" }}>Hip Circumference</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "3px",
                  }}
                >
                  <p className="enter-weight-p-div-input" type="number">
                    {userdetails.hip_circumference_cm}
                  </p>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="enter-weight-p-div">
                  <p style={{ fontWeight: "600" }}>Bust Circumference</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "3px",
                  }}
                >
                  <p className="enter-weight-p-div-input" type="number">
                    {userdetails.bust_circumference_cm}
                  </p>
                </div>
              </div>
            </div>
            </div>
       

              {/*<div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingTop: "20px",
                }}
              >
                <div className="enter-weight-p-div">
                  <p>Current Weight of the Patient</p>
                </div>
                <div>
                  <h2>:</h2>
                </div>
                <p
                  className="enter-weight-p-div-for-bmr"
                  style={{ textAlign: "center" }}
                >
                  {userdetails.current_weight}
                </p>
              </div>*/}
            </div>
          </div>
        </div>
      </div>
    );
};

export default PersonalPatientFile;
