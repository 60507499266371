import axios from "axios";
import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import Loader from "../Loader/Loader";
import swal from "sweetalert";
import EastSharpIcon from "@mui/icons-material/EastSharp";
import { Link } from "react-router-dom";
import Apiservice from "../../Services/Apiservice";

{
  /*
const Que = forwardRef((props, ref) => {
  const [detail, setdetail] = useState("");
  const UsernameofPatient = localStorage.getItem("Username");
  const [response, setresponse] = useState("");
  const [message, setMessage] = useState("");
  const [rows, setRows] = useState([{ name: "", year: "" }]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const tableData = {};
    rows.forEach((row) => {
      tableData[row.name] = {
        Name: row.name,
        Year: row.year,
      };
    });
    const data = Object.fromEntries(Object.entries(tableData));

    fetch(
      `https://djangoobesitychat.azurewebsites.net/patient/response/Other/${UsernameofPatient}/`,
      {
        method: "POST",
        body: JSON.stringify([
          {
            question1: props.item.question1,
            patient_name: UsernameofPatient,
            tablejson: data,
          },
        ]),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  const handleAddRow = () => {
    setRows([...rows, { medication: "", dosage: "", number: "" }]);
  };

  const handleChange = (event, index) => {
    const newRows = [...rows];
    newRows[index][event.target.name] = event.target.value;
    setRows(newRows);
  };

  return (
    <div>
      {props.item.question_category === "yes_no" ? (
        <div
          className="questo-form-quesyion-ans-main-div-for-main"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="questo-form-questions-div" style={{ width: "55%" }}>
            <p>
              {props.item.mandatory === "Yes" ? (
                <span style={{ color: "red" }}> *</span>
              ) : null}
              {props.item.question}
            </p>
          </div>
          <div className="questo-form-options-div" style={{ width: "15%" }}>
            <div style={{ display: "flex", gap: "9px" }}>
              <input
                className="add-patient-input-field-of-radio"
                name={props.item.question_id}
                type="radio"
                value="Yes"
                onChange={(event) =>
                  props.radioButtonHandler({
                    que_id: props.item.question_id,
                    val: event.target.value,
                  })
                }
              />
              <p style={{ fontSize: "13px" }}>Yes</p>
              <input
                className="add-patient-input-field-of-radio"
                name={props.item.question_id}
                type="radio"
                value="No"
                onChange={(event) =>
                  props.radioButtonHandler({
                    que_id: props.item.question_id,
                    val: event.target.value,
                  })
                }
              />
              <p style={{ fontSize: "13px" }}>No</p>
            </div>
          </div>
          <div
            className="questo-form-details-div-in-pat"
            style={{ width: "30%" }}
          >
            <textarea
              className="questo-form-input-tag-for-patient"
              type={"text"}
              placeholder="Details..."
              onChange={(event) =>
                props.detailsHandler({
                  que_id: props.item.question_id,
                  val: event.target.value,
                })
              }
            />
          </div>
        </div>
      ) : props.item.question_category === "multiple_choice" ? (
        <div>Multiple Choice</div>
      ) : props.item.question_category === "table_format" ? (
        <>
          <div className="questo-form-questions-div" style={{ width: "55%" }}>
            <p>
              {props.item.mandatory === "Yes" ? (
                <span style={{ color: "red" }}> *</span>
              ) : null}
              {props.item.question}
            </p>
          </div>
          <form
            onSubmit={handleSubmit}
            style={{
              width: "55%",
              marginLeft: "15em",
              marginTop: "2em",
              marginBottom: "2em",
            }}
            className="questo-form-input-tag-for-patient"
          >
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Year</th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="text"
                        name="medication"
                        value={row.name}
                        onChange={(event) => handleChange(event, index)}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="dosage"
                        value={row.year}
                        onChange={(event) => handleChange(event, index)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={3}>
                    <button onClick={handleAddRow}>Add Row</button>
                  </td>
                </tr>
              </tfoot>
            </table>
            <button type="submit">Save</button>
          </form>
        </>
      ) : null}
    </div>
  );
});
*/
}
const PatientQuestionnaireMedTest = () => {
  // const [questionetailsget, setquestionetailsget] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [selectedFile, setSelectedFile] = React.useState(null);

  // const childRef = useRef();

  // useEffect(() => {
  //   axios
  //     .get(`https://djangoobesitychat.azurewebsites.net/question/category/Medical_Test/`, {})

  //     .then((response) => {
  //       const data = response.data.map((entry) => {
  //         return Object.assign({}, entry, {
  //           patient_name: localStorage.getItem("Username"),
  //           question1: entry.question,
  //         });
  //       });
  //       setquestionetailsget(data);
  //       setLoading(false);
  //     })
  //     .catch((err) => {});
  // }, []);

  // const radioButtonHandler = (param) => {
  //   const newResponse = questionetailsget.map((item) => {
  //     if (item.question_id == param.que_id) {
  //       item.patient_response1 = param.val;
  //     }
  //     return item;
  //   });
  //   setquestionetailsget(newResponse);
  // };

  // const detailsHandler = (param) => {
  //   const newResponse = questionetailsget.map((item) => {
  //     if (item.question_id == param.que_id) {
  //       item.response_details1 = param.val;
  //     }
  //     return item;
  //   });
  //   setquestionetailsget(newResponse);
  // };

  // const tableHandler = (param) => {
  //   const newResponse = questionetailsget.map((item) => {
  //     if (item.question_id == param.que_id) {
  //       item.tablejson = param.val;
  //     }
  //     return item;
  //   });
  //   setquestionetailsget(newResponse);
  // };

  // const submitHandler = (props) => {
  //   const UsernameofPatient = localStorage.getItem("Username");
  //   const test = questionetailsget;
  //   axios
  //     .post(
  //       `https://djangoobesitychat.azurewebsites.net/patient/response/post/Medical_Test/${UsernameofPatient}/`,
  //       questionetailsget,
  //       {}
  //     )
  //     .then((res) => {
  //       swal({
  //         title:
  //           "Your Response has been Submitted Successfully !! Please Move to the Medicine Questionnaire...",
  //         className: "successAlert",
  //         buttons: true,
  //         timer: 3000,
  //       });
  //     })
  //     .catch((err) => {
  //       swal({
  //         title: "Please Fill all the Responces...",
  //         className: "successAlert",
  //         buttons: true,
  //         timer: 3000,
  //       });
  //     });
  // };

  const Username = localStorage.getItem("Username");
  const [testname, settestname] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("med_docs", selectedFile && selectedFile);
    formData.append("customeuser_username", Username);
    formData.append("test_name", testname);
    const medDocsField = formData.get("med_docs");
    try {
      const response = await axios({
        method: "post",
        url: `https://djangoobesitychat.azurewebsites.net/medicaltestapi/${Username}/`,
        headers: {
          Authorization: `Basic ZGVtb19wYXRpZW50OkRlbW9Vc2VyQDEyMw==`,
        },
        data: formData,
      });
      swal({
        title:
          "Your Medical Test Document is Submitted Successfully... You Can add More Documents One by One",
        className: "successAlert",
        buttons: true,
        timer: 3000,
      });
      // setSelectedFile(null)
      // settestname("")
      Apiservice
      .get(`/medicaltestapi/${Username}/`)

      .then((response) => {
        setpatientuserdetails(response.data);
        // setLoading(false);
      })
    } catch (error) {}
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const [patientuserdetails, setpatientuserdetails] = useState([]);

  useEffect(() => {
    Apiservice
      .get(`/medicaltestapi/${Username}/`)

      .then((response) => {
        setpatientuserdetails(response.data);
        // setLoading(false);
      })
      .catch((err) => {
        // // // //console.log(err);
      });
  }, []);

  // const MedDocID = localStorage.getItem("MedDocID");
  const [MedDocID, setMedDocID] = useState();


  const DownloadURL = `https://djangoobesitychat.azurewebsites.net/filedownload/${MedDocID}/`;

  const [popup, setPop] = useState(false);
  const handleClickOpen = (key) => {
    localStorage.setItem("MedDocID", key);
    setPop(!popup);
  };

  // const handleClick = (key) => {
  //   handleClickOpen();
  // };

  const handleClick = (id) => {
    // localStorage.setItem("MedDocID", id);
    setMedDocID(id);
    // //console.log('Stored MedDocID::', id);
    handleClickOpen();
  };

  return (
    <div>
      <div className="skip-for-now-div">
        <Link>
          <h3>
            Skip For Now <EastSharpIcon style={{ marginTop: "4px" }} />
          </h3>
        </Link>
      </div>
      {/* 
          <div>
            <div
              className="questo-form-quesyion-ans-main-div-for-main"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="questo-form-questions-div"
                style={{ width: "55%", justifyContent: "center" }}
              >
                <h3>Questions</h3>
              </div>
              <div
                className="questo-form-options-div"
                style={{ width: "15%", justifyContent: "center" }}
              >
                <h3>Response</h3>
              </div>
              <div
                className="questo-form-details-div-in-pat"
                style={{ width: "30%", justifyContent: "center" }}
              >
                <h3>Details</h3>
              </div>
            </div>
            {questionetailsget.map((item) => {
              return (
                <Que
                  key={item.question_id}
                  item={item}
                  ref={childRef}
                  detailsHandler={detailsHandler}
                  radioButtonHandler={radioButtonHandler}
                  tableHandler={tableHandler}
                />
              );
            })}
          </div>
          */}
      {/*
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "0px",
            }}
          >
            <button onClick={submitHandler} className="submit-btn-in-que">
              Submit
            </button>
          </div>
          */}
      <form onSubmit={handleSubmit}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            gap: "30px",
            paddingBottom: "20px",
          }}
        >
          <div style={{ width: "40%" }}>
            <input
              type={"text"}
              placeholder="Enter Test Name..."
              className="questo-form-input-tag-for-patient"
              value={testname}
              onChange={(e) => settestname(e.target.value)}
            />
          </div>
          <div className="med-testc-doc-upload-div" style={{ width: "40%" }}>
            <input type="file" onChange={handleFileSelect} />
          </div>
          <div style={{ width: "20%" }}>
            <button
              style={{
                padding: "8px",
                backgroundColor: "rgb(49, 163, 214)",
                border: "none",
                borderRadius: "10px",
                cursor: "pointer",
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </form>

      <div
        style={{
          height: "115vh",
          overflowY: "scroll",
          boxShadow: "rgb(0 0 0 / 35%) 0px 5px 15px",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        <div className="test-doc-details-table">
          <div className="test-table-doc-sub-div">
            <h4>Test Name</h4>
          </div>
          <div className="test-table-doc-sub-div">
            <h4>Documents</h4>
          </div>
        </div>
        {patientuserdetails &&
          patientuserdetails.map((index) => (
            <div className="test-doc-details-table" key={index.id}>
              <div className="test-table-doc-sub-div">{index.test_name}</div>
              <div className="test-table-doc-sub-div">
                <button
                  className="update-btn-btn-tag"
                  // onClick={() => (handleClick(index.id)) }
                  // onClick={handleClickOpen}
                  onClick={() => handleClick(index.id)}
                >
                  Download
                </button>
              </div>
            </div>
          ))}
      </div>

      {popup ? (
        <div className="msg-download-popup">
          <p>Do You Want to Download Selected File ?</p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <a href={DownloadURL}>
              <button
                className="download-confirm-btn-tag"
                style={{ color: "white", backgroundColor: "rgb(49,163,214)" }}
                onClick={handleClickOpen}
              >
                Yes
              </button>
            </a>
            <button
              onClick={handleClick}
              className="download-confirm-btn-tag"
              style={{ color: "white", backgroundColor: "rgb(49,163,214)" }}
            >
              No
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default PatientQuestionnaireMedTest;
