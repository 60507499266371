import axios from "axios";
import React, { useEffect, useState } from "react";
import OptQues from "../Quiz/OptQues";
import RandomQuiz from "../Quiz/RandomQuiz";
import PatientQuestionnaireDisease from "./PatientQuestionnaireDisease";
import PatientQuestionnaireEmotionalTest from "./PatientQuestionnaireEmotionalTest";
import PatientQuestionnaireEmotionalTestNew from "./PatientQuestionnaireEmotionalTestNew";
import PatientQuestionnaireLifestyle from "./PatientQuestionnaireLifestyle";
import PatientQuestionnaireMedicine from "./PatientQuestionnaireMedicine";
import PatientQuestionnaireMedTest from "./PatientQuestionnaireMedTest";
import "./PatientQuestionnaireNew.css";
import ArrowForwardSharpIcon from "@mui/icons-material/ArrowForwardSharp";
import EastSharpIcon from "@mui/icons-material/EastSharp";
import { Link } from "react-router-dom";
import PatientQuestionnaireGeneral from "./PatientQuestionnaireGeneral";
import PatientQuestionnaireOther from "./PatientQuestionnaireOther";
import swal from "sweetalert";
import QuestionnaireGeneral from "../QuestionnairePutRequest/QuestionnaireGeneral";
import QuestionnaireDisease from "../QuestionnairePutRequest/QuestionnaireDisease";
import QuestionnaireLifestyle from "../QuestionnairePutRequest/QuestionnaireLifestyle";
import QuestionnaireMedicine from "../QuestionnairePutRequest/QuestionnaireMedicine";
import QuestionnaireOther from "../QuestionnairePutRequest/QuestionnaireOther";

const PatientQuestionnaireNew = () => {
  const [general, setGeneral] = useState(true);
  const [disease, setDisease] = useState(false);
  const [lifestyle, setLifestyle] = useState(false);
  const [mtest, setMtest] = useState(false);
  const [medicine, setMedicine] = useState(false);
  const [quiz, setQuiz] = useState(false);
  const [other, setOther] = useState(false);
  const [option, setOption] = useState(false);
  const [isgeneralactive, setIsgeneralactive] = useState(true);
  const [isdiseaseactive, setIsdiseaseactive] = useState(false);
  const [islifestyleactive, setIsLifestyleactive] = useState(false);
  const [ismtestactive, setIsMtestactive] = useState(false);
  const [ismedicineactive, setIsMedicineactive] = useState(false);
  const [isquizactive, setIsQuizactive] = useState(false);
  const [isotheractive, setIsOtheractive] = useState(false);
  const [isoptionactive, setIsOptionactive] = useState(false);
  


  // HANDELING THE TABLIST OF QUESTIONNAIRE USING STATE VARIABLE

  const GeneralHandler = () => {
    setGeneral(true);
    setDisease(false);
    setLifestyle(false);
    setMtest(false);
    setMedicine(false);
    setQuiz(false);
    setOther(false);
    setIsgeneralactive(true);
    setIsdiseaseactive(false);
    setIsLifestyleactive(false);
    setIsMtestactive(false);
    setIsMedicineactive(false);
    setIsQuizactive(false);
    setIsOtheractive(false);
  };

  const DiseseHandler = () => {
    setGeneral(false);
    setDisease(true);
    setLifestyle(false);
    setMtest(false);
    setMedicine(false);
    setQuiz(false);
    setOther(false);
    setIsgeneralactive(false);
    setIsdiseaseactive(true);
    setIsLifestyleactive(false);
    setIsMtestactive(false);
    setIsMedicineactive(false);
    setIsQuizactive(false);
    setIsOtheractive(false);
  };
  const LifestyleHandler = () => {
    setGeneral(false);
    setDisease(false);
    setLifestyle(true);
    setMtest(false);
    setMedicine(false);
    setQuiz(false);
    setOther(false);
    setIsgeneralactive(false);
    setIsdiseaseactive(false);
    setIsLifestyleactive(true);
    setIsMtestactive(false);
    setIsMedicineactive(false);
    setIsQuizactive(false);
    setIsOtheractive(false);
  };
  const MTestHandler = () => {
    setGeneral(false);
    setDisease(false);
    setLifestyle(false);
    setMtest(true);
    setMedicine(false);
    setQuiz(false);
    setOther(false);
    setIsgeneralactive(false);
    setIsdiseaseactive(false);
    setIsLifestyleactive(false);
    setIsMtestactive(true);
    setIsMedicineactive(false);
    setIsQuizactive(false);
    setIsOtheractive(false);
  };
  const MedicineHandler = () => {
    setGeneral(false);
    setDisease(false);
    setLifestyle(false);
    setMtest(false);
    setMedicine(true);
    setQuiz(false);
    setOther(false);
    setIsgeneralactive(false);
    setIsdiseaseactive(false);
    setIsLifestyleactive(false);
    setIsMtestactive(false);
    setIsMedicineactive(true);
    setIsQuizactive(false);
    setIsOtheractive(false);
  };
  const QuizHandler = () => {
    setGeneral(false);
    setDisease(false);
    setLifestyle(false);
    setMtest(false);
    setMedicine(false);
    setQuiz(true);
    setOther(false);
    setIsgeneralactive(false);
    setIsdiseaseactive(false);
    setIsLifestyleactive(false);
    setIsMtestactive(false);
    setIsMedicineactive(false);
    setIsQuizactive(true);
    setIsOtheractive(false);
  };

  const OtherHandler = () => {
    setGeneral(false);
    setDisease(false);
    setLifestyle(false);
    setMtest(false);
    setMedicine(false);
    setQuiz(false);
    setOther(true);
    setIsgeneralactive(false);
    setIsdiseaseactive(false);
    setIsLifestyleactive(false);
    setIsMtestactive(false);
    setIsMedicineactive(false);
    setIsQuizactive(false);
    setIsOtheractive(true);
  };

  const patient = localStorage.getItem("is_patient");

  

  // GETTING THE BOOLEAN VALUE OF QUESTIONNAIRE TO CHECK WHICH POST REQUEST HAS BEEN COMPLETED
  // Example ::::  
  // IF NewGeneral is TRUE SO AT THAT TIME IM REMOVING POST REQUEST COMPONENT AND DISPLAYING PUT REQUEST COMPONENT 
  
  // FOR QUESTIONNAIRE GO WITH THE CODE ABOVE TO LINE NO 272


  const NewGeneral = localStorage.getItem("General");
  const NewDisease = localStorage.getItem("Disease");
  const NewLifestyle = localStorage.getItem("Lifestyle");
  const NewMedicine = localStorage.getItem("Medicine");
  const NewOther = localStorage.getItem("Other");

  const [currentComponent, setCurrentComponent] = useState("General");

  const goToNext = () => {
    if(currentComponent === "General") {
      setCurrentComponent("Disease");
    }
    else if(currentComponent === "Disease") {
      setCurrentComponent("Lifestyle");
    }
    else if(currentComponent === "Lifestyle") {
      setCurrentComponent("MTest");
    }
    else if(currentComponent === "MTest") {
      setCurrentComponent("Medicine");
    }
    else if(currentComponent === "Medicine") {
      setCurrentComponent("Quiz");
    }
    else if(currentComponent === "Quiz") {
      setCurrentComponent("Other");
    }
    else if(currentComponent === "Other") {
      setCurrentComponent("Option");
    }
    else if(currentComponent === "Option") {
      setCurrentComponent("General");
    }
  }


  return (
    <div>


      {patient == "true" ? (
        <div>
          <div className="new-que-home-div-flex">
            <div
              className={
                isgeneralactive
                  ? "new-que-home-div-sub-one-active"
                  : "new-que-home-div-sub-one"
              }
              onClick={GeneralHandler}
            >
              General
            </div>
            <div
              className={
                isdiseaseactive
                  ? "new-que-home-div-sub-one-active"
                  : "new-que-home-div-sub-one"
              }
              onClick={DiseseHandler}
            >
              Health Overview
            </div>
            <div
              className={
                islifestyleactive
                  ? "new-que-home-div-sub-one-active"
                  : "new-que-home-div-sub-one"
              }
              onClick={LifestyleHandler}
            >
              Lifestyle & weight history
            </div>
            <div
              className={
                ismtestactive
                  ? "new-que-home-div-sub-one-active"
                  : "new-que-home-div-sub-one"
              }
              onClick={MTestHandler}
            >
              Diagnostic Tool
            </div>
            <div
              className={
                ismedicineactive
                  ? "new-que-home-div-sub-one-active"
                  : "new-que-home-div-sub-one"
              }
              onClick={MedicineHandler}
            >
              Drugs
            </div>
            <div
              className={
                isquizactive
                  ? "new-que-home-div-sub-one-active"
                  : "new-que-home-div-sub-one"
              }
              onClick={QuizHandler}
            >
              Emotional Questionnaire
            </div>
            <div
              className={
                isotheractive
                  ? "new-que-home-div-sub-one-active"
                  : "new-que-home-div-sub-one"
              }
              onClick={OtherHandler}
            >
              Physical & Social Activity
            </div>
          </div>
          {/* {currentComponent === "General" && <PatientQuestionnaireGeneral goToNext={goToNext} />}

          {currentComponent === "Disease" &&  <PatientQuestionnaireDisease />} */}
          {general && <PatientQuestionnaireGeneral />}
          {disease && <PatientQuestionnaireDisease />}
          {lifestyle && <PatientQuestionnaireLifestyle />}
          {mtest && <PatientQuestionnaireMedTest />}
          {medicine && <PatientQuestionnaireMedicine />}
          {quiz && <PatientQuestionnaireEmotionalTestNew />}
          {other && <PatientQuestionnaireOther />}
          {option && <OptQues />}
        </div>
      ) : (
        <div
          style={{
            padding: "30px",
            paddingLeft: "100px",
            paddingRight: "100px",
          }}
        >
        
          <div>
            <div className="new-que-home-div-flex">
              <div
                className={
                  isgeneralactive
                    ? "new-que-home-div-sub-one-active"
                    : "new-que-home-div-sub-one"
                }
                onClick={GeneralHandler}
              >
                General
              </div>
              <div
                className={
                  isdiseaseactive
                    ? "new-que-home-div-sub-one-active"
                    : "new-que-home-div-sub-one"
                }
                onClick={DiseseHandler}
              >
                Health Overview
              </div>
              <div
                className={
                  islifestyleactive
                    ? "new-que-home-div-sub-one-active"
                    : "new-que-home-div-sub-one"
                }
                onClick={LifestyleHandler}
              >
                Lifestyle & weight history
              </div>
              <div
                className={
                  ismtestactive
                    ? "new-que-home-div-sub-one-active"
                    : "new-que-home-div-sub-one"
                }
                onClick={MTestHandler}
              >
                Diagnostic Tool
              </div>
              <div
                className={
                  ismedicineactive
                    ? "new-que-home-div-sub-one-active"
                    : "new-que-home-div-sub-one"
                }
                onClick={MedicineHandler}
              >
                Drugs
              </div>
              <div
                className={
                  isquizactive
                    ? "new-que-home-div-sub-one-active"
                    : "new-que-home-div-sub-one"
                }
                onClick={QuizHandler}
              >
                Emotional Questionnaire
              </div>
              <div
                className={
                  isotheractive
                    ? "new-que-home-div-sub-one-active"
                    : "new-que-home-div-sub-one"
                }
                onClick={OtherHandler}
              >
                Physical & Social Activity
              </div>
            </div>
          </div>
          <hr />
          {general && (
            <div>
              {NewGeneral == "true" ? (
                <QuestionnaireGeneral />
              ) : (
                <PatientQuestionnaireGeneral />
              )}
            </div>
          )}
          {disease && (
            <div>
              {NewDisease == "true" ? (
                <QuestionnaireDisease />
              ) : (
                <PatientQuestionnaireDisease />
              )}
            </div>
          )}
          {lifestyle && 
            <div>
            {NewLifestyle == "true" ? <QuestionnaireLifestyle/> :
            <PatientQuestionnaireLifestyle />
          }
            </div>
          }
          {mtest && <PatientQuestionnaireMedTest />}
          {medicine && 
            <div>
            {NewMedicine == "true" ? <QuestionnaireMedicine/> :
            <PatientQuestionnaireMedicine />
          }
          </div>
          }
          {quiz && <PatientQuestionnaireEmotionalTestNew />}
          {other && 
            <div>
            {NewOther == "true" ? <QuestionnaireOther/> :
            <PatientQuestionnaireOther />
          }
            </div>
          }
          {option && <OptQues />}
        </div>
      )}
    </div>
  );
};

export default PatientQuestionnaireNew;
