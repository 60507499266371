import axios from "axios";
import React, { useEffect, useState } from "react";
import PatientDocuments from "../PatientDocuments/PatientDocuments";
// import "./PatientQuestionnaireNew.css";
import QuestionnaireDisease from "./QuestionnaireDisease";
import QuestionnaireEmotional from "./QuestionnaireEmotional";
import QuestionnaireGeneral from "./QuestionnaireGeneral";
import QuestionnaireLifestyle from "./QuestionnaireLifestyle";
import QuestionnaireMedicine from "./QuestionnaireMedicine";
import QuestionnaireMedTest from "./QuestionnaireMedTest";
import QuestionnaireOther from "./QuestionnaireOther";

const QuestionnairePutRequest = () => {
  const [disease, setDisease] = useState(false);
  const [lifestyle, setLifestyle] = useState(false);
  const [mtest, setMtest] = useState(false);
  const [medicine, setMedicine] = useState(false);
  const [emotional, setEmotional] = useState(false);
  const [general, setGeneral] = useState(true);
  const [other, setOther] = useState(false);
  const [isdiseaseactive, setIsdiseaseactive] = useState(false);
  const [islifestyleactive, setIsLifestyleactive] = useState(false);
  const [ismtestactive, setIsMtestactive] = useState(false);
  const [ismedicineactive, setIsMedicineactive] = useState(false);
  const [isemotionalactive, setIsEmotionalactive] = useState(false);
  const [isgeneralactive, setIsGeneralactive] = useState(true);
  const [isotheractive, setIsOtheractive] = useState(false);

  const DiseseHandler = () => {
    setDisease(true);
    setLifestyle(false);
    setMtest(false);
    setMedicine(false);
    setEmotional(false);
    setGeneral(false);
    setOther(false);
    setIsOtheractive(false);
    setIsEmotionalactive(false);
    setIsdiseaseactive(true);
    setIsLifestyleactive(false);
    setIsMtestactive(false);
    setIsMedicineactive(false);
    setIsGeneralactive(false);
  };
  const LifestyleHandler = () => {
    setDisease(false);
    setLifestyle(true);
    setMtest(false);
    setMedicine(false);
    setEmotional(false);
    setGeneral(false);
    setOther(false);
    setIsOtheractive(false);
    setIsEmotionalactive(false);
    setIsdiseaseactive(false);
    setIsLifestyleactive(true);
    setIsMtestactive(false);
    setIsMedicineactive(false);
    setIsGeneralactive(false)
  };
  const MTestHandler = () => {
    setDisease(false);
    setLifestyle(false);
    setMtest(true);
    setMedicine(false);
    setEmotional(false);
    setGeneral(false);
    setOther(false);
    setIsOtheractive(false);
    setIsEmotionalactive(false);
    setIsdiseaseactive(false);
    setIsLifestyleactive(false);
    setIsMtestactive(true);
    setIsMedicineactive(false);
    setIsGeneralactive(false)
  };
  const MedicineHandler = () => {
    setDisease(false);
    setLifestyle(false);
    setMtest(false);
    setMedicine(true);
    setEmotional(false);
    setGeneral(false);
    setOther(false);
    setIsOtheractive(false);
    setIsEmotionalactive(false);
    setIsdiseaseactive(false);
    setIsLifestyleactive(false);
    setIsMtestactive(false);
    setIsMedicineactive(true);
    setIsGeneralactive(false)
  };

  const EmotionalHandler = () => {
    setDisease(false);
    setLifestyle(false);
    setMtest(false);
    setMedicine(false);
    setEmotional(true);
    setGeneral(false);
    setOther(false);
    setIsOtheractive(false);
    setIsEmotionalactive(true);
    setIsdiseaseactive(false);
    setIsLifestyleactive(false);
    setIsMtestactive(false);
    setIsMedicineactive(false);
    setIsGeneralactive(false)
  };
  const GeneralHandler = () => {
    setDisease(false);
    setLifestyle(false);
    setMtest(false);
    setMedicine(false);
    setEmotional(false);
    setGeneral(true);
    setOther(false);
    setIsOtheractive(false);
    setIsEmotionalactive(false);
    setIsdiseaseactive(false);
    setIsLifestyleactive(false);
    setIsMtestactive(false);
    setIsMedicineactive(false);
    setIsGeneralactive(true)
  };
  const OtherHandler = () => {
    setDisease(false);
    setLifestyle(false);
    setMtest(false);
    setMedicine(false);
    setEmotional(false);
    setGeneral(false);
    setOther(true);
    setIsOtheractive(true);
    setIsEmotionalactive(false);
    setIsdiseaseactive(false);
    setIsLifestyleactive(false);
    setIsMtestactive(false);
    setIsMedicineactive(false);
    setIsGeneralactive(false)
  };
  return (
    <div style={{padding:"10px"}}>
      <div className="new-que-home-div-flex">
        <div
          className={
            isgeneralactive
              ? "new-que-home-div-sub-one-active"
              : "new-que-home-div-sub-one"
          }
          onClick={GeneralHandler}
        >
          General
        </div>
        <div
          className={
            isdiseaseactive
              ? "new-que-home-div-sub-one-active"
              : "new-que-home-div-sub-one"
          }
          onClick={DiseseHandler}
        >
          Health Overview
        </div>
        <div
          className={
            islifestyleactive
              ? "new-que-home-div-sub-one-active"
              : "new-que-home-div-sub-one"
          }
          onClick={LifestyleHandler}
        >
          Lifestyle & Weight History
        </div>
        <div
          className={
            ismtestactive
              ? "new-que-home-div-sub-one-active"
              : "new-que-home-div-sub-one"
          }
          onClick={MTestHandler}
        >
        Diagnostic Tool
        </div>
        <div
          className={
            ismedicineactive
              ? "new-que-home-div-sub-one-active"
              : "new-que-home-div-sub-one"
          }
          onClick={MedicineHandler}
        >
          Drugs
        </div>
        <div
          className={
            isemotionalactive
              ? "new-que-home-div-sub-one-active"
              : "new-que-home-div-sub-one"
          }
          onClick={EmotionalHandler}
        >
          Emotional Questionnaire
        </div>
        <div
          className={
            isotheractive
              ? "new-que-home-div-sub-one-active"
              : "new-que-home-div-sub-one"
          }
          onClick={OtherHandler}
        >
        Physical & Social Activity
        </div>
      </div>
      <hr />
      {general && <QuestionnaireGeneral />}
      {disease && <QuestionnaireDisease />}
      {lifestyle && <QuestionnaireLifestyle />}
        {/*{mtest && <QuestionnaireMedTest />}*/}
      {mtest && <PatientDocuments/>}
      {medicine && <QuestionnaireMedicine />}
      {emotional && <QuestionnaireEmotional/>}
      {other && <QuestionnaireOther/>}
    </div>
  );
};

export default QuestionnairePutRequest;
