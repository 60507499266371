import React, { useState, Fragment, useEffect } from "react";
import "./PatientCalender.css";
import Calendar from "react-calendar";
import Modal from "@mui/material/Modal";
import { Button, Box, Grid } from "@mui/material";
import axios from "axios";
import Apiservice from "../../Services/Apiservice";
// import CalenderPrescriptionPopup from "./CalenderPrescriptionPopup";
const style = {
  position: "absolute",
  top: "80%",
  left: "60%",
  right: "100%",
  bottom: "2vw",
  transform: "translate(-60%, -100%)",
  height: "70vh",
  width: "32vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
};

const PatientCalender = (props) => {
  const [value, onChange] = useState(new Date());
  const [open, setOpen] = useState(false);
  const [datesWithAppointments, setDatesWithAppointments] = useState([]);
  const [showTime, setShowTime] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = (value) => {
    setOpen(false);
  };
  const [mypatients, setmypatients] = useState([]);
  const Username = localStorage.getItem("Username");

  // TABLIST
  const [medicine, setmedicine] = useState(true);
  const [exercise, setexercise] = useState(false);
  const [diet, setdiet] = useState(false);
  const [futureevents, setfutureevents] = useState(false);
  const [ismedicineactive, setismedicineactive] = useState(true);
  const [isexerciseactive, setisexerciseactive] = useState(false);
  const [isdietactive, setisdietactive] = useState(false);
  const [isfutureeventsactive, setisfutureeventsactive] = useState(false);

  const medicineHandler = () => {
    setmedicine(true);
    setexercise(false);
    setdiet(false);
    setismedicineactive(true);
    setisexerciseactive(false);
    setisdietactive(false);
    setfutureevents(false);
    setisfutureeventsactive(false);
  };
  const exerciseHandler = () => {
    setexercise(true);
    setdiet(false);
    setisexerciseactive(true);
    setisdietactive(false);
    setmedicine(false);
    setismedicineactive(false);
    setfutureevents(false);
    setisfutureeventsactive(false);
  };
  const dietHandler = () => {
    setexercise(false);
    setdiet(true);
    setisexerciseactive(false);
    setisdietactive(true);
    setmedicine(false);
    setismedicineactive(false);
    setfutureevents(false);
    setisfutureeventsactive(false);
  };
  const futurEeventsHandler = () => {
    setexercise(false);
    setdiet(false);
    setisexerciseactive(false);
    setisdietactive(false);
    setmedicine(false);
    setismedicineactive(false);
    setfutureevents(true);
    setisfutureeventsactive(true);
  };

  // GET EXERCISE PRESCRIBED
  const [getExerciseData, setgetExerciseData] = useState("");
  useEffect(() => {
    Apiservice
      .get(
        `/exerciseprescription/patient/${Username}/`,
        {}
      )
      .then((response) => {
        setgetExerciseData(response.data["getdata"]);
      })
      .catch((err) => {});
  }, []);

  // GET DIET PRESCRIBED
  const [getDietData, setgetDietData] = useState("");
  useEffect(() => {
    Apiservice
      .get(`/dietprescription/patient/${Username}/`, {})
      .then((response) => {
        setgetDietData(response.data);
      })
      .catch((err) => {});
  }, []);

  // //console.log('getDietData', getDietData);
  // GET EVENTS
  const [getEventData, setgetEventData] = useState("");
  useEffect(() => {
    Apiservice
      .get(`/event/user/${Username}/`, {
        headers: {
          "Authorization": `Token ${localStorage.getItem("SavedToken")}`,
        },
      })
      .then((response) => {
        setgetEventData(response.data);
      })
      .catch((err) => {});
  }, []);

  return (
    <div className="calender-main-div">
      <Calendar
        onChange={onChange}
        value={value}
        onClickDay={handleOpen}
        tileClassName={({ date }) => {
          let day = date.getDate();
          let month = date.getMonth() + 1;
          if (month < 10) {
            month = "0" + month;
          }
          if (day < 10) {
            day = "0" + day;
          }
          const realDate = date.getFullYear() + "-" + month + "-" + day;
          // return dates.includes(realDate) ? "appointment-date" : "";
        }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
          sx={{
            height: "50vh",
            width: "100%",
            margin: "auto",
          }}
        >
          <Grid
            item
            xs={14}
            sx={style}
            justify="flex-end"
            style={{ width: "900px", overflowY: "scroll" }}
          >
            <Button sx={{ marginTop: -4 }} onClick={handleClose}>
              Close
            </Button>
            <div style={{ display: "flex" }}>
              <p
                style={{
                  fontSize: 12,
                  marginLeft: -4,
                  marginTop: "2vh",
                  marginBottom: "2vh",
                }}
              >
                {value.toLocaleDateString("fr-CA")}
              </p>
            </div>
            {/*TABLIST */}
            <div className="dahs-board-main-div-two-sub-one-new">
              <div className="patient-diet-and-exercise-view-flex-div">
                <div className="patient-diet-and-exercise-view-width-div">
                  <div className="patient-diet-and-exercise-view-main-div">
                    <div
                      className={
                        ismedicineactive
                          ? "new-que-home-div-sub-one-active-in-pres"
                          : "new-que-home-div-sub-one-in-pres"
                      }
                      onClick={medicineHandler}
                    >
                      Medicines Prescribed
                    </div>
                    <div
                      className={
                        isexerciseactive
                          ? "new-que-home-div-sub-one-active-in-pres"
                          : "new-que-home-div-sub-one-in-pres"
                      }
                      onClick={exerciseHandler}
                    >
                      Exercise Prescribed
                    </div>

                    <div
                      className={
                        isdietactive
                          ? "new-que-home-div-sub-one-active-in-pres"
                          : "new-que-home-div-sub-one-in-pres"
                      }
                      onClick={dietHandler}
                    >
                      Diet Prescribed
                    </div>
                    <div
                      className={
                        isfutureeventsactive
                          ? "new-que-home-div-sub-one-active-in-pres"
                          : "new-que-home-div-sub-one-in-pres"
                      }
                      onClick={futurEeventsHandler}
                    >
                      Events
                    </div>
                  </div>
                  <div>
                  
                    {medicine && <div>Medicine</div>}


                    {exercise && (
                      <div className="patient-calender-exercise-flex-div">
                        <div className="patient-calender-exercise-width-div">
                          <div className="patient-calender-exercise-main-div">
                            <div className="patient-calender-exercise-sub-div-one">
                              <h3>Exercise Name</h3>
                            </div>
                            <div className="patient-calender-exercise-sub-div-two">
                              <h3>Exercise Time</h3>
                            </div>
                            <div className="patient-calender-exercise-sub-div-three">
                              <h3>Trainer Name</h3>
                            </div>
                          </div>

                          {getExerciseData &&
                            getExerciseData.map((index) => (
                              <div>
                                {index.exercise_date ==
                                value.toLocaleDateString("fr-CA") ? (
                                  <div className="patient-calender-exercise-main-div">
                                    <div
                                      className="patient-calender-exercise-sub-div-one"
                                      style={{ textAlign: "left" }}
                                    >
                                      {index.exercise.map((exercise) => (
                                        <li>{exercise}</li>
                                      ))}
                                    </div>
                                    <div className="patient-calender-exercise-sub-div-two">
                                      <p>{index.exercise_time}</p>
                                    </div>
                                    <div className="patient-calender-exercise-sub-div-three">
                                      <p>
                                        {index.trainer_firstName}{" "}
                                        {index.trainer_lastName}
                                      </p>
                                    </div>
                                  </div>
                                ) : (
                                  <div>
                                    <p></p>
                                    {/*<p className="no-data-available-msg">
                                    No Exercis Prescribed for{" "}
                                    {value.toLocaleDateString("fr-CA")} !!!
                                </p>*/}
                                  </div>
                                )}
                              </div>
                            ))}
                        </div>
                      </div>
                    )}


                    {diet && (
                      <div className="patient-calender-exercise-flex-div">
                        <div className="patient-calender-exercise-width-div">
                          <div className="patient-calender-exercise-main-div">
                            <div className="patient-calender-exercise-sub-div-one">
                              <h3>Meal Name</h3>
                            </div>
                            <div className="patient-calender-exercise-sub-div-two">
                              <h3>Meal Time</h3>
                            </div>
                            <div className="patient-calender-exercise-sub-div-three">
                              <h3>Dietician Name</h3>
                            </div>
                          </div>

                          {getDietData &&
                            getDietData.map((index) => (
                              <div>
                                {index.diet_date ==
                                value.toLocaleDateString("fr-CA") ? (
                                  <div className="patient-calender-exercise-main-div">
                                    <div
                                      className="patient-calender-exercise-sub-div-one"
                                      style={{ textAlign: "left" }}
                                    >
                                      {index.foods.map((foods) => (
                                        <li>{foods}</li>
                                      ))}
                                    </div>
                                    <div className="patient-calender-exercise-sub-div-two">
                                      <p>{index.meal_time}</p>
                                    </div>
                                    <div className="patient-calender-exercise-sub-div-three">
                                      <p>
                                        {index.dietitian_firstName}{" "}
                                        {index.dietitian_lastName}
                                      </p>
                                    </div>
                                  </div>
                                ) : (
                                  <div>
                                    <p></p>
                                    {/*<p className="no-data-available-msg">
                                    No Diet Prescribed for{" "}
                                    {value.toLocaleDateString("fr-CA")} !!!
                                </p>*/}
                                  </div>
                                )}
                              </div>
                            ))}
                        </div>
                      </div>
                    )}


                    {futureevents && (
                      <div className="patient-calender-exercise-flex-div">
                        <div className="patient-calender-exercise-width-div">
                          <div className="patient-calender-exercise-main-div">
                            <div
                              className="patient-calender-exercise-sub-div-one"
                              style={{ width: "25%" }}
                            >
                              <h3>Event Name</h3>
                            </div>
                            <div
                              className="patient-calender-exercise-sub-div-two"
                              style={{ width: "25%" }}
                            >
                              <h3>Event Details</h3>
                            </div>
                            <div
                              className="patient-calender-exercise-sub-div-three"
                              style={{ width: "25%" }}
                            >
                              <h3>Next Step</h3>
                            </div>
                            <div
                              className="patient-calender-exercise-sub-div-three"
                              style={{ width: "25%" }}
                            >
                              <h3>Doctor Name</h3>
                            </div>
                          </div>

                          {getEventData &&
                            getEventData.map((index) => (
                              <div>
                                {index.date ==
                                value.toLocaleDateString("fr-CA") ? (
                                  <div className="patient-calender-exercise-main-div">
                                    <div
                                      className="patient-calender-exercise-sub-div-one"
                                      style={{ width: "25%" }}
                                    >
                                      <p>{index.events}</p>
                                    </div>
                                    <div
                                      className="patient-calender-exercise-sub-div-two"
                                      style={{ width: "25%" }}
                                    >
                                      <p>{index.Event_details}</p>
                                    </div>
                                    <div
                                      className="patient-calender-exercise-sub-div-three"
                                      style={{ width: "25%" }}
                                    >
                                      <p>{index.next_steps}</p>
                                    </div>
                                    <div
                                      className="patient-calender-exercise-sub-div-three"
                                      style={{ width: "25%" }}
                                    >
                                      <p>Doctor Name</p>
                                    </div>
                                  </div>
                                ) : (
                                  <div>
                                    <p></p>
                                    {/*<p className="no-data-available-msg">
                                    No Events Available for{" "}
                                    {value.toLocaleDateString("fr-CA")} !!!
                                </p>*/}
                                  </div>
                                )}
                              </div>
                            ))}
                        </div>
                      </div>
                    )}

                    
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};

export default PatientCalender;
