import React, { useState, useEffect } from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import Loader from "../Loader/Loader";
import "./Notification.css";

function NotificationComponent() {
  const [socket, setSocket] = useState(null);
  const [notification, setNotification] = useState([]);
  const [client, setClient] = useState(null);
  const username = localStorage.getItem("Username");

  const[notificationLoading,setnotificationLoading]=useState(true)


  useEffect(() => {
    // Connect to the WebSocket
    const clients = new W3CWebSocket(
      // `wss://djangoobesitychat.azurewebsites.net/ws/notifications/?auth_token=${username}`
      // `wss://djangoobesitychat.azurewebsites.net/ws/notifications/?auth_token=${username}`
      `wss://djangoobesitychat.azurewebsites.net/ws/notifications/?auth_token=${username}`
      // `6380`
    );
    setClient(clients);

    // Set up event listeners for the WebSocket
    clients.onopen = () => {
      const data = {
        id: 1,
        read: true, // Set the field status to true
      };
      // // //console.log('WebSocket connected');
      clients.send("Hello from client");
    };

    clients.onmessage = (event) => {
      const data = JSON.parse(event.data);
      const d = data?.data;

      setNotification(d);
      setnotificationLoading(false)
    };
    clients.onclose = () => {
      clients.close();
      //console.log("WebSocket closed");
    };

    const Total = notification && notification.length;
    // //console.log("Total", Total);

    setSocket(clients);

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, []);
  const onButtonClicked = (e) => {
    if (client) {
      client.send(
        JSON.stringify({
          type: "message",
          message: "Clicked",
        })
      );
    }
    // // //console.log(e)
    e.preventDefault();
  };
  //  // // //console.log(client)
  // create a list of the notifications

  const [notificationID, setnotificationID] = useState("");

  const handleClick = (id) => {
    setnotificationID(id);
    const data = {
      id: id,
      read: true, // Set the field status to true
    };
    client.send(JSON.stringify(data));
  };

  const handleMarkAllAsRead = () => {
    notification.forEach((n) => {
      const data = {
        id: n.id,
        read: true, // Set the field status to true for all notifications
      };
      client.send(JSON.stringify(data));
    });
  };

  const notifications = notification?.map((notification) => (
    <div>
      {notificationLoading == true ? (
        <Loader />
      ) : (
        <div className="notification-main-flex-div">
          <p className="notification-main-flex-sub-div-one" key={notification.id}>
            {notification.activity_type}
          </p>
          <div className="notification-main-flex-sub-div-two">
            <button
              id="mark-read-btn"
              className="main-text"
              onClick={() => handleClick(notification.id)}
            >
              Mark as Read
            </button>
          </div>
        </div>
      )}
    </div>
  ));

  return (
    <div>
      <button id="mark-read-btn" className="main-text" onClick={handleMarkAllAsRead}>
        Mark All as Read
      </button>
      {notifications && <p>{notifications}</p>}
    </div>
  );
}

export default NotificationComponent;
