import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from "react";
import "./PatientExercise.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import axios from "axios";
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";
import SearchLoader from "../Loader/SearchLoader";
import Add from "@mui/icons-material/Add";
import swal from "sweetalert";
import ExerciseCalender from "./ExerciseCalender";
import { Link } from "react-router-dom";
import Apiservice from "../../Services/Apiservice";

const PatientExercise = (props) => {
  const patientUserName = localStorage.getItem("patientUserName");
  const Username = localStorage.getItem("Username");
  // const shootCalender = localStorage.getItem("shootCalender");

  function ImageCellRenderer(params) {
    const imageUrl = params.value;
    return <img style={{ height: "3em" }} src={imageUrl} alt="Cell" />;
  }
  const gridRef = useRef();
  const [columnDefs, setColumnDefs] = useState([
    { field: "exercise_date", filter: true, headerName: "Date" },
    { field: "exercise_time", filter: true, headerName: "Time" },
    {
      field: "exercise",
      filter: true,
      headerName: "Exercise",
      headerClassName: "super-app-theme--header",
    },

    { field: "trainer_firstName", headerName: "Trainer First Name" },
    { field: "trainer_lastName", headerName: "Trainer Last Name" },
  ]);
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    resizable: true,
    suppressToolPanel: true,
  }));
  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(
      document.getElementById("filter-text-box").value
    );
  }, []);
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    fetch(
      is_patient == "true"
        ? `https://djangoobesitychat.azurewebsites.net/exerciseprescription/patient/${Username}/`
        : `https://djangoobesitychat.azurewebsites.net/exerciseprescription/patient/${patientUserName}/`,
       {
          method: "GET",
          headers: {
            "Authorization": `Token ${localStorage.getItem("SavedToken")}` 
          },
          }
    )
      .then((data) => data.json())
      .then((data) => setTableData(data["getdata"]));
    // setLoading(false);
  }, []);

  // GET AI EXERCISE
  const [AIDIetResponse, setAIDIetResponse] = useState();

  const [GetAIDiet, setGetAIDiet] = useState(null);
  const [DayOneExercise, setDayOneExercise] = useState("");
  const [DayTwoExercise, setDayTwoExercise] = useState("");
  const [DayThreeExercise, setDayThreeExercise] = useState("");
  const [DayFourExercise, setDayFourExercise] = useState("");
  const [DayFiveExercise, setDayFiveExercise] = useState("");
  const [DaySixExercise, setDaySixExercise] = useState("");
  const [DaySevenExercise, setDaySevenExercise] = useState("");
  useEffect(() => {
    Apiservice
      .get(`/suggestedexercise/patient_name/${Username}/`)
      .then((response) => {
        setGetAIDiet(response.data[0]["suggestedexercise"]);
        setDayOneExercise(response.data[0]["suggestedexercise"]["Day 1"]);
        setDayTwoExercise(response.data[0]["suggestedexercise"]["Day 2"]);
        setDayThreeExercise(response.data[0]["suggestedexercise"]["Day 3"]);
        setDayFourExercise(response.data[0]["suggestedexercise"]["Day 4"]);
        setDayFiveExercise(response.data[0]["suggestedexercise"]["Day 5"]);
        setDaySixExercise(response.data[0]["suggestedexercise"]["Day 6"]);
        setDaySevenExercise(response.data[0]["suggestedexercise"]["Day 7"]);
      })
      .catch((err) => {});
  }, [Username]);

  const [isAddPrescriptionActive, setisAddPrescriptionActive] = useState(false);
  const ExercisePlanID = localStorage.getItem("ExercisePlanID");
  const trainer_name = localStorage.getItem("Username");
  const is_patient = localStorage.getItem("is_patient");

  const AddPrescriptionHandler = () => {
    setisAddPrescriptionActive(true);
  };
  const HideAddPrescriptionHandler = () => {
    setisAddPrescriptionActive(false);
  };

  const [opentwo, setOPentwo] = useState(false);
  const toggletwo = () => {
    setOPentwo(!opentwo);
  };

  //   SEARCH EXERCISE
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  useEffect(() => {
    const loadPosts = async () => {
      setLoading(true);
      const response = await Apiservice.get(
        "/allexerciseplans/get/"
      );
      setPosts(response.data);
      //   // // //console.log(response.data);
      setLoading(false);
    };
    loadPosts();
  }, []);

  const [days_of_operation, setdays_of_operation] = useState([]);
  const [patient_name, setpatient_name] = useState("");
  const [exercise_time, setexercise_time] = useState("");
  const [exerciseplans_id, setexerciseplans_id] = useState("");

  function handleChange(event) {
    setdays_of_operation([...days_of_operation, event.target.value]);
  }

  const AddPatientExercise = async (e) => {
    e.preventDefault();

    await Apiservice
      .post(
        // `https://djangoobesitychat.azurewebsites.net/user/doctorpostapi/`,
        `/exerciseprescription/post/`,

        {
          days_of_operation: days_of_operation,
          trainer_name: trainer_name,
          patient_name: patientUserName,
          exercise_time: exercise_time,
          exerciseplans_id: ExercisePlanID,
        }
      )
      .then((res) => {
        swal({
          title: "Exercise Added Successfully...",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });

        fetch(
          `https://djangoobesitychat.azurewebsites.net/exerciseprescription/patient/${patientUserName}/`, {
          method: "GET",
          headers: {
            "Authorization": `Token ${localStorage.getItem("SavedToken")}` 
          },
          }
        )
          // fetch(`https://djangoobesitychat.azurewebsites.net/exerciseprescription/patient/demo_patient/`)
          .then((data) => data.json())
          .then((data) => setTableData(data));
      });
    HideAddPrescriptionHandler().catch((err) => {});
  };

  return (
    <div>
      {is_patient == "true" ? (
        <div>
          {tableData.length != 0 ? (
            <div
              className="ag-theme-material"
              style={{
                width: "100%",
                height: "70vh",
                boxShadow: "rgb(0 0 0 / 35%) 0px 5px 15px",
                marginTop: "20px",
                overflowY: "scroll",
                overflowX: "scroll",
              }}
            >
              <AgGridReact
                ref={gridRef}
                rowData={tableData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                rowSelection="multiple"
              />
            </div>
          ) : (
            <div className="ai-diet-responce-main-div">
              {DayOneExercise ? (
                <div>
                  <div className="ai-diet-main-div-one">
                    <h3>Exercise For Day One</h3>
                  </div>
                  <div style={{ paddingLeft: "100px", paddingTop: "15px" }}>
                    {DayOneExercise &&
                      DayOneExercise?.map((index) => <li>{index}</li>)}
                  </div>
                </div>
              ) : (
                ""
              )}

              {DayTwoExercise ? (
                <div>
                  <div className="ai-diet-main-div-one">
                    <h3>Exercise For Day Two</h3>
                  </div>
                  <div style={{ paddingLeft: "100px", paddingTop: "15px" }}>
                    {DayTwoExercise &&
                      DayTwoExercise?.map((index) => <li>{index}</li>)}
                  </div>
                </div>
              ) : (
                ""
              )}

              {DayThreeExercise ? (
                <div>
                  <div className="ai-diet-main-div-one">
                    <h3>Exercise For Day Three</h3>
                  </div>
                  <div style={{ paddingLeft: "100px", paddingTop: "15px" }}>
                    {DayThreeExercise &&
                      DayThreeExercise?.map((index) => <li>{index}</li>)}
                  </div>
                </div>
              ) : (
                ""
              )}

              {DayFourExercise ? (
                <div>
                  <div className="ai-diet-main-div-one">
                    <h3>Exercise For Day One</h3>
                  </div>
                  <div style={{ paddingLeft: "100px", paddingTop: "15px" }}>
                    {DayFourExercise &&
                      DayFourExercise?.map((index) => <li>{index}</li>)}
                  </div>
                </div>
              ) : (
                ""
              )}

              {DayFiveExercise ? (
                <div>
                  <div className="ai-diet-main-div-one">
                    <h3>Exercise For Day Five</h3>
                  </div>
                  <div style={{ paddingLeft: "100px", paddingTop: "15px" }}>
                    {DayFiveExercise &&
                      DayFiveExercise?.map((index) => <li>{index}</li>)}
                  </div>
                </div>
              ) : (
                ""
              )}

              {DaySixExercise ? (
                <div>
                  <div className="ai-diet-main-div-one">
                    <h3>Exercise For Day Six</h3>
                  </div>
                  <div style={{ paddingLeft: "100px", paddingTop: "15px" }}>
                    {DaySixExercise &&
                      DaySixExercise?.map((index) => <li>{index}</li>)}
                  </div>
                </div>
              ) : (
                ""
              )}

              {DaySevenExercise ? (
                <div>
                  <div className="ai-diet-main-div-one">
                    <h3>Exercise For Day Seven</h3>
                  </div>
                  <div style={{ paddingLeft: "100px", paddingTop: "15px" }}>
                    {DaySevenExercise &&
                      DaySevenExercise?.map((index) => <li>{index}</li>)}
                  </div>
                </div>
              ) : (
                ""
              )}

              {GetAIDiet == null ? (
                <div className="email-verification-main-div-two">
                <Link to="/patientDashboard/aidiet">
                  <button
                    className="login-page-login-btn"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      //   width: "60px",
                      padding: "10px",
                    }}
                  >
                    Generate Your Exercise Plan
                  </button>
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      ) : (
        <div>
          <div>
            {isAddPrescriptionActive == true ? null : (
              <div className="add-pat-btn-div">
                <button
                  className="add-btn-btn-tag"
                  onClick={AddPrescriptionHandler}
                >
                  ADD EXERCISE
                  <BsFillCaretDownFill
                    style={{ height: "20px", width: "20px" }}
                  />
                </button>
              </div>
            )}

            {isAddPrescriptionActive == true ? (
              <div className="add-pat-btn-div">
                <button
                  className="add-btn-btn-tag"
                  onClick={HideAddPrescriptionHandler}
                >
                  HIDE EXERCISE FORM
                  <BsFillCaretUpFill
                    style={{ height: "20px", width: "20px" }}
                  />
                </button>
              </div>
            ) : (
              ""
            )}
          </div>

          {isAddPrescriptionActive == true ? (
            <div className="diet-presc-calender-main-div">
              <ExerciseCalender />
            </div>
          ) : (
            ""
          )}

          <div
            className="ag-theme-material"
            style={{
              width: "100%",
              height: "70vh",
              boxShadow: "rgb(0 0 0 / 35%) 0px 5px 15px",
              marginTop: "20px",
              overflowY: "scroll",
              overflowX: "scroll",
            }}
          >
            <AgGridReact
              ref={gridRef}
              rowData={tableData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              animateRows={true}
              rowSelection="multiple"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PatientExercise;
