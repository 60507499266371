import axios from 'axios';
import { useQuery, useMutation } from 'react-query';

const serverUrl = process.env.REACT_APP_SERVERURL;

export const useGet = (url) => {
  return useQuery(url, async () => {
    const response = await axios.get(`${serverUrl}${url}`, {
      headers: { Authorization: `Token ${localStorage.getItem('SavedToken')}` },
    });
    return response.data;
  });
};

export const usePost = (url) => {
    return useMutation(async (payload) => {
      try {
        const response = await axios.post(`${serverUrl}${url}`, payload, {
          headers: { Authorization: `Token ${localStorage.getItem('SavedToken')}` },
        });
        
        return response.data;
      } catch (error) {
        // Log the error for debugging purposes
        console.error("Error in usePost:", error);
  
        // Check if the error response has data to return
        if (error.response && error.response.data) {
          return error.response.data;
        }
  
        // Re-throw the error if you want it to be caught by onError in the mutation
        throw error;
      }
    });
  };
  
  

export const usePut = (url) => {
  return useMutation(async (payload) => {
    const response = await axios.put(`${serverUrl}${url}`, payload, {
      headers: { Authorization: `Token ${localStorage.getItem('SavedToken')}` },
    });
    console.log('response', response.data);
    return response.data;
  });
};
