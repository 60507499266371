import React from "react";
import { useState, useEffect } from "react";
import AppointmentbyClinic from "./AppointmentbyClinic";
import AppointmentbySubClinic from "./AppointmentbySubClinic";
import DititionistAppointment from "./DititionistAppointment";
import DititionistAppointmentSC from "./DititionistAppointmentSC";
import TrainerAppointment from "./TrainerAppointment";
import TrainerAppointmentSC from "./TrainerAppointmentSC";

const AppontmentbyClinicHomePage = () => {
  const [doctor, setDoctor] = useState();
  const [Dititionist, setDititionist] = useState();
  const [Trainer, setTrainer] = useState();
  const [isdoctoractive, setIsdoctoractive] = useState();
  const [isDititionistactive, setIsDititionistactive] = useState();
  const [isTraineractive, setIsTraineractive] = useState();
  const is_subclinic = localStorage.getItem("is_subclinic");
  const is_receptionist = localStorage.getItem("is_receptionist");
  const ClinicIDForHomepage = localStorage.getItem("ClinicIDForHomepage");
  const SubClinicIDForHomepage = localStorage.getItem("SubClinicIDForHomepage");

  useEffect(() => {
    const isTrainerActiveInLocalStorage = localStorage.getItem('isTraineractive');
    setTrainer(isTrainerActiveInLocalStorage);
    setIsTraineractive(isTrainerActiveInLocalStorage);
    const isDititionistActiveInLocalStorage = localStorage.getItem('isDititionistactive');
    setDititionist(isDititionistActiveInLocalStorage);
    setIsDititionistactive(isDititionistActiveInLocalStorage);
    const isDoctorActiveInLocalStorage = localStorage.getItem('isdoctoractive');
    setDoctor(isDoctorActiveInLocalStorage);
    setIsdoctoractive(isDoctorActiveInLocalStorage);
  }, []);

  //console.log("isdoctoractive", isdoctoractive);
  //console.log("isDititionistactive", Dititionist);
  //console.log("isTraineractive", isTraineractive);


  
  const DoctorHandler = () => {
    setDoctor(true);
    setIsdoctoractive(true);
    setDititionist(false);
    setIsDititionistactive(false);
    setTrainer(false);
    setIsTraineractive(false);
  };

  const DititionistHandler = () => {
    setDoctor(false);
    setIsdoctoractive(false);
    setDititionist(true);
    setIsDititionistactive(true);
    setTrainer(false);
    setIsTraineractive(false);
  };

  const TrainerHandler = () => {
    setDoctor(false);
    setIsdoctoractive(false);
    setDititionist(false);
    setIsDititionistactive(false);
    setTrainer(true);
    setIsTraineractive(true);
  };

  const trainerState = localStorage.getItem("isTraineractive");
  //console.log("trainerState", trainerState);

  
  


  return (
    <div className="appt-by-clinic-homepage-flex-div">
      <div className="appt-by-clinic-homepage-width-div">
        <div className="appt-by-clinic-homepage-main-div">
          <div
            style={{ width: "100%" }}
            className={
              isdoctoractive
                ? "new-que-home-div-sub-one-active"
                : "new-que-home-div-sub-one"
            }
            onClick={DoctorHandler}
          >
            Appointment for Doctor
          </div>
          <div
            style={{ width: "100%" }}
            className={
              isDititionistactive
                ? "new-que-home-div-sub-one-active"
                : "new-que-home-div-sub-one"
            }
            onClick={DititionistHandler}
          >
            Appointment for Dietician
          </div>

          <div
            style={{ width: "100%" }}
            className={
              isTraineractive
                ? "new-que-home-div-sub-one-active"
                : "new-que-home-div-sub-one"
            }
            onClick={TrainerHandler}
          >
            Appointment for Trainer
          </div>
        </div>
        <div>
        {is_receptionist == "true" ? 
        <div>
          {ClinicIDForHomepage == "null" ? (
            <div>
              {doctor && <AppointmentbySubClinic />}
              {Dititionist && <DititionistAppointmentSC />}
              {Trainer && <TrainerAppointmentSC />}
            </div>
          ) : (
            <div>
              {doctor && <AppointmentbyClinic />}
              {Dititionist && <DititionistAppointment />}
              {Trainer && <TrainerAppointment />}
            </div>
          )}
        </div>
          :
        <div>
          {is_subclinic == "true" ? (
            <div>
              {doctor && <AppointmentbySubClinic />}
              {Dititionist && <DititionistAppointmentSC />}
              {Trainer && <TrainerAppointmentSC />}
            </div>
          ) : (
            <div>
              {doctor && <AppointmentbyClinic />}
              {Dititionist && <DititionistAppointment />}
              {Trainer && <TrainerAppointment />}
            </div>
          )}
        </div>
        }
      </div>
      </div>
    </div>
  );
};

export default AppontmentbyClinicHomePage;
