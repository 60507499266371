import React from 'react';
import { useNavigate } from "react-router-dom";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const GraphComponent = ({ data }) => {
  const navigate = useNavigate();
  const handleBarClick = (patientUsername) => {
    //console.log(patientUsername["patient_name"])
    localStorage.setItem("UsernameOfPatient", patientUsername["patient_name"]);
    navigate("/clinicsidebar/patientresponseanalysis/");
  };
  return (
    <ResponsiveContainer width="100%" height={500}>
      <BarChart
        width={800}
        height={500}
        data={data}
        margin={{
          top: 0,
          right: 30,
          left: 20,
          bottom: 5,
        }}>
        
        <XAxis dataKey={"patient_name"} />
        <YAxis />
        <Tooltip />
        <Bar dataKey={"answered_percentage"} fill="#8884d8" XHeight={'500px'} onClick={(patientUsername) => handleBarClick(patientUsername)}/>
      </BarChart>
    </ResponsiveContainer>
  
  );
};

export default GraphComponent;