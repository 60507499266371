import React, { useState } from "react";
import PatientDiet from "../PatientDiet/PatientDiet";
import PatientExercise from "../PatientExercise/PatientExercise";
import "./PatientDietAndExerciseView.css";

const PatientDietAndExerciseView = () => {
  const [exercise, setexercise] = useState(true);
  const [diet, setdiet] = useState(false);
  const [isexerciseactive, setisexerciseactive] = useState(true);
  const [isdietactive, setisdietactive] = useState(false);

  const exerciseHandler = () => {
    setexercise(true);
    setdiet(false);
    setisexerciseactive(true);
    setisdietactive(false);
  };

  const dietHandler = () => {
    setexercise(false);
    setdiet(true);
    setisexerciseactive(false);
    setisdietactive(true);
  };
  return (
    <div className="patient-diet-and-exercise-view-flex-div">
      <div className="patient-diet-and-exercise-view-width-div">
        <div className="patient-diet-and-exercise-view-main-div">
          <div
            className={
              isexerciseactive
                ? "new-que-home-div-sub-one-active-in-pres"
                : "new-que-home-div-sub-one-in-pres"
            }
            onClick={exerciseHandler}
          >
            Exercise Prescribed
          </div>

          <div
            className={
              isdietactive
                ? "new-que-home-div-sub-one-active-in-pres"
                : "new-que-home-div-sub-one-in-pres"
            }
            onClick={dietHandler}
          >
            Diet Prescribed
          </div>
        </div>
        <div>
          {exercise && <PatientExercise />}
          {diet && <PatientDiet />}
        </div>
      </div>
    </div>
  );
};

export default PatientDietAndExerciseView;
