import React, { useEffect, useState } from "react";
import "./PhenotypeDrilldownDoughnut.css";
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, PieChart, Pie, Legend, Label, ResponsiveContainer, Tooltip } from "recharts";
import axios from "axios";
import { json } from "react-router-dom";
import Apiservice from "../../Services/Apiservice";



const PhenotypeDrilldownDoughnutTotal = () => {

  const [drilldown,setdrilldown]=useState()
  const patientUserName = localStorage.getItem("patientUserName")

  useEffect(()=>{
    Apiservice.get(`/phenotypedrilldown/${patientUserName}/`)
    .then((response)=>{
      setdrilldown(response.data["categorized"])
    })
    .catch(error =>{
    })
  },[patientUserName])

  //console.log("drilldown",drilldown)

  const hungryBrain = parseFloat(drilldown&&drilldown[`hungry brain`])
  const emotionalHunger = parseFloat(drilldown&&drilldown[`emotional hunger`]) 
  const hungryGut = parseFloat(drilldown&&drilldown[`hungry gut`]) 
  const slowBurn = parseFloat(drilldown&&drilldown[`slow burn`]) 

  const phenotypeTotal = hungryBrain + emotionalHunger + hungryGut + slowBurn

  const hungryBrainPercent = Math.round((hungryBrain/phenotypeTotal)*100)
  const emotionalHungerPercent = Math.round((emotionalHunger/phenotypeTotal)*100)
  const hungryGutPercent = Math.round((hungryGut/phenotypeTotal)*100)
  const slowBurnPercent = Math.round((slowBurn/phenotypeTotal)*100)




  const data = [
    { name: "Hungry Brain", value: hungryBrainPercent, fill: "#d00000" },
    { name: "Emotional Hunger", value: emotionalHungerPercent, fill: "#ffba08" },
    { name: "Hungry Gut", value: hungryGutPercent, fill: "#3f88c5" },
    { name: "Slow Burn", value: slowBurnPercent, fill: "#032b43" },
  ];


  return (
    
    <ResponsiveContainer
      width={303}
      height={319}
      className="chart-resp-cont-in-pheno"
    >
      <PieChart>
      <Tooltip
      formatter={(value, name) => [`${value}%`, name]}
      />
        <Pie
          data={data}
          cx={100}
          cy={180}
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
          align="center"
          stroke="none"
          className="pie-in-phno"
        ></Pie>
        <Legend verticalAlign="top" align="left" height={1} />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PhenotypeDrilldownDoughnutTotal;
