import React, { useEffect, useState } from "react";
import {
  GiPaperBagCrumpled,
  GiHeartBeats,
  GiMedicines,
  GiLoveInjection,
} from "react-icons/gi";
import { MdBloodtype } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import PhenotypeDrilldownDoughnut from "../PhenotypeDrilldownDoughnut/PhenotypeDrilldownDoughnut";
import ProgressBar from "../ProgressBar/ProgressBar";
import FemaleDoughnut from "../FemaleDoughnut/FemaleDoughnut";
import MaleDoughnut from "../MaleDoughnut/MaleDoughnut";
import "../PersonalPatientFile/PersonalPatientFile.css";
import { FaAngleDown } from "react-icons/fa";
import axios from "axios";
import Loader from "../Loader/Loader";
import { Link } from "react-router-dom";
import { FcSportsMode } from "react-icons/fc";
import { RiArrowDropDownFill } from "react-icons/ri";
import swal from "sweetalert";
import PatientDiet from "../PatientDiet/PatientDiet";
import PatientExercise from "../PatientExercise/PatientExercise";
import Apiservice from "../../Services/Apiservice";
import PhenotypeDrilldownDoughnutTotal from "../PhenotypeDrilldownDoughnut/PhenotypeDrilldownDoughnut_total";
import moment from "moment";


const MedicalHistory = () => {
  const [popup, setPop] = useState(false);
  const handleClickOpen = () => {
    setPop(!popup);
  };

  const closePopup = () => {
    setPop(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", () => {
      setPop(false);
    });
  });

  // Contradictions Popup
  const [contraPopup, setContraPopup] = useState(false);
  const onClickOpen = () => {
    setContraPopup(!contraPopup);
  };

  const [comorboditiesPopup, setComorboditiesPopup] = useState(false);
  const onClickOpencomorbodities = () => {
    setComorboditiesPopup(!comorboditiesPopup);
  };

  const closeContraPopup = () => {
    setContraPopup(false);
  };

  // DrugInteraction Popup
  const [DrugInteractionPopup, setDrugInteractionPopup] = useState(false);
  const onClickOpenDrugInteractionPopup = () => {
    setDrugInteractionPopup(!DrugInteractionPopup);
  };

  const closeDrugInteractionPopup = () => {
    setDrugInteractionPopup(false);
  };

  // Collapsible Screening Stages

  const [open, setOPen] = useState(false);
  const toggle = () => {
    setOPen(!open);
  };

  const [opentwo, setOPentwo] = useState(false);
  const toggletwo = () => {
    setOPentwo(!opentwo);
  };

  const [openthree, setOPenthree] = useState(false);
  const togglethree = () => {
    setOPenthree(!openthree);
  };

  const [openfour, setOPenfour] = useState(false);
  const togglefour = () => {
    setOPenfour(!openfour);
  };

  const [openfive, setOPenfive] = useState(false);
  const togglefive = () => {
    setOPenfive(!openfive);
  };
  const [opensix, setOPensix] = useState(false);
  const togglesix = () => {
    setOPensix(!opensix);
  };

  const PatientIDforRedirect = localStorage.getItem("PatientID");
  // const superuser = localStorage.getItem("SuperUser", SuperUser);
  const [patientdetails, setpatientdetails] = useState([]);
  const savedtoken = localStorage.getItem("SavedToken");
  const patientUserName = localStorage.getItem("patientUserName");
  const [userdetails, setuserdetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [patientBmiHistory, setPatientBmiHistory] = useState();

  const clinic = localStorage.getItem("is_clinic");

  //fetching these to disable popups
  const isdietitian = localStorage.getItem("is_dietitian");
  const istrainer = localStorage.getItem("is_trainer");
  const isdoctor = localStorage.getItem("is_doctor");

  const [drilldown, setdrilldown] = useState();

  useEffect(() => {
    Apiservice
      .get(`/phenotypedrilldown/${patientUserName}/`)
      .then((response) => {
        setdrilldown(response.data["categorized"]);
        // // //console.log("pheno details", response.data);
      })
      .catch((error) => {
        // // //console.log(error);
      });
  }, [patientUserName]);

  const data1 = drilldown || { drilldown };
  const sorted = Object.entries(data1)
    .sort((a, b) => b[1] - a[1])
    .map((entry) => entry[0])
    .slice(0, 2);
  // // //console.log("sorted", sorted);

  const [mainDataLoading, setmainDataLoading] = useState(true);

  useEffect(() => {
    Apiservice
      .get(
        // `https://djangoobesitychat.azurewebsites.net/patientapi/update/${PatientIDforRedirect}/`,
        `/user/patientapi/update/${PatientIDforRedirect}/`,
        {
          headers: {
            Authorization: `Token ${savedtoken}`,
          },
        }
      )

      .then((response) => {
        setpatientdetails(response.data);
        setmainDataLoading(false);

        // // // //console.log(response.data)
        const { patient_name } = response.data;
        localStorage.setItem("patientUserName", patient_name);
        // setLoading(false);
      })
      .catch((err) => {});
  }, [PatientIDforRedirect]);

  const [heartRate, setheartRate] = useState("");
  const [bloodSugar, setbloodSugar] = useState("");
  const [bloodPressure, setbloodPressure] = useState("");
  const [Systolic_blood_pressure, setSystolic_blood_pressure] = useState("");
  const [Diastolic_blood_pressure, setDiastolic_blood_pressure] = useState("");
  const addPatient = async (e) => {
    e.preventDefault();
    setmainDataLoading(true)

    await axios
      .put(
        `https://djangoobesitychat.azurewebsites.net/user/patientapi/update/${PatientIDforRedirect}/`,

        {
          // heart_rate: heartRate,
          heart_rate: HeartRateNew,
          // blood_pressure: BloodPressureNew,
          Systolic_blood_pressure:Systolic_blood_pressureNew,
          Diastolic_blood_pressure:Diastolic_blood_pressureNew,
          blood_sugar: BloodSugarNew,
          clinic_id:patientdetails?.clinic_id,
          subclinic_id:patientdetails?.subclinic_id,
          patient_name:patientUserName
        },
        {
          headers: {
            Authorization: `Token ${savedtoken}`,
          },
        }
      )
      .then((res) => {
        // // // //console.log(res);
    setmainDataLoading(false)

        swal({
          title: "Patient Details Updated Successfully",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });
      })
      .catch((err) => {
    setmainDataLoading(false)

      });
  };

  const HeartRateNew = heartRate == "" ? patientdetails?.heart_rate : heartRate;

  const BloodPressureNew =
    bloodPressure == "" ? patientdetails?.blood_pressure : bloodPressure;

  const Diastolic_blood_pressureNew =
    Diastolic_blood_pressure == "" ? patientdetails?.Diastolic_blood_pressure : Diastolic_blood_pressure;

    const Systolic_blood_pressureNew =
    Systolic_blood_pressure == "" ? patientdetails?.Systolic_blood_pressure : Systolic_blood_pressure;

  const BloodSugarNew =
    bloodSugar == "" ? patientdetails?.blood_sugar : bloodSugar;

  const [contracount, setcontracount] = useState();

  useEffect(() => {
    Apiservice
      .get(`/drugwarningcount/${patientUserName}/`)
      .then((response) => {
        setcontracount(response.data);
        // // // //console.log("setcontracount", response.data);
      })
      .catch((err) => {
        // // //console.log(err);
      });
  }, []);

  const [patientuserdetails, setpatientuserdetails] = useState([]);
  useEffect(() => {
    Apiservice
      .get(`/user/patientapi/${patientUserName}/`)
      .then((response) => {
        // // //console.log("Patient data is", response[0]);
        setpatientuserdetails(response.data[0]);
      })
      .catch((err) => {
        // // // //console.log(err);
      });
  }, [patientUserName]);

  const [lastpresc, setlastpresc] = useState("");
  const medicine_name = lastpresc && lastpresc.medicine_name.map(medicine => medicine.medicine_name).join(", ");
  useEffect(() => {
    Apiservice
      .get(`/prescription2api/patient_name/${patientUserName}/`)
      .then((response) => {
        setlastpresc(response.data[response.data.length - 1]);
        // // // //console.log("setlastpresc", response.data[response.data.length-1]);
      })
      .catch((err) => {
        // // //console.log(err);
      });
  }, []);
  
  //BMI API for Patient Personal
  const [ShowBMIbyWeek, setShowBMIbyWeek] = useState(false);
  const toggleShowBMIbyWeek = () => {
    setShowBMIbyWeek(!ShowBMIbyWeek);
  };
  // Current Date
  const currentdate = new Date();
  const weekDay = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const day = weekDay[currentdate.getDay()];
  const month = months[currentdate.getMonth()];
  const date = currentdate.getDate();
  const year = currentdate.getFullYear();
  const patientUsername = localStorage.getItem("patientUserName");
  useEffect(() => {
    Apiservice
      .get(`/user/allwighthistory/${patientUsername}/`)
      .then((response) => {
        // // //console.log("BMI Data in Personal PatientFile", response.data);
        setPatientBmiHistory(response.data);
      })
      .catch((err) => {
        // // //console.log(err);
      });
  }, [patientUserName]);

  const dateParsed =
    patientBmiHistory &&
    patientBmiHistory.map((index) =>
      moment(index.updatedate, "MMM DD YYYY HH:mm A").valueOf()
    );

  // const sortedData = patientBmiHistory && patientBmiHistory.sort((a, b) => moment(b.updatedate, "MMM DD YYYY HH:mm A").valueOf() - moment(a.updatedate, "MMM DD YYYY HH:mm A").valueOf());
  const sortedData =
    patientBmiHistory && patientBmiHistory.sort((a, b) => a.id - b.id);

  /* Creating a variable called currentWeek and setting it equal to the current week of the year. */
  const currentWeek = moment().week();

  const currentMonth = moment().month();

  /* Filtering the patientBmiHistory array to only include the weeks that are less than or equal to the
  current week. */
  const filtered =
    patientBmiHistory &&
    patientBmiHistory.filter((index) => index.week <= currentWeek);

  const filteredbyMonth =
    patientBmiHistory &&
    patientBmiHistory.filter((index) => index.month <= currentMonth);

  const dataByWeek =
    filtered &&
    filtered.reduce((acc, item) => {
      acc[item.week] = item;
      return acc;
    }, {});

  const dataByMonth =
    filteredbyMonth &&
    filteredbyMonth.reduce((acc, item) => {
      acc[item.month] = item;
      return acc;
    }, {});

  //CARD for patient BMI
  const Card = ({ data }) => {
    const {
      id,
      user,
      activity_type,
      BMI,
      changes,
      date,
      week,
      month,
      updated_at,
    } = data;
    // const BMI = changes && changes.BMI;
    const BMII = BMI;
    const sweek = moment(patientBmiHistory[0].date).month();
    

    return (
      <div className="week-section-main-div">
        <div className="week-section-sub-div-one">
          <p className="week-para" style={{ textAlign: "center" }}>
            Month {month - (sweek - 1)}
          </p>
          <div className="bmi-div-btn">
            <p>{Math.round(BMII)} BMI</p>
          </div>
        </div>
      </div>
    );
  };

  const CardbyWeek = ({ data }) => {
    const {
      id,
      user,
      activity_type,
      BMI,
      changes,
      date,
      week,
      month,
      updated_at,
    } = data;
    // const BMI = changes && changes.BMI;
    const BMII = BMI;
    const sweek = moment(patientBmiHistory[0].date).week();

    return (
      <div className="week-section-main-div">
        <div className="week-section-sub-div-one">
          <p className="week-para" style={{ textAlign: "center" }}>
            Week {week - (sweek - 1)}
          </p>
          <div className="bmi-div-btn">
            <p>{Math.round(BMII)} BMI</p>
          </div>
        </div>
      </div>
    );
  };

  const openPopupVitals = () => {
    setIsOpenPopup(!isOpenPopup);
  };

  

 

  //console.log("lastpresc", lastpresc);
  // const phenoValue = Object.keys(drilldown);
  // const phenoData = Object.values(drilldown);
  return (
    <div>
      {mainDataLoading && (
        <div className="purchase-order-details-pop-up-main-div">
          <div className="purchase-order-details-pop-up-sub-div">
            <div
              className="purchase-order-details-pop-up-sub-div-container"
              style={{ background: "none" }}
            >
              <Loader />
            </div>
          </div>
        </div>
      )}
      <div className="heart" onClick={openPopupVitals}>
      </div>
    <div className = "vital-list">
    <div class="book">
      <div class="back">
    {contracount && contracount.contraindications_match_list.map(
          (index) => <li>{index}</li>)}
          </div>
        <div class="cover">
        <div
                className="blood-sugar-card-in-top"
                onClick={onClickOpen}
                style={{ cursor: "pointer" }}
              >
                <div className="card-first-sec-div">
                  <div className="card-logo-in-contra-diction">
                    <GiLoveInjection
                      style={{
                        height: "25px",
                        width: "25px",
                        color: "white",
                      }}
                    />
                  </div>
                  <div className="card-detail-div-main-for-flex">
                    <div className="card-detail-div-main">
                      <h5>Contraindications</h5>
                    </div>
                  </div>
                </div>
                <div className="contra-dic-text">
                  <h2>{contracount && contracount.contraindications_count}</h2>
                </div>
                </div>
                </div>
      </div>

      <div class="book">
      <div class="back">
      {contracount && contracount.drug_interaction_match_list.map(
          (index) => <li style={{fontSize: "1px"}} >{index}</li>
      )}
      </div>
        <div class="cover">
        <div
                className="blood-sugar-card-in-top"
                onClick={onClickOpen}
                style={{ cursor: "pointer" }}
              >
                <div className="card-first-sec-div">
                  <div className="card-logo-in-contra-diction">
                    <GiMedicines
                      style={{
                        height: "22px",
                        width: "22px",
                        color: "white",
                      }}
                    />
                  </div>
                  <div className="card-detail-div-main-for-flex">
                    <div className="card-detail-div-main">
                      <h5>Drug Drug Interactions</h5>
                    </div>
                  </div>
                </div>
                <div className="contra-dic-text">
                  <h2>{contracount && contracount.drug_interaction_count}</h2>
                </div>
                </div>
        </div>
      </div>
      </div>

      
      <div>
      {isOpenPopup && (
        isdietitian == "true" || istrainer == "true" ? (
          <div className="medical-history-section-main-div-pp">
            <div className="blood-sugar-card">
              <div className="card-logo-in-medical-history">
                <GiPaperBagCrumpled
                  style={{ height: "25px", width: "25px", color: "white" }}
                />
              </div>
              <div className="card-detail-div-main-for-flex">
                <div className="card-detail-div-main">
                  <h5>Blood Sugar</h5>
                  <div className="card-detail-div-sub">
                    <h3 className="card-diet-personal-pat">
                      {patientdetails?.blood_sugar}
                    </h3>

                    <p>mg / dl</p>
                  </div>
                </div>
                <div className="card-detail-div-for-btn">
                  {patientdetails &&
                  patientdetails?.blood_sugar > 35 &&
                  patientdetails &&
                  patientdetails?.blood_sugar < 80 ? (
                    <button
                      className="nor-btn"
                      style={{ backgroundColor: "rgb(244,180,0)" }}
                    >
                      LOW
                    </button>
                  ) : patientdetails &&
                    patientdetails?.blood_sugar >= 80 &&
                    patientdetails &&
                    patientdetails?.blood_sugar <= 180 ? (
                    <button
                      className="nor-btn"
                      style={{ backgroundColor: "green" }}
                    >
                      Normal
                    </button>
                  ) : patientdetails &&
                    patientdetails?.blood_sugar > 180 &&
                    patientdetails ? (
                    <button
                      className="nor-btn"
                      style={{ backgroundColor: "rgb(244 0 0)" }}
                    >
                      HIGH
                    </button>
                  ) : patientdetails && patientdetails?.blood_sugar < 35 ? (
                    <button className="nor-btn">ERROR</button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <div className="blood-sugar-card">
              <div className="card-logo-in-medical-history-heart">
                <GiHeartBeats
                  style={{ height: "22px", width: "22px", color: "white" }}
                />
              </div>
              <div className="card-detail-div-main-for-flex">
                <div className="card-detail-div-main">
                  <h5>Heart Rate</h5>
                  <div className="card-detail-div-sub">
                    <h3 className="card-diet-personal-pat">
                      {patientdetails?.heart_rate}
                    </h3>

                    <p>mg / dl</p>
                  </div>
                </div>
                <div className="card-detail-div-for-btn">
                  <button className="nor-btn-heart">Normal</button>
                </div>
              </div>
            </div>

            <div className="blood-sugar-card">
              <div className="card-logo-in-medical-history-blood">
                <MdBloodtype
                  style={{ height: "22px", width: "22px", color: "white" }}
                />
              </div>
              <div className="card-detail-div-main-for-flex">
                <div className="card-detail-div-main">
                  <h5>Blood Pressure</h5>
                  <div className="card-detail-div-sub">
                    <h3 className="card-diet-personal-pat">
                      {patientdetails?.blood_pressure}
                    </h3>

                    <p>/ 72 mmhg</p>
                  </div>
                </div>
                <div className="card-detail-div-for-btn">
                  {patientdetails &&
                  patientdetails?.blood_pressure < 120 &&
                  patientdetails &&
                  patientdetails?.blood_pressure > 80 ? (
                    <button
                      className="nor-btn"
                      style={{ backgroundColor: "green" }}
                    >
                      Normal
                    </button>
                  ) : patientdetails &&
                    patientdetails?.blood_pressure > 120 &&
                    patientdetails &&
                    patientdetails?.blood_pressure <= 130 ? (
                    <button
                      className="nor-btn"
                      style={{ backgroundColor: "rgb(244,180,0)" }}
                    >
                      ELEVATED
                    </button>
                  ) : patientdetails &&
                    patientdetails?.blood_pressure > 130 &&
                    patientdetails &&
                    patientdetails?.blood_pressure <= 140 ? (
                    <button
                      className="nor-btn"
                      style={{ backgroundColor: "rgb(234 131 12)" }}
                    >
                      HIGH
                    </button>
                  ) : patientdetails &&
                    patientdetails?.blood_pressure > 140 &&
                    patientdetails &&
                    patientdetails?.blood_pressure <= 180 ? (
                    <button
                      className="nor-btn"
                      style={{ backgroundColor: "rgb(250 77 25)" }}
                    >
                      HIGH
                    </button>
                  ) : patientdetails && patientdetails?.blood_pressure > 180 ? (
                    <button
                      className="nor-btn"
                      style={{ backgroundColor: "rgb(244 0 0)" }}
                    >
                      HIGH
                    </button>
                  ) : patientdetails && patientdetails?.blood_pressure < 80 ? (
                    <button className="nor-btn">ERROR</button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <div
              className="blood-sugar-card"
              onClick={onClickOpencomorbodities}
              style={{ cursor: "pointer" }}
            >
              <div className="card-logo-in-medical-history-blood">
                <MdBloodtype
                  style={{ height: "22px", width: "22px", color: "white" }}
                />
              </div>
              <div className="card-detail-div-main-for-flex">
                <div className="card-detail-div-main">
                  <h5>Comorbidities</h5>
                  <div className="card-detail-div-sub-three">
                    <h2>
                      {contracount && contracount.background_disease_count}
                    </h2>
                  </div>
                </div>
                
              </div>
              <div className="add-pat-btn-div">
        <button onClick={addPatient} className="add-btn-btn-tag">
          Save Changes
        </button>
      </div>
              
              {comorboditiesPopup ? (
                <div
                  className="contra-pop-up-main-div"
                  style={{ marginTop: "315px", marginLeft: "0px" }}
                >
                  <div className="contra-pop-up">
                    <div className="card-detail-div-sub-three">
                      {contracount &&
                        contracount.background_disease.map((index) => (
                          <p>{index}</p>
                        ))}
                    </div>                
                  </div>  
                </div>
                
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          <div className="medical-history-section-main-div-pp">

          <div className="blood-sugar-card-main-div-new">
            <div className="blood-sugar-card-for-last-update" style={{height:"75%"}}>
              <div className="card-logo-in-medical-history">
                <GiPaperBagCrumpled
                  style={{ height: "25px", width: "25px", color: "white" }}
                />
              </div>
              <div className="card-detail-div-main-for-flex">
                <div className="card-detail-div-main">
                  <h5>Blood Sugar</h5>
                  <div className="card-detail-div-sub">
                    <input
                      className="pate-dash-card-inp-tag"
                      placeholder={patientdetails?.blood_sugar}
                      value={bloodSugar}
                      onChange={(e) => setbloodSugar(e.target.value)}
                    />
                    <p>mg / dl</p>
                  </div>
                </div>
                <div className="card-detail-div-for-btn">
                  {patientdetails &&
                  patientdetails?.blood_sugar > 35 &&
                  patientdetails &&
                  patientdetails?.blood_sugar < 80 ? (
                    <button
                      className="nor-btn"
                      style={{ backgroundColor: "rgb(244,180,0)" }}
                    >
                      LOW
                    </button>
                  ) : patientdetails &&
                    patientdetails?.blood_sugar >= 80 &&
                    patientdetails &&
                    patientdetails?.blood_sugar <= 180 ? (
                    <button
                      className="nor-btn"
                      style={{ backgroundColor: "green" }}
                    >
                      Normal
                    </button>
                  ) : patientdetails &&
                    patientdetails?.blood_sugar > 180 &&
                    patientdetails ? (
                    <button
                      className="nor-btn"
                      style={{ backgroundColor: "rgb(244 0 0)" }}
                    >
                      HIGH
                    </button>
                  ) : patientdetails && patientdetails?.blood_sugar < 35 ? (
                    <button className="nor-btn">ERROR</button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="blood-sugar-update-date-div">
            <p style={{margin:"0px"}}> Last Updaed : {patientuserdetails && patientuserdetails.blood_sugar_updated_at?.slice(0,10)}</p>
            </div>
            </div>

          <div className="blood-sugar-card-main-div-new">
            <div className="blood-sugar-card-for-last-update" style={{height:"75%"}}>
              <div className="card-logo-in-medical-history-heart">
                <GiHeartBeats
                  style={{ height: "22px", width: "22px", color: "white" }}
                />
              </div>
              <div className="card-detail-div-main-for-flex">
                <div className="card-detail-div-main">
                  <h5>Heart Rate</h5>
                  <div className="card-detail-div-sub">
                    <input
                      className="pate-dash-card-inp-tag"
                      placeholder={patientdetails?.heart_rate}
                      value={heartRate}
                      onChange={(e) => setheartRate(e.target.value)}
                    />

                    <p>mg / dl</p>
                  </div>
                </div>
                <div className="card-detail-div-for-btn">
                  <button className="nor-btn-heart">Normal</button>
                </div>
              </div>
            </div>
            <div className="blood-sugar-update-date-div">
            <p style={{margin:"0px"}}> Last Updaed : {patientuserdetails && patientuserdetails.heart_rate_updated_at?.slice(0,10)}</p>
            </div>
            </div>

          <div className="blood-sugar-card-main-div-new">
            <div className="blood-sugar-card-for-last-update" style={{height:"75%"}}>
              <div className="card-logo-in-medical-history-blood">
                <MdBloodtype
                  style={{ height: "22px", width: "22px", color: "white" }}
                />
              </div>
              <div className="card-detail-div-main-for-flex">
                <div className="card-detail-div-main">
                  <h5>Blood Pressure</h5>
                  <div className="card-detail-div-sub">
                    <input
                      className="pate-dash-card-inp-tag"
                      placeholder={patientdetails?.Systolic_blood_pressure}
                      value={Systolic_blood_pressure}
                      onChange={(e) => setSystolic_blood_pressure(e.target.value)}

                      
                    />
                    <h2>/</h2>
                    <input
                      className="pate-dash-card-inp-tag"
                      // style={{color:"rgb(170,170,170)", fontSize:"10px"}}
                      placeholder={patientdetails?.Diastolic_blood_pressure}
                      value={Diastolic_blood_pressure}
                      onChange={(e) => setDiastolic_blood_pressure(e.target.value)}
                    />
                    <p>mmhg</p>

                  </div>
                </div>
                <div className="card-detail-div-for-btn">
                  {patientdetails &&
                  patientdetails?.blood_pressure < 120 &&
                  patientdetails &&
                  patientdetails?.blood_pressure > 80 ? (
                    <button
                      className="nor-btn"
                      style={{ backgroundColor: "green" }}
                    >
                      Normal
                    </button>
                  ) : patientdetails &&
                    patientdetails?.blood_pressure > 120 &&
                    patientdetails &&
                    patientdetails?.blood_pressure <= 130 ? (
                    <button
                      className="nor-btn"
                      style={{ backgroundColor: "rgb(244,180,0)" }}
                    >
                      ELEVATED
                    </button>
                  ) : patientdetails &&
                    patientdetails?.blood_pressure > 130 &&
                    patientdetails &&
                    patientdetails?.blood_pressure <= 140 ? (
                    <button
                      className="nor-btn"
                      style={{ backgroundColor: "rgb(234 131 12)" }}
                    >
                      HIGH
                    </button>
                  ) : patientdetails &&
                    patientdetails?.blood_pressure > 140 &&
                    patientdetails &&
                    patientdetails?.blood_pressure <= 180 ? (
                    <button
                      className="nor-btn"
                      style={{ backgroundColor: "rgb(250 77 25)" }}
                    >
                      HIGH
                    </button>
                  ) : patientdetails && patientdetails?.blood_pressure > 180 ? (
                    <button
                      className="nor-btn"
                      style={{ backgroundColor: "rgb(244 0 0)" }}
                    >
                      HIGH
                    </button>
                  ) : patientdetails && patientdetails?.blood_pressure < 80 ? (
                    <button className="nor-btn">ERROR</button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="blood-sugar-update-date-div">
            <p style={{margin:"0px"}}> Last Updaed : {patientuserdetails && patientuserdetails.blood_pressure_updated_at?.slice(0,10)}</p>
            </div>
            </div>

            {clinic == "true" ? (
              <Link to="/clinicsidebar/prescriptionbydoctorclinicview">
                <div className="blood-sugar-card" style={{ cursor: "pointer" }}>
                  <div className="card-logo-in-medical-history-drugs">
                    <GiMedicines
                      style={{
                        height: "22px",
                        width: "22px",
                        color: "white",
                      }}
                    />
                  </div>
                  <div className="card-detail-div-main-for-flex">
                    <div className="card-detail-div-main">
                      <h5>Drugs Prescribed</h5>
                      <div className="card-detail-div-sub-three">
                        <h4>{medicine_name}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ) : (
              <Link to="/dashboard/patientprescription">
                <div className="blood-sugar-card" style={{ cursor: "pointer" }}>
                  <div className="card-logo-in-medical-history-drugs">
                    <GiMedicines
                      style={{
                        height: "22px",
                        width: "22px",
                        color: "white",
                      }}
                    />
                  </div>
                  <div className="card-detail-div-main-for-flex">
                    <div className="card-detail-div-main">
                      <h5>Drugs Prescribed</h5>
                      <div className="card-detail-div-sub-three">
                        <h4>{medicine_name}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            )}{" "}
            <div className="Comorbidities-card">
            <div
              className="blood-sugar-card"
              onClick={onClickOpencomorbodities}
              style={{ cursor: "pointer" }}
            >
              <div className="card-logo-in-medical-history-blood">
                <MdBloodtype
                  style={{ height: "22px", width: "22px", color: "white" }}
                />
              </div>
              <div className="card-detail-div-main-for-flex">
                <div className="card-detail-div-main">
                  <h5>Comorbidities</h5>
                  <div className="card-detail-div-sub-three">
                    <h2>
                      {contracount && contracount.background_disease_count}
                    </h2>
                  </div>
                </div>
              </div>
              {comorboditiesPopup ? (
                <div className="purchase-order-details-pop-up-main-div">
                  <div className="purchase-order-details-pop-up-sub-div">
                    <div className="purchase-order-details-pop-up-sub-div-container">
                      <div className="purchase-order-details-pop-up-sub-div-container-one">
                        <div className="doctor-patient-prescription-sub-div-for-popup-close-div">
                          <AiOutlineClose
                            className="close-btn-in-pop-up-of-presc"
                            onClick={onClickOpencomorbodities}
                          />
                        </div>
                        <div
                        className="appointment-list-sub-div-one"
                        style={{ marginBottom: "20px" }}
                      >
                        <h3>Comorbidities</h3>
                      </div>
                        <div className="card-detail-div-sub-three">
                          {contracount &&
                            contracount.background_disease.map((index) => (
                              <li>{index}</li>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="add-pat-btn-div">
        <button onClick={addPatient} className="add-btn-btn-tag">
          Save Changes
        </button>
      </div>
      </div>
          </div>
        )
        
      )
      
      }

        {/* {isdietitian == "true" || istrainer == "true" ? (
          ""
        ) : (
          <div className="add-pat-btn-div">
            <button onClick={addPatient} className="add-btn-btn-tag">
              Save Changes
            </button>
          </div>
        )} */}
        
        

        <div className="pheno-title-main" style={{ paddingBottom: "20px" }}>
          <h5>Phenotype Drilldown</h5>
        </div>

        {isdietitian == "true" || istrainer == "true" ? (
          <div
            className="blood-sugar-card"
            style={{ display: "block", padding: "10px", height: "auto" }}
          >
            {sorted && sorted.map((index) => <p>{index}</p>)}
          </div>
        ) : (
          <div className="pheno-div-section-tag">
            <div
              className="pheno-drill-doughnut-main-div"
              style={{
                paddingLeft: !popup ? "120px" : "",
                transitionDuration: "2s",
              }}
              
            >
              <PhenotypeDrilldownDoughnut />
              <PhenotypeDrilldownDoughnutTotal />
            </div>
            {/* {popup ? (
              <div className="main">
                <div className="popup">
                  <div className="pip-up-main-div">
                    <div className="pop-up-sub-div-one">
                      <h4>Age Distribution</h4>
                      <div>
                        <ProgressBar />
                      </div>
                    </div>
                    <div className="pop-up-sub-div-one">
                      <h4 style={{ paddingBottom: "5.5vh" }}>
                        Gender Distribution
                      </h4>
                      <div className="pop-up-sub-div-two">
                        <div className="female-sou-ghnut-main-div-new">
                          <FemaleDoughnut />
                        </div>
                        <div className="male-sou-ghnut-main-div-new" >
                          <MaleDoughnut />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )} */}

            <div className="pheno-drill-sub-sec-two">
              {/* <div
                className="blood-sugar-card-in-pheno"
                onClick={onClickOpen}
                style={{ cursor: "pointer" }}
              >
                <div className="card-first-sec-div">
                  <div className="card-logo-in-contra-diction">
                    <GiLoveInjection
                      style={{
                        height: "25px",
                        width: "25px",
                        color: "white",
                      }}
                    />
                  </div>
                  <div className="card-detail-div-main-for-flex">
                    <div className="card-detail-div-main">
                      <h5>Contraindications</h5>
                    </div>
                  </div>
                </div>
                <div className="contra-dic-text">
                  <h2>{contracount && contracount.contraindications_count}</h2>
                </div>
              </div> */}

              {contraPopup ? (
                <div className="purchase-order-details-pop-up-main-div">
                  <div className="purchase-order-details-pop-up-sub-div">
                    <div className="purchase-order-details-pop-up-sub-div-container">
                      <div className="purchase-order-details-pop-up-sub-div-container-one">
                        <div className="doctor-patient-prescription-sub-div-for-popup-close-div">
                          <AiOutlineClose
                            className="close-btn-in-pop-up-of-presc"
                            onClick={onClickOpen}
                          />
                        </div>
                        <div
                        className="appointment-list-sub-div-one"
                        style={{ marginBottom: "20px" }}
                      >
                        <h3>Contraindications</h3>
                      </div>
                        {contracount &&
                          contracount.contraindications_match_list.map(
                            (index) => <li>{index}</li>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {/* <div
                className="blood-sugar-card-in-pheno-two"
                onClick={onClickOpenDrugInteractionPopup}
                style={{ cursor: "pointer" }}
              >
                <div className="card-first-sec-div">
                  <div className="card-logo-in-medical-history-drugs">
                    <GiMedicines
                      style={{
                        height: "25px",
                        width: "25px",
                        color: "white",
                      }}
                    />
                  </div>
                  <div className="card-detail-div-main-for-flex">
                    <div className="card-detail-div-main">
                      <h5>Drug drug interactions</h5>
                    </div>
                  </div>
                </div>
                <div className="contra-dic-text">
                  <h2>{contracount && contracount.drug_interaction_count}</h2>
                </div>
              </div> */}

              {/* {DrugInteractionPopup ? (
                <div className="purchase-order-details-pop-up-main-div">
                  <div className="purchase-order-details-pop-up-sub-div">
                    <div className="purchase-order-details-pop-up-sub-div-container">
                      <div className="purchase-order-details-pop-up-sub-div-container-one">
                        <div className="doctor-patient-prescription-sub-div-for-popup-close-div">
                          <AiOutlineClose
                            className="close-btn-in-pop-up-of-presc"
                            onClick={onClickOpenDrugInteractionPopup}
                          />
                        </div>

                        <div
                          className="appointment-list-sub-div-one"
                          style={{ marginBottom: "20px" }}
                        >
                          <h3>Drug Drug Interactions</h3>
                        </div>
                        {contracount &&
                          contracount.drug_interaction_match_list.map(
                            (index) => <li>{index}</li>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )} */}
            </div>
          </div>
        )}

        {isdietitian == "true" ? (
          <div className="pheno-title-main">
            <h5>Diet Prescribed</h5>
            <PatientDiet />
          </div>
        ) : istrainer == "true" ? (
          <div className="pheno-title-main">
            <h5>Exercise Prescribed</h5>
            <PatientExercise />
          </div>
        ) : (
          ""
        )}

        <div className="pheno-title-main">
          <h5>Lifestyle</h5>
        </div>
        <div className="medical-history-section-main-div-pp">
          <div className="blood-sugar-card">
            <div className="card-logo-in-medical-history">
              <GiPaperBagCrumpled
                style={{ height: "25px", width: "25px", color: "white" }}
              />
            </div>
            <div className="card-detail-div-main-for-flex">
              <div className="card-detail-div-main">
                <h5>Calories Consumed</h5>

                <div className="card-detail-div-sub">
                  <h4>
                    {patientuserdetails && patientuserdetails.total_calories}
                  </h4>
                  <p>kCal</p>
                </div>
              </div>
              <div className="card-detail-div-for-btn">
                <button className="nor-btn">High</button>
              </div>
            </div>
          </div>
          <div className="blood-sugar-card">
            <div className="card-logo-in-medical-history">
              <GiPaperBagCrumpled
                style={{ height: "25px", width: "25px", color: "white" }}
              />
            </div>
            <div className="card-detail-div-main-for-flex">
              <div className="card-detail-div-main">
                <h5>Physical Activity</h5>

                <div className="card-detail-div-sub">
                  <h4>
                    {patientuserdetails && patientuserdetails.last_exercise}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          {/* 
            <div className="blood-sugar-card">
              <div className="card-logo-in-medical-history">
                <GiPaperBagCrumpled
                  style={{ height: "25px", width: "25px", color: "white" }}
                />
              </div>
              <div className="card-detail-div-main-for-flex">
                <div className="card-detail-div-main">
                  <h5>Physical Activity</h5>

                  <div className="card-detail-div-sub">
                    <h4>Once</h4>
                    <p>every week</p>
                  </div>
                </div>
                <div className="card-detail-div-for-btn">
                  <button className="nor-btn">Low</button>
                </div>
              </div>
            </div>
*/}
          <div className="blood-sugar-card" style={{}}>
            <div className="card-logo-in-medical-history">
              <FcSportsMode style={{ height: "25px", width: "25px" }} />
            </div>
            <div className="card-detail-div-main-for-flex">
              <div className="card-detail-div-main">
                <h5>Sport Activity</h5>

                <div
                  className="card-detail-div-sub"
                  style={{ cursor: "pointer" }}
                >
                  <h4>
                    {patientuserdetails &&
                      patientuserdetails.total_calories_burn}
                  </h4>
                  <p>Calories Burned</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pheno-title-main" style={{ paddingBottom: "20px" }}>
          <h5>Patient BMI</h5>
        </div>

        <div
                className="personal-patient-file-bmi-flex"
                style={{ display: "flex", justifyContent: "left", gap: "30px" }}
              >
                {dataByMonth &&
                  Object.values(dataByMonth).map((index) => (
                    <Card key={index.id} data={index} />
                  ))}
              </div>

              <div style={{ display: "flex", justifyContent: "center" }}>
                {ShowBMIbyWeek == true ? (
                  <button
                    onClick={toggleShowBMIbyWeek}
                    className="male-female-btn-div-active"
                  >
                    Hide BMI By Week
                  </button>
                ) : (
                  <button
                    onClick={toggleShowBMIbyWeek}
                    className="male-female-btn-div-active"
                  >
                    Show BMI By Week
                  </button>
                )}
              </div>

              {ShowBMIbyWeek && (
                <div className="bmi-cards-main-div-in-patient-dashboard">
                  {dataByMonth &&
                    Object.values(dataByWeek).map((index) => (
                      <CardbyWeek key={index.id} data={index} />
                    ))}
                </div>
              )}

      </div>
    </div>
    
  );
};

export default MedicalHistory;
