import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { VideoSDKMeeting } from "@videosdk.live/rtc-js-prebuilt";
import CallNotesPost from "../CallNotes/CallNotesPost";
import "./Prebuilt.css";

export default function VideoNew() {
  const PatientID = localStorage.getItem("PatientUsernameforSummary");
  const is_patient = localStorage.getItem("is_patient");
  const is_doctor = localStorage.getItem("is_doctor");
  const navigatetwo = useNavigate();

  useEffect(() => {
    const token =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiIyM2VmM2EzYi0zNjVlLTQ5YmEtYTVmOS0zNTg5ZDczYzIwYmQiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTY4OTA3OTA3NywiZXhwIjoxNzIwNjE1MDc3fQ.7mpBS1qywHFLsEOodIa_FFvvdTBNhByXU-vE-bsdjD8";
    const meetingId = "milkyway";
    const name = "Demo User";
    const is_patient = localStorage.getItem("is_patient");

    const config = {
      theme: "DARK",
      name: name,
      meetingId: meetingId,
      token: token,

      region: "sg001", // region for new meeting

      containerId: "video-meeting",

      redirectOnLeave:
        is_patient == "true"
          ? "http://localhost:3000/patientdashboard/"
          : "http://localhost:3000/dashboard/",

      micEnabled: true,
      webcamEnabled: true,
      participantCanToggleSelfWebcam: true,
      participantCanToggleSelfMic: true,
      participantCanLeave: true, // if false, leave button won't be visible

      chatEnabled: true,
      screenShareEnabled: true,
      pollEnabled: true,
      whiteboardEnabled: true,
      raiseHandEnabled: true,
      

      recording: {
        autoStart: false, // auto start recording on participant joined
        enabled: true,
        webhookUrl: "https://www.videosdk.live/callback",
        awsDirPath: `/meeting-recordings/${meetingId}/`, // automatically save recording in this s3 path
      },

      livestream: {
        autoStart: true,
        enabled: true,
      },

      layout: {
        type: "SPOTLIGHT", // "SPOTLIGHT" | "SIDEBAR" | "GRID"
        priority: "PIN", // "SPEAKER" | "PIN",
        // gridSize: 3,
      },

      branding: {
        enabled: true,
        logoURL:
          "https://media-exp1.licdn.com/dms/image/C560BAQGmCFvTZKy-Nw/company-logo_200_200/0/1570352872455?e=2159024400&v=beta&t=P41OBQcIYXUkzoxCw4N22TOrxgUiCb2s0GBNVwGsqso",
        name: "SK Pharma",
        poweredBy: false,
      },

      permissions: {
        pin: true,
        askToJoin: false, // Ask joined participants for entry in meeting
        toggleParticipantMic: true, // Can toggle other participant's mic
        toggleParticipantWebcam: true, // Can toggle other participant's webcam
        drawOnWhiteboard: true, // Can draw on whiteboard
        toggleWhiteboard: true, // Can toggle whiteboard
        toggleRecording: true, // Can toggle meeting recording
        toggleLivestream: true, //can toggle live stream
        removeParticipant: true, // Can remove participant
        endMeeting: true, // Can end meeting
        changeLayout: true, //can change layout
      },

      joinScreen: {
        visible: true, // Show the join screen ?
        title: "Meeting with Doctor", // Meeting title
        meetingUrl: window.location.href, // Meeting joining url
      },

      leftScreen: {
        // visible when redirect on leave not provieded
        actionButton: {
          // optional action button
          label: "Go Live", // action button label
          href: "https://djangoobesitychat.azurewebsites.net/", // action button href
        },
      },

      notificationSoundEnabled: true,

      debug: true, // pop up error during invalid config or netwrok error

      maxResolution: "sd", // "hd" or "sd"

      // For more features check: /prebuilt/guide/prebuilt-video-and-audio-calling/getting-started
    };

    const meeting = new VideoSDKMeeting();
    meeting.init(config);
  }, []);

  return (
    <body>
      {is_patient==="true" && <button className="add-btn-btn-tag" style={{paddingBottom: "10px", marginBottom:'10px', marginLeft: '10px'}} onClick={() => navigatetwo("/patientdashboard/")}>Back to Dashboard</button>}
      <div id="video-meeting" style={{ height: "70vh" }}></div>
      
      {is_doctor==="true" && <CallNotesPost />}
    </body>
  );
}
