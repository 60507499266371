import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";
import "./BlogMainPage.css";
import Apiservice from "../../Services/Apiservice";

const BlogCard = (props) => {
  const doctor = localStorage.getItem("is_doctor");
  const patient = localStorage.getItem("is_patient");
  const clinic = localStorage.getItem("is_clinic");
  const subclinic = localStorage.getItem("is_subclinic");

  return (
    <div className="blog-main-page-sub-card">
      <div className="blog-sub-card-img">
        <img src={props.imageurl} alt="" className="blog-sub-card-img" />
      </div>
      <div className="blog-sub-card-text-div">
        <div className="blog-sub-card-date">
          <p>November 25,2022</p>
        </div>
        <div className="blog-sub-card-header">
          <h3>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
            sit amet ultrices metus, aliquam tincidunt massa.{" "}
          </h3>
        </div>
        <div className="blog-sub-card-para">
          <p>
            Praesent at tortor vel nulla rutrum consequat eu sit amet dui.
            Mauris ut metus ut dolor pretium aliquam. Mauris id nulla a arcu
            iaculis aliquet. Integer ac tempus est. Proin feugiat metus nunc, in
            viverra libero varius at.
          </p>
        </div>

        <Link to="/dashboard/blogbydoctor">
          <div className="add-pat-btn-div">
            <button className="read-more-btn">Read More</button>
          </div>
        </Link>
      </div>
    </div>
  );
};

const BlogMainPage = () => {
  const [blogdetails, setblogdetails] = useState([]);
  const [blogdetailspagination, setblogdetailspagination] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openBlogId, setOpenBlogId] = useState(null);
  const is_clinic = localStorage.getItem("is_clinic");
  const is_doctor = localStorage.getItem("is_doctor");
  const is_subclinic = localStorage.getItem("is_subclinic");

  const[next,setnext]=useState(null)

  useEffect(() => {
    Apiservice
      .get(
        next == null ?
        `/blogapi/`
        : next
        , {})

      .then((response) => {
        console.log("response",response?.data)
        setblogdetails(response?.data);
        setblogdetailspagination(response.data["next"])
        // // //console.log("setblogdetails", response.data);
        setLoading(false);
      })
      .catch((err) => {});
  }, [next]);

  console.log("blogdetails",blogdetails)
  // //console.log("next",next)

  const handleClick = (key) => {
    localStorage.setItem("BlogID", key);
    setOpenBlogId(key);
  };

  const NextClick = () =>{
    setnext(blogdetailspagination)
  }

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop = document.documentElement.scrollTop;
      const scrollBottom = scrollTop + windowHeight;
      if (scrollBottom === documentHeight) {
        Apiservice.get(`/blogapi/`).then((response) => {
          setblogdetails((prevBlogDetails) => [...prevBlogDetails, ...response.data]);
        }).catch((err) => {});
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // //console.log("next",next)

  return (
    <div className="blog-main-page-flex-div">
    {loading ? (
      <Loader />
    ) : (
      <div className="blog-main-page-width-div">
     
        <div className="blog-main-page-main-div-one">
          <h2>The Blog</h2>
          {
            is_doctor == "true" ? (
              <div className="add-pat-btn-div-blo">
                <Link to="/dashboard/doctorcreateblog">
                  <button className="read-more-btn">Create Blog</button>
                </Link>
              </div>
            ) : is_clinic == "true" ?(
              <div className="add-pat-btn-div-blo">  
                <Link to="/clinicsidebar/doctorcreateblog">
                  <button className="read-more-btn">Create Blog</button>
                </Link>
              </div>  
            ) : (
              <div className="add-pat-btn-div-blo">  
                <Link to="/subclinicsidebar/doctorcreateblog">
                  <button className="read-more-btn">Create Blog</button>
                </Link>
              </div>
            )
          }

        <div className="blog-main-page-main-div">
          <div className="blog-main-page-sub-div-one">
            <img
              src="https://wallpaperaccess.com/full/1561053.jpg"
              alt=""
              className="blog-main-page-main-imag"
            />
          </div>
          <div className="blog-main-page-sub-div-two">
            <div className="blog-main-page-sub-div-two-for-date">
              <p>December 12,2022</p>
            </div>
            <div className="blog-main-page-sub-div-two-for-header">
              <h3>
                Neque porro quisquam est qui dolorem ipsum quia dolor sit amet,
                consectetur, adipisci velit...
              </h3>
            </div>
            <div className="blog-main-page-sub-div-two-for-para">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur sit amet ultrices metus, aliquam tincidunt massa. Sed
                cursus purus ex, eu blandit magna facilisis at. Morbi elementum
                quam non magna rhoncus pharetra. Aliquam sit amet egestas diam.
                Nulla ac ipsum risus. Donec rhoncus tellus ac euismod finibus.
              </p>
            </div>
          </div>
        </div>

        <div className="blog-main-page-main-div-two">
          <div className="blog-main-page-sub-card">
            <div className="blog-sub-card-img">
              <img
                src="https://st2.depositphotos.com/4924139/10750/i/950/depositphotos_107509340-stock-photo-high-resolution-3d-render-of.jpg"
                alt=""
                className="blog-sub-card-img"
              />
            </div>
            <div className="blog-sub-card-text-div">
              <div className="blog-sub-card-date">
                <p>January 02,2023</p>
              </div>
              <div className="blog-sub-card-header">
                <h3>
                  Hungry Brain
                  <br />
                  Hungry Gut
                  <br />
                  Emotional Hunger
                  <br />
                  Slow Burn
                </h3>
              </div>
              <div className="blog-sub-card-para">
                <p>
                  Excessive calory consumption without feeling full Delayed
                  satiation during meal the brain is unable to determine when to
                  terminate a meal caused by genetics\metabolic factors
                </p>
              </div>
              <Link to="/dashboard/bloghomepage">
                <div className="add-pat-btn-div">
                  <button className="read-more-btn">Read More</button>
                </div>
              </Link>
            </div>
          </div>
          {/*<BlogCard imageurl="https://wallpaperaccess.com/full/1561052.jpg" />
          <BlogCard imageurl="https://www.hdwallpapers.net/previews/dna-molecular-model-480.jpg" />
          <BlogCard imageurl="https://w0.peakpx.com/wallpaper/806/226/HD-wallpaper-dna-tablet.jpg" />
          <BlogCard imageurl="https://images3.alphacoders.com/110/1106561.jpg" />
          <BlogCard imageurl="https://c0.wallpaperflare.com/preview/262/991/578/dna-genetic-material-helix-proteins-thumbnail.jpg" />*/}

          {Array.isArray(blogdetails) && blogdetails.map((index) => (
            <div className="blog-main-page-sub-card" style={{position:"relative"}}>
              <div className="blog-sub-card-img">
                <img
                  src="https://www.hdwallpapers.net/previews/dna-molecular-model-480.jpg"
                  alt=""
                  className="blog-sub-card-img"
                />
              </div>
              <div className="blog-sub-card-text-div">
                <div className="blog-sub-card-date">
                  <p>{index.created_data}</p>
                </div>
                <div className="blog-sub-card-header">
                  <h3>
                    {index.title}
                  </h3>
                </div>
                <div className="blog-sub-card-para">
                  <p>
                    {index.summary}
                  </p>
                </div>

                {openBlogId !== index.blog_id && (
                  <Link to="/dashboard/blogbydoctor">
                    <div
                      className="add-pat-btn-div"
                      style={{ position: "absolute", bottom: "5px", marginLeft: "125px" }}
                    >
                      <button
                        className="read-more-btn"
                        onClick={() => handleClick(index.blog_id)}
                      >
                        Read More
                      </button>
                    </div>
                  </Link>
                )}
              </div>
              {openBlogId === index.blog_id && (
                <div className="blog-sub-card">
                  <div className="blog-sub-card-heading">
                    <h3>{index.title}</h3>
                  </div>
                  <div className="blog-sub-card-para">
                    <p>{index.content}</p>
                  </div>
                </div>
              )}
            </div>
          ))}
          </div>
          </div>
    </div>
    )}
    </div>
  );
};

export default BlogMainPage;
