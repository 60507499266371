import React from "react";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import "./PatientDocuments.css";
import Loader from "../Loader/Loader";
import swal from "sweetalert";
import Apiservice from "../../Services/Apiservice";
import { useGet } from "../../Services/ApiServiceReactQuery";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PreviewIcon from '@mui/icons-material/Preview';

const PatientDocuments = () => {
  // a local state to store the currently selected file.
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [testname, settestname] = React.useState("");
  const [patientuserdetails, setpatientuserdetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setsubmitLoading] = useState(false);
  const { mutate } = useGet("/medicaltestapi/${Username}/");

  const [download, setdownload] = useState();
  const userID = localStorage.getItem("id");
  // const Username = localStorage.getItem("Username");

  const is_patient = localStorage.getItem("is_patient");

  const Username =
    is_patient == "true"
      ? localStorage.getItem("Username")
      : localStorage.getItem("patientUserName");

  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }
  const csrftoken = getCookie("csrftoken");
  const { data: fetchData } = useGet(`/medicaltestapi/${Username}/`);

  useEffect(() => {
    if (fetchData) {
      setpatientuserdetails(fetchData);
      setLoading(false);
    }
  }, [fetchData]);

  console.log("patientuserdetails::", patientuserdetails);
  console.log("fetchData::", fetchData);

  // useEffect(() => {
  //   // use mutate to get the data
  //   mutate({
  //     onSuccess: (data) => {
  //       setpatientuserdetails(data);
  //       setLoading(false);
  //     },
  //   });
  // }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setsubmitLoading(true);
    const formData = new FormData();
    formData.append("med_docs", selectedFile && selectedFile);
    formData.append("customeuser_username", Username);
    formData.append("test_name", testname);
    const medDocsField = formData.get("med_docs");
    // // //console.log(medDocsField);
    try {
      const response = await axios({
        method: "post",
        url: `https://djangoobesitychat.azurewebsites.net/medicaltestapi/${Username}/`,
        data: formData,
      });
      swal({
        title: "Your Medical Test Documents Submitted Successfully",
        className: "successAlert",
        buttons: true,
        timer: 3000,
      });
      setsubmitLoading(false);

      Apiservice
        .get(`/medicaltestapi/${Username}/`)
        .then((response) => {
          setpatientuserdetails(response.data);
        });
    } catch (error) {
      // // //console.log(error);
      setsubmitLoading(false);
    }
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleinputSelect = (event) => {
    settestname(event.target.value);
  };

  // const MedDocID = localStorage.getItem("MedDocID");

  const [MedDocID, setMedDocID] = useState();

  const DownloadURL = `https://djangoobesitychat.azurewebsites.net/filedownload/${MedDocID}/`;

  const [popup, setPop] = useState(false);
  const handleClickOpen = (id) => {
    localStorage.setItem("MedDocID", id);
    setPop(!popup);
  };

  const [showDocuments, setshowDocuments] = useState(false);
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState("")
  const handleButtonClick = (url) => {
    setSelectedDocumentUrl(url.slice(27));
    setshowDocuments(true);
  };

  const handleButtonClickClose =()=>{
    setshowDocuments(false)
  }

  //console.log("selectedDocumentUrl::",selectedDocumentUrl.slice(27))


  const handleClick = (id) => {
    // localStorage.setItem("MedDocID", id);
    setMedDocID(id);
    // //console.log('Stored MedDocID::', id);
    handleClickOpen();
  };
  

  return (
    <div>
      {submitLoading && (
        <div className="purchase-order-details-pop-up-main-div">
          <div className="purchase-order-details-pop-up-sub-div">
            <div
              className="purchase-order-details-pop-up-sub-div-container"
              style={{ background: "none" }}
            >
              <Loader />
            </div>
          </div>
        </div>
      )}

      <div>
        <form onSubmit={handleSubmit}>
          <div
            style={{
              paddingLeft: "100px",
              paddingRight: "100px",
              paddingTop: "25px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <h4 style={{ width: "50%" }}>Test Name</h4>
              <input
                placeholder="Test Name..."
                style={{ width: "50%" }}
                className="test-name-info-div-one-input"
                onChange={handleinputSelect}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "50px",
                alignItems: "center",
                width: "100%",
                paddingTop: "15px",
                paddingBottom: "20px",
              }}
            >
              <h4 style={{ width: "50%" }}>Documents</h4>
              <div style={{ width: "50%", display: "flex" }}>
                <input type="file" onChange={handleFileSelect} />
                <input type="submit" value="Upload File" />
              </div>
            </div>
          </div>
        </form>
      </div>

      <div className="second-para-div--n-docs">
        <div className="acc-setting-main-div">
          <div className="update-account-title-div">
            <h2>Existing Test Document Details</h2>
          </div>
        </div>
      </div>

      {/*<div>
      <iframe src={"https://djangoobesitychat.azurewebsites.net/filedownload/3/"} width="100%" height="500px"></iframe>
    </div>*/}

      {loading ? (
        <Loader />
      ) : (
        <div
          style={{
            height: "115vh",
            overflowY: "scroll",
            boxShadow: "rgb(0 0 0 / 35%) 0px 5px 15px",
            padding: "10px",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          
          {patientuserdetails &&
            patientuserdetails.map((index) => (
              <div
                className="test-doc-details-table"
                key={index.id}
                style={{ alignItems: "center", textAlign: "center" }}
              >
                <div className="test-table-doc-sub-div">{index.test_name}</div>
                <div className="test-table-doc-sub-div">
                  <button
                    className="update-btn-btn-tag"
                    onClick={() => handleClick(index.id)}
                  >
                    <FileDownloadIcon />
                  </button>
                </div>
                <div className="test-table-doc-sub-div">
                  <button
                    className="update-btn-btn-tag"
                    onClick={() => handleButtonClick(index.med_docs)}
                  >
                    <PreviewIcon />
                  </button>
                </div>

                {showDocuments && (
                  <div className="purchase-order-details-pop-up-main-div">
                    <div className="purchase-order-details-pop-up-sub-div">
                      <div
                        className="purchase-order-details-pop-up-sub-div-container"
                        style={{ background: "none" }}
                      >
                        <div style={{ display: "block" }}>
                          <div>
                            <button   className="update-btn-btn-tag" onClick={handleButtonClickClose}>Close</button>
                          </div>
                          <div>
                            <iframe
                              src={`https://djangoobesitychat.azurewebsites.net${selectedDocumentUrl}`}
                              title="Patient Documents"
                              width="600px"
                              height="500px"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>
      )}
      {popup ? (
        <div className="msg-download-popup">
          <p>Do You Want to Download Selected File ?</p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <a href={DownloadURL}>
              <button
                className="download-confirm-btn-tag"
                style={{ color: "white", backgroundColor: "rgb(49,163,214)" }}
                onClick={handleClickOpen}
              >
                Yes
              </button>
            </a>
            <button
              onClick={handleClick}
              className="download-confirm-btn-tag"
              style={{ color: "white", backgroundColor: "rgb(49,163,214)" }}
            >
              No
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default PatientDocuments;
