import * as React from "react";
import { useState, useEffect } from "react";
// import "./PatientList.css";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaFilter } from "react-icons/fa";
import { AiFillAppstore } from "react-icons/ai";
import Add from "@mui/icons-material/Add";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import PatientLogo from "../../Images/ClinicSVG/newdoctor.svg";

const TrainerPatientList = () => {
  // GET PATIENT DETAILS
  const [trainerdetails, settrainerdetails] = useState([]);
  const [patientdetails, setpatientdetails] = useState([]);

  const savedtoken = localStorage.getItem("SavedToken");
  //   const clinic = localStorage.getItem("is_clinic");

  const [latestweightdetails, setlatestweightdetails] = useState([]);

  const [loading, setLoading] = useState(true);
  //   const clinciID = localStorage.getItem("ClinicIDForHomepage")

  const trainer_id = localStorage.getItem("Username");

  // CURRENNTW WEIGHT GET
  useEffect(() => {
    axios
      .get(`https://djangoobesitychat.azurewebsites.net/user/latestwaightapi/`, {
        headers: {
          Authorization: `Token ${savedtoken}`,
        },
      })
      .then((response) => {
        setlatestweightdetails(response.data);
      })
      .catch((err) => {});
  }, []);

  // const combinedPatientDetails =
  // patientdetails &&
  // patientdetails.map((revisitItem) => {
  //   const myPatientsItem =
  //     latestweightdetails &&
  //     latestweightdetails.find(
  //       (item) => item && item.patient_name === revisitItem.patient_name
  //     );
  //   return myPatientsItem
  //     ? { ...myPatientsItem,...revisitItem }
  //     : revisitItem;
  // });

  const combinedData =
    patientdetails.patient_list &&
    patientdetails.patient_list.map((revisitItem) => {
      const myPatientsItem =
        patientdetails.my_patients &&
        patientdetails.my_patients.find(
          (item) => item && item.patient_id_id === revisitItem.patient_name__id
        );
      return myPatientsItem && { ...revisitItem, ...myPatientsItem };
    });

    //console.log("combinedData",combinedData)

  const uniqueIds = [];
  const uniquePatients =
    combinedData &&
    combinedData.filter((element) => {
      const isDuplicate = uniqueIds.includes(
        element && element.patient_name__id
      );

      if (!isDuplicate) {
        uniqueIds.push(element && element.patient_name__id);
        return true;
      }
      return false;
    });

    //console.log("uniquePatients",uniquePatients)

  const combinedPatientDetails =
    uniquePatients &&
    uniquePatients.map((revisitItem) => {
      const myPatientsItem =
        latestweightdetails &&
        latestweightdetails.find(
          (item) =>
            item && item.patient_name === revisitItem.patient_name__username
        );
      // let PatientID = revisitItem.id
      // //console.log("myPatientsItem",myPatientsItem)
      return myPatientsItem
        ? { ...myPatientsItem, ...revisitItem }
        : revisitItem;
    });

  useEffect(() => {
    axios

      // .get(`https://djangoobesitychat.azurewebsites.net/user/patient/trainer/${trainer_id}/`,
      .get(`https://djangoobesitychat.azurewebsites.net/trainer/patient/${trainer_id}/`, {
        headers: {
          Authorization: `Token ${savedtoken}`,
        },
      })

      .then((response) => {
        // settrainerdetails(response.data);
        setpatientdetails(response.data);

        // // // //console.log("ASD",response.data)
        setLoading(false);
      })
      .catch((err) => {});
  }, []);
  if (!trainerdetails) return null;

  const Row = (props) => {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    const SaveID = () => {
      localStorage.setItem("PatientID", row.id);
      localStorage.setItem("patientUserName", row.patient_name);
    };

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }} onClick={SaveID}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <RemoveIcon /> : <AddIcon />}
            </IconButton>
          </TableCell>
          <TableCell align="center">
            <img
              src={PatientLogo}
              alt=""
              style={{ height: "30px", width: "30px" }}
            />
          </TableCell>
          <Link to="/trainersidebar/personalPatientView">
            <TableCell align="center" style={{ cursor: "pointer" }}>
              {row.id}
            </TableCell>
            
            <TableCell align="center">
            {row.patient_name__firstName} {row.patient_name__lastName}
            </TableCell>
            </Link>
          <TableCell align="center">{row.age}</TableCell>
          <TableCell align="center">{Math.round(row.bmi)}</TableCell>
          <TableCell align="center">{row.current_weight}</TableCell>
          <TableCell align="center">{row.target_weight}</TableCell>
          {/*<TableCell align="center">{row.phenotype}</TableCell>*/}
          <TableCell align="center">
          {row.dispensed_drug &&
            row.dispensed_drug?.map((index) => (
              <h4>{index.medicine_name}</h4>
            ))}
            </TableCell>
          <TableCell align="center">{row.dosage}</TableCell>
          <TableCell align="center">{row.height}</TableCell>
          {/*<TableCell align="center">{row.blank}</TableCell>*/}
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <div className="coll-table-main-div">
                <div className="coll-table-id-div">
                  <h3>ID : </h3>

                  <p>#{row.id}</p>
                </div>

                <div className="coll-table-main-sub-div">
                  <div className="coll-table-sub-div-one">
                    <div className="coll-table-sub-one-section-one">
                      <h3>Gender:</h3> <p>{row.gender}</p>
                    </div>
                    <div className="coll-table-sub-one-section-one">
                      <h3>Weight Beginning:</h3> <p>{row.beginning_weight}</p>
                    </div>
                    <div className="coll-table-sub-one-section-one">
                      <h3>% Match of phenotype:</h3>{" "}
                      <p>{row.percent_match_to_phenotype}</p>
                    </div>
                  </div>
                  <div className="coll-table-sub-div-one">
                    <div className="coll-table-sub-one-section-one">
                      <h3>Date of Birth:</h3> <p>{row.date_of_birth}</p>
                    </div>
                    <div className="coll-table-sub-one-section-one">
                      <h3>Progress % according to the plan:</h3>{" "}
                      <p>{row.progress_percent_according_to_plan}</p>
                    </div>
                    <div className="coll-table-sub-one-section-one">
                      <h3>Background Diseases:</h3>{" "}
                      <p>{row.background_diseases}</p>
                    </div>
                  </div>
                  <div className="coll-table-sub-div-one">
                    <div className="coll-table-sub-one-section-one">
                      <h3>Patient Questionnaire:</h3>
                      <Link to="/trainersidebar/questionnaire">
                        <p
                          style={{
                            cursor: "pointer",
                            color: "rgb(49,163,214)",
                          }}
                        >
                          Click here
                        </p>
                      </Link>
                    </div>
                    <div className="coll-table-sub-one-section-one">
                      <h3>Patient Summary:</h3>
                      <Link to="/trainersidebar/patientviewfordoctor">
                        <p
                          style={{
                            cursor: "pointer",
                            color: "rgb(49,163,214)",
                          }}
                        >
                          Click here
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  return (
    <div>
      <div>
        <div className="paient-list-top-main-flex-div">
          <div className="paient-list-top-main-width-div">
            <div
              className="paient-list-top-main-div"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingBottom: "20px",
              }}
            >
              <div className="page-header-main-div">
                <p>Patient List</p>
              </div>

              <div
                className="table-page-middle-nav-main-div"
                style={{ paddingBottom: "0px" }}
              ></div>
            </div>
          </div>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <div style={{ padding: "20px" }}>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead
                  sx={{ backgroundColor: "rgb(49, 163, 214)", color: "white" }}
                >
                  <TableRow>
                    <TableCell />
                    <TableCell component="th" scope="row" align="center">
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          borderRight: "1px solid black",
                        }}
                      >
                        <p>Image</p>{" "}
                        <BsThreeDotsVertical
                          style={{
                            cursor: "pointer",
                            color: "rgb(49,163,214)",
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          borderRight: "1px solid black",
                        }}
                      >
                        <p>ID</p>{" "}
                        <BsThreeDotsVertical
                          style={{
                            cursor: "pointer",
                            color: "rgb(49,163,214)",
                          }}
                        />
                      </div>
                    </TableCell>

                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          borderRight: "1px solid black",
                        }}
                      >
                        <p>Name</p>
                        <BsThreeDotsVertical
                          style={{
                            cursor: "pointer",
                            color: "rgb(49,163,214)",
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          borderRight: "1px solid black",
                        }}
                      >
                        <p>Age</p>{" "}
                        <BsThreeDotsVertical
                          style={{
                            cursor: "pointer",
                            color: "rgb(49,163,214)",
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          borderRight: "1px solid black",
                        }}
                      >
                        <p>BMI</p>{" "}
                        <BsThreeDotsVertical
                          style={{
                            cursor: "pointer",
                            color: "rgb(49,163,214)",
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          borderRight: "1px solid black",
                        }}
                      >
                        <p>Weight Current</p>{" "}
                        <BsThreeDotsVertical
                          style={{
                            cursor: "pointer",
                            color: "rgb(49,163,214)",
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          borderRight: "1px solid black",
                        }}
                      >
                        <p>Weight Target</p>{" "}
                        <BsThreeDotsVertical
                          style={{
                            cursor: "pointer",
                            color: "rgb(49,163,214)",
                          }}
                        />
                      </div>
                    </TableCell>
                    {/*<TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          borderRight: "1px solid black",
                        }}
                      >
                        <p>Phenotype</p>{" "}
                        <BsThreeDotsVertical
                          style={{
                            cursor: "pointer",
                            color: "rgb(49,163,214)",
                          }}
                        />
                      </div>
                        </TableCell>*/}
                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          borderRight: "1px solid black",
                        }}
                      >
                        <p>Dispense Drug</p>{" "}
                        <BsThreeDotsVertical
                          style={{
                            cursor: "pointer",
                            color: "rgb(49,163,214)",
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          borderRight: "1px solid black",
                        }}
                      >
                        <p>Dosage</p>{" "}
                        <BsThreeDotsVertical
                          style={{
                            cursor: "pointer",
                            color: "rgb(49,163,214)",
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          borderRight: "1px solid black",
                        }}
                      >
                        <p>Height</p>{" "}
                        <BsThreeDotsVertical
                          style={{
                            cursor: "pointer",
                            color: "rgb(49,163,214)",
                          }}
                        />
                      </div>
                    </TableCell>
                    {/*<TableCell align="center"></TableCell>*/}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {combinedPatientDetails &&
                    combinedPatientDetails.map((row) => (
                      <Row key={row.id} row={row} />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
    </div>
  );
};

export default TrainerPatientList;
