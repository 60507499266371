import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { quizListAction, quizPostAction } from "../../Redux/Action/Task-action";
import swal from "sweetalert";
import EastSharpIcon from "@mui/icons-material/EastSharp";
import { Link } from "react-router-dom";
function PatientQuestionnaireEmotionalTestNew() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  

  const user_id = localStorage.getItem("Username");
  const { questionlist } = useSelector((state) => state.QuizzListReducer);
  const { success, error } = useSelector((state) => state.QuizzPostReducer);

  useEffect(() => {
    dispatch(quizListAction());
  }, []);

  const [checkList, setCheckList] = useState([]);
  const [errorRender, setErrorRender] = useState(false);
  const [successRender, setSuccessRender] = useState(false);
  const [totalAttempts, setTotalAttempts] = useState(0);
  const [totalQuestions, setTotalQuestions] = useState(0);

  const submitQuizz = (e) => {
    swal({
      title: "Are you sure you want to submit your Response?",
      text:
        "Your total attempted answers " +
        " out of " +
        " questions",
      type: "warning",
      // buttons: true,
      buttons: {
        confirm: {
          text: "Submit",
          value: true,
          visible: true,
          className: "bg-indigo-800",
          closeModal: true,
        },
        cancel: {
          text: "Cancel",
          value: false,
          visible: true,
          className: "class3",
        },
      },
    }).then((willSubmit) => {
      if (willSubmit) {
        const formData = new FormData();
        if (checkList.length > 0) {
          for (let index = 0; index < checkList.length; index++) {
            const element = checkList[index];
            formData.append("quizz", JSON.stringify(element));
          }
        }
        formData.append("user", user_id);
        formData.append("total_attempts", totalAttempts);
        formData.append("total_questions", totalQuestions);

        dispatch(quizPostAction(formData));

        setSuccessRender(true);
        setErrorRender(true);
      }
    });
  };

  useEffect(() => {
    if (questionlist?.length) {
      const questionAnsArr = [];
      {
        questionlist?.map((item) => {
          questionAnsArr.push({
            question: item.question,
            question_id: item.id,
            answer: "",
            weightage: 0,
            user: user_id,
          });
        });
      }

      setCheckList(questionAnsArr);
      setTotalQuestions(questionlist?.length);
    }
  }, [questionlist]);

  const updateList = (index, question_id, question, answer, weightage) => {
    let list = [...checkList];
    list[index].answer = answer;
    list[index].weightage = weightage;
    setCheckList(list);

    const checkAnswers = list.filter((item) => item.answer == "");
    const unanswersCount = checkAnswers.length;
    const totalAttempts = totalQuestions - unanswersCount;

    setTotalAttempts(totalAttempts);
  };

  useEffect(() => {
    if (success && successRender) {
      swal({
        title: "Response Successfully submitted",
        text:
          "Your total attempted answers " +
          totalAttempts +
          " out of " +
          totalQuestions +
          " questions !! Please Move to the Other Questionnaire...",
        className: "successAlert",
        buttons: false,
        timer: 3000,
      });
      setSuccessRender(false);
      // navigate("/patientDashboard/thankyoumsg");
    }
    if (error && errorRender) {
      swal({
        title: "Error",
        text: error,
        className: "errorAlert",
        buttons: false,
        timer: 3000,
      });
      setErrorRender(false);
    }
  }, [success, error]);

  return (
    <>
      <div className=" w-full bg-blue-100 relative h-screen	">
        <main>
          <div className="pt-6 px-4">
            <div className="xl:gap-4 my-4">
              <div className="bg-white rounded-md p-2 sm:p-6 h-full">
                <div className=" mb-12">
               
                  <div className="question-div  mb-2">
                  <div className="skip-for-now-div">
                  <Link>
                    <h3 onClick={() => {
                      submitQuizz();
                    }}>
                      Skip For Now <EastSharpIcon style={{ marginTop: "4px" }} />
                    </h3>
                  </Link>
                </div>
                    <hr/>
                    {questionlist?.map((item, index) => (
                      <div
                        className="questo-form-quesyion-ans-main-div-for-main"
                        style={{
                          display: "flex",
                          // justifyContent: "center",
                          alignItems: "center",
                          gap: "20px",
                          width: "100%",
                          borderBottom: "1px solid rgb(49,163,214)",
                        }}
                      >
                        <div
                          className="question-div w-2/5	"
                          style={{ width: "40%" }}
                        >
                          <ul>
                            <li
                              className="questo-form-questions-div"
                              style={{ width: "100%" }}
                            >
                              {index+1}. {item?.question}
                            </li>
                          </ul>
                        </div>
                        <div
                          className="radio-grops w-3/5	"
                          style={{ width: "60%" }}
                        >
                          <ul
                            className="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg 0 sm:flex dark:bg-gray-700 dark:text-white"
                            style={{
                              display: "flex",
                              width: "100%",
                              // gap: "20px",
                            }}
                          >
                            <li
                              className="w-full "
                              style={{ display: "contents", width: "25%" }}
                            >
                              <div
                                className="flex items-center pl-3"
                                style={{
                                  width: "25%",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "4px",
                                }}
                              >
                                <input
                                  id={"answer1" + item.id}
                                  type="radio"
                                  name={"answer" + item.id}
                                  className="w-4 h-4  bg-gray-100 dark:bg-gray-600"
                                  onChange={(e) => {
                                    updateList(
                                      index,
                                      item.id,
                                      item.question,
                                      item?.answer1,
                                      item?.weightage1
                                    );
                                  }}
                                />
                                <label
                                  htmlFor={"answer1" + item.id}
                                  className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                  {item?.answer1}
                                </label>
                              </div>
                            </li>
                            <li
                              className="w-full "
                              style={{ display: "contents", width: "25%" }}
                            >
                              <div
                                className="flex items-center pl-3"
                                style={{
                                  width: "25%",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "4px",
                                }}
                              >
                                <input
                                  id={"answer2" + item.id}
                                  type="radio"
                                  name={"answer" + item.id}
                                  className="w-4 h-4 bg-gray-100  dark:bg-gray-600"
                                  onChange={(e) => {
                                    updateList(
                                      index,
                                      item.id,
                                      item.question,
                                      item?.answer2,
                                      item?.weightage2
                                    );
                                  }}
                                />
                                <label
                                  htmlFor={"answer2" + item.id}
                                  className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                  {item?.answer2}
                                </label>
                              </div>
                            </li>
                            <li
                              className="w-full"
                              style={{ display: "contents", width: "25%" }}
                            >
                              <div
                                className="flex items-center pl-3"
                                style={{
                                  width: "25%",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "4px",
                                }}
                              >
                                <input
                                  id={"answer3" + item.id}
                                  type="radio"
                                  name={"answer" + item.id}
                                  className="w-4 h-4 dark:bg-gray-600"
                                  onChange={(e) => {
                                    updateList(
                                      index,
                                      item.id,
                                      item.question,
                                      item?.answer3,
                                      item?.weightage3
                                    );
                                  }}
                                />
                                <label
                                  htmlFor={"answer3" + item.id}
                                  className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                  {item?.answer3}
                                </label>
                              </div>
                            </li>
                            <li
                              className="w-full "
                              style={{ display: "contents", width: "25%" }}
                            >
                              <div
                                className="flex items-center pl-3"
                                style={{
                                  width: "25%",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "4px",
                                }}
                              >
                                <input
                                  id={"answer4" + item.id}
                                  type="radio"
                                  name={"answer" + item.id}
                                  className="w-4 h-4 dark:bg-gray-600"
                                  onChange={(e) => {
                                    updateList(
                                      index,
                                      item.id,
                                      item.question,
                                      item?.answer4,
                                      item?.weightage4
                                    );
                                  }}
                                />
                                <label
                                  htmlFor={"answer4" + item.id}
                                  className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                  {item?.answer4}
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/*
                  <div className="submit-btn float-right pr-12  ">
                    <button
                      className="bg-indigo-800 hover:bg-indigo-700 text-white  py-2 px-4 rounded mb-20"
                      onClick={() => {
                        submitQuizz();
                      }}
                      type="button"
                    >
                      Submit
                    </button>
                    </div>*/}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "10px",
                    }}
                  >
                    <button
                      onClick={() => {
                        submitQuizz();
                      }}
                      className="submit-btn-in-que"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default PatientQuestionnaireEmotionalTestNew;
