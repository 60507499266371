import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import "./SubClinicDetailsHomePage.css";
import ClinicDoctors from "../../Images/ClinicSVG/newdoctor.svg";
import ClinicPatient from "../../Images/ClinicSVG/newpatients.svg";
import { FaUserNurse } from "react-icons/fa";
import Loader from "../Loader/Loader";
import ClinicAppointments from "../../Images/ClinicSVG/newappointments.svg";

const SubClinicDetailsHomePage = () => {
  const [clinicdetails, setclinicdetails] = useState([]);
  const savedtoken = localStorage.getItem("SavedToken");
  const SubCllinicNametoRedirect = localStorage.getItem(
    "SubCllinicNametoRedirect"
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(
        `https://djangoobesitychat.azurewebsites.net/user/subclinicapi/name/${SubCllinicNametoRedirect}/`,
        {
          headers: {
            Authorization: `Token ${savedtoken}`,
          },
        }
      )

      .then((response) => {
        setclinicdetails(response.data[0]);
        // // //console.log("SubCLinic Details", response.data);
        setLoading(false);
      })
      .catch((err) => {});
  }, []);
  if (!clinicdetails) return null;

  return (
    <div className="sub-clinic-details-home-flex-div">
    {loading ? (
      <Loader />
    ) : (
      <div className="sub-clinic-details-home-width-div">
      
        <div className="sub-clinic-details-home-main-div">
          <div
            className="pateient-main-div-in-patient"
            style={{ width: "100%", backgroundColor: "rgb(236,249,255)" }}
          >
            <div className="patient-img-name-div-in-pateinet-dashboard">
              <div>
                <h3 style={{ textAlign: "center" }}>Sub Clinic Details</h3>
                <div>
                  <div className="patient-info-main-div-in-pateint-dashboard">
                    <p>Sub Clinic ID :{clinicdetails.subclinic_id} </p>
                    <h4></h4>
                  </div>
                  <div
                    className="patient-info-in-user-login-main-div"
                    style={{ paddingLeft: "" }}
                  >
                    <div
                      className="patient-user-login-main-info"
                      style={{ width: "100%" }}
                    >
                      <div
                        className="patient-user-login-main-info-one"
                        style={{ width: "50%" }}
                      >
                        <h4 className="pat-inho-h-tag">Name</h4>
                      </div>
                      <div
                        className="patient-user-login-main-info-two"
                        style={{ width: "50%" }}
                      >
                        <p className="pat-inho-p-tag">
                          {clinicdetails.subclinic_firstName}
                        </p>
                      </div>
                    </div>

                    <div
                      className="patient-user-login-main-info"
                      style={{ width: "100%" }}
                    >
                      <div
                        className="patient-user-login-main-info-one"
                        style={{ width: "50%" }}
                      >
                        <h4 className="pat-inho-h-tag">Branch Name</h4>
                      </div>
                      <div
                        className="patient-user-login-main-info-two"
                        style={{ width: "50%" }}
                      >
                        <p className="pat-inho-p-tag">
                          {clinicdetails.subclinic_lastName}
                        </p>
                      </div>
                    </div>
                    <div
                      className="patient-user-login-main-info"
                      style={{ width: "100%" }}
                    >
                      <div
                        className="patient-user-login-main-info-one"
                        style={{ width: "50%" }}
                      >
                        <h4 className="pat-inho-h-tag">Username</h4>
                      </div>
                      <div
                        className="patient-user-login-main-info-two"
                        style={{ width: "50%" }}
                      >
                        <p className="pat-inho-p-tag">
                          {clinicdetails.subclinic_name}
                        </p>
                      </div>
                    </div>

                    <div
                      className="patient-user-login-main-info"
                      style={{ width: "100%" }}
                    >
                      <div
                        className="patient-user-login-main-info-one"
                        style={{ width: "50%" }}
                      >
                        <h4 className="pat-inho-h-tag">Email</h4>
                      </div>
                      <div
                        className="patient-user-login-main-info-two"
                        style={{ width: "50%" }}
                      >
                        <p className="pat-inho-p-tag">
                          {clinicdetails.subclinic_email}
                        </p>
                      </div>
                    </div>

                    <div
                      className="patient-user-login-main-info"
                      style={{ width: "100%" }}
                    >
                      <div
                        className="patient-user-login-main-info-one"
                        style={{ width: "50%" }}
                      >
                        <h4 className="pat-inho-h-tag">Contact</h4>
                      </div>
                      <div
                        className="patient-user-login-main-info-two"
                        style={{ width: "50%" }}
                      >
                        <p className="pat-inho-p-tag">
                          {clinicdetails.subclinic_mobile}
                        </p>
                      </div>
                    </div>
                    <div
                      className="patient-user-login-main-info"
                      style={{ width: "100%" }}
                    >
                      <div
                        className="patient-user-login-main-info-one"
                        style={{ width: "50%" }}
                      >
                        <h4 className="pat-inho-h-tag">Address</h4>
                      </div>
                      <div
                        className="patient-user-login-main-info-two"
                        style={{ width: "50%" }}
                      >
                        <p className="pat-inho-p-tag">
                          {clinicdetails.subclinic_address}
                        </p>
                      </div>
                    </div>
                    <div
                      className="patient-user-login-main-info"
                      style={{ width: "100%" }}
                    >
                      <div
                        className="patient-user-login-main-info-one"
                        style={{ width: "50%" }}
                      >
                        <h4 className="pat-inho-h-tag">City</h4>
                      </div>
                      <div
                        className="patient-user-login-main-info-two"
                        style={{ width: "50%" }}
                      >
                        <p className="pat-inho-p-tag">{clinicdetails.city}</p>
                      </div>
                    </div>
                    <div
                      className="patient-user-login-main-info"
                      style={{ width: "100%" }}
                    >
                      <div
                        className="patient-user-login-main-info-one"
                        style={{ width: "50%" }}
                      >
                        <h4 className="pat-inho-h-tag">State</h4>
                      </div>
                      <div
                        className="patient-user-login-main-info-two"
                        style={{ width: "50%" }}
                      >
                        <p className="pat-inho-p-tag">{clinicdetails.state}</p>
                      </div>
                    </div>
                    <div
                      className="patient-user-login-main-info"
                      style={{ width: "100%" }}
                    >
                      <div
                        className="patient-user-login-main-info-one"
                        style={{ width: "50%" }}
                      >
                        <h4 className="pat-inho-h-tag">Country</h4>
                      </div>
                      <div
                        className="patient-user-login-main-info-two"
                        style={{ width: "50%" }}
                      >
                        <p className="pat-inho-p-tag">
                          {clinicdetails.country}
                        </p>
                      </div>
                    </div>
                    <div
                      className="patient-user-login-main-info"
                      style={{ width: "100%" }}
                    >
                      <div
                        className="patient-user-login-main-info-one"
                        style={{ width: "50%" }}
                      >
                        <h4 className="pat-inho-h-tag">Open Time</h4>
                      </div>
                      <div
                        className="patient-user-login-main-info-two"
                        style={{ width: "50%" }}
                      >
                        <p className="pat-inho-p-tag">
                          {clinicdetails.opentime}
                        </p>
                      </div>
                    </div>
                    <div
                      className="patient-user-login-main-info"
                      style={{ width: "100%" }}
                    >
                      <div
                        className="patient-user-login-main-info-one"
                        style={{ width: "50%" }}
                      >
                        <h4 className="pat-inho-h-tag">Close Time</h4>
                      </div>
                      <div
                        className="patient-user-login-main-info-two"
                        style={{ width: "50%" }}
                      >
                        <p className="pat-inho-p-tag">
                          {clinicdetails.closetime}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sub-clinic-home-main-div-two">
          <div
            className="clinic-home-sub-one-part-one-flex"
            style={{ paddingTop: "50px" }}
          >
            <Link to="/clinicsidebar/subclinicreceptionist">
              <div
                className="clinic-home-page-card"
                style={{ backgroundColor: "rgb(137 216 255)" }}
              >
                <div>
                  <FaUserNurse
                    className="clinic-home-svg"
                    style={{ color: "rgb(0,102,153)" }}
                  />
                </div>
              </div>
              <div>
                <h3 className="clinic-p-tag-data">Receptionists</h3>
              </div>
            </Link>

            <Link to="/clinicsidebar/subclinicpatients">
              <div
                className="clinic-home-page-card"
                style={{ backgroundColor: "rgb(255 206 133)" }}
              >
                <div>
                  <img src={ClinicPatient} className="clinic-home-svg" alt="" />
                </div>
              </div>
              <div>
                <h3 className="clinic-p-tag-data">Patients</h3>
              </div>
            </Link>

            <Link to="/clinicsidebar/subclinicdoctors">
              <div
                className="clinic-home-page-card"
                style={{ backgroundColor: "rgb(189 217 251)" }}
              >
                <div>
                  <img src={ClinicDoctors} className="clinic-home-svg" alt="" />
                </div>
              </div>
              <div>
                <h3 className="clinic-p-tag-data">Doctors</h3>
              </div>
            </Link>

            <Link to="/clinicsidebar/subclinicappointmentlist">
                  <div
                    className="clinic-home-page-card"
                    style={{ backgroundColor: "rgb(137 255 255)" }}
                  >
                    <div>
                      <img
                        src={ClinicAppointments}
                        className="clinic-home-svg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div>
                    <h3 className="clinic-p-tag-data">Appointments</h3>
                  </div>
                </Link>
          </div>
        </div>
      </div>
    )}
    </div>
  );
};

export default SubClinicDetailsHomePage;
