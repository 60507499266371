import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaClinicMedical } from "react-icons/fa";
import "./chattest.css";
import { useLocation } from "react-router-dom";
import { MdSend } from "react-icons/md";
import { FaAngleDown, FaUserAlt} from "react-icons/fa";
import Apiservice from "../../Services/Apiservice";
import Loader from "../Loader/Loader";
function Chat({props, user, onlineUsers}) {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [socket, setSocket] = useState(null);
  const location = useLocation();
  const state = location.state?.data;
  const [selectedUser, setSelectedUser] = useState(state);
  

  console.log( state, onlineUsers, selectedUser, messages);

  // Connect to the WebSocket when a user is selected
  useEffect(() => {
    if (selectedUser) {
      const sortedUsers = [user, selectedUser].sort().join("__");
      const chatSocket = new WebSocket(
        // `wss://djangoobesitychat.azurewebsites.net/ws/chat/${sortedUsers}/`
        `wss://djangoobesitychat.azurewebsites.net/ws/chat/${sortedUsers}/`
      );

      chatSocket.onmessage = function (e) {
        const data = JSON.parse(e.data);

        // Check if the message contains a 'message_history' field
        if (data.message_history) {
          // Update the 'messages' state with the message history
          setMessages(
            data.message_history.map((item) => ({
              message: item.message,
              sender: item.sender,
            }))
          );
        } else {
          // If not, it's a new message, so append it to the 'messages' state
          setMessages((messages) => [
            ...messages,
            { message: data.message, sender: data.sender },
          ]);
        }
      };

      chatSocket.onclose = function (e) {
        console.error("Chat socket closed unexpectedly");
      };

      setSocket(chatSocket);
      return () => {
        chatSocket.close();
      };
    }
  }, [user, selectedUser]);

  const sendMessage = (message) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify({ message, sender: user }));
    }
  };

  const handleNewMessageChange = (event) => {
    setNewMessage(event.target.value);
  };

  // const handleSend = () => {
  //   sendMessage(newMessage);
  //   setNewMessage("");
  // };

  const handleSend = (event) => {
    event.preventDefault();
    if (newMessage.trim() !== "") {
      sendMessage(newMessage);
      setNewMessage("");
    }
  };
  
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSend(event);
    }
  };

  const handleUserSelect = (event) => {
    //console.log(event);
    // setSelectedUser(onlineUsers.find((user) => user === event));
    setSelectedUser(event);
    setMessages([]);
  };

  


  //console.log("selectedUser", selectedUser);

  const is_patient = localStorage.getItem("is_patient");
  const is_doctor = localStorage.getItem("is_doctor");
  const is_clinic = localStorage.getItem("is_clinic");
  const is_dietitian = localStorage.getItem("is_dietitian");
  const is_trainer = localStorage.getItem("is_trainer");
  const is_subclinic = localStorage.getItem("is_subclinic");
  const is_receptionist = localStorage.getItem("is_receptionist");
  const Username = localStorage.getItem("Username");


  // Patient Connection
  const [doctor, setdoctor] = useState("");
  const [clinic, setclinic] = useState("");
  const [dietitian, setdietitian] = useState("");
  const [trainer, settrainer] = useState("");

  // CLINIC CONNECTION
  const [clinic_doctors_list, setclinic_doctors_list] = useState("");
  const [clinic_subclinics_list, setclinic_subclinics_list] = useState("");
  const [clinic_receptionists_list, setclinic_receptionists_list] =
    useState("");
  const [clinic_trainers_list, setclinic_trainers_list] = useState("");
  const [clinic_dietitians_list, setclinic_dietitians_list] = useState("");
  const [clinic_patient_list, setclinic_patient_list] = useState("");

  // DOCTOR CONNECTION , DIETICIAN, TRAINER
  const [doctor_patient, setdoctor_patient] = useState("");
  const [doctor_clinic, setdoctor_clinic] = useState(""); // clinic
  
  // SUBCLINIC Connection
  const [subclinic_doctors_list, setsubclinic_doctors_list] = useState("");
  const [subclinic_receptionists_list, setsubclinic_receptionists_list] = useState("");
  const [subclinic_trainers_list, setsubclinic_trainers_list] = useState("");
  const [subclinic_dietitians_list, setsubclinic_dietitians_list] = useState("");
// receptionsit connection
const[receptionist_clinic_list,setreceptionist_clinic_list]=useState("")
const[receptionist_subclinic_list,setreceptionist_subclinic_list]=useState("")
  

const[getPeople,setgetPeople]=useState(false)

  useEffect(() => {
    setgetPeople(true)
    Apiservice
      .get(
        is_patient == "true"
          ? `/chat/api/patient_connection/${Username}/`
          : is_doctor == "true"
          ? `/chat/api/doctor_connection/${Username}/`
          : is_clinic == "true"
          ? `/chat/api/clinic_connection/${Username}/`
          : is_dietitian == "true"
          ? `/chat/api/dietitian_connection/${Username}/`
          : is_trainer == "true"
          ? `/chat/api/trainer_connection/${Username}/`
          : is_subclinic == "true"
          ? `/chat/api/subclinic_connection/${Username}/`
          : is_receptionist == "true"
          ? `/chat/api/receptionist_connection/${Username}/`
          : ""
      )
      .then((responce) => {
        // Patient Connection
        setdoctor(responce.data["doctor"]);
        setclinic(responce.data["clinic"]);
        setdietitian(responce.data["dietitian"]);
        settrainer(responce.data["trainer"]);
        // CLINIC CONNECTION
        setclinic_doctors_list(responce.data["clinic_doctors_list"]);
        setclinic_subclinics_list(responce.data["clinic_subclinics_list"]);
        setclinic_receptionists_list(
          responce.data["clinic_receptionists_list"]
        );
        setclinic_trainers_list(responce.data["clinic_trainers_list"]);
        setclinic_dietitians_list(responce.data["clinic_dietitians_list"]);
        setclinic_patient_list(responce.data["clinic_patient_list"]);
        // DOCTOR CONNECTION  ===  DIETICIAN CONNECTION ==== TRAINER CONNECTION
        setdoctor_patient(responce.data["doctor_patient"]);
        setdoctor_clinic(responce.data["clinic"]);
        //  SUBCLINIC CONNECTION
        setsubclinic_doctors_list(responce.data["subclinic_doctors_list"]);
        setsubclinic_receptionists_list(responce.data["subclinic_receptionists_list"]);
        setsubclinic_trainers_list(responce.data["subclinic_trainers_list"]);
        setsubclinic_dietitians_list(responce.data["subclinic_dietitians_list"]);
        // RECEPTIONIST CONECTIO
        setreceptionist_clinic_list(responce.data["receptionist_clinic_list"]);
        setreceptionist_subclinic_list(responce.data["receptionist_subclinic_list"]);


        setgetPeople(false)
        
      });
  }, [Username]);

  return (
    <div style={{ display: "flex", height: "90vh" }}>
      {getPeople &&
      <div className="purchase-order-details-pop-up-main-div">
      <div className="purchase-order-details-pop-up-sub-div">
        <div
          className="purchase-order-details-pop-up-sub-div-container"
          style={{ background: "none" }}
        >
          <Loader />
        </div>
      </div>
    </div>
      }
      <div
        style={{
          width: "30%",
          borderRight: "1px solid #ddd",
          overflowY: "auto",
          backgroundColor: "rgb(246, 249, 254)",
          padding:"10px"
        }}
      >

        {/* <div className="chat-div" style={{backgroundColor:"rgb(71, 106, 166)"}}> */}
          {/* <h2
            style={{
              fontSize: "2rem",
              textTransform: "capitalize",
              color: "white",
            }}
          >
            Messenger
          </h2> */}
          {/* <span className="icon">
            <FaAngleDown style={{ color: "#000", fontSize: "1.5rem" }} />
          </span> */}
        {/* </div> */}

        {is_patient == "true" ? (
          <div style={{ borderBottom: "1px solid #000" }}>
            <div>
            <hr />
              <h3>Doctor</h3>

              {doctor &&
                doctor?.map((onlineUser) => (
                  <div
                    className="chat-with"
                    key={onlineUser}
                    onClick={() => handleUserSelect(onlineUser.doctor_name)}
                    style={{
                      cursor: "pointer",
                      padding: "10px",
                      display:"flex",alignItems:"center",gap:"10px"
                    }}
                  >
                    <div><FaUserAlt style={{height:"20px", width:"20px"}}/></div>
                    <p style={{ margin: "0px" }}>
                      {onlineUser.doctor_name__firstName}{" "}
                      {onlineUser.doctor_name__lastName}
                    </p>
                  </div>
                ))}
            </div>

            <div>
              <hr />
              <h3>Clinic</h3>

              {clinic &&
                clinic?.map((onlineUser) => (
                  <div
                    className="chat-with"
                    key={onlineUser}
                    onClick={() => handleUserSelect(onlineUser.clinic_name)}
                    style={{
                      cursor: "pointer",
                      padding: "10px",
                      display:"flex",alignItems:"center",gap:"10px"
                    }}
                  >
                    <div><FaUserAlt style={{height:"20px", width:"20px"}}/></div>
                    <p style={{ margin: "0px" }}>
                      {onlineUser.clinic_name__firstName}{" "}
                      {onlineUser.clinic_name__lastName}
                    </p>
                  </div>
                ))}
            </div>

            <div>
              <hr />
                {/*
 <span className="icon">
                  <img
                    className="image"
                    src="https://www.iconbunny.com/icons/media/catalog/product/2/1/2131.10-doctor-icon-iconbunny.jpg"
                    alt="doctor"
                  />
                </span>
*/}
                <h3>Dietitian</h3>
              

              {dietitian &&
                dietitian?.map((onlineUser) => (
                  <div
                    className="chat-with"
                    key={onlineUser}
                    onClick={() => handleUserSelect(onlineUser.dietitian_name)}
                    style={{
                      cursor: "pointer",
                      padding: "10px",
                      display:"flex",alignItems:"center",gap:"10px"
                    }}
                  >
                    <div><FaUserAlt style={{height:"20px", width:"20px"}}/></div>
                    <p style={{ margin: "0px" }}>
                      {onlineUser.dietitian_name__firstName}{" "}
                      {onlineUser.dietitian_name__lastName}
                    </p>
                  </div>
                ))}
            </div>

            <div>
              <hr />
                {/*
 <span className="icon">
                  <img
                    className="image"
                    src="https://www.iconbunny.com/icons/media/catalog/product/2/1/2131.10-doctor-icon-iconbunny.jpg"
                    alt="doctor"
                  />
                </span>
*/}
                <h3>Trainer</h3>
            

              {trainer &&
                trainer?.map((onlineUser) => (
                  <div
                    className="chat-with"
                    key={onlineUser}
                    onClick={() => handleUserSelect(onlineUser.trainer_name)}
                    style={{
                      cursor: "pointer",
                      padding: "10px",
                      display:"flex",alignItems:"center",gap:"10px"
                    }}
                  >
                    <div><FaUserAlt style={{height:"20px", width:"20px"}}/></div>
                    <p style={{ margin: "0px" }}>
                      {onlineUser.trainer_name__firstName}{" "}
                      {onlineUser.trainer_name__lastName}
                    </p>
                  </div>
                ))}
            </div>
          </div>
        ) : (
          ""
        )}

        <></>

        {is_doctor == "true" || is_dietitian=="true" || is_trainer =="true" ? (
          <div style={{ borderBottom: "1px solid #000" }}>
            <div>
            <hr />
              <h3>Patient</h3>


              {doctor_patient &&
                doctor_patient?.map((onlineUser) => (
                  <div
                    className="chat-with"
                    key={onlineUser}
                    onClick={() => handleUserSelect(onlineUser.patient_name)}
                    style={{
                      cursor: "pointer",
                      padding: "10px",
                      display:"flex",alignItems:"center",gap:"10px"
                    }}
                  >
                    <div><FaUserAlt style={{height:"20px", width:"20px"}}/></div>
                    <p style={{ margin: "0px" }}>
                      {onlineUser.patient_name__firstName}{" "}
                      {onlineUser.patient_name__lastName}
                    </p>
                  </div>
                ))}
            </div>

            <div>
              <hr />
              <h3>Clinic</h3>


              {doctor_clinic &&
                doctor_clinic?.map((onlineUser) => (
                  <div
                    className="chat-with"
                    key={onlineUser}
                    onClick={() => handleUserSelect(onlineUser.clinic_name)}
                    style={{
                      cursor: "pointer",
                      padding: "10px",
                      display:"flex",alignItems:"center",gap:"10px"
                    }}
                  >
                    <div><FaUserAlt style={{height:"20px", width:"20px"}}/></div>
                    <p style={{ margin: "0px" }}>
                      {onlineUser.clinic_name__firstName}{" "}
                      {onlineUser.clinic_name__lastName}
                    </p>
                  </div>
                ))}
            </div>

          </div>
        ) : (
          ""
        )}
          <></>

          {is_clinic == "true" ? (
            <div style={{ borderBottom: "1px solid #000" }}>
              <div>
              <hr />
                <h3>Doctor</h3>
              
  
                {clinic_doctors_list &&
                  clinic_doctors_list?.map((onlineUser) => (
                    <div
                      className="chat-with"
                      key={onlineUser}
                      onClick={() => handleUserSelect(onlineUser.doctors_name)}
                      style={{
                        cursor: "pointer",
                        padding: "10px",
                      display:"flex",alignItems:"center",gap:"10px"
                      }}
                    >
                    <div><FaUserAlt style={{height:"20px", width:"20px"}}/></div>
                      <p style={{ margin: "0px" }}>
                        {onlineUser.doctor_first_name}{" "}
                        {onlineUser.doctor_last_name}
                      </p>
                    </div>
                  ))}
              </div>
  
              <div>
               <hr />
                 {/*
 <span className="icon">
                    <img
                      className="image"
                      src="https://www.iconbunny.com/icons/media/catalog/product/2/1/2131.10-doctor-icon-iconbunny.jpg"
                      alt="doctor"
                    />
                  </span>
*/}
                  <h3>Sub Clinic</h3>
                
                <hr />
              
  
                {clinic &&
                  clinic?.map((onlineUser) => (
                    <div
                      className="chat-with"
                      key={onlineUser}
                      onClick={() => handleUserSelect(onlineUser.subclinic_name)}
                      style={{
                        cursor: "pointer",
                        padding: "10px",
                      display:"flex",alignItems:"center",gap:"10px"
                      }}
                    >
                    <div><FaUserAlt style={{height:"20px", width:"20px"}}/></div>
                      <p style={{ margin: "0px" }}>
                        {onlineUser.subclinic_first_name}{" "}
                        {onlineUser.subclinic_last_name}
                      </p>
                    </div>
                  ))}
              </div>
  
              <div>
                <hr />
                 {/*
 <span className="icon">
                    <img
                      className="image"
                      src="https://www.iconbunny.com/icons/media/catalog/product/2/1/2131.10-doctor-icon-iconbunny.jpg"
                      alt="doctor"
                    />
                  </span>
*/}
                  <h3>Receptionists</h3>
                
  
                {clinic_receptionists_list &&
                  clinic_receptionists_list?.map((onlineUser) => (
                    <div
                      className="chat-with"
                      key={onlineUser}
                      onClick={() => handleUserSelect(onlineUser.receptionist_name)}
                      style={{
                        cursor: "pointer",
                        padding: "10px",
                      display:"flex",alignItems:"center",gap:"10px"
                      }}
                    >
                    <div><FaUserAlt style={{height:"20px", width:"20px"}}/></div>
                      <p style={{ margin: "0px" }}>
                        {onlineUser.receptionist_first_name}{" "}
                        {onlineUser.receptionist_last_name}
                      </p>
                    </div>
                  ))}
              </div>
  
              <div>
                <hr />>
                 {/*
 <span className="icon">
                    <img
                      className="image"
                      src="https://www.iconbunny.com/icons/media/catalog/product/2/1/2131.10-doctor-icon-iconbunny.jpg"
                      alt="doctor"
                    />
                  </span>
*/}
                  <h3>Trainer</h3>
  
                {clinic_trainers_list &&
                  clinic_trainers_list?.map((onlineUser) => (
                    <div
                      className="chat-with"
                      key={onlineUser}
                      onClick={() => handleUserSelect(onlineUser.trainer_name)}
                      style={{
                        cursor: "pointer",
                        padding: "10px",
                      display:"flex",alignItems:"center",gap:"10px"
                      }}
                    >
                    <div><FaUserAlt style={{height:"20px", width:"20px"}}/></div>
                      <p style={{ margin: "0px" }}>
                        {onlineUser.trainer_first_name}{" "}
                        {onlineUser.trainer_last_name}
                      </p>
                    </div>
                  ))}
              </div>


              <div>
                <hr />>
                 {/*
 <span className="icon">
                    <img
                      className="image"
                      src="https://www.iconbunny.com/icons/media/catalog/product/2/1/2131.10-doctor-icon-iconbunny.jpg"
                      alt="doctor"
                    />
                  </span>
*/}
                  <h3>Dietician</h3>
             
  
                {clinic_dietitians_list &&
                  clinic_dietitians_list?.map((onlineUser) => (
                    <div
                      className="chat-with"
                      key={onlineUser}
                      onClick={() => handleUserSelect(onlineUser.dietitian_name)}
                      style={{
                        cursor: "pointer",
                        padding: "10px",
                      display:"flex",alignItems:"center",gap:"10px"
                      }}
                    >
                    <div><FaUserAlt style={{height:"20px", width:"20px"}}/></div>
                      <p style={{ margin: "0px" }}>
                        {onlineUser.dietitian_first_name}{" "}
                        {onlineUser.dietitian_last_name}
                      </p>
                    </div>
                  ))}
              </div>

              <div>
                <hr />
                <h3>Patient</h3>
  
                {clinic_patient_list &&
                  clinic_patient_list?.map((onlineUser) => (
                    <div
                      className="chat-with"
                      key={onlineUser}
                      onClick={() => handleUserSelect(onlineUser.patient_name)}
                      style={{
                        cursor: "pointer",
                        padding: "10px",
                      display:"flex",alignItems:"center",gap:"10px"
                      }}
                    >
                    <div><FaUserAlt style={{height:"20px", width:"20px"}}/></div>
                      <p style={{ margin: "0px" }}>
                        {onlineUser.patient_first_name}{" "}
                        {onlineUser.patient_last_name}
                      </p>
                    </div>
                  ))}
              </div>

            </div>
          ) : (
            ""
          )}

          <></>

          {is_subclinic == "true" ? (
            <div style={{ borderBottom: "1px solid #000" }}>
              <div>
              <hr />
                <h3>Doctor</h3>
  
                {subclinic_doctors_list &&
                  subclinic_doctors_list?.map((onlineUser) => (
                    <div
                      className="chat-with"
                      key={onlineUser}
                      onClick={() => handleUserSelect(onlineUser.doctors_name)}
                      style={{
                        cursor: "pointer",
                        padding: "10px",
                      display:"flex",alignItems:"center",gap:"10px"
                      }}
                    >
                    <div><FaUserAlt style={{height:"20px", width:"20px"}}/></div>
                      <p style={{ margin: "0px" }}>
                        {onlineUser.doctor_first_name}{" "}
                        {onlineUser.doctor_last_name}
                      </p>
                    </div>
                  ))}
              </div>
  
             
  
              <div>
                <hr />
                 {/*
 <span className="icon">
                    <img
                      className="image"
                      src="https://www.iconbunny.com/icons/media/catalog/product/2/1/2131.10-doctor-icon-iconbunny.jpg"
                      alt="doctor"
                    />
                  </span>
*/}
                  <h3>Receptionists</h3>
                
  
                {subclinic_receptionists_list &&
                  subclinic_receptionists_list?.map((onlineUser) => (
                    <div
                      className="chat-with"
                      key={onlineUser}
                      onClick={() => handleUserSelect(onlineUser.receptionist_name)}
                      style={{
                        cursor: "pointer",
                        padding: "10px",
                      display:"flex",alignItems:"center",gap:"10px"
                      }}
                    >
                    <div><FaUserAlt style={{height:"20px", width:"20px"}}/></div>
                      <p style={{ margin: "0px" }}>
                        {onlineUser.receptionist_first_name}{" "}
                        {onlineUser.receptionist_last_name}
                      </p>
                    </div>
                  ))}
              </div>
  
              <div>
                <hr />
                 {/*
 <span className="icon">
                    <img
                      className="image"
                      src="https://www.iconbunny.com/icons/media/catalog/product/2/1/2131.10-doctor-icon-iconbunny.jpg"
                      alt="doctor"
                    />
                  </span>
*/}
                  <h3>Trainer</h3>
                
  
                {subclinic_trainers_list &&
                  subclinic_trainers_list?.map((onlineUser) => (
                    <div
                      className="chat-with"
                      key={onlineUser}
                      onClick={() => handleUserSelect(onlineUser.trainer_name)}
                      style={{
                        cursor: "pointer",
                        padding: "10px",
                      display:"flex",alignItems:"center",gap:"10px"
                      }}
                    >
                    <div><FaUserAlt style={{height:"20px", width:"20px"}}/></div>
                      <p style={{ margin: "0px" }}>
                        {onlineUser.trainer_first_name}{" "}
                        {onlineUser.trainer_last_name}
                      </p>
                    </div>
                  ))}
              </div>


              <div>
                <hr />
                 {/*
 <span className="icon">
                    <img
                      className="image"
                      src="https://www.iconbunny.com/icons/media/catalog/product/2/1/2131.10-doctor-icon-iconbunny.jpg"
                      alt="doctor"
                    />
                  </span>
*/}
                  <h3>Dietician</h3>
                
  
                {subclinic_dietitians_list &&
                  subclinic_dietitians_list?.map((onlineUser) => (
                    <div
                      className="chat-with"
                      key={onlineUser}
                      onClick={() => handleUserSelect(onlineUser.dietitian_name)}
                      style={{
                        cursor: "pointer",
                        padding: "10px",
                      display:"flex",alignItems:"center",gap:"10px"
                      }}
                    >
                    <div><FaUserAlt style={{height:"20px", width:"20px"}}/></div>
                      <p style={{ margin: "0px" }}>
                        {onlineUser.dietitian_first_name}{" "}
                        {onlineUser.dietitian_last_name}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            ""
          )}

          <></>
          {is_receptionist == "true" ? (
            <div style={{ borderBottom: "1px solid #000" }}>
              <div>
              < hr/>
  
                {receptionist_clinic_list &&
                  receptionist_clinic_list?.map((onlineUser) => (
                    <div
                      className="chat-with"
                      key={onlineUser}
                      onClick={() => handleUserSelect(onlineUser.clinic_name)}
                      style={{
                        cursor: "pointer",
                        padding: "10px",
                      display:"flex",alignItems:"center",gap:"10px"
                      }}
                    >
                    <div><FaUserAlt style={{height:"20px", width:"20px"}}/></div>
                      <p style={{ margin: "0px" }}>
                        {onlineUser.clinic_name__firstName}{" "}
                        {onlineUser.clinic_name__lastName}
                      </p>
                    </div>
                  ))}
              </div>
  
             
  
              <div>
                <hr />
                 {/*
 <span className="icon">
                    <img
                      className="image"
                      src="https://www.iconbunny.com/icons/media/catalog/product/2/1/2131.10-doctor-icon-iconbunny.jpg"
                      alt="doctor"
                    />
                  </span>
*/}
                  <h3>Subclinic</h3>
               
  
                {receptionist_subclinic_list &&
                  receptionist_subclinic_list?.map((onlineUser) => (
                    <div
                      className="chat-with"
                      key={onlineUser}
                      onClick={() => handleUserSelect(onlineUser.subclinic_name)}
                      style={{
                        cursor: "pointer",
                        padding: "10px",
                      display:"flex",alignItems:"center",gap:"10px"
                      }}
                    >
                    <div><FaUserAlt style={{height:"20px", width:"20px"}}/></div>
                      <p style={{ margin: "0px" }}>
                        {onlineUser.subclinic_name__firstName}{" "}
                        {onlineUser.subclinic_name__lastName}
                      </p>
                    </div>
                  ))}
              </div>
  
             
            </div>
          ) : (
            ""
          )}



      </div>
      <div
        style={{
          width: "70%",
          backgroundColor: "#fff",
          padding:"10px",
          boxshadow:
            " rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
        }}
      >
        {selectedUser && (
          <>
            <div className="box-flex-shadow">
              <h2
                style={{
                  fontSize: "1.5rem",
                  textTransform: "uppercase",
                  color: "#60a7ee",
                }}
              >
                {selectedUser}
              </h2>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "80%",
                overflowY: "auto",
                padding: "10px",
                marginBottom: "10px",
              }}
            >
              {messages.map((message, index) => (
                <div
                  key={index}
                  style={{
                    margin: "10px 0",
                    backgroundColor:
                      message.sender === user ? "#0084FF" : "#ddd",
                    color: message.sender === user ? "#fff" : "#000",
                    boxShadow:
                      message.sender === user
                        ? " rgba(0, 0, 0, 0.24) 0px 3px 8px"
                        : "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    padding: "10px",
                    borderRadius: "5px",
                    alignSelf:
                      message.sender === user ? "flex-end" : "flex-start",
                  }}
                >
                  {message.message}
                </div>
              ))}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input
                className="massege-input"
                type="text"
                value={newMessage}
                onChange={handleNewMessageChange}
                placeholder="Write message..."
                style={{ width: "80%", padding: "15px", marginRight: "10px" }}
                onKeyDown={handleKeyPress}
              />
              <button
                className="hover"
                onClick={handleSend}
                style={{
                  backgroundColor: "#31a3d6",
                  color: "#fff",
                  padding: "10px 20px",
                  fontSize: "1.2em",
                  borderRadius: "5px",
                  border: "none",
                }}
              >
                <MdSend />
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Chat;
