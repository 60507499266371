import axios from 'axios';
import React, { useEffect, useState } from 'react'
import PatientQuestionnaireEmotionalTestNew from '../PatientQuestionnaire/PatientQuestionnaireEmotionalTestNew';

const QuestionnaireEmotional = () => {
  const [emotionalqueres, setemotionalqueres] = useState([]);
  const [isUpdateActive, setisUpdateActive] = useState(false);
  // const Username = localStorage.getItem("Username")
  const is_patient = localStorage.getItem("is_patient")

  const Username = 
  is_patient == "true" ?
  localStorage.getItem("Username")
: localStorage.getItem("patientUserName")

  const OpenUpdateHandler = () => {
    setisUpdateActive(true);
  };

  const CloseUpdateHandler = () => {
    setisUpdateActive(false);
  };

    useEffect(() => {
        axios
          // .get(`https://djangoobesitychat.azurewebsites.net/emotional/list-view-answer/`)
          .get(`https://djangoobesitychat.azurewebsites.net/emotional/list-view-answer/${Username}/`)
    
          .then((response) => {
            setemotionalqueres(response.data);
            // // //console.log("setemotionalqueres", response);
          })
          .catch((err) => {
            // // // //console.log(err);
          });
      }, []);
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
            {isUpdateActive === true ? (
              <button
                className="submit-btn-in-que"
                style={{ width: "175px" }}
                onClick={CloseUpdateHandler}
              >
                Discard Changes
              </button>
            ) : (
              <button
                className="submit-btn-in-que"
                style={{ width: "175px" }}
                onClick={OpenUpdateHandler}
              >
                Update Your Response
              </button>
            )}
          </div>
      {isUpdateActive ? (
        <PatientQuestionnaireEmotionalTestNew
          CloseUpdateHandler={CloseUpdateHandler}
        />
      ) : (
        // <div>
        //   <div className="test-doc-details-table">
        //     <div className="test-table-doc-sub-div">
        //       <h4>Question</h4>
        //     </div>
        //     <div className="test-table-doc-sub-div">
        //       <h4>Response</h4>
        //     </div>
        //   </div>
          emotionalqueres &&
            emotionalqueres.map((index) => (
              <div className="test-doc-details-table">
                <div className="test-table-doc-sub-div">{index.question}</div>
                <div className="test-table-doc-sub-div">
                  <p>{index.answer}</p>
                </div>
              </div>
            ))
        
      )}
    </div>
  );
};

export default QuestionnaireEmotional;
