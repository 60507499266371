import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./EmailVerification.css";
import EastSharpIcon from "@mui/icons-material/EastSharp";
import axios from "axios";
import swal from "sweetalert";

const EmailVerification = () => {
  const [response, setresponse] = useState();
  const [submitLoading, setsubmitLoading] = useState(false);
  const [otp, setotp] = useState("");
  const username = localStorage.getItem("Username");
  const navigate = useNavigate();

  const email = localStorage.getItem("email");

  const handleSubmitOTP = async (event) => {
    event.preventDefault();
    setsubmitLoading(true);
    const formDatatwo = new FormData();
    formDatatwo.append("email", email);

    axios({
      method: "post",
      url: `https://djangoobesitychat.azurewebsites.net/send-otp/`,
      data: formDatatwo,
    })
      .then((res) => {
        swal({
          title: "Please Check your Registered Mailbox for OTP...!",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });
       
        setsubmitLoading(false);
      })

      .catch((res) => {
        setresponse(res.response.data);
      });
  };

  const SubmitOtp = () => {
    axios
      .post(`https://djangoobesitychat.azurewebsites.net/email/otp/${username}/`, {
        otp: otp,
      })
      .then((res) => {
        // // //console.log(res);
        swal({
          title: "OTP Verified Successfully...!",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });
        // navigate("/patientquestionnaire");
        navigate("/companydetailsvideo");
      })
      .catch((res) => {
        // // //console.log("err", res.response.data);
        swal({
            title: "OTP Verification Failed...!",
            className: "successAlert",
            buttons: true,
            timer: 3000,
          });
      });
  };

  return (
    <div className="email-verification-flex-div">
      <div>
        {/* <div className="skip-for-now-div" style={{ paddingBottom: "10px" }}>
          <Link to="/patientquestionnaire">
            <h3>
              Skip For Now <EastSharpIcon style={{ marginTop: "4px" }} />
            </h3>
          </Link>
  </div> */}
        <div className="email-verification-width-div">
          <div className="email-verification-main-div-one">
            <h3>Email Verification</h3>
          </div>
          <div className="email-verification-main-div-one">
            <h4>Please Check Your Mailbox for OTP...</h4>
          </div>
          <div className="email-verification-main-div-two">
            <div>
              <p>OTP : </p>
            </div>
            <div>
              <input
                type={"number"}
                className="email-input-field-tag"
                value={otp}
                onChange={(e) => setotp(e.target.value)}
              />
            </div>
          </div>
          <div className="email-verification-main-div-two">
          <button
              className="login-page-login-btn"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "60px",
              }}
              onClick={handleSubmitOTP}
            >
              Resend OTP
            </button>
            <button
              className="login-page-login-btn"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "60px",
              }}
              onClick={SubmitOtp}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
