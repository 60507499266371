import React from "react";
import "./ProductDetails.css";


const ProductDetails = () => {
  return (
    <div className="prod-details-flex-div">
      <div className="prod-details-width-div">
        <div className="prod-details-main-div">
            <div><h3>All Product</h3></div>
            <div>Table</div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
