import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  QuizzListReducer,
  QuizzPostReducer,
} from "./Redux/Reducer/Task-reducer";

const reducer = combineReducers({
  QuizzListReducer,
  QuizzPostReducer,
});

// get userData from localStorage
// const userDataFromStorage = localStorage.getItem("userData")
//   ? JSON.parse(localStorage.getItem("userData"))
//   : null;

// initialState
// const initialState = {
//   authReducer: { userData: userDataFromStorage },
// };
// middleware used thunk
const middleware = [thunk];

// store variable initialized
const store = createStore(
  reducer,
//   initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
