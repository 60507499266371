import axios from "axios";
import React, { useEffect, useMemo, useRef, useCallback } from "react";
import { useState } from "react";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-material.css"; // Optional theme CSS
import Loader from "../Loader/Loader";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const DrugDrugInteractionList = () => {

    const Username = localStorage.getItem("Username")


  const headerHeight = 50;
  const gridOptions = {
    onColumnResized: (params) => {
      // //console.log(params);
    },
  };
  function sizeToFit(skipHeader) {
    const allColumnIds = [];
    gridOptions?.columnApi?.getColumns()?.forEach((column) => {
      allColumnIds?.push(column?.getId());
    });

    gridOptions?.columnApi?.autoSizeColumns(allColumnIds, skipHeader);
  }
  const gridRef = useRef();
  const [columnDefs, setColumnDefs] = useState([
    {
      field: "first_name",
      filter: true,
      headerName: "First Name",
      width: 330,
      headerClass: "header-in-aggrement-table",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: {
        // fontWeight: "bold",
        lineHeight: "2",
      },
    },
    {
      field: "last_name",
      filter: true,
      headerName: "Last Name",
      width: 330,
      headerClass: "header-in-aggrement-table",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: {
        // fontWeight: "bold",
        lineHeight: "2",
      },
    },
    {
      field: "drug_interaction_count",
      filter: true,
      headerName: "Total Drug Interaction ",
      width: 200,
      headerClass: "header-in-aggrement-table",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: {
        // fontWeight: "bold",
        lineHeight: "2",
      },
    },
    {
      field: "drug_interaction_match_list",
      filter: true,
      headerName: "Drug Interaction",
      width: 200,
      headerClass: "header-in-aggrement-table",
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: {
        // fontWeight: "bold",
        lineHeight: "2",
      },
    },
   
  ]);
  const defaultColDef = useMemo(() => ({
    sortable: true,
    filter: "agTextColumnFilter",
    resizable: true,
    flex: 1,
    minWidth: 180,
  }));
  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(
      document.getElementById("filter-text-box").value
    );
  }, []);
  const [tableData, setTableData] = useState(null);
  
  const isdietitian = localStorage.getItem("is_dietitian");
  const is_trainer = localStorage.getItem("is_trainer");
  const is_doctor = localStorage.getItem("is_doctor");
  useEffect(() => {
    const headers = {
      Authorization: `Token ${localStorage.getItem("SavedToken")}`,
    };
    fetch(

      isdietitian == "true"
          ? `https://djangoobesitychat.azurewebsites.net/dietitian/patient/${Username}/`
          : is_trainer == "true"
          ? `https://djangoobesitychat.azurewebsites.net/trainer/patient/${Username}/`
          : is_doctor == "true"
          ?
          `https://djangoobesitychat.azurewebsites.net/patientresponse/${Username}/`
          : "",
      { headers: headers }


        //  `https://djangoobesitychat.azurewebsites.net/patientresponse/${Username}/`
    )
      .then((data) => data.json())
      .then((data) => setTableData(data["total_drug_interaction_list"]));
  }, []);

  const navigate = useNavigate();

  const NavigatetoPersonalPatientFile = (params) => {
    localStorage.setItem("patientUserName", params.data.patient_name);
    localStorage.setItem("PatientID", params.data.patient_id);

         navigate(
          isdietitian == "true" ?
          "/dietitiansidebar/personalPatientView"
          : is_trainer == "true"
          ?"/trainersidebar/personalPatientView"
          : is_doctor == "true"
          ?"/dashboard/personalPatientView"
          : ""

         )

  };

  return (
    <div className="pre-scr-pti-on-view-for-pat-ient-doctor-cli-nic-flex-div">
      <div className="pre-scr-pti-on-view-for-pat-ient-doctor-cli-nic-width-div">
        <div
          className="appointment-list-sub-div-one"
          style={{ marginBottom: "20px" }}
        >
          <h3>Drug Interaction Patients</h3>
        </div>
        
        <div
          className="ag-theme-material"
          style={{
            width: "100%",
            height: "90vh",
            boxShadow: "rgb(0 0 0 / 35%) 0px 5px 15px",
            marginTop: "20px",
          }}
        >
          {!tableData ? (
            <Loader />
          ) : (
            <AgGridReact
              ref={gridRef} // Ref for accessing Grid's API
              rowData={tableData} // Row Data for Rows
              columnDefs={columnDefs} // Column Defs for Columns
              defaultColDef={defaultColDef} // Default Column Properties
              animateRows={true} // Optional - set to 'true' to have rows animate when sorted
              rowSelection="multiple" // Options - allows click selection of rows
              onCellClicked={NavigatetoPersonalPatientFile}
              headerHeight={headerHeight}
              onGridReady={sizeToFit}
            />
          )}
        </div>

        
      </div>
    </div>
  );
};

export default DrugDrugInteractionList;
