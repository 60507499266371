import React from 'react'
import "./SearchLoader.css"
import { Dna } from "react-loader-spinner";

const SearchLoader = () => {
  return (
    <div className="search-loader-main-div">
      <Dna
        visible={true}
        height="100"
        width="100"
        ariaLabel="dna-loading"
        wrapperStyle={{}}
        wrapperClass="dna-wrapper"
      />
    </div>
  )
}

export default SearchLoader