import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ConnectApi from "./ConnectApi"


// MaterialUI
import { Button } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import { Checkbox } from "@mui/material";
import { Link } from "@mui/material";
import  { Typography } from "@mui/material";
import { Container } from "@mui/material";
import { RadioGroup } from "@mui/material";
import { Alert, AlertTitle }  from "@mui/material";
import { styled } from "@mui/system";

// const useStyles = styled((theme) => ({
//   "@global": {
//   paper: {
//     marginTop: theme.spacing(8),
//     display: "flex",
//     flexDirection: "column",
//   },
//   form: {
//     width: "100%", // Fix IE 11 issue.
//     marginTop: theme.spacing(1),
//   },
//   submit: {
//     margin: theme.spacing(3, 0, 2),
//   },
//   correct: {
//     color: "blue",
//   },
// },
// }));

export const RandomQuiz = () => {
  // const classes = useStyles();
  const { topic } = useParams();
  const API_URL = "https://djangoobesitychat.azurewebsites.net/r/Anxiety/";
  const [dataState] = ConnectApi(API_URL);
  const a = dataState.data.flatMap((q) => q.answer);
  const ac = a.length;
  const [answer, setAnswer] = useState({});
  const [answerCheck, setAnswerCheck] = useState();

  useEffect(() => {
    if (Object.keys(answer).length === 0) {
      setAnswer(createInitalAnswers());
    }
  }, [answer]);

  const handleSelection = (e) => {
    setAnswer({ ...answer, [e.target.value]: e.target.checked });
  };

  const createInitalAnswers = () => {
    let z = a.flatMap((obj) => obj.id);
    var object = {};
    for (var x = 0; x < ac; x++) {
      object[z[x]] = false;
    }
    return object;
  };

  const checkAnswer = (e) => {
    e.preventDefault();

    let n = a.map((obj) => obj.is_right);
    let y = { ...n };

    function arrayEquals(o, p) {
      return (
        Array.isArray(o) &&
        Array.isArray(p) &&
        o.length === p.length &&
        o.every((val, index) => val === p[index])
      );
    }

    let o = Object.values(y);
    let p = Object.values(answer);
    if (arrayEquals(o, p)) {
        setAnswerCheck(true);
    } else {
        setAnswerCheck(false);
    }
  };

  function refreshPage() {
    window.location.reload(false);
  }

  function Result() {
    if (answerCheck === true) {
      return (
        ""
        // <Alert severity="success">
        //   <AlertTitle>Correct Answer</AlertTitle>
        //   Well done you got it right —{" "}
        //   <Link href="#" variant="body2" onClick={refreshPage}>
        //     {"Next Question"}
        //   </Link>
        // </Alert>
      );
    } else if (answerCheck === false) {
      return (
        <Alert >
        </Alert>
      );
    } else {
      return <React.Fragment></React.Fragment>;
    }
  }

  return (
    <React.Fragment>
      
      <Container component="main" maxWidth="xs">
        <div>
          {dataState.data.map(({ title, answer }, i) => (
            <div key={i}>
              <Typography component="h1" variant="h5">
                {title}
              </Typography>
              {answer.map(({ answer_text, id }) => (
                <RadioGroup>
                  <FormControlLabel
                    control={
                      <input 
                      type="radio"
                        value={id}
                        color="primary"
                        onChange={handleSelection}
                      />
                    }
                    label={answer_text}
                  />
                  </RadioGroup>
              ))}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={checkAnswer}
              >
                Submit Answer
              </Button>
              <Result />
            </div>
          ))}
        </div>
      </Container>
    </React.Fragment>
  );
};

export default RandomQuiz;