import React, {useEffect, useState} from 'react'
import './MailNotification.css'
import './../../containers/patientlist/PatientList.css'
import Apiservice from '../../Services/Apiservice'

const MailNotification = () => {
    const [questionnaireNotification, setquestionnaireNotification] = useState(false);
    const [weightNotification, setweightNotification] = useState(false);
    const [eventNotification, seteventNotification] = useState(false);
    const [questionnaireMail, setquestionnaireMail] = useState(false);
    const [weightMail, setweightMail] = useState(false);
    const [eventMail, seteventMail] = useState(false);
    const [posts, setPosts] = useState([]);
    const [searchTitle, setSearchTitle] = useState("");
    const [opentwo, setOPentwo] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([
        {
            "choice_id": null,
            "questionnaire_notification": false,
            "weight_notification": false,
            "event_notification": false,
            "questionnaire_mail": false,
            "weight_mail": false,
            "event_mail": false,
            "patient_name": ``,
        }
    ]);
    
    const toggletwo = () => {
    setOPentwo(!opentwo);
  };



    const UsernametogetDocs = localStorage.getItem("UsernametogetDocs");

  // SEARCH PATIENT
  useEffect(() => {
    const loadPosts = async () => {
      setLoading(true);
      const response = await Apiservice.get(
        "/all/patient/"
      );
      setPosts(response.data);
      setLoading(false);
    };
    loadPosts();
  }, [UsernametogetDocs]);
  

  useEffect(() => {
    const loadData = async () => {
        setLoading(true);
        const response = await Apiservice.get(
            `/emailchoice/${UsernametogetDocs}/`
        );
        setData(response.data);
        setquestionnaireNotification(response.data[0]?.questionnaire_notification);
        setweightNotification(response.data[0]?.weight_notification);
        seteventNotification(response.data[0]?.event_notification);
        seteventMail(response.data[0]?.event_mail);
        setweightMail(response.data[0]?.weight_mail);
        setquestionnaireMail(response.data[0]?.questionnaire_mail);
    };
    loadData();
    }, [UsernametogetDocs]);

    const handleQuestionnaireCheckboxChange = (checked) => {
        setquestionnaireNotification(checked);
      };
    
    console.log(questionnaireNotification)
    console.log(weightNotification)
    console.log(eventNotification)
    console.log(questionnaireMail)
    console.log(weightMail)
    console.log(eventMail)

    const firstHandleSubmit = async (e) => {
        e.preventDefault();
        const response = await Apiservice.post(
            `/emailchoice/post/`,
            {
                questionnaire_notification: questionnaireNotification,
                weight_notification: weightNotification,
                event_notification: eventNotification,
                questionnaire_mail: questionnaireMail,
                weight_mail: weightMail,
                event_mail: eventMail,
                patient_name: UsernametogetDocs,
            }
        );
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await Apiservice.put(
            `/emailchoice/update/${UsernametogetDocs}/`,
            {
                questionnaire_notification: questionnaireNotification,
                weight_notification: weightNotification,
                event_notification: eventNotification,
                questionnaire_mail: questionnaireMail,
                weight_mail: weightMail,
                event_mail: eventMail,
                patient_name: UsernametogetDocs,
            }
        );
    };

    
  return (
    <div>
          <div
            style={{
              display: "flex",
              gap: "10px",
              width: "100%",
              alignItems: "center",
              paddingTop: "25px",
              paddingLeft: "150px",
              paddingRight: "150px",
              justifyContent: "center",
            }}
          >
            <div
              className="patient-details-in-appointment-sub-div-two-part-one"
              style={{ width: "20%" }}
            >
              <h4>Search Patient :</h4>
            </div>
            <div
              className="InputContainer"
            >
              <input
                type="text"
                name='text'
                id='input'
                placeholder="Search Patient..."
                className="input"
                onClick={toggletwo}
                onChange={(e) => setSearchTitle(e.target.value)}
              />
              
              
            </div>
            {opentwo && (
                <div style={{ marginTop: '85px', marginLeft: '240px'}} className="list-of-all-user" onClick={toggletwo}>
                  {loading ? (
                    <div style={{ display: "none" }}></div>
                  ) : (
                    posts
                      .filter((value) => {
                        if (searchTitle === "") {
                          // return value;
                        } else if (
                          value.firstName &&
                          value.username
                            ?.toLowerCase()
                            .includes(searchTitle?.toLowerCase())
                        ) {
                          return value;
                        }
                      })
                      .map((item) => (
                        <div className="list-of-user-main-flex-div" key={item.id}>
                          <div className="list-of-user-main-div">
                            <p
                              onClick={() =>
                                localStorage.setItem(
                                  "UserIdtoDetDetails",
                                  item.id
                                ) ||
                                localStorage.setItem(
                                  "UsernametogetDocs",
                                  item.username
                                )
                              }
                            >
                              {item.username}
                            </p>
                          </div>
                        </div>
                      ))
                  )}
                </div>
              )}
          </div>
          {data.length === 0 ? (
            <div className="no-data-found">
                <h4 style={{marginLeft:'700px', marginTop:'20px'}}>{UsernametogetDocs}</h4>
            <table style={{marginLeft: '509px', width:'35%', padding: '20px', gap:'4px', border: "2px"}}>
            <thead>
              <tr>
                <th>Setting</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
            
              <tr>
                <td style={{ padding: '10px 0' }} >Questionnaire Notification</td>
                <div className="toggle-border">
                <td  >
                <label className='checkbox-container' for="one">
                    <input id='one' type="checkbox" checked={questionnaireNotification} onChange= {(e) => setquestionnaireNotification(e.target.checked)} />
                
                    <div class="checkmark"></div>
                </label>
                </td>
                </div>
              </tr>
              <tr>
                <td style={{ padding: '10px 0' }} >Weight Notification</td>
                <div className="toggle-border">
                <td>
                  <label className='checkbox-container' for="two"><input id="two" type="checkbox" checked={weightNotification}  onChange = {(e) => setweightNotification(e.target.checked)} />
                    <div class="checkmark"></div>
                  </label>
                </td>
                </div>
              </tr>
              <tr>
                <td style={{ padding: '10px 0' }} >Event Notification</td>
                <div className="toggle-border">
                <td >
                <label className='checkbox-container' for="three">
                  <input id="three" type="checkbox" checked={eventNotification} onChange = {(e) => seteventNotification(e.target.checked)} />
                  
                  <div class="checkmark"></div>
                </label></td>
                </div>
              </tr>
              <tr>
                <td style={{ padding: '10px 0' }} >Questionnaire Mail</td>
                <div className="toggle-border">
                <td>
                <label className='checkbox-container' for="four">
                  <input id='four' type="checkbox" checked={questionnaireMail} onChange = {(e) => setquestionnaireMail(e.target.checked)} />
                  <div class="checkmark"></div>
                </label></td>
                </div>
              </tr>
              <tr>
                <td style={{ padding: '10px 0' }} >Weight Mail</td>
                <div className="toggle-border">
                <td>
                <label className='checkbox-container' for="five">
                  <input id='five' type="checkbox" checked={weightMail} onChange = {(e) => setweightMail(e.target.checked)} />
                  <div class="checkmark"></div>
                </label></td>
                </div>
              </tr>
              <tr>
                <td style={{ padding: '10px 0' }} >Event Mail</td>
                <div className="toggle-border">
                <td><label className='checkbox-container' for="six"><input id='six' type="checkbox" checked={eventMail} onChange = {(e) => seteventMail(e.target.checked)} />
                <div class="checkmark"></div>
                </label></td>
                </div>
              </tr>
            </tbody>
          </table>
          <button className="save-button" onClick={firstHandleSubmit}>Save</button>
            </div>
            ) : (
          data.map((item, index) => (
        <div key={index}>
          <h4 style={{marginLeft:'700px' , marginTop:'20px'}}>{item.patient_name}</h4>
          <table style={{marginLeft: '509px', width:'35%', padding: '20px', gap:'4px'}}>
            <thead>
              <tr>
                <th>Setting</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ padding: '10px 0' }}>Questionnaire Notification</td>
                <div className="toggle-border">
                <td>
                <label className='checkbox-container' for="one">
                  <input id='one' type="checkbox" checked={questionnaireNotification} onChange = {(e) => setquestionnaireNotification(e.target.checked)} />
                  <div class="checkmark"></div>
                </label></td>
                </div>
              </tr>
              <tr>
                <td style={{ padding: '10px 0' }}>Weight Notification</td>
                <div className="toggle-border">
                
                <td><label className='checkbox-container' for="two"><input id='two' type="checkbox" checked={weightNotification} onChange = {(e) => setweightNotification(e.target.checked)} />
                <div class="checkmark"></div>
                </label></td>
                </div>
              </tr>
              <tr>
                <td style={{ padding: '10px 0' }}>Event Notification</td>
                <div className="toggle-border">
                <td><label className='checkbox-container' for="three"><input id='three' type="checkbox" checked={eventNotification} onChange = {(e) => seteventNotification(e.target.checked)} />
                <div class="checkmark"></div>
                </label></td>
                </div>
              </tr>
              <tr>
                <td style={{ padding: '10px 0' }}>Questionnaire Mail</td>
                <div className="toggle-border">
                <td><label className='checkbox-container' for="four"><input id='four' type="checkbox" checked={questionnaireMail} onChange = {(e) => setquestionnaireMail(e.target.checked)} />
                <div class="checkmark"></div>
                </label></td>
                </div>
              </tr>
              <tr>
                <td style={{ padding: '10px 0' }}>Weight Mail</td>
                <div className="toggle-border">
                <td><label className='checkbox-container' for="five"><input id='five' type="checkbox" checked={weightMail} onChange = {(e) => setweightMail(e.target.checked)} />
                    <div class="checkmark"></div>
                </label></td>
                </div>
              </tr>
              <tr>
                <td style={{ padding: '10px 0' }}>Event Mail</td>
                <div className="toggle-border">
                <td><label className='checkbox-container' for="six"><input id='six' type="checkbox" checked={eventMail} onChange = {(e) => seteventMail(e.target.checked)} />
                <div class="checkmark"></div>
                </label></td>
                </div>
              </tr>
            </tbody>
          </table>
          <button className="save-button" onClick={handleSubmit}>Save</button>
        </div>
            ))
            )}
        </div> 
  )
}

export default MailNotification