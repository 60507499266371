import axios from "axios";
import React, { useEffect, useState } from "react";
import "./EventByClinic.css";
import Add from "@mui/icons-material/Add";
import swal from "sweetalert";
import Loader from "../Loader/Loader";
import Apiservice from "../../Services/Apiservice";


const EventByClinic = () => {
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const [poststwo, setPoststwo] = useState([]);
  const [searchTitletwo, setSearchTitletwo] = useState("");
  const [poststhree, setPoststhree] = useState([]);
  const [searchTitlethree, setSearchTitlethree] = useState("");
  const [patientdetailsinaddpatient, setpatientdetailsinaddpatient] = useState([]);
  const [doctorDetailsinAppointment, setdoctorDetailsinAppointment] = useState([]);
  const [Event_details, setEvent_details] = useState("");
  const [next_steps, setnext_steps] = useState("");
  const [date, setdate] = useState("");
  const [message, setMessage] = useState("");
  const clinicID = localStorage.getItem("ClinicIDForHomepage");

  const DoctorIdToGetDetails = localStorage.getItem("DoctorIdToGetDetails");
  const UserIdtoDetDetails = localStorage.getItem("UserIdtoDetDetails");
  const [response, setresponse] = useState();

  const [opentwo, setOPentwo] = useState(false);
  const toggletwo = () => {
    setOPentwo(!opentwo);
  };
  const [openthree, setOPenthree] = useState(false);
  const togglethree = () => {
    setOPenthree(!openthree);
  };
  const [openfour, setOPenfour] = useState(false);
  const togglefour = () => {
    setOPenfour(!openfour);
  };

  // SEARCH PATIENTS
  useEffect(() => {
    const loadPosts = async () => {
      setLoading(true);
      const response = await Apiservice.get(
        // "https://djangoobesitychat.azurewebsites.net/all/patient/"
        `/user/patient/clinic/${clinicID}/`
        
      );
      setPosts(response.data);
      setLoading(false);
    };
    loadPosts();
  }, []);

  // SEARCH DOCTORS
  useEffect(() => {
    const loadPoststwo = async () => {
      setLoading(true);
      const response = await Apiservice.get(
        // "https://djangoobesitychat.azurewebsites.net/all/doctor/"
        `/user/doctor/clinic/${clinicID}/`
      );
      setPoststwo(response.data);
      setLoading(false);
    };
    loadPoststwo();
  }, []);

  // SEARCH EVENTS
  useEffect(() => {
    const loadPoststhree = async () => {
      setLoading(true);
      const response = await Apiservice.get("/commoneventapi/");
      setPoststhree(response.data);
      // // //console.log("event", response.data);
      setLoading(false);
    };
    loadPoststhree();
  }, []);

  // GET SEARCHED PATIENT DETAILS
  useEffect(() => {
    if (UserIdtoDetDetails) {
      Apiservice
        .get(
          `/current/user/details/${UserIdtoDetDetails}/`
        )
        .then((response) => {
          setpatientdetailsinaddpatient(response.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [UserIdtoDetDetails]);

  // GET SEARCHED DOCTOR DETAILS
  useEffect(() => {
    if (DoctorIdToGetDetails) {
    Apiservice
      .get(
        `/current/user/details/${DoctorIdToGetDetails}/`
      )

      .then((response) => {
        setdoctorDetailsinAppointment(response.data);
        // // // //console.log(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
    }
}, [DoctorIdToGetDetails]);

  const [submitLoading,setsubmitLoading]=useState(false)


  const CreateEvent = async (e) => {
    e.preventDefault();
    setsubmitLoading(true)

    await Apiservice
      .post(
        `/allevents/`,
        {
          patient_name:patientdetailsinaddpatient.username,
          doctor_id:doctorDetailsinAppointment.id,
          events:localStorage.getItem("EventName"),
          Event_details:Event_details,
          next_steps:next_steps,
          date:date
        },
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("SavedToken")}`,
        }
        }
        )
        .then((res) => {
          // // // //console.log("Response",res);
    setsubmitLoading(false)

          swal({
            title: "Event Created Successfully...",
            className: "successAlert",
            buttons: true,
            timer: 3000,
          });
        })
        .catch((res) => {
          // // //console.log("err", res.response.data);
          setresponse(res.response.data);
    setsubmitLoading(false)

        });
    };

  return (
    <div className="events-by-clinic-flex-div">

    {submitLoading &&
      <div className="purchase-order-details-pop-up-main-div">
            <div className="purchase-order-details-pop-up-sub-div">
              <div className="purchase-order-details-pop-up-sub-div-container" style={{background:"none"}}>
      <Loader/>
      </div>
      </div>
      </div>
    }

      <div className="events-by-clinic-width-div">
        <div className="events-by-clinic-main-flex-div-one">
          <div className="appointment-by-clini-sub-div-one" >
            <h4>Create Event</h4>
          </div>
          <div className="events-search-doctor-div">
            <div
              style={{
                display: "flex",
                gap: "10px",
                width: "100%",
                alignItems: "center",
              }}
            >
              <div className="patient-details-in-appointment-sub-div-two-part-one">
                <h4>Search Patient :</h4>
              </div>
              <div
                className="patient-details-in-appointment-sub-div-two-part-one-two"
                style={{ width: "100%" }}
              >
                <input
                  type="text"
                  placeholder="Search Patient..."
                  className="search-patient-in-appt-inp-tag"
                  onClick={toggletwo}
                  onChange={(e) => setSearchTitle(e.target.value)}
                />
                {opentwo && (
                  <div className="list-of-all-user" onClick={toggletwo}>
                    {loading ? (
                      <div style={{ display: "none" }}></div>
                    ) : (
                      posts
                        .filter((value) => {
                          if (searchTitle === "") {
                            return posts;
                          } else if (
                            value.patient_firstName
                              ?.toLowerCase()
                              .includes(searchTitle?.toLowerCase())
                          ) {
                            return value;
                          }
                        })
                        .map((item) => (
                          <div className="list-of-user-main-flex-div">
                            <div className="list-of-user-main-div">
                              <p
                                onClick={() =>
                                  localStorage.setItem(
                                    "UserIdtoDetDetails",
                                    item.patient_name
                                  )
                                }
                              >
                                {item.patient_firstName} {item.patient_lastName}
                              </p>
                            </div>
                          </div>
                        ))
                    )}
                  </div>
                )}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                gap: "10px",
                width: "100%",
                alignItems: "center",
              }}
            >
              <div className="patient-details-in-appointment-sub-div-two-part-one">
                <h4>Search Doctor :</h4>
              </div>
              <div
                className="patient-details-in-appointment-sub-div-two-part-one-two"
                style={{ width: "100%" }}
              >
                <input
                  type="text"
                  placeholder="Search Doctor..."
                  className="search-patient-in-appt-inp-tag"
                  onClick={togglethree}
                  onChange={(e) => setSearchTitletwo(e.target.value)}
                />
                {openthree && (
                  <div className="list-of-all-user" onClick={togglethree}>
                    {loading ? (
                      <div style={{ display: "none" }}></div>
                    ) : (
                      poststwo
                        .filter((value) => {
                          if (searchTitletwo === "") {
                            return poststwo;
                          } else if (
                            value.doctor_firstName,
                            value.username
                              ?.toLowerCase()
                              .includes(searchTitletwo?.toLowerCase())
                          ) {
                            return value;
                          }
                        })
                        .map((item) => (
                          <div className="list-of-user-main-flex-div">
                            <div className="list-of-user-main-div">
                              <p
                                onClick={() =>
                                  localStorage.setItem(
                                    "DoctorIdToGetDetails",
                                    item.doctor_name
                                  )
                                }
                              >
                                {item.doctor_firstName} {item.doctor_lastName}
                              </p>
                            </div>
                          </div>
                        ))
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="event-by-clinic-sub-div-two">
            <div className="events-patient-details-main-div">
              <div>
                <h3>Patient Name</h3>
              </div>
              <div className="user-details-get-main-div">
                <p>
                  {patientdetailsinaddpatient.firstName}
                  {patientdetailsinaddpatient.lastName}
                </p>
              </div>
            </div>

            <div className="events-patient-details-main-div">
              <div>
                <h3>Doctor Name</h3>
              </div>
              <div className="user-details-get-main-div">
                <p>
                  {doctorDetailsinAppointment.firstName}{" "}
                  {doctorDetailsinAppointment.lastName}
                </p>
              </div>
            </div>
          </div>

          <div className="event-by-clinic-sub-div-two">
            <div className="events-patient-details-main-div">
              <div>
                <h3>Search Event</h3>
              </div>
              <div className="user-details-get-main-div">
                <div
                  className="patient-details-in-appointment-sub-div-two-part-one-two-"
                  style={{ width: "100%" }}
                >
                  <input
                    type="text"
                    placeholder="Search Events..."
                    className="search-patient-in-appt-inp-tag"
                    onClick={togglefour}
                    onChange={(e) => setSearchTitlethree(e.target.value)}
                  />
                  {openfour && (
                    <div className="list-of-all-user" onClick={togglefour}>
                      {loading ? (
                        <div style={{ display: "none" }}></div>
                      ) : (
                        poststhree
                          .filter((value) => {
                            if (searchTitlethree === "") {
                              return poststhree;
                            } else if (
                              value.event
                                ?.toLowerCase()
                                .includes(searchTitlethree?.toLowerCase())
                            ) {
                              return value;
                            }
                          })
                          .map((item) => (
                            <div className="list-of-user-main-flex-div">
                              <div className="list-of-user-main-div">
                                <p
                                  onClick={() =>
                                    localStorage.setItem(
                                      "EventName",
                                      item.event
                                    )
                                  }
                                >
                                  {item.event}
                                </p>
                              </div>
                            </div>
                          ))
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="events-patient-details-main-div">
              <div>
                <h3>Event</h3>
              </div>
              <div className="user-details-get-main-div">
                <p>{localStorage.getItem("EventName")}</p>
              </div>
            </div>
          </div>

          <div className="event-by-clinic-sub-div-three">
            <div style={{ width: "17%" }}>
              <h3>Event Details</h3>
            </div>
            <div className="event-details-text-area-main-div">
              <textarea placeholder="Event Details..." 
              value={Event_details}
              onChange={(e) => setEvent_details(e.target.value)}
              />
              <div className="error-message-preview">
              <p>{response && response.Event_details}</p>
            </div>
            </div>
          </div>


          <div className="event-by-clinic-sub-div-three">
            <div style={{ width: "17%" }}>
              <h3>Event Date</h3>
            </div>
            <div className="event-details-text-area-main-div">
              <input type={"date"}  
              value={date}
              onChange={(e) => setdate(e.target.value)}
              />
              <div className="error-message-preview">
              <p>{response && response.date}</p>
            </div>
            </div>
          </div>



          <div className="event-by-clinic-sub-div-three">
            <div style={{ width: "17%" }}>
              <h3>Next Steps</h3>
            </div>
            <div className="event-details-text-area-main-div">
              <textarea placeholder="Next Steps..." 
              value={next_steps}
              onChange={(e) => setnext_steps(e.target.value)}
              />
              <div className="error-message-preview">
              <p>{response && response.next_steps}</p>
            </div>
            </div>
          </div>
        </div>

        <div style={{display:"flex", justifyContent:"center",paddingTop:"10px",paddingBottom:"5px",color:"rgb(49, 163, 214)"}}>
        {message ? (
          <p>{message}</p>
        ) : (
          <p style={{ fontSize: "10px" }}></p>
        )}
        </div>

        <div className="add-pat-btn-div">
        <button className="add-btn-btn-tag" onClick={CreateEvent}>
          <Add style={{ height: "20px", width: "20px" }} />
          CREATE EVENT
        </button>
      </div>
      </div>
    </div>
  );
};

export default EventByClinic;
