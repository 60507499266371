import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  PieChart,
  Pie,
  Legend,
  Label,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import Apiservice from "../../Services/Apiservice";

const renderColorfulLegendText = (value) => {
  return (
    <span style={{ color: "#596579", fontWeight: 500, padding: "10px" }}>
      {value}
    </span>
  );
};

const HungryBrainDoughnut = () => {
  const [drilldown, setdrilldown] = useState();
  const Doctor = localStorage.getItem("is_doctor");
  const Patient = localStorage.getItem("is_patient");
  const Username = [];
  if (Doctor == "true") {
    Username.push(localStorage.getItem("patientUserName"));
  }
  if (Patient == "true") {
    Username.push(localStorage.getItem("Username"));
  }
  // // // //console.log("Username",Username)

  useEffect(() => {
    Apiservice
      .get(`/phenotypedrilldown/${Username}/`)
      .then((response) => {
        setdrilldown(response.data["categorized"]);
        // // // //console.log(response.data)
      })
      .catch((error) => {
        // // //console.log(error)
      });
  }, []);
  const data1 = drilldown || { drilldown };
  // //console.log("data1",data1)
  // const sorted = Object.entries(data1)
  //   .sort((a, b) => b[1] - a[1])
  //   .map((entry) => entry[0])
  //   .slice(0, 2);

    const sorted = Object.entries(data1)
    .sort((a, b) => parseFloat(b[1]) - parseFloat(a[1]))
    .map((entry) => entry[0])
    .slice(0, 2)


  // //console.log("sorted",sorted)
  //console.log("drilldown && drilldown[sorted[0]]  ::",parseFloat(drilldown && drilldown[sorted[0]]))
  //console.log("drilldown && drilldown[sorted[1]]  :: ",parseFloat(drilldown && drilldown[sorted[1]]))


  const data = [
    {
      name: sorted[0],
      value: parseFloat(drilldown && drilldown[sorted[0]]),
      fill: "rgb(238,175,0)",
    },
    { name: sorted[1], value: parseFloat(drilldown && drilldown[sorted[1]]), fill: "red" },
  ];

  console.log("data",data)
  return isNaN(data[0].value) ? (
      <div className="fem-dough-resp-container-main">
        <p style={{fontFamily:"sans-serif", fontSize: "10px" }}> Please fill in weight and food consumed. If filled, contact your clinic or admin for further steps</p>
      </div>
    ) : (
    <ResponsiveContainer
      width={303}
      height={300}
      // width="100%" height="100%"
      className="fem-dough-resp-container-main"
    >
      <PieChart >
        <Tooltip 
        formatter={(value, name) => [`${value}%`, name]}
        />
        <Pie
          data={data}
          cx={100}
          cy={130}
          innerRadius={70}
          outerRadius={90}
          fill="#8884d8"
          paddingAngle={0}
          dataKey="value"
          align="center"
          stroke="none"
          cornerRadius={40}
        >
          ,
          <Label
            value={sorted[0]}
            // value={`${sorted[0]}%`}
            position="center"
            className="doug-inside"
            style={{ fontSize: "13.5px" }}
          />
        </Pie>
      </PieChart>
    </ResponsiveContainer> 
  );
};

export default HungryBrainDoughnut;
