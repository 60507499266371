import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { GoAlert } from "react-icons/go";
import { AiFillExclamationCircle } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import Calender from "../Calender/Calender";
import AvgPatientGraph from "../AvgPatientGraph/AvgPatientGraph";
import TreatmentSuccessRateDoughnut from "../TreatmentSuccessRateDoughnut/TreatmentSuccessRateDoughnut";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import Loader from "../Loader/Loader";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Apiservice from "../../Services/Apiservice";

const SubClinicDashboard = () => {
  // const DoctorName = localStorage.getItem('Username')
  const UserInfo = localStorage.getItem("Username");

  const gridRef = useRef();
  const [columnDefs, setColumnDefs] = useState([
    {
      field: "doctor_firstName",
      filter: true,
      headerName: "First Name",
      width: "130",
      headerClass: "header-in-aggrement-table"
    },
    { field: "doctor_lastName", headerName: "Last Name", width: "130",headerClass: "header-in-aggrement-table" },
    { field: "doctor_gender", headerName: "Gender", width: "130" ,headerClass: "header-in-aggrement-table"},
    { field: "doctor_mobile", headerName: "Contact", width: "130",headerClass: "header-in-aggrement-table" },
  ]);
  const defaultColDef = useMemo(() => ({
    // flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    // resizable: true,
    suppressToolPanel: true,
  }));
  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(
      document.getElementById("filter-text-box").value
    );
  }, []);
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    fetch(
      `https://djangoobesitychat.azurewebsites.net/user/doctor/subclinic/${SubClinicIDForHomepage}/`
    )
      .then((data) => data.json())
      .then((data) => setTableData(data));
    // setLoading(false);
  }, []);
  const clinic = localStorage.getItem("is_clinic");
  const ClinicIDForHomepage = localStorage.getItem("ClinicIDForHomepage");
  const SubClinicIDForHomepage = localStorage.getItem("SubClinicIDForHomepage");

  const [clinicdashboarddetails, setclinicdashboarddetails] = useState();
  const Username = localStorage.getItem("Username");
  useEffect(() => {
    Apiservice
      .get(`/subclinicpatients/${SubClinicIDForHomepage}/`)
      .then((response) => {
        setclinicdashboarddetails(response.data);
        // // //console.log("setclinicdashboarddetails",response.data)
      })
      .catch((error) => {
        // // //console.log(error);
      });
  }, [ClinicIDForHomepage]);

  const [subcliniccarddetails, setsubcliniccarddetails] = useState();
  useEffect(() => {
    Apiservice
      .get(
        `/patient/drugcount/subclinic/${SubClinicIDForHomepage}/`
      )
      .then((response) => {
        setsubcliniccarddetails(response.data);
        // //console.log("subcliniccarddetails", response.data);
      })
      .catch((error) => {
        // // //console.log(error);
      });
  }, [SubClinicIDForHomepage]);

  // SUB Clinic Appointment List

  const [appointmentlist, setappointmentlist] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    Apiservice
      .get(
        `/appointmentapi/subclinic/${SubClinicIDForHomepage}/`,
        {}
      )

      .then((response) => {
        setappointmentlist(response.data);
        // // // //console.log("ASD", response.data);
        setLoading(false);
      })
      .catch((err) => {});
  }, []);
  if (!appointmentlist) return null;
  const d = new Date();
  const text = d.toLocaleDateString("fr-CA");
  // // // //console.log("date", text);

  return (
    <div className="dahs-board-flex-div">
      <div className="dahs-board-width-div">
        <div className="dash-board-main-div-for-flex">
          <div className="dahs-board-main-div">
            <div className="dahs-board-sub-div-one">
              {clinic == "true" ? (
                <h4>Welcome back, Clinic. {UserInfo} </h4>
              ) : (
                <h4>Welcome back, Sub Clinic. {UserInfo} </h4>
              )}
              <p>
                Thank you for using Obesity Tool,the digital workplace. It's a
                pleasure to have you here!!
              </p>
              <p>
                This is the Obesity Tool dashboard. Here you can fin data and
                summarise about your Patient and treatment activity.{" "}
              </p>
            </div>
            <div className="dahs-board-sub-div-two">
              <div className="dahs-board-sub-div-two-width">
                <div className="dahs-board-sub-div-two-part-one">
                  <div className="sub-two-card-one">
                    <p className="card-para-tag">Active Patient</p>
                    <h4>
                      {clinicdashboarddetails &&
                        clinicdashboarddetails.activePatient}
                    </h4>
                  </div>
                  <div className="sub-two-card-two-alerts">
                    <p className="card-para-tag">Critical Alerts</p>
                    <div className="critical-alerts-div">
                      <GoAlert style={{ color: "red" }} />
                      <h4>
                        {" "}
                        {subcliniccarddetails &&
                          subcliniccarddetails.critical_alert_count}
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="dahs-board-sub-div-two-part-one">
                  <div className="sub-two-card-one">
                    <p className="card-para-tag">
                      Patient Treated in the next 48 hours
                    </p>
                    <h4 style={{ marginTop: "-2vh" }}>
                      {" "}
                      {subcliniccarddetails &&
                        subcliniccarddetails.Next_48hr_appointment_count_by_subclinic}
                    </h4>
                  </div>
                  <div className="sub-two-card-two-warning">
                    <p className="card-para-tag">Warning Alerts</p>
                    <div className="critical-alerts-div">
                      <AiFillExclamationCircle
                        style={{
                          color: "rgb(255,192,72)",
                          height: "4vh",
                          width: "4vw",
                          // marginTop: "1.5vw",
                        }}
                      />
                      <h4>
                        {subcliniccarddetails &&
                          subcliniccarddetails.warnings_and_precautions_count_by_subclinic_id}
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="dahs-board-sub-div-two-part-one">
                  <div className="sub-two-card-one">
                    <p className="card-para-tag">Drugs drugs interaction</p>
                    <h4>
                      {subcliniccarddetails &&
                        subcliniccarddetails.drug_interaction_count}
                    </h4>
                  </div>
                  <div className="sub-two-card-two">
                    <p className="card-para-tag">
                      Today's Total Number of Appointment
                    </p>
                    <h4>  {subcliniccarddetails &&
                      subcliniccarddetails.Today_total_number_of_appointments_count}</h4>
                  </div>
                </div>
                <div className="dahs-board-sub-div-two-part-one">
                  <div className="sub-two-card-one-full">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "20px",
                        alignItems: "center",
                      }}
                    >
                      <FaUserAlt style={{ color: "rgb(45,164,215)" }} />
                      <p className="card-para-tag">New Patient</p>
                    </div>
                    <h4 style={{ paddingTop: "3vh", marginLeft: "2vw" }}>
                      {clinicdashboarddetails &&
                        clinicdashboarddetails.new_count}
                    </h4>
                    <hr style={{ marginTop: "5vh" }} />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "20px",
                        alignItems: "center",
                        paddingTop: "30px",
                      }}
                    >
                      <FaUserAlt style={{ color: "rgb(45,164,215)" }} />
                      <p className="card-para-tag">Existing Patient</p>
                    </div>
                    <h4 style={{ paddingTop: "3vh", marginLeft: "2vw" }}>
                      {clinicdashboarddetails &&
                        clinicdashboarddetails.revisit_count}
                    </h4>
                  </div>
                </div>
                <div className="dahs-board-sub-div-two-part-two">
                  <Calender />
                </div>
              </div>
            </div>
          </div>

          <div className="dahs-board-main-div-two" style={{ width: "100%" }}>
            <div
              className="dahs-board-main-div-two-sub-one"
              style={{ width: "30%" }}
            >
              <h4 style={{ textAlign: "center", paddingBottom: "40px" }}>
                Average Patient Visits
              </h4>
              <AvgPatientGraph />
            </div>
            <div className="dahs-board-main-div-two-sub-one" style={{ width: "35%" }}>
              <h4 style={{ textAlign: "center" }}>Today's Appointments</h4>
              {loading ? (
                <Loader />
              ) : (
                <TableContainer component={Paper} style={{ height: "70vh" }}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Patient First Name</TableCell>
                        <TableCell align="left">Patient Last Name</TableCell>
                        <TableCell align="left">Doctor First Name</TableCell>
                        <TableCell align="left">Doctor Last Name</TableCell>
                        <TableCell align="left">Date and Time</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {appointmentlist &&
                        appointmentlist.map((row) => (
                          <TableRow
                            key={row && row.clinic_id}
                            id={row && row.appoinment_id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            {row && row.starttime.slice(0,10) == text ? (
                              <>
                                <TableCell align="left" width={"90px"}>
                                  <p>{row && row.patient_firstName}</p>
                                </TableCell>
                                <TableCell align="left" width={"90px"}>
                                  <p>{row && row.patient_lastName}</p>
                                </TableCell>
                                <TableCell align="left" width={"90px"}>
                                  <p>{row && row.doctor_firstName}</p>
                                </TableCell>
                                <TableCell align="left" width={"90px"}>
                                  <p> {row && row.doctor_lastName}</p>
                                </TableCell>
                                <TableCell align="left" width={"90px"}>
                                  <p>{row && row.starttime}</p>
                                </TableCell>
                              </>
                            ) : null}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </div>
            <div
              className="dahs-board-main-div-two-sub-one"
              style={{ width: "35%" }}
            >
              <h4 style={{ textAlign: "left" }}>Doctors</h4>

              <div
                className="ag-theme-material"
                style={{
                  width: "100%",
                  height: "65vh",
                  boxShadow: "rgb(0 0 0 / 35%) 0px 5px 15px",
                  marginTop: "20px",
                }}
              >
                <AgGridReact
                  ref={gridRef}
                  rowData={tableData}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  animateRows={true}
                  rowSelection="multiple"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubClinicDashboard;
