import axios from "axios";
import React, { useEffect, useState } from "react";
import Loader from "../Loader/Loader";
import "./ClinicDocuments.css";
import swal from "sweetalert";
import Apiservice from "../../Services/Apiservice";

const ClinicDocuments = () => {
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [patientuserdetails, setpatientuserdetails] = useState([]);
  const [testname, settestname] = React.useState("");

  const userID = localStorage.getItem("id");

  const [patientdetailsinaddpatient, setpatientdetailsinaddpatient] = useState(
    []
  );
  const UserIdtoDetDetails = localStorage.getItem("UserIdtoDetDetails");
  const UsernametogetDocs = localStorage.getItem("UsernametogetDocs");

  const [opentwo, setOPentwo] = useState(false);
  const toggletwo = () => {
    setOPentwo(!opentwo);
  };
  // SEARCH PATIENT
  useEffect(() => {
    const loadPosts = async () => {
      setLoading(true);
      const response = await Apiservice.get(
        "/all/patient/"
      );
      setPosts(response.data);
      setLoading(false);
    };
    loadPosts();
  }, []);

  // GET SEARCHED PATIENT DETAILS
  useEffect(() => {
    if (UserIdtoDetDetails) {
    Apiservice
      .get(
        `/current/user/details/${UserIdtoDetDetails}/`
      )
      .then((response) => {
        setpatientdetailsinaddpatient(response.data);
        Apiservice
          .get(`/medicaltestapi/${UsernametogetDocs}/`)
          .then((response) => {
            setpatientuserdetails(response.data);
          });
      })
      .catch((err) => {});
  }
},
   
  [UserIdtoDetDetails]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("med_docs", selectedFile && selectedFile);
    formData.append("customeuser_username", UsernametogetDocs);
    formData.append("test_name", testname);
    const medDocsField = formData.get("med_docs");
    // // //console.log(medDocsField);
    try {
      const response = await axios({
        method: "post",
        // url: `https://djangoobesitychat.azurewebsites.net/medicaltestapi/${UserIdtoDetDetails}/`,
        url: `https://djangoobesitychat.azurewebsites.net/medicaltestapi/${UsernametogetDocs}/`,
        data: formData,
      });
      swal({
        title: "Medical Test Documents Of the Patient Submitted Successfully",
        className: "successAlert",
        buttons: true,
        timer: 3000,
      });
      // // // //console.log(formData);
      Apiservice
        .get(`/medicaltestapi/${UsernametogetDocs}/`)
        .then((response) => {
          setpatientuserdetails(response.data);
        });
    } catch (error) {
      // // //console.log(error);
    }
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleinputSelect = (event) => {
    settestname(event.target.value);
  };

  useEffect(() => {
    Apiservice
      .get(`/medicaltestapi/${UsernametogetDocs}/`)
      .then((response) => {
        setpatientuserdetails(response.data);
        setLoading(false);
      })
      .catch((err) => {
        // // // //console.log(err);
      });
  }, []);
  const [popup, setPop] = useState(false);
  const handleClickOpen = () => {
    setPop(!popup);
  };
  const [MedDocID, setMedDocID] = useState();

  const handleClick = (id) => {
    // // // //console.log(key);
    // localStorage.setItem("MedDocID", key);
    setMedDocID(id);

    handleClickOpen();

  };
  // const MedDocID = localStorage.getItem("MedDocID");
  const DownloadURL = `https://djangoobesitychat.azurewebsites.net/filedownload/${MedDocID}/`;

  return (
    <div className="clinic-docs-flex-div">
      <div className="clinic-docs-width-div">
        <div className="clinic-docs-main-div">
          <div className="appointment-by-clini-sub-div-one">
            <h4>Add Patient Documents</h4>
          </div>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              gap: "10px",
              width: "100%",
              alignItems: "center",
              paddingTop: "25px",
              paddingLeft: "150px",
              paddingRight: "150px",
              justifyContent: "center",
            }}
          >
            <div
              className="patient-details-in-appointment-sub-div-two-part-one"
              style={{ width: "20%" }}
            >
              <h4>Search Patient :</h4>
            </div>
            <div
              className="patient-details-in-appointment-sub-div-two-part-one-two"
              style={{ width: "100%" }}
            >
              <input
                type="text"
                placeholder="Search Patient..."
                className="search-patient-in-appt-inp-tag"
                onClick={toggletwo}
                onChange={(e) => setSearchTitle(e.target.value)}
              />
              {opentwo && (
                <div className="list-of-all-user" onClick={toggletwo}>
                  {loading ? (
                    <div style={{ display: "none" }}></div>
                  ) : (
                    posts
                      .filter((value) => {
                        if (searchTitle === "") {
                          // return value;
                        } else if (
                          value.firstName &&
                          value.username
                            ?.toLowerCase()
                            .includes(searchTitle?.toLowerCase())
                        ) {
                          return value;
                        }
                      })
                      .map((item) => (
                        <div className="list-of-user-main-flex-div">
                          <div className="list-of-user-main-div">
                            <p
                              onClick={() =>
                                localStorage.setItem(
                                  "UserIdtoDetDetails",
                                  item.id
                                ) ||
                                localStorage.setItem(
                                  "UsernametogetDocs",
                                  item.username
                                )
                              }
                            >
                              {item.username}
                            </p>
                          </div>
                        </div>
                      ))
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            width: "100%",
            alignItems: "center",
            paddingTop: "25px",
            paddingLeft: "150px",
            paddingRight: "150px",
            justifyContent: "center",
          }}
        >
          <div
            className="events-patient-details-main-div"
            style={{ width: "100%" }}
          >
            <div style={{ width: "22%" }}>
              <h4>Patient Name :</h4>
            </div>
            <div
              className="user-details-get-main-div"
              style={{ width: "100%" }}
            >
              <p>
                {patientdetailsinaddpatient.firstName}{" "}
                {patientdetailsinaddpatient.lastName}
              </p>
            </div>
          </div>
        </div>

        <div>
          <div>
            <form onSubmit={handleSubmit}>
              <div
                style={{
                  paddingLeft: "150px",
                  paddingRight: "150px",
                  paddingTop: "25px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <h4 style={{ width: "20%" }}>Test Name</h4>
                  <input
                    placeholder="Test Name..."
                    style={{ width: "90%", minHeight: "40px" }}
                    className="test-name-info-div-one-input"
                    onChange={handleinputSelect}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    //   gap: "50px",
                    alignItems: "center",
                    width: "100%",
                    paddingTop: "15px",
                    paddingBottom: "20px",
                  }}
                >
                  <h4 style={{ width: "22%" }}>Documents</h4>
                  <div style={{ width: "100%", display: "flex" }}>
                    <input type="file" onChange={handleFileSelect} />
                    <input type="submit" value="Upload File" />
                  </div>
                </div>
              </div>
            </form>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div>
          <div className="second-para-div--n-docs">
            <div className="acc-setting-main-div">
              <div className="update-account-title-div">
                <h3>Existing Test Document Details</h3>
              </div>
            </div>
          </div>
        
            <div style={{height:"115vh", overflowY:"scroll", boxShadow:"rgb(0 0 0 / 35%) 0px 5px 15px",padding:"10px",borderRadius:"10px"}}>
              <div className="test-doc-details-table">
                <div className="test-table-doc-sub-div td-header">
                  <h4>Test Name</h4>
                </div>
                <div className="test-table-doc-sub-div td-header">
                  <h4>Documents</h4>
                </div>
              </div>
              {patientuserdetails.map((index) => (
                <div className="test-doc-details-table">
                  <div className="test-table-doc-sub-div">
                    {index.test_name}
                  </div>
                  <div className="test-table-doc-sub-div">
                      <button className="update-btn-btn-tag"
                      // onClick={() => (handleClick(index.id)) }
                  // onClick={handleClick}
                  onClick={() => handleClick(index.id)}


                      >Download</button>
                  </div>
                </div>
              ))}
            </div>
            </div>
          )}
          {popup && (
            <div className="msg-download-popup" style={{marginLeft:"345px"}}>
              <p>Do You Want to Download Selected File ?</p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <a href={DownloadURL} >
                  <button
                    className="download-confirm-btn-tag"
                    style={{ color: "white", backgroundColor: "rgb(49,163,214)" }}  onClick={handleClickOpen}
                  >
                    Yes
                  </button>
                </a>
                <button
                  onClick={handleClick}
                  className="download-confirm-btn-tag"
                  style={{ color: "white", backgroundColor: "rgb(49,163,214)" }}
                >
                  No
                </button>
              </div>
            </div>
          ) }
        </div>
       
      </div>
     
    </div>
  );
};

export default ClinicDocuments;
