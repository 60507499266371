import React from "react";
import "./TermsandConditions.css";

const TermsandConditions = () => {
  return (
    <div className="terms-and-condn-flex-div">
      <div className="terms-and-condn-width-div">
        <div className="terms-and-condn-main-div">
          <div className="terms-and-condn-main-div-one">
            <h3>Terms and Conditions of Use </h3>
          </div>
          <div className="terms-and-condn-main-div-two">
            <p>
              These terms and conditions of use (the <b>"Terms"</b>), together
              with any other guidelines, policies, disclaimers and additional
              terms published from time to time on this electronic platform
              [______] (the <b>"Platform"</b>) owned and operated by us,
              [______] (the
              <b>“Company”</b>), contain the complete and legally binding terms
              and conditions that govern your access and use of the Platform.{" "}
            </p>
            <p>
              You may not change, supplement, or amend these Terms or any other
              guidelines, policies, disclaimers or additional terms published by
              the Company on this Platform (these Terms together with all such
              documents are hereinafter referred to as the <b>"Rules"</b>) in
              any manner. The Company may, in its sole and absolute discretion,
              change, supplement, add or amend any or all of the Rules as they
              relate to your future use of the Platform from time to time, for
              any or no reason, and with or without any prior notice or
              liability to you or any other person. Unless otherwise expressly
              stated in any specific change, it shall enter into force
              immediately upon publishing on the Platform. You should
              periodically visit this page to review the then current Terms and
              other Rules.
            </p>
            <p>
              If there is any discrepancy between these Terms and any
              dispositive norm of the applicable law, these Terms shall prevail.{" "}
            </p>
          </div>
        </div>

        <div className="terms-and-condn-main-div">
          <div className="terms-and-condn-main-div-one">
            <h3>Company's Contact Details </h3>
          </div>
          <div
            className="terms-and-condn-main-div-two"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div>
              <div style={{ display: "flex", width: "500px" }}>
                <div style={{ width: "250px" }}>
                  <h4>Name</h4>
                </div>
                <div
                  style={{ width: "250px", borderBottom: "1px solid #018fa3" }}
                ></div>
              </div>
              <div style={{ display: "flex", width: "500px" }}>
                <div style={{ width: "250px" }}>
                  <h4>Company Number</h4>
                </div>
                <div
                  style={{ width: "250px", borderBottom: "1px solid #018fa3" }}
                ></div>
              </div>
              <div style={{ display: "flex", width: "500px" }}>
                <div style={{ width: "250px" }}>
                  <h4>Address</h4>
                </div>
                <div
                  style={{ width: "250px", borderBottom: "1px solid #018fa3" }}
                ></div>
              </div>
              <div style={{ display: "flex", width: "500px" }}>
                <div style={{ width: "250px" }}>
                  <h4>Phone Number</h4>
                </div>
                <div
                  style={{ width: "250px", borderBottom: "1px solid #018fa3" }}
                ></div>
              </div>
              <div style={{ display: "flex", width: "500px" }}>
                <div style={{ width: "250px" }}>
                  <h4>Email</h4>
                </div>
                <div
                  style={{ width: "250px", borderBottom: "1px solid #018fa3" }}
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div className="terms-and-condn-main-div">
          <div className="terms-and-condn-main-div-one">
            <h3>Designated Functionality </h3>
          </div>
          <div className="terms-and-condn-main-div-two">
            <p>
              This Platform is designed to assist users to create and process
              their personal profiles based on the AI algorithm of the Platform
              with the view to generate personalized reports, feedbacks and
              statistics, which can be taken into consideration by the relevant
              user's doctor, at her/his complete discretion, upon giving the
              medical advice to such user. The designated health problem
              addressed by this Platform is [obesity]. The Company is entitled,
              as its sole discretion, to amend, extend or reduce the designated
              functionality of the Platform, as the Company deems fit. In order
              to make the Platform more useful, versatile and inclusive for
              general use, the Company is also entitled to add or remove
              different auxiliary functionalities and services to or from the
              Platform, including social media, video calls, lifestyle tips,
              customized news and scientific articles and digest section, third
              party links, etc.
            </p>
          </div>
        </div>

        <div className="terms-and-condn-main-div">
          <div className="terms-and-condn-main-div-one">
            <h3> Personal Use </h3>
          </div>
          <div className="terms-and-condn-main-div-two">
            <p>
              The information available on the Platform is for personal use only
              and intended exclusively for information purposes. You may not
              commercialize any information published on or provided by the
              Platform. This is without prejudice to the right of the clinic,
              where you are receiving, or is willing to apply to in order to
              receive, medical treatment of the designated problem, to charge
              you for the services rendered to you
            </p>
          </div>
        </div>

        <div className="terms-and-condn-main-div">
          <div className="terms-and-condn-main-div-one">
            <h3>Privacy Policy </h3>
          </div>
          <div className="terms-and-condn-main-div-two">
            <p>
              By accessing and using the Platform, you give your consent to the
              Company to collect and store all information provided by you to
              the Platform, including your personal information, in the
              Company's database. All collection, processing, use or disclosure
              of the personal information shall be done in full accordance with
              our privacy policy published on this Platform, which constitutes
              an integral part of these Terms.
            </p>
          </div>
        </div>

        <div className="terms-and-condn-main-div">
          <div className="terms-and-condn-main-div-one">
            <h3>Not Medical Advice </h3>
          </div>
          <div className="terms-and-condn-main-div-two">
            <p>
              The services provided by the Platform do not constitute medicine
              practice (עיסוק ברפואה), as defined in the Physicians Ordinance
              [new version], 1976. The personalized reports, feedbacks and
              statistics generated by the Platform do not constitute, and are
              not intended to replace or supplement, medical advice, which can
              only be given by a practicing physician duly authorized in
              accordance with the applicable law. No decisions or actions shall
              be taken by users in reliance upon the personalized reports,
              feedbacks and statistics provided by the Platform or any portion
              thereof. Only your doctor will decide, at her/his absolute
              discretion, if and to what extent such personalized reports,
              feedbacks and statistics can be taken into consideration upon
              giving you the medical advice. In no event shall the Company be
              held liable for any damage caused, either directly or indirectly,
              in connection with the medical advice provided by a person having
              access to the information collected or generated by the Platform,
              regardless or whether or not such information has been taken into
              consideration by such advising person and to what extent. Although
              each user may be provided with limited access to certain portions
              of her/his own customized personalized reports, feedbacks and
              statistics, but the full information will only be accessible to
              the authorized personnel of the clinic, where such user is
              receiving, or is willing to apply to in order to receive, medical
              treatment of the designated problem, including the user's doctor
              practicing in such clinic. The Company is not an agent or partner
              of the clinic where you are receiving, or is willing to apply to
              in order to receive, medical treatment of the designated problem.
              The Company bears no responsibility for any action or omission of
              the clinics and doctors having access to the information collected
              or generated by the Platform. All requests, complaints and claims
              that you may have to or against such persons, you shall address
              directly to them.
            </p>
          </div>
        </div>

        <div className="terms-and-condn-main-div">
          <div className="terms-and-condn-main-div-one">
            <h3>Disclaimer regarding Algorithm and Services </h3>
          </div>
          <div className="terms-and-condn-main-div-two">
            <p>
              The operation and services of this Platform are based on the AI
              algorithm developed by the Company based on the data processed by
              the qualified professionals teamed up and managed by the Company.
              Although we believe in good faith our sources, methods and
              conclusions underlying the algorithm to be correct, accurate,
              competent and matching the best practices, but the interpretation
              given by the algorithm to the processed data has never been
              clinically approved and may be subject to different uncertainties
              and other factors that may cause actual results to differ
              materially from those suggested by the algorithm. We make no
              warranties or representations (whether express or implied by law,
              statute or otherwise) with respect to the Platform, the algorithm
              or any content or results thereof or the services provided by the
              Platform, or that the Platform or the services will be error-free
              or uninterrupted or with respect to the accuracy, quality,
              merchantability, fitness for particular purpose or suitability of
              all or any of the foregoing. Except as expressly stated otherwise
              herein, we hereby exclude all warranties, representations and
              implied terms and conditions to the fullest extent permitted by
              law. We make no representations about the suitability,
              reliability, availability, timeliness and accuracy of the
              information, software, products and services contained on or
              provided by the Platform for any purpose. All information,
              software, products and services are provided "as is" without
              warranty of any kind and the Company hereby disclaims all
              representations and warranties with respect to such information,
              software and services whether expressed or implied. We will
              continue to make our best efforts to improve the algorithm with
              the view to create a better and more reliable product, provided
              however that we undertake no obligation that such efforts would
              actually be sufficient to achieve any particular purpose or
              improvement.{" "}
            </p>
          </div>
        </div>

        <div className="terms-and-condn-main-div">
          <div className="terms-and-condn-main-div-one">
            <h3>Use of Platform and Prohibitions </h3>
          </div>
          <div className="terms-and-condn-main-div-two">
            <p>
              In the access or use of this Platform, you will comply with the
              Rules as published on the Platform at that time and shall use the
              Platform in strict accordance with applicable law. By approving
              these Terms, you confirm and represent that you are at least 18
              years old and that you have the power, right and authority to
              assume the obligations conferred upon you under these Terms, and
              that these Terms bind you in accordance with the terms hereof and
              applicable law. You will be solely responsible for all content
              transmitted, uploaded, posted, e-mailed or otherwise made
              available on the Platform by you. You undertake that all
              information that you provide to us is true, complete and accurate
              in all material respects, and that you are duly authorized to
              provide us such information in accordance with applicable law.
              Considering that the information that you provide to us will be
              made available to your doctor, you expressly acknowledge that any
              inaccurate fact or misrepresentation in your information can
              naturally have misleading effect on the professional opinion of
              your doctor. In no event shall we be responsible for any damage
              caused, either directly or indirectly, as a result of or in
              connection with any falseness, incompletion or inaccuracy of the
              information that you provide to us. You must not misuse this
              Platform. You will not: (a) commit or encourage a criminal offense
              or any illegal activity; (b) transmit or distribute a virus,
              trojan, worm, logic bomb or any other material which is malicious,
              technologically harmful, in breach of confidence or in any way
              offensive or obscene; (c) hack into any aspect of the Platform;
              (d) corrupt data; (e) cause annoyance to other users or otherwise
              use or access the Platform in an abusive, excessive, or
              inappropriate manner as determined in the sole discretion of
              Company; (f) infringe upon the rights of any other person's
              proprietary rights; (g) send any unsolicited advertising or
              promotional material, commonly referred to as "spam"; (h) attempt
              to affect the performance or functionality of any computer
              facilities of or accessed through this Platform; (i) collect or
              harvest any personally identifiable information, including account
              names, from the Platform; (j) impersonate another person or
              otherwise misrepresent your affiliation with a person or entity,
              conducting fraud, or hiding or attempting to hide your identity;
              (k) interfere with the proper working of the Platform; (l) access
              any content on the Platform through any technology or means other
              than those provided or authorized by the Platform; or (m)  bypass
              the measures we may use to prevent or restrict access to the
              Platform, including features that prevent or restrict use or
              copying of any content or enforce limitations on use of the
              Platform or the content therein. If the Platform permits
              interaction with other users, you are solely responsible for such
              interactions.  We reserve the right, but have no obligation, to
              monitor disputes between you and other users.  The Company shall
              have no liability for your interactions with other users, or for
              any user’s action or inaction.{" "}
            </p>
          </div>
        </div>

        <div className="terms-and-condn-main-div">
          <div className="terms-and-condn-main-div-one">
            <h3>Sign-up and user accounts </h3>
          </div>
          <div className="terms-and-condn-main-div-two">
            <p>
              Except the home page of the Platform and other limited materials
              and third-party links available on the home page, the Platform,
              its designated functionality and services are only accessible to
              the registered users who created a user account on the Platform.
              Creation of a user account is subject to unrestricted acceptance
              of these Terms, the Privacy Policy and other Rules. Each user
              account will be connected to the personal email of the relevant
              user. Access to each user account will be secured with a personal
              code chosen by the user at its sole discretion, which shall comply
              with the basic requirements for the code established by the
              Company (number and variety of symbols, and previous use). Please
              always keep your code in secret and do not disclose it to any
              person. If you believe that your user account has been hacked or
              otherwise made accessible to any person, please change the code
              and inform us immediately. You acknowledge and agree that the
              clinic where you are receiving, or is willing to receive, medical
              treatment of the designated problem, will have access to all
              information at your user account. The Company reserves the right
              not to approve creation of any new account, or to delete, amend,
              suspend or terminate, temporarily or permanently, any registered
              user account, at the Company's sole discretion, for any reason or
              without reason, and without any prior notice or liability to the
              applicant or user, as applicable, or any other person. Upon
              registration of a user account, you will be offered an option to
              subscribe to the Company's news and marketing materials by email.
              If you no longer want to receive such news and materials from us
              on a going-forward basis, you may opt out by clicking on the
              "unsubscribe" link contained in all emails with our marketing
              materials. As a part of our security measures, we may need to
              verify your identity before implementing any of your requests sent
              to us in these Terms or the privacy policy.
            </p>
          </div>
        </div>

        <div className="terms-and-condn-main-div">
          <div className="terms-and-condn-main-div-one">
            <h3>Amendment of Platform </h3>
          </div>
          <div className="terms-and-condn-main-div-two">
            <p>
              We may, at any time and for any or no reason and in its sole
              discretion, amend, suspend or terminate, temporarily or
              permanently, the Platform, the algorithm, the services provided by
              the Platform or any part thereof, without any prior notice or
              liability to you or any other person.{" "}
            </p>
          </div>
        </div>

        <div className="terms-and-condn-main-div">
          <div className="terms-and-condn-main-div-one">
            <h3>Intellectual Property </h3>
          </div>
          <div className="terms-and-condn-main-div-two">
            <p>
              All contents of this Platform are protected under the copyright
              laws of the applicable jurisdictions. Subject to the material
              remaining AS-IS (and all notices of intellectual property rights
              remaining AS-IS), the Platform's contents may be viewed, used and
              copied for non-commercial personal purposes only. Any other use of
              the Platform's contents without the Company's express written
              permission is strictly prohibited, and the Company reserves any
              and all rights which are not expressly granted under these Terms.
              Except as otherwise noted, the trademarks and logos displayed on
              this Platform belong to the Company or the relevant third-party
              licensors, as applicable, and may not be used without the
              Company's express written permission.{" "}
            </p>
          </div>
        </div>

        <div className="terms-and-condn-main-div">
          <div className="terms-and-condn-main-div-one">
            <h3>Limited License </h3>
          </div>
          <div className="terms-and-condn-main-div-two">
            <p>
              We grant you a limited, revocable, and non-exclusive license to
              access and make personal use of the Platform in accordance with
              the Rules. This limited license does not include the right to: (a)
              frame or utilize framing techniques to enclose the Platform or any
              portion thereof; (b) republish, redistribute, transmit, sell,
              license, copy or download the Platform or any and/or all of its
              content (except caching or as necessary to view the Platform); (c)
              make any use of the Platform or any and/or all of it content other
              than personal use without prior written consent of the Company;
              (d) modify, reverse engineer or create any derivative works based
              upon either the Platform or any of its content; (e) collect
              account information for the benefit of yourself or another party;
              (f) use any meta tags or any other "hidden text" utilizing any
              and/or all of its content; or (g) use software robots, spiders,
              crawlers, or similar data gathering and extraction tools, or take
              any other action that may impose an unreasonable burden or load on
              our infrastructure. You must retain, without modification, all
              proprietary notices on the Platform or affixed to or contained in
              the Platform.{" "}
            </p>
          </div>
        </div>

        <div className="terms-and-condn-main-div">
          <div className="terms-and-condn-main-div-one">
            <h3>Third Party Materials </h3>
          </div>
          <div className="terms-and-condn-main-div-two">
            <p>
              The Company reserves the rights to publish on the Platform
              advertising materials and links to any third-party materials and
              services, as the Company deems fit at its sole discretion,
              including texts, pictures, social media, video calls, etc.,
              whether accessible on or outside of the Platform. It is hereby
              acknowledged that the Company is not responsible for the content
              of any third-party material, service, linked material or any link
              contained in a linked material, as well as for compliance of such
              content with applicable law or non-infringement of third-parties
              rights. Such materials and links are provided solely as a
              convenience and the inclusion of any link neither implies
              endorsement, investigation or verification by the Company of the
              linked material or information contained therein, nor constitutes
              the Company as a party to or beneficiary of any transaction
              consummated by users in connection with such materials.{" "}
            </p>
          </div>
        </div>

        <div className="terms-and-condn-main-div">
          <div className="terms-and-condn-main-div-one">
            <h3> Forward Looking Information </h3>
          </div>
          <div className="terms-and-condn-main-div-two">
            <p>
              The Platform may contain forward-looking information relating to
              the future performance of the Platform, its designated
              functionality, its improvements, prospects and forecasts, as well
              as any scientific or journalistic articles of medical, technical,
              business or any other nature. Forward looking information is
              subject to a number of known and unknown risks, uncertainties and
              other factors that may cause actual results to differ materially
              from those anticipated in any forward looking materials. The
              forward-looking materials are made based on various assumptions,
              beliefs, estimates and opinions of their authors on the date the
              materials are issued. Should one or more of these risks and
              uncertainties materialize, or should underlying facts or
              assumptions prove incorrect, actual results may vary materially
              from those described in the forward-looking materials contained
              herein. The Company undertakes no obligation to update
              forward-looking materials if these assumptions, beliefs, estimates
              and opinions or other circumstances should change.{" "}
            </p>
          </div>
        </div>

        <div className="terms-and-condn-main-div">
          <div className="terms-and-condn-main-div-one">
            <h3>Limitation of Liability </h3>
          </div>
          <div className="terms-and-condn-main-div-two">
            <p>
              Information and pictures on the Platform are for illustration
              purposes only. You acknowledge and agree that the Company, its
              affiliates or any of their shareholders, directors, officers and
              employees are not and will not be subject to any liability based
              on the information contained on this Platform or the use of such
              information, errors herein or omissions therefrom, whether or not
              any of such persons knew or should have known of any such errors
              or omissions or were responsible for or participated in the
              inclusion or omission of information on this Platform. You
              acknowledge and agree that in no event shall you be entitled to
              rely on the information, conclusions, feedbacks, reports and
              statistics provided by the Platform, whether general or
              personalized. Such information will be provided to your doctor,
              which shall have complete discretion regarding if and to what
              extent it can be taken into consideration upon giving you the
              medical advice. In no event shall the Company, its affiliates or
              any of their shareholders, directors, officers and employees be
              liable to you with regard to any advice given to you by your
              doctor, whether based on the information provided by the Platform
              or not, or any damage caused in connection therewith, or with
              regard to any act or omission of your doctor or the personnel of
              the clinic, where you are receiving, or have earlier received,
              medical treatment of the designated problem. To the fullest extent
              permitted by applicable law, in no event shall we be responsible
              or liable for any (a) access delays or access interruptions to the
              Platform or its availability on compatibility with any certain
              type of hardware; (b) data non-delivery, misdelivery, corruption,
              destruction or other modification; (c) loss or damage of any sort
              incurred as a result of dealings with or the presence of
              off-Platform links on the Platform or third-party services
              provided on the Platform; (d) computer viruses, system failures or
              malfunctions which may occur in connection with your use of the
              Platform (e) any inaccuracies or omissions in content or (f)
              events beyond our reasonable control. In no event shall the
              Company, its affiliates or any of their shareholders, directors,
              officers and employees be liable for any indirect, incidental,
              special or consequential damages or damages for loss of profits,
              revenue, data or use incurred by you, whether in an action for
              contract or tort, arising from the access to, or use of, the
              Platform.{" "}
            </p>
          </div>
        </div>

        <div className="terms-and-condn-main-div">
          <div className="terms-and-condn-main-div-one">
            <h3>
              NOTWITHSTANDING ANYTHING TO THE CONTRARY SET FORTH HEREIN, IN NO
              EVENT SHALL THE AGGREGATE LIABILITY OF THE COMPANY TO YOU EXCEED
              THE LOWER OF (A) TEN THOUSAND SHEKELS (NIS 10,000), OR (B) THE
              AGGREGATE AMOUNT OF THE FEES RECEIVED BY THE COMPANY FROM YOUR
              CLINIC WITHIN SIX (6) MONTHS BEFORE THE LIABILITY EVENT FOR
              PROVISION OF YOUR PERSONAL PROFILE IN ACCORDANCE WITH THE
              DESIGNATED FUNCTIONALITY OF THE PLATFORM.
            </h3>
          </div>
          <div className="terms-and-condn-main-div-two">
            <p></p>
          </div>
        </div>

        <div className="terms-and-condn-main-div">
          <div className="terms-and-condn-main-div-one">
            <h3>Indemnity </h3>
          </div>
          <div className="terms-and-condn-main-div-two">
            <p>
              You hereby undertake to indemnify the Company, its affiliates and
              their shareholders, directors, officers and employees, and to keep
              them harmless, at first request, against any losses, damages,
              costs, liabilities and expenses (including but not limited to
              legal expenses and any amounts paid by any of them to a third
              party in settlement of a claim or dispute) incurred or suffered by
              any of them in connection with your use this Platform or your
              breach of the Rules, violation of any applicable law or
              infringement of any third-party right.{" "}
            </p>
          </div>
        </div>

        <div className="terms-and-condn-main-div">
          <div className="terms-and-condn-main-div-one">
            <h3>Termination </h3>
          </div>
          <div className="terms-and-condn-main-div-two">
            <p>
              These Terms are effective until terminated by the Company. 
              Company may terminate or suspend your use of the Platform at any
              time and without prior notice, for any or no reason, including if
              Company believes that you have violated or acted inconsistently
              with the letter or spirit of the Rules.  Upon any such termination
              or suspension, your right to use the Platform and/or the services
              provided by it will immediately cease, and Company may, without
              liability to you or any third party, immediately deactivate or
              delete your user name, password, and account.  Sections {" "}
              <i>
                Third Party Materials, Limitation of Liability, Indemnity,
                Termination and Law and Jurisdiction
              </i>{" "}
              shall survive any expiration or termination of this Agreement.{" "}
            </p>
          </div>
        </div>

        <div className="terms-and-condn-main-div">
          <div className="terms-and-condn-main-div-one">
            <h3>Limitation Period </h3>
          </div>
          <div className="terms-and-condn-main-div-two">
            <p>
              You hereby agree that your right to file a claim against the
              Company shall be limited to twelve (12) months from the date of
              the event underlying such claim. This clause constitutes an
              agreement for the limitation period (תקופת ההתיישנות) as per
              clause 19 of the Limitation Law – 1958, given the nature of the
              services provided by the Platform. This clause shall prevail over
              any dispositive norm of the applicable law.{" "}
            </p>
          </div>
        </div>

        <div className="terms-and-condn-main-div">
          <div className="terms-and-condn-main-div-one">
            <h3>Severability </h3>
          </div>
          <div className="terms-and-condn-main-div-two">
            <p>
              The invalidity or unenforceability of any provision of these Terms
              shall not affect the validity or enforceability of any other
              provision. Any invalid or unenforceable provision shall be deemed
              severed from these Terms to the extent of its invalidity or
              unenforceability, and these Terms shall be construed and enforced
              as if the Terms did not contain that particular provision to the
              extent of its invalidity or unenforceability.
            </p>
          </div>
        </div>

        <div className="terms-and-condn-main-div">
          <div className="terms-and-condn-main-div-one">
            <h3>Assignment </h3>
          </div>
          <div className="terms-and-condn-main-div-two">
            <p>
              These Terms, and any rights and licenses granted under the Rules,
              may not be transferred or assigned by you, but may be assigned by
              Company without restriction.  Any attempted transfer or assignment
              in violation hereof shall be null and void.  We may assign,
              transfer, or sublicense any or all of our rights or obligations
              under these Terms without restriction.{" "}
            </p>
          </div>
        </div>

        <div className="terms-and-condn-main-div">
          <div className="terms-and-condn-main-div-one">
            <h3>Errors and Complaints </h3>
          </div>
          <div className="terms-and-condn-main-div-two">
            <p>
              The Company makes substantial efforts to operate, maintain and
              improve the Platform. If you encounter any problem or error on the
              Platform or if you have any queries, comments or complaints,
              please let us know. Our contact details are set forth in the
              beginning of these Terms. We operate a complaint handling
              procedure which we will use to try to resolve disputes when they
              first arise.
            </p>
          </div>
        </div>

        <div className="terms-and-condn-main-div">
          <div className="terms-and-condn-main-div-one">
            <h3>Waiver </h3>
          </div>
          <div className="terms-and-condn-main-div-two">
            <p>
              If you breach the Rules and we take no action, we will still be
              entitled to use our rights and remedies in any other situation
              where you breach the Rules. If we choose in good faith at our
              absolute discretion to assist you with remedying of any breach of
              the Rules despite our right not to do so, such assistance shall
              not be deemed as a waiver of our rights with respect to such
              breach.{" "}
            </p>
          </div>
        </div>
        <div className="terms-and-condn-main-div">
          <div className="terms-and-condn-main-div-one">
            <h3>Law and Jurisdiction </h3>
          </div>
          <div className="terms-and-condn-main-div-two">
            <p>
              Information on this Platform is subject to the laws of Israel. By
              accessing this Platform, you agree that all disputes,
              controversies, or claims arising out of or in connection with the
              Platform shall be subject to the exclusive jurisdiction of the
              courts of Tel Aviv. By accessing this Platform from outside
              Israel, you acknowledge and agree that you do so at your own risk
              and are responsible for any failure to comply with any local,
              national or international law. The Platform may not be used by
              persons in jurisdictions where access to or use of the Platform or
              any part of it may be illegal or prohibited.{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsandConditions;
