import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import axios from "axios";
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";
import SearchLoader from "../Loader/SearchLoader";
import Add from "@mui/icons-material/Add";
import swal from "sweetalert";
import DietCalender from "./DietCalender";
import "./PatientDiet.css";
import { Link } from "react-router-dom";
import Apiservice from "../../Services/Apiservice";

const PatientDiet = () => {
  const patientUserName = localStorage.getItem("patientUserName");
  const Username = localStorage.getItem("Username");
  const is_patient = localStorage.getItem("is_patient");

  function ImageCellRenderer(params) {
    const imageUrl = params.value;
    return <img style={{ height: "3em" }} src={imageUrl} alt="Cell" />;
  }
  const gridRef = useRef();
  const [columnDefs, setColumnDefs] = useState([
    { field: "diet_date", headerName: "Date" },
    { field: "meal_time", filter: true, headerName: "Meal Time" },

    {
      field: "foods",
      filter: true,
      headerName: "Meal Name",
      headerClassName: "super-app-theme--header",
    },
    { field: "dietitian_firstName", headerName: "Dietition First Name" },
    { field: "dietitian_lastName", headerName: "Dietition Last Name" },
  ]);
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    resizable: true,
    suppressToolPanel: true,
  }));
  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(
      document.getElementById("filter-text-box").value
    );
  }, []);
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    fetch(
      is_patient == "true"
        ? `https://djangoobesitychat.azurewebsites.net/dietprescription/patient/${Username}/`
        : `https://djangoobesitychat.azurewebsites.net/dietprescription/patient/${patientUserName}/`
    )
      .then((data) => data.json())
      .then((data) => setTableData(data));
  }, []);

  // //console.log("tableData",tableData.length)

  // GET AI DIET
  const [GetAIDiet, setGetAIDiet] = useState(null);
  const [BreakfastDiet, setBreakfastforDiet] = useState();
  const [LunchDiet, setLunchforDiet] = useState("");
  const [DinnerDiet, setDinnerforDiet] = useState("");
  const [SnacksDiet, setSnacksforDiet] = useState("");
  useEffect(() => {
    Apiservice
      .get(`/suggesteddiet/patient_name/${Username}/`)
      .then((res) => {
        setGetAIDiet(res.data[0]["suggesteddiet"]);
        setBreakfastforDiet(res.data[0]["suggesteddiet"]["Breakfast"]);
        setLunchforDiet(res.data[0]["suggesteddiet"]["Lunch"]);
        setDinnerforDiet(res.data[0]["suggesteddiet"]["Dinner"]);
        setSnacksforDiet(res.data[0]["suggesteddiet"]["Snacks"]);
      })
      .catch((err) => {});
  }, [Username]);

  //console.log("GetAIDiet", GetAIDiet);

  const [isAddPrescriptionActive, setisAddPrescriptionActive] = useState(false);
  const ExercisePlanID = localStorage.getItem("ExercisePlanID");
  const trainer_name = localStorage.getItem("Username");
  const AddPrescriptionHandler = () => {
    setisAddPrescriptionActive(true);
  };
  const HideAddPrescriptionHandler = () => {
    setisAddPrescriptionActive(false);
  };

  const [opentwo, setOPentwo] = useState(false);
  const toggletwo = () => {
    setOPentwo(!opentwo);
  };

  //   SEARCH DIETPLAN
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  useEffect(() => {
    const loadPosts = async () => {
      setLoading(true);
      const response = await Apiservice.get(
        "/alldietplans/get/"
      );
      setPosts(response.data);
      setLoading(false);
    };
    loadPosts();
  }, []);

  const [days_of_operation, setdays_of_operation] = useState([]);
  const [patient_name, setpatient_name] = useState("");
  const [exercise_time, setexercise_time] = useState("");
  const [exerciseplans_id, setexerciseplans_id] = useState("");

  function handleChange(event) {
    setdays_of_operation([...days_of_operation, event.target.value]);
  }

  const AddPatientExercise = async (e) => {
    e.preventDefault();

    await Apiservice
      .post(
        // `https://djangoobesitychat.azurewebsites.net/user/doctorpostapi/`,
        `/dietprescription/post/`,

        {
          days_of_operation: days_of_operation,
          dietitian_name: trainer_name,
          patient_name: patientUserName,
          meal_time: exercise_time,
          diet_id: ExercisePlanID,
        }
      )
      .then((res) => {
        swal({
          title: "Diet Plan Added Successfully...",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });

        fetch(
          `https://djangoobesitychat.azurewebsites.net/dietprescription/patient/${patientUserName}/`
        )
          .then((data) => data.json())
          .then((data) => setTableData(data));
      });
    HideAddPrescriptionHandler().catch((err) => {});
  };

  return (
    <div>
      {is_patient == "true" ? (
        <div>
          {tableData.length > 0 ? (
            <div
              className="ag-theme-material"
              style={{
                width: "100%",
                height: "70vh",
                boxShadow: "rgb(0 0 0 / 35%) 0px 5px 15px",
                marginTop: "20px",
                overflowY: "scroll",
                overflowX: "scroll",
              }}
            >
              <AgGridReact
                ref={gridRef}
                rowData={tableData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                rowSelection="multiple"
              />
            </div>
          ) : (
            <div className="ai-diet-responce-main-div">
              {BreakfastDiet ? (
                <div>
                  <div className="ai-diet-main-div-one">
                    <h3>Breakfast</h3>
                  </div>
                  <div style={{ paddingLeft: "100px", paddingTop: "15px" }}>
                    {BreakfastDiet &&
                      BreakfastDiet?.map((index) => <li>{index}</li>)}
                  </div>
                </div>
              ) : (
                ""
              )}

              {LunchDiet ? (
                <div>
                  <div className="ai-diet-main-div-one">
                    <h3>Lunch</h3>
                  </div>
                  <div style={{ paddingLeft: "100px", paddingTop: "15px" }}>
                    {LunchDiet && LunchDiet?.map((index) => <li>{index}</li>)}
                  </div>
                </div>
              ) : (
                ""
              )}

              {DinnerDiet ? (
                <div>
                  <div className="ai-diet-main-div-one">
                    <h3>Dinner</h3>
                  </div>
                  <div style={{ paddingLeft: "100px", paddingTop: "15px" }}>
                    {DinnerDiet && DinnerDiet?.map((index) => <li>{index}</li>)}
                  </div>
                </div>
              ) : (
                ""
              )}

              {SnacksDiet ? (
                <div>
                  <div className="ai-diet-main-div-one">
                    <h3>Snacks</h3>
                  </div>
                  <div style={{ paddingLeft: "100px", paddingTop: "15px" }}>
                    {SnacksDiet && SnacksDiet?.map((index) => <li>{index}</li>)}
                  </div>
                </div>
              ) : (
                ""
              )}

              {GetAIDiet == null ? (
                <div className="email-verification-main-div-two">
                <Link to="/patientDashboard/aidiet">
                  <button
                    className="login-page-login-btn"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      //   width: "60px",
                      padding: "10px",
                    }}
                  >
                    Generate Your Diet Plan
                  </button>
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      ) : (
        <div>
          <div>
            {isAddPrescriptionActive == true ? null : (
              <div className="add-pat-btn-div">
                <button
                  className="add-btn-btn-tag"
                  onClick={AddPrescriptionHandler}
                >
                  ADD DIET PLAN
                  <BsFillCaretDownFill
                    style={{ height: "20px", width: "20px" }}
                  />
                </button>
              </div>
            )}

            {isAddPrescriptionActive == true ? (
              <div className="add-pat-btn-div">
                <button
                  className="add-btn-btn-tag"
                  onClick={HideAddPrescriptionHandler}
                >
                  HIDE DIET PLAN FORM
                  <BsFillCaretUpFill
                    style={{ height: "20px", width: "20px" }}
                  />
                </button>
              </div>
            ) : (
              ""
            )}
          </div>

          {isAddPrescriptionActive == true ? (
            <div className="diet-presc-calender-main-div">
              <DietCalender />
            </div>
          ) : (
            ""
          )}

          <div
            className="ag-theme-material"
            style={{
              width: "100%",
              height: "70vh",
              boxShadow: "rgb(0 0 0 / 35%) 0px 5px 15px",
              marginTop: "20px",
              overflowY: "scroll",
              overflowX: "scroll",
            }}
          >
            <AgGridReact
              ref={gridRef}
              rowData={tableData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              animateRows={true}
              rowSelection="multiple"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PatientDiet;
