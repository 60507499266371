import { AgGridReact } from "ag-grid-react";
import React from "react";
import { useState } from "react";
import { useCallback } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useMemo } from "react";

const ClinicPatientPrescription = () => {
  const patientIDforPrescription = localStorage.getItem("patientUserName");

  function ImageCellRenderer(params) {
    const imageUrl = params.value;
    return <img style={{ height: "3em" }} src={imageUrl} alt="Cell" />;
  }
  const gridRef = useRef();
  const [columnDefs, setColumnDefs] = useState([
    { field: "prescription_id", filter: true, headerName: "ID" },
    { filter: true, headerName: "Medicine Name", 
    valueGetter: params => params.data.medicine_name.map(med => med.medicine_name).join(', ')
  
  },

    { headerName: "Dosage", valueGetter: params => params.data.medicine_name.map(med => med.dosage).join(', ') },
    { field: "comment", headerName: "Comment" },
    { field: "doctor_id", headerName: "Doctor ID" },
  ]);
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    resizable: true,
    suppressToolPanel: true,
  }));
  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(
      document.getElementById("filter-text-box").value
    );
  }, []);
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    fetch(
      `https://djangoobesitychat.azurewebsites.net/prescription2api/patient_name/${patientIDforPrescription}/`
    )
      .then((data) => data.json())
      .then((data) => setTableData(data));
    // setLoading(false);
  }, []);

    //console.log("Prescription Table",tableData);
  return (
    <div style={{padding:"20px"}}>
    <div className="acc-setting-main-div">
          <div className="update-account-title-div">
            <h2>Prescription</h2>
          </div>
        </div>
    <div
      className="ag-theme-material"
      style={{
        width: "100%",
        height: "50vh",
        boxShadow: "rgb(0 0 0 / 35%) 0px 5px 15px",
        marginTop: "20px",
      }}
    >
      <AgGridReact
        ref={gridRef}
        rowData={tableData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        animateRows={true}
        rowSelection="multiple"
      />
    </div>
    </div>
  );
};

export default ClinicPatientPrescription;
