import React, { useState } from "react";

import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../Loader/Loader";
import AutoComplete from "../Map/AutoComplete";

const SubClinicRegistration = () => {
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [password2, setpassword2] = useState("");
  const [email, setemail] = useState("");
  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [mobile, setmobile] = useState("");
  const [address, setaddress] = useState("");
  const [age, setage] = useState("");
  const [gender, setgender] = useState("");
  const [date_of_birth, setdate_of_birth] = useState("");
  const [image, setimage] = useState("");
  const navigate = useNavigate();
  const [response, setresponse] = useState();
  const [selectedFile, setSelectedFile] = useState("");
  const [responseData, setResponseData] = useState(null);

  // const register = async (e) => {
  //   e.preventDefault();

  //   await axios
  //     .post(`https://djangoobesitychat.azurewebsites.net/user/subclinic/register/`, {
  //       username: username,
  //       password: password,
  //       password2: password2,
  //       email: email,
  //       firstName: first_name,
  //       lastName: last_name,
  //       mobile: mobile,
  //       address: address,
  //       age: null,
  //       gender: null,
  //       date_of_birth: null,
  //       image: null,
  //     })
  //     .then((res) => {
  //       navigate("/clinicsidebar/subcliniclist");
  //     })
  //     .catch((res) => {
  //       // // //console.log("err", res.response.data);
  //       setresponse(res.response.data);
  //     });
  // };

  const onHandleChange = (childData) => {
    // //console.log("Value inside Patient is",childData )
    setaddress(childData)
  };

  const [submitLoading, setsubmitLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setsubmitLoading(true);

    const formData = new FormData();
    formData.append("image", selectedFile && selectedFile);
    formData.append("username", username);
    formData.append("password", password);
    formData.append("password2", password2);
    formData.append("email", email);
    formData.append("firstName", first_name);
    formData.append("lastName", last_name);
    formData.append("mobile", mobile);
    formData.append("address", address);
    formData.append("age", age);
    formData.append("gender", gender);
    formData.append("date_of_birth", date_of_birth);
    const medDocsField = formData.get("image");
    // // //console.log(medDocsField);

    axios({
      method: "post",
      url: `https://djangoobesitychat.azurewebsites.net/user/subclinic/register/`,
      data: formData,
    })
      .then((res) => {
        // // //console.log("res", res);
        localStorage.setItem("SubCLinicUserNameDuringRegistration", username);
        navigate("/clinicsidebar/subclinicextradetails");
        setsubmitLoading(false);
      })

      .catch((res) => {
        // // //console.log("err", res.response.data);
        setsubmitLoading(false);
        setresponse(res.response.data);
      });
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  //data for chatengine
 
  return (
    <div className="login-page-flex-div">
      {submitLoading && (
        <div className="purchase-order-details-pop-up-main-div">
          <div className="purchase-order-details-pop-up-sub-div">
            <div
              className="purchase-order-details-pop-up-sub-div-container"
              style={{ background: "none" }}
            >
              <Loader />
            </div>
          </div>
        </div>
      )}
      <div className="login-page-width-div">
        <div className="login-page-main-div">
          <div
            className="login-page-sub-div-one"
            style={{ paddingBottom: "20px" }}
          >
            <div
              className="login-page-sub-one-sec-one"
              style={{ paddingBottom: "0px" }}
            >
              <h4>obesity tool</h4>
              <p>by sk pharma</p>
              <h5>Sub Clinic Registration</h5>
            </div>
            <div className="login-page-sub-one-sec-two">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "60px",
                }}
              >
                <div className="input-fiend-one">
                  <input
                    type="text"
                    className="email-input-field-tag"
                    placeholder="Sub Clinic Name"
                    style={{ width: "290px" }}
                    value={first_name}
                    onChange={(e) => setfirst_name(e.target.value)}
                  />
                  <div className="error-message-preview">
                    <p>{response && response.firstName}</p>
                  </div>
                </div>
                <div className="input-fiend-one">
                  <input
                    type="text"
                    className="email-input-field-tag"
                    placeholder="Sub Clinic Branch Name"
                    style={{ width: "290px" }}
                    value={last_name}
                    onChange={(e) => setlast_name(e.target.value)}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "60px",
                }}
              >
                <div className="input-fiend-one">
                  <input
                    type="email"
                    className="email-input-field-tag"
                    placeholder="Sub Clinic Email ID"
                    style={{ width: "290px" }}
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                  />
                  <div className="error-message-preview">
                    <p>{response && response.email}</p>
                  </div>
                </div>

                <div className="input-fiend-one">
                  <input
                    type="number"
                    className="email-input-field-tag"
                    placeholder="Sub Clinic Mobile Number"
                    style={{ width: "290px" }}
                    value={mobile}
                    onChange={(e) => setmobile(e.target.value)}
                  />
                  <div className="error-message-preview">
                    <p>{response && response.mobile}</p>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "60px",
                }}
              >
                <div className="input-fiend-one">
                  <input
                    type="text"
                    className="email-input-field-tag"
                    placeholder="Sub Clinic Username"
                    style={{ width: "290px" }}
                    value={username}
                    onChange={(e) => setusername(e.target.value)}
                  />
                  <div className="error-message-preview">
                    <p>{response && response.username}</p>
                  </div>
                </div>
                <div className="input-fiend-one">
                  <input
                    type="file"
                    className="email-input-field-tag"
                    style={{ width: "290px" }}
                    onChange={handleFileSelect}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "60px",
                }}
              >
                <div className="input-fiend-one">
                  <input
                    type="password"
                    className="email-input-field-tag"
                    placeholder="Password"
                    style={{ width: "290px" }}
                    value={password}
                    onChange={(e) => setpassword(e.target.value)}
                  />
                </div>
                <div className="input-fiend-one">
                  <input
                    type="password"
                    className="email-input-field-tag"
                    placeholder="Check Password"
                    style={{ width: "290px" }}
                    value={password2}
                    onChange={(e) => setpassword2(e.target.value)}
                  />
                  <div className="error-message-preview">
                    <p>{response && response.password2}</p>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "60px",
                }}
              >
                <div className="input-fiend-one">
                <AutoComplete parentCallback={onHandleChange} />
                </div>

                <div className="input-fiend-one">
                  <input
                    type="date"
                    className="email-input-field-tag"
                    placeholder="Registration Date"
                    style={{ width: "290px" }}
                    value={date_of_birth}
                    onChange={(e) => setdate_of_birth(e.target.value)}
                  />
                </div>
              </div>
              {/* 
              <div className="input-fiend-one">
                <input
                  type="text"
                  className="email-input-field-tag"
                  placeholder="Age"
                  style={{width:"290px"}}
                  value={age}
                  onChange={(e) => setage(e.target.value)}
                />
              </div>
              <div style={{display:"flex", justifyContent:"center", alignItems:"center", gap:"60px"}}>
              <div className="input-fiend-one" style={{paddingTop:"5px"}}><h4>Gender</h4></div>
              <div
                className="input-fiend-one"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "60px",
                  paddingTop:"3px"
                }}
              >
                
                <div
                  style={{
                    display: "flex",
                    gap: "2px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="radio"
                    name="gender"
                    value="Male"
                    style={{}}
                    onChange={(e) => setgender(e.target.value)}
                  />
                  <p>Male</p>
                </div>
                <div style={{ display: "flex", gap: "2px" }}>
                  <input
                    type="radio"
                    name="gender"
                    value="Female"
                    style={{}}
                    onChange={(e) => setgender(e.target.value)}
                  />
                  <p>Female</p>
                </div>
              </div>
              </div>
              <div className="input-fiend-one" style={{paddingTop:"5px"}}><h4>Date of Birth</h4></div>
              <div className="input-fiend-one" style={{paddingTop:"3px"}}>
                <input
                  type="date"
                  className="email-input-field-tag"
                  placeholder="Date of Birth"
                  style={{width:"290px"}}
                  value={date_of_birth}
                  onChange={(e) => setdate_of_birth(e.target.value)}
                />
              </div>
              */}
            </div>

            <div className="login-page-sub-one-sec-four">
              <button onClick={handleSubmit} className="login-page-login-btn">
                Register a Sub Clinic
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubClinicRegistration;
