import React from "react";
import { useState } from "react";

const BlogHomePage = () => {
  const [open, setOPen] = useState(false);
  const toggle = () => {
    setOPen(!open);
  };
  return (
    <div className="blog-page-flex-div">
      <div className="blog-page-width-div">
        <div className="blog-page-main-div">
          <div className="blog-page-sub-div-one">
            <div>
              <img
                src="https://media.istockphoto.com/id/1297146235/photo/blue-chromosome-dna-and-gradually-glowing-flicker-light-matter-chemical-when-camera-moving.jpg?b=1&s=170667a&w=0&k=20&c=exQXSk71T61mZ01CIqLgPSvYqmDfduPakUNw491s6zI="
                alt=""
                className="blog-main-img-tag"
              />
            </div>
            <div className="blod-data-main-div">
              <div className="blog-header-div">
                <h2>Phenotype Drilldown</h2>
                <p>January 03,2023 </p>
              </div>
              <div className="blog-main-para-div">
                <h3>Hungry Brain</h3>
                <p>
                  Excessive calory consumption without feeling full Delayed
                  satiation during meal the brain is unable to determine when to
                  terminate a meal caused by genetics\metabolic factors
                </p>

                <h3>Hungry Gut</h3>
                <p>
                  Normal food intake during meal characterized with a rapid feel
                  of hunger shortly after meal frequently get hungry in between
                  meals The cause of the lack of satiety post-eating is because
                  the stomach emptying the food faster than other people
                </p>

                {open && (
                  <div>
                    <h3>Emotional Hunger</h3>
                    <p>
                      Eating as a response to emotions Looking for food as a
                      reaction to negative or positive emotions or as a
                      self-compensation action Characterized by craving, anxiety
                      and negative moods
                    </p>

                    <h3>Slow Burn</h3>
                    <p>
                    Slow metabolism burns fewer calories, which means more get stored as fat in the body

                    Also characterized by low muscle mass
                    
                    Influenced by genetic factors
                    </p>
                  </div>
                )}
              </div>
              
              <div>
                <div className="add-pat-btn-div">
                {!open && (
                  <button className="read-more-btn" onClick={toggle}>
                    Read More
                  </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="blog-page-sub-div-two">
            <div className="blog-page-sub-div-two-sec-one">
              <h3>RECENT POSTS</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisci elit, sed
                eiusmod tempor incidunt ut labore et dolore magna aliqua.
              </p>
            </div>
            <div className="blog-page-sub-div-two-sec-two">
              <h3>RECENT COMMENTS</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisci elit, sed
                eiusmod tempor incidunt ut labore et dolore magna aliqua.
              </p>
            </div>
            <div className="blog-page-sub-div-two-sec-two">
              <h3>ARCHIVES</h3>
              <p>October 2022</p>
              <p>September 2022</p>
              <p>August 2022</p>
              <p>July 2022</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogHomePage;
