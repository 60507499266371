import axios from "axios";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import Loader from "../../Loader/Loader";
import SearchLoader from "../../Loader/SearchLoader";
// import MyStatefulEditor from "../MarketingMailbyClinicSubClinic/RichTextEditor";
import Apiservice from "../../../Services/Apiservice";
import { Textarea } from "@mui/joy";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import the styles

const SelectedUserMail = () => {
  const [val, setVal] = useState([""]);
  const [subject, setsubject] = useState([]);

  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const clinicID = localStorage.getItem("ClinicIDForHomepage");
  const subclinic_id = localStorage.getItem("SubClinicIDForHomepage");
  const [editorHtml, setEditorHtml] = useState('');

  useEffect(() => {
    const loadPosts = async () => {
      setLoading(true);
      const response = await axios.get(
        is_clinic == "true"
          ? `https://djangoobesitychat.azurewebsites.net/user/patient/clinic/${clinicID}/`
          : `https://djangoobesitychat.azurewebsites.net/user/patient/subclinic/${subclinic_id}/`
      );
      setPosts(response.data);
      setLoading(false);
    };
    loadPosts();
  }, []);

  const [opentwo, setOPentwo] = useState(false);
  const toggletwo = () => {
    setOPentwo(!opentwo);
  };

  const onChange = (value) => {
    setVal(value);
  };

  const id = localStorage.getItem("id");

  const [userdetails, setuserdetails] = useState([]);
  useEffect(() => {
    Apiservice
      .get(`/current/user/details/${id}/`)

      .then((response) => {
        setuserdetails(response.data);
      })
      .catch((err) => {});
  }, [id]);

  //   // //console.log("userdetails",userdetails)
  const [submitLoading,setsubmitLoading]=useState(false)

  const is_clinic = localStorage.getItem("is_clinic");

  const SendMail = async (e) => {
    e.preventDefault();
    setsubmitLoading(true)

    await Apiservice
      .post(
        `/selecteduser/sendemail/`,

        {
          usernames: MailReceiver,
          body: val,
          subject: subject,
          sender: userdetails.email,
        }
      )
      .then((res) => {
        swal({
          title: "Mail to the Selected Patients Sent Successfully...",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });
      })
    setsubmitLoading(false)

      .catch((res) => {});
  };

  const [MailReceiver, setMailReceiver] = useState([]);
  //console.log("MailReceiver", MailReceiver);

  return (
    <div className="marketing-mail-width-div">
    {submitLoading &&
        <div className="purchase-order-details-pop-up-main-div">
              <div className="purchase-order-details-pop-up-sub-div">
                <div className="purchase-order-details-pop-up-sub-div-container" style={{background:"none"}}>
        <Loader/>
        </div>
        </div>
        </div>
      }
      <div className="marketing-mail-flex-div">
        <div className="marketing-mail-main-div">
          <div className="marketing-mail-main-div-one">
            <div className="marketing-mail-main-div-one-sender">
              <h3>Search Patient </h3>
            </div>
            <div className="marketing-mail-main-div-one-sender-text">
              <input
                type="text"
                placeholder="Search Patient..."
                className="marketing-mail-main-div-one-sender-input"
                onClick={toggletwo}
                onChange={(e) => setSearchTitle(e.target.value)}
              />
              {opentwo && (
                <div
                  className="list-of-all-user"
                  onClick={toggletwo}
                  style={{ width: "330px  " }}
                >
                  {loading ? (
                    <div>
                      <SearchLoader />
                    </div>
                  ) : (
                    posts
                      .filter((value) => {
                        if (searchTitle === "") {
                          return posts;
                        } else if (
                          (value.patient_name,
                          value.patient_firstName
                            ?.toLowerCase()
                            .includes(searchTitle?.toLowerCase()))
                        ) {
                          return value;
                        }
                      })
                      .map((item) => (
                        <div className="list-of-user-main-flex-div">
                          <div
                            className="list-of-user-main-div"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <p>
                              {item.patient_firstName} {item.patient_lastName}
                            </p>
                            <button
                              className="disease-handler-input-search-tag-btn"
                              onClick={() => {
                                setMailReceiver([
                                  ...MailReceiver,
                                  item.patient_name,
                                ]);
                              }}
                            >
                              Select
                            </button>
                          </div>
                        </div>
                      ))
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="marketing-mail-main-div-one">
            <div className="marketing-mail-main-div-one-sender">
              <h3>To</h3>
            </div>
            <div className="marketing-mail-main-div-one-sender-text">
              <div
                className="marketing-mail-main-div-one-sender-input"
                style={{ minHeight: "30px" }}
              >
                {MailReceiver &&
                  MailReceiver.map((indextwo) => <li>{indextwo}</li>)}
              </div>
            </div>
          </div>

          <div className="marketing-mail-main-div-one">
            <div className="marketing-mail-main-div-one-sender">
              <h3>Subject</h3>
            </div>
            <div className="marketing-mail-main-div-one-sender-text">
              <input
                type={"text"}
                className="marketing-mail-main-div-one-sender-input"
                value={subject}
                onChange={(e) => setsubject(e.target.value)}
              />
            </div>
          </div>

          <div className="marketing-mail-main-div-one">
            <div className="marketing-mail-main-div-one-sender">
              <h3>Body</h3>
            </div>
          </div>

          <div className="marketing-mail-main-div-one">
          <ReactQuill value={editorHtml} onChange={onChange} />
          </div>

          <div
            className="marketing-mail-main-div-one"
            style={{ marginTop:"25px", display: "flex", justifyContent: "center" }}
          >
            <button
              onClick={SendMail}
              className="disease-handler-input-search-tag-btn"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectedUserMail;
