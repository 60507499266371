import axios from "axios";
import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import Loader from "../Loader/Loader";
import swal from "sweetalert";
import DiseaseHandler from "./PatientQuestionnaireNew";
import EastSharpIcon from "@mui/icons-material/EastSharp";
import { Link } from "react-router-dom";
import Apiservice from "../../Services/Apiservice";
import { useNavigate } from "react-router-dom";

const Que = forwardRef((props, ref) => {
  const [detail, setdetail] = useState("");
  const UsernameofPatient = localStorage.getItem("Username");
  const [response, setresponse] = useState("");
  const [message, setMessage] = useState("");

  return (
    <div>
      <div className="questo-form-quesyion-ans-main-div-for-main">
      <div className="questo-form-questions-wrapper" style={{ width: "55%", cursor:"pointer" }}>
      <div className="questo-form-questions-div" >
          <p className="question-text">{props.number}. {props.item.question}</p>
          {props.item.mandatory === "Yes" ? (
              <span style={{ color: "red" }}> *</span>
            ) : null}
        </div>
        <div className="tooltip">More information</div>
        </div>
        <div className="response-div">
        <div className="questo-form-options-div" style={{ width: "15%" }}>
        <p classname="label-for-response" style={{color: '#88869d', marginBottom: '30px'}}>Response: </p>
          <div style={{ display: "flex", gap: "9px" }}>
            <input
              className="add-patient-input-field-of-radio"
              name={props.item.question_id}
              type="radio"
              value="Yes"
              checked={props.item.patient_response1 === "Yes"}
              onChange={(event) =>
                props.radioButtonHandler({
                  que_id: props.item.question_id,
                  val: event.target.value,
                })
              }
            />
            <p style={{ fontSize: "13px" }}>Yes</p>
            <input
              className="add-patient-input-field-of-radio"
              name={props.item.question_id}
              type="radio"
              value="No"
              checked={props.item.patient_response1 === "No"}
              onChange={(event) =>
                props.radioButtonHandler({
                  que_id: props.item.question_id,
                  val: event.target.value,
                })
              }
            />
            <p style={{ fontSize: "13px" }}>No</p>
          </div>
        </div>
        <div
          className="questo-form-details-div-in-pat"
          style={{ width: "30%" }}
        >
          <p classname="questo-new-label-for-pat-que" style={{color: '#88869d'}}>Details: </p>
          <textarea
            className="questo-form-input-tag-for-patient"
            type={"text"}
            placeholder="Details..."
            onChange={(event) =>
              props.detailsHandler({
                que_id: props.item.response_id1,
                val: event.target.value,
              })
            }
          />
        </div>
      </div>
      </div>
    </div>
  );
});

const PatientQuestionnaireGeneral = (handleNext) => {
  const [questionetailsget, setquestionetailsget] = useState([]);
  const [loading, setLoading] = useState(true);
  const [questionsDisease, setquestionsDisease] = useState([]);
  const [questionsLifestyle, setquestionsLifestyle] = useState([]);
  const [questionsMedicine, setquestionsMedicine] = useState([]);
  const [questionsOther, setquestionsOther] = useState([]);

  const childRef = useRef();

  useEffect(() => {
    Apiservice
      .get(`/question/category/General/`, {})

      .then((response) => {
        const data = response.data.map((entry) => {
          return Object.assign({}, entry, {
            patient_name: localStorage.getItem("Username"),
            question1: entry.question_id,
          });
        });
        setquestionetailsget(data);
        //console.log(questionetailsget);
        setLoading(false);
      })
      .catch((err) => {});
  }, []);

  

  

  const gender = localStorage.getItem("Gender")

  //filter question related to pregnancy or breast feeding if gender is male
 

  const radioButtonHandler = (param) => {
    const newResponse = questionetailsget.map((item) => {
      if (item.question_id == param.que_id) {
        item.patient_response1 = param.val;
      }
      return item;
    });
    setquestionetailsget(newResponse);
  };

  const detailsHandler = (param) => {
    const newResponse = questionetailsget.map((item) => {
      if (item.question_id == param.que_id) {
        item.response_details1 = param.val;
      }
      return item;
    });
    setquestionetailsget(newResponse);
  };

  const [submitLoading,setsubmitLoading]=useState(false)

  const submitHandler = (props) => {
    const UsernameofPatient = localStorage.getItem("Username");
    const test = questionetailsget;
    setsubmitLoading(true)
    

    

    axios
      .post(
        `https://djangoobesitychat.azurewebsites.net/patient/response/post/General/${UsernameofPatient}/`,
        questionetailsget,
        {}
      )
      .then((res) => {
        // // //console.log("res", res);
        localStorage.setItem("General", true);
        swal(
          {
            title: "Your Response has been Submitted Successfully",
            className: "successAlert",
            buttons: true,
            timer: 3000,
          },
    setsubmitLoading(false),
    
          function () {
            DiseaseHandler();
          }
        );
      })
      .catch((err) => {
    setsubmitLoading(false)

        swal({
          title: "Your Response has been Submitted Successfully",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });
      });
     
  };
  
  useEffect (()=> {
    fetchQuestions();
  }, [])

  const api_url_1 = "https://djangoobesitychat.azurewebsites.net/question/category/Disease/"
  const api_url_2 = "https://djangoobesitychat.azurewebsites.net/question/category/Lifestyle/"
  const api_url_3 = "https://djangoobesitychat.azurewebsites.net/question/category/Medicine/"
  const api_url_4 = "https://djangoobesitychat.azurewebsites.net/question/category/Other/"
  const api_url_5 = "https://djangoobesitychat.azurewebsites.net/emotional/get-quiz/"

  
  const navigate = useNavigate();
  // Fetch questions for multiple categories using Promise.all()
  const fetchQuestions = async () => {
    try {
      const categoryAPIs = [api_url_1, api_url_2, api_url_3, api_url_4, api_url_5]; // Add more API URLs for additional categories
      const requests = categoryAPIs.map((apiUrl) => axios.get(apiUrl));
      const responses = await Promise.all(requests);
      const questions = responses.map((response) => response.data);
      const diseaseData = questions[0].map((entry) => {
        return Object.assign({}, entry, {
          patient_name: localStorage.getItem("Username"),
          question1: entry.question,
        });
      });
      setquestionsDisease(diseaseData);
     
      const lifestyleData = questions[1].map((entry) => {
        return Object.assign({}, entry, {
          patient_name: localStorage.getItem("Username"),
          question1: entry.question,
        });
      });
      setquestionsLifestyle(lifestyleData);
      const medicineData = questions[2].map((entry) => {
        return Object.assign({}, entry, {
          patient_name: localStorage.getItem("Username"),
          question1: entry.question,
        });
      });
      setquestionsMedicine(medicineData);
      const otherData = questions[3].map((entry) => {
        return Object.assign({}, entry, {
          patient_name: localStorage.getItem("Username"),
          question1: entry.question,
        });
      });
      setquestionsOther(otherData);
      
      
      setLoading(false);
    } catch (error) {
      console.error('Error fetching questions:', error);
    }
  };

  //console.log("questionetailsget:", questionetailsget)
  //console.log("questionsDisease:", questionsDisease)

  const UsernameofPatient = localStorage.getItem("Username");
  const post_api_url_1 = `https://djangoobesitychat.azurewebsites.net/patient/response/post/Lifestyle/${UsernameofPatient}/`
  const post_api_url_2 = `https://djangoobesitychat.azurewebsites.net/patient/response/post/Other/${UsernameofPatient}/`
  const post_api_url_3 = `https://djangoobesitychat.azurewebsites.net/patient/response/post/Medicine/${UsernameofPatient}/`
  const post_api_url_4 = `https://djangoobesitychat.azurewebsites.net/patient/response/post/Disease/${UsernameofPatient}/`

  const skipallHandler = async () => {
  const UsernameofPatient = localStorage.getItem("Username");
  setsubmitLoading(true);
  try {
    await axios.post(post_api_url_1, questionsLifestyle, {});
    await axios.post(post_api_url_2, questionsOther, {});
    await axios.post(post_api_url_3, questionsMedicine, {});
    await axios.post(post_api_url_4, questionsDisease, {});

    
    swal({
      title: "Your Response has been Submitted Successfully",
      className: "successAlert",
      buttons: true,
      timer: 3000,
    });
  } catch (error) {
    console.error('Error submitting questions:', error);
  }
  setsubmitLoading(false);
  navigate('/');

};

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
        {submitLoading &&
          <div className="purchase-order-details-pop-up-main-div">
                <div className="purchase-order-details-pop-up-sub-div">
                  <div className="purchase-order-details-pop-up-sub-div-container" style={{background:"none"}}>
          <Loader/>
          </div>
          </div>
          </div>
        }
          <div>
          <div className="skip-for-now-div">
          <Link>
            <h3 onClick={submitHandler}>
              Skip For Now <EastSharpIcon style={{ marginTop: "4px" }} />
            </h3>
          </Link>
          <Link>
            <h3 onClick={skipallHandler}>
              Skip All <EastSharpIcon style={{ marginTop: "4px" }} />
            </h3>
          </Link>
        </div>
            {/* <div
              className="questo-form-quesyion-ans-main-div-for-main"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="questo-form-questions-div"
                style={{ width: "55%", justifyContent: "center" }}
              >
                <h3>Questions</h3>
              </div>
              <div
                className="questo-form-options-div"
                style={{ width: "15%", justifyContent: "center" }}
              >
                <h3>Response</h3>
              </div>
              <div
                className="questo-form-details-div-in-pat"
                style={{ width: "30%", justifyContent: "center" }}
              >
                <h3>Details</h3>
              </div>
            </div> */}
            {questionetailsget.map((item, index) => {
              return (
                <Que
                  key={item.question_id}
                  number={index + 1}
                  item={item}
                  ref={childRef}
                  detailsHandler={detailsHandler}
                  radioButtonHandler={radioButtonHandler}
                />
              );
            })}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "0px",
            }}
          >
            <button onClick={submitHandler} className="submit-btn-in-que">
              Submit
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default PatientQuestionnaireGeneral;
