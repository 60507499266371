import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="terms-and-condn-flex-div">
      <div className="terms-and-condn-width-div">
        <div className="terms-and-condn-main-div">
          <div className="terms-and-condn-main-div-one">
            <h3>Privacy Policy </h3>
          </div>
          <div className="terms-and-condn-main-div-two">
            <p>
              This privacy policy sets out how we collect, protect, use and
              disclose information on this electronic platform [______] (the
              <b>"Platform"</b>) owned and operated by us, [______] (the{" "}
              <b>“Company”</b>).{" "}
            </p>
            <p>
              We are committed to ensuring that your privacy is protected.
              Please be assured that all identifiable information collected by
              us through the Platform will only be used in accordance with this
              privacy policy.{" "}
            </p>
            <p>
              We may change this policy from time to time by updating this page.
              You should periodically visit this page to review the then current
              privacy policy to be aware of the privacy terms and your rights
              relating thereto.
            </p>
          </div>
        </div>
        <div className="terms-and-condn-main-div">
          <div className="terms-and-condn-main-div-one">
            <h3>What types of information we collect </h3>
          </div>
          <div className="terms-and-condn-main-div-two">
            <p>We collect the following types of information:</p>
            <p>
              <b>Personal information</b> – information that identifies you as
              an individual or relates to an identifiable individual. This
              information may include both the <b>general information</b>, such
              as your name, phone number, date of birth, gender, location, IP
              address, email address, etc., and the{" "}
              <b>information related to your health</b>, such as information
              about your illness, symptoms and their potential causes, your
              medical history, your test results, and any other personal health
              information.{" "}
            </p>
            <p>
              <b>Non-personal information</b> – un-identified and
              non-identifiable information about users who use the Platform
              anonymously and about users who create an account, as well as
              anonymized statistical data generated in the ordinary course of
              operation of the Platform. This information mainly consists of
              technical and aggregated usage information, such as statistical
              aggregated analysis of the Platform's operation, browsing
              activities and browser information, app usage data, information
              collected through cookies, pixel tags and other technologies.{" "}
            </p>
          </div>
        </div>
        <div className="terms-and-condn-main-div">
          <div className="terms-and-condn-main-div-one">
            <h3>How we collect personal information </h3>
          </div>
          <div className="terms-and-condn-main-div-two">
            <p>
              You hereby acknowledge that you are under no legal obligation to
              disclose any information to us. Upon your first access to the home
              page of the Platform, you are requested to provide your consent to
              collection, storing and use of your certain personal information
              in accordance with this privacy policy. If you don't want to share
              such information with us, please leave the Platform. If you decide
              to continue using the Platform, such use shall be subject to the
              terms of this privacy policy.{" "}
            </p>
            <p>
              We may expressly ask you to share your information with us. For
              example, we do so when you create an account on the Platform or
              contact us directly by online forms, email, social media or
              otherwise. This information may include, among other things,
              sensitive personal information relating to your health. You have
              absolute discretion as to whether or not to share with us any such
              information, provided that some of our services cannot be rendered
              in absence of any certain necessary information requested.
              Likewise, absence of any unnecessary information requested, can
              naturally cause our services to be less effective or less
              personalized to your specific requirements.  {" "}
            </p>
            <p>
              Subject to your express consent, we may receive any such
              information about you from third parties, in particular from the
              clinic where you are receiving, or is willing to receive, medical
              treatment of the designated problem. Any transfer of such
              information to us is subject to your express knowing consent,
              which the clinic is obligated to receive from you before sharing
              with us any of your personal information.{" "}
            </p>
            <p>
              If you disclose to us any personal information relating to other
              people, you represent that you have the authority to do so and to
              permit us to use the information in accordance with this privacy
              policy.{" "}
            </p>
            <p>
              In addition to the information which you deliberately disclose to
              us upon our express request, or which you authorize the clinic to
              share with us, we collect certain information automatically when
              you visit or use the Platform. We do this ourselves or with the
              help of third-party services, including through the use of
              “cookies” and other tracking technologies, as further detailed
              below. This information may include your IP address (which may
              also be associated with your domain name or the domain name of
              your internet service provider), data relating to your use and
              navigation, unique identification numbers associated with your
              mobile device or our mobile application and your approximate
              geographical location. We collect such information based on your
              approval of the terms of this privacy policy upon your access to
              the Platform.{" "}
            </p>
          </div>
        </div>
        <div className="terms-and-condn-main-div">
          <div className="terms-and-condn-main-div-one">
            <h3>How we collect non-personal information </h3>
          </div>
          <div className="terms-and-condn-main-div-two">
            <p>
              We and our service providers may collect non-personal information
              in a variety of ways. For example:{" "}
            </p>
            <li>
              Certain information is collected by most browsers or automatically
              through your device, such as your Media Access Control (MAC)
              address, computer type (Windows or Mac), screen resolution,
              operating system name and version, device manufacturer and model,
              language, Internet browser type and version and the name and
              version of the Platform software you are using.  We use this
              information to ensure that the services function properly.{" "}
            </li>
            <li>
              When you download and use the Platform as a mobile application, we
              and our service providers may track and collect the application
              usage data, such as the date and time the application on your
              device accesses our servers and what information and files have
              been downloaded to the application based on your device number.{" "}
            </li>
            <li>
              Certain information is collected by use of cookies. Cookies are
              pieces of information stored directly on the computer that you are
              using. Cookies allow us to collect information such as browser
              type, time spent on the services, pages visited, language
              preferences, and other traffic data.  We and our service providers
              use the information for security purposes, to facilitate
              navigation, to display information more effectively, and to
              personalize your experience.  We also gather statistical
              information about use of the services in order to continually
              improve their design and functionality, understand how they are
              used, and assist us with resolving questions regarding them.  If
              you do not want information collected through the use of cookies,
              most browsers allow you to automatically decline cookies or be
              given the choice of declining or accepting a particular cookie (or
              cookies) from a particular website. Please note that this may
              prevent you from taking full advantage of the services provided by
              the Platform.{" "}
            </li>
            <li>
              Pixel tags (also known as web beacons and clear GIFs) may be used
              to, among other things, track the actions of users of the services
              (including email recipients), measure the success of our marketing
              campaigns, and compile statistics about usage of the services and
              response rates.{" "}
            </li>
            <li>
              We may use third party analytics services, such as Google
              Analytics, which uses cookies and similar technologies to collect
              and analyze information about use of the services and report on
              activities and trends.  This service may also collect information
              regarding the use of other websites, apps and online resources.{" "}
            </li>
          </div>
        </div>
        <div className="terms-and-condn-main-div">
          <div className="terms-and-condn-main-div-one">
            <h3>How we use information  </h3>
          </div>
          <div className="terms-and-condn-main-div-two">
            <p>
              We may use your personal information related to your health for
              the following purposes:{" "}
            </p>
            <li>
              To process your personal information on the Platform in order to
              generate personalized information, reports and feedbacks based on
              your personal information in accordance with the Platform's
              designated functionality.{" "}
            </li>
            <li>
              To share your personal information with the clinic where you are
              receiving, or is willing to receive, medical treatment of the
              designated problem, together with the personalized information,
              reports and feedbacks generated by the Platform based on your
              personal information, which can be taken into consideration by
              your doctor, at her/his complete discretion, upon giving you the
              medical advice.{" "}
            </li>
            <li>
              To provide the Platform's functionality to you, such as arranging
              access to your registered account, and rendering to you customer
              service, provided that we may restrict your access to the
              personalized information, reports and feedbacks generated by the
              Platform. Such restricted information will be accessible to the
              authorized personnel of the clinic where you are receiving, or is
              willing to receive, medical treatment of the designated problem,
              and your doctor.{" "}
            </li>
            <li>
              To respond to your inquiries and fulfil your requests, when you
              contact us via one of our online contact forms, email, social
              media or otherwise, for example, when you send us questions,
              suggestions, compliments or complaints, or when you request other
              information about our services.{" "}
            </li>
            <li>
              To verify your information, and provide you with related customer
              service.{" "}
            </li>
            <li>
              To comply with any legal or regulatory requirements, including for
              defending of legal suits, to the extent reasonably required.
            </li>

            <p>
              We may use your{" "}
              <b>personal information non-related to your health</b> for all
              abovementioned purposes, as well as for the following purposes:{" "}
            </p>
            <li>
              To send administrative information to you, such as changes to our
              terms, conditions, and policies.{" "}
            </li>
            <li>
              To provide you information, by email or otherwise, about our
              Company, the Platform, products and services, and to send you
              attaching marketing materials. Such information may either be
              generic or tailored to your needs and interests. If you no longer
              want to receive marketing related emails from us on a
              going-forward basis, you may opt out by clicking on the
              "unsubscribe" link contained in all our marketing materials.{" "}
            </li>

            <p>
              Except where we are required to do otherwise under applicable law,
              we may use your non-personal information for any purpose without
              restrictions, as it does not identify you or any other individual
              and cannot be traced back to you. Without limitation of the
              generality of the foregoing, we may use your non-personal
              information for all abovementioned purposes, as well as for the
              following purposes:{" "}
            </p>

            <li>
              To analyze your non-personal information in order to improve our
              AI models.{" "}
            </li>
            <li>
              To carry out fraud security monitoring and prevent fraudulent
              activities.
            </li>
            <li>To develop new products and services. </li>
            <li>
              To enhance, improve, repair, maintain and modify our current
              products, services and safety assurance measures.{" "}
            </li>
            <li>
              To identify usage trends, for example, understanding which parts
              of our services are of most interest to users.{" "}
            </li>
            <li>
              To determine the effectiveness of our promotional campaigns, so
              that we can adapt our campaigns to the needs and interests of our
              users.{" "}
            </li>
          </div>
        </div>
        <div className="terms-and-condn-main-div">
          <div className="terms-and-condn-main-div-one">
            <h3>Who we share information with </h3>
          </div>
          <div className="terms-and-condn-main-div-two">
            <p>
              We may share all your data with our affiliates to use it on the
              same terms, as we use it in accordance with this privacy policy.
              We shall be responsible for any breach by our affiliates of the
              terms of this privacy policy to the same extent, as we are
              responsible for our own actions and omissions. For purposes of
              this privacy policy, "affiliate" means a company that directly, or
              indirectly through one or more intermediaries, owns or controls,
              is owned or is controlled by, or is under common ownership or
              control with, the Company.{" "}
            </p>
            <p>
              In the regular course of business, we may share your personal
              information with the following third parties in the following
              manners and instances:{" "}
            </p>
            <li>
              We may share all your data, without any restriction, with the
              clinic where you are receiving, or is willing to receive, medical
              treatment of the designated problem.{" "}
            </li>
            <li>
              We may share your data with third-party service providers, whose
              services and solutions complement, facilitate and enhance our own,
              including without limitation hosting and server services,
              communications and content delivery networks, data and
              cybersecurity services, performance measurement services, data
              optimization and marketing services, content providers, and our
              legal and financial advisors.  Such service providers may have
              access to your personal information according to their particular
              roles and purposes.{" "}
            </li>

            <p>
              In addition to the foregoing, in specific cases described below,
              we may share your personal information with third parties as
              necessary or appropriate, in particular when we have a legal
              obligation to do so:{" "}
            </p>
            <li>
              We may share personal information to comply with applicable law
              and regulations, which may include laws outside your country of
              residence.
            </li>
            <li>
              We may share personal information with third parties in connection
              with a transaction, such as a merger, sale of Company's assets or
              shares, reorganization, financing, change of control or
              acquisition of all or a portion of our business, or in the event
              of a bankruptcy or related or similar proceedings.{" "}
            </li>
            <li>
              Where permitted or required by applicable data protection laws, we
              may disclose your personal information pursuant to a legal
              request, or in compliance with applicable laws, if we have good
              faith belief that the law requires us to do so, with or without
              notice to you.{" "}
            </li>
            <li>
              Where permitted or required by law, we may share your personal
              information with others if we believe in good faith that it will
              help protect the rights, property or personal safety of the
              Company, any of our users, or any member of the general public,
              with or without notice to you.{" "}
            </li>

            <p>
              Although we will take reasonable precautions to safeguard your
              personal information using at least the same level of efforts and
              measures as required under applicable law, including the
              establishing of reasonable electronic security means on the
              Platform and disclosing personal information only the minimum
              information required by law, if applicable, but we shall not be
              responsible for any failure by third parties to take the relevant
              security precautions or for unauthorized use of your personal
              information by any third party, unless applicable law
              unconditionally dictates otherwise.{" "}
            </p>
            <p>
              Except where we are required to do otherwise under applicable law,
              we may share your non-personal information for any purpose without
              restrictions or liability on our behalf.{" "}
            </p>
          </div>
        </div>
        <div className="terms-and-condn-main-div">
          <div className="terms-and-condn-main-div-one">
            <h3>Your access to your personal information </h3>
          </div>
          <div className="terms-and-condn-main-div-two">
            <p>
              At any time upon your request, you are entitled to review your
              personal information collected and stored by the Platform. Such
              request should be provided to us by sending the online form or an
              email to our address set forth in the "Contact Us" section of the
              Platform. Upon receipt of your request, we will provide you
              without unreasonable delay with a hard or electronic copy of your
              personal information collected and stored by the Platform,
              provided that we may restrict your access to:{" "}
            </p>
            <li>
              the personalized information, reports and feedbacks generated by
              the Platform;
            </li>
            <li>
              personal information relating to your medical or mental condition
              if in our opinion such information may cause serious damage to
              your physical or mental health or endanger your life;{" "}
            </li>
            <li>
              any other information which we may be required to restrict in
              accordance with applicable law
            </li>
            <p>
              Such restricted information will be accessible to the authorized
              personnel of the clinic where you are receiving, or is willing to
              receive, medical treatment of the designated problem, and your
              doctor.{" "}
            </p>
          </div>
        </div>
        <div className="terms-and-condn-main-div">
          <div className="terms-and-condn-main-div-one">
            <h3>Your right to amend your personal information </h3>
          </div>
          <div className="terms-and-condn-main-div-two">
            <p>
              You can always request your doctor or the clinic where you are
              receiving, or is willing to receive, medical treatment of the
              designated problem, to make any changes, updates or additions to
              your personal information earlier provided to us, with or without
              the use of the Platform. Any such change, update or addition may
              (but shall not necessarily) be disclosed to us by the authorized
              personal of the clinic, at their absolute discretion.{" "}
            </p>
            <p>
              If the Platform's functionality allows it, you are entitled to
              change, update or add your personal information stored on the
              Platform by yourself at any time. If the Platform's functionality
              does not allow you to amend any of your personal information, you
              are entitled to request us to amend such personal information by
              sending the online form or an email to our address set forth in
              the "Contact Us" section of the Platform.{" "}
            </p>
            <p>
              In no event shall any such request be unreasonably rejected or
              ignored. Unless we have material reasons to reject your request,
              we will amend your personal information as per your request, and
              then we will notify the clinic where you are receiving, or is
              willing to receive, medical treatment of the designated problem,
              about the relevant change, update or addition, without
              unreasonable delay. If we have material reasons to reject your
              request, we will notify you of our rejection and the reasons for
              it in reasonable detail. For the avoidance of doubt, our rejection
              cannot prevent you from applying to your doctor or the clinic
              where you are receiving, or is willing to receive, medical
              treatment of the designated problem, with the same request, and
              you are always entitled to do so at your absolute discretion.{" "}
            </p>
          </div>
        </div>
        <div className="terms-and-condn-main-div">
          <div className="terms-and-condn-main-div-one">
            <h3>Your right to delete your personal information </h3>
          </div>
          <div className="terms-and-condn-main-div-two">
            <p>
              We will keep your personal information for as long as your user
              account is active, in order to allow you to have access to your
              information and to provide you with our services.{" "}
            </p>
            <p>
              We may continue to retain your personal information even after you
              deactivate your user account or stop using the Platform, as
              reasonably necessary to comply with our legal obligations, to
              resolve disputes regarding our users, enforce our agreements or
              protect our legitimate interests, consistent with applicable law.
              When your personal information is no longer required, we will
              ensure it is deleted.You are entitled to request us to delete your
              personal information by sending the online form or an email to our
              address set forth in the "Contact Us" section of the Platform.
              While doing so, please note that in absence of your personal data,
              the Platform will not be able to provide the services personalized
              for your needs. We will verify and respond to your request
              consistent with applicable law, taking into account the type and
              sensitivity of the personal information subject to the request.{" "}
            </p>
            <p>
              For the avoidance of doubt and notwithstanding anything to the
              contrary, we will be entitled to keep all non-personal information
              as long as it does not identify you or any other individual and
              cannot be traced back to you.{" "}
            </p>
          </div>
        </div>
        <div className="terms-and-condn-main-div">
          <div className="terms-and-condn-main-div-one">
            <h3>Third party services </h3>
          </div>
          <div className="terms-and-condn-main-div-two">
            <p>
              This privacy policy does not address, and we are not responsible
              for, the privacy, information, or other practices of any third
              parties, including any third party operating any platform, website
              or service, links to which are displayed on the Platform, or the
              services of which are included on the Platform, including social
              media, video calls, etc. Once you have used these links or
              services, you should note that we do not have any control over
              that other platforms, websites or services, and they are not
              governed by this privacy policy. The inclusion of a link on the
              Platform does not imply endorsement of the linked platform,
              website or service by us. You should exercise caution and look at
              the privacy statements applicable to the relevant third party
              platforms, websites and services.{" "}
            </p>
            <p>
              In addition, we are not responsible for the information
              collection, use, disclosure, or security policies or practices of
              other organizations, such as Facebook, Apple, Google, Microsoft,
              RIM, or any other app developer, app provider, social media
              platform provider, operating system provider, wireless service
              provider, or device manufacturer, including with respect to any
              personal information you disclose to other organizations through
              or in connection with the Platform.{" "}
            </p>
            <p>
              Without derogation from the generality of the foregoing, we do not
              have control over the clinic where you are receiving, or is
              willing to receive, medical treatment of the designated problem,
              and it may collect, store and use your private information in
              accordance with its own privacy policy, based on your consent
              provided directly to the clinic. Our privacy policy does not
              address, and we are not responsible for, the privacy, information,
              or other practices of the clinic. You should exercise caution and
              look at the privacy statements of your clinic before you disclose
              any of your personal information to it or its personnel
            </p>
          </div>
        </div>
        <div className="terms-and-condn-main-div">
          <div className="terms-and-condn-main-div-one">
            <h3>Security </h3>
          </div>
          <div className="terms-and-condn-main-div-two">
            <p>
              We are committed to take reasonable precautions to ensure that
              your information is secure. In order to prevent unauthorized
              access or disclosure, we have put in place and will constantly
              maintain and update suitable physical, electronic and managerial
              procedures to safeguard and secure the personal information
              collected and stored by the Platform.{" "}
            </p>
            <p>
              We seek to use reasonable organizational, technical and
              administrative measures to protect personal information within our
              organization. Unfortunately, no data transmission or storage
              system can be guaranteed to be 100% secure against unauthorized
              access or disclosure. If you have reason to believe that your
              interaction with us is no longer secure, please immediately notify
              us by sending the online form or an email to our address set forth
              in the "Contact Us" section of the Platform.{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
