import React, {useEffect, useState} from 'react';
import GraphComponent from './GraphComponentByClinic';
import axios from 'axios';
import Loader from '../Loader/Loader';

const MainDashboard = () => {
  const ClinicIDForHomepage = localStorage.getItem('ClinicIDForHomepage');
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);


    // const data =  [
    //         {
    //           "patient_name": "demo_dietitian",
    //           "answered_percentage": 0
    //         },
    //         {
    //           "patient_name": "conda",
    //           "answered_percentage": 0
    //         },
    //         {
    //           "patient_name": "demo_patient",
    //           "answered_percentage": 13.740458015267176
    //         }
    //       ]
        
  

    

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
          const result = await axios.get(`https://djangoobesitychat.azurewebsites.net/patientresponse_categorize_by_clinic/${ClinicIDForHomepage}/`,
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("SavedToken")}`,
            },
          },
          );
          setData(result.data);
        };
        setLoading(false);
        fetchData();
      }, [ClinicIDForHomepage]);

    const patientsdata = data ? Object?.keys(data).reduce((acc, key) => {
        const patients = data[key].patients.map(patient => ({
            patient_name: patient.patient_name,
            answered_percentage: patient.answered_percentage
        }));
        return [...acc, ...patients];
    }, []) : [];



    if (!data) return null;


  return (
    loading ? (
       <Loader />
    ) : (
    <div>
        <h1>Dashboard</h1>
      <h5>Patient Answered Percentage</h5>
      <GraphComponent data={patientsdata} />    
    </div>
    )
  );
};

export default MainDashboard;