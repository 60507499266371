import axios from "axios";
import React, { useEffect, useState } from "react";
import "./AddFood.css";
import Add from "@mui/icons-material/Add";
import swal from "sweetalert";
import Loader from "../Loader/Loader";
import Apiservice from "../../Services/Apiservice";

const AddFood = () => {
  const [FoodName, setFoodName] = useState("");
  const [PortionSize, setPortionSize] = useState("");
  const [CaloriesPer100g, setCaloriesPer100g] = useState("");
  const [EnergyContent, setEnergyContent] = useState("");
  const [FoodCategory, setFoodCategory] = useState("");

  const [getFoodCategory, setgetFoodCategory] = useState([]);
  useEffect(() => {
    const loadPosts = async () => {
      const response = await Apiservice.get(
        `/foodcategoryapi/post/`
      );
      setgetFoodCategory(response.data);
    };
    loadPosts();
  }, []);

  const [submitLoading, setsubmitLoading] = useState(false);
  const [response, setresponse] = useState();

  //console.log("response", response);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setsubmitLoading(true);

    const formData = new FormData();
    formData.append("FoodName", FoodName);
    formData.append("PortionSize", PortionSize);
    formData.append("CaloriesPer100g", CaloriesPer100g);
    formData.append("EnergyContent", EnergyContent);
    formData.append("FoodCategory", FoodCategory);

    axios({
      method: "post",
      url: `https://djangoobesitychat.azurewebsites.net/food/post/`,
      data: formData,
    })
      .then((res) => {
        setsubmitLoading(false);

        swal({
          title: "Diet Plan Added Successfully...",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });
      })

      .catch((res) => {
        setsubmitLoading(false);
        setresponse(res.response.data);
      });
  };

  return (
    <div className="diet-prescr-flex-div">
      {submitLoading && (
        <div className="purchase-order-details-pop-up-main-div">
          <div className="purchase-order-details-pop-up-sub-div">
            <div
              className="purchase-order-details-pop-up-sub-div-container"
              style={{ background: "none" }}
            >
              <Loader />
            </div>
          </div>
        </div>
      )}
      <div className="diet-prescr-width-div">
        <h3 className="add-new-pat-h-tag">Add New Food</h3>
        <div className="diet-prescr-main-div">
          <div className="new-pat-part-one-flex">
            <div className="new-pat-part-one-sub-one">
              <p>Food Name</p>
            </div>
            <div className="new-pat-part-one-sub-two">
              <input
                className="add-patient-input-field"
                placeholder="Food Name"
                value={FoodName}
                onChange={(e) => setFoodName(e.target.value)}
              />
            </div>
          </div>
          <div className="input-fiend-response-responsive">
          <p>{response && response.FoodName}</p>
        </div>

          <div className="new-pat-part-one-flex">
            <div className="new-pat-part-one-sub-one">
              <p>Portion Size</p>
            </div>
            <div className="new-pat-part-one-sub-two">
              <input
                type="number"
                className="add-patient-input-field"
                placeholder="PortionSize"
                value={PortionSize}
                onChange={(e) => setPortionSize(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="input-fiend-response">
          <p>{response && response.FoodName}</p>
        </div>

        <div className="diet-prescr-main-div">
          <div className="new-pat-part-one-flex">
            <div className="new-pat-part-one-sub-one">
              <p>Calories Per 100g</p>
            </div>
            <div className="new-pat-part-one-sub-two">
              <input
                type="number"
                className="add-patient-input-field"
                placeholder="Calories Per 100g"
                value={CaloriesPer100g}
                onChange={(e) => setCaloriesPer100g(e.target.value)}
              />
            </div>
          </div>
          
          <div className="new-pat-part-one-flex">
            <div className="new-pat-part-one-sub-one">
              <p>Energy Content</p>
            </div>
            <div className="new-pat-part-one-sub-two">
              <input
                className="add-patient-input-field"
                placeholder="Energy Content"
                value={EnergyContent}
                onChange={(e) => setEnergyContent(e.target.value)}
              />
            </div>
          </div>
        </div>
        
        <div className="input-fiend-response-two">
          <p>{response && response.EnergyContent}</p>
        </div>
        <div className="diet-prescr-main-div">
          <div className="new-pat-part-one-flex">
            <div className="new-pat-part-one-sub-one">
              <p>Food Category</p>
            </div>
            <div className="new-pat-part-one-sub-two">
              <select
                className="add-patient-input-field"
                value={FoodCategory}
                onChange={(e) => setFoodCategory(e.target.value)}
              >
                <option>Select Food Category</option>
                {getFoodCategory &&
                  getFoodCategory?.map((item) => (
                    <option key={item.id} value={item.foodcategory_name}>
                      {item.foodcategory_name}
                    </option>
                  ))}
              </select>
            </div>           
          </div>          
        </div>
        <div className="input-fiend-response-two">
          <p>{response && response.EnergyContent}</p>
        </div>

        <div className="add-pat-btn-div">
          <button className="add-btn-btn-tag" onClick={handleSubmit}>
            <Add style={{ height: "20px", width: "20px" }} />
            Add Food
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddFood;
