import * as React from "react";
import { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Routes, Route } from "react-router-dom";
import { Link } from "react-router-dom";
import DashboardSharpIcon from "@mui/icons-material/DashboardSharp";
import Dashboard from "../Dashboard/Dashboard";
import BallotIcon from "@mui/icons-material/Ballot";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import PatientList from "../../containers/patientlist/PatientList";
import PersonalPatientFile from "../PersonalPatientFile/PersonalPatientFile";
import Navbar from "../Navbar/Navbar";
import { GiDirectionSign } from "react-icons/gi";
import { BsBell } from "react-icons/bs";
import { FaAngleDown } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import AddPatient from "../AddPatient/AddPatient";
import AccountSetting from "../AccountSetting/AccountSetting";
// import Chat from "../Chat/Chat";
import DoctorPrescription from "../DoctorPrescription/DoctorPrescription";
// import ReactWhatsapp from "react-whatsapp";
import { BsWhatsapp } from "react-icons/bs";
import DoctorBlog from "../DoctorBlog/DoctorBlog";
import BlogMainPage from "../BlogMainPage/BlogMainPage";
import DoctorCreateBlog from "../DoctorCreateBlog/DoctorCreateBlog";
import QuizSelect from "../Quiz/QuizSelect";
import RandomQuiz from "../Quiz/RandomQuiz";
import PatientViewForDoctor from "../PatientViewForDoctor/PatientViewForDoctor";
import QuestionnaireNew from "../Questionnaire/QuestionnaireNew";
import VideoNew from "../VideoComponent/Prebuilt";
import PatientSummary from "../PatientSummary/PatientSummary";
import DoctorExtraDetails from "../DoctorExtraDetails/DoctorExtraDetails";
import BlogHomePage from "../BlogMainPage/BlogHomePage";
import DoctorAppointmentList from "../AppointmentList/DoctorAppointmentList";
import DoctorPatientList from "../DoctorPatientList/DoctorPatientList";
import Notification from "../Notification/Notification";
import NotificationComponent from "../Notification/NotificationNew";
import Icon from "../../Images/Icon";
import DoctorPatientPrescription from "../DoctorPatientPrescription/DoctorPatientPrescription";
import axios from "axios";
// import ChatComponent from "../Chat/ChatNew";
import ChatSDK from "../Chat/ChatTest";
import CallNotes from "../CallNotes/CallNotes";
import CallNotesPost from "../CallNotes/CallNotesPost";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import PrescriptionNew from "../PrescriptionNew/PrescriptionNew";
// import Chatbot from "../Chat/Chatbot";
import TermsandConditions from "../TermsandConditions/TermsandConditions";
import PrivacyPolicy from "../TermsandConditions/PrivacyPolicy";
import AccessibilityPolicy from "../TermsandConditions/AccessibilityPolicy";
import ICDSearch from "../Disease/Disease";
// import { ChatEngine } from "react-chat-engine";
// import DirectChatPage from "../Chat/ChatEngine";
import NewNavbar from "../NewNavbar/NewNavbar";
import ContraindicationsList from "../ContraindicationsList/ContraindicationsList";
import DrugDrugInteractionList from "../ContraindicationsList/DrugDrugInteractionList";
import WarningList from "../ContraindicationsList/WarningList";
import Chat from "../Chat/ChatTest";
import QuestionnairePutRequest from "../QuestionnairePutRequest/QuestionnairePutRequest";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  overflow: "hidden",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  overflow: "hidden",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(3.5)} + 2px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(5)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  height: 1,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: '#ffffff', // Set the background color to match the image.
  color: '#333333', // Set the text color to match the image.
  borderRadius: "5px",
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
    borderRadius: "5px", // Add border radius
    boxShadow: "0px 3px 6px #00000029", // Add box shadow
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
const username = localStorage.getItem("PatientUsernameforSummary");
export default function Sidebar() {
  const currentPath = window.location.pathname;
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [openPlan, setOpenPlan] = React.useState(false);
  const navigatetwo = useNavigate();

  const [opentwo, setOPentwo] = useState(false);
  const toggletwo = () => {
    setOPentwo(!opentwo);
  };

  const [openthree, setOPenthree] = useState(false);
  const togglethree = () => {
    setOPenthree(!openthree);
  };

  const handleClick = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleClickPlan = () => {
    setOpenPlan(!openPlan);
  };
  const handleDrawerOpen = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(!drawerOpen);
  };

  const loginpage = () => {
    navigate("/");
  };

  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    navigate("/");
  };

  const URL = window.location.href;

  const navigateFunction = () => {
    if (URL !== "http://localhost:3000/dashboard" && URL !== "http://localhost:3000/dashboard") {
    navigatetwo(-1);
  }
}
console.log("URL", URL);

  const UserInfo = localStorage.getItem("Username");

  const [onlineUsers, setOnlineUsers] = useState([]);

  useEffect(() => {
    const fetchOnlineUsers = async () => {
      const response = await fetch(
        "https://djangoobesitychat.azurewebsites.net/chat/api/online_users/",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        }
      );
      // if (!response.ok) {
      //   throw new Error(`Network response was not ok: ${response.status}`);
      // }
      const data = await response.json();
      setOnlineUsers(data);
    };
    fetchOnlineUsers();
  }, []);
  // Clinic ID Store at Homepage

  useEffect(() => {
    axios
      .get(`https://djangoobesitychat.azurewebsites.net/user/doctor/name/${UserInfo}/`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("SavedToken")}`,
        },
      }
      
      )

      .then((response) => {
        const doctor_id = response.data[0].doctor_id;
        localStorage.setItem("LoginDoctorID", doctor_id);
      })
      .catch((err) => {});
  }, []);

  const webcamCall = localStorage.getItem("webcamCall") === "true";

  // CHECK THE USERTPYE IS DOCTOR OR NOT
  const [authenticated, setauthenticated] = useState(null);
  useEffect(() => {
    const loggedInUser = localStorage.getItem("is_doctor") === "true";
    const webcamCall = localStorage.getItem("webcamCall") === "true";

    if (loggedInUser || webcamCall) {
      setauthenticated(loggedInUser || webcamCall);
    }
  }, []);
  if (!authenticated) {
  } else
    return (
      <div>
        <Box sx={{ display: "flex", overflow: "hidden" }}>
          <CssBaseline />

          {/* NAVBAR COMPONENT */}
          <NewNavbar />
         
          <div></div>

          {/* DOCTOR SIDEBAR CODE */}
          {!webcamCall && 
          <Drawer
            sx={{ "& .MuiDrawer-paper": { 
              backgroundColor: "#fff",
              borderRadius: drawerOpen ? "10px" : "0", // Apply border radius conditionally
              boxShadow: drawerOpen ? "0px 3px 6px #00000029" : "none", // Apply box shadow conditionally
            }  }}
            variant="permanent"
            open={drawerOpen}
          >
            <DrawerHeader>
              <IconButton
                style={{ color: "#84829a", fontSize: 19, borderRadius: "1%" }}
                onClick={handleDrawerClose}
              >
                {theme.direction === "ltr" ? (
                  <GiHamburgerMenu />
                ) : (
                  <GiHamburgerMenu />
                )}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
              <ListItem
                key="Go Back"
                disablePadding
                sx={{ display: "block" }}
                onClick={navigateFunction}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 3,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 1.4,
                      justifyContent: "center",
                    }}
                  >
                    <ArrowBackIcon style={{ color: "#84829a" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Go Back"
                    style={{ color: "#84829a" }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem
                key="Dashboard"
                disablePadding
                sx={{ display: "block" }}
              >
                <Link to="/dashboard">
                  {currentPath === "/dashboard" ? (
                    <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 3,
                      backgroundColor: "#0374e5",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 1.4,
                        justifyContent: "center",
                      }}
                    >
                      <DashboardSharpIcon style={{ color: "#fff" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Dashboard"
                      style={{ color: "#fff" }}
                    />
                  </ListItemButton>
                  ) : (
                    <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 3,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 1.4,
                        justifyContent: "center",
                        color: "#fff",
                      }}
                    >
                      <DashboardSharpIcon style={{ color: "#84829a" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Dashboard"
                      style={{ color: "#84829a" }}
                    />
                  </ListItemButton>
                  ) 
                  }
                </Link>
              </ListItem>

              <ListItem
                key="patientList"
                disablePadding
                sx={{ display: "block" }}
              >
                <Link to="/dashboard/patientlist">
                  {currentPath === "/dashboard/patientlist" ? (
                    <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 3,
                      backgroundColor: "#0374e5",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 1.4,
                        justifyContent: "center",
                      }}
                    >
                      <BallotIcon style={{ fill: "#fff" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Patient List"
                      style={{ color: "#fff" }}
                    />
                  </ListItemButton>
                  ) : (
                    <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 3,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 1.4,
                        justifyContent: "center",
                      }}
                    >
                      <BallotIcon style={{ fill: "#84829a" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Patient List"
                      style={{ color: "#84829a" }}
                    />
                  </ListItemButton>
                  )}
                  
                </Link>
              </ListItem>

              <ListItem
                key="patientList"
                disablePadding
                sx={{ display: "block" }}
              >
                <Link to="/dashboard/chat/obesity/">
                  {currentPath === "/dashboard/chat/obesity/" ? (
                    <ListItemButton
                    onClick="window.location.href=window.location.href"
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 3,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 1.4,
                        justifyContent: "center",
                      }}
                    >
                      <MessageOutlinedIcon style={{ fill: "#84829a" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Messenger"
                      style={{ color: "#84829a" }}
                    />
                  </ListItemButton>
                  ) : (
                    <ListItemButton
                    onClick="window.location.href=window.location.href"
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 3,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 1.4,
                        justifyContent: "center",
                      }}
                    >
                      <MessageOutlinedIcon style={{ fill: "#84829a" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Messenger"
                      style={{ color: "#84829a" }}
                    />
                  </ListItemButton>
                  )}
                </Link>
              </ListItem>

              {/* <ListItem
                key="patientList"
                disablePadding
                sx={{ display: "block" }}
              >
                <Link to="/dashboard/webcam/:username">
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 3,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 1.4,
                        justifyContent: "center",
                      }}
                    >
                      <CameraAltIcon style={{ fill: "#84829a" }} />
                    </ListItemIcon>
                    <ListItemText primary="Blog" style={{ color: "#84829a" }} />
                  </ListItemButton>
                </Link>
              </ListItem> */}
              <ListItem
                key="patientList"
                disablePadding
                sx={{ display: "block" }}
              >
                <Link to="/dashboard/blogmainpage">
                  {currentPath === "/dashboard/blogmainpage" ? (
                    <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 3,
                      backgroundColor: "#0374e5",
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 1.4,
                        justifyContent: "center",
                      }}
                    >
                      <TextSnippetIcon style={{ fill: "#fff" }} />
                    </ListItemIcon>
                    <ListItemText primary="Blog" style={{ color: "#fff" }} />
                  </ListItemButton>
                  ) : (
                    <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 3,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 1.4,
                        justifyContent: "center",
                      }}
                    >
                      <TextSnippetIcon style={{ fill: "#84829a" }} />
                    </ListItemIcon>
                    <ListItemText primary="Blog" style={{ color: "#84829a" }} />
                  </ListItemButton>
                  )}
                </Link>
              </ListItem>
              {/* <ListItem
                key="patientList"
                disablePadding
                sx={{ display: "block" }}
              >
                <Link to="/dashboard/chatbot">
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 3,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 1.4,
                        justifyContent: "center",
                      }}
                    >
                      <TextSnippetIcon style={{ fill: "#ffffff" }} />
                    </ListItemIcon>
                    <ListItemText primary="Blog" style={{ color: "#ffffff" }} />
                  </ListItemButton>
                </Link>
              </ListItem> */}
              {/* <ListItem
                key="patientList"
                disablePadding
                sx={{ display: "block" }}
              >
                <Link to="/dashboard/diseaseadd">
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 3,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 1.4,
                        justifyContent: "center",
                      }}
                    >
                      <TextSnippetIcon style={{ fill: "#ffffff" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Add Disease"
                      style={{ color: "#ffffff" }}
                    />
                  </ListItemButton>
                </Link>
              </ListItem> */}

              {!open ? (
                <div
                  style={{ position: "relative", valign: "bottom", top: "10%" }}
                >
                  <ListItem
                    key="Privacy Policy"
                    disablePadding
                    sx={{ display: "block", valign: "bottom" }}
                  >
                    <Link to="/dashboard/privacypolicy">
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 3,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 20,
                            mr: open ? 3 : 1.4,
                            justifyContent: "center",
                          }}
                        ></ListItemIcon>
                        <ListItemText
                          primary="Privacy Policy"
                          style={{ color: "#84829a" }}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>

                  <ListItem
                    key="Terms & Conditions"
                    disablePadding
                    sx={{ display: "block", bottom: "0" }}
                  >
                    <Link to="/dashboard/termsandconditions">
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 3,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 20,
                            mr: open ? 3 : 1.4,
                            justifyContent: "center",
                          }}
                        ></ListItemIcon>
                        <ListItemText
                          primary="Terms & Conditions"
                          style={{ color: "#84829a" }}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>

                  <ListItem
                    key="Accessibility Policy"
                    disablePadding
                    sx={{ display: "block", bottom: "0" }}
                  >
                    <Link to="/dashboard/accessibilitypolicy">
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 3,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 20,
                            mr: open ? 3 : 1.4,
                            justifyContent: "center",
                          }}
                        ></ListItemIcon>
                        <ListItemText
                          primary="Accessibility Policy"
                          style={{ color: "#84829a" }}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>
                </div>
              ) : (
                <p></p>
              )}
            </List>
          </Drawer>
}



          {/* DOCTOR ROUTES PATH */}

          <Box component="main" open={open}>
            <DrawerHeader />
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/patientlist" element={<PatientList />} />
              <Route
                path="/personalPatientView"
                element={<PersonalPatientFile />}
              />
              <Route
                path="/chat/obesity/"
                element={<Chat user={UserInfo} onlineUsers={onlineUsers} />}
              />
              <Route path="addpatient" element={<AddPatient />} />
              <Route path="patientsummary" element={<PatientSummary />} />
              <Route path="questionnaire" element={<QuestionnairePutRequest />} />
              <Route
                path="patientviewfordoctor"
                element={<PatientViewForDoctor />}
              />
              <Route path="blogmainpage" element={<BlogMainPage />} />
              <Route path="blogbydoctor" element={<DoctorBlog />} />
              <Route
                path="doctorappointmentlist"
                element={<DoctorAppointmentList />}
              />
              <Route path="bloghomepage" element={<BlogHomePage />} />
              <Route path="doctorpatientlist" element={<DoctorPatientList />} />
              <Route path="doctorcreateblog" element={<DoctorCreateBlog />} />
              <Route path="accsetting" element={<AccountSetting />} />
              <Route path="webcam/:username" element={<VideoNew />} />
              <Route path="callnotes" element={<CallNotes />} />
              <Route path="callnotespost" element={<CallNotesPost />} />
              <Route
                path="doctorextradetails"
                element={<DoctorExtraDetails />}
              />
              <Route
                path="prescriptionbydoctor"
                element={<DoctorPrescription />}
              />
              <Route path="patientprescription" element={<PrescriptionNew />} />
              {/* <Route path="chatbot" element={<Chatbot />} /> */}
              {/* <Route path="diseaseadd" element={<ICDSearch />} /> */}
              <Route
                path="contraindicationslist"
                element={<ContraindicationsList />}
              />
              <Route
                path="druginteractionlist"
                element={<DrugDrugInteractionList />}
              />
              <Route path="warninglist" element={<WarningList />} />
              <Route path="quiz" element={<QuizSelect />} />
              <Route path="/r/Anxiety" element={<RandomQuiz />} />

              <Route
                path="/termsandconditions"
                element={<TermsandConditions />}
              />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route
                path="/accessibilitypolicy"
                element={<AccessibilityPolicy />}
              />
            </Routes>
          </Box>
        </Box>
      </div>
    );
}
