import React, { useEffect, useState } from "react";
import PatientMedicalHistory from "../PatientMedicalHistory/PatientMedicalHistory";
import "./PatientDashboard.css";
import PatientEvents from "../PatientEvents/PatientEvents";
import PatientDocuments from "../PatientDocuments/PatientDocuments";
import PatientHistory from "../PatientHistory/PatientHistory";
import axios from "axios";
import PatientQuestionnaireNew from "../PatientQuestionnaire/PatientQuestionnaireNew";
import oneThirtykg from "../../Images/WeightImages/OneHundredThirtyKg.svg";
import oneTwentykg from "../../Images/WeightImages/OneHundredTwentyKg.svg";
import onehundreadkg from "../../Images/WeightImages/HundredKg.svg";
import nintykg from "../../Images/WeightImages/NinetyKg.svg";
import MnintyClothes from "../../Images/WeightImages/ninetyMclothes.svg";
import MhundreadClothes from "../../Images/WeightImages/hundreadMclothes.svg";
import MhundreadtenClothes from "../../Images/WeightImages/hundreadtenMclothes.svg";
import MhundreadtenplusClothes from "../../Images/WeightImages/hundreadtenplusMclothes.svg";
import FnintyClothes from "../../Images/WeightImages/ninetyFClothes.svg";
import FhundreadClothes from "../../Images/WeightImages/hundreadFClothes.svg";
import FhundreadtenClothes from "../../Images/WeightImages/hundreadtenFClothes.svg";
import FhundreadtenplusClothes from "../../Images/WeightImages/hundreadtenplusFClothes.svg";
import FnintyWithoutClothes from "../../Images/WeightImages/ninetyFWithout.svg";
import FhundreadWithoutClothes from "../../Images/WeightImages/hundreadFWithout.svg";
import FhundreadtenWithoutClothes from "../../Images/WeightImages/hundreadtenFWithout.svg";
import FhundreadtenplusWithoutClothes from "../../Images/WeightImages/hundreadtenplusFWithout.svg";
import swal from "sweetalert";
import QuestionnairePutRequest from "../QuestionnairePutRequest/QuestionnairePutRequest";
import WeightForcasting from "../WeightForcasting/WeightForcasting";
import Loader from "../Loader/Loader";
import Apiservice from "../../Services/Apiservice";

const PatientDashboard = () => {

  const [HealthOverview, setHealthOverview] = useState(true);
  const [HealthDetails, setHealthDetails] = useState(false);
  const [PatientEventsPage, setPatientEventsPage] = useState(false);
  const [PatientDocumentsPage, setPatientDocumentsPage] = useState(false);
  const [PatientHistoryPage, setPatientHistoryPage] = useState(false);
  const [isMedActive, setIsMedActive] = useState(true);
  const [isQuesActive, setIsQuesActive] = useState(false);
  const [isEventsActive, setIsEventsActive] = useState(false);
  const [isDocumentActive, setIsDocumentActive] = useState(false);
  const [isHistoryActive, setIsHistoryActive] = useState(false);

  const [male, setMale] = useState(true);
  const [female, setFemale] = useState(false);
  const [malewithclothes, setMalewithclothes] = useState(true);
  const [malewithoutclothes, setMalewithoutclothes] = useState(false);
  const [femalewithclothes, setFemalewithclothes] = useState(true);
  const [femalewithoutclothes, setFemalewithoutclothes] = useState(false);
  const [isMaleActive, setIsMaleActive] = useState(true);
  const [isFemaleActive, setIsFemaleActive] = useState(false);
  const [isClothesActive, setIsClothesActive] = useState(true);
  const [isWithoutClothesActive, setIsWithoutClothesActive] = useState(false);

  const [wc, setWc] = useState("");

  const Male = () => {
    setMale(true);
    setFemale(false);
    setIsMaleActive(true);
    setIsFemaleActive(false);
  };
  const Female = () => {
    setFemale(true);
    setMale(false);
    setIsMaleActive(false);
    setIsFemaleActive(true);
  };

  const MalewithclothesHandler = () => {
    setMalewithclothes(true);
    setMalewithoutclothes(false);
    setIsClothesActive(true);
    setIsWithoutClothesActive(false);
  };
  const MalewithoutclothesHandler = () => {
    setMalewithclothes(false);
    setMalewithoutclothes(true);
    setIsClothesActive(false);
    setIsWithoutClothesActive(true);
  };
  const FemalewithclothesHandler = () => {
    setFemalewithclothes(true);
    setFemalewithoutclothes(false);
    setIsClothesActive(true);
    setIsWithoutClothesActive(false);
  };
  const FemalewithoutclothesHandler = () => {
    setFemalewithclothes(false);
    setFemalewithoutclothes(true);
    setIsClothesActive(false);
    setIsWithoutClothesActive(true);
  };

  const HealthOverviewHandler = () => {
    setIsMedActive(true);
    setIsQuesActive(false);
    setIsEventsActive(false);
    setIsDocumentActive(false);
    setIsHistoryActive(false);
    setHealthOverview(true);
    setHealthDetails(false);
    setPatientEventsPage(false);
    setPatientDocumentsPage(false);
    setPatientHistoryPage(false);
  };

  const HealthDetailsHandler = () => {
    setIsMedActive(false);
    setIsQuesActive(true);
    setIsEventsActive(false);
    setIsDocumentActive(false);
    setIsHistoryActive(false);
    setHealthOverview(false);
    setHealthDetails(true);
    setPatientEventsPage(false);
    setPatientDocumentsPage(false);
    setPatientHistoryPage(false);
  };
  const PatientEventsPageHandler = () => {
    setIsEventsActive(true);
    setIsMedActive(false);
    setIsQuesActive(false);
    setIsDocumentActive(false);
    setIsHistoryActive(false);
    setHealthOverview(false);
    setHealthDetails(false);
    setPatientEventsPage(true);
    setPatientDocumentsPage(false);
    setPatientHistoryPage(false);
  };
  const PatientDocumentsPageHandler = () => {
    setIsEventsActive(false);
    setIsMedActive(false);
    setIsQuesActive(false);
    setIsDocumentActive(true);
    setIsHistoryActive(false);
    setHealthOverview(false);
    setHealthDetails(false);
    setPatientEventsPage(false);
    setPatientDocumentsPage(true);
    setPatientHistoryPage(false);
  };
  const PatientHistoryPageHandler = () => {
    setIsEventsActive(false);
    setIsMedActive(false);
    setIsQuesActive(false);
    setIsDocumentActive(false);
    setIsHistoryActive(true);
    setHealthOverview(false);
    setHealthDetails(false);
    setPatientEventsPage(false);
    setPatientDocumentsPage(false);
    setPatientHistoryPage(true);
  };

  // first login popup
  const [showPopup, setShowPopup] = useState(false);
  



  // Doughnut Popup
  const [popup, setPop] = useState(false);
  const handleClickOpen = () => {
    setPop(!popup);
  };

  const closePopup = () => {
    setPop(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", () => {
      setPop(false);
    });
  });

  // Contradictions Popup
  const [contraPopup, setContraPopup] = useState(false);
  const onClickOpen = () => {
    setContraPopup(!contraPopup);
  };

  const closeContraPopup = () => {
    setContraPopup(false);
  };

  // Current Date
  const currentdate = new Date();
  const weekDay = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const day = weekDay[currentdate.getDay()];
  const month = months[currentdate.getMonth()];
  const date = currentdate.getDate();
  const year = currentdate.getFullYear();
  const [value, onChange] = useState(new Date());

  const [imageweight, setImageweight] = useState(0);
  // User Details Get Method
  const id = localStorage.getItem("id");
  const [loginUserDetails, setLoginUserDetails] = useState("");
  const tokenForAddPatient = localStorage.getItem("SavedToken");
  const Username = localStorage.getItem("Username");
  const [patientuserdetails, setpatientuserdetails] = useState([]);
  const [bust_circumference_cm, setbust_circumference_cm] = useState("");
  const [hip_circumference_cm, sethip_circumference_cm] = useState("");
  const [waist_circumference_cm, setwaist_circumference_cm] = useState("");

  const [childData, setChildData] = useState({
    dataset: "",
    dataindex: "",
    value: 0,
  });
  const [isClicked, setIsClicked] = useState(false);
  
  console.log("Child Data Set", childData)

  const [mainDataLoading, setmainDataLoading] = useState(true);

  useEffect(() => {
    Apiservice
      .get(`/user/patientapi/${Username}/`)
      .then((responseone) => {
        setLoginUserDetails(responseone.data[0]);
        setmainDataLoading(false);
      })
      .catch((err) => {});
  }, [Username]);


  // GET WEIGHT

  const handleContinue = () => {
    setShowPopup(!showPopup);
  };
  const [ageDetails, setAgeDetails] = useState("");
  const [patientDetails, setPatientDetails] = useState("");

  useEffect(() => {
    Apiservice
      .get(`/current/user/details/${Username}/`)
      .then((response) => {
        setPatientDetails(response.data);
        
      })
      .catch((err) => {});
  }, [Username]);

  const UserImagePath = patientDetails&&patientDetails?.image
  const filename = UserImagePath?.split('/').pop();
  const spliturltwo = UserImagePath && UserImagePath?.slice(6);
  // remove -jpeg from the end and append "_avatar.jpeg"
  const spliturl = spliturltwo && spliturltwo?.slice(0, -5);
  
  
  console.log("UserImagePath", spliturltwo)

  const weightWiseAvatar = () => {
   
      console.log(childData?.value)
  }

  //console.log("patientDetails", ageDetails);

  const [weightdetails, setweightdetails] = useState("");

  useEffect(() => {
    Apiservice
      .get(`/user/allwighthistory/${Username}/`)
      .then((response) => {
        setweightdetails(response.data[response.data.length - 1]);
      })
      .catch((err) => {});
  }, [Username]);
  // // // //console.log("weightdetails", weightdetails);

  useEffect(() => {
    if (!weightdetails?.current_weight) {
        setShowPopup(true);
    }
}, [weightdetails?.current_weight]);

  // GET NEXT APPOINTMENT
  const [nextAppointment, setnextAppointment] = useState("");

  const spliturlthree = spliturltwo && spliturltwo?.slice(0, -4) + "_avatar_" + (weightdetails?.current_weight || childData.value) + "kgs.jpeg";

  useEffect(() => {
    Apiservice
      .get(`/appointmentapi/patient/${id}/`)
      .then((response) => {
        // setnextAppointment(response.data[response.data.length - 1]);
        setnextAppointment(response.data);
      })
      .catch((err) => {
        // // //console.log(err);
      });
  }, []);

  const TodaysDate = value.toLocaleDateString("fr-CA") + "T00:00:00Z";
  const NextAppointmentDateMap =
    nextAppointment && nextAppointment.map((index) => 
    // index.starttime.slice(0,10)
    index.starttime >= TodaysDate ? index.starttime: null
    );

    const ValidNextAppointmentDate =  
    NextAppointmentDateMap&&NextAppointmentDateMap?.sort((a, b) => new Date(b) - new Date(a));

    const RecentNextAppointments = []
    ValidNextAppointmentDate&&ValidNextAppointmentDate.forEach(elements => {
      if (elements != null) {
        RecentNextAppointments.push(elements);
      }
     });


    const NextCurrentAppointment = RecentNextAppointments&&RecentNextAppointments[RecentNextAppointments.length-1]

    // NextAppointmentDateMap.sort((a, b) => a - b);

    // NextAppointmentDateMap.forEach(elements => {
    //   if (elements != null && elements !== undefined && elements !== "") {
    //     ValidNextAppointmentDate.push(elements);
    //   }
    //  });

    // const ValidNextAppointmentDateNew= ValidNextAppointmentDate.sort();
// //console.log("ValidNextAppointmentDateMew",ValidNextAppointmentDateNew);



  // //console.log("NextAppointmentDateMap", NextAppointmentDateMap);
  //console.log("RecentNextAppointments", RecentNextAppointments);
  //console.log("NextCurrentAppointment", NextCurrentAppointment);
  // //console.log("ValidNextAppointmentDate", ValidNextAppointmentDate);
  // //console.log("TodaysDate", TodaysDate);

  const addweight = async (e) => {
    e.preventDefault();
    setmainDataLoading(true);
    await Apiservice
      .post(
        // `https://djangoobesitychat.azurewebsites.net/user/patientapi/update2/${Username}/`,
        `/user/currentwight/post/`,
        {
          patient_name: Username,
          current_weight: imageweight,
          height: loginUserDetails.height,
          gender: loginUserDetails.gender,
          age: loginUserDetails.age,
          // clinic_id: loginUserDetails.clinic_id,
          // subclinic_id: loginUserDetails.subclinic_id,
        },
        {
          headers: {
            Authorization: `Token ${tokenForAddPatient}`,
          },
        }
      )
      .then((res) => {
        swal({
          title: "Your Weight has been Submitted Successfully",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });
        Apiservice
          .get(`/user/allwighthistory/${Username}/`)
          .then((response) => {
            setweightdetails(response.data[response.data.length - 1]);
          });
        setmainDataLoading(false);
      })
      .catch((err) => {
        setmainDataLoading(false);
      });
  };

  //
  const addmeasurement = async (e) => {
    e.preventDefault();
    setmainDataLoading(true);

    await axios
      .put(
        `https://djangoobesitychat.azurewebsites.net/user/patientapi/update2/${Username}/`,
        {
          bust_circumference_cm: BustNew,
          hip_circumference_cm: HipNew,
          waist_circumference_cm: WestNew,
          clinic_id: loginUserDetails.clinic_id,
          subclinic_id: loginUserDetails.subclinic_id,
          patient_name: Username,
        },
        {
          headers: {
            Authorization: `Token ${tokenForAddPatient}`,
          },
        }
      )
      .then((res) => {
        swal({
          title: "Your Measurements are Submitted Successfully",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });
        setmainDataLoading(false);
      })
      .catch((err) => {
        setmainDataLoading(false);
      });
  };

  const HipNew =
    hip_circumference_cm == ""
      ? loginUserDetails && loginUserDetails.hip_circumference_cm
      : hip_circumference_cm;

  const BustNew =
    bust_circumference_cm == ""
      ? loginUserDetails && loginUserDetails.bust_circumference_cm
      : bust_circumference_cm;

  const WestNew =
    waist_circumference_cm == ""
      ? loginUserDetails && loginUserDetails.waist_circumference_cm
      : waist_circumference_cm;

  const imageGender = loginUserDetails && loginUserDetails.gender;
  // // // //console.log("Gender", imageGender);
  // const getweight = weightdetails && weightdetails.current_weight;

  const childDatavalue = localStorage.getItem("childDatavalue");
  let getweight = childDatavalue
    ? childDatavalue
    : weightdetails && weightdetails.current_weight;

  if (isClicked) {
    localStorage.setItem("childDatavalue", childData.value);
    setIsClicked(false);
  }

  const DateAppt = value.toLocaleDateString("fr-CA");

  console.log(`https://djangoobesitychat.azurewebsites.net${spliturlthree}`);

  const [postFinished, setpostFinished] = useState(false)
  useEffect(() => {
    if (spliturlthree) {
        setImageFile(`https://djangoobesitychat.azurewebsites.net${spliturlthree}`);
    }
}, [postFinished]);

  // get image file from url

  const [imageFile, setImageFile] = useState(spliturlthree ? `https://djangoobesitychat.azurewebsites.net${spliturlthree}` : null);

  const handleImageChange = (e) => {
    setImageFile(`https://djangoobesitychat.azurewebsites.net${spliturlthree}`);
  }

  

  useEffect(() => {
    const fetchData = async () => {
      setmainDataLoading(true)
        // 
                const imageFormData = new FormData();
                // imageFormData.append("image", file);
                imageFormData.append("username", Username);
                imageFormData.append("imageName", filename);
                imageFormData.append("imageWeight", childData.value);

                try {
                  const axiosResponse = await axios({
                      method: "post",
                      url: "https://djangoobesitychat.azurewebsites.net/avatar/",
                      data: imageFormData,
                  });

                  if (axiosResponse.status === 200) {
                      setpostFinished(!postFinished)
                      setImageFile(`https://djangoobesitychat.azurewebsites.net${spliturlthree}`);
                      setmainDataLoading(false)
                  }
      
                  console.log(axiosResponse.data);  // handle or use the axios response if needed

                 
              } catch (error) {
                  console.error("There was an error with the Axios request:", error);
              }
    };

    fetchData();
}, [childData?.value, Username, filename]);

  
console.log(imageFile)
console.log(weightdetails?.current_weight)

  return (
    <>
    
    <div className="health-overview-flex-div">
      {mainDataLoading && (
        <div className="purchase-order-details-pop-up-main-div">
          <div className="purchase-order-details-pop-up-sub-div">
            <div
              className="purchase-order-details-pop-up-sub-div-container"
              style={{ background: "none" }}
            >
              <Loader />
            </div>
          </div>
        </div>
      )}
      {!weightdetails?.current_weight && showPopup && (
         <div className="purchase-order-details-pop-up-main-div">
         <div className="purchase-order-details-pop-up-sub-div">
           <div
             className="purchase-order-details-pop-up-sub-div-container"
             style={{ background: "none" }}
           >
           <div className="results-summary-container">
           <div className="confetti">
             <div className="confetti-piece"></div>
             <div className="confetti-piece"></div>
             <div className="confetti-piece"></div>
             <div className="confetti-piece"></div>
             <div className="confetti-piece"></div>
             <div className="confetti-piece"></div>
             <div className="confetti-piece"></div>
             <div className="confetti-piece"></div>
             <div className="confetti-piece"></div>
             <div className="confetti-piece"></div>
             <div className="confetti-piece"></div>
             <div className="confetti-piece"></div>
             <div className="confetti-piece"></div>
             <div className="confetti-piece"></div>
             <div className="confetti-piece"></div>
             <div className="confetti-piece"></div>
             <div className="confetti-piece"></div>
             <div className="confetti-piece"></div>
             <div className="confetti-piece"></div>
           </div>
           <div className="results-summary-container__result">
             <div className="heading-tertiary">Congratulations!!</div>
             <div className="result-text-box">
               <div className="heading-secondary">Welcome to Weight Management app</div>
               <li className="paragraph">
                 <ul>Please fill the weight in the body measurements section.  </ul>
                 <ul>Please fill calories consumed in the Lifestyle section (scroll down). </ul>
                 <ul>For best results set up an appointment with the nearest clinic. </ul>
               </li>
             </div>
             <div class="summary__cta">
               <button class="btn btn__continue" onClick={handleContinue}>Continue 
             </button>
             </div>
           </div>
         </div>
         </div>
         </div>
         </div>
      )}
      <div className="health-overview-width-div">
        <div className="health-overview-main-div">
          
          <div className="health-overview-sub-div-one">
          <div className="health-overview-sub-one-flex">
                    <div className={isMedActive ? "tab active" : "tab"} onClick={HealthOverviewHandler}>
                    <p className={isMedActive ? "tab-title active" : "tab-title"}>
                        General Overview
                    </p>
                </div>

                <div className={isQuesActive ? "tab active" : "tab"} onClick={HealthDetailsHandler}>
                    <p className={isQuesActive ? "tab-title active" : "tab-title"}>
                        Questionnaire
                    </p>
                </div>

                <div className={isEventsActive ? "tab active" : "tab"} onClick={PatientEventsPageHandler}>
                    <p className={isEventsActive ? "tab-title active" : "tab-title"}>
                        Events
                    </p>
                </div>

                <div className={isDocumentActive ? "tab active" : "tab"} onClick={PatientDocumentsPageHandler}>
                    <p className={isDocumentActive ? "tab-title active" : "tab-title"}>
                        Documents
                    </p>
                </div>
                  </div>
            <div className="health-overview-sub-div-one-part-one">
              {/* <div className="health-overview-sub-one-title">
                <h3>Overview</h3>
                <div style={{ display: "flex", gap: "5px" }}>
                  <p>{day},</p>
                  <p>{month}</p>
                  <p>{date},</p>
                  <p>{year}</p>
                </div>
              
                  <div style={{ display: "flex", gap: "5px" }}>
                    <p>Next Appointment : </p>
                    <p>
                      {NextCurrentAppointment&&NextCurrentAppointment?.slice(0,10)}
                    </p>
                    <p>
                      {NextCurrentAppointment&&NextCurrentAppointment?.slice(11, 19)}
                    </p>
                   
                  </div>
                
              </div> */}
             

                {/* Rest of the content */}
                {HealthOverview && <PatientMedicalHistory />}
                {HealthDetails && <QuestionnairePutRequest />}
                {PatientEventsPage && <PatientEvents />}
                {PatientDocumentsPage && <PatientDocuments />}
                
            </div>
          </div>

          <div className="health-overview-sub-div-two-in-patient">
            <div className="pateient-main-div-in-patient">
              <div
                className="patient-img-name-div-in-pateinet-dashboard"
                style={{ width: "100%" }}
              >
                  <img
                    src={`https://djangoobesitychat.azurewebsites.net${spliturlthree}`}
                    className="patient-img-logo"
                  />
                
                <div className="patient-info-main-div-in-pateint-dashboard">
                  
                  <h4>
                    {patientDetails && patientDetails.firstName}{" "}
                    {loginUserDetails && loginUserDetails.lastName}
                  </h4>
                  <p>Patient ID : {patientDetails && patientDetails.id}</p>
                </div>
                
              </div>
              <div className="patient-info-in-user-login-main-div">
                  <div className="patient-user-login-main-info">
                    <div className="patient-user-login-main-info-one">
                      <h4 className="pat-inho-h-tag">Gender: </h4>
                    </div>
                    <div className="patient-user-login-main-info-two">
                      <p className="pat-inho-p-tag">
                        {patientDetails && patientDetails.gender}
                      </p>
                    </div>
                  </div>

                  <div className="patient-user-login-main-info">
                    <div className="patient-user-login-main-info-one">
                      <h4 className="pat-inho-h-tag">DOB:</h4>
                    </div>
                    <div className="patient-user-login-main-info-two">
                      <p className="pat-inho-p-tag">
                        {patientDetails &&
                          patientDetails.date_of_birth}
                      </p>
                    </div>
                  </div>

                  <div className="patient-user-login-main-info">
                    <div className="patient-user-login-main-info-one">
                      <h4 className="pat-inho-h-tag">Age:</h4>
                    </div>
                    <div className="patient-user-login-main-info-two">
                      <p className="pat-inho-p-tag">
                        {patientDetails && patientDetails.age}
                      </p>
                    </div>
                  </div>

                 
                </div>
                <div className="patient-user-login-main-info">
                    <div className="patient-user-login-main-info-one">
                      <h4 className="pat-inho-h-tag">Address: </h4>
                    </div>
                    <div className="patient-user-login-main-info-two">
                      <p className="pat-inho-p-tag">
                        {patientDetails && patientDetails.address}
                      </p>
                    </div>
                  </div>
            </div>

            <div className="chart-section-main-div">
              <p>Weight Forcasting</p>
              <div className="weight-forcast-graph-div">
                <WeightForcasting
                  passChildData={setChildData}
                  passClick={setIsClicked}
                />
              </div>
            </div>
            
          <div className = "body-measurement-card">
            <div className="chart-section-main-div-last">
              <h4>Body Measurements</h4>
              {/*<p>Last Checked 2 Days Ago</p>*/}
              <p>
                Last Checked {loginUserDetails && loginUserDetails.updatedate}
              </p>
              <div className="body-measurement-main-div">
              <div className="body-measurement-weight-div">
              <div className="enter-weight-p-div">
                <p>Current Weight</p>
              </div>
              {(!weightdetails || !weightdetails.current_weight) ? (
                <div className="gradientEffect">
                <p
                className="enter-weight-p-div-for-bmr"
                style={{ textAlign: "center" }}
              >
                {weightdetails && weightdetails.current_weight} Kg
              </p>
              </div>
                ) : (
              <p
                className="enter-weight-p-div-for-bmr"
                style={{ textAlign: "center" }}
              >
                {weightdetails && weightdetails.current_weight} Kg
              </p>
              )}    
              
            </div>
            <div className="body-measurement-weight-div">
              <div className="enter-weight-p-div">
                <p>Beginning BMR</p>
              </div>
              <div></div>
              <p
                className="enter-weight-p-div-for-bmr"
                style={{ textAlign: "center" }}
              >
                1697 kJ
              </p>
            </div>

            <div className="body-measurement-weight-div">
              <div className="enter-weight-p-div">
                <p>Current BMR</p>
              </div>
              <div></div>
              <p
                className="enter-weight-p-div-for-bmr"
                style={{ textAlign: "center" }}
              >
                {Math.round(weightdetails && weightdetails.BMR)} kJ
              </p>
            </div>
            </div>

            {imageGender == "Male" ? (
              <div>
                {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingTop: "10px",
                  }}
                >
                  <p
                    onClick={MalewithclothesHandler}
                    className={
                      isClothesActive
                        ? "male-female-btn-div-active"
                        : "male-female-btn-div"
                    }
                  >
                    With Clothes
                  </p>
                  <p
                    onClick={MalewithoutclothesHandler}
                    className={
                      isWithoutClothesActive
                        ? "male-female-btn-div-active"
                        : "male-female-btn-div"
                    }
                  >
                    Without Clothes
                  </p>
                </div> */}
                <div>
                  {malewithclothes && (
                    <div>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {getweight < 90 ? (
                          <div style={{display: 'flex', flexDirection:"column"}}>
                          <img
                            src={imageFile}
                            alt={Math.round(childData?.value)}
                            className=""
                            style={{ marginLeft: "5px", width:"350px", height: "325px" }}
                          />
                          <p>Your Avatar when your weight is {Math.round(childData?.value)}kgs</p>
                          </div>
                        ) : getweight >= 90 && getweight <= 100 ? (
                          <div style={{display: 'flex', flexDirection:"column"}}>
                          <img
                            src={imageFile}
                            alt={Math.round(childData?.value)}
                            className=""
                            style={{ marginLeft: "5px", width:"350px", height: "325px" }}
                          />
                          <p>Your Avatar when your weight is {Math.round(childData?.value)}kgs</p>
                          </div>
                        ) : getweight >= 100 && getweight <= 110 ? (
                          <div style={{display: 'flex', flexDirection:"column"}}>
                          <img
                            src={imageFile}
                            alt={Math.round(childData?.value)}
                            className=""
                            style={{ marginLeft: "5px", width:"350px", height: "325px" }}
                          />
                          <p>Your Avatar when your weight is {Math.round(childData?.value)}kgs</p>
                          </div>
                        ) : getweight >= 110 ? (
                          <div style={{display: 'flex', flexDirection:"column"}}>
                          <img
                            src={imageFile}
                            alt={Math.round(childData?.value)}
                            className=""
                            style={{ marginLeft: "5px", width:"350px", height: "325px" }}
                          />
                          <p>Your Avatar when your weight is {Math.round(childData?.value)}kgs</p>
                          </div>
                        ) : (
                          <p>Please Enter your Weight</p>
                        )}
                      </div>
                    </div>
                  )}

                  {malewithoutclothes && (
                    <div>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {getweight < 90 ? (
                          <div style={{display: 'flex', flexDirection:"column"}}>
                          <img
                            src={imageFile}
                            alt={Math.round(childData?.value)}
                            className=""
                            style={{ marginLeft: "5px", width:"350px", height: "325px" }}
                          />
                          <p>Your Avatar when your weight is {Math.round(childData?.value)}kgs</p>
                          </div>
                        ) : getweight >= 90 && getweight <= 100 ? (
                          <div style={{display: 'flex', flexDirection:"column"}}>
                          <img
                            src={imageFile}
                            alt={Math.round(childData?.value)}
                            className=""
                            style={{ marginLeft: "5px", width:"350px", height: "325px" }}
                          />
                          <p>Your Avatar when your weight is {Math.round(childData?.value)}kgs</p>
                          </div>
                        ) : getweight >= 100 && getweight <= 110 ? (
                          <div style={{display: 'flex', flexDirection:"column"}}>
                          <img
                            src={imageFile}
                            alt={Math.round(childData?.value)}
                            className=""
                            style={{ marginLeft: "5px", width:"350px", height: "325px" }}
                          />
                          <p>Your Avatar when your weight is {Math.round(childData?.value)}kgs</p>
                          </div>
                        ) : getweight >= 110 ? (
                          <div style={{display: 'flex', flexDirection:"column"}}>
                          <img
                            src={imageFile}
                            alt={Math.round(childData?.value)}
                            className=""
                            style={{ marginLeft: "5px", width:"350px", height: "325px" }}
                          />
                          <p>Your Avatar when your weight is {Math.round(childData?.value)}kgs</p>
                          </div>
                        ) : (
                          <p>Please Enter your Weight</p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : imageGender == "Female" ? (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingTop: "10px",
                  }}
                >
                  <p
                    onClick={FemalewithclothesHandler}
                    className={
                      isClothesActive
                        ? "male-female-btn-div-active"
                        : "male-female-btn-div"
                    }
                  >
                    With Clothes
                  </p>
                  <p
                    onClick={FemalewithoutclothesHandler}
                    className={
                      isWithoutClothesActive
                        ? "male-female-btn-div-active"
                        : "male-female-btn-div"
                    }
                  >
                    Without Clothes
                  </p>
                </div>
                <div>
                  {femalewithclothes && (
                    <div>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {getweight < 90 ? (
                          <div style={{display: 'flex', flexDirection:"column"}}>
                          <img
                            src={imageFile}
                            alt={Math.round(childData?.value)}
                            className=""
                            style={{ marginLeft: "5px", width:"350px", height: "325px" }}
                          />
                          <p>Your Avatar when your weight is {Math.round(childData?.value)}kgs</p>
                          </div>
                        ) : getweight >= 90 && getweight <= 100 ? (
                          <div style={{display: 'flex', flexDirection:"column"}}>
                          <img
                            src={imageFile}
                            alt={Math.round(childData?.value)}
                            className=""
                            style={{ marginLeft: "5px", width:"350px", height: "325px" }}
                          />
                          <p>Your Avatar when your weight is {Math.round(childData?.value)}kgs</p>
                          </div>
                        ) : getweight >= 100 && getweight <= 110 ? (
                          <div style={{display: 'flex', flexDirection:"column"}}>
                          <img
                            src={imageFile}
                            alt={Math.round(childData?.value)}
                            className=""
                            style={{ marginLeft: "5px", width:"350px", height: "325px" }}
                          />
                          <p>Your Avatar when your weight is {Math.round(childData?.value)}kgs</p>
                          </div>
                        ) : getweight >= 110 ? (
                          <div style={{display: 'flex', flexDirection:"column"}}>
                          <img
                            src={imageFile}
                            alt={Math.round(childData?.value)}
                            className=""
                            style={{ marginLeft: "5px", width:"350px", height: "325px" }}
                          />
                          <p>Your Avatar when your weight is {Math.round(childData?.value)}kgs</p>
                          </div>
                        ) : (
                          <p>Please Enter your Weight</p>
                        )}
                      </div>
                    </div>
                  )}
                  {femalewithoutclothes && (
                    <div>
                      <div
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        {getweight < 90 ? (
                          <div style={{display: 'flex', flexDirection:"column"}}>
                          <img
                            src={imageFile}
                            alt={Math.round(childData?.value)}
                            className=""
                            style={{ marginLeft: "5px", width:"350px", height: "325px" }}
                          />
                          <p>Your Avatar when your weight is {Math.round(childData?.value)}kgs</p>
                          </div>
                        ) : getweight >= 90 && getweight <= 100 ? (
                          <div style={{display: 'flex', flexDirection:"column"}}>
                          <img
                            src={imageFile}
                            alt={Math.round(childData?.value)}
                            className=""
                            style={{ marginLeft: "5px", width:"350px", height: "325px" }}
                          />
                          <p>Your Avatar when your weight is {Math.round(childData?.value)}kgs</p>
                          </div>
                        ) : getweight >= 100 && getweight <= 110 ? (
                          <div style={{display: 'flex', flexDirection:"column"}}>
                          <img
                            src={imageFile}
                            alt={Math.round(childData?.value)}
                            className=""
                            style={{ marginLeft: "5px", width:"350px", height: "325px" }}
                          />
                          <p>Your Avatar when your weight is {Math.round(childData?.value)}kgs</p>
                          </div>
                        ) : getweight >= 110 ? (
                          <div style={{display: 'flex', flexDirection:"column"}}>
                          <img
                            src={imageFile}
                            alt={Math.round(childData?.value)}
                            className=""
                            style={{ marginLeft: "5px", width:"350px", height: "325px" }}
                          />
                          <p>Your Avatar when your weight is {Math.round(childData?.value)}kgs</p>
                          </div>
                        ) : (
                          <p>Please Enter your Weight</p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div>
                <p>Please add Your Gender Details to See Your Avatar!!!</p>
              </div>
            )}
            </div>
            </div>

            

          <div className="enter-weight-card-div">
            <div className="enter-weight-card-div-one">
              <div className="enter-weight-p-div">
                <p style={{ fontWeight: "600" }}>Please Enter Your Weight</p>
              </div>
              <div>
                <h2>:</h2>
              </div>
              {(!weightdetails || !weightdetails.current_weight) ? (
                <div className = "gradientEffect">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "3px",
                }}
              >
                <input
                  onChange={(event) => setImageweight(event.target.value)}
                  className="enter-weight-p-div-input"
                  type="number"
                />
                <p>Kg</p>
              </div>
              </div>
              ) : (
                <>
                <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "3px",
                }}
              >
                <input
                  onChange={(event) => setImageweight(event.target.value)}
                  className="enter-weight-p-div-input"
                  type="number"
                />
                <p>Kg</p>
                </div>
                </>

              )}
             
            </div>
            <div
            >
              <button
                onClick={addweight}
                className="male-female-btn-div"
                style={{ backgroundColor: "rgb(49,163,214)" }}
              >
                Submit
              </button>
            </div>   
          </div>
            
            <div className="waist-hip-measurements-section">
            <div style={{ paddingTop: "10px", paddingBottom: "20px" }}>
              <h4>Measurements (in cms)</h4>
            </div>
            <div className="waist-hip-measurements-section-flex">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="enter-weight-p-div">
                <p style={{ fontWeight: "600" }}>Waist Circumference</p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "3px",
                }}
              >
                <input
                  onChange={(event) =>
                    setwaist_circumference_cm(event.target.value)
                  }
                  className="enter-weight-p-div-input"
                  type="number"
                  placeholder={
                    loginUserDetails && loginUserDetails.waist_circumference_cm
                  }
                />
                
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="enter-weight-p-div">
                <p style={{ fontWeight: "600" }}>Hip Circumference</p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "3px",
                }}
              >
                <input
                  onChange={(event) =>
                    sethip_circumference_cm(event.target.value)
                  }
                  className="enter-weight-p-div-input"
                  type="number"
                  placeholder={
                    loginUserDetails && loginUserDetails.hip_circumference_cm
                  }
                />
                
              </div>
              </div>
            

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="enter-weight-p-div">
                <p style={{ fontWeight: "600" }}>Bust Circumference</p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "3px",
                }}
              >
                <input
                  onChange={(event) =>
                    setbust_circumference_cm(event.target.value)
                  }
                  className="enter-weight-p-div-input"
                  type="number"
                  placeholder={
                    loginUserDetails && loginUserDetails.bust_circumference_cm
                  }
                />
              </div>
              </div>
            </div>
            <div
              style={{
                paddingTop: "10px",
                paddingBottom: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                onClick={addmeasurement}
                className="male-female-btn-div"
                style={{ backgroundColor: "rgb(49,163,214)" }}
              >
                Submit
              </button>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default PatientDashboard;
