import React from "react";
import "./AIDiet.css";
import { useState } from "react";
import axios from "axios";
import Loader from "../Loader/Loader";
import { useEffect } from "react";
import swal from "sweetalert";
import Apiservice from "../../Services/Apiservice";

const AIExercise = () => {
  const [WeightforDiet, setWeightforDiet] = useState(null);
//   const [MeatTypeforDiet, setMeatTypeforDiet] = useState("");
//   const [CuisineforDiet, setCuisineforDiet] = useState("");
//   const [IntoleranceforDiet, setIntoleranceforDiet] = useState("");

  const Username = localStorage.getItem("Username");

  const [LoginUserDetails, setLoginUserDetails] = useState("");

  //   GET AGE AND GENDER FROM USER MODEL
  useEffect(() => {
    Apiservice
      .get(`/current/user/details/${Username}/`)

      .then((response) => {
        setLoginUserDetails(response.data);
      })
      .catch((err) => {});
  }, [Username]);

  //   GET CURRENT WEIGHT

  useEffect(() => {
    Apiservice
      .get(`/user/allwighthistory/${Username}/`)
      .then((response) => {
        setWeightforDiet(
          response.data[response.data.length - 1]["current_weight"]
        );
      })
      .catch((err) => {});
  }, [Username]);

  const GenderforDiet = LoginUserDetails.gender;
  const AgeforDiet = LoginUserDetails.age;
  const CaleoriesforDiet = LoginUserDetails.gender == "Male" ? "2200" : "1900";

  const [loading, setLoading] = useState(false);

  const [AIDIetResponse, setAIDIetResponse] = useState();

  const [DayOneExercise, setDayOneExercise] = useState("");
  const [DayTwoExercise, setDayTwoExercise] = useState("");
  const [DayThreeExercise, setDayThreeExercise] = useState("");
  const [DayFourExercise, setDayFourExercise] = useState("");
  const [DayFiveExercise, setDayFiveExercise] = useState("");
  const [DaySixExercise, setDaySixExercise] = useState("");
  const [DaySevenExercise, setDaySevenExercise] = useState("");

//   const SendData = async (e) => {
//     setLoading(true);
//     e.preventDefault();
//     await axios
//       .post(
//         `https://djangoobesitychat.azurewebsites.net/defaultexerciseapi/${GenderforDiet}/${WeightforDiet}Kgs/${AgeforDiet}/${CaleoriesforDiet}/${Username}/`
//       )
//       .then((res) => {
//         //console.log("Res", res.data);
//         setAIDIetResponse(res.data);
//         setDayOneExercise(res.data["Day 1"]);
//         setDayTwoExercise(res.data["Day 2"]);
//         setDayThreeExercise(res.data["Day 3"]);
//         setDayFourExercise(res.data["Day 4"]);
//         setDayFiveExercise(res.data["Day 5"]);
//         setDaySixExercise(res.data["Day 6"]);
//         setDaySevenExercise(res.data["Day 7"]);

//         setLoading(false);
//       })
//       .catch((error) => {
//         setLoading(false);
//         // SendData()
//       });
//   };


const SendData = async (e) => {
    setLoading(true);
    e.preventDefault();
  
    try {
      const response = await axios.post(
        `https://djangoobesitychat.azurewebsites.net/defaultexerciseapi/${GenderforDiet}/${WeightforDiet}Kgs/${AgeforDiet}/${CaleoriesforDiet}/${Username}/`
      );
      //console.log('Res', response.data);
  
      setAIDIetResponse(response.data);
      setDayOneExercise(response.data['Day 1']);
      setDayTwoExercise(response.data['Day 2']);
      setDayThreeExercise(response.data['Day 3']);
      setDayFourExercise(response.data['Day 4']);
      setDayFiveExercise(response.data['Day 5']);
      setDaySixExercise(response.data['Day 6']);
      setDaySevenExercise(response.data['Day 7']);
  
      setLoading(false);
    } catch (error) {
        //console.log("error",error)
      setLoading(false);
      if (
        error.response &&
        error.response.status == 500 &&
        error.response.data.error == 'key error'
      ) {
        const updatedUrl = `https://djangoobesitychat.azurewebsites.net/defaultexerciseapi/${GenderforDiet}/${WeightforDiet}Kgs/${AgeforDiet}/${CaleoriesforDiet}/${Username}/?key=correct_key_value`;
        const response = await axios.post(updatedUrl);
        //console.log('Resent Res', response.data);
  
        setAIDIetResponse(response.data);
        setDayOneExercise(response.data['Day 1']);
        setDayTwoExercise(response.data['Day 2']);
        setDayThreeExercise(response.data['Day 3']);
        setDayFourExercise(response.data['Day 4']);
        setDayFiveExercise(response.data['Day 5']);
        setDaySixExercise(response.data['Day 6']);
        setDaySevenExercise(response.data['Day 7']);
  
        setLoading(false);
      } else {
        console.error(error);
      }
    }
  };

  const SavetoDB = async (e) => {
    e.preventDefault();
    setLoading(true);

    await Apiservice
      .post(
        "/suggestedexercise/post/",

        {
            suggestedexercise: AIDIetResponse,
          patient_name: Username,
        }
      )
      .then((res) => {
        swal({
          title: "Exercise Plan Added as Your Default Exercise Successfully...",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });
        setLoading(false);
      })
      .catch((res) => {
        setLoading(false);
      });
  };


  return (
    <div className="ai-diet-flex-div">
      {loading && (
        <div className="purchase-order-details-pop-up-main-div">
          <div className="purchase-order-details-pop-up-sub-div">
            <div
              className="purchase-order-details-pop-up-sub-div-container"
              style={{ background: "none" }}
            >
              <Loader />
            </div>
          </div>
        </div>
      )}
      <div className="ai-diet-width-div">
        <div className="ai-diet-main-div">
          <div className="ai-diet-main-div-one"></div>
          <div className="ai-diet-main-div-one">
            <div
              className="acc-setting-form-main-div"
              style={{ paddingTop: "10px" }}
            >
              <div className="acc-setting-form-block-div">
                {WeightforDiet == null ? (
                  <div className="acc-setting-details">
                    <div className="acc-info-div-one">
                      <p>Weight</p>
                    </div>
                    <div className="acc-info-div-one">
                      <input
                        placeholder="Please add Your Weight"
                        type="number"
                        value={WeightforDiet}
                        onChange={(e) => setWeightforDiet(e.target.value)}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="email-verification-main-div-two">
                  <button
                    className="login-page-login-btn"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      //   width: "60px",
                      padding: "10px",
                    }}
                    onClick={SendData}
                  >
                    Generate My Exercise Plan
                  </button>
                </div>

                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div className="ai-diet-responce-main-div">
          {DayOneExercise ? (
            <div>
              <div className="ai-diet-main-div-one">
                <h3>Exercise For Day One</h3>
              </div>
              <div style={{ paddingLeft: "100px", paddingTop: "15px" }}>
                {DayOneExercise &&
                  DayOneExercise?.map((index) => <li>{index}</li>)}
              </div>
            </div>
          ) : (
            ""
          )}

          {DayTwoExercise ? (
            <div>
              <div className="ai-diet-main-div-one">
                <h3>Exercise For Day Two</h3>
              </div>
              <div style={{ paddingLeft: "100px", paddingTop: "15px" }}>
                {DayTwoExercise &&
                  DayTwoExercise?.map((index) => <li>{index}</li>)}
              </div>
            </div>
          ) : (
            ""
          )}

          {DayThreeExercise ? (
            <div>
              <div className="ai-diet-main-div-one">
                <h3>Exercise For Day Three</h3>
              </div>
              <div style={{ paddingLeft: "100px", paddingTop: "15px" }}>
                {DayThreeExercise &&
                  DayThreeExercise?.map((index) => <li>{index}</li>)}
              </div>
            </div>
          ) : (
            ""
          )}

          {DayFourExercise ? (
            <div>
              <div className="ai-diet-main-div-one">
                <h3>Exercise For Day One</h3>
              </div>
              <div style={{ paddingLeft: "100px", paddingTop: "15px" }}>
                {DayFourExercise &&
                  DayFourExercise?.map((index) => <li>{index}</li>)}
              </div>
            </div>
          ) : (
            ""
          )}

          {DayFiveExercise ? (
            <div>
              <div className="ai-diet-main-div-one">
                <h3>Exercise For Day Five</h3>
              </div>
              <div style={{ paddingLeft: "100px", paddingTop: "15px" }}>
                {DayFiveExercise &&
                  DayFiveExercise?.map((index) => <li>{index}</li>)}
              </div>
            </div>
          ) : (
            ""
          )}

          {DaySixExercise ? (
            <div>
              <div className="ai-diet-main-div-one">
                <h3>Exercise For Day Six</h3>
              </div>
              <div style={{ paddingLeft: "100px", paddingTop: "15px" }}>
                {DaySixExercise &&
                  DaySixExercise?.map((index) => <li>{index}</li>)}
              </div>
            </div>
          ) : (
            ""
          )}

          {DaySevenExercise ? (
            <div>
              <div className="ai-diet-main-div-one">
                <h3>Exercise For Day Seven</h3>
              </div>
              <div style={{ paddingLeft: "100px", paddingTop: "15px" }}>
                {DaySevenExercise &&
                  DaySevenExercise?.map((index) => <li>{index}</li>)}
              </div>
            </div>
          ) : (
            ""
          )}

          {AIDIetResponse ? 
            <div className="email-verification-main-div-two">
                  <button
                    className="login-page-login-btn"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "10px",
                    }}
                    onClick={SavetoDB}
                  >
                    Save the above Exercise Plan as my Default Exercise
                  </button>
                </div>
            : ""}
        </div>
      </div>
    </div>
  );
};

export default AIExercise;
