import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import SearchLoader from "../Loader/SearchLoader";
import "./PrescriptionNew.css";
import Add from "@mui/icons-material/Add";
import swal from "sweetalert";
import { MdClose } from "react-icons/md";
import ICDSearch from "../Disease/Disease";
import PrescriptionView from "./PrescriptionView";
import Apiservice from "../../Services/Apiservice";

const PrescriptionNew = () => {
  const [morning, setmorning] = useState(false);
  const [afternoon, setafternoon] = useState(false);
  const [evening, setevening] = useState(false);
  const [nignt, setnignt] = useState(false);

  const handleChangemorning = () => { 
    setmorning(!morning); 
  }; 
  const handleChangeafternoon = () => { 
    setafternoon(!afternoon); 
  }; 
  const handleChangeevening = () => { 
    setevening(!evening); 
  }; 
  const handleChangenight = () => { 
    setnignt(!nignt); 
  }; 


  const [comment, setcomment] = useState("");
  const [dosage, setdosage] = useState("");
  const [dosageUnit, setdosageUnit] = useState(""); // ["tabs", "caps", "ml", "puff", "spray", "gelule", "dose"
  const [duration, setduration] = useState("");
  const [prescription_date, setprescription_date] = useState("");
  const [next_appointment_date, setnext_appointment_date] = useState("");
  const [next_appointment_time, setnext_appointment_time] = useState("");
  // const MedicineName = localStorage.getItem("MedicineName");
  const Diagnosis = localStorage.getItem("Diagnosis");
  const LoginDoctorID = localStorage.getItem("LoginDoctorID");
  const patientUserName = localStorage.getItem("patientUserName")
  //console.log("patientUserName",patientUserName)

  //console.log("nignt",nignt)
  const DateFieldNew = next_appointment_date + "T" + "00:00:00.000000" + "Z";

  const [opentwo, setOPentwo] = useState(false);
  const toggletwo = () => {
    setOPentwo(!opentwo);
  };

  const [openthree, setOPenthree] = useState(false);
  const togglethree = () => {
    setOPenthree(!openthree);
  };

  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  useEffect(() => {
    const loadPosts = async () => {
      setLoading(true);
      const response = await Apiservice.get("/medicineapi/");
      setPosts(response.data);
      setLoading(false);
    };
    loadPosts();
  }, []);
  // //console.log(posts);

  //   SEARCH DISEASE
  const [loadingtwo, setLoadingtwo] = useState(false);
  const [poststwo, setPoststwo] = useState([]);
  const [searchTitletwo, setSearchTitletwo] = useState("");
  useEffect(() => {
    const loadPoststwo = async () => {
      setLoadingtwo(true);
      const response = await Apiservice.get("/diseaseapi/");
      setPoststwo(response.data);
      setLoadingtwo(false);
    };
    loadPoststwo();
  }, []);

  let nextId = 0;
  const [medicine_name, setmedicine_name] = useState("");
  const [MedicineDetails, setMedicineDetails] = useState([]);

  const [drilldown,setdrilldown]=useState()

  useEffect(()=>{
    Apiservice.get(`/phenotypedrilldown/${patientUserName}/`)
    .then((response)=>{
      setdrilldown(response.data["recommendation"])
    })
    .catch(error =>{
    })
  },[patientUserName])

  //console.log("drilldown", drilldown);
  //console.log("MedicineDetails", MedicineDetails);
  const PostPrescription = async (e) => {
    e.preventDefault();

    await Apiservice
      .post(
        "/prescription2api/post/",
        {
          medicine_name: MedicineDetails,
          prescription_date: prescription_date,
          next_appointment: DateFieldNew,
          comment: comment,
          patient_name: patientUserName,
          disease_name: Diagnosis,
          doctor_id: LoginDoctorID,
        }
      )
      .then((res) => {
        swal({
          title: "Medicines Prescribed Successfully...",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });
        window.location.reload(); // Reload the page after successful prescription post
      })
      .catch((res) => {
        //console.log("response", res.response.data);
        // // //console.log("err", res.response.data);
        // setresponse(res.response.data);
      });
  };

  const [addDisease, setaddDisease] = useState(false);
  const closePopup = () => {
    setaddDisease(false);
  };
  const OpenPopup = () => {
    setaddDisease(!addDisease);
  };

  const [showPreviousPrescription, setshowPreviousPrescription] =
    useState(false);

  const handleshowPreviousPrescription = () => {
    setshowPreviousPrescription(true);
  };

  const [MedicineName, setMedicineName] = useState('');

 

  const MedicineNameNew = localStorage.getItem("MedicineName")

  
  const handleChange = (e) => {
    setMedicineName(e.target.value);
  };

  //console.log("SearchTitle", searchTitle)

  return (
    <div>
      <div className="appointment-by-clini-sub-div-one">
        <h4>Prescription</h4>
      </div>

    <div className = "search-panel">
      <div className="prescription-disease-search-section">
            <div className="patient-details-in-appointment-sub-div-two-part-one">
              <h4>Search for Diagnosis :</h4>
            </div>
            <div className="patient-details-in-appointment-sub-div-two-part-one-two">
              <input
                type="text"
                placeholder="Search Disease..."
                className="search-patient-in-appt-inp-tag"
                onClick={togglethree}
                onChange={(e) => setSearchTitletwo(e.target.value)}
              />
              {openthree && (
                <div
                  className="list-of-all-user"
                  onClick={togglethree}
                  style={{ width: "275px" }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      className="disease-handler-input-search-tag-btn"
                      onClick={OpenPopup}
                    >
                      Add More Disease
                    </button>
                  </div>
                  {loadingtwo ? (
                    <div>
                      <SearchLoader />
                    </div>
                  ) : (
                    poststwo
                      .filter((value) => {
                        if (searchTitletwo === "") {
                          return poststwo;
                        } else if (
                          value.disease_name
                            ?.toLowerCase()
                            .includes(searchTitletwo?.toLowerCase())
                        ) {
                          return value;
                        }
                      })
                      .map((item) => (
                        <div className="list-of-user-main-flex-div">
                          <div className="list-of-user-main-div">
                            <p
                              onClick={() =>
                                localStorage.setItem(
                                  "Diagnosis",
                                  item.disease_name
                                )
                              }
                            >
                              {item.disease_name}
                            </p>
                          </div>
                        </div>
                      ))
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="medicine-search-post-main-div">
            <div className="medicine-search-panel">
              <div className="search-panel-components">
                <div className="patient-details-in-appointment-sub-div-two-part-one">
                  <p>Search Medicine</p>
                </div>
                <div className="patient-details-in-appointment-sub-div-two-part-one-two">
                  <input
                    type="text"
                    placeholder="Search Medicine..."
                    className="search-patient-in-appt-inp-tag"
                    onClick={toggletwo}
                    onChange={(e) => setSearchTitle(e.target.value)}
                  />
                  {opentwo && (
                    <div
                      className="list-of-all-user"
                      onClick={toggletwo}
                      style={{ width: "275px" }}
                    >
                      {loading ? (
                        <div>
                          <SearchLoader />
                        </div>
                      ) : (
                        posts?.filter((value) => {
                            if (searchTitle === "") {
                              return posts;
                            } else if (
                              value.medicine_name
                                ?.toLowerCase()
                                .includes(searchTitle?.toLowerCase())
                            ) {
                              return value;
                            }
                          })
                          .map((item) => (
                            <div className="list-of-user-main-flex-div">
                              <div className="list-of-user-main-div">
                                <p
                                  onClick={() =>
                                    localStorage.setItem(
                                      "MedicineName",
                                      item.medicine_name
                                    )
                                  }
                                >
                                  {item.medicine_name}
                                </p>
                              </div>
                            </div>
                          ))
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="search-panel-components">
                <div className="patient-details-in-appointment-sub-div-two-part-one">
                  <p>Selected Medicine</p>
                </div>
                <div
                  className="patient-details-in-appointment-sub-div-two-part-one-two"
                  style={{ display: "flex" }}
                >
                {MedicineNameNew !== 'QSYMIA' && MedicineNameNew !== 'SAXENDA' && MedicineNameNew !== 'CONTRAVE' && MedicineNameNew !== 'LOMAIRA' && MedicineNameNew !== 'MYSIMBA' ? (
                  <input
                    className="post-prescription-input-tag"
                    type={"text"}
                    placeholder="When Other is selected, please specify Medicine"
                    value={MedicineName}
                    onChange={handleChange}
                  />
      ) : (
        <p>{MedicineNameNew}</p>
      )}
      </div>
              </div>

              <div className="search-panel-components">
                <div className="patient-details-in-appointment-sub-div-two-part-one">
                  <p>Dosage</p>
                </div>
                <div
                  className="patient-details-in-appointment-sub-div-two-part-one-two"
                  style={{ display: "flex" }}
                >
                  <input
                    className="post-prescription-input-tag"
                    type={"text"}
                    placeholder="Dosage Unit"
                    value={dosageUnit}
                    onChange={(e) => setdosageUnit(e.target.value)}
                  />
                  {/* <p>ml</p> */}
                  <select>
                    <option>select</option>
                    <option>tabs</option>
                    <option>caps</option>
                    <option>ml</option>
                    <option>puff</option>
                    <option>spray</option>
                    <option>gelule</option>
                    <option>dose</option>
                  </select>
                </div>
              </div>
              <div className="search-panel-components">
                <div className="patient-details-in-appointment-sub-div-two-part-one">
                  <p>Duration</p>
                </div>
                <div
                  className="patient-details-in-appointment-sub-div-two-part-one-two"
                  style={{ display: "flex" }}
                >
                  <input
                    className="post-prescription-input-tag"
                    type={"number"}
                    placeholder="Days"
                    value={duration}
                    onChange={(e) => setduration(e.target.value)}
                  />
                  <p>Days</p>
                </div>
              </div>
              <div className="search-panel-components">
                <div className="patient-details-in-appointment-sub-div-two-part-one">
                  <p>Time</p>
                </div>
                <div className="patient-details-in-appointment-sub-div-two-part-one-two">
                  <div
                    className="doctor-prescr-sub-div-three"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      // width: "30%",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <div style={{ display: "flex", gap: "3px" }}>
                      <input
                        type="checkbox"
                        // value={"true"}
                        // onChange={(e) => setmorning(e.target.value)}
                        onChange={handleChangemorning}
                        
                      />{" "}
                      <p>Morning</p>
                    </div>
                    <div style={{ display: "flex", gap: "3px" }}>
                      <input
                        type="checkbox"
                        // value={"true"}
                        // onChange={(e) => setafternoon(e.target.value)}
                        onChange={handleChangeafternoon}
                      />{" "}
                      <p>Afternoon</p>
                    </div>
                    <div style={{ display: "flex", gap: "3px" }}>
                      <input
                        // value={"true"}
                        // onChange={(e) => setevening(e.target.value)}
                        onChange={handleChangeevening}
                        type="checkbox"
                      />{" "}
                      <p>Evening</p>
                    </div>
                    <div style={{ display: "flex", gap: "3px" }}>
                      <input
                        type="checkbox"
                        // value={handleChangenight}
                        // onChange={(e) => setnignt(e.target.value)}
                        onChange={handleChangenight}
                      />{" "}
                      <p>Night</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <div
                className="patient-details-in-appointment-sub-div-two-part"
                style={{ justifyContent: "center" }}
              >
                <button
                  className="add-btn-btn-tag"
                  onClick={() => {
                    setmedicine_name("");
                    setdosage("");
                    setduration("");
                    setMedicineDetails([
                      ...MedicineDetails,
                      {
                        id: nextId++,
                        medicine_name: MedicineNameNew !== 'QSYMIA' && MedicineNameNew !== 'SAXENDA' && MedicineNameNew !== 'CONTRAVE' && MedicineNameNew !== 'LOMAIRA' && MedicineNameNew !== 'MYSIMBA' ? MedicineName : MedicineNameNew,
                        dosage: dosage,
                        dosageUnit: dosageUnit,
                        duration: duration,
                        morning: morning,
                        afternoon: afternoon,
                        evening: evening,
                        nignt: nignt,
                      },

                    ]);
                  }}
                >
                  <Add style={{ height: "20px", width: "20px" }} />
                  Add
                </button>
              </div>
            </div>
      </div>
      <div className="doctor-prescription-medicine-flex-div">
        <div
          className="patient-details-in-appointment-main-div"
        >
        <div className="diagnosis-section">
          <div className="patient-details-in-appointment-sub-div-two-part">
            <div className="patient-details-in-appointment-sub-div-two-part-one">
              <p>Diagnosis</p>
            </div>
            <div className="patient-details-in-appointment-sub-div-two-part-one-two">
              <p>{Diagnosis}</p>
            </div>
          </div>

          <div className="patient-details-in-appointment-sub-div-two-part">
            <div className="patient-details-in-appointment-sub-div-two-part-one">
              <p>Prescription Date</p>
            </div>
            <div className="patient-details-in-appointment-sub-div-two-part-one-two">
              <input
                className="post-prescription-input-tag"
                type={"date"}
                placeholder="Comment"
                value={prescription_date}
                onChange={(e) => setprescription_date(e.target.value)}
              />
            </div>
          </div>
        </div>

          <div className="patient-details-in-appointment-sub-div-two">

            {drilldown ?
            <div className="recommended-medicine-main-div">
            <p className="recommended-medicine-main-div-p-tag">{drilldown&&drilldown}</p>
            </div>
            : ""}

            <div className="medicine-details-post-main-div">
              <div className="medicine-details-post-main-div-for-added-med">
                <div className="medicine-details-post-main-div-for-added-med-one">
                  <h3>Medicine</h3>
                </div>
                <div className="medicine-details-post-main-div-for-added-med-two">
                  <h3>Dosage</h3>
                </div>
                <div className="medicine-details-post-main-div-for-added-med-three">
                  <h3>Duration</h3>
                </div>
                <div className="medicine-details-post-main-div-for-added-med-four">
                  <h3>Morning</h3>
                </div>
                <div className="medicine-details-post-main-div-for-added-med-four">
                  <h3>Afternoon</h3>
                </div>
                <div className="medicine-details-post-main-div-for-added-med-four">
                  <h3>Evening</h3>
                </div>
                <div className="medicine-details-post-main-div-for-added-med-four">
                  <h3>Night</h3>
                </div>
              </div>

              {MedicineDetails &&
                MedicineDetails.map((index) => (
                  <div className="medicine-details-post-main-div-for-added-med">
                    <div className="medicine-details-post-main-div-for-added-med-one">
                      <p>{index.medicine_name}</p>
                    </div>
                    <div className="medicine-details-post-main-div-for-added-med-two">
                      <p>{index.dosage}</p>
                    </div>
                    <div className="medicine-details-post-main-div-for-added-med-three">
                      <p>{index.duration}</p>
                    </div>
                    <div className="medicine-details-post-main-div-for-added-med-four">
                      <p>{index.morning == true ? 1 : 0}</p>
                    </div>
                    <div className="medicine-details-post-main-div-for-added-med-four">
                      <p>{index.afternoon == true ? 1 : 0}</p>
                    </div>
                    <div className="medicine-details-post-main-div-for-added-med-four">
                      <p>{index.evening == true ? 1 : 0}</p>
                    </div>
                    <div className="medicine-details-post-main-div-for-added-med-four">
                      <p>{index.nignt == true ? 1 : 0}</p>
                    </div>
                  </div>
                ))}
            </div>

            <div className="patient-details-in-appointment-sub-div-two-part">
              <div className="patient-details-in-appointment-sub-div-two-part-one">
                <p>Comment</p>
              </div>
              <div className="patient-details-in-appointment-sub-div-two-part-one-two">
                <input
                  className="post-prescription-input-tag"
                  type={"text"}
                  placeholder="Comment"
                  value={comment}
                  onChange={(e) => setcomment(e.target.value)}
                />
              </div>
            </div>

            <div className="patient-details-in-appointment-sub-div-two-part">
              <div className="patient-details-in-appointment-sub-div-two-part-one">
                <p>Next Appointment</p>
              </div>
              <div className="patient-details-in-appointment-sub-div-two-part-one-two">
                <input
                  className="post-prescription-input-tag"
                  // className="post-presc-next-appt-input-date-time"
                  type={"date"}
                  placeholder="Comment"
                  value={next_appointment_date}
                  onChange={(e) => setnext_appointment_date(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="add-pat-btn-div">
            <button
              className="add-btn-btn-tag"
              onClick={PostPrescription}
            >
              <Add style={{ height: "20px", width: "20px" }} />
              SUBMIT PRESCRIPTION
            </button>
          </div>
        </div>
      </div>

      {addDisease && (
        <div className="purchase-order-details-pop-up-main-div">
          <div className="purchase-order-details-pop-up-sub-div">
            <div className="purchase-order-details-pop-up-sub-div-container">
              <div className="purchase-order-details-pop-up-sub-div-container-one">
                <div className="purchase-order-details-pop-up-sub-div-close">
                  <MdClose
                    onClick={closePopup}
                    style={{
                      height: "25px",
                      width: "25px",
                      cursor: "pointer",
                      color: "rgb(49,163,214)",
                    }}
                  />
                </div>
                <div>
                  <ICDSearch />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div>
        <div>
          <PrescriptionView />
        </div>
      </div>
    </div>
  );
};

export default PrescriptionNew;
