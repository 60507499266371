import React from "react";
import { Link } from "react-router-dom";
import "./CompanyDetailsVideo.css";
import EastSharpIcon from "@mui/icons-material/EastSharp";

const CompanyDetailsVideo = () => {
  return (
    <div className="company-details-flex-div">
      <div>
        <div className="skip-for-now-div" style={{paddingBottom:"10px"}}>
  {/*<Link to="/patientquestionnaire">*/}
          <Link to="/patientquestionnaire">
            <h3>
              Skip For Now <EastSharpIcon style={{ marginTop: "4px" }} />
            </h3>
          </Link>
        </div>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/Nl-HyC9C7P0"
          title="YouTube video player"
          frameborder="0"
          allow="autoplay"
          
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
};

export default CompanyDetailsVideo;
