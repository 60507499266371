import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from "react";
// import "./PatientPrescription.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import axios from "axios";

const PatientEvents = () => {
  const UserIDforEvents = localStorage.getItem("Username");

  function ImageCellRenderer(params) {
    const imageUrl = params.value;
    return <img style={{ height: "3em" }} src={imageUrl} alt="Cell" />;
  }
  const gridRef = useRef();
  const [columnDefs, setColumnDefs] = useState([
    {
      field: "date",
      filter: true,
      headerName: "Date",
      headerClassName: "super-app-theme--header",
    },
    { field: "events", filter: true, headerName: "Event" },
    { field: "Event_details", filter: true, headerName: "Event Details" },
    { field: "next_steps", headerName: "Next Steps" },
    { field: "doctor_firstName", headerName: "Created by First Name" },
    { field: "doctor_lastName", headerName: "Created by Last Name" },
  ]);
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    resizable: true,
    suppressToolPanel: true,
  }));
  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(
      document.getElementById("filter-text-box").value
    );
  }, []);
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    // fetch(`https://djangoobesitychat.azurewebsites.net/prescriptionapi/doctor/${patientIDforPrescription}/`)
    fetch(`https://djangoobesitychat.azurewebsites.net/event/user/${UserIDforEvents}/`, {
      headers: {
        Authorization: `Token ${localStorage.getItem("SavedToken")}`,
      },
    })
      .then((data) => data.json())
      .then((data) => setTableData(data));
    // setLoading(false);
  }, []);

  // // //console.log("Prescription Table", tableData);

  return (
    <div>
      <div
        className="ag-theme-material"
        style={{
          width: "100%",
          height: "150vh",
          boxShadow: "rgb(0 0 0 / 35%) 0px 5px 15px",
          marginTop: "20px",
          overflowY: "scroll",
        }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={tableData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          rowSelection="multiple"
        />
      </div>
    </div>
  );
};

export default PatientEvents;
