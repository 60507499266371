import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Loader from "../Loader/Loader";
import swal from "sweetalert";

const Que = (props) => {
  return (
    <div>
      <div className="questo-form-quesyion-ans-main-div-for-main">
      <div className="questo-form-questions-wrapper" style={{ width: "55%", cursor:"pointer" }}>
      <div className="questo-form-questions-div" >
          <p className="question-text">{props.number}. {props.item.question}</p>
        </div>
        <div className="tooltip">More information</div>
        </div>
        <div className="response-div">
        <div className="questo-form-options-div" style={{ width: "15%" }}>
        <p classname="label-for-response" style={{color: '#88869d', marginBottom: '30px'}}>Response: </p>
          <p style={{ textAlign: "center" }}>{props.item.patient_response1}</p>
        </div>
        <div
          className="questo-form-details-div-in-pat"
          style={{ width: "30%" }}
        >
          <p classname="questo-new-label-for-pat-que" style={{color: '#88869d'}}>Details: </p>
          <div className="questo-form-input-tag-for-patient">
            <p>{props.item.medicine_name == "Other" ? props.item.response_details1 : props.item.medicine_name }</p>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

const QueUpdate = (props) => {

  const [selectedOption, setSelectedOption] = useState("");
  const [options, setOptions] = useState([]);
  const [rows, setRows] = useState([
    { medication: "", dosage: "", number: "" },
  ]);
  const is_patient = localStorage.getItem("is_patient")
  const is_doctor = localStorage.getItem("is_doctor");
  const UsernameofPatient =
    is_patient == "true"
      ? localStorage.getItem("Username")
      : is_doctor == "true"
      ? localStorage.getItem("patientUserName")
      : localStorage.getItem("Username");


  const handleAddRow = () => {
    setRows([...rows, { medication: "", dosage: "", number: "" }]);
  };

  const handleRemoveRow = () => {
    const newRows = [...rows];
    newRows.splice(-1, 1);
    setRows(newRows);
  };


  const handleChange = (event, index) => {
    const newRows = [...rows];
    newRows[index][event.target.name] = event.target.value;
    setRows(newRows);
  };
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Convert the data from the table into the format the API expects
    const tableData = {};
    rows.forEach((row) => {
      tableData[row.medication] = {
        "Medication Name": row.medication,
        Dosage: row.dosage,
        Number: row.number,
      };
    });
    const data = Object.fromEntries(Object.entries(tableData));
    fetch(
      `https://djangoobesitychat.azurewebsites.net/patient/response/Other/${UsernameofPatient}/`,
      {
        method: "POST",
        body: JSON.stringify([
          {
            question1: props.item.question1,
            patient_name: UsernameofPatient,
            tablejson: data,
          },
        ]),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };



  return (
    <div>
      <div className="questo-form-quesyion-ans-main-div-for-main">
      <div className="questo-form-questions-wrapper" style={{ width: "55%", cursor:"pointer" }}>
      <div className="questo-form-questions-div" >
          <p className="question-text">{props.number}. {props.item.question}</p>
        </div>
        <div className="tooltip">More information</div>
        </div>
        {/*<div className="questo-form-options-div" style={{ width: "15%" }}>
          <div style={{ display: "flex", gap: "9px" }}>
            <input
              className="add-patient-input-field-of-radio"
              name={props.item.response_id1}
              type="radio"
              value="Yes"
              onChange={(event) =>
                props.radioButtonHandler({
                  que_id: props.item.response_id1,
                  val: event.target.value,
                })
              }
            />
            <p style={{ fontSize: "13px" }}>Yes</p>
            <input
              className="add-patient-input-field-of-radio"
              name={props.item.response_id1}
              type="radio"
              value="No"
              onChange={(event) =>
                props.radioButtonHandler({
                  que_id: props.item.response_id1,
                  val: event.target.value,
                })
              }
            />
            <p style={{ fontSize: "13px" }}>No</p>
          </div>
        </div>
        <div
          className="questo-form-details-div-in-pat"
          style={{ width: "30%" }}
        >
          <textarea
            className="questo-form-input-tag-for-patient"
            type={"text"}
            placeholder="Details..."
            onChange={(event) =>
              props.detailsHandler({
                que_id: props.item.response_id1,
                val: event.target.value,
              })
            }
          />
          </div>*/}

          {props.item.question_category === "yes_no" ? (
            <div className="response-div">
              <div className="questo-form-options-div" style={{ width: "15%" }}>
              <p classname="label-for-response" style={{color: '#88869d', marginBottom: '20px'}}>Response: </p>
                <div style={{ display: "flex", gap: "9px"}}>
                  <input
                    className="add-patient-input-field-of-radio"
                    // name="response"
                    name={props.item.response_id1}
                    type="radio"
                    value="Yes"
                    checked={props.item.patient_response1 === "Yes"}
                    onChange={(event) =>
                      props.radioButtonHandler({
                        que_id: props.item.response_id1,
                        val: event.target.value,
                      })
                    }
                  />
                  <p style={{ fontSize: "13px" }}>Yes</p>
                  <input
                    className="add-patient-input-field-of-radio"
                    // name="response"
                    name={props.item.response_id1}
                    type="radio"
                    value="No"
                    checked={props.item.patient_response1 === "No"}
                    onChange={(event) =>
                      props.radioButtonHandler({
                        que_id: props.item.response_id1,
                        val: event.target.value,
                      })
                    }
                  />
                  <p style={{ fontSize: "13px" }}>No</p>
                </div>
              </div>
              <div
                className="questo-form-details-div-in-pat"
                style={{ width: "30%"}}
              >
                <p classname="questo-new-label-for-pat-que" style={{color: '#88869d'}}>Medicines: </p>
               
                <select
                  className="questo-form-input-tag-for-patient"
                  // value={selectedOption}
                  // onChange={handleSelectChange}
                  onChange={(event) =>
                    props.detailsHandler({
                      que_id: props.item.response_id1,
                      val: event.target.value,
                    },
                setSelectedOption(event.target.value)
                    
                    )
                  }
                >
                  <option>Select Medicine</option>
                  <option value="CONTRAVE">CONTRAVE</option>
                  <option value="LOMAIRA">LOMAIRA</option>
                  <option value="SAXENDA">SAXENDA</option>
                  <option value="QSYMIA">QSYMIA</option>
                  <option value="MYSIMBA">MYSIMBA</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              {selectedOption === "Other" ? (
                  <div
                  className="questo-form-details-div-in-pat"
                  style={{ width: "30%" }}
                >
                  <p classname="questo-new-label-for-pat-que" style={{color: '#88869d'}}>Details: </p>
                  <textarea
                    className="questo-form-input-tag-for-patient"
                    type={"text"}
                    style={{marginTop:"5px"}}
                    placeholder="Details..."
                    onChange={(event) =>
                      props.othersHandler({
                        que_id: props.item.response_id1,
                        val: event.target.value,
                      })
                    }
                  />
                </div>
                ) : null}
              <div>
                {/*{props.detailsHandler({medicine_name}== 'Other')  && <input/>}*/}
              </div>
            </div>
          ) : props.item.question_category === "free_text" ? (
            <>
              <div className="questo-form-questions-div" style={{ width: "55%" }}>
                <p>
                  {props.item.mandatory === "Yes" ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : null}
                </p>
              </div>
              <textarea
                className="questo-form-input-tag-for-patient"
                type={"text"}
                placeholder="Details..."
                onChange={(event) =>
                  props.detailsHandler({
                    que_id: props.item.response_id1,
                    val: event.target.value,
                  })
                }
              />
            </>
          ) : props.item.question_category === "" ? (
            <>
              <div className="questo-form-questions-div" style={{ width: "55%" }}>
                <p>
                  {props.item.mandatory === "Yes" ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : null}
                </p>
              </div>
              <textarea
                className="questo-form-input-tag-for-patient"
                type={"text"}
                placeholder="Details..."
                onChange={(event) =>
                  props.detailsHandler({
                    que_id: props.item.response_id1,
                    val: event.target.value,
                  })
                }
              />
            </>
          ):props.item.question_category === "table_format" ? (
            <div
              className="questo-form-quesyion-ans-main-div-for-main"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="questo-form-questions-div" style={{ width: "55%" }}>
                <p>
                  {props.item.mandatory === "Yes" ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : null}
                </p>
              </div>
              <form
                // onSubmit={handleSubmit}
                style={
                  {
                   
                  }
                }
                className="questo-form-input-tag-for-patient"
              >
              <div style={{display: "flex", justifyContent: "center"}}>
              <table>
    
    
                    <thead>
                      <tr>
                        <th>Medication Name</th>
                        <th>Dosage</th>
                        <th>Number of times taken daily</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rows.map((row, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              type="text"
                              name="medication"
                              className="questo-form-input-tag-for-patient"
                              value={row.medication}
                              onChange={(event) => handleChange(event, index)}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="dosage"
                              className="questo-form-input-tag-for-patient"
                              value={row.dosage}
                              onChange={(event) => handleChange(event, index)}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="number"
                              className="questo-form-input-tag-for-patient"
                              value={row.number}
                              onChange={(event) => handleChange(event, index)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot style={{textAlign:"center"}}>
                      <tr>
                        <td colSpan={3}>
                          <button type="button" onClick={handleAddRow} className="male-female-btn-div" style={{backgroundColor:"rgb(49, 163, 214)",padding:"2px"}}>Add Row</button>
                          <button type="button" onClick={handleRemoveRow} className="male-female-btn-div" style={{backgroundColor:"rgb(49, 163, 214)",padding:"2px", marginLeft: "5px"}}>Remove Row</button>
                        </td>
                      </tr>
                    </tfoot>
                    </table>
                    </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                <button type="submit" onClick={handleSubmit} className="submit-btn-in-que" style={{padding:"4px"}}>Save</button>
                </div>
              </form>
            </div>
          ) : null}

      </div>
    </div>
  );
};
<></>
const QuestionnaireMedicine = () => {
  const [questionetailsget, setquestionetailsget] = useState([]);
  const [loading, setLoading] = useState(true);
  // const UsernameofPatient = localStorage.getItem("Username");
  const is_patient = localStorage.getItem("is_patient")

  const is_doctor = localStorage.getItem("is_doctor");
  const UsernameofPatient =
    is_patient == "true"
      ? localStorage.getItem("Username")
      : is_doctor == "true"
      ? localStorage.getItem("patientUserName")
      : localStorage.getItem("Username");

//   const UsernameofPatient = 
//   is_patient == "true" ?
//   localStorage.getItem("Username")
// : localStorage.getItem("patientUserName")


  const childRef = useRef();
  const [isUpdateActive, setisUpdateActive] = useState(false);

  //console.log("questionetailsgetNew",questionetailsget)

  const OpenUpdateHandler = () => {
    setisUpdateActive(true);
  };

  const CloseUpdateHandler = () => {
    setisUpdateActive(false);
  };

  useEffect(() => {
    axios
      .get(
        // `https://djangoobesitychat.azurewebsites.net/patient/response/Medicine/${UsernameofPatient}/`,
        `https://djangoobesitychat.azurewebsites.net/patient/response/get/Medicine/${UsernameofPatient}/`,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("SavedToken")}`,
          },
        }
      )

      .then((response) => {
        const data = response.data.map((entry) => {
          return Object.assign({}, entry, {
            // patient_name: localStorage.getItem("Username"),
            patient_name: UsernameofPatient,
            response_id1: entry.response_id1,
          });
        });
        setquestionetailsget(data);
        setLoading(false);
      })
      .catch((err) => {});
  }, []);

  const radioButtonHandler = (param) => {
    const newResponse = questionetailsget.map((item) => {
      if (item.response_id1 == param.que_id) {
        item.patient_response1 = param.val;
      }
      return item;
    });
    setquestionetailsget(newResponse);
  };

  const detailsHandler = (param) => {
    const newResponse = questionetailsget.map((item) => {
      if (item.response_id1 == param.que_id) {
        item.medicine_name = param.val;
      }
      return item;
    });
    setquestionetailsget(newResponse);
  };

  const othersHandler = (param) => {
    const newResponse = questionetailsget.map((item) => {
      if (item.response_id1 == param.que_id) {
        item.response_details1 = param.val;
        // item.detail = param.val;

      }
      return item;
    });
    setquestionetailsget(newResponse);
  };
  

  const [submitLoading, setsubmitLoading] = useState(false);

  const submitHandler = (props) => {
    // const UsernameofPatient = localStorage.getItem("Username");
    const test = questionetailsget;
    setsubmitLoading(true);

    axios
      .put(
        `https://djangoobesitychat.azurewebsites.net/patient-response/bulk-update/`,
        questionetailsget
      )
      .then((res) => {
        // //console.log("res", res);
    setsubmitLoading(false);

        swal({
          title: "Your Response has been Updated Successfully",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });
        CloseUpdateHandler();

        // //console.log("res", res);
      })
      .catch((err) => {
    setsubmitLoading(false);

        // //console.log("err", err);
      });
  };

  const selectAllYes = () => {
    const newResponse = questionetailsget.map((item) => {
      if (item.question_category === "yes_no") {
        item.patient_response1 = "Yes";
      }
      return item;
    });
    setquestionetailsget(newResponse);
  };

  const selectAllNo = () => {
    const newResponse = questionetailsget.map((item) => {
      if (item.question_category === "yes_no") {
        item.patient_response1 = "No";
      }
      return item;
    });
    setquestionetailsget(newResponse);
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>
        {submitLoading && (
          <div className="purchase-order-details-pop-up-main-div">
            <div className="purchase-order-details-pop-up-sub-div">
              <div
                className="purchase-order-details-pop-up-sub-div-container"
                style={{ background: "none" }}
              >
                <Loader />
              </div>
            </div>
          </div>
        )}
          
          <div className="update-response-div">
            {isUpdateActive == true ? (
              <>
              <button
                className="submit-btn-in-que"
                onClick={CloseUpdateHandler}
              >
                Discard Changes
              </button>
              <p style={{ fontSize: "16px" }}>Select All : </p>
              <button onClick={selectAllYes} className="submit-btn-in-que">
                Yes
              </button>
              <button onClick={selectAllNo} className="submit-btn-in-que">
                No
              </button>
              </>
            ) : (
              
              <button
                className="submit-btn-in-que"
                onClick={OpenUpdateHandler}
              >
                Update Your Response
              </button>
              
              
            )}
          </div>

          < hr />
          {/* <div className="questo-form-quesyion-ans-main-div-for-main">
            <div
              className="questo-form-questions-div"
              style={{ width: "55%", justifyContent: "center" }}
            >
              <h3>Questions</h3>
            </div>
            <div
              className="questo-form-options-div"
              style={{ width: "15%", justifyContent: "center" }}
            >
              <h3 style={{ textAlign: "center" }}>Response</h3>
            </div>
            <div
              className="questo-form-details-div-in-pat"
              style={{ width: "30%", justifyContent: "center" }}
            >
              <h3>Details</h3>
            </div>
          </div> */}
          {questionetailsget.map((item, index) => {
            return (
              <div>
                {isUpdateActive == true ? (
                  <QueUpdate
                    key={item.question_id}
                    item={item}
                    number={index + 1}
                    ref={childRef}
                    detailsHandler={detailsHandler}
                    radioButtonHandler={radioButtonHandler}
                    othersHandler={othersHandler}
                  />
                ) : (
                  <Que
                    key={item.question_id}
                    item={item}
                    number={index + 1}
                    // ref={childRef}
                    // detailsHandler={detailsHandler}
                    // radioButtonHandler={radioButtonHandler}
                  />
                )}
              </div>
            );
          })}
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "0px",
        }}
      >
        {isUpdateActive == true ? (
          <button style={{marginTop: '4px'}} onClick={submitHandler} className="submit-btn-in-que">
            Submit
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default QuestionnaireMedicine;

