import * as React from "react";
import { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Routes, Route } from "react-router-dom";
import { Link } from "react-router-dom";
import DashboardSharpIcon from "@mui/icons-material/DashboardSharp";
import Dashboard from "../Dashboard/Dashboard";
import BallotIcon from "@mui/icons-material/Ballot";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import PatientList from "../../containers/patientlist/PatientList";
import PersonalPatientFile from "../PersonalPatientFile/PersonalPatientFile";
import Navbar from "../Navbar/Navbar";
import { GiDirectionSign } from "react-icons/gi";
import { BsBell } from "react-icons/bs";
import { FaAngleDown } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import AddPatient from "../AddPatient/AddPatient";
import AccountSetting from "../AccountSetting/AccountSetting";
import DoctorPrescription from "../DoctorPrescription/DoctorPrescription";
// import ReactWhatsapp from "react-whatsapp";
import { BsWhatsapp } from "react-icons/bs";
import DoctorBlog from "../DoctorBlog/DoctorBlog";
import BlogMainPage from "../BlogMainPage/BlogMainPage";
import DoctorCreateBlog from "../DoctorCreateBlog/DoctorCreateBlog";
import QuizSelect from "../Quiz/QuizSelect";
import RandomQuiz from "../Quiz/RandomQuiz";
import PatientViewForDoctor from "../PatientViewForDoctor/PatientViewForDoctor";
import QuestionnaireNew from "../Questionnaire/QuestionnaireNew";
import VideoNew from "../VideoComponent/Prebuilt";
import PatientSummary from "../PatientSummary/PatientSummary";
import DoctorExtraDetails from "../DoctorExtraDetails/DoctorExtraDetails";
import BlogHomePage from "../BlogMainPage/BlogHomePage";
import DoctorAppointmentList from "../AppointmentList/DoctorAppointmentList";
import DoctorPatientList from "../DoctorPatientList/DoctorPatientList";
import Notification from "../Notification/Notification";
import DietitionPatientList from "../DietitionPatientList/DietitionPatientList";
import DieticianAppointmentList from "../AppointmentList/DieticianAppointmentList";
import AddFood from "../AddFood/AddFood";
import Icon from "../../Images/Icon";
import axios from "axios";
import DietPrescribed from "../DietPrescribed/DietPrescribed";
import FastfoodIcon from '@mui/icons-material/Fastfood';
import TermsandConditions from "../TermsandConditions/TermsandConditions";
import PrivacyPolicy from "../TermsandConditions/PrivacyPolicy";
import AccessibilityPolicy from "../TermsandConditions/AccessibilityPolicy";
import NewNavbar from "../NewNavbar/NewNavbar";
import ContraindicationsList from "../ContraindicationsList/ContraindicationsList";
import DrugDrugInteractionList from "../ContraindicationsList/DrugDrugInteractionList";
import WarningList from "../ContraindicationsList/WarningList";
import CallNotes from "../CallNotes/CallNotes";
import CallNotesPost from "../CallNotes/CallNotesPost";
import Chat from "../Chat/ChatTest";
import Apiservice from "../../Services/Apiservice";
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  overflow: "hidden",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  overflow: "hidden",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(3.5)} + 2px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(5)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  height: 1,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));


export default function DietitianSidebar() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [openPlan, setOpenPlan] = React.useState(false);
  const navigatetwo = useNavigate();

  const [opentwo, setOPentwo] = useState(false);
  const toggletwo = () => {
    setOPentwo(!opentwo);
  };

  const [openthree, setOPenthree] = useState(false);
  const togglethree = () => {
    setOPenthree(!openthree);
  };

  const handleClick = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleClickPlan = () => {
    setOpenPlan(!openPlan);
  };
  const handleDrawerOpen = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(!drawerOpen);
  };

 

  const URL = window.location.href;

  const navigateFunction = () => {
    if (URL !== "http://localhost:3000/dietitiandashboard" && URL !== "http://localhost:3000/dietitiandashboard") {
    navigatetwo(-1);
  }
}

     // GET DITITIONIST ID
     useEffect(() => {
      Apiservice
        .get(`/user/dietitian/name/${UserInfo}/`)
  
        .then((response) => {
          const dietitian_id = response.data[0].dietitian_id;
          localStorage.setItem("dietitian_id", dietitian_id);
        })
        .catch((err) => {});
    }, []);

  const UserInfo = localStorage.getItem("Username");
  const [onlineUsers, setOnlineUsers] = useState([]);

useEffect(() => {
    const fetchOnlineUsers = async () => {
        const response = await fetch('https://djangoobesitychat.azurewebsites.net/chat/api/online_users/');
        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.status}`);
        }
        const data = await response.json();
        setOnlineUsers(data);
    };
    fetchOnlineUsers();
}, []);


  // CHECK THE USERTPYE IS DIETICIAN OR NOT
  const [authenticated, setauthenticated] = useState(null);
  useEffect(() => {
    const loggedInUser = localStorage.getItem("is_dietitian") === "true";

    if (loggedInUser) {
      setauthenticated(loggedInUser);
    }
  }, []);
  if (!authenticated) {
  } else
    return (
      <div>
        <Box sx={{ display: "flex", overflow: "hidden" }}>
          <CssBaseline />

          {/* NAVBAR COMPONENT */}
          <NewNavbar/>
          
          {/* DIETICIAN SIDEBAR CODE */}

          <Drawer
            sx={{ "& .MuiDrawer-paper": { backgroundColor: "#252b4a" } }}
            variant="permanent"
            open={drawerOpen}
          >
            <DrawerHeader>
              <IconButton
                style={{ color: "#ffffff", fontSize: 19, borderRadius: "1%" }}
                onClick={handleDrawerClose}
              >
                {theme.direction === "ltr" ? (
                  <GiHamburgerMenu />
                ) : (
                  <GiHamburgerMenu />
                )}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
              <ListItem
                key="Go Back"
                disablePadding
                sx={{ display: "block" }}
                onClick={() => navigatetwo(-1)}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 3,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 1.4,
                      justifyContent: "center",
                    }}
                  >
                    <ArrowBackIcon style={{ color: "#ffffff" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Go Back"
                    style={{ color: "#ffffff" }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem
                key="Dashboard"
                disablePadding
                sx={{ display: "block" }}
              >
                <Link to="/dietitiansidebar">
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 3,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 1.4,
                        justifyContent: "center",
                      }}
                    >
                      <DashboardSharpIcon style={{ color: "#ffffff" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Dashboard"
                      style={{ color: "#ffffff" }}
                    />
                  </ListItemButton>
                </Link>
              </ListItem>

              <ListItem
                key="patientList"
                disablePadding
                sx={{ display: "block" }}
              >
                <Link to="/dietitiansidebar/patientlist">
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 3,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 1.4,
                        justifyContent: "center",
                      }}
                    >
                      <BallotIcon style={{ fill: "#ffffff" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Patient List"
                      style={{ color: "#ffffff" }}
                    />
                  </ListItemButton>
                </Link>
              </ListItem>

              <ListItem
                key="patientList"
                disablePadding
                sx={{ display: "block" }}
              >
                <Link to="/dietitiansidebar/chat/obesity/">
                  <ListItemButton
                    onClick="window.location.href=window.location.href"
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 3,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 1.4,
                        justifyContent: "center",
                      }}
                    >
                      <MessageOutlinedIcon style={{ fill: "#ffffff" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Messenger"
                      style={{ color: "#ffffff" }}
                    />
                  </ListItemButton>
                </Link>
              </ListItem>

              <ListItem
                key="patientList"
                disablePadding
                sx={{ display: "block" }}
              >
                <Link to="/dietitiansidebar/addfood">
                  <ListItemButton
                    onClick="window.location.href=window.location.href"
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 3,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 1.4,
                        justifyContent: "center",
                      }}
                    >
                      <FastfoodIcon style={{ fill: "#ffffff" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Food"
                      style={{ color: "#ffffff" }}
                    />
                  </ListItemButton>
                </Link>
              </ListItem>

              <ListItem
                key="patientList"
                disablePadding
                sx={{ display: "block" }}
              >
                <Link to="/dietitiansidebar/blogmainpage">
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 3,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 1.4,
                        justifyContent: "center",
                      }}
                    >
                      <TextSnippetIcon style={{ fill: "#ffffff" }} />
                    </ListItemIcon>
                    <ListItemText primary="Blog" style={{ color: "#ffffff" }} />
                  </ListItemButton>
                </Link>
              </ListItem>


              {!open ? (
                <div
                  style={{ position: "relative", valign: "bottom", top: "25%" }}
                >
                  <ListItem
                    key="Privacy Policy"
                    disablePadding
                    sx={{ display: "block", valign: "bottom" }}
                  >
                    <Link to="/dietitiansidebar/privacypolicy">
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 3,
                        }}
                      >
                      <ListItemIcon
                      sx={{
                        minWidth: 20,
                        mr: open ? 3 : 1.4,
                        justifyContent: "center",
                      }}
                    ></ListItemIcon>
                        <ListItemText
                          primary="Privacy Policy"
                          style={{ color: "#ffffff" }}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>

                  <ListItem
                    key="Terms & Conditions"
                    disablePadding
                    sx={{ display: "block", bottom: "0" }}
                  >
                    <Link to="/dietitiansidebar/termsandconditions">
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 3,
                        }}
                      >
                      <ListItemIcon
                      sx={{
                        minWidth: 20,
                        mr: open ? 3 : 1.4,
                        justifyContent: "center",
                      }}
                    ></ListItemIcon>
                        <ListItemText
                          primary="Terms & Conditions"
                          style={{ color: "#ffffff" }}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>

                  <ListItem
                    key="Accessibility Policy"
                    disablePadding
                    sx={{ display: "block", bottom: "0" }}
                  >
                    <Link to="/dietitiansidebar/accessibilitypolicy">
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 3,
                        }}
                      >
                      <ListItemIcon
                      sx={{
                        minWidth: 20,
                        mr: open ? 3 : 1.4,
                        justifyContent: "center",
                      }}
                    ></ListItemIcon>
                        <ListItemText
                          primary="Accessibility Policy"
                          style={{ color: "#ffffff" }}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>
                </div>
              ) : (
                <p></p>
              )}



            </List>
          </Drawer>



          {/* DIETICIAN ROUTES PATH */}

          <Box component="main" open={open}>
            <DrawerHeader />
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/patientlist" element={<DietitionPatientList />} />
              <Route path="/dietprescribed" element={<DietPrescribed />} />
              <Route path="/addfood" element={<AddFood />} />
              <Route path="accsetting" element={<AccountSetting />} />
              <Route
                path="/personalPatientView"
                element={<PersonalPatientFile />}
              />
              <Route path="/chat/obesity/" element={<Chat user = {UserInfo} onlineUsers = {onlineUsers} />} />
              <Route path="addpatient" element={<AddPatient />} />
              <Route path="patientsummary" element={<PatientSummary />} />
              <Route path="questionnaire" element={<QuestionnaireNew />} />
              <Route
                path="patientviewfordoctor"
                element={<PatientViewForDoctor />}
              />
              <Route path = "contraindicationslist" element = {<ContraindicationsList/>} />
              <Route path = "druginteractionlist" element = {<DrugDrugInteractionList/>} />
              <Route path = "warninglist" element = {<WarningList/>} />
              <Route path="blogmainpage" element={<BlogMainPage />} />
              <Route path="blogbydoctor" element={<DoctorBlog />} />
              <Route path="callnotes" element={<CallNotes/>} />
              <Route path="callnotespost" element={<CallNotesPost/>} />
              <Route
                path="doctorappointmentlist"
                element={<DoctorAppointmentList />}
              />
              <Route path="bloghomepage" element={<BlogHomePage />} />
              <Route path="doctorpatientlist" element={<DoctorPatientList />} />
              <Route path="doctorcreateblog" element={<DoctorCreateBlog />} />
              <Route path="webcam/:username" element={<VideoNew />} />
              <Route
                path="doctorextradetails"
                element={<DoctorExtraDetails />}
              />
              <Route
                path="prescriptionbydoctor"
                element={<DoctorPrescription />}
              />
              <Route path="quiz" element={<QuizSelect />} />
              <Route path="/r/Anxiety" element={<RandomQuiz />} />
              <Route
              path="/termsandconditions"
              element={<TermsandConditions />}
            />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route
              path="/accessibilitypolicy"
              element={<AccessibilityPolicy />}
            />
            <Route path = "/dietitianappointmentlist" element = {<DieticianAppointmentList />} />

            </Routes>
          </Box>
        </Box>
      </div>
    );
}
