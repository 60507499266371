import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PatientLogo from "../../Images/ClinicSVG/newdoctor.svg";
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";
import { TableHead } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import Apiservice from "../../Services/Apiservice";

const DoctorPatientList = () => {
  const [loading, setLoading] = useState(true);
  const DoctorName = localStorage.getItem("Username");
  const [open, setOpen] = React.useState(false);
  const [mypatients, setmypatients] = useState([]);

  useEffect(() => {
    Apiservice
      .get(`/patientresponse/${DoctorName}/`, {})

      .then((response) => {
        setmypatients(response.data);
        setLoading(false);
      })
      .catch((err) => {});
  }, []);
  const rowsOne = [mypatients.revisit_data_list];
  const rowsThree = [mypatients.my_patients];
  // Create a new list that combines the revisit_data_list and my_patients data
  const combinedData =
    mypatients.revisit_data_list &&
    mypatients.revisit_data_list.map((revisitItem) => {
      const myPatientsItem =
        mypatients.my_patients &&
        mypatients.my_patients.find(
          (item) => item && item.patient_id2_id === revisitItem.patient_id
        );
      return myPatientsItem && { ...revisitItem, ...myPatientsItem };
    });

  const handleClick = (key) => {
    localStorage.setItem("PatientUsernameforSummary", key);
  };
  return (
    <div style={{ padding: "20px" }}>
      <div className="second-para-div--n-docs" style={{ paddingTop: "0px" }}>
        <div className="acc-setting-main-div">
          <div className="update-account-title-div">
            <h2>Patient List</h2>
          </div>
        </div>
      </div>
      <TableContainer component={Paper}>
        {loading ? (
          <Loader />
        ) : (
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Logo</TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left">Patient ID</TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Contact</TableCell>
                <TableCell align="left">Gender</TableCell>
                <TableCell align="left">Age</TableCell>
                <TableCell align="left">Date of Birth</TableCell>
                <TableCell align="left">Patient Type</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {combinedData &&
                combinedData.map((row) => (
                  <React.Fragment>
                    <TableRow
                      key={row && row.name}
                      id={row && row.patient_id}
                      onClick={() =>
                        handleClick(row && row.patient_data[0]["username"])
                      }
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center">
                        <img
                          src={PatientLogo}
                          alt=""
                          style={{ height: "30px", width: "30px" }}
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => setOpen(!open)}
                        >
                          {open ? <RemoveIcon /> : <AddIcon />}
                        </IconButton>
                      </TableCell>

                      <TableCell align="left" style={{ padding: "10px" }}>
                        <div style={{ display: "flex", gap: "2px" }}>
                          <Link
                            to="/dashboard/patientsummary"
                            style={{ textDecoration: "none" }}
                          >
                            <h3
                              style={{
                                fontSize: "14px",
                                cursor: "pointer",
                                gap: "3px",
                              }}
                            >
                              {row && row.patient_id2_id}
                            </h3>
                          </Link>
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        {row && row.patient_data[0]["firstName"]}{" "}
                        {row && row.patient_data[0]["lastName"]}
                      </TableCell>
                      <TableCell align="left">
                        {row && row.patient_data[0]["mobile"]}
                      </TableCell>
                      <TableCell align="left">
                        {row && row.patient_data[0]["gender"]}
                      </TableCell>
                      <TableCell align="left">
                        {row && row.patient_data[0]["age"]}
                      </TableCell>

                      <TableCell align="left" style={{ padding: "10px" }}>
                        {row && row.patient_data[0]["date_of_birth"]}
                      </TableCell>
                      <TableCell align="left" style={{ padding: "10px" }}>
                        {row && row.revisit_data}
                      </TableCell>
                    </TableRow>

                    <TableRow key={row && row.name}
                    id={row && row.patient_id}>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={12}
                      >
                        <Collapse in={open} timeout="auto" unmountOnExit>
                          <div className="coll-table-main-div">
                            <div className="coll-table-id-div">
                              <h3>ID : </h3>

                              <p>#</p>
                            </div>

                            <div className="coll-table-main-sub-div">
                              <div className="coll-table-sub-div-one">
                                <div className="coll-table-sub-one-section-one">
                                  <h3>Gender:</h3> <p></p>
                                </div>
                                <div className="coll-table-sub-one-section-one">
                                  <h3>Weight Beginning:</h3> <p></p>
                                </div>
                                <div className="coll-table-sub-one-section-one">
                                  <h3>% Match of phenotype:</h3> <p></p>
                                </div>
                              </div>
                              <div className="coll-table-sub-div-one">
                                <div className="coll-table-sub-one-section-one">
                                  <h3>Date of Birth:</h3> <p></p>
                                </div>
                                <div className="coll-table-sub-one-section-one">
                                  <h3>Progress % according to the plan:</h3>{" "}
                                  <p></p>
                                </div>
                                <div className="coll-table-sub-one-section-one">
                                  <h3>Background Diseases:</h3> <p></p>
                                </div>
                              </div>
                              <div className="coll-table-sub-div-one">
                                <div className="coll-table-sub-one-section-one">
                                  <h3>Patient Questionnaire:</h3>
                                  <Link to="/dashboard/questionnaire">
                                    <p
                                      style={{
                                        cursor: "pointer",
                                        color: "rgb(49,163,214)",
                                      }}
                                    >
                                      Click here
                                    </p>
                                  </Link>
                                </div>
                                <div className="coll-table-sub-one-section-one">
                                  <h3>Patient Summary:</h3>
                                  <Link to="/dashboard/patientviewfordoctor">
                                    <p
                                      style={{
                                        cursor: "pointer",
                                        color: "rgb(49,163,214)",
                                      }}
                                    >
                                      Click here
                                    </p>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </div>
  );
};

export default DoctorPatientList;
