
import React from "react";
import { useState } from "react";
import MarketingMailbyClinicSubClinic from "../MarketingMailbyClinicSubClinic/MarketingMailbyClinicSubClinic";
import SelectedUserMail from "../SelectedUserMail/SelectedUserMail";


const MailHomePage = () => {
  const [doctor, setDoctor] = useState(true);
  const [Dititionist, setDititionist] = useState(false);
  const [Trainer, setTrainer] = useState(false);
  const [isdoctoractive, setIsdoctoractive] = useState(true);
  const [isDititionistactive, setIsDititionistactive] = useState(false);
  const [isTraineractive, setIsTraineractive] = useState(false);
  const is_subclinic = localStorage.getItem("is_subclinic");

  const DoctorHandler = () => {
    setDoctor(true);
    setIsdoctoractive(true);
    setDititionist(false);
    setIsDititionistactive(false);
    setTrainer(false);
    setIsTraineractive(false);
  };

  const DititionistHandler = () => {
    setDoctor(false);
    setIsdoctoractive(false);
    setDititionist(true);
    setIsDititionistactive(true);
    setTrainer(false);
    setIsTraineractive(false);
  };

  const TrainerHandler = () => {
    setDoctor(false);
    setIsdoctoractive(false);
    setDititionist(false);
    setIsDititionistactive(false);
    setTrainer(true);
    setIsTraineractive(true);
  };

  return (
    <div className="appt-by-clinic-homepage-flex-div">
      <div className="appt-by-clinic-homepage-width-div">
        <div className="appt-by-clinic-homepage-main-div">
          <div
            style={{ width: "100%" }}
            className={
              isdoctoractive
                ? "new-que-home-div-sub-one-active"
                : "new-que-home-div-sub-one"
            }
            onClick={DoctorHandler}
          >
            Marketing Mail
          </div>
          <div
            style={{ width: "100%" }}
            className={
              isDititionistactive
                ? "new-que-home-div-sub-one-active"
                : "new-que-home-div-sub-one"
            }
            onClick={DititionistHandler}
          >
            Personal Mail
          </div>

         {/*<div
            style={{ width: "100%" }}
            className={
              isTraineractive
                ? "new-que-home-div-sub-one-active"
                : "new-que-home-div-sub-one"
            }
            onClick={TrainerHandler}
          >
            Appointment for Trainer
        </div>*/}
        </div>
        <div>
         
            <div>
              {doctor && <MarketingMailbyClinicSubClinic />}
              {Dititionist && <SelectedUserMail/>}
            </div>
        
        </div>
      </div>
    </div>
  );
};

export default MailHomePage;
