import React, {useState, useEffect} from 'react';
import axios from "axios";
import { DataGrid } from '@mui/x-data-grid';
import { Container, Typography, Button, RadioGroup, FormControlLabel, Checkbox, } from '@mui/material';


const OptQues = () => {
    const [data, setData] = useState()

    const columns = [
        {
            field: "title",
            headerName: "Questionnaire",
            width: 150,
            editable: true,
          },
    ]

    useEffect(() => {
        axios
          .get(`https://djangoobesitychat.azurewebsites.net/q/Anxiety/`)
    
          .then((response) => {
            setData(response.data);
          })
          .catch((err) => {});
      }, []);
    
    // const d = data[0]
    // // //console.log(data)
  return (
    <div>
        
        <Container component="main" maxWidth="xs">
       
          
        
       </Container>
    </div>
  )
}

export default OptQues