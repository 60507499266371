import React from "react";
import { Link } from "react-router-dom";

const BlogCard = (props) => {
  return (
    <div className="blog-main-page-sub-card">
      <div className="blog-sub-card-img">
        <img src={props.imageurl} alt="" className="blog-sub-card-img" />
      </div>
      <div className="blog-sub-card-text-div">
        <div className="blog-sub-card-date">
          <p>November 25,2022</p>
        </div>
        <div className="blog-sub-card-header">
          <h3>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
            sit amet ultrices metus, aliquam tincidunt massa.{" "}
          </h3>
        </div>
        <div className="blog-sub-card-para">
          <p>
            Praesent at tortor vel nulla rutrum consequat eu sit amet dui.
            Mauris ut metus ut dolor pretium aliquam. Mauris id nulla a arcu
            iaculis aliquet. Integer ac tempus est. Proin feugiat metus nunc, in
            viverra libero varius at.
          </p>
        </div>
        <Link to="/patientDashboard/blogbydoctor">
          <div className="add-pat-btn-div">
            <button className="read-more-btn">Read More</button>
          </div>
        </Link>
      </div>
    </div>
  );
};

const PatientBlogMainPage = () => {
  return (
    <div className="blog-main-page-flex-div">
      <div className="blog-main-page-width-div">
        <div className="blog-main-page-main-div-one">
          <h2>The Blog</h2>
        </div>

        <div className="blog-main-page-main-div">
          <div className="blog-main-page-sub-div-one">
            <img
              src="https://wallpaperaccess.com/full/1561053.jpg"
              alt=""
              className="blog-main-page-main-imag"
            />
          </div>
          <div className="blog-main-page-sub-div-two">
            <div className="blog-main-page-sub-div-two-for-date">
              <p>December 12,2022</p>
            </div>
            <div className="blog-main-page-sub-div-two-for-header">
              <h3>
                Neque porro quisquam est qui dolorem ipsum quia dolor sit amet,
                consectetur, adipisci velit...
              </h3>
            </div>
            <div className="blog-main-page-sub-div-two-for-para">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur sit amet ultrices metus, aliquam tincidunt massa. Sed
                cursus purus ex, eu blandit magna facilisis at. Morbi elementum
                quam non magna rhoncus pharetra. Aliquam sit amet egestas diam.
                Nulla ac ipsum risus. Donec rhoncus tellus ac euismod finibus.
              </p>
            </div>
          </div>
        </div>
        <div className="blog-main-page-main-div-two">
          <BlogCard imageurl="https://st2.depositphotos.com/4924139/10750/i/950/depositphotos_107509340-stock-photo-high-resolution-3d-render-of.jpg" />
          <BlogCard imageurl="https://wallpaperaccess.com/full/1561052.jpg" />
          <BlogCard imageurl="https://www.hdwallpapers.net/previews/dna-molecular-model-480.jpg" />
        </div>
      </div>
    </div>
  );
};

export default PatientBlogMainPage;
