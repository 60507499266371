import React, { useEffect } from "react";
import "./NewNavbar.css";
import { BsWhatsapp } from "react-icons/bs";
import { GiDirectionSign } from "react-icons/gi";
import { BsBell } from "react-icons/bs";
import { FaAngleDown } from "react-icons/fa";
import Icon from "../../Images/Icon";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import NotificationComponent from "../Notification/NotificationNew";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import axios from "axios";
import Apiservice from "../../Services/Apiservice";
import CloseIcon from '@mui/icons-material/Close';

const NewNavbar = ( open, handleDrawerOpen ) => {

  const is_patient = localStorage.getItem("is_patient")
  const is_doctor = localStorage.getItem("is_doctor")
  const is_clinic = localStorage.getItem("is_clinic")
  const is_subclinic = localStorage.getItem("is_subclinic")
  const is_dietitian = localStorage.getItem("is_dietitian")
  const is_trainer = localStorage.getItem("is_trainer")
  const is_receptionist = localStorage.getItem("is_receptionist")
  const username = localStorage.getItem("Username");
  // Use `open` to adjust styles or classes based on the state of the drawer
  // e.g. add padding to the `main` element when the drawer is open
  

  const [opentwo, setOPentwo] = useState(false);
  const toggletwo = () => {
    setOPentwo(!opentwo);
  };

  const [openthree, setOPenthree] = useState(false);
  const togglethree = () => {
    setOPenthree(!openthree);
  };

  const closeNotification = () =>{
    setOPenthree(false);

  }
  const navigate = useNavigate();

  function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i].trim();
          if (cookie.substring(0, name.length + 1) === (name + '=')) {
            cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
            break;
          }
        }
    }
    return cookieValue;
}

  const logout = () => {
    const username = localStorage.getItem("Username");
    var csrfToken = getCookie('csrftoken');
    
    axios.delete(`https://djangoobesitychat.azurewebsites.net/chat/api/online_users/update/${username}/`, {
      headers : {
        // 'X-CSRFToken': csrfToken,
        Authorization: `Token ${localStorage.getItem("SavedToken")}`,
      },
      username: username,
        })
        .then((response) => {
          //console.log("response", response);
        })
        .catch((error) => {
          //console.log("error", error);
        });

    localStorage.clear();
    navigate("/");
  };

  const UserInfo = localStorage.getItem("Username");
  const [socket, setSocket] = useState(null);
  const [notification, setNotification] = useState([]);
  const [client, setClient] = useState(null);
  

  useEffect(() => {
    const clients = new W3CWebSocket(
      // `wss://djangoobesitychat.azurewebsites.net/ws/notifications/?auth_token=${UserInfo}`
      `wss://djangoobesitychat.azurewebsites.net/ws/notifications/?auth_token=${UserInfo}`
    );
    setClient(clients);

    clients.onopen = () => {
      const data = {
        read: true, // Set the field status to true
      };
      clients.send("Hello from client");
    };

    clients.onmessage = (event) => {
      const data = JSON.parse(event.data);
      // //console.log("data", data?.data.activity_type);
      const d = data?.data;

      setNotification(d)
    };

    clients.onclose = () => {
      clients.close();
      //console.log("WebSocket closed");
    };

    window.addEventListener("beforeunload", () => {
      clients.close();
    });

    setSocket(clients);

    return () => {
      window.removeEventListener("beforeunload", () => {
      clients.close();
    });
    clients.close();
    };
  }, []);

  const Total = notification&&notification.length == undefined ? 0 : notification&&notification.length;
  //console.log("Total notification ===", Total);
  //console.log("notification ===", notification);

  const [LoginUserDetails,setLoginUserDetails]=useState("")
  useEffect(()=>{
    Apiservice.get(`/current/user/details/${UserInfo}/`)
    .then((response)=>{
      setLoginUserDetails(response.data)
    })
  },[UserInfo])
  const UserImagePath = LoginUserDetails&&LoginUserDetails.image
  const spliturltwo = UserImagePath && UserImagePath.slice(6);
  console.log("spliturltwo", spliturltwo);


  return (
    username === "demoSuperUser" ? (
      <div className="navbar-flex-div">
        <div className="navbar-width-div">
          <div className="navbar-main-div">
          <div className="navbar-sub-div-two">
          <div style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "40px",
              }} >
              <Link to="/clinicsidebar">
              <p> Clinic Dashboard</p>
              </Link>
            </div>
            <div style={{
                display: "flex",
                
                
              }} >
              <Link to="/patientdashboard">
              <p> Patient Dashboard</p>
              </Link>
            </div>
            <div style={{
                display: "flex",
               
                
              }} >
              <Link to="/dashboard">
              <p> Doctor Dashboard</p>
              </Link>
            </div>
            </div>
            <div
              className="navbar-sub-div-one"
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "40px",
              }}
            >
              <Icon />
            </div>
            <div className="navbar-sub-div-two">
              <div className="nav-symb-div">
                <a href="https://web.whatsapp.com/" target="_blank">
                  <BsWhatsapp
                    style={{
                      height: "20px",
                      width: "20px",
                      color: "rgb(188,190,90)",
                    }}
                  />
                </a>
              </div>
              {/* <div className="nav-symb-div">
                <GiDirectionSign
                  style={{
                    height: "20px",
                    width: "20px",
                    color: "rgb(0,138,201)",
                    cursor: "pointer",
                  }}
                />
              </div> */}
              <div className="nav-symb-div">
                <div onClick={togglethree} style={{ cursor: "pointer" }}>
                  <BsBell
                    style={{
                      height: "20px",
                      width: "20px",
                      color: "rgb(0,138,201)",
                      // cursor: "pointer",
                      position: "relative",
                    }}
                  />
    
                  <div className="notification-circle-main-div">
                    <p
                      className="notification-count-text"
                      style={{ padding: 4, fontSize: "10px" }}
                    >
                      {Total}
                    </p>
                  </div>
                </div>
    
                {openthree && (
                  <div className="purchase-order-details-pop-up-main-div">
                    <div className="notification-main-window-div">
                      <div className="notification-main-window">
                        <div
                          onClick={closeNotification}
                          className="notification-main-flex-div-for-close"
                        >
                          <p className="close-notification-p-tag">
                            Close Notifications
                          </p>
                        </div>
                        <NotificationComponent />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="user-img-in-nav">
                <div>
                  <img
                    // src="https://img.freepik.com/premium-vector/user-icon_126283-435.jpg?w=2000"
                    // src={`https://djangoobesitychat.azurewebsites.net/static/uploads/profile_pic/download_1_F9zqbsm.jpg`}
                    src={`https://djangoobesitychat.azurewebsites.net${spliturltwo}`}
                    className="user-img-tag-in-nav-bar"
                  />
                </div>
                <div className="dropdown" onClick={toggletwo}>
                  <FaAngleDown
                    style={{ color: "rgb(0,138,201)", cursor: "pointer" }}
                  />
                  <span></span>
                  {opentwo && (
                    <div>
                      <div className="dropdown-content" onClick={toggletwo}>
                        <p>{UserInfo}</p>
                        <Link
                          to={
                            is_patient == "true"
                              ? "/patientDashboard/accsetting"
                              : is_doctor == "true"
                              ? "/dashboard/accsetting"
                              : is_clinic == "true"
                              ? "/clinicsidebar/accsetting"
                              : is_subclinic == "true"
                              ? "/subclinicsidebar/accsetting"
                              : is_dietitian == "true"
                              ? "/dietitiansidebar/accsetting"
                              : is_trainer == "true"
                              ? "/trainersidebar/accsetting"
                              : ""
                          }
                        >
                          <p style={{ color: "black" }}>Account Setting</p>
                        </Link>
                        <p onClick={logout}>Log Out!!!</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    ) : (
      <div className="navbar-flex-div">
        <div className="navbar-width-div">
          <div className="navbar-main-div">
            <div
              className="navbar-sub-div-one"
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "40px",
              }}
            >
              <Icon />
            </div>
            <div className="navbar-sub-div-two">
              <div className="nav-symb-div">
                <a href="https://web.whatsapp.com/" target="_blank">
                  <BsWhatsapp
                    style={{
                      height: "20px",
                      width: "20px",
                      color: "rgb(188,190,90)",
                    }}
                  />
                </a>
              </div>
              {/* <div className="nav-symb-div">
                <GiDirectionSign
                  style={{
                    height: "20px",
                    width: "20px",
                    color: "rgb(0,138,201)",
                    cursor: "pointer",
                  }}
                />
              </div> */}
              <div className="nav-symb-div">
                <div onClick={togglethree} style={{ cursor: "pointer" }}>
                  <BsBell
                    style={{
                      height: "20px",
                      width: "20px",
                      color: "rgb(0,138,201)",
                      // cursor: "pointer",
                      position: "relative",
                    }}
                  />
    
                    {notification&&notification?.length > 0 ? (
                  <div className="notification-circle-main-div">
                    <p
                      className="notification-count-text"
                      style={{ padding: 4, fontSize: "10px" }}
                    >
                      {Total}
                    </p>
                  </div>
                ) : null}
                </div>
    
                {openthree && (
                  <div className="purchase-order-details-pop-up-main-div">
                    <div className="notification-main-window-div">
                      <div className="notification-main-window">
                        <div
                          onClick={closeNotification}
                          className="notification-main-flex-div-for-close"
                        >
                          <p className="close-notification-p-tag">
                            <CloseIcon style={{color: "red", marginLeft: "16.7em"}} />
                          </p>
                        </div>
                        <NotificationComponent />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="user-img-in-nav">
                <div>
                  <img
                    // src="https://img.freepik.com/premium-vector/user-icon_126283-435.jpg?w=2000"
                    // src={`https://djangoobesitychat.azurewebsites.net/static/uploads/profile_pic/download_1_F9zqbsm.jpg`}
                    src={`https://djangoobesitychat.azurewebsites.net${spliturltwo}`}
                    className="user-img-tag-in-nav-bar"
                  />
                </div>
                <div className="dropdown" onClick={toggletwo}>
                  <FaAngleDown
                    style={{ color: "rgb(0,138,201)", cursor: "pointer" }}
                  />
                  <span></span>
                  {opentwo && (
                    <div>
                      <div className="dropdown-content" onClick={toggletwo}>
                        <p>{UserInfo}</p>
                        <Link
                          to={
                            is_patient == "true"
                              ? "/patientDashboard/accsetting"
                              : is_doctor == "true"
                              ? "/dashboard/accsetting"
                              : is_clinic == "true"
                              ? "/clinicsidebar/accsetting"
                              : is_subclinic == "true"
                              ? "/subclinicsidebar/accsetting"
                              : is_dietitian == "true"
                              ? "/dietitiansidebar/accsetting"
                              : is_trainer == "true"
                              ? "/trainersidebar/accsetting"
                              : ""
                          }
                        >
                          <p style={{ color: "black" }}>Account Setting</p>
                        </Link>
                        <p onClick={logout}>Log Out!!!</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};


export default NewNavbar;
