import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from "react";
import "./DoctorPrescription.css";
import { FaFilter } from "react-icons/fa";
import { AiFillAppstore } from "react-icons/ai";
import Add from "@mui/icons-material/Add";
import axios from "axios";
import swal from "sweetalert";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import Loader from "../Loader/Loader";

import { WiSunrise } from "react-icons/wi";
import { BiSun } from "react-icons/bi";
import { BsSunset, BsMoonStars } from "react-icons/bs";
import Apiservice from "../../Services/Apiservice";

const DoctorPrescription = () => {
  // Search Medicine
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  useEffect(() => {
    const loadPosts = async () => {
      setLoading(true);
      const response = await Apiservice.get("/medicineapi/");
      setPosts(response.data);
      // // //console.log(response.data);
      setLoading(false);
    };
    loadPosts();
  }, []);

  //   GET MEDICINE DETAILS

  const MedicineIDtoGetDetails = localStorage.getItem("MedicineID");
  const [medicinedetailsindoctor, setMedicinedetails] = useState([]);
  const [response, setresponse] = useState();

  useEffect(() => {
    Apiservice
      .get(`/medicinebyidapi/${MedicineIDtoGetDetails}/`)

      .then((response) => {
        setMedicinedetails(response.data[0]);
        // // //console.log("MedicinebyID", response.data);
      })
      .catch((err) => {});
  }, [MedicineIDtoGetDetails]);

  const [opentwo, setOPentwo] = useState(false);
  const toggletwo = () => {
    setOPentwo(!opentwo);
  };

  const patientIDforPrescription = localStorage.getItem("patientUserName");
  const DoctorNameforPrescription = localStorage.getItem("id");
  const [morning, setmorning] = useState("");
  const [afternoon, setafternoon] = useState("");
  const [evening, setevening] = useState("");
  const [nignt, setnignt] = useState("");
  const [comment, setcomment] = useState("");
  const [dosage, setdosage] = useState("");
  const [duration, setduration] = useState("");

  // POST MEDICINE
  const CreateAppointment = async (e) => {
    e.preventDefault();
    await Apiservice
      .post(
        `/prescriptionapi/user/${patientIDforPrescription}/`,

        {
          patient_name: patientIDforPrescription,
          doctor_id: DoctorNameforPrescription,
          medicine_name: medicinedetailsindoctor.medicine_name,
          dosage: dosage,
          duration: duration,
          morning: morning,
          afternoon: afternoon,
          evening: evening,
          night: nignt,
          comment: comment,
        }
      )
      .then((res) => {
        swal({
          title: "Medicine to the Patient Prescription Added Successfully",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });
        fetch(
          `https://djangoobesitychat.azurewebsites.net/prescriptionapi/user/${patientIDforPrescription}/`
        )
          .then((data) => data.json())
          .then((data) => setTableData(data));
      })
      .catch((res) => {
        // // //console.log("err", res.response.data);
        setresponse(res.response.data);
      });
  };

  // MEDICINE TABLE GET
  function ImageCellRenderer(params) {
    const imageUrl = params.value;
    return <img style={{ height: "3em" }} src={imageUrl} alt="Cell" />;
  }
  const gridRef = useRef();
  const [columnDefs, setColumnDefs] = useState([
    { field: "prescription_id", filter: true, headerName: "ID" },
    { field: "medicine_name", filter: true, headerName: "Medicine Name" },
    { field: "dosage", headerName: "Dosage" },
    { field: "comment", headerName: "Comment" },
    { field: "doctor_id", headerName: "Doctor ID" },
  ]);
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    resizable: true,
    suppressToolPanel: true,
  }));

  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    fetch(
      `https://djangoobesitychat.azurewebsites.net/prescriptionapi/user/${patientIDforPrescription}/`
    )
      .then((data) => data.json())
      .then((data) => setTableData(data))
      .then(setLoading(false));
  }, []);


  const[prescribedMedicine,setprescribedMedicine]=useState([])

  useEffect(() => {
    Apiservice
      .get(`/prescriptionapi/user/${patientIDforPrescription}/`)
      .then((response) => {
        setprescribedMedicine(response.data);
        // // //console.log("prescribedMedicine", response.data);
      })
      .catch((error) => {
        // // //console.log(error);
      });
  }, [patientIDforPrescription]);

//   const morningmed = prescribedMedicine&&prescribedMedicine.map((index)=>(
//      index.morning
//   ))

//  const truemorning = morningmed == true
//   // // //console.log("one",truemorning)


//   // // //console.log("morningmed",morningmed)

//   const data =[]
//   if(morningmed==true){
//     data.push("one")
//   }
//   if(morningmed=="null"){
//     data.push("zero")
//   }
//   // // //console.log("data",data)

  return (
    <div>
      {/* 
    <div>
      <div className="paient-list-top-main-flex-div">
        <div className="paient-list-top-main-width-div">
          <div className="paient-list-top-main-div">
            <div className="table-page-middle-nav-main-div">
              <div>
                <p style={{ fontWeight: "600", fontSize: "18px" }}>
                  Prescriptions
                </p>
              </div>

              <div>
                <input
                  className="pateint-search-input-tag"
                  type="text"
                  placeholder="Search Medicine..."
                  onClick={toggletwo}
                  onChange={(e) => setSearchTitle(e.target.value)}
                />
                {opentwo && (
                  <div className="list-of-all-user" onClick={toggletwo}>
                    {loading ? (
                      <div style={{ display: "none" }}></div>
                    ) : (
                      posts
                        .filter((value) => {
                          if (searchTitle === "") {
                          } else if (
                            value.medicine_name
                              ?.toLowerCase()
                              .includes(searchTitle?.toLowerCase())
                          ) {
                            return value;
                          }
                        })
                        .map((item) => (
                          <div className="list-of-user-main-flex-div">
                            <div className="list-of-user-main-div">
                              <p
                                onClick={() =>
                                  localStorage.setItem("MedicineID", item.id)
                                }
                              >
                                {item.medicine_name}
                              </p>
                            </div>
                          </div>
                        ))
                    )}
                  </div>
                )}
              </div>

              <div className="table-pg-middle-nav-scnd-div">
                <p>Filter</p>
                <FaFilter style={{ cursor: "pointer" }} />
                <AiFillAppstore
                  style={{
                    height: "35px",
                    width: "35px",
                    color: "rgb(49,163,214)",
                    cursor: "pointer",
                  }}
                />
                <p>Previous</p>
                <p
                  style={{
                    borderLeft: "1px solid black",
                    paddingLeft: "5px",
                    color: "rgb(49,163,214)",
                  }}
                >
                  Next
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="doctor-prescr-flex-div">
        <div className="doctor-prescr-width-div">
          <div className="doctor-prescr-main-div">
            <div className="doctor-prescr-sub-div-one" style={{ width: "15%" }}>
              <h3>Medicine</h3>
            </div>
            <div className="doctor-prescr-sub-div-two" style={{ width: "15%" }}>
              <h3>Dosage</h3>
            </div>
            <div
              className="doctor-prescr-sub-div-three"
              style={{ width: "30%" }}
            >
              <h3>Frequency</h3>
            </div>
            <div
              className="doctor-prescr-sub-div-four"
              style={{ width: "20%" }}
            >
              <h3>Duration</h3>
            </div>
            <div
              className="doctor-prescr-sub-div-four"
              style={{ width: "20%" }}
            >
              <h3>Comment</h3>
            </div>
          </div>

          <div className="doctor-prescr-main-div">
            <div className="doctor-prescr-sub-div-one" style={{ width: "15%" }}>
              <p>{medicinedetailsindoctor.medicine_name}</p>
            </div>
            <div
              className="doctor-prescr-sub-div-two"
              style={{
                width: "15%",
                display: "flex",
                gap: "3px",
                justifyContent: "center",
              }}
            >
              <input
                type={"text"}
                placeholder="Dosage"
                value={dosage}
                onChange={(e) => setdosage(e.target.value)}
                style={{ width: "100px" }}
              />
              <p>ml</p>
            </div>
            <div
              className="doctor-prescr-sub-div-three"
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "30%",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", gap: "3px" }}>
                <input
                  type="checkbox"
                  value={"true"}
                  onChange={(e) => setmorning(e.target.value)}
                />{" "}
                <p>Morning</p>
              </div>
              <div style={{ display: "flex", gap: "3px" }}>
                <input
                  type="checkbox"
                  value={"true"}
                  onChange={(e) => setafternoon(e.target.value)}
                />{" "}
                <p>Afternoon</p>
              </div>
              <div style={{ display: "flex", gap: "3px" }}>
                <input
                  value={"true"}
                  onChange={(e) => setevening(e.target.value)}
                  type="checkbox"
                />{" "}
                <p>Evening</p>
              </div>
              <div style={{ display: "flex", gap: "3px" }}>
                <input
                  type="checkbox"
                  value={"true"}
                  onChange={(e) => setnignt(e.target.value)}
                />{" "}
                <p>Night</p>
              </div>
            </div>

            
            <div
              className="doctor-prescr-sub-div-four"
              style={{
                gap: "5px",
                justifyContent: "center",
                width: "20%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                }}
              >
                <input
                  type={"number"}
                  placeholder="days"
                  value={duration}
                  onChange={(e) => setduration(e.target.value)}
                />
                <p>Days</p>
              </div>
              <div className="error-message-preview">
                <p>{response && response.duration}</p>
              </div>
            </div>

            <div className="doctor-prescr-sub-div-two" style={{ width: "20%" }}>
              <input
                type={"text"}
                placeholder="Comment"
                value={comment}
                onChange={(e) => setcomment(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="add-pat-btn-div">
        <button className="add-btn-btn-tag" onClick={CreateAppointment}>
          <Add style={{ height: "20px", width: "20px" }} />
          SUBMIT PRESCRIPTION
        </button>
      </div>

      {loading ? (
        <Loader />
      ) : (
        <div
          className="ag-theme-material"
          style={{
            width: "100%",
            height: "90vh",
            boxShadow: "rgb(0 0 0 / 35%) 0px 5px 15px",
            marginTop: "20px",
          }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={tableData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            animateRows={true}
            rowSelection="multiple"
          />
        </div>
      )}
    </div>
    */}
      <div className="prescription-by-doctor-flex-div">
        <div className="prescription-by-doctor-width-div">
          <div className="prescription-by-doctor-main-div">

          {prescribedMedicine&&prescribedMedicine.map((index) => (
            <div className="prescription-card-dlex-div">
              <div className="prescription-card-days-div">
                <div className="prescription-card-days-sub-div">
                  <h3>{index.duration}</h3>
                  <h5>Days</h5>
                </div>
              </div>
              <div className="prescription-details-block-div">
                <div className="medicine-details-flex-div">
                  <h3>{index.medicine_name}</h3>
                  <p>{index.dosage} mg</p>
                </div>
                <div className="medicine-details-flex-div">
                  <div className="prescription-time-flex-div">
                    <div>
                      <WiSunrise
                        className="presc-time-icon"
                        style={{ height: "45px", width: "50px" }}
                      />
                    </div>
                    <div>
                      <h3 style={{ fontSize: "22px" }}>0</h3>
                    </div>
                  </div>

                  <div className="prescription-time-flex-div">
                    <div>
                      <BiSun className="presc-time-icon" />
                    </div>
                    <div>
                      <h3 style={{ fontSize: "22px" }}>0</h3>
                    </div>
                  </div>

                  <div className="prescription-time-flex-div">
                    <div>
                      <BsSunset className="presc-time-icon" />
                    </div>
                    <div>
                      <h3 style={{ fontSize: "22px" }}>0</h3>
                    </div>
                  </div>

                  <div className="prescription-time-flex-div">
                    <div>
                      <BsMoonStars
                        className="presc-time-icon"
                        style={{ width: "30px" }}
                      />
                    </div>
                    <div>
                      <h3 style={{ fontSize: "22px" }}>0</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="prescription-comment-flex-div">
                <p>{index.comment}</p>
              </div>
            </div>
          ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorPrescription;
