import * as React from "react";
import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaFilter } from "react-icons/fa";
import { AiFillAppstore } from "react-icons/ai";
import Add from "@mui/icons-material/Add";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";

const SubClinicList = () => {
  // GET SUB CLINIC DETAILS
  const [patientdetails, setpatientdetails] = useState([]);
  const savedtoken = localStorage.getItem("SavedToken");
  const clinic = localStorage.getItem("is_clinic");

  const [loading, setLoading] = useState(true);
  const clinicIDtogetSubClinic = localStorage.getItem("ClinicIDForHomepage");

  useEffect(() => {
    axios
      .get(
        `https://djangoobesitychat.azurewebsites.net/user/subclinicapi/clinicid/${clinicIDtogetSubClinic}/`,
        {
          headers: {
            Authorization: `Token ${savedtoken}`,
          },
        }
      )

      .then((response) => {
        setpatientdetails(response.data);
        // // //console.log("SubCLinic List", response.data);
        setLoading(false);
      })
      .catch((err) => {});
  }, []);
  if (!patientdetails) return null;

  const Row = (props) => {
    const { row } = props;

    const SaveID = () => {
      localStorage.setItem("SubClinicIDForHomepage", row.subclinic_id);
      localStorage.setItem("SubCllinicNametoRedirect", row.subclinic_name);
    };

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }} onClick={SaveID}>
          <Link to="/clinicsidebar/subclinicdetails">
            <TableCell align="center" style={{ cursor: "pointer" }}>
              {row.subclinic_id}
            </TableCell>
          </Link>

          <TableCell align="center">{row.subclinic_firstName}</TableCell>
          <TableCell align="center">{row.city}</TableCell>
          <TableCell align="center">{row.subclinic_email}</TableCell>
          <TableCell align="center">{row.subclinic_mobile}</TableCell>
          <TableCell align="center">{row.subclinic_address}</TableCell>
          <TableCell align="center">{row.opentime}</TableCell>
          <TableCell align="center">{row.closetime}</TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  return (
    <div>
      <div>
        <div className="paient-list-top-main-flex-div">
          <div className="paient-list-top-main-width-div">
            <div className="paient-list-top-main-div" style={{display:"flex",justifyContent:"space-between",alignItems:"center",paddingBottom:"20px"}}>
              <div className="page-header-main-div">
                <p>Sub Clinic List</p>
              </div>

              <div className="table-page-middle-nav-main-div" style={{paddingBottom:"0px"}}>
                <div>
                  <Link to="/clinicsidebar/subclinicregistration">
                    <button className="add-btn-btn-tag">
                      <Add style={{ height: "20px", width: "20px" }} />
                      Add New Subclinic
                    </button>
                  </Link>
                </div>

              </div>
            </div>
          </div>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <div>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell component="th" scope="row" align="center">
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <p>ID</p>{" "}
                        <BsThreeDotsVertical
                          style={{
                            cursor: "pointer",
                            color: "rgb(49,163,214)",
                          }}
                        />
                      </div>
                    </TableCell>

                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <p>Sub Clinic Name</p>
                        <BsThreeDotsVertical
                          style={{
                            cursor: "pointer",
                            color: "rgb(49,163,214)",
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <p>Sub Clinic City</p>{" "}
                        <BsThreeDotsVertical
                          style={{
                            cursor: "pointer",
                            color: "rgb(49,163,214)",
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <p>Sub Clinic Email</p>{" "}
                        <BsThreeDotsVertical
                          style={{
                            cursor: "pointer",
                            color: "rgb(49,163,214)",
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <p>Sub Clinic Contact</p>{" "}
                        <BsThreeDotsVertical
                          style={{
                            cursor: "pointer",
                            color: "rgb(49,163,214)",
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <p>Sub Clinic Address</p>{" "}
                        <BsThreeDotsVertical
                          style={{
                            cursor: "pointer",
                            color: "rgb(49,163,214)",
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <p>Open Time</p>{" "}
                        <BsThreeDotsVertical
                          style={{
                            cursor: "pointer",
                            color: "rgb(49,163,214)",
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <p>Close Time</p>{" "}
                        <BsThreeDotsVertical
                          style={{
                            cursor: "pointer",
                            color: "rgb(49,163,214)",
                          }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {patientdetails.map((row) => (
                    <Row key={row.subclinic_id} row={row} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubClinicList;
