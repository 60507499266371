import React from "react";
import "./Loader.css";
import { Dna } from  'react-loader-spinner'
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const Loader = () => {
  return (
    <div className="loader-main-div">
   {/* <div className="loader-main-div">
      <div className="loader"></div>
  </div>*/}
  <Dna
  visible={true}
  height="100"
  width="100"
  ariaLabel="dna-loading"
  wrapperStyle={{}}
  wrapperClass="dna-wrapper"
/>
  </div>
  );
};

export default Loader;
