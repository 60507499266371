import React, { useState, useEffect } from "react";
import axios from "axios";
import Add from "@mui/icons-material/Add";
import { AiFillCaretDown } from "react-icons/ai";
import swal from "sweetalert";

import "./AppointmentbyClinic.css";
import { Navigate, useNavigate } from "react-router-dom";
import SearchLoader from "../Loader/SearchLoader";
import Loader from "../Loader/Loader";
import Apiservice from "../../Services/Apiservice";

const AppointmentbySubClinic = () => {
  //Search Name of Patient
  const [date, setDate] = useState("");
  const [ServiceType, setServiceType] = useState("");
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const [time, setTime] = useState("");
  const [message, setMessage] = useState("");
  const clinicID = localStorage.getItem("ClinicIDForHomepage");
  const subclinic_id = localStorage.getItem("SubClinicIDForHomepage");

  useEffect(() => {
    const loadPosts = async () => {
      setLoading(true);
      const response = await Apiservice.get(
        // "https://djangoobesitychat.azurewebsites.net/all/patient/"
        `/user/patient/subclinic/${subclinic_id}/`
      );
      setPosts(response.data);
      // // // //console.log(response.data);
      setLoading(false);
    };
    loadPosts();
  }, [subclinic_id]);

  // GET Details of Patient by Username
  const UserIdtoDetDetails = localStorage.getItem("UserIdtoDetDetails");
  const [patientdetailsinaddpatient, setpatientdetailsinaddpatient] = useState(
    []
  );
  const [userdetails, setuserdetails] = useState([]);
  const is_clinic = localStorage.getItem("is_clinic");
  const is_subclinic = localStorage.getItem("is_subclinic");

  useEffect(() => {
    Apiservice
      .get(
        `/current/user/details/${UserIdtoDetDetails}/`
        // `https://djangoobesitychat.azurewebsites.net/all/patient/${UserIdtoDetDetails}/`
      )

      .then((response) => {
        setpatientdetailsinaddpatient(response.data);
        // // // //console.log(response.data);
      })
      .catch((err) => {});
  }, [UserIdtoDetDetails]);

  const [opentwo, setOPentwo] = useState(false);
  const toggletwo = () => {
    setOPentwo(!opentwo);
  };
  const [openthree, setOPenthree] = useState(false);
  const togglethree = () => {
    setOPenthree(!openthree);
  };
  const [openfour, setOPenfour] = useState(false);
  const togglefour = () => {
    setOPenfour(!openfour);
  };

  //Search Name of Doctor
  const [poststwo, setPoststwo] = useState([]);
  const [searchTitletwo, setSearchTitletwo] = useState("");
  const clinciID = localStorage.getItem("ClinicIDForHomepage");
  const  is_receptionist = localStorage.getItem("is_receptionist");

  useEffect(() => {
    const loadPoststwo = async () => {
      setLoading(true);
      const response = await Apiservice.get(
        // "https://djangoobesitychat.azurewebsites.net/all/doctor/"
        // `https://djangoobesitychat.azurewebsites.net/user/doctor/clinic/${clinciID}/`
        `/user/doctor/subclinic/${subclinic_id}/`
      );
      setPoststwo(response.data);
      // // // //console.log(response.data);
      setLoading(false);
    };
    loadPoststwo();
  }, [subclinic_id]);

  // GET Details of Doctor by Username
  const DoctorIdToGetDetails = localStorage.getItem("DoctorIdToGetDetails");
  const [doctorDetailsinAppointment, setdoctorDetailsinAppointment] = useState(
    []
  );
  const [doctordetails, setdoctordetails] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(
        // `https://djangoobesitychat.azurewebsites.net/current/user/details/${DoctorIdToGetDetails}/`
        `https://djangoobesitychat.azurewebsites.net/current/user/details/${DoctorIdToGetDetails}/`
      )

      .then((response) => {
        setdoctorDetailsinAppointment(response.data);
        // // // //console.log(response.data);
      })
      .catch((err) => {});
  }, [DoctorIdToGetDetails]);

  // POST Appointment Request

  // const clinicid=localStorage.getItem(id)
  const [submitLoading, setsubmitLoading] = useState(false);

  const CreateAppointment = async (e) => {
    e.preventDefault();
    setsubmitLoading(true);

    await Apiservice
      .post(
        // "https://djangoobesitychat.azurewebsites.net/appointmentapi/",
        `/appointmentpostapi/`,

        {
          // doctor_firstName: doctorDetailsinAppointment.firstName,
          // doctor_lastName: doctorDetailsinAppointment.lastName,
          doctor_name: doctorDetailsinAppointment.username,
          // patient_firstName: patientdetailsinaddpatient.firstName,
          // patient_lastName: patientdetailsinaddpatient.lastName,
          patient_id2: patientdetailsinaddpatient.id,
          service: ServiceType,
          day: date,
          time: time,
          // clinic_id: clinicID,
          subclinic_id: subclinic_id,
          endtime: "2023-03-07T12:19:00Z",
        }
      )
      .then((res) => {
        // // // //console.log(res);
        setsubmitLoading(false);

        localStorage.removeItem("DoctorIdToGetDetails");
        localStorage.removeItem("UserIdtoDetDetails");
        swal({
          title: "Appointment Created Successfully...",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });
        // navigate("/subclinicsidebar/subclinicappointmentlist");
        if (is_receptionist === "true") {
          navigate("/receptionistsidebar/appointmentlist");
        } else {
          navigate("/subclinicsidebar/subclinicappointmentlist");
        }
      })
      .catch((err) => {
        // // // //console.log("Error", err);
        setMessage(() => {
          return (
            <div>
              <p>Something Went Wrong...</p>
            </div>
          );
        });
      });
  };

  return (
    <div className="appointment-by-clini-flex-div">
      {submitLoading && (
        <div className="purchase-order-details-pop-up-main-div">
          <div className="purchase-order-details-pop-up-sub-div">
            <div
              className="purchase-order-details-pop-up-sub-div-container"
              style={{ background: "none" }}
            >
              <Loader />
            </div>
          </div>
        </div>
      )}
      <div className="appointment-by-clini-width-div">
        <div className="appointment-by-clini-main-div">
          <div className="appointment-by-clini-sub-div-one">
            <h4>Create New Appointment</h4>
          </div>
          <div className="appointment-by-clini-sub-div-two">
            <div className="patient-details-in-appointment-main-div">
              <div className="patient-details-in-appointment-sub-div-two-part">
                <div className="patient-details-in-appointment-sub-div-two-part-one">
                  <h4>Search Patient :</h4>
                </div>
                <div className="patient-details-in-appointment-sub-div-two-part-one-two">
                  <input
                    type="text"
                    placeholder="Search Patient..."
                    className="search-patient-in-appt-inp-tag"
                    onClick={toggletwo}
                    onChange={(e) => setSearchTitle(e.target.value)}
                  />
                  {opentwo && (
                    <div className="list-of-all-user" onClick={toggletwo}>
                      {loading ? (
                        <div>
                          <SearchLoader />
                        </div>
                      ) : (
                        posts
                          .filter((value) => {
                            if (searchTitle === "") {
                              return posts;
                            } else if (
                              (value.patient_name,
                              value.patient_firstName
                                ?.toLowerCase()
                                .includes(searchTitle?.toLowerCase()))
                            ) {
                              return value;
                            }
                          })
                          .map((item) => (
                            <div className="list-of-user-main-flex-div">
                              <div className="list-of-user-main-div">
                                <p
                                  onClick={() =>
                                    localStorage.setItem(
                                      "UserIdtoDetDetails",
                                      item.patient_name
                                    )
                                  }
                                >
                                  {item.patient_name}
                                </p>
                              </div>
                            </div>
                          ))
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className="patient-details-in-appointment-sub-div-two">
                <div className="patient-details-in-appointment-sub-div-two-part">
                  <div className="patient-details-in-appointment-sub-div-two-part-one">
                    <p>First Name</p>
                  </div>
                  <div className="patient-details-in-appointment-sub-div-two-part-one-two">
                    <p>{patientdetailsinaddpatient.firstName}</p>
                  </div>
                </div>
                <div className="patient-details-in-appointment-sub-div-two-part">
                  <div className="patient-details-in-appointment-sub-div-two-part-one">
                    <p>Last Name</p>
                  </div>
                  <div className="patient-details-in-appointment-sub-div-two-part-one-two">
                    <p>{patientdetailsinaddpatient.lastName}</p>
                  </div>
                </div>
                <div className="patient-details-in-appointment-sub-div-two-part">
                  <div className="patient-details-in-appointment-sub-div-two-part-one">
                    <p>Gender</p>
                  </div>
                  <div className="patient-details-in-appointment-sub-div-two-part-one-two">
                    <p>{patientdetailsinaddpatient.gender}</p>
                  </div>
                </div>
                <div className="patient-details-in-appointment-sub-div-two-part">
                  <div className="patient-details-in-appointment-sub-div-two-part-one">
                    <p>Age</p>
                  </div>
                  <div className="patient-details-in-appointment-sub-div-two-part-one-two">
                    <p>{patientdetailsinaddpatient.age}</p>
                  </div>
                </div>
                <div className="patient-details-in-appointment-sub-div-two-part">
                  <div className="patient-details-in-appointment-sub-div-two-part-one">
                    <p>Address</p>
                  </div>
                  <div className="patient-details-in-appointment-sub-div-two-part-one-two">
                    <p>{patientdetailsinaddpatient.address}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="patient-details-in-appointment-main-div">
              <div className="patient-details-in-appointment-sub-div-two-part">
                <div className="patient-details-in-appointment-sub-div-two-part-one">
                  <h4>Search Doctor :</h4>
                </div>
                <div className="patient-details-in-appointment-sub-div-two-part-one-two">
                  <input
                    type="text"
                    placeholder="Search Doctor..."
                    className="search-patient-in-appt-inp-tag"
                    onClick={togglethree}
                    onChange={(e) => setSearchTitletwo(e.target.value)}
                  />
                  {openthree && (
                    <div className="list-of-all-user" onClick={togglethree}>
                      {loading ? (
                        <div>
                          <SearchLoader />
                        </div>
                      ) : (
                        poststwo
                          .filter((value) => {
                            if (searchTitletwo === "") {
                              return poststwo;
                            } else if (
                              value.doctor_name
                                // value.username
                                ?.toLowerCase()
                                .includes(searchTitletwo?.toLowerCase())
                            ) {
                              return value;
                            }
                          })
                          .map((item) => (
                            <div className="list-of-user-main-flex-div">
                              <div className="list-of-user-main-div">
                                <p
                                  onClick={() =>
                                    localStorage.setItem(
                                      "DoctorIdToGetDetails",
                                      item.doctor_name
                                    )
                                  }
                                >
                                  {item.doctor_name}
                                </p>
                              </div>
                            </div>
                          ))
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className="patient-details-in-appointment-sub-div-two">
                <div className="patient-details-in-appointment-sub-div-two-part">
                  <div className="patient-details-in-appointment-sub-div-two-part-one">
                    <p>First Name</p>
                  </div>
                  <div className="patient-details-in-appointment-sub-div-two-part-one-two">
                    <p>{doctorDetailsinAppointment.firstName}</p>
                  </div>
                </div>
                <div className="patient-details-in-appointment-sub-div-two-part">
                  <div className="patient-details-in-appointment-sub-div-two-part-one">
                    <p>Last Name</p>
                  </div>
                  <div className="patient-details-in-appointment-sub-div-two-part-one-two">
                    <p>{doctorDetailsinAppointment.lastName}</p>
                  </div>
                </div>
                <div className="patient-details-in-appointment-sub-div-two-part">
                  <div className="patient-details-in-appointment-sub-div-two-part-one">
                    <p>Gender</p>
                  </div>
                  <div className="patient-details-in-appointment-sub-div-two-part-one-two">
                    <p>{doctorDetailsinAppointment.gender}</p>
                  </div>
                </div>
                <div className="patient-details-in-appointment-sub-div-two-part">
                  <div className="patient-details-in-appointment-sub-div-two-part-one">
                    <p>Age</p>
                  </div>
                  <div className="patient-details-in-appointment-sub-div-two-part-one-two">
                    <p>{doctorDetailsinAppointment.age}</p>
                  </div>
                </div>
                <div className="patient-details-in-appointment-sub-div-two-part">
                  <div className="patient-details-in-appointment-sub-div-two-part-one">
                    <p>Address</p>
                  </div>
                  <div className="patient-details-in-appointment-sub-div-two-part-one-two">
                    <p>{doctorDetailsinAppointment.address}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div></div>
          <div className="final-appointment-main-div-flex">
            <div className="final-appointment-main-div">
              <div className="final-appointment-block-div">
                {/*
                <div className="final-appointment-sub-div-one">
                  <div className="final-appointment-main-div-part-one">
                    <h4>Service</h4>
                  </div>
                  <div className="final-appointment-main-div-part-two">
                    <div
                      className="appointment-details-fill-div"
                      style={{ textAlign: "right" }}
                      onClick={togglefour}
                    >
                      <AiFillCaretDown style={{ color: "rgb(49, 163, 214)" }} />
                    </div>
                    {openfour && (
                      <div className="toggle-drop-down-in-service-main">
                        <div className="toggle-drop-down-in-service-sub">
                          <div>
                            <input type="radio" name="services" />
                          </div>
                          <div>
                            <p>Doctor care</p>
                          </div>
                        </div>
                        <div className="toggle-drop-down-in-service-sub">
                          <div>
                            <input type="radio" name="services" />
                          </div>
                          <div>
                            <p>Nursing care</p>
                          </div>
                        </div>
                        <div className="toggle-drop-down-in-service-sub">
                          <div>
                            <input type="radio" name="services" />
                          </div>
                          <div>
                            <p>Medical Social Services </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                       */}
                <div className="final-appointment-sub-div-one">
                  <div className="final-appointment-main-div-part-one">
                    <h4>Service</h4>
                  </div>
                  <div className="final-appointment-main-div-part-two">
                    <div className="appointment-details-fill-div">
                      <div
                        className="toggle-drop-down-in-service-main-new"
                        style={{ display: "flex" }}
                      >
                        <div className="toggle-drop-down-in-service-sub">
                          <div>
                            <input
                              type="radio"
                              name="ServiceType"
                              value="Doctor care"
                              onChange={(e) => setServiceType(e.target.value)}
                            />
                          </div>
                          <div>
                            <p>Doctor care</p>
                          </div>
                        </div>
                        <div className="toggle-drop-down-in-service-sub">
                          <div>
                            <input
                              type="radio"
                              name="ServiceType"
                              value="Clinic Care"
                              onChange={(e) => setServiceType(e.target.value)}
                            />
                          </div>
                          <div>
                            <p>Clinic care</p>
                          </div>
                        </div>
                        {/* <div className="toggle-drop-down-in-service-sub">
                          <div>
                            <input
                              type="radio"
                              name="ServiceType"
                              value="Medical social services"
                              onChange={(e) => setServiceType(e.target.value)}
                            />
                          </div>
                          <div>
                            <p>Medical Social Services </p>
                          </div>
                      </div>*/}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="final-appointment-sub-div-one">
                  <div className="final-appointment-main-div-part-one">
                    <h4>Date</h4>
                  </div>
                  <div className="final-appointment-main-div-part-two">
                    <div>
                      <input
                        type="date"
                        className="appointment-details-fill-div"
                        // placeholder="dd-mm-yyyy"
                        // value="date"
                        onChange={(e) => setDate(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="final-appointment-sub-div-one">
                  <div className="final-appointment-main-div-part-one">
                    <h4>Time</h4>
                  </div>
                  <div className="final-appointment-main-div-part-two">
                    <div>
                      {/*   <input
                      className="appointment-details-fill-div"
                        type="text"
                        value={time}
                        onChange={(e) => setTime(e.target.value)}
                   />*/}
                      <div className="appointment-details-fill-div">
                        <div className="time-slot-flex-one">
                          <div
                            style={{
                              display: "flex",
                              gap: "3px",
                              alignItems: "center",
                            }}
                          >
                            <input
                              type="radio"
                              name="time"
                              value="3 PM"
                              onChange={(e) => setTime(e.target.value)}
                            />
                            <p>3 PM</p>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              gap: "3px",
                              alignItems: "center",
                            }}
                          >
                            <input
                              type="radio"
                              name="time"
                              value="3:30 PM"
                              onChange={(e) => setTime(e.target.value)}
                            />
                            <p>3:30 PM</p>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              gap: "3px",
                              alignItems: "center",
                            }}
                          >
                            <input
                              type="radio"
                              name="time"
                              value="4 PM"
                              onChange={(e) => setTime(e.target.value)}
                            />
                            <p>4 PM</p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              gap: "3px",
                              alignItems: "center",
                            }}
                          >
                            <input
                              type="radio"
                              name="time"
                              value="4:30 PM"
                              onChange={(e) => setTime(e.target.value)}
                            />
                            <p>4:30 PM</p>
                          </div>
                        </div>

                        <div className="time-slot-flex-one">
                          <div
                            style={{
                              display: "flex",
                              gap: "3px",
                              alignItems: "center",
                            }}
                          >
                            <input
                              type="radio"
                              name="time"
                              value="5 PM"
                              onChange={(e) => setTime(e.target.value)}
                            />
                            <p>5 PM</p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              gap: "3px",
                              alignItems: "center",
                            }}
                          >
                            <input
                              type="radio"
                              name="time"
                              value="5:30 PM"
                              onChange={(e) => setTime(e.target.value)}
                            />
                            <p>5:30 PM</p>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              gap: "3px",
                              alignItems: "center",
                            }}
                          >
                            <input
                              type="radio"
                              name="time"
                              value="6 PM"
                              onChange={(e) => setTime(e.target.value)}
                            />
                            <p>6 PM</p>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              gap: "3px",
                              alignItems: "center",
                            }}
                          >
                            <input
                              type="radio"
                              name="time"
                              value="6:30 PM"
                              onChange={(e) => setTime(e.target.value)}
                            />
                            <p>6:30 PM</p>
                          </div>
                        </div>
                        <div className="time-slot-flex-one">
                          <div
                            style={{
                              display: "flex",
                              gap: "3px",
                              alignItems: "center",
                            }}
                          >
                            <input
                              type="radio"
                              name="time"
                              value="7 PM"
                              onChange={(e) => setTime(e.target.value)}
                            />
                            <p>7 PM</p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              gap: "3px",
                              alignItems: "center",
                            }}
                          >
                            <input
                              type="radio"
                              name="time"
                              value="7:30 PM"
                              onChange={(e) => setTime(e.target.value)}
                            />
                            <p>7:30 PM</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "10px",
              paddingBottom: "5px",
            }}
          >
            {message ? <p>{message}</p> : <p style={{ fontSize: "10px" }}></p>}
          </div>

          <div className="add-pat-btn-div">
            <button className="add-btn-btn-tag" onClick={CreateAppointment}>
              <Add style={{ height: "20px", width: "20px" }} />
              CREATE APPOINTMENT
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentbySubClinic;
