import React, { useEffect, useState } from "react";
import "./ClinicHomePage.css";
import ClinicDashboard from "../../Images/ClinicSVG/newdashboard.svg";
import ClinicDoctors from "../../Images/ClinicSVG/newdoctor.svg";
import ClinicAppointments from "../../Images/ClinicSVG/newappointments.svg";
import ClinicPatient from "../../Images/ClinicSVG/newpatients.svg";
import ClinicPrescription from "../../Images/ClinicSVG/newprescription.svg";
import ClinicDoc from "../../Images/ClinicSVG/newdocuments.svg";
import Appointment from "../../Images/ClinicSVG/addappointment.svg";
import NewPatient from "../../Images/ClinicSVG/addpatient.svg";
import { MdEventNote } from "react-icons/md";
import { BiClinic } from "react-icons/bi";
import { GiGymBag} from "react-icons/gi";
import { FaUserNurse, FaGulp } from "react-icons/fa";
import { RiStethoscopeFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import axios from "axios";
import DietitianRegistration from "../DietitianRegistration/DietitianRegistration";
import Apiservice from "../../Services/Apiservice";

const ClinicHomePage = () => {
  const [opentwo, setOPentwo] = useState(false);
  const toggletwo = () => {
    setOPentwo(!opentwo);
  };

  const [openthree, setOPenthree] = useState(false);
  const togglethree = () => {
    setOPenthree(!openthree);
  };

  const [openfour, setOPenfour] = useState(false);
  const togglefour = () => {
    setOPenfour(!openfour);
  };

  const [openfive, setOPenfive] = useState(false);
  const togglefive = () => {
    setOPenfive(!openfive);
  };

  const [opensix, setOPensix] = useState(false);
  const togglesix = () => {
    setOPensix(!opensix);
  };
  const [openseven, setOPenseven] = useState(false);
  const toggleseven = () => {
    setOPenseven(!openseven);
  };

  const [openeight, setOPeneight] = useState(false);
  const toggleeight = () => {
    setOPeneight(!openeight);
  };

  const clinic = localStorage.getItem("is_clinic");
  const [clinicdetails, setclinicdetails] = useState([]);
  const [subclinicdetails, setsubclinicdetails] = useState([]);
  const SubClinicName = localStorage.getItem("Username");
  const ClinicName = localStorage.getItem("Username");

  // Clinic ID Store at Homepage

  useEffect(() => {
    Apiservice
      .get(`/user/clinicapi/name/${ClinicName}/`)

      .then((response) => {
        const clinic_id = response.data[0].clinic_id;
        localStorage.setItem("ClinicIDForHomepage", clinic_id);
      })
      .catch((err) => {});
  }, []);
  // if (!clinicdetails) return null;

  // SUB Clinic ID Store at Homepage

  useEffect(() => {
    Apiservice
      .get(`/user/subclinicapi/name/${SubClinicName}/`)

      .then((response) => {
        const subclinic_id = response.data[0].subclinic_id;
        localStorage.setItem("SubClinicIDForHomepage", subclinic_id);
      })
      .catch((err) => {});
  }, []);
  // if (!subclinicdetails) return null;

  return (
    <div className="clinic-home-page-flex-div">
      <div className="clinic-home-page-width-div">
        <div className="clinic-home-page-main-div">
          <div className="clinic-new-home-page-sub-div-main-one">
            <div className="coll-button-new-clinic-home-main">
              <div style={{ paddingBottom: "20px" }}>
                <div
                  className="coll-button-new-clinic-home-main-sub-one"
                  onClick={toggletwo}
                >
                  <img src={Appointment} className="quick-link-svg" alt="" />

                  {clinic == "true" ? (
                    <Link to="/clinicsidebar/appointmentbyclinic">
                      {opentwo && (
                        <p style={{ color: "black" }}>New Appointment</p>
                      )}
                    </Link>
                  ) : (
                    <Link to="/subclinicsidebar/appointmentbyclinic">
                      {opentwo && (
                        <p style={{ color: "black" }}>New Appointment</p>
                      )}
                    </Link>
                  )}
                </div>
              </div>
              <div
                className="coll-button-new-clinic-home-main-sub-one-two"
                onClick={togglethree}
                style={{ marginBottom: "20px" }}
              >
                <img src={NewPatient} className="quick-link-svg" alt="" />

                {clinic == "true" ? (
                  <Link to="/clinicsidebar/addnewpatient">
                    {openthree && <p style={{ color: "black" }}>New Patient</p>}
                  </Link>
                ) : (
                  <Link to="/subclinicsidebar/addnewpatient">
                    {openthree && <p style={{ color: "black" }}>New Patient</p>}
                  </Link>
                )}
              </div>

              <div
                className="coll-button-new-clinic-home-main-sub-one"
                onClick={togglefour}
                style={{ marginBottom: "20px" }}
              >
                <MdEventNote className="quick-link-svg" />
                {clinic == "true" ? (
                  <Link to="/clinicsidebar/eventsbyclinic">
                    {openfour && (
                      <p style={{ color: "black" }}>Create Events</p>
                    )}
                  </Link>
                ) : (
                  <Link to="/subclinicsidebar/eventsbyclinic">
                    {openfour && (
                      <p style={{ color: "black" }}>Create Events</p>
                    )}
                  </Link>
                )}
              </div>

              {clinic == "true" ? (
                <div
                  className="coll-button-new-clinic-home-main-sub-one"
                  onClick={togglefive}
                  style={{ marginBottom: "20px" }}
                >
                  <BiClinic className="quick-link-svg" />

                  <Link to="/clinicsidebar/subcliniclist">
                    {openfive && <p style={{ color: "black" }}>Sub Clinic</p>}
                  </Link>
                </div>
              ) : (
                ""
              )}

              <div
                className="coll-button-new-clinic-home-main-sub-one"
                onClick={togglesix}
                style={{ marginBottom: "20px" }}
              >
                <FaUserNurse className="quick-link-svg" />
                {clinic == "true" ? (
                  <Link to="/clinicsidebar/clinicreceptionistlist">
                    {opensix && (
                      <p style={{ color: "black" }}>Receptionists</p>
                    )}
                  </Link>
                ) : (
                  <Link to="/subclinicsidebar/subclinicreceptionistlist">
                    {opensix && (
                      <p style={{ color: "black" }}>Receptionists</p>
                    )}
                  </Link>
                )}
              </div>

            { /*<div
                className="coll-button-new-clinic-home-main-sub-one"
                onClick={toggleeight}
                style={{ marginBottom: "20px" }}
              >
                <FaGulp className="quick-link-svg" />
                {clinic == "true" ? (
                  <Link to="/clinicsidebar/clinicdietitianlist">
                    {openeight && (
                      <p style={{ color: "black" }}>Dietitian</p>
                    )}
                  </Link>
                ) : (
                  <Link to="/subclinicsidebar/subclinicdietitianlist">
                    {openeight && (
                      <p style={{ color: "black" }}>Dietitian</p>
                    )}
                  </Link>
                )}
                    </div>*/}

            {/* <div
                className="coll-button-new-clinic-home-main-sub-one"
                onClick={toggleeight}
                style={{ marginBottom: "20px" }}
              >
                <GiGymBag className="quick-link-svg" />
                {clinic == "true" ? (
                  <Link to="/clinicsidebar/clinictrainerlist">
                    {openeight && (
                      <p style={{ color: "black" }}>Trainer</p>
                    )}
                  </Link>
                ) : (
                  <Link to="/subclinicsidebar/subclinictrainerlist">
                    {openeight && (
                      <p style={{ color: "black" }}>Trainer</p>
                    )}
                  </Link>
                )}
                    </div>*/}

              {/*<div
                className="coll-button-new-clinic-home-main-sub-one"
                onClick={toggleseven}
              >
                <RiStethoscopeFill className="quick-link-svg" />
                {clinic == "true" ? (
                  <Link to="/clinicsidebar/adddoctordetails">
                    {openseven && (
                      <p style={{ color: "black" }}>Add Doctor Details</p>
                    )}
                  </Link>
                ) : (
                  <Link to="/subclinicsidebar/adddoctordetails">
                    {openseven && (
                      <p style={{ color: "black" }}>Add Doctor Details</p>
                    )}
                  </Link>
                )}
                    </div>*/}
            </div>
          </div>

          <div className="clinic-home-page-sub-div-one">
            <div className="clinic-home-sub-one-part-one-flex">
              {clinic == "true" ? (
                <Link to="/clinicsidebar/clinicdashboard">
                  <div
                    className="clinic-home-page-card"
                    style={{ backgroundColor: "rgb(137 216 255)" }}
                  >
                    <div>
                      <img
                        src={ClinicDashboard}
                        className="clinic-home-svg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div>
                    <h3 className="clinic-p-tag-data">Dashboard</h3>
                  </div>
                </Link>
              ) : (
                <Link to="/subclinicsidebar/clinicdashboard">
                  <div
                    className="clinic-home-page-card"
                    style={{ backgroundColor: "rgb(137 216 255)" }}
                  >
                    <div>
                      <img
                        src={ClinicDashboard}
                        className="clinic-home-svg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div>
                    <h3 className="clinic-p-tag-data">Dashboard</h3>
                  </div>
                </Link>
              )}

              {clinic == "true" ? (
                <Link to="/clinicsidebar/clinicpatientlist">
                  <div
                    className="clinic-home-page-card"
                    style={{ backgroundColor: "rgb(255 206 133)" }}
                  >
                    <div>
                      <img
                        src={ClinicPatient}
                        className="clinic-home-svg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div>
                    <h3 className="clinic-p-tag-data">Patients</h3>
                  </div>
                </Link>
              ) : (
                <Link to="/subclinicsidebar/subclinicpatientlist">
                  <div
                    className="clinic-home-page-card"
                    style={{ backgroundColor: "rgb(255 206 133)" }}
                  >
                    <div>
                      <img
                        src={ClinicPatient}
                        className="clinic-home-svg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div>
                    <h3 className="clinic-p-tag-data">Patients</h3>
                  </div>
                </Link>
              )}

              {clinic == "true" ? (
                <Link to="/clinicsidebar/clinicserviseproviderlist">
                  <div
                    className="clinic-home-page-card"
                    style={{ backgroundColor: "rgb(189 217 251)" }}
                  >
                    <div>
                      <img
                        src={ClinicDoctors}
                        className="clinic-home-svg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div>
                    <h3 className="clinic-p-tag-data">Service Providers</h3>
                  </div>
                </Link>
              ) : (
                <Link to="/subclinicsidebar/subclinicserviseproviderlist">
                  <div
                    className="clinic-home-page-card"
                    style={{ backgroundColor: "rgb(189 217 251)" }}
                  >
                    <div>
                      <img
                        src={ClinicDoctors}
                        className="clinic-home-svg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div>
                    <h3 className="clinic-p-tag-data">Service Providers</h3>
                  </div>
                </Link>
              )}
            </div>

            <div className="clinic-home-sub-one-part-one-flex-two">
              {clinic == "true" ? (
                <Link to="/clinicsidebar/appointmentlist">
                  <div
                    className="clinic-home-page-card"
                    style={{ backgroundColor: "rgb(137 255 255)" }}
                  >
                    <div>
                      <img
                        src={ClinicAppointments}
                        className="clinic-home-svg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div>
                    <h3 className="clinic-p-tag-data">Appointments</h3>
                  </div>
                </Link>
              ) : (
                <Link to="/subclinicsidebar/subclinicappointmentlist">
                  <div
                    className="clinic-home-page-card"
                    style={{ backgroundColor: "rgb(137 255 255)" }}
                  >
                    <div>
                      <img
                        src={ClinicAppointments}
                        className="clinic-home-svg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div>
                    <h3 className="clinic-p-tag-data">Appointments</h3>
                  </div>
                </Link>
              )}

              {clinic == "true" ? (
                <Link to="/clinicsidebar/docbyclinic">
                  <div
                    className="clinic-home-page-card"
                    style={{ backgroundColor: "rgb(243 224 155)" }}
                  >
                    <div>
                      <img src={ClinicDoc} className="clinic-home-svg" alt="" />
                    </div>
                  </div>
                  <div>
                    <h3 className="clinic-p-tag-data">Files</h3>
                  </div>
                </Link>
              ) : (
                <Link to="/subclinicsidebar/docbyclinic">
                  <div
                    className="clinic-home-page-card"
                    style={{ backgroundColor: "rgb(243 224 155)" }}
                  >
                    <div>
                      <img src={ClinicDoc} className="clinic-home-svg" alt="" />
                    </div>
                  </div>
                  <div>
                    <h3 className="clinic-p-tag-data">Files</h3>
                  </div>
                </Link>
              )}
              {clinic == "true" ? (
                <Link to="/clinicsidebar/prescriptioncliniccard">
                  <div
                    className="clinic-home-page-card"
                    style={{ backgroundColor: "rgb(200 235 247)" }}
                  >
                    <div>
                      <img
                        src={ClinicPrescription}
                        className="clinic-home-svg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div>
                    <h3 className="clinic-p-tag-data">Prescription</h3>
                  </div>
                </Link>
              ) : (
                <Link to="/subclinicsidebar/prescriptioncliniccard">
                  <div
                    className="clinic-home-page-card"
                    style={{ backgroundColor: "rgb(200 235 247)" }}
                  >
                    <div>
                      <img
                        src={ClinicPrescription}
                        className="clinic-home-svg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div>
                    <h3 className="clinic-p-tag-data">Prescription</h3>
                  </div>
                </Link>
              )}
            </div>
          </div>

          {/*
          <div className="clinic-home-page-sub-div-two">
            <div className="quick-link-flex-main">
              <div>
                <img src={Appointment} className="quick-link-svg" alt="" />
              </div>
              <div>
                <h4>New Appointment</h4>
              </div>
            </div>
            <div className="quick-link-flex-main">
              <div>
                <img src={NewPatient} className="quick-link-svg" alt="" />
              </div>
              <div>
                <h4>New Patient</h4>
              </div>
            </div>
            <div className="quick-link-flex-main">
              <div>
                <img src={AddPrescription} className="quick-link-svg" alt="" />
              </div>
              <div>
                <h4>Add Prescription</h4>
              </div>
            </div>
            <div className="quick-link-flex-main">
              <div>
                <img src={ViewReport} className="quick-link-svg" alt="" />
              </div>
              <div>
                <h4>View Report</h4>
              </div>
            </div>
            <div className="quick-link-flex-main">
              <div>
                <img src={Billing} className="quick-link-svg" alt="" />
              </div>
              <div>
                <h4>Billing</h4>
              </div>
            </div>

            <div className="quick-link-flex-main-for-notes">
              <div>
                <img src={Notes} className="quick-link-svg" alt="" />
              </div>
              <div>
                <h4>Notes</h4>
              </div>
            </div>
            <div className="quick-link-flex-main" style={{ paddingTop: "5px" }}>
              <div>
                <img src={Customersupport} className="quick-link-svg" alt="" />
              </div>
              <div>
                <h4>Customer Support</h4>
              </div>
            </div>
            <div className="quick-link-flex-main">
              <div>
                <img src={HowtoUse} className="quick-link-svg" alt="" />
              </div>
              <div>
                <h4>How to Use</h4>
              </div>
            </div>
            <div className="quick-link-flex-main">
              <div>
                <img src={HelpLine} className="quick-link-svg" alt="" />
              </div>
              <div>
                <h4>Help Line</h4>
              </div>
            </div>
          </div>
 */}
        </div>
      </div>
    </div>
  );
};

export default ClinicHomePage;
