import React, { useEffect, useState } from "react";
import { GiPaperBagCrumpled, GiHeartBeats, GiMedicines } from "react-icons/gi";
import { MdBloodtype, MdOutlineDoubleArrow } from "react-icons/md";
import WeightForcasting from "../WeightForcasting/WeightForcasting";
import "./PatientMedicalHistory.css";
import EmotionalHungerDoughnut from "../EmotionalHungerDoughnut/EmotionalHungerDoughnut";
import HungryBrainDoughnut from "../HungryBrainDoughnut/HungryBrainDoughnut";
import { FaAngleDown } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FcSportsMode } from "react-icons/fc";
import { RiArrowDropDownFill } from "react-icons/ri";
import {
  AiOutlineArrowUp,
  AiOutlineArrowDown,
  AiOutlineClose,
} from "react-icons/ai";
import axios from "axios";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import swal from "sweetalert";
import PatientCalender from "../PatientCalender/PatientCalender";
import Loader from "../Loader/Loader";
import Apiservice from "../../Services/Apiservice";

const Card = ({ data }) => {
  const { id, patient_name, BMI, date, week, month, year, updated_at } = data;
  const BMII = BMI;
  const datee = moment(updated_at).format("YYYY-MM-DD");
  //console.log("smonth", date[0]);

  return (
    <div
      style={{ display: "flex", justifyContent: "center", gap: "30px" }}
      className="card"
    >
      <div className="week-section-main-div">
        <div className="week-section-sub-div-one">
          <p className="week-para-in-patient">Month {month}</p>
          <div className="bmi-div-btn-in-patient">
            <div className="bmi-btn-two-part-div"></div>

            <div>{Math.round(BMII)} BMI</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PatientMedicalHistory = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleCard = () => {
    setIsExpanded(!isExpanded);
    // Add your animation logic here
  };
  const [popup, setPop] = useState(false);
  const handleClickOpen = () => {
    setPop(!popup);
  };

  const closePopup = () => {
    setPop(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", () => {
      setPop(false);
    });
  });

  // Contradictions Popup
  const [contraPopup, setContraPopup] = useState(false);
  const onClickOpen = () => {
    setContraPopup(!contraPopup);
  };

  const closeContraPopup = () => {
    setContraPopup(false);
  };

  // Hungry Brain Popup
  const [hungrybrainPopup, sethungrybrainPopup] = useState(false);
  const onClickOpenhungrybrain = () => {
    sethungrybrainPopup(!hungrybrainPopup);
  };

  const closehungrybrainPopup = () => {
    sethungrybrainPopup(false);
  };
  const [emotionalPopup, setemotionalPopup] = useState(false);
  const onClickOpenemotional = () => {
    setemotionalPopup(!emotionalPopup);
  };

  const closeemotionalPopup = () => {
    setemotionalPopup(false);
  };

  const [open, setOPen] = useState(false);
  const toggle = () => {
    setOPen(!open);
  };

  const [opentwo, setOPentwo] = useState(false);
  const toggletwo = () => {
    setOPentwo(!opentwo);
  };

  const [openthree, setOPenthree] = useState(false);
  const togglethree = () => {
    setOPenthree(!openthree);
  };

  const [openfour, setOPenfour] = useState(false);
  const togglefour = () => {
    setOPenfour(!openfour);
  };

  const [openfive, setOPenfive] = useState(false);
  const togglefive = () => {
    setOPenfive(!openfive);
  };

  const [opensix, setOPensix] = useState(false);
  const togglesix = () => {
    setOPensix(!opensix);
  };

  const [openseven, setOPenseven] = useState(false);
  const toggleseven = () => {
    setOPenseven(!openseven);
  };

  const [ShowBMIbyWeek, setShowBMIbyWeek] = useState(false);
  const toggleShowBMIbyWeek = () => {
    setShowBMIbyWeek(!ShowBMIbyWeek);
  };

  const [submitLoading, setsubmitLoading] = useState(false);
  const tokenForAddPatient = localStorage.getItem("SavedToken");
  const Username = localStorage.getItem("Username");
  const [patientuserdetails, setpatientuserdetails] = useState([]);
  const [query, setquery] = useState("");
  const [exercise, setexercise] = useState("");

  // PATIENT DETAILS GET REQUEST
  useEffect(() => {
    Apiservice
      .get(`/user/patientapi/${Username}/`)
      .then((response) => {
        setpatientuserdetails(response.data[0]);
      })
      .catch((err) => {
        // // // //console.log(err);
      });
  }, [Username]);

  // CALORIES POST REQUEST
  const addfooddata = async (e) => {
    setsubmitLoading(true);

    e.preventDefault();

    await Apiservice
      .post(`/natural/${Username}/`, {
        query: query,
      })
      .then((res) => {
        Apiservice
          .get(`/user/patientapi/${Username}/`)
          .then((response) => {
            setpatientuserdetails(response.data[0]);
          });
        toggleseven();
        setsubmitLoading(false);

        swal({
          title: "Food Added Successfully...",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });
      })
      .catch((err) => {
        setsubmitLoading(false);
      });
  };

  // const PrescriptionMedicine = patientuserdetails&&patientuserdetails.dispensed_drug
  // //console.log("PrescriptionMedicine",PrescriptionMedicine)

  // const total_caloriesget = localStorage.getItem("total_calories");

  // CALORIES POST REQUEST
  // const[dispensed_drug,setdispensed_drug]=useState("")
  const addexercisedata = async (e) => {
    e.preventDefault();
    setsubmitLoading(true);

    await Apiservice
      .post(`/exercise/${Username}/`, {
        query: exercise,
      })
      .then((res) => {
        Apiservice
          .get(`/user/patientapi/${Username}/`)
          .then((response) => {
            setpatientuserdetails(response.data[0]);
            // setdispensed_drug(patientuserdetails["dispensed_drug"])
          });
        togglesix();
        setsubmitLoading(false);

        swal({
          title: "Exercise Added Successfully...",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });
      })
      .catch((err) => {
        setsubmitLoading(false);
      });
  };

    const dispensed_drug = patientuserdetails&&patientuserdetails["dispensed_drug"] 
  

  //console.log("dispensed_drug",dispensed_drug)
  // //console.log("patientuserdetails",patientuserdetails)
  // const CaloriesBurn = localStorage.getItem("CaloriesBurn");

  // DRILLDOWN DATA GET REQUEST
  const [drilldown, setdrilldown] = useState();
  useEffect(() => {
    Apiservice
      .get(`/phenotypedrilldown/${Username}/`)
      .then((response) => {
        setdrilldown(response.data["categorized"]);
      })
      .catch((error) => {});
  }, [Username]);

  const data1 = drilldown || { drilldown };
  // const sorted = Object.entries(data1)
  //   .sort((a, b) => b[1] - a[1])
  //   .map((entry) => entry[0])
  //   .slice(0, 2);

  const sorted = Object.entries(data1)
  .sort((a, b) => parseFloat(b[1]) - parseFloat(a[1]))
  .map((entry) => entry[0])
  .slice(0, 2)

  const data = [
    {
      name: sorted[0],
      value: drilldown && drilldown[sorted[0]],
      fill: "rgb(238,175,0)",
    },
    { name: sorted[1], value: drilldown && drilldown[sorted[1]], fill: "red" },
  ];
  const defination = sorted[0];
  //console.log("sorted", sorted);
  //console.log("defination", defination);

  //  BMI HISTORY GET REQUEST
  const [bmiHistory, setbmiHistory] = useState();
  const [PatientBMI, setPatientBMI] = useState();
  const [comorboditiesPopup, setComorboditiesPopup] = useState(false);
  const onClickOpencomorbodities = () => {
    setComorboditiesPopup(!comorboditiesPopup);
  };
  useEffect(() => {
    Apiservice
      // .get(`https://djangoobesitychat.azurewebsites.net/notifications/${Username}/`)
      .get(`/user/allwighthistory/${Username}/`)
      .then((response) => {
        setbmiHistory(response.data);
        // // // //console.log("BMI", response.data);
      })
      .catch((err) => {
        // // //console.log(err);
      });
  }, []);

  const [contracount, setcontracount] = useState();

  useEffect(() => {
    Apiservice
      .get(`/drugwarningcount/${Username}/`)
      .then((response) => {
        setcontracount(response.data);
      })
      .catch((err) => {
        // // //console.log(err);
      });
  }, []);

  const [lastpresc, setlastpresc] = useState("");

  useEffect(() => {
    Apiservice
      .get(`/prescription2api/patient_name/${Username}/`)
      .then((response) => {
        setlastpresc(response.data[response.data.length - 1]);
        // // //console.log("setlastpresc", response.data[response.data.length]);
      })
      .catch((err) => {
        // // //console.log(err);
      });
  }, []);

  //  EXERCISE GET REQUEST

  const [exercisecheck, setexercisecheck] = useState("");

  useEffect(() => {
    Apiservice
      .get(`/exerciseprescription/patient/${Username}/`)
      .then((response) => {
        setexercisecheck(response.data["getdata"]);
      })
      .catch((err) => {
        // // //console.log(err);
      });
  }, []);

  // //console.log("exercisecheck",exercisecheck&&exercisecheck.length)

  const [dietcheck, setdietcheck] = useState("");

  useEffect(() => {
    Apiservice
      .get(`/dietprescription/patient/${Username}/`)
      .then((response) => {
        setdietcheck(response.data);
      })
      .catch((err) => {
        // // //console.log(err);
      });
  }, []);

  // //console.log("dietcheck",dietcheck&&dietcheck.length)

  // // // //console.log("array",lastpresc)
  // const c = bmiHistory && bmiHistory.map((index) => JSON.parse(index.changes).BMI);
  // const d = bmiHistory && bmiHistory.map((index) => index.week);

  const dateParsed =
    bmiHistory &&
    bmiHistory.map((index) =>
      moment(index.updatedate, "MMM DD YYYY HH:mm A").valueOf()
    );
  // const sortedData = bmiHistory && bmiHistory.sort((a, b) => moment(b.updatedate, "MMM DD YYYY HH:mm A").valueOf() - moment(a.updatedate, "MMM DD YYYY HH:mm A").valueOf());
  const sortedData = bmiHistory && bmiHistory.sort((a, b) => a.id - b.id);

  // const mostRecent = filtered && filtered[0];

  /* Creating a variable called currentWeek and setting it equal to the current week of the year. */
  const currentWeek = moment().week();

  const currentMonth = moment().month();
  // //console.log("currentWeek",currentWeek)

  /* Filtering the bmiHistory array to only include the weeks that are less than or equal to the
  current week. */
  const filtered =
    bmiHistory && bmiHistory.filter((index) => index.week <= currentWeek);

  const filteredbyMonth =
    bmiHistory && bmiHistory.filter((index) => index.month <= currentMonth);

  /* Checking if filtered is truthy, and if it is, it is assigning the first element of filtered to
  mostRecent. */
  const mostRecent = filtered && filtered[0];

  const dataByWeek =
    filtered &&
    filtered.reduce((acc, item) => {
      acc[item.week] = item;
      return acc;
    }, {});

  const sortDataByWeek =
    dataByWeek && Object.values(dataByWeek).sort((a, b) => a.week - b.week);

  //console.log("sortDataByWeek", sortDataByWeek);

  const dataByMonth =
    filteredbyMonth &&
    filteredbyMonth.reduce((acc, item) => {
      acc[item.month] = item;
      return acc;
    }, {});

  //console.log("dataByWeek", dataByWeek);

  const [MonthForBMIGet, setMonthForBMIGet] = useState("04");

  const CardbyWeek = ({ data }) => {
    const { id, patient_name, BMI, date, week, month, year, updated_at } = data;

    const BMII = BMI;
    const sweek = moment(bmiHistory[0].date).week();

    return (
      <div
        style={{ display: "flex", justifyContent: "center", gap: "30px" }}
        className="card"
      >
        <div className="week-section-main-div">
          <div className="week-section-sub-div-one">
            <p className="week-para-in-patient">Week {week - (sweek - 1)}</p>
            <div className="bmi-div-btn-in-patient">
              <div className="bmi-btn-two-part-div"></div>

              <div>{Math.round(BMII)} BMI</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  //console.log("bmiHistory", bmiHistory);
  const monthsWithData = [...new Set(bmiHistory&&bmiHistory?.map((entry) => entry.date.slice(0, 2)))];
  //console.log("monthsWithData", monthsWithData);
  //console.log("Total Calories", patientuserdetails && patientuserdetails.total_calories);

  const cardStyle = {
    cursor: "pointer",
    borderColor: (patientuserdetails && patientuserdetails.total_calories > 0) ? "" : "#ff0000",
    color: (patientuserdetails && patientuserdetails.total_calories > 0) ? "initial" : "#ff0000",
    boxShadow: (patientuserdetails && patientuserdetails.total_calories > 0) ? "rgb(0 0 0 / 35%) 0vh 1vh 1vh" : "2px 2px 8px 1px #ff0000",
    borderRadius: (patientuserdetails && patientuserdetails.total_calories > 0) ? "2em" : "10px",
  };
  

  return (
    <div style={{ paddingTop: "15px" }}>
      {submitLoading && (
        <div className="purchase-order-details-pop-up-main-div">
          <div className="purchase-order-details-pop-up-sub-div">
            <div
              className="purchase-order-details-pop-up-sub-div-container"
              style={{ background: "none" }}
            >
              <Loader />
            </div>
          </div>
        </div>
      )}

      <div>
        <select
          value={MonthForBMIGet}
          onChange={(e) => setMonthForBMIGet(e.target.value)}
          className="select-month-to-get-bmi-tag"
        >
          <option>Select Month</option>
          {monthsWithData.map((month) => (
        <option key={month} value={month}>
          {moment(month, "MM").format("MMMM")}
        </option>
      ))}
          {/* <option value="01">January</option>
          <option value="02">February</option>
          <option value="03">March</option>
          <option value="04">April</option>
          <option value="05">May</option>
          <option value="06">June</option>
          <option value="07">July</option>
          <option value="08">August</option>
          <option value="09">September</option>
          <option value="10">October</option>
          <option value="11">November</option>
          <option value="12">December</option> */}
        </select>
      </div>

      <div className="bmi-cards-main-div-in-patient-dashboard">
        {sortDataByWeek &&
          Object.values(sortDataByWeek).map((index) => (
            <div>
              {index.month == MonthForBMIGet ? (
                <div>
                  <CardbyWeek key={index.id} data={index} />
                </div>
              ) : (
                null
              )}
            </div>
          ))}
      </div>

      <div
        className="patient-medical-history-second-main-flex"
        style={{ width: "100%", paddingTop: "0px" }}
      >
        <div style = {{width:"40%"}}>
          <div className="pheno-title-main" style={{ paddingBottom: "10px" }}>
            <h5>Phenotype</h5>
          </div>
          <div
            className="pheno-drill-doughnut-main-div-new"
            style={{ padding: "15px", height: "300px" }}
          >
            <div className="pheno-pat-dash-flex-div">
              <div
                className="female-sou-ghnut-main-div"
                style={{ cursor: "pointer" }}
                onClick={onClickOpenhungrybrain}
              >
                <HungryBrainDoughnut />
              </div>

              {hungrybrainPopup ? (
                <div className="hungry-brain-pop-up-window">
                  <div
                    style={{
                      position: "absolute",
                      right: "10px",
                      cursor: "pointer",
                    }}
                    onClick={closehungrybrainPopup}
                  >
                    <AiOutlineClose />
                  </div>
                  {defination == "hungry gut" ? (
                    <div>
                      <h4>Hungry Gut</h4>
                      <p>
                        Normal food intake during meal characterized with a
                        rapid feel of hunger shortly after meal frequently get
                        hungry in between meals The cause of the lack of satiety
                        post-eating is because the stomach emptying the food
                        faster than other people
                      </p>
                    </div>
                  ) : defination == "hungry brain" ? (
                    <div>
                      <h4>Hungry Brain</h4>
                      <p>
                        Excessive calory consumption without feeling full
                        Delayed satiation during meal the brain is unable to
                        determine when to terminate a meal caused by
                        genetics\metabolic factors
                      </p>
                    </div>
                  ) : defination == "emotional hunger" ? (
                    <div>
                      <h4>Emotional Hunger</h4>
                      <p>
                        Eating as a response to emotions Looking for food as a
                        reaction to negative or positive emotions or as a
                        self-compensation action Characterized by craving,
                        anxiety and negative moods
                      </p>
                    </div>
                  ) : defination == "slow burn" ? (
                    <div>
                      <h4>Slow Burn</h4>
                      <p>
                        Slow metabolism burns fewer calories, which means more
                        get stored as fat in the body Also characterized by low
                        muscle mass Influenced by genetic factors
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  <div style={{ paddingTop: "10px" }}>
                    <Link to="/patientDashboard/bloghomepage">
                      <p
                        style={{
                          fontSize: "14px",
                          color: "rgb(3,23,247)",
                          cursor: "pointer",
                        }}
                      >
                        Learn More...
                      </p>
                    </Link>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <div style={{width: "60%"}}>
          <div className="pheno-title-main" style={{ paddingBottom: "10px" }}>
            <h5>Calendar</h5>
          </div>
          <div
            className="pheno-drill-doughnut-main-div-calendar"
            style={{ padding: "15px", height: "300px" }}
          >
            <div className="weight-forcast-graph-div-in-patient">
              <PatientCalender />
            </div>
          </div>
        </div>
      </div>

      <div className="pheno-title-main">
        <h5>Vital Information</h5>
      </div>
      <div className="med-history-cards-responsive">
        <div className="medical-history-section-main-div">

          <div className="blood-sugar-card-main-div-new">
          <div className="blood-sugar-card-for-last-update" style={{height:"75%"}}>
            <div className="card-logo-in-medical-history">
              <GiPaperBagCrumpled
                style={{ height: "25px", width: "25px", color: "white" }}
              />
            </div>
            <div className="card-detail-div-main-for-flex">
              <div className="card-detail-div-main">
                <h5>Blood Sugar</h5>

                {patientuserdetails && patientuserdetails.blood_sugar ? (
                  <div className="card-detail-div-sub">
                  <h4>
                    {patientuserdetails && patientuserdetails.blood_sugar}
                  </h4>
                  <p>mg / dl</p>
                </div>
                ) : (
                  <div className="card-detail-div-sub">
                  
                  <pre><p>Please visit the clinic to update</p>
                      <p>this information</p></pre>
                  
                </div>
                )
                }
            
                
              </div>
              <div className="card-detail-div-for-btn">
                {patientuserdetails &&
                patientuserdetails.blood_sugar > 35 &&
                patientuserdetails &&
                patientuserdetails.blood_sugar < 80 ? (
                  <button
                    className="nor-btn"
                    style={{ backgroundColor: "rgb(244,180,0)" }}
                  >
                    LOW
                  </button>
                ) : patientuserdetails &&
                  patientuserdetails.blood_sugar >= 80 &&
                  patientuserdetails &&
                  patientuserdetails.blood_sugar <= 180 ? (
                  <button
                    className="nor-btn"
                    style={{ backgroundColor: "green" }}
                  >
                    Normal
                  </button>
                ) : patientuserdetails &&
                  patientuserdetails.blood_sugar > 180 &&
                  patientuserdetails ? (
                  <button
                    className="nor-btn"
                    style={{ backgroundColor: "rgb(244 0 0)" }}
                  >
                    HIGH
                  </button>
                ) : patientuserdetails &&
                  patientuserdetails.blood_sugar < 35 ? (
                  <button className="nor-btn">ERROR</button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="blood-sugar-update-date-div">
          <p style={{margin:"0px"}}> Last Updated : {patientuserdetails && patientuserdetails.blood_sugar_updated_at?.slice(0,10)}</p>
          </div>
          </div>

          <div className="blood-sugar-card-main-div-new">
          <div className="blood-sugar-card-for-last-update" style={{height:"75%"}}>
            <div className="card-logo-in-medical-history-heart">
              <GiHeartBeats
                style={{ height: "22px", width: "22px", color: "white" }}
              />
            </div>
            <div className="card-detail-div-main-for-flex">
              <div className="card-detail-div-main">
                <h5>Heart Rate</h5>
                {patientuserdetails && patientuserdetails.heart_rate ? (
                  <div className="card-detail-div-sub">
                  <h4>{patientuserdetails && patientuserdetails.heart_rate}</h4>
                  <p>mg / dl</p>
                </div>
                ) : (
                  <div className="card-detail-div-sub">
                    <pre><p>Please visit the clinic to update</p>
                      <p>this information</p></pre>
                  </div>
                )
                }
                
              </div>
              <div className="card-detail-div-for-btn">
                <button className="nor-btn-heart">Normal</button>
              </div>
            </div>
          </div>
          <div className="blood-sugar-update-date-div">
          <p style={{margin:"0px"}}> Last Updated : {patientuserdetails && patientuserdetails.heart_rate_updated_at?.slice(0,10)}</p>
          </div>
          </div>

          <div className="blood-sugar-card-main-div-new">
          <div className="blood-sugar-card-for-last-update" style={{height:"75%"}}>
            <div className="card-logo-in-medical-history-blood">
              <MdBloodtype
                style={{ height: "22px", width: "22px", color: "white" }}
              />
            </div>
            <div className="card-detail-div-main-for-flex">
              <div className="card-detail-div-main">
                <h5>Blood Pressure</h5>
                {patientuserdetails && patientuserdetails.Systolic_blood_pressure ? (
                  <div className="card-detail-div-sub">
                  <h4>
                    {patientuserdetails && patientuserdetails.Systolic_blood_pressure}
                  </h4>
                  <p>/ {patientuserdetails && patientuserdetails.Diastolic_blood_pressure} mmhg</p>
                </div>
                ) : (
                  <div className="card-detail-div-sub">
                    <pre><p>Please visit the clinic to update</p>
                      <p>this information</p></pre>
                  </div>
                ) 
                
                }
                
              </div>
              <div className="card-detail-div-for-btn">
                {patientuserdetails &&
                patientuserdetails.blood_pressure < 120 &&
                patientuserdetails &&
                patientuserdetails.blood_pressure > 80 ? (
                  <button
                    className="nor-btn"
                    style={{ backgroundColor: "green" }}
                  >
                    Normal
                  </button>
                ) : patientuserdetails &&
                  patientuserdetails.blood_pressure > 120 &&
                  patientuserdetails &&
                  patientuserdetails.blood_pressure <= 130 ? (
                  <button
                    className="nor-btn"
                    style={{ backgroundColor: "rgb(244,180,0)" }}
                  >
                    ELEVATED
                  </button>
                ) : patientuserdetails &&
                  patientuserdetails.blood_pressure > 130 &&
                  patientuserdetails &&
                  patientuserdetails.blood_pressure <= 140 ? (
                  <button
                    className="nor-btn"
                    style={{ backgroundColor: "rgb(234 131 12)" }}
                  >
                    HIGH
                  </button>
                ) : patientuserdetails &&
                  patientuserdetails.blood_pressure > 140 &&
                  patientuserdetails &&
                  patientuserdetails.blood_pressure <= 180 ? (
                  <button
                    className="nor-btn"
                    style={{ backgroundColor: "rgb(250 77 25)" }}
                  >
                    HIGH
                  </button>
                ) : patientuserdetails &&
                  patientuserdetails.blood_pressure > 180 ? (
                  <button
                    className="nor-btn"
                    style={{ backgroundColor: "rgb(244 0 0)" }}
                  >
                    HIGH
                  </button>
                ) : patientuserdetails &&
                  patientuserdetails.blood_pressure < 80 ? (
                  <button className="nor-btn">ERROR</button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="blood-sugar-update-date-div">
          <p style={{margin:"0px"}}> Last Updated : {patientuserdetails && patientuserdetails.blood_pressure_updated_at?.slice(0,10)}</p>
          </div>
          </div>

          {/*<Link to="/patientDashboard/patientprescription">
            <div className="blood-sugar-card">
              <div className="card-logo-in-medical-history-drugs">
                <GiMedicines
                  style={{ height: "22px", width: "22px", color: "white" }}
                />
              </div>
              <div className="card-detail-div-main-for-flex">
                <div className="card-detail-div-main">
                  <h5>Drugs Prescribed</h5>
                  <div className="card-detail-div-sub-three">
                    <h4>{lastpresc && lastpresc.medicine_name}</h4>
                  </div>
                </div>
              </div>
            </div>
                </Link>*/}

          <div
            className="blood-sugar-card"
            onClick={onClickOpencomorbodities}
            style={{ cursor: "pointer" }}
          >
            <div className="card-logo-in-medical-history-blood">
              <MdBloodtype
                style={{ height: "22px", width: "22px", color: "white" }}
              />
            </div>
            <div className="card-detail-div-main-for-flex">
              <div className="card-detail-div-main">
                <h5>Comorbidities</h5>
                <div className="card-detail-div-sub-three">
                  <h2>{contracount && contracount.background_disease_count}</h2>
                </div>
              </div>
              {comorboditiesPopup ? (
                <div
                  className="contra-pop-up-main-div"
                  style={{
                    marginTop: "826px",
                    marginLeft: "-100px",
                    height: "300px",
                    overflowX: "scroll",
                  }}
                >
                  <div className="contra-pop-up">
                    <div className="card-detail-div-sub-three">
                      <div>
                        <div>
                          <CloseIcon
                            fontSize="small"
                            className="close-icon"
                            style={{ marginTop: "0px" }}
                            onClick={onClickOpencomorbodities}
                          />
                        </div>
                        {contracount &&
                          contracount.background_disease.map((index) => (
                            <p>{index}</p>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="pheno-title-main">
        <h5>Prescriptions</h5>
      </div>

      <div
        className="med-history-cards-responsive"
        style={{ paddingTop: "30px" }}
      >
        <div
          className="blood-sugar-card"
          style={{
            height: "auto",
            padding: "10px",
            // boxShadow: "rgb(49, 163, 214) 0vh 1vh 1vh",
            boxShadow: "rgb(49 163 214 / 46%) 0vh 0.5vh 2vh 0vh",
            height: "auto",
            alignItems: "center",
          }}
        >
          <Link to="/patientDashboard/patientprescription">
            <div style={{ display: "flex", gap: "10px" }}>
              <div className="card-logo-in-medical-history-drugs">
                <GiMedicines
                  style={{ height: "22px", width: "22px", color: "white" }}
                />
              </div>
              <div className="card-detail-div-main-for-flex">
                <div
                  className="card-detail-div-main"
                  style={{ minWidth: "130px" }}
                >
                  <h5>Drugs Prescribed</h5>
                  <div className="card-detail-div-sub-three">
                    {/*<h4>{lastpresc && lastpresc.medicine_name}</h4>*/}

                    {dispensed_drug ?
                      <div>
                        {dispensed_drug &&
                          dispensed_drug?.map((index) => (
                            <h4>{index.medicine_name}</h4>
                          ))}
                      </div>
                      :""}

                   {/*<h4>
                      // {dispensed_drug && dispensed_drug.medicine_name}
                          </h4>*/}

                  </div>
                </div>
              </div>
            </div>
          </Link>

          <div style={{ borderLeft: "2px solid #31a3d6", paddingLeft: "15px" }}>

          {dispensed_drug ?
            <div>
              {dispensed_drug &&
                dispensed_drug?.map((index) => (
                  <p>{index.mechanism_of_action}</p>
                ))}
            </div>
            :""}


           {/*<p>
              {dispensed_drug&&dispensed_drug.mechanism_of_action}
           </p>*/}
            {/* <p style={{ textAlign: "right", cursor: "pointer", color: "blue" }}>
              Read More...
            </p> */}
          </div>
        </div>
      </div>

      <div className="pheno-title-main">
        <h5>Lifestyle</h5>
      </div>
      <div className="med-history-cards-responsive">
        <div className="medical-history-section-main-div">
          <div className="blood-sugar-card" style={cardStyle}>
            <div className="card-logo-in-medical-history">
              <GiPaperBagCrumpled
                style={{ height: "25px", width: "25px", color: "white" }}
              />
            </div>
            <div className="card-detail-div-main-for-flex">
              <div className="card-detail-div-main">
                <h5>Calories Consumed</h5>
                

                <div
                  className="card-detail-div-sub"
                  style={{ cursor: "pointer" }}
                  onClick={toggleseven}
                >
                  {patientuserdetails && patientuserdetails.total_calories > 0 ? (
                    <>
                    <h4>
                    {patientuserdetails && patientuserdetails.total_calories}
                    </h4>
                  <p>kCal</p>
                  </>
                  ) : (
                    <pre><p>Please input the food</p>
                    <p>you have consumed</p>
                    </pre>
                  )
                  }
                  
                </div>
              </div>
              {openseven && (
                <div className="add-food-data-download-popup">
                  <span onClick={toggleseven}>
                    <CloseIcon fontSize="small" className="close-icon" />
                  </span>
                  <textarea
                    className="food-activity-text-field"
                    placeholder="Please add the details of the last meal you had"
                    value={query}
                    onChange={(e) => setquery(e.target.value)}
                  />
                  <div className="card-detail-div-for-btn">
                    <button
                      className="nor-btn"
                      style={{ cursor: "pointer" }}
                      onClick={addfooddata}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={`blood-sugar-card ${isExpanded ? 'expanded' : ''}`} style={{}} onClick={toggleCard}>
            <div className="card-logo-in-medical-history">
              <GiPaperBagCrumpled
                style={{ height: "25px", width: "25px", color: "white" }}
              />
            </div>
            <div className="card-detail-div-main-for-flex">
              <div className="card-detail-div-main">
                <h5>Physical Activity</h5>

                <div
                  className="card-detail-div-sub"
                  style={{ cursor: "pointer" }}
                  // onClick={toggleseven}
                >
                  <h4>
                    {patientuserdetails && patientuserdetails.last_exercise}
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <div className="blood-sugar-card" style={{}}>
            <div className="card-logo-in-medical-history">
              <FcSportsMode style={{ height: "25px", width: "25px" }} />
            </div>
            <div className="card-detail-div-main-for-flex">
              <div className="card-detail-div-main">
                <h5>Sport Activity</h5>

                {patientuserdetails && patientuserdetails.total_calories_burn ? (
                  <div
                  className="card-detail-div-sub"
                  style={{ cursor: "pointer" }}
                  onClick={togglesix}
                >
                  <h4>
                    {patientuserdetails &&
                      patientuserdetails.total_calories_burn}
                  </h4>
                  <p>Calories Burned</p>
                </div>
                ) : (
                  <pre><p>Please input the exercise</p>
                    <p>you have done</p>
                    </pre>
                )
                }
                
              </div>
              {opensix && (
                <div className="add-food-data-download-popup">
                  <span onClick={togglesix}>
                    <CloseIcon fontSize="small" className="close-icon" />
                  </span>
                  <textarea
                    className="food-activity-text-field"
                    placeholder="Add Your Sport Activity"
                    value={exercise}
                    onChange={(e) => setexercise(e.target.value)}
                  />
                  <div className="card-detail-div-for-btn">
                    <button
                      className="nor-btn"
                      style={{ cursor: "pointer" }}
                      onClick={addexercisedata}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>

          <Link to="/patientDashboard/patientdietandexerciseview">
            <div className="blood-sugar-card" style={{}}>
              <div className="card-logo-in-medical-history">
                <GiPaperBagCrumpled
                  style={{ height: "25px", width: "25px", color: "white" }}
                />
              </div>
              <div className="card-detail-div-main-for-flex">
                <div className="card-detail-div-main">
                  <h5>Diet and Exercise</h5>

                  <div
                    className="card-detail-div-sub"
                    style={{ cursor: "pointer" }}
                  >
                    <p>Learn More...</p>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
      {/* <div className="stages-treat-main-div-card">
        <div className="stages-treat-sub-one">
          <div className="stages-treat-sub-one-sec-one">
            <p onClick={toggle}>1</p>
          </div>
          <div className="stages-treat-sub-one-sec-two">
            <p>Screening</p>
          </div>
        </div>

        <div>
          <hr
            style={{
              width: "200px",
              marginTop: "-10px",
              border: "1.4px solid black",
            }}
          />
        </div>
        <div className="stages-treat-sub-one">
          <div className="stages-treat-sub-one-sec-one">
            <p onClick={toggletwo}>2</p>
          </div>
          <div className="stages-treat-sub-one-sec-two">
            <p>Assessment</p>
          </div>
        </div>
        <div>
          <hr
            style={{
              width: "200px",
              marginTop: "-10px",
              border: "1.4px solid rgb(174,205,234)",
            }}
          />
        </div>
        <div className="stages-treat-sub-one">
          <div className="stages-treat-sub-one-sec-one-three">
            <p onClick={togglethree}>3</p>
          </div>
          <div className="stages-treat-sub-one-sec-two">
            <p>Intervention</p>
          </div>
        </div>
      </div> */}

      {/* <div className="coll-flex-div-in-scr">
        {open && (
          <div className="main-collaps-screen">
            <div className="content-of-collaps-card">
              <div className="content-one-in-coll-card">
                <input type="checkbox" />
                <p>Existing Medical Tests</p>
              </div>
              <div className="content-one-in-coll-card">
                <input type="checkbox" />
                <p>Additional Medical Tests</p>
              </div>
              <div className="content-one-in-coll-card">
                <input type="checkbox" />
                <p>Comorbidity Screening</p>
              </div>
              <div className="content-one-in-coll-card">
                <input type="checkbox" />
                <p>Phenotype Screening</p>
              </div>
            </div>
          </div>
        )}

        {opentwo && (
          <div className="main-collaps-screen-two">
            <div className="content-of-collaps-card">
              <div
                onClick={togglefour}
                className="content-one-in-coll-card-sub-coll"
              >
                <p>Existing Medical Tests</p>
                <FaAngleDown
                  style={{ color: "rgb(0,138,201)", cursor: "pointer" }}
                />
              </div>

              {openfour && (
                <div>
                  <div className="content-one-in-coll-card">
                    <input type="checkbox" />
                    <p>Medical History</p>
                  </div>
                  <div className="content-one-in-coll-card">
                    <input type="checkbox" />
                    <p>Child Growth</p>
                  </div>
                  <div className="content-one-in-coll-card">
                    <input type="checkbox" />
                    <p>Parental Obesity</p>
                  </div>
                </div>
              )}

              <div
                onClick={togglefive}
                className="content-one-in-coll-card-sub-coll"
              >
                <p>Behaviour Risk</p>
                <FaAngleDown
                  style={{ color: "rgb(0,138,201)", cursor: "pointer" }}
                />
              </div>

              {openfive && (
                <div>
                  <div className="content-one-in-coll-card">
                    <input type="checkbox" />
                    <p>Sedentary Time</p>
                  </div>
                  <div className="content-one-in-coll-card">
                    <input type="checkbox" />
                    <p>Eating</p>
                  </div>
                  <div className="content-one-in-coll-card">
                    <input type="checkbox" />
                    <p>Physical Activity</p>
                  </div>
                </div>
              )}

              <div className="content-one-in-coll-card">
                <p>Activity</p>
              </div>
            </div>
          </div>
        )}

        {openthree && (
          <div className="main-collaps-screen-three">
            <div className="content-of-collaps-card">
              <div className="content-one-in-coll-card">
                <input type="checkbox" />
                <p>Surgical</p>
              </div>
              <div className="content-one-in-coll-card">
                <input type="checkbox" />
                <p>Medical</p>
              </div>
            </div>
          </div>
        )}
      </div> */}
    </div>
  );
};

export default PatientMedicalHistory;
