import axios from "axios";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import EmailVerification from "../PatientSignUp/EmailVerification";
import "./AccountSetting.css";
import Loader from "../Loader/Loader";
import Apiservice from "../../Services/Apiservice";

const AccountSetting = () => {
  const [firstname, setfirst_name] = useState("");
  const [lastname, setlast_name] = useState("");
  const [mobileno, setmobile] = useState("");
  const [password, setpassword] = useState("");
  const [email, setemail] = useState("");
  const [image, setimage] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [response, setresponse] = useState();

  const [userdetails, setuserdetails] = useState([]);
  const [updateuserdetails, setupdatedetails] = useState([]);
  const [otp, setotp] = useState("");
  // const username = localStorage.getItem("Username");
  const id = localStorage.getItem("id");
  const getusername = localStorage.getItem("Username");
  const [submitLoading, setsubmitLoading] = useState(false);

  const [isAddPrescriptionActive, setisAddPrescriptionActive] = useState(false);
  const AddPrescriptionHandler = () => {
    setisAddPrescriptionActive(!isAddPrescriptionActive);
    handleSubmitOTP();
  };
  const HideAddPrescriptionHandler = () => {
    setisAddPrescriptionActive(false);
  };

  //  GET USER DATA
  useEffect(() => {
    Apiservice
      .get(`/current/user/details/${id}/`)

      .then((response) => {
        setuserdetails(response.data);
        setfirst_name(response.data["firstName"]);
        setlast_name(response.data["lastName"]);
        setmobile(response.data["mobile"]);
        setemail(response.data["email"]);
        
        setpassword("");
      })
      .catch((err) => {
        // // // //console.log(err);
      });
  }, [id]);

  if (!userdetails) return null;

  // //console.log("firstname",firstname)

  // UPDATE USER DATA
  const handleSubmit = async (event) => {
    event.preventDefault();
    setsubmitLoading(true);
    const formData = new FormData();
    formData.append("image", selectedFile && selectedFile);
    formData.append("username", getusername);
    formData.append("firstName", firstname);
    formData.append("lastName", lastname);
    formData.append("password", password);
    formData.append("email", email);
    formData.append("mobile", mobileno);
    formData.append("is_doctor", userdetails && userdetails.is_doctor);
    formData.append("is_dietitian", userdetails && userdetails.is_dietitian);
    formData.append("is_trainer", userdetails && userdetails.is_trainer);
    formData.append("is_clinic", userdetails && userdetails.is_clinic);
    formData.append("is_subclinic", userdetails && userdetails.is_subclinic);
    formData.append(
      "is_receptionist",
      userdetails && userdetails.is_receptionist
    );
    formData.append("is_patient", userdetails && userdetails.is_patient);
    const medDocsField = formData.get("image");
    // // //console.log(medDocsField);

    const imageFormData = new FormData();
    imageFormData.append("image", selectedFile);
    imageFormData.append("username", getusername);
    imageFormData.append("imageName", selectedFile ? selectedFile.name : "");

    const updateAccount = () => { 
      return axios({
      method: "put",
      url: `https://djangoobesitychat.azurewebsites.net/user/accountsetting/update/${getusername}/`,
      data: formData,
    });
  };

    const postImage = () => {
      return axios({
        method: "post",
        url: "https://djangoobesitychat.azurewebsites.net/avatar/",
        data: imageFormData,
      });
    };

    const image =  imageFormData.get('image');
    

    if (image && image !== "") {
    Promise.all([updateAccount(), postImage()])
      .then((res) => {
        swal({
          title: "User Account Details Updated Successfully...",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });


        setfirst_name("");
        setlast_name("");
        setmobile("");
        setpassword("");
        setemail("");
        setSelectedFile(null);
        Apiservice
          .get(`/current/user/details/${id}/`)

          .then((response) => {
            setuserdetails(response.data);
          });
    setsubmitLoading(false);

      })

      .catch((res) => {
        // // //console.log("err", res.response.data);
        setresponse(res.response.data);
    setsubmitLoading(false);

      });
    } else {
      console.log("else")
      updateAccount()
        .then((res) => {
          swal({
            title: "User Account Details Updated Successfully...",
            className: "successAlert",
            buttons: true,
            timer: 3000,
          });
          setsubmitLoading(false);
        })
        .catch((res) => {
          // // //console.log("err", res.response.data);
          setresponse(res.response.data);
          setsubmitLoading(false);
        });
    }
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // SEND OTP

  const handleSubmitOTP = async (event) => {
    event.preventDefault();
    setsubmitLoading(true);
    const formDatatwo = new FormData();
    formDatatwo.append("email", userdetails.email);

    axios({
      method: "post",
      url: `https://djangoobesitychat.azurewebsites.net/send-otp/`,
      data: formDatatwo,
    })
      .then((res) => {
        swal({
          title: "Please Check your Registered Mailbox for OTP...!",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });
        setisAddPrescriptionActive(true);
        setsubmitLoading(false);
      })

      .catch((res) => {
        setresponse(res.response.data);
      });
  };

  // SUBMIT OTP

  const handleUploadOTP = async (event) => {
    event.preventDefault();
    setsubmitLoading(true);
    const formDatathree = new FormData();
    formDatathree.append("otp", otp);
    formDatathree.append("email", userdetails.email);

    axios({
      method: "post",
      // url: `https://djangoobesitychat.azurewebsites.net/email/otp/${getusername}/`,
      url: `https://djangoobesitychat.azurewebsites.net/verify-otp/`,
      data: formDatathree,
    })

    .then((res) => {
      swal({
        title: "OTP Verified Successfully...!",
        className: "successAlert",
        buttons: true,
        timer: 3000,
      });
  setsubmitLoading(false);

})
.catch((res) => {
  swal({
    title: "OTP Verification Failed...!",
    className: "successAlert",
    buttons: true,
    timer: 3000,
  });
});
setsubmitLoading(false);

  }

  const SubmitOtp = () => {
    setsubmitLoading(true);
    Apiservice
      .post(`/email/otp/${getusername}/`, {
        otp: otp,
      })
      .then((res) => {
        // // //console.log(res);
        swal({
          title: "OTP Verified Successfully...!",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });
    setsubmitLoading(false);

      })
      .catch((res) => {
        // // //console.log("err", res.response.data);
        swal({
          title: "OTP Verification Failed...!",
          className: "successAlert",
          buttons: true,
          timer: 3000,
        });
      });
    setsubmitLoading(false);

  };

  return (
    <div className="acc-setting-flex-div">
      {submitLoading && (
        <div className="purchase-order-details-pop-up-main-div">
          <div className="purchase-order-details-pop-up-sub-div">
            <div
              className="purchase-order-details-pop-up-sub-div-container"
              style={{ background: "none" }}
            >
              <Loader />
            </div>
          </div>
        </div>
      )}
      <div className="acc-setting-width-div">
        <div className="acc-setting-main-div">
          <div className="update-account-title-div">
            <h2>Update Account</h2>
          </div>
        </div>
        <div className="acc-setting-form-main-div">
          <div className="acc-setting-form-block-div">
            <div className="acc-setting-details">
              <div className="acc-info-div-one">
                <p>First Name</p>
              </div>
              <div className="acc-info-div-one">
                <input
                  type="text"
                  value={firstname}
                  onChange={(e) => setfirst_name(e.target.value)}
                  placeholder={userdetails.firstName}
                />
              </div>
            </div>

            <div className="acc-setting-details">
              <div className="acc-info-div-one">
                <p>Last Name </p>
              </div>
              <div className="acc-info-div-one">
                <input
                  value={lastname}
                  onChange={(e) => setlast_name(e.target.value)}
                  placeholder={userdetails.lastName}
                />
              </div>
            </div>

            <div className="acc-setting-details">
              <div className="acc-info-div-one">
                <p>Mobile Number </p>
              </div>
              <div className="acc-info-div-one">
                <input
                  value={mobileno}
                  onChange={(e) => setmobile(e.target.value)}
                  placeholder={userdetails.mobile}
                />
              </div>
            </div>
            <div className="acc-setting-details">
              <div className="acc-info-div-one">
                <p>Email ID</p>
              </div>
              <div className="acc-info-div-one">
                <input
                  type={"email"}
                  autoComplete="off"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                  placeholder={userdetails.email}
                />
              </div>
            </div>
            <div className="acc-setting-details">
              <div className="acc-info-div-one">
                <p>Password</p>
              </div>
              <div className="acc-info-div-one">
                <input
                  type={"password"}
                  value={password}
                  onChange={(e) => setpassword(e.target.value)}
                  placeholder={"Enter Your Password"}
                />
              </div>
            </div>
            <div className="acc-setting-details">
              <div className="acc-info-div-one">
                <p>Image</p>
              </div>
              <div className="acc-info-div-one">
                <input
                  type={"file"}
                  onChange={handleFileSelect}
                  // placeholder={"Enter Your Password"}
                />
              </div>
            </div>

            {/*<div className="acc-setting-details">
              <div className="acc-info-div-one">
                <p>Image </p>
              </div>
              <div className="acc-info-div-one">
                <input type="file" />
              </div>
  </div>*/}
            <div className="update-pat-btn-div">
              <button onClick={handleSubmit} className="update-btn-btn-tag">
                Update Account
              </button>
            </div>

            {/* 
            <div className="update-pat-btn-div">
              <a href="https://djangoobesitychat.azurewebsites.net/update-email/">
                <button
                  onClick={AddPrescriptionHandler}
                  className="update-btn-btn-tag"
                >
                  Verify Email !
                </button>
              </a>
            </div>
*/}
            <div>
              {userdetails && userdetails.verified == false ? (
                <div className="update-pat-btn-div">
                  <button
                    onClick={handleSubmitOTP}
                    className="update-btn-btn-tag"
                  >
                    Send OTP for Email Verification !
                  </button>
                </div>
              ) : (
                ""
              )}

              {isAddPrescriptionActive == true ? (
                <div style={{ paddingTop: "10px" }}>
                  <div className="email-verification-width-div">
                    <div className="email-verification-main-div-one">
                      <h3>Email Verification</h3>
                    </div>
                    <div className="email-verification-main-div-one">
                      <h4>Please Check Your Mailbox for OTP...</h4>
                    </div>
                    <div className="email-verification-main-div-two">
                      <div>
                        <p>OTP : </p>
                      </div>
                      <div>
                        <input
                          type={"number"}
                          className="email-input-field-tag"
                          value={otp}
                          onChange={(e) => setotp(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="email-verification-main-div-two">
                      <button
                        className="login-page-login-btn"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "60px",
                        }}
                        onClick={handleUploadOTP}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSetting;
