import React from "react";

const CustomerSuccessDashboard = () => {
    return (
        <div>
        <h1>Customer Success Dashboard</h1>
        </div>
    );
    }

export default CustomerSuccessDashboard;